@charset "UTF-8";
.login-page {
  /*
	Table of contents:
	1. Common styles
	2. Common responsive styles
	3. Headers
	4. Navigations
	5. Contents
	6. Testimonials
	7. Features
	8. Forms
	9. Call to Actions
	10. Teams
	11. Pricing Tables
	12. Contacts
	13. Showcases
	14. Footers
	15. Ecommerce
	16. Applications
*/
  /* 1. Common styles */
  /* Change AOS plugin default settings */
  /* Slick slider */
  /* Arrows */
  /* Dots */
  /* 2. Common responsive styles */
  /* Smaller than 1200px */
  /* Smaller than 992px */
  /* Smaller than 768px */
  /* Smaller than 576px */
  /* 3. Headers */
  /* 4. Navigations */
  /* 5. Contents */
  /* 6. Testimonials */
  /* 7. Features */
  /* 8. Forms */
  /* 9. Call to Actions */
  /* 10. Teams */
  /* 11. Pricing Tables */
  /* 12. Contacts */
  /* 13. Showcases */
  /* 14. Footers */
  /* 15. Ecommerce */
  /* 16. Applications */ }
  .login-page body {
    font-family: Nunito Sans, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #1e3060;
    -webkit-font-smoothing: antialiased; }
    .login-page body.dev {
      background: #ccc;
      min-height: 10000px; }
      .login-page body.dev .js-content-editable {
        outline: rgba(113, 33, 244, 0.7) dashed 2px !important;
        outline-offset: -2px; }
        .login-page body.dev .js-content-editable:hover {
          outline-color: #0074f1 !important;
          outline-style: solid !important; }
      .login-page body.dev section.js-content-editable,
      .login-page body.dev header.js-content-editable,
      .login-page body.dev nav.js-content-editable,
      .login-page body.dev footer.js-content-editable {
        outline-width: 10px !important;
        outline-offset: -10px; }
  .login-page nav,
  .login-page header,
  .login-page section,
  .login-page footer {
    position: relative; }
  .login-page header,
  .login-page section {
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover; }
  .login-page header nav {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000; }
  .login-page nav {
    width: 100%; }
    .login-page nav.transparent {
      background: transparent; }
  .login-page a,
  .login-page button {
    cursor: pointer;
    transition: all 0.25s; }
    .login-page a:hover,
    .login-page button:hover {
      text-decoration: none; }
    .login-page a:active, .login-page a:focus,
    .login-page button:active,
    .login-page button:focus {
      text-decoration: none;
      outline: none; }
  .login-page button {
    border: none;
    outline: none !important; }
  .login-page .header_menu_1 {
    padding-bottom: 40px !important; }
  .login-page input,
  .login-page textarea {
    border: 0;
    outline: none;
    background: transparent;
    color: #1e3060;
    resize: none; }
  .login-page label {
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer; }
  .login-page h1,
  .login-page h2,
  .login-page h3,
  .login-page h4,
  .login-page h5,
  .login-page h6 {
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    margin: 0;
    font-weight: 700;
    font-family: Nunito Sans, Helvetica, Arial, sans-serif; }
  .login-page h1,
  .login-page h2 {
    font-size: 58px;
    line-height: 70px; }
    .login-page h1.big,
    .login-page h2.big {
      font-size: 72px;
      line-height: 86px; }
    .login-page h1.small,
    .login-page h2.small {
      font-size: 42px;
      line-height: 52px;
      font-weight: 700; }
  .login-page h3 {
    font-size: 42px;
    line-height: 52px; }
  .login-page b,
  .login-page strong {
    font-weight: 700; }
  .login-page [data-aos="fade-down"] {
    transform: translate3d(0, -35px, 0); }
  .login-page [data-aos="fade-up"] {
    transform: translate3d(0, 35px, 0); }
  .login-page .slick-prev,
  .login-page .slick-next {
    position: absolute;
    display: block;
    height: 32px;
    width: 32px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    margin-top: -16px;
    padding: 0;
    border: none;
    outline: none;
    z-index: 1; }
    .login-page .slick-prev:hover, .login-page .slick-prev:focus,
    .login-page .slick-next:hover,
    .login-page .slick-next:focus {
      outline: none;
      background: transparent;
      color: transparent; }
      .login-page .slick-prev:hover:before, .login-page .slick-prev:focus:before,
      .login-page .slick-next:hover:before,
      .login-page .slick-next:focus:before {
        opacity: 0.5; }
    .login-page .slick-prev.slick-disabled:before,
    .login-page .slick-next.slick-disabled:before {
      opacity: 0.3; }
    .login-page .slick-prev:before,
    .login-page .slick-next:before {
      display: block;
      content: "";
      width: 32px;
      height: 32px;
      opacity: 1;
      transition: opacity 0.2s; }
  .login-page .slick-prev {
    left: 0; }
    [dir="rtl"] .login-page .slick-prev {
      left: auto;
      right: 0; }
  .login-page .slick-next {
    right: 0; }
    [dir="rtl"] .login-page .slick-next {
      left: 0;
      right: auto; }
  .login-page .slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
    margin: 0;
    left: 0;
    right: 0; }
    .login-page .slick-dots li {
      position: relative;
      display: inline-block;
      height: 10px;
      width: 10px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer; }
      .login-page .slick-dots li button {
        box-sizing: border-box;
        border: 2px solid #FFFFFF;
        border-radius: 50%;
        background: transparent;
        display: block;
        height: 10px;
        width: 10px;
        padding: 0;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        color: transparent;
        opacity: 0.3;
        cursor: pointer; }
        .login-page .slick-dots li button:hover {
          outline: none;
          opacity: 0.8; }
      .login-page .slick-dots li.slick-active button {
        background: #FFFFFF;
        opacity: 1; }
        .login-page .slick-dots li.slick-active button:hover {
          opacity: 1; }
  .login-page .slick-slide {
    outline: none; }
  .login-page .container {
    position: relative; }
  .login-page ::-moz-selection {
    background: #7121F4;
    color: #FFFFFF; }
  .login-page ::-webkit-selection {
    background: #7121F4;
    color: #FFFFFF; }
  .login-page ::selection {
    background: #7121F4;
    color: #FFFFFF; }
  @media (max-width: 1199.98px) {
    .login-page header[class^="pt-"], .login-page header[class*=" pt-"],
    .login-page section[class^="pt-"],
    .login-page section[class*=" pt-"] {
      padding-top: 80px; }
    .login-page header[class^="pb-"], .login-page header[class*=" pb-"],
    .login-page section[class^="pb-"],
    .login-page section[class*=" pb-"] {
      padding-bottom: 80px; }
    .login-page h1 br,
    .login-page h2 br,
    .login-page h3 br,
    .login-page .text-adaptive br {
      display: none; }
    .login-page h1,
    .login-page h2 {
      font-size: 52px;
      line-height: 62px; }
      .login-page h1.big,
      .login-page h2.big {
        font-size: 62px;
        line-height: 72px; }
      .login-page h1.small,
      .login-page h2.small {
        font-size: 36px;
        line-height: 46px; }
    .login-page h3 {
      font-size: 36px;
      line-height: 46px; }
    .login-page .navigation_mobile {
      display: block; } }
  @media (max-width: 991.98px) {
    .login-page h1,
    .login-page h2 {
      font-size: 42px;
      line-height: 52px; }
      .login-page h1.big,
      .login-page h2.big {
        font-size: 52px;
        line-height: 62px; }
      .login-page h1.small,
      .login-page h2.small {
        font-size: 32px;
        line-height: 42px; }
    .login-page h3 {
      font-size: 32px;
      line-height: 42px; }
    .login-page .text-adaptive.f-24 {
      font-size: 22px;
      line-height: 32px; }
    .login-page .text-adaptive.f-22 {
      font-size: 20px;
      line-height: 30px; }
    .login-page .text-adaptive.f-20 {
      font-size: 18px;
      line-height: 28px; }
    .login-page .alert {
      top: 10px;
      left: 20px;
      right: 20px;
      transform: none;
      width: auto;
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .login-page h1,
    .login-page h2 {
      font-size: 32px;
      line-height: 42px; }
      .login-page h1.big,
      .login-page h2.big {
        font-size: 42px;
        line-height: 52px; }
      .login-page h1.small,
      .login-page h2.small {
        font-size: 26px;
        line-height: 36px; }
    .login-page h3 {
      font-size: 26px;
      line-height: 36px; }
    .login-page .text-adaptive.f-24 {
      font-size: 20px;
      line-height: 30px; }
    .login-page .text-adaptive.f-22 {
      font-size: 18px;
      line-height: 28px; }
    .login-page .text-adaptive.f-20 {
      font-size: 16px;
      line-height: 26px; }
    .login-page .text-adaptive.f-18 {
      font-size: 16px;
      line-height: 26px; }
    .login-page .btn.lg {
      height: 50px;
      line-height: 50px;
      padding: 0 30px;
      font-size: 18px;
      font-weight: 500; }
      .login-page .btn.lg[class^="border-"], .login-page .btn.lg[class*=" border-"] {
        line-height: 46px; }
    .login-page .btn.xl {
      height: 60px;
      line-height: 60px;
      padding: 0 35px;
      font-size: 18px;
      font-weight: 500; }
      .login-page .btn.xl[class^="border-"], .login-page .btn.xl[class*=" border-"] {
        line-height: 56px; }
    .login-page .input.lg {
      height: 50px;
      font-size: 18px;
      font-weight: 500; } }
  @media (max-width: 575.98px) {
    .login-page h1,
    .login-page h2 {
      font-size: 26px;
      line-height: 36px; }
      .login-page h1.big,
      .login-page h2.big {
        font-size: 32px;
        line-height: 42px; }
      .login-page h1.small,
      .login-page h2.small {
        font-size: 22px;
        line-height: 32px; }
    .login-page .btn.xl {
      height: 50px;
      line-height: 50px;
      padding: 0 30px; }
      .login-page .btn.xl[class^="border-"], .login-page .btn.xl[class*=" border-"] {
        line-height: 46px; } }
  @media (max-width: 767.98px) {
    .login-page .header_1 .text-adaptive {
      margin-top: 20px; }
    .login-page .header_1 .buttons {
      margin-top: 40px; } }
  @media (max-width: 767.98px) {
    .login-page .header_2 .text-adaptive {
      margin-top: 20px; }
    .login-page .header_2 form {
      margin-top: 40px; }
      .login-page .header_2 form .input {
        text-align: center;
        max-width: 100%;
        width: 100%; }
      .login-page .header_2 form .btn {
        margin-top: 15px; } }
  @media (max-width: 1199.98px) {
    .login-page .header_3 form {
      margin-top: 40px; } }
  @media (max-width: 575.98px) {
    .login-page .header_3 .input {
      margin-bottom: 20px; } }
  @media (max-width: 767.98px) {
    .login-page .header_4 .poster {
      margin-top: 30px; } }
  .login-page .header_5 .block_with_border {
    border-left: 2px solid rgba(30, 48, 96, 0.3);
    border-right: 2px solid rgba(30, 48, 96, 0.3); }
  @media (max-width: 991.98px) {
    .login-page .header_5 .block_with_border {
      border: none;
      padding: 0 15px; } }
  @media (max-width: 767.98px) {
    .login-page .header_5 .btn {
      margin-top: 30px; }
    .login-page .header_5 img {
      margin-bottom: 20px; } }
  @media (max-width: 767.98px) {
    .login-page .header_6 .btn_holder {
      margin-top: 30px;
      margin-bottom: 30px; } }
  .login-page .header_7 .img_holder img {
    left: 50%;
    top: 0;
    transform: translateX(-50%); }
  @media (max-width: 1199.98px) {
    .login-page .header_7 .inner {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media (max-width: 575.98px) {
    .login-page .header_7 .buttons {
      margin-top: 30px; }
      .login-page .header_7 .buttons .btn {
        margin: 0 auto 10px auto !important;
        display: block;
        max-width: 320px; } }
  .login-page .header_8 .slick-arrow {
    top: 50px; }
  @media (max-width: 1199.98px) {
    .login-page .header_8 .slick-arrow {
      top: 40px; } }
  @media (max-width: 991.98px) {
    .login-page .header_8 .slick-arrow {
      top: 35px; } }
  @media (max-width: 767.98px) {
    .login-page .header_8 .slick-arrow {
      top: 30px; }
    .login-page .header_8 .text-adaptive {
      margin-top: 15px; } }
  @media (max-width: 575.98px) {
    .login-page .header_8 .slider {
      padding: 0; }
    .login-page .header_8 .slick-arrow {
      display: none !important; } }
  @media (max-width: 991.98px) {
    .login-page .header_9 .left {
      padding-top: 40px; } }
  .login-page .header_10 .videos .fa-play {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  @media (max-width: 1199.98px) {
    .login-page .header_10 .btn,
    .login-page .header_10 .videos {
      margin-top: 50px; } }
  @media (max-width: 767.98px) {
    .login-page .header_10 .videos {
      margin-top: 20px; }
      .login-page .header_10 .videos .text-adaptive {
        width: auto;
        margin: 0 auto; }
    .login-page .header_10 .image {
      margin: 0 auto;
      margin-bottom: 15px; } }
  .login-page .header_menu_11 .btn {
    min-width: 80px; }
  @media (max-width: 469.98px) {
    .login-page .header_11 .buttons {
      margin-top: 20px;
      display: block !important; }
      .login-page .header_11 .buttons .btn,
      .login-page .header_11 .buttons .link {
        vertical-align: baseline;
        margin-left: 0;
        margin-top: 20px; } }
  @media (max-width: 991.98px) {
    .login-page .header_12 .logo {
      margin-bottom: 15px; } }
  @media (max-width: 575.98px) {
    .login-page .header_13 .text-adaptive {
      margin-top: 10px; } }
  .login-page .header_14 .separate:before {
    content: " ";
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-bottom: 2px solid #E7EAED; }
  @media (max-width: 991.98px) {
    .login-page .header_14 .text-adaptive {
      margin-top: 10px; }
    .login-page .header_14 a.btn {
      margin-top: 20px; }
    .login-page .header_14 form {
      margin-top: 40px; } }
  @media (max-width: 575.98px) {
    .login-page .header_14 form {
      padding-left: 40px;
      padding-right: 40px; } }
  @media (max-width: 374.98px) {
    .login-page .header_14 form {
      padding-left: 20px;
      padding-right: 20px; } }
  @media (max-width: 359.98px) {
    .login-page .header_14 label {
      font-size: 14px; } }
  .login-page .header_15 .separate {
    position: absolute;
    top: 0;
    width: 2px; }
    .login-page .header_15 .separate:nth-of-type(1) {
      left: 0; }
    .login-page .header_15 .separate:nth-of-type(2) {
      right: 0; }
  @media (max-width: 767.98px) {
    .login-page .header_15 h1 {
      margin-bottom: 15px; }
    .login-page .header_15 .btn {
      margin-top: 30px; }
    .login-page .header_15 .blocks {
      margin-top: 20px; } }
  .login-page .header_16 {
    background-position: 50% 100%;
    overflow: hidden; }
    @media (max-width: 991.98px) {
      .login-page .header_16 .blocks {
        margin-top: 20px; } }
    @media (max-width: 767.98px) {
      .login-page .header_16 .img {
        max-width: 100%;
        margin: 0; }
      .login-page .header_16 .blocks .title {
        margin-top: 15px;
        margin-bottom: 15px; } }
  .login-page .header_19 .slick-dots {
    width: 10px;
    height: auto;
    left: 0;
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .login-page .header_19 .slick-dots li {
      margin: 5px auto; }
      .login-page .header_19 .slick-dots li button {
        border: none;
        background: #E7EAED; }
      .login-page .header_19 .slick-dots li.slick-active button {
        background: #7121F4; }
  @media (max-width: 991.98px) {
    .login-page .header_19 .btn {
      margin-top: 40px; }
    .login-page .header_19 .inner {
      padding-left: 30px; } }
  @media (max-width: 767.98px) {
    .login-page .header_19 .slider {
      margin-top: 30px; }
    .login-page .header_19 .slick-dots {
      width: auto;
      top: auto;
      right: 0;
      bottom: -40px;
      flex-direction: row; }
      .login-page .header_19 .slick-dots li {
        margin: 0 5px; }
    .login-page .header_19 .inner {
      margin-top: 40px;
      padding-left: 0; }
      .login-page .header_19 .inner .text-adaptive {
        margin-top: 15px; } }
  @media (max-width: 767.98px) {
    .login-page .header_20 h1 {
      margin-top: 10px; }
    .login-page .header_20 .text-adaptive {
      margin-top: 15px; } }
  @media (max-width: 575.98px) {
    .login-page .header_20 .img {
      margin-bottom: 40px;
      max-height: 70vh; } }
  @media (max-width: 991.98px) {
    .login-page .header_21 .buttons {
      margin-top: 50px;
      margin-bottom: 20px; } }
  @media (max-width: 767.98px) {
    .login-page .header_22 .text-adaptive {
      margin-top: 10px;
      margin-bottom: 40px; }
    .login-page .header_22 .socials {
      margin-top: 40px; }
      .login-page .header_22 .socials .title {
        margin-bottom: 15px; }
      .login-page .header_22 .socials .btn {
        margin-left: 10px;
        margin-right: 10px; }
        .login-page .header_22 .socials .btn:first-of-type {
          margin-left: 0; }
        .login-page .header_22 .socials .btn:last-of-type {
          margin-right: 0; } }
  @media (max-width: 1199.98px) {
    .login-page .navigation_2 a {
      margin: 0 15px; }
    .login-page .navigation_2 .dropdown a {
      margin: 0; } }
  .login-page .navigation_3 form button:hover {
    background: #FFFFFF !important; }
  @media (max-width: 1199.98px) {
    .login-page .navigation_4 a {
      margin: 0 10px; } }
  @media (max-width: 575.98px) {
    .login-page .navigation_8 a {
      margin: 0 10px; } }
  @media (max-width: 767.98px) {
    .login-page .navigation_9 a {
      margin: 0 10px; } }
  .login-page .navigation_13 .link {
    border-bottom: 2px solid transparent; }
    .login-page .navigation_13 .link:hover {
      border-bottom-color: #7121F4; }
  @media (max-width: 991.98px) {
    .login-page .navigation_14 a,
    .login-page .navigation_17 a,
    .login-page .navigation_19 a,
    .login-page .navigation_20 a,
    .login-page .navigation_21 a {
      margin: 0 10px; } }
  @media (max-width: 991.98px) {
    .login-page .navigation_18 a,
    .login-page .navigation_18 form {
      margin-left: 10px;
      margin-right: 10px; } }
  .login-page .navigation_22 .banner {
    overflow: hidden; }
  .login-page .navigation_22 .img {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    min-width: 100%;
    min-height: 100%; }
  .login-page .navigation_22 .navigation_popup .link {
    padding: 5px 15px; }
    .login-page .navigation_22 .navigation_popup .link:hover {
      background: #E7EAED;
      color: #1e3060; }
  .login-page .navigation_22 .navigation_popup .inner {
    top: 0;
    left: 0; }
  @media (max-width: 399.98px) {
    .login-page .navigation_22 .inner {
      margin-left: 40px; } }
  .login-page .navigation_23 form button:hover {
    background: #FFFFFF !important; }
  .login-page .navigation_23 .navigation_popup .slider .inner {
    top: 0;
    left: 0; }
  .login-page .navigation_23 .navigation_popup .slider .slick-dots {
    bottom: -30px; }
    .login-page .navigation_23 .navigation_popup .slider .slick-dots li.slick-active button {
      background: #0074f1; }
    .login-page .navigation_23 .navigation_popup .slider .slick-dots li button {
      opacity: 1;
      background: #E7EAED;
      border: none; }
  .login-page .navigation_23 .navigation_popup .link:hover img {
    opacity: 0.7; }
  .login-page .navigation_23 .navigation_popup .link img {
    transition: opacity 0.25s; }
  @media (max-width: 991.98px) {
    .login-page .navigation_23 .navigation_popup .categories .link {
      margin: 0 10px; } }
  @media (max-width: 469.98px) {
    .login-page .navigation_23 .slide .inner {
      padding: 30px; }
      .login-page .navigation_23 .slide .inner h2 {
        font-size: 26px;
        line-height: 36px; } }
  @media (max-width: 359.98px) {
    .login-page .navigation_23 .slide .inner h2 {
      font-size: 22px;
      line-height: 32px; } }
  .login-page .navigation_24 .navigation_popup .link:hover img {
    opacity: 0.7; }
  .login-page .navigation_24 .navigation_popup .link img {
    transition: opacity 0.25s; }
  .login-page .navigation_25 .navigation_popup {
    border-color: rgba(255, 255, 255, 0.3); }
  .login-page .navigation_26 .navigation_popup {
    border-color: rgba(255, 255, 255, 0.3); }
  .login-page .navigation_26 .categories .link {
    color: rgba(255, 255, 255, 0.7); }
    .login-page .navigation_26 .categories .link:hover {
      background: rgba(255, 255, 255, 0.3);
      color: rgba(255, 255, 255, 0.7); }
  .login-page .navigation_26 .slider {
    overflow: hidden; }
    .login-page .navigation_26 .slider img {
      min-height: 250px;
      min-width: 100%; }
    .login-page .navigation_26 .slider .inner {
      top: 0;
      left: 0; }
    .login-page .navigation_26 .slider .slick-dots {
      left: 20px;
      right: auto;
      bottom: 25px; }
      .login-page .navigation_26 .slider .slick-dots li.slick-active button {
        background: #1e3060; }
      .login-page .navigation_26 .slider .slick-dots li button {
        background: transparent;
        border: 2px solid #1e3060; }
  .login-page .navigation_26 .right_block {
    overflow: hidden; }
    .login-page .navigation_26 .right_block .bg {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%; }
  @media (max-width: 1199.98px) {
    .login-page .navigation_26 .categories .title {
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    .login-page .content_1 h2 {
      margin-top: 10px; } }
  @media (max-width: 991.98px) {
    .login-page .content_2 .text-adaptive {
      margin-top: 15px; } }
  @media (max-width: 767.98px) {
    .login-page .content_3 .blocks {
      margin-top: 10px; } }
  @media (max-width: 767.98px) {
    .login-page .content_4 .icons .fa {
      margin: 0 15px; }
    .login-page .content_4 h2 {
      margin-top: 30px; }
    .login-page .content_4 .text-adaptive {
      margin-top: 15px; } }
  @media (max-width: 767.98px) {
    .login-page .content_5 h2 {
      margin-top: 15px; } }
  @media (max-width: 991.98px) {
    .login-page .content_6 .text-adaptive {
      margin-top: 15px; } }
  .login-page .content_13 .inner {
    border: 2px solid #E7EAED; }
  @media (max-width: 767.98px) {
    .login-page .content_13 .inner {
      padding-top: 30px;
      padding-bottom: 30px; }
    .login-page .content_13 h2 {
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    .login-page .content_14 .inner {
      padding-top: 30px;
      padding-bottom: 30px; } }
  @media (max-width: 1199.98px) {
    .login-page .content_15 .container {
      padding-top: 40px; } }
  @media (max-width: 767.98px) {
    .login-page .content_15 .text-adaptive {
      margin-top: 10px; } }
  @media (max-width: 1199.98px) {
    .login-page .content_16 h2,
    .login-page .content_16 .text-adaptive {
      padding-left: 0; } }
  @media (max-width: 767.98px) {
    .login-page .content_16 .text-adaptive {
      margin-top: 5px; } }
  @media (max-width: 359.98px) {
    .login-page .content_17 .block_tip {
      padding-left: 20px;
      padding-right: 20px; } }
  .login-page .content_18 .opening_tabs .tab_opener .fa,
  .login-page .content_18 .opening_tabs .tab_opener .fas,
  .login-page .content_18 .opening_tabs .tab_opener .far,
  .login-page .content_18 .opening_tabs .tab_opener .fab {
    transition: all 0.2s; }
  .login-page .content_18 .opening_tabs .tab_opener.opened .fa,
  .login-page .content_18 .opening_tabs .tab_opener.opened .fas,
  .login-page .content_18 .opening_tabs .tab_opener.opened .far,
  .login-page .content_18 .opening_tabs .tab_opener.opened .fab {
    transform: rotate(90deg); }
  .login-page .content_18 .opening_tabs .tab_text {
    border-bottom: 2px solid #E7EAED; }
    .login-page .content_18 .opening_tabs .tab_text:last-child {
      border: none; }
  @media (max-width: 991.98px) {
    .login-page .content_18 .block_right {
      margin-left: 0;
      width: auto; } }
  .login-page .content_20 .number {
    position: absolute;
    top: 0;
    left: 0; }
  .login-page .content_20 .content_20_line {
    position: absolute;
    top: 0;
    left: 19px;
    width: 2px;
    height: 100%;
    background: #E7EAED; }
  .login-page .content_20 .video_block .fa,
  .login-page .content_20 .video_block .fas,
  .login-page .content_20 .video_block .far,
  .login-page .content_20 .video_block .fab {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  @media (max-width: 575.98px) {
    .login-page .content_20 .video_block .inner {
      margin-left: 0;
      margin-top: 30px; } }
  @media (max-width: 374.98px) {
    .login-page .content_20 .number {
      position: static; }
    .login-page .content_20 .block_inner {
      margin-left: 0; }
    .login-page .content_20 .content_20_line {
      display: none; } }
  @media (max-width: 767.98px) {
    .login-page .content_21[class^="pt-"], .login-page .content_21[class*=" pt-"] {
      padding-top: 40px; }
    .login-page .content_21[class^="pb-"], .login-page .content_21[class*=" pb-"] {
      padding-bottom: 40px; } }
  .login-page .content_22 {
    min-height: 100vh; }
    @media (max-width: 1199.98px) {
      .login-page .content_22[class^="pb-"], .login-page .content_22[class*=" pb-"] {
        padding-bottom: 30px; } }
  @media (max-width: 767.98px) {
    .login-page .content_24[class^="pt-"], .login-page .content_24[class*=" pt-"] {
      padding-top: 40px; }
    .login-page .content_24[class^="pb-"], .login-page .content_24[class*=" pb-"] {
      padding-bottom: 40px; }
    .login-page .content_24 .tip {
      margin-top: 10px;
      margin-left: 10px; } }
  .login-page .content_25 {
    min-height: 100vh; }
  @media (max-width: 991.98px) {
    .login-page .content_26 .btn {
      margin-top: 30px; } }
  .login-page .content_27 .image .fa,
  .login-page .content_27 .image .fas,
  .login-page .content_27 .image .far,
  .login-page .content_27 .image .fab {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  @media (max-width: 767.98px) {
    .login-page .content_27 .poster {
      margin-bottom: 20px; } }
  @media (max-width: 575.98px) {
    .login-page .content_27 .image {
      margin-left: auto;
      margin-right: auto; } }
  @media (max-width: 767.98px) {
    .login-page .content_29[class^="pb-"], .login-page .content_29[class*=" pb-"] {
      padding-bottom: 60px; }
    .login-page .content_29 .holder {
      margin-bottom: 15px; } }
  .login-page .content_30 .block {
    background: rgba(231, 234, 237, 0.4); }
    .login-page .content_30 .block img {
      max-width: 70%;
      max-height: 70%; }
  .login-page .content_31 .block {
    border: 2px solid rgba(255, 255, 255, 0.15); }
    .login-page .content_31 .block img {
      max-width: 70%;
      max-height: 70%; }
  @media (max-width: 767.98px) {
    .login-page .content_31 .text-adaptive {
      margin-top: 10px;
      margin-bottom: 30px; } }
  .login-page .content_32 .left .cell {
    padding: 22px 30px 20px 30px; }
    .login-page .content_32 .left .cell.bordered {
      border-bottom: 2px solid #E7EAED; }
  .login-page .content_32 .right {
    background: rgba(231, 234, 237, 0.6); }
  .login-page .content_33 .table td,
  .login-page .content_33 .table th {
    padding: 24px 15px 20px 15px; }
  .login-page .content_33 .table tr td:first-of-type,
  .login-page .content_33 .table tr th:first-of-type {
    padding-left: 30px; }
  .login-page .content_33 .table tr td:last-of-type,
  .login-page .content_33 .table tr th:last-of-type {
    padding-right: 30px; }
  @media (max-width: 1199.98px) {
    .login-page .content_33 .table-responsive-shadow {
      position: relative; }
      .login-page .content_33 .table-responsive-shadow:after {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 40px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 78%); } }
  .login-page .content_34 #copy_from_me {
    background: rgba(231, 234, 237, 0.5);
    font-family: Courier New, monospace; }
    .login-page .content_34 #copy_from_me pre {
      color: #1e3060; }
  @media (max-width: 992.98px) {
    .login-page .content_34 .right {
      margin-top: 50px;
      padding: 0; } }
  @media (max-width: 767.98px) {
    .login-page .testimonial_2 .text-adaptive {
      margin-top: 10px;
      margin-bottom: 25px; } }
  @media (max-width: 1199.98px) {
    .login-page .testimonial_3[class^="pb-"], .login-page .testimonial_3[class*=" pb-"] {
      padding-bottom: 20px; } }
  @media (max-width: 767.98px) {
    .login-page .testimonial_3 h2,
    .login-page .testimonial_3 .block {
      margin-bottom: 30px; } }
  .login-page .testimonial_4 .block {
    border: 2px solid #E7EAED; }
  @media (max-width: 991.98px) {
    .login-page .testimonial_4 .holder {
      max-width: 570px; } }
  @media (max-width: 767.98px) {
    .login-page .testimonial_4 .block {
      padding: 30px; }
    .login-page .testimonial_4 .text-adaptive {
      margin-bottom: 20px; } }
  @media (max-width: 575.98px) {
    .login-page .testimonial_4 .inner {
      margin-left: 0; } }
  @media (max-width: 1199.98px) {
    .login-page .testimonial_5[class^="pb-"], .login-page .testimonial_5[class*=" pb-"] {
      padding-bottom: 30px; } }
  .login-page .testimonial_6 .block {
    border: 2px solid rgba(255, 255, 255, 0.15); }
  .login-page .testimonial_6 .author {
    left: 0;
    bottom: 0; }
  @media (max-width: 575.98px) {
    .login-page .testimonial_6 .inner {
      padding-left: 20px;
      padding-bottom: 0; }
    .login-page .testimonial_6 .info {
      margin-left: 0; }
    .login-page .testimonial_6 .author {
      margin-top: 10px;
      position: static; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_1[class^="pb-"], .login-page .feature_1[class*=" pb-"] {
      padding-bottom: 30px; } }
  @media (max-width: 767.98px) {
    .login-page .feature_1 .title {
      margin-top: 10px;
      margin-bottom: 10px; }
      .login-page .feature_1 .title br {
        display: none; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_2[class^="pb-"], .login-page .feature_2[class*=" pb-"] {
      padding-bottom: 30px; }
    .login-page .feature_2 .description {
      margin-top: 20px; } }
  .login-page .feature_3 .block {
    border: 2px solid #E7EAED; }
  @media (max-width: 1199.98px) {
    .login-page .feature_3[class^="pb-"], .login-page .feature_3[class*=" pb-"] {
      padding-bottom: 50px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_3 .description {
      margin-top: 10px;
      margin-bottom: 40px; } }
  .login-page .feature_4 .block {
    border: 2px solid rgba(255, 255, 255, 0.3); }
  @media (max-width: 1199.98px) {
    .login-page .feature_4[class^="pb-"], .login-page .feature_4[class*=" pb-"] {
      padding-bottom: 50px; }
    .login-page .feature_4 .description {
      margin-top: 10px;
      margin-bottom: 40px; }
    .login-page .feature_4 .block {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 50px; } }
  .login-page .feature_5 .block {
    border: 2px solid #E7EAED;
    border-top: none; }
  @media (max-width: 1199.98px) {
    .login-page .feature_5[class^="pb-"], .login-page .feature_5[class*=" pb-"] {
      padding-bottom: 30px; }
    .login-page .feature_5 .description {
      margin-top: 10px; }
    .login-page .feature_5 .block {
      padding: 30px; }
      .login-page .feature_5 .block .title {
        margin-bottom: 10px; } }
  @media (max-width: 575.98px) {
    .login-page .feature_5 .holder {
      max-width: 370px; } }
  .login-page .feature_6 .block_bordered {
    border: 2px solid rgba(255, 255, 255, 0.2); }
  @media (max-width: 991.98px) {
    .login-page .feature_6 .block {
      margin-top: 40px; }
    .login-page .feature_6 .title {
      margin-top: 15px;
      margin-bottom: 10px; }
    .login-page .feature_6 .block_bordered {
      margin-top: 50px;
      padding: 30px; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_7[class^="pb-"], .login-page .feature_7[class*=" pb-"] {
      padding-bottom: 20px; } }
  @media (max-width: 767.98px) {
    .login-page .feature_7 h2 {
      margin-bottom: 40px; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_8[class^="pb-"], .login-page .feature_8[class*=" pb-"] {
      padding-bottom: 40px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_8 .icon {
      margin-left: 0;
      width: 25px; }
    .login-page .feature_8 .title {
      margin-bottom: 10px; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_9[class^="pb-"], .login-page .feature_9[class*=" pb-"] {
      padding-bottom: 40px; }
    .login-page .feature_9 h2 {
      margin-bottom: 15px; }
    .login-page .feature_9 .title {
      margin-bottom: 10px; }
    .login-page .feature_9 .hr {
      margin-bottom: 45px; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_10[class^="pb-"], .login-page .feature_10[class*=" pb-"] {
      padding-bottom: 40px; } }
  @media (max-width: 767.98px) {
    .login-page .feature_10 h2 {
      margin-bottom: 10px; } }
  .login-page .feature_11 {
    overflow: hidden; }
    @media (max-width: 992.98px) {
      .login-page .feature_11 .bg {
        max-height: 100vh; }
      .login-page .feature_11 .description {
        margin-top: 10px;
        margin-bottom: 50px; } }
    @media (max-width: 575.98px) {
      .login-page .feature_11 .bg {
        margin-top: 50px;
        max-height: 70vh;
        max-width: 100%; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_12[class^="pb-"], .login-page .feature_12[class*=" pb-"] {
      padding-bottom: 0px; }
    .login-page .feature_12 .icon {
      margin-left: 0; } }
  @media (max-width: 991.98px) {
    .login-page .feature_12 .title {
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    .login-page .feature_12 h2 {
      margin: 10px 0; }
    .login-page .feature_12 .icon {
      margin: 0 auto 10px auto; }
    .login-page .feature_12 .bg {
      margin-top: 50px; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_13[class^="pb-"], .login-page .feature_13[class*=" pb-"] {
      padding-bottom: 40px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_14[class^="pb-"], .login-page .feature_14[class*=" pb-"] {
      padding-bottom: 40px; }
    .login-page .feature_14 h2 {
      margin-bottom: 0; }
    .login-page .feature_14 .main_title {
      margin-top: 10px; }
    .login-page .feature_14 .bg {
      max-height: 80vh;
      margin-bottom: 50px; }
    .login-page .feature_14 .block {
      margin-bottom: 40px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_15 h2 {
      margin: 0px 0 10px 0; }
    .login-page .feature_15 .block {
      margin-top: 40px; }
    .login-page .feature_15 .title {
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    .login-page .feature_15 .bg {
      margin-top: 50px;
      max-height: 80vh; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_16[class^="pb-"], .login-page .feature_16[class*=" pb-"] {
      padding-bottom: 30px; } }
  @media (max-width: 575.98px) {
    .login-page .feature_16 h2 {
      margin-bottom: 10px; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_17[class^="pb-"], .login-page .feature_17[class*=" pb-"] {
      padding-bottom: 30px; }
    .login-page .feature_17 h6 {
      margin-top: 10px;
      margin-bottom: 50px; }
      .login-page .feature_17 h6 br {
        display: none; } }
  @media (max-width: 767.98px) {
    .login-page .feature_17 .img {
      margin-bottom: 40px;
      max-height: 70vh; }
    .login-page .feature_17 .title {
      margin-bottom: 10px; }
    .login-page .feature_17 .block {
      margin-bottom: 40px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_18 .description {
      margin-top: 10px;
      margin-bottom: 40px; }
    .login-page .feature_18 .block {
      margin-bottom: 40px; } }
  @media (max-width: 767.98px) {
    .login-page .feature_18 .description {
      margin-bottom: 0; }
    .login-page .feature_18 .img {
      max-height: 70vh; } }
  @media (max-width: 767.98px) {
    .login-page .feature_19 .icons {
      margin-bottom: 30px;
      font-size: 26px;
      line-height: 36px; }
    .login-page .feature_19 .btn {
      margin-top: 30px; } }
  .login-page .feature_20 .bg {
    top: 0;
    left: 50%;
    transform: translateX(-50%); }
  @media (max-width: 1199.98px) {
    .login-page .feature_20 .left_side {
      padding-top: 80px;
      padding-bottom: 40px; }
    .login-page .feature_20 h2 {
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    .login-page .feature_20 .btn {
      margin-bottom: 40px; }
    .login-page .feature_20 .title {
      margin: 10px 0; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_21[class^="pb-"], .login-page .feature_21[class*=" pb-"] {
      padding-bottom: 30px; }
    .login-page .feature_21 .block {
      margin-bottom: 50px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_21 .description {
      margin-top: 10px;
      margin-bottom: 50px; } }
  .login-page .feature_22 .buttons .btn {
    min-width: 200px; }
  @media (max-width: 991.98px) {
    .login-page .feature_22[class^="pb-"], .login-page .feature_22[class*=" pb-"] {
      padding-bottom: 40px; }
    .login-page .feature_22 .description {
      margin-top: 10px;
      margin-bottom: 40px; }
    .login-page .feature_22 .icon i {
      font-size: 32px;
      line-height: 42px; }
    .login-page .feature_22 .title {
      margin-bottom: 10px; }
    .login-page .feature_22 .block {
      margin-bottom: 40px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_23 .description {
      margin-top: 10px;
      margin-bottom: 40px; } }
  @media (max-width: 767.98px) {
    .login-page .feature_23 .info {
      margin-left: 0; } }
  .login-page .feature_25 .bg {
    top: 0;
    left: 50%;
    transform: translateX(-50%); }
  @media (max-width: 1199.98px) {
    .login-page .feature_25 .left_side {
      padding-top: 80px;
      padding-bottom: 40px; }
    .login-page .feature_25 .description {
      margin: 10px 0 50px 0; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_26 .link {
      margin-bottom: 40px; } }
  @media (max-width: 575.98px) {
    .login-page .feature_26[class^="pb-"], .login-page .feature_26[class*=" pb-"] {
      padding-bottom: 40px; }
    .login-page .feature_26 .img {
      max-height: 70vh; }
    .login-page .feature_26 h2 {
      margin-bottom: 10px; }
    .login-page .feature_26 .title {
      margin-bottom: 10px; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_27[class^="pb-"], .login-page .feature_27[class*=" pb-"] {
      padding-bottom: 30px; } }
  @media (max-width: 767.98px) {
    .login-page .feature_27 .description {
      margin: 10px 0 50px 0; } }
  @media (max-width: 575.98px) {
    .login-page .feature_28 .description {
      margin: 10px 0 40px 0; }
    .login-page .feature_28 .img {
      margin-top: 40px; } }
  .login-page .feature_29 .slick-slide {
    cursor: grab; }
  .login-page .feature_29 .slick-dots {
    left: 0;
    bottom: auto;
    top: 50%;
    width: 10px;
    margin-top: -35px; }
    .login-page .feature_29 .slick-dots li {
      margin: 5px; }
  @media (max-width: 1199.98px) {
    .login-page .feature_29 .slick-slide {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_29 .slick-dots {
      top: auto;
      margin-top: 0;
      width: 100%;
      bottom: 30px; }
    .login-page .feature_29 h2 {
      margin-bottom: 10px; }
    .login-page .feature_29 .title {
      margin: 10px 0; } }
  @media (max-width: 767.98px) {
    .login-page .feature_29 .img {
      max-height: 70vh;
      margin-bottom: 50px; } }
  .login-page .feature_31 .img {
    top: 0;
    right: 15px; }
  .login-page .feature_31 .slick-slide {
    cursor: grab; }
  .login-page .feature_31 .slick-dots {
    left: auto;
    right: calc((100% - 1170px) / 2);
    bottom: auto;
    top: 50%;
    width: 10px;
    margin-top: -35px; }
    .login-page .feature_31 .slick-dots li {
      margin: 5px; }
  @media (max-width: 1199.98px) {
    .login-page .feature_31 .slick-dots {
      right: calc((100% - 970px) / 2); }
    .login-page .feature_31 .slick-slide {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_31 .slick-dots {
      right: calc((100% - 750px) / 2); } }
  @media (max-width: 767.98px) {
    .login-page .feature_31 .slick-dots {
      top: auto;
      bottom: 30px;
      left: 0;
      right: 0;
      width: auto; }
    .login-page .feature_31 .slick-slide {
      padding-bottom: 40px; }
    .login-page .feature_31 .right_side {
      padding-bottom: 0; }
    .login-page .feature_31 .img {
      position: static;
      height: auto;
      max-width: 100%; }
    .login-page .feature_31 .title {
      margin-bottom: 10px; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_32 .block {
      margin-left: 20px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_32 .decription {
      margin: 10px 0 40px 0; }
    .login-page .feature_32 .left_side,
    .login-page .feature_32 .right_side {
      margin-top: 0; }
    .login-page .feature_32 .block {
      margin-left: 0; } }
  @media (max-width: 991.98px) {
    .login-page .feature_33 .description {
      margin-top: 10px; }
    .login-page .feature_33 .title {
      margin-bottom: 0px; } }
  .login-page .feature_34 {
    overflow: hidden; }
    @media (max-width: 767.98px) {
      .login-page .feature_34[class^="pb-"], .login-page .feature_34[class*=" pb-"] {
        padding-bottom: 30px; }
      .login-page .feature_34 .title {
        margin: 10px 0; } }
    @media (max-width: 575.98px) {
      .login-page .feature_34 h2 {
        margin-bottom: 10px; }
      .login-page .feature_34 .img {
        max-width: 100%; }
      .login-page .feature_34 .blocks {
        margin-top: 50px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_36 h2 {
      margin-top: 0;
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    .login-page .feature_36 .img {
      max-height: 70vh; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_37[class^="pb-"], .login-page .feature_37[class*=" pb-"] {
      padding-bottom: 40px; }
    .login-page .feature_37 h2 {
      margin-bottom: 10px; }
    .login-page .feature_37 .advantage {
      margin-bottom: 40px; } }
  .login-page .feature_38 {
    background-position: 50% 100%;
    overflow: hidden;
    padding-bottom: 370px; }
    .login-page .feature_38 .img {
      top: 70px;
      left: 50%;
      transform: translateX(-44%); }
    @media (max-width: 1199.98px) {
      .login-page .feature_38 {
        padding-bottom: 300px; }
        .login-page .feature_38 .img {
          height: 150%; } }
    @media (max-width: 991.98px) {
      .login-page .feature_38 {
        padding-bottom: 250px; }
        .login-page .feature_38 .img {
          top: 200px;
          height: 125%; } }
    @media (max-width: 767.98px) {
      .login-page .feature_38 h2 {
        margin-bottom: 10px; }
      .login-page .feature_38 .link {
        margin-top: 20px; } }
    @media (max-width: 575.98px) {
      .login-page .feature_38 {
        padding-bottom: 200px; }
        .login-page .feature_38 .img {
          top: 50%;
          height: 100%; }
        .login-page .feature_38 .link {
          font-size: 16px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_39 .description {
      margin: 10px 0 0 0; }
    .login-page .feature_39 .advantage {
      margin-top: 40px; } }
  @media (max-width: 767.98px) {
    .login-page .feature_40[class^="pb-"], .login-page .feature_40[class*=" pb-"] {
      padding-bottom: 45px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_41 .description {
      margin: 10px 0 40px 0; }
    .login-page .feature_41 .inner {
      padding-bottom: 60px; } }
  .login-page .feature_42 .bg {
    top: 0;
    left: 50%;
    transform: translateX(-50%); }
  @media (max-width: 575.98px) {
    .login-page .feature_42 .inner {
      padding-top: 80px;
      padding-bottom: 60px; }
    .login-page .feature_42 h2 {
      margin-bottom: 10px; }
    .login-page .feature_42 .hr {
      margin: 40px 0 0 0; } }
  .login-page .feature_43 .bg {
    top: 0;
    left: 50%;
    transform: translateX(-50%); }
  @media (max-width: 575.98px) {
    .login-page .feature_43 .inner {
      padding-top: 80px;
      padding-bottom: 80px; }
    .login-page .feature_43 h2 {
      margin-bottom: 10px; } }
  .login-page .feature_44 {
    overflow: hidden; }
    @media (max-width: 767.98px) {
      .login-page .feature_44 h2 {
        margin-top: 0;
        margin-bottom: 10px; }
      .login-page .feature_44 .img {
        margin-top: 40px;
        max-width: 100%; }
      .login-page .feature_44 .row2 {
        margin-top: 50px; }
      .login-page .feature_44 .inner2 {
        padding-bottom: 0; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_45[class^="pb-"], .login-page .feature_45[class*=" pb-"] {
      padding-bottom: 35px; } }
  @media (max-width: 575.98px) {
    .login-page .feature_45 h2,
    .login-page .feature_45 .title {
      margin-bottom: 10px; }
    .login-page .feature_45 .description {
      margin-bottom: 40px; } }
  .login-page .feature_46 .block:hover, .login-page .feature_46 .block:focus {
    opacity: 0.7; }
  @media (max-width: 991.98px) {
    .login-page .feature_46 h2 {
      margin-bottom: 10px; }
    .login-page .feature_46 .block {
      margin-bottom: 50px; } }
  .login-page .feature_47 .block {
    border: 2px solid rgba(255, 255, 255, 0.2); }
  @media (max-width: 1199.98px) {
    .login-page .feature_47[class^="pb-"], .login-page .feature_47[class*=" pb-"] {
      padding-bottom: 50px; }
    .login-page .feature_47 h2 {
      margin-bottom: 30px; }
    .login-page .feature_47 .block {
      padding: 30px; } }
  @media (max-width: 467.98px) {
    .login-page .form_1 h2 {
      margin-bottom: 20px; }
    .login-page .form_1 form {
      padding: 30px; } }
  @media (max-width: 359.98px) {
    .login-page .form_1 form {
      padding: 30px 20px; } }
  .login-page .form_2 .img_holder img {
    left: 50%;
    top: 0;
    transform: translateX(-50%); }
  @media (max-width: 1199.98px) {
    .login-page .form_2 form {
      padding: 80px 0; }
    .login-page .form_2 .buttons {
      margin-top: 10px; }
    .login-page .form_2 button {
      margin-right: 0px;
      width: 100%; } }
  @media (max-width: 575.98px) {
    .login-page .form_2 form {
      max-width: 290px;
      margin: 0 auto; } }
  .login-page .form_4 {
    background-image: url("/images/form_4_bg@2x.jpg"); }
    .login-page .form_4 .form_4_menu {
      display: grid;
      grid-template-columns: 1fr 1fr; }
      .login-page .form_4 .form_4_menu.forgot-password {
        display: flex;
        justify-content: center; }
        .login-page .form_4 .form_4_menu.forgot-password .link {
          border-bottom-color: #e7eaed !important; }
      .login-page .form_4 .form_4_menu .link {
        border-bottom: 3px solid #e7eaed;
        transition: all .2s ease;
        cursor: pointer; }
        .login-page .form_4 .form_4_menu .link.active {
          border-bottom-color: #1e3060; }
      .login-page .form_4 .form_4_menu .slick-track {
        position: relative;
        border-bottom: 3px solid #E7EAED; }
      .login-page .form_4 .form_4_menu a {
        width: 50%; }
        .login-page .form_4 .form_4_menu a:after {
          content: "";
          display: block;
          width: 0%;
          height: 3px;
          background: #1e3060;
          position: absolute;
          right: 0;
          bottom: -3px;
          transition: width 0.2s;
          z-index: 10000; }
        .login-page .form_4 .form_4_menu a:last-child:after {
          left: 0;
          right: auto; }
        .login-page .form_4 .form_4_menu a.slick-current {
          color: #1e3060; }
          .login-page .form_4 .form_4_menu a.slick-current:after {
            width: 100%; }
    .login-page .form_4 .separate {
      position: relative; }
      .login-page .form_4 .separate:before {
        content: " ";
        width: 100%;
        position: absolute;
        left: 0;
        top: 50%;
        border-bottom: 1px solid #E7EAED; }
    @media (max-width: 575.98px) {
      .login-page .form_4 h2 {
        margin-bottom: 10px; }
      .login-page .form_4 .form_4_menu a {
        line-height: 60px; }
      .login-page .form_4 .slider {
        margin-top: 40px;
        padding-bottom: 40px; } }
  @media (max-width: 767.98px) {
    .login-page .form_6 form {
      padding-top: 40px; }
    .login-page .form_6 h2 {
      margin-bottom: 30px; }
    .login-page .form_6 .hr {
      margin-top: 30px; } }
  .login-page .form_7 .right_side {
    border: 2px solid rgba(255, 255, 255, 0.2); }
  @media (max-width: 767.98px) {
    .login-page .form_7 h2 {
      margin-bottom: 10px; }
    .login-page .form_7 .right_side {
      margin-top: 50px;
      padding: 30px; } }
  @media (max-width: 991.98px) {
    .login-page .form_9 form {
      margin-top: 40px; }
    .login-page .form_9 .input {
      width: 100%;
      margin-right: 0; } }
  @media (max-width: 767.98px) {
    .login-page .form_10 .icons {
      font-size: 32px;
      line-height: 42px; }
      .login-page .form_10 .icons i {
        margin: 0 15px; }
    .login-page .form_10 h2 {
      margin-bottom: 10px; }
    .login-page .form_10 form {
      margin-top: 40px; }
    .login-page .form_10 .input {
      width: 100%;
      margin-right: 0; } }
  .login-page .form_11 form {
    border: 2px solid #E7EAED; }
  @media (max-width: 767.98px) {
    .login-page .form_11 form {
      padding: 50px 30px; }
    .login-page .form_11 .input {
      width: 100%;
      margin-right: 0; } }
  .login-page .form_13 .js-form-block {
    border: 2px solid #E7EAED;
    transition: all 0.2s; }
    .login-page .form_13 .js-form-block.active {
      border-color: #0074f1; }
  .login-page .form_13 .icons {
    top: 25px;
    right: 30px;
    line-height: 1; }
  @media (max-width: 991.98px) {
    .login-page .form_13 .block {
      width: 250px; } }
  @media (max-width: 767.98px) {
    .login-page .form_13 h2 {
      margin-bottom: 30px; }
    .login-page .form_13 .js-form-block {
      padding: 30px; }
    .login-page .form_13 .icons {
      font-size: 32px; }
    .login-page .form_13 .block {
      margin-right: 0;
      width: 100%; } }
  @media (max-width: 469.98px) {
    .login-page .form_13 .inner,
    .login-page .form_13 .text-adaptive {
      margin-left: 0; } }
  .login-page .form_14 form {
    border: 2px solid #E7EAED; }
  @media (max-width: 575.98px) {
    .login-page .form_14 form {
      padding: 50px 30px; } }
  .login-page .form_15 .form_15_menu .slick-track {
    position: relative;
    border-bottom: 3px solid #E7EAED; }
  .login-page .form_15 .form_15_menu a {
    width: 50%; }
    .login-page .form_15 .form_15_menu a:after {
      content: "";
      display: block;
      width: 0%;
      height: 3px;
      background: #1e3060;
      position: absolute;
      right: 0;
      bottom: -3px;
      transition: width 0.2s;
      z-index: 10000; }
    .login-page .form_15 .form_15_menu a:last-child:after {
      left: 0;
      right: auto; }
    .login-page .form_15 .form_15_menu a.slick-current {
      color: #1e3060; }
      .login-page .form_15 .form_15_menu a.slick-current:after {
        width: 100%; }
  @media (max-width: 575.98px) {
    .login-page .form_15 h2 {
      margin-bottom: 30px; }
    .login-page .form_15 .form_15_menu a {
      line-height: 60px; }
    .login-page .form_15 .slider {
      margin-top: 40px;
      padding: 0 20px 40px 20px; } }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_1 .description {
      margin: 10px 0 30px 0; }
    .login-page .call_to_action_1 .btn {
      margin-bottom: 15px; } }
  .login-page .call_to_action_2 .inner {
    border: 2px solid #E7EAED; }
  @media (max-width: 991.98px) {
    .login-page .call_to_action_2 .inner {
      padding: 40px 30px; } }
  @media (max-width: 575.98px) {
    .login-page .call_to_action_2 .inner {
      padding: 30px 20px; } }
  @media (max-width: 575.98px) {
    .login-page .call_to_action_3 .inner {
      padding: 50px 30px; } }
  @media (max-width: 991.98px) {
    .login-page .call_to_action_4 .text-adaptive {
      margin-top: 10px; } }
  .login-page .call_to_action_5 .inner {
    min-height: 100vh; }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_7 h2 {
      margin-bottom: 10px; }
    .login-page .call_to_action_7 .btn {
      margin-top: 30px; } }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_8 h2 {
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_9 .btn {
      margin-top: 30px; } }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_10 h2 {
      margin-bottom: 0; } }
  .login-page .call_to_action_12 .btn {
    height: auto; }
  @media (max-width: 575.98px) {
    .login-page .call_to_action_13 .btn {
      margin: 20px 10px 0 10px; } }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_14 h2 {
      margin-bottom: 10px; }
    .login-page .call_to_action_14 .title {
      margin: 15px 0 10px 0; }
    .login-page .call_to_action_14 .btn {
      margin-top: 25px; } }
  .login-page .call_to_action_15 .inner {
    border: 2px solid #E7EAED; }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_15 h2 {
      margin: 10px 0; }
    .login-page .call_to_action_15 .inner {
      padding-top: 30px;
      padding-bottom: 40px; } }
  @media (max-width: 575.98px) {
    .login-page .call_to_action_16 .btn {
      margin-left: 10px;
      margin-right: 10px; } }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_17 .buttons {
      font-size: 32px;
      line-height: 42px; }
      .login-page .call_to_action_17 .buttons a {
        margin: 0 10px 20px 10px; } }
  .login-page .call_to_action_19 .link {
    display: flex;
    width: 20%; }
    .login-page .call_to_action_19 .link:hover i {
      transform: scale(1.1); }
    .login-page .call_to_action_19 .link i {
      transition: all 0.2s; }
  .login-page .call_to_action_19 .border_right {
    border-right: 2px solid #E7EAED; }
  @media (max-width: 1199.98px) {
    .login-page .call_to_action_19 .inner {
      height: 200px; } }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_19 .inner {
      height: 140px; }
      .login-page .call_to_action_19 .inner i {
        font-size: 36px; } }
  @media (max-width: 575.98px) {
    .login-page .call_to_action_19 .inner {
      height: 100px; }
      .login-page .call_to_action_19 .inner i {
        font-size: 28px; } }
  @media (max-width: 1199.98px) {
    .login-page .call_to_action_20[class^="pb-"], .login-page .call_to_action_20[class*=" pb-"] {
      padding-bottom: 50px; } }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_21 .text-adaptive {
      margin-top: 10px;
      margin-bottom: 40px; }
    .login-page .call_to_action_21 .play {
      width: 60px;
      height: 60px;
      line-height: 60px; } }
  @media (max-width: 575.98px) {
    .login-page .call_to_action_21 .play {
      margin: 0 auto; }
    .login-page .call_to_action_21 .btn {
      margin-left: 0; } }
  .login-page .call_to_action_22 .inner {
    border: 2px solid rgba(255, 255, 255, 0.3); }
  @media (max-width: 575.98px) {
    .login-page .call_to_action_22 .inner {
      padding: 40px 15px; }
    .login-page .call_to_action_22 .play {
      margin: 30px 0; } }
  .login-page .call_to_action_23 .img {
    top: 0;
    left: 50%;
    transform: translateX(-50%); }
  @media (max-width: 991.98px) {
    .login-page .call_to_action_23 {
      padding: 80px 0; }
      .login-page .call_to_action_23 .img {
        position: static;
        transform: none;
        height: auto;
        width: 100%;
        border-radius: 10px; }
      .login-page .call_to_action_23 .inner {
        padding: 40px 0 0 0; }
      .login-page .call_to_action_23 .text-adaptive {
        margin: 10px 0 40px 0; } }
  @media (max-width: 575.98px) {
    .login-page .call_to_action_26 .btn {
      margin: 0 10px 20px 10px; }
    .login-page .call_to_action_26 .img {
      margin-top: 40px; } }
  @media (max-width: 575.98px) {
    .login-page .call_to_action_27 .input {
      max-width: 290px; }
    .login-page .call_to_action_27 .btn {
      margin: 20px 0px 0px 0px; } }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_28 .inner {
      padding-left: 0; } }
  .login-page .call_to_action_29 .img {
    top: 0;
    left: 50%;
    transform: translateX(-50%); }
  @media (max-width: 991.98px) {
    .login-page .call_to_action_29 {
      padding-top: 60px; }
      .login-page .call_to_action_29 .img {
        position: static;
        max-width: 100%;
        height: auto;
        max-height: 70vh;
        transform: none; }
      .login-page .call_to_action_29 .inner {
        padding-top: 40px;
        padding-bottom: 60px; }
      .login-page .call_to_action_29 h2 {
        margin-bottom: 10px; }
      .login-page .call_to_action_29 .btn {
        margin-top: 30px; } }
  .login-page .call_to_action_30 {
    overflow: hidden; }
    @media (max-width: 575.98px) {
      .login-page .call_to_action_30 .img {
        position: static;
        max-width: 100%; }
      .login-page .call_to_action_30 .inner {
        padding-top: 0;
        padding-bottom: 60px; }
      .login-page .call_to_action_30 .btn {
        margin: 20px 10px 0 10px; } }
  .login-page .call_to_action_31 {
    overflow: hidden; }
    .login-page .call_to_action_31 .img {
      bottom: 0;
      left: 0px; }
    @media (max-width: 767.98px) {
      .login-page .call_to_action_31 .inner {
        padding-bottom: 60px; }
      .login-page .call_to_action_31 .img {
        position: static;
        max-width: 100%; } }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_32 {
      padding-bottom: 40px; }
      .login-page .call_to_action_32 .logos {
        margin-bottom: 0; }
        .login-page .call_to_action_32 .logos img {
          margin: 0 10px 20px 10px; }
      .login-page .call_to_action_32 .inner {
        padding: 80px 0px 60px 0px; }
      .login-page .call_to_action_32 .img {
        max-width: 100%; } }
  .login-page .team_1 .block {
    border: 2px solid #E7EAED; }
  .login-page .team_1 .block_socials {
    border-top: 2px solid #E7EAED; }
    .login-page .team_1 .block_socials .border_right {
      border-right: 2px solid #E7EAED; }
  .login-page .team_2 .bordered {
    border-left: 2px solid #E7EAED;
    border-right: 2px solid #E7EAED; }
  @media (max-width: 767.98px) {
    .login-page .team_2 .bordered {
      border: none;
      border-top: 2px solid #E7EAED;
      border-bottom: 2px solid #E7EAED; } }
  @media (max-width: 991.98px) {
    .login-page .team_4 h2 {
      margin-bottom: 0; } }
  @media (max-width: 575.98px) {
    .login-page .team_5 .block_socials .link {
      margin: 0 10px; } }
  .login-page .pricing_table_1 .block {
    border: 2px solid #E7EAED;
    border-bottom-left-radius: 10px; }
    .login-page .pricing_table_1 .block.noborder_right {
      border-right: none; }
  .login-page .pricing_table_1 .item {
    padding: 16px 0 14px 0;
    border-top: 2px solid #E7EAED; }
  .login-page .pricing_table_1 .price span.absolute {
    top: 5px;
    left: -15px; }
  @media (max-width: 991.98px) {
    .login-page .pricing_table_1[class^="pb-"], .login-page .pricing_table_1[class*=" pb-"] {
      padding-bottom: 30px; }
    .login-page .pricing_table_1 .block {
      margin: 0 auto 50px auto;
      max-width: 300px;
      border-radius: 10px; }
      .login-page .pricing_table_1 .block.noborder_right {
        border-right: 2px solid #E7EAED; } }
  .login-page .pricing_table_3 .overflow {
    overflow: auto; }
  .login-page .pricing_table_3 .block {
    border-right: 2px solid #E7EAED; }
    .login-page .pricing_table_3 .block.noborder_right {
      border-right: none; }
  .login-page .pricing_table_3 .price span.absolute {
    top: -5px;
    left: -15px; }
  .login-page .pricing_table_3 .table_row {
    border-left: 2px solid #E7EAED;
    border-right: 2px solid #E7EAED;
    border-bottom: 2px solid #E7EAED; }
    .login-page .pricing_table_3 .table_row:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px; }
  .login-page .pricing_table_3 .table_head {
    background: rgba(231, 234, 237, 0.5);
    border: none; }
  @media (max-width: 1199.98px) {
    .login-page .pricing_table_3 .table-responsive-shadow {
      position: relative; }
      .login-page .pricing_table_3 .table-responsive-shadow:after {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 40px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 78%); } }
  .login-page .pricing_table_4 .block {
    border: 2px solid rgba(255, 255, 255, 0.2); }
  .login-page .pricing_table_4 ul {
    list-style: none; }
    .login-page .pricing_table_4 ul li {
      position: relative; }
      .login-page .pricing_table_4 ul li:not(.disabled):before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f00c";
        display: block;
        position: absolute;
        left: -25px;
        top: 0px;
        color: #18c967;
        font-size: 12px; }
  @media (max-width: 1199.98px) {
    .login-page .pricing_table_4 .block {
      padding-left: 50px;
      padding-right: 30px; } }
  .login-page .pricing_table_5 .block:not(.bg-dark) {
    border: 2px solid #E7EAED; }
  .login-page .pricing_table_5 .price span.absolute {
    top: 0px;
    left: -15px; }
  .login-page .pricing_table_5 ul {
    list-style: none; }
    .login-page .pricing_table_5 ul li {
      position: relative; }
      .login-page .pricing_table_5 ul li:not(.disabled):before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f00c";
        display: block;
        position: absolute;
        left: -25px;
        top: 0px;
        color: #0074f1;
        font-size: 12px; }
  .login-page .pricing_table_6 .custom-toggle:hover, .login-page .pricing_table_6 .custom-toggle:focus {
    background: #005bbe; }
  .login-page .pricing_table_6 .custom-toggle div {
    left: 3px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px; }
  .login-page .pricing_table_6 .img {
    margin-left: -2px;
    margin-right: -2px;
    width: auto;
    min-width: calc(100% + 4px);
    max-width: calc(100% + 4px); }
  .login-page .pricing_table_6 .block {
    border: 2px solid #E7EAED;
    border-top: none; }
  @media (max-width: 575.98px) {
    .login-page .pricing_table_6 .block .inner {
      padding: 0 30px; } }
  .login-page .pricing_table_7 .inner {
    border: 2px solid #E7EAED; }
  @media (max-width: 575.98px) {
    .login-page .pricing_table_7 h2 {
      margin-bottom: 10px; } }
  .login-page .contact_1 textarea.input {
    height: 170px; }
  .login-page .contact_1 .separate {
    position: relative; }
    .login-page .contact_1 .separate:before, .login-page .contact_1 .separate:after {
      content: " ";
      display: block;
      position: absolute;
      width: calc(100% / 2 - 60px);
      left: 0;
      top: 50%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
    .login-page .contact_1 .separate:after {
      left: auto;
      right: 0; }
  .login-page .contact_2 textarea.input {
    height: 170px; }
  .login-page .contact_3 textarea.input {
    height: 150px; }
  .login-page .contact_4 textarea.input {
    height: 110px; }
  @media (max-width: 469.98px) {
    .login-page .contact_4 form {
      padding: 40px 20px; } }
  .login-page .contact_5 textarea.input {
    height: 170px; }
  .login-page .contact_6 form {
    background: rgba(231, 234, 237, 0.3); }
  .login-page .contact_6 textarea.input {
    height: 170px; }
  @media (max-width: 1199.98px) {
    .login-page .contact_6 .input_block .input {
      width: 240px; } }
  @media (max-width: 767.98px) {
    .login-page .contact_6 .input_block .input {
      width: 300px; } }
  @media (max-width: 575.98px) {
    .login-page .contact_6 .input_block .input {
      width: 100%; } }
  @media (max-width: 469.98px) {
    .login-page .contact_6 form {
      padding: 40px 20px; } }
  .login-page .contact_7 textarea.input {
    height: 170px; }
  @media (max-width: 991.98px) {
    .login-page .contact_7 .input_block .input {
      width: 100%; } }
  .login-page .contact_8 .js-google-map {
    right: calc((-100vw + 1200px) / 2);
    left: 15px;
    top: 0; }
  @media (max-width: 1199.98px) {
    .login-page .contact_8 .js-google-map {
      right: calc((-100vw + 970px) / 2); }
    .login-page .contact_8 .text_big {
      font-size: 32px;
      line-height: 42px; } }
  @media (max-width: 991.98px) {
    .login-page .contact_8 .js-google-map {
      right: calc((-100vw + 750px) / 2); }
    .login-page .contact_8 .text_big {
      font-size: 26px;
      line-height: 36px; }
    .login-page .contact_8 .title {
      margin-bottom: 0; } }
  @media (max-width: 767.98px) {
    .login-page .contact_8 {
      padding-bottom: 80px; }
      .login-page .contact_8 .left_side {
        padding-top: 80px;
        padding-bottom: 40px; }
      .login-page .contact_8 .js-google-map {
        position: relative;
        left: auto;
        right: auto;
        height: 60vh; }
      .login-page .contact_8 .text-adaptive {
        margin: 10px 0 40px 0; } }
  @media (max-width: 575.98px) {
    .login-page .contact_8 {
      padding-bottom: 0; }
      .login-page .contact_8 .text_big {
        font-size: 22px;
        line-height: 32px; }
      .login-page .contact_8 .js-google-map {
        left: -15px;
        width: calc(100% + 30px); } }
  .login-page .contact_9 .map_overlay,
  .login-page .contact_9 .js-google-map {
    top: 0; }
  .login-page .contact_9 .map_overlay {
    background: rgba(53, 56, 61, 0.85); }
  @media (max-width: 1199.98px) {
    .login-page .contact_9 .text_big {
      font-size: 32px;
      line-height: 42px; } }
  @media (max-width: 991.98px) {
    .login-page .contact_9 .text_big {
      font-size: 26px;
      line-height: 36px; } }
  @media (max-width: 767.98px) {
    .login-page .contact_9 .text-adaptive {
      margin: 10px 0 40px 0; }
    .login-page .contact_9 .text_big {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 40px; } }
  @media (max-width: 991.98px) {
    .login-page .contact_10[class^="pb-"], .login-page .contact_10[class*=" pb-"] {
      padding-bottom: 60px; }
    .login-page .contact_10 h2 {
      margin: 40px 0 60px 0; } }
  @media (max-width: 575.98px) {
    .login-page .contact_10 h2 a {
      font-size: 75%;
      margin-top: 0; }
    .login-page .contact_10 .socials a {
      margin: 0 15px 20px 15px; } }
  .login-page .showcase_1 .block a:hover {
    opacity: 0.8; }
  @media (max-width: 991.98px) {
    .login-page .showcase_1 .description {
      margin: 10px 0 0 0; } }
  .login-page .showcase_2 a:hover {
    opacity: 0.8; }
  .login-page .showcase_3 .block {
    border: 2px solid #E7EAED; }
  .login-page .showcase_3 img {
    vertical-align: bottom;
    position: relative;
    top: 2px; }
  .login-page .showcase_3 a:hover {
    opacity: 0.8; }
  @media (max-width: 575.98px) {
    .login-page .showcase_3 .block .text-adaptive {
      margin: 10px 0 20px 0; } }
  .login-page .showcase_4 .link:hover {
    filter: brightness(1.1); }
  .login-page .showcase_6 img {
    transition: all 0.2s; }
  .login-page .showcase_6 a:hover img {
    opacity: 0.7; }
  .login-page .showcase_8 a:hover {
    filter: brightness(1.15); }
  .login-page .showcase_9 .block {
    border: 2px solid #E7EAED; }
  @media (max-width: 359.98px) {
    .login-page .showcase_9 .block {
      padding-left: 20px;
      padding-right: 20px; } }
  .login-page .showcase_10 .with_border {
    border: 2px solid #E7EAED; }
  @media (max-width: 575.98px) {
    .login-page .footer_1 .links a {
      margin: 0 10px; } }
  @media (max-width: 991.98px) {
    .login-page .footer_3 .description {
      margin: 10px 0; }
    .login-page .footer_3 .socials a {
      margin: 0 10px; }
    .login-page .footer_3 .title {
      margin-bottom: 15px; } }
  @media (max-width: 1199.98px) {
    .login-page .footer_5 .hr {
      margin-top: 30px; }
    .login-page .footer_5 .socials {
      margin-top: 20px; }
    .login-page .footer_5 .logo,
    .login-page .footer_5 .title {
      margin-bottom: 15px; } }
  @media (max-width: 991.98px) {
    .login-page .footer_6 .hr {
      margin: 0 0 50px 0; }
    .login-page .footer_6 .title {
      margin-bottom: 15px; }
    .login-page .footer_6 .links a {
      margin-bottom: 10px; } }
  @media (max-width: 575.98px) {
    .login-page .footer_7 .link {
      margin: 0 10px; } }
  @media (max-width: 1199.98px) {
    .login-page .footer_8 a {
      margin: 0 10px; } }
  @media (max-width: 1199.98px) {
    .login-page .footer_9 form {
      margin-bottom: 40px; }
    .login-page .footer_9 .title {
      margin-bottom: 15px; }
    .login-page .footer_9 .links a {
      margin-bottom: 10px; } }
  @media (max-width: 991.98px) {
    .login-page .footer_10 .socials {
      margin-top: 15px; }
    .login-page .footer_10 .logo,
    .login-page .footer_10 .title {
      margin-bottom: 15px; } }
  @media (max-width: 991.98px) {
    .login-page .footer_11 a {
      margin: 0 10px; } }
  @media (max-width: 991.98px) {
    .login-page .footer_12 {
      padding-top: 50px; }
      .login-page .footer_12 .text-adaptive {
        margin-top: 30px; } }
  @media (max-width: 991.98px) {
    .login-page .footer_13 a {
      margin: 0 10px; } }
  .login-page .ecommerce_1 .product .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_1 .product .img_link:hover img {
    opacity: 0.8; }
  .login-page .ecommerce_1 .product .btn {
    left: 0;
    bottom: 0; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_1 .main_info .btn {
      margin-top: 30px; } }
  .login-page .ecommerce_2 .product {
    border: 2px solid #E7EAED; }
    .login-page .ecommerce_2 .product.big {
      min-height: calc(100% - 30px); }
      .login-page .ecommerce_2 .product.big ul {
        column-count: 2; }
    .login-page .ecommerce_2 .product li {
      list-style: none;
      position: relative; }
      .login-page .ecommerce_2 .product li:before {
        content: "";
        display: block;
        position: absolute;
        top: 11px;
        left: -10px;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background: #1E3060; }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_2 .product {
      max-width: 290px;
      padding: 20px 20px 30px 20px; }
      .login-page .ecommerce_2 .product.big ul {
        column-count: 1; }
      .login-page .ecommerce_2 .product .left {
        margin-right: 0; }
      .login-page .ecommerce_2 .product img {
        width: 100%;
        max-width: 100%; } }
  .login-page .ecommerce_3 .product .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_3 .product .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_3 h2 {
      margin-bottom: 10px; }
    .login-page .ecommerce_3 .products {
      margin-top: 0; }
      .login-page .ecommerce_3 .products .text-adaptive {
        max-width: 100%; } }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_3 .product {
      max-width: 290px; } }
  .login-page .ecommerce_4 .dropdown-menu {
    box-shadow: 0px 15px 40px rgba(114, 114, 114, 0.3); }
  .login-page .ecommerce_4 .link_holder {
    transition: all 0.25s; }
    .login-page .ecommerce_4 .link_holder:hover {
      background: #E7EAED; }
  .login-page .ecommerce_4 .product .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_4 .product .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_4 h2 {
      margin-bottom: 10px; }
    .login-page .ecommerce_4 .dropdown {
      margin-left: 10px;
      margin-right: 10px; } }
  .login-page .ecommerce_5 .dropdown-menu {
    box-shadow: 0px 15px 40px rgba(114, 114, 114, 0.3); }
  .login-page .ecommerce_5 .link_holder {
    transition: all 0.25s; }
    .login-page .ecommerce_5 .link_holder:hover {
      background: #E7EAED; }
  .login-page .ecommerce_5 .product .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_5 .product .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_5 .categories {
      margin-top: 10px; }
    .login-page .ecommerce_5 .category {
      margin-left: 10px;
      margin-right: 10px; }
    .login-page .ecommerce_5 .product {
      margin-bottom: 0; } }
  .login-page .ecommerce_6 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_6 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_6 {
      padding-top: 80px; }
      .login-page .ecommerce_6 .bg {
        max-width: 100%;
        height: auto; }
      .login-page .ecommerce_6 .inner {
        padding-top: 40px;
        padding-bottom: 80px; }
      .login-page .ecommerce_6 .buttons {
        margin-top: 30px; } }
  .login-page .ecommerce_7 .categories a {
    border-bottom: 2px solid transparent; }
    .login-page .ecommerce_7 .categories a:hover {
      border-color: #3657ae; }
  .login-page .ecommerce_7 .categories .active {
    color: #1E3060;
    border-color: #1E3060; }
    .login-page .ecommerce_7 .categories .active:hover {
      border-color: #1E3060; }
  .login-page .ecommerce_7 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_7 .img_link:hover img {
    opacity: 0.8; }
  .login-page .ecommerce_7 input[type="radio"]:checked + label:before {
    content: "\f00c";
    color: #FFFFFF; }
  .login-page .ecommerce_7 input[type="radio"] + label:before {
    border: none;
    line-height: 20px;
    color: #FFFFFF; }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_7 .categories {
      margin-top: 20px; }
      .login-page .ecommerce_7 .categories a {
        margin: 5px 8px 0 8px;
        font-size: 16px;
        line-height: 26px; } }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_7 .product {
      max-width: 290px; } }
  .login-page .ecommerce_8 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_8 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_8 .product {
      max-width: 290px; } }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_9 {
      padding-top: 80px; }
      .login-page .ecommerce_9 .bg {
        max-width: 100%;
        height: auto; }
      .login-page .ecommerce_9 .inner {
        padding-top: 40px;
        padding-bottom: 80px; }
      .login-page .ecommerce_9 .prices {
        margin-top: 40px; } }
  .login-page .ecommerce_10 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_10 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_10 .inner {
      padding: 0; }
    .login-page .ecommerce_10 .title {
      font-size: 18px;
      line-height: 28px; } }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_10 .product {
      margin-bottom: 0;
      max-width: 370px; } }
  .login-page .ecommerce_11 .slick-arrow {
    margin-top: 0;
    top: -90px;
    width: 50px;
    height: 50px; }
    .login-page .ecommerce_11 .slick-arrow:before {
      width: 50px;
      height: 50px;
      border: 2px solid #E7EAED;
      border-radius: 100%;
      transition: all 0.25s; }
    .login-page .ecommerce_11 .slick-arrow:hover:before {
      opacity: 1;
      background-color: #FFFFFF; }
  .login-page .ecommerce_11 .slick-prev {
    left: auto;
    right: 75px; }
    .login-page .ecommerce_11 .slick-prev:before {
      background-size: 7px auto; }
  .login-page .ecommerce_11 .slick-next {
    right: 15px; }
    .login-page .ecommerce_11 .slick-next:before {
      background-size: 7px auto; }
  .login-page .ecommerce_11 .holder:hover .inner {
    opacity: 1; }
  .login-page .ecommerce_11 .inner {
    top: 0;
    left: 0;
    background: rgba(0, 116, 241, 0.7);
    transition: all 0.25s; }
    .login-page .ecommerce_11 .inner .btn {
      min-width: 145px; }
  .login-page .ecommerce_11 input[type="radio"]:checked + label {
    background: #FFFFFF;
    color: #1e3060; }
  .login-page .ecommerce_11 input[type="radio"] + label {
    height: 50px;
    line-height: 46px;
    border: 2px solid #E7EAED;
    transition: all 0.25s; }
    .login-page .ecommerce_11 input[type="radio"] + label:before {
      display: none; }
    .login-page .ecommerce_11 input[type="radio"] + label:hover {
      color: #1e3060; }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_11 .header_holder {
      padding-right: 0; }
    .login-page .ecommerce_11 .slick-arrow {
      top: 10px; } }
  .login-page .ecommerce_12 .product {
    border: 2px solid #E7EAED; }
    .login-page .ecommerce_12 .product li {
      list-style: none;
      position: relative; }
      .login-page .ecommerce_12 .product li:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background: #1E3060; }
    .login-page .ecommerce_12 .product .btn {
      left: 50%;
      bottom: 30px;
      transform: translateX(-50%); }
  .login-page .ecommerce_12 .img_link img {
    margin: -2px -2px 0 -2px;
    max-width: calc(100% + 4px);
    min-width: calc(100% + 4px);
    transition: all 0.25s; }
  .login-page .ecommerce_12 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_12 .product {
      max-width: 370px;
      padding-bottom: 30px; }
      .login-page .ecommerce_12 .product .btn {
        position: static;
        transform: none; }
    .login-page .ecommerce_12 .title {
      font-size: 18px;
      line-height: 28px; } }
  .login-page .ecommerce_13 .category {
    border: 2px solid #E7EAED; }
    .login-page .ecommerce_13 .category .btn {
      left: 50%;
      bottom: 30px;
      transform: translateX(-50%); }
  .login-page .ecommerce_13 .img_link img {
    margin: -2px -2px 0 -2px;
    max-width: calc(100% + 4px);
    min-width: calc(100% + 4px);
    transition: all 0.25s; }
  .login-page .ecommerce_13 .img_link:hover img {
    opacity: 0.8; }
  .login-page .ecommerce_14 .product {
    overflow: hidden; }
  .login-page .ecommerce_14 .img_link img {
    min-height: 100%;
    transition: all 0.25s; }
  .login-page .ecommerce_14 .img_link:hover img {
    opacity: 0.8; }
  .login-page .ecommerce_14 .stars .active {
    color: #f2c94c; }
  .login-page .ecommerce_14 .stars .absolute {
    top: 50%;
    left: 0;
    transform: translateY(-50%); }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_14 .img_link img {
      width: 100%;
      min-height: 0; } }
  .login-page .ecommerce_15 .slider_menu a img {
    transition: all 0.25s; }
  .login-page .ecommerce_15 .slider_menu a:hover img {
    opacity: 0.8; }
  .login-page .ecommerce_15 .stars .active {
    color: #f2c94c; }
  .login-page .ecommerce_15 .stars .absolute {
    top: 50%;
    left: 0;
    transform: translateY(-50%); }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_15 h2 {
      margin-top: 0px;
      margin-bottom: 5px; }
    .login-page .ecommerce_15 .btn {
      margin-top: 50px; } }
  .login-page .ecommerce_16 .dropdown-menu {
    box-shadow: 0px 15px 40px rgba(114, 114, 114, 0.3); }
  .login-page .ecommerce_16 .link_holder {
    transition: all 0.25s; }
    .login-page .ecommerce_16 .link_holder:hover {
      background: #E7EAED; }
  .login-page .ecommerce_16 input[type="radio"]:checked + label:before {
    content: "\f00c";
    color: #FFFFFF; }
  .login-page .ecommerce_16 input[type="radio"] + label:before {
    border: none;
    line-height: 20px;
    color: #FFFFFF; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_16 .inner {
      margin-left: 0;
      width: 390px; } }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_16 .categories {
      margin-top: 10px; }
    .login-page .ecommerce_16 .category {
      margin-left: 10px;
      margin-right: 10px; } }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_16 .product {
      max-width: 370px; }
      .login-page .ecommerce_16 .product .img {
        width: 100%; }
    .login-page .ecommerce_16 .inner {
      max-width: 100%; }
    .login-page .ecommerce_16 .price {
      margin-left: 0; } }
  .login-page .ecommerce_17 .holder:hover .inner {
    opacity: 1; }
  .login-page .ecommerce_17 .inner {
    top: 0;
    left: 0;
    background: rgba(0, 116, 241, 0.7);
    transition: all 0.25s; }
  .login-page .ecommerce_18 .dropdown-menu {
    box-shadow: 0px 15px 40px rgba(114, 114, 114, 0.3); }
  .login-page .ecommerce_18 .link_holder {
    transition: all 0.25s; }
    .login-page .ecommerce_18 .link_holder:hover {
      background: #E7EAED; }
  .login-page .ecommerce_18 .img_link img {
    min-height: 100%;
    transition: all 0.25s; }
  .login-page .ecommerce_18 .img_link:hover img {
    opacity: 0.8; }
  .login-page .ecommerce_18 .buttons {
    left: 0;
    bottom: 10px; }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_18 .inner {
      margin-left: 0;
      padding-bottom: 0; }
    .login-page .ecommerce_18 .buttons {
      position: static; } }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_18 .product {
      max-width: 370px; }
      .login-page .ecommerce_18 .product .img_link img {
        width: 100%; } }
  .login-page .ecommerce_19 .product {
    border: 2px solid #E7EAED; }
    .login-page .ecommerce_19 .product.big {
      min-height: calc(100% - 30px); }
    .login-page .ecommerce_19 .product .img,
    .login-page .ecommerce_19 .product .slider {
      margin-top: -2px;
      margin-right: -2px;
      margin-bottom: -2px; }
    .login-page .ecommerce_19 .product .slider {
      overflow: hidden; }
      .login-page .ecommerce_19 .product .slider .slick-list,
      .login-page .ecommerce_19 .product .slider .slick-track,
      .login-page .ecommerce_19 .product .slider .slick-slide {
        height: 100%; }
    .login-page .ecommerce_19 .product .slider_menu {
      max-width: 100%; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_19 .product.small {
      width: calc(50% - 15px); }
      .login-page .ecommerce_19 .product.small .inner {
        padding-left: 20px;
        padding-right: 20px; }
    .login-page .ecommerce_19 .product.big .inner {
      padding-left: 30px; } }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_19 .product.small {
      width: 100%; }
    .login-page .ecommerce_19 .product .slider {
      margin: -2px;
      width: auto;
      border-radius: 10px 10px 0 0; }
      .login-page .ecommerce_19 .product .slider img {
        width: 100%; } }
  @media (max-width: 369.98px) {
    .login-page .ecommerce_19 .product.big .inner {
      padding: 20px; }
    .login-page .ecommerce_19 .product.big .title {
      font-size: 18px;
      line-height: 28px; }
    .login-page .ecommerce_19 .product.big .btn {
      width: 100%; }
    .login-page .ecommerce_19 .product.big .slider_menu img {
      margin: 0 auto; }
    .login-page .ecommerce_19 .product.small {
      display: block !important; }
      .login-page .ecommerce_19 .product.small .img {
        margin: -2px;
        width: calc(100% + 4px);
        max-width: calc(100% + 4px);
        border-radius: 10px 10px 0 0; } }
  .login-page .ecommerce_20 .category {
    transition: all 0.25s; }
    .login-page .ecommerce_20 .category:hover {
      background: #E7EAED; }
  .login-page .ecommerce_20 .product .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_20 .product .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_20 h2 {
      margin-bottom: 20px; } }
  @media (max-width: 359.98px) {
    .login-page .ecommerce_20 .title {
      font-size: 22px;
      line-height: 32px; } }
  .login-page .ecommerce_21 .category {
    border: 2px solid #E7EAED;
    transition: all 0.25s; }
    .login-page .ecommerce_21 .category:hover {
      border-color: #0074f1; }
  .login-page .ecommerce_21 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_21 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_21 .inner {
      padding-left: 0;
      padding-right: 0; } }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_21 .category {
      max-width: 320px; } }
  .login-page .ecommerce_22 .dropdown-menu {
    box-shadow: 0px 15px 40px rgba(114, 114, 114, 0.3); }
  .login-page .ecommerce_22 .link_holder {
    transition: all 0.25s; }
    .login-page .ecommerce_22 .link_holder:hover {
      background: #E7EAED; }
  .login-page .ecommerce_22 .category {
    transition: all 0.25s; }
    .login-page .ecommerce_22 .category:hover {
      background: #E7EAED; }
  .login-page .ecommerce_22 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_22 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_22[class^="pb-"], .login-page .ecommerce_22[class*=" pb-"] {
      padding-bottom: 30px; }
    .login-page .ecommerce_22 .btn {
      margin-right: 0; } }
  .login-page .ecommerce_23 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_23 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_23 .buttons {
      margin-top: 50px; } }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_23 {
      padding-top: 80px; }
      .login-page .ecommerce_23 .bg {
        max-width: 100%;
        height: auto;
        border-radius: 10px; }
      .login-page .ecommerce_23 .inner {
        padding-top: 40px;
        padding-bottom: 80px; }
      .login-page .ecommerce_23 .buttons {
        margin-top: 30px; } }
  .login-page .ecommerce_24 .bg {
    top: 0;
    left: 50%;
    transform: translateX(-50%); }
  .login-page .ecommerce_24 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_24 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_24 {
      padding: 80px 0; }
      .login-page .ecommerce_24 .inner {
        padding-top: 0;
        padding-bottom: 0; }
      .login-page .ecommerce_24 .title_price {
        width: 100%; } }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_24 .product {
      max-width: 370px; }
      .login-page .ecommerce_24 .product img {
        width: 100%; }
    .login-page .ecommerce_24 .inner {
      margin-left: 0; } }
  .login-page .ecommerce_26 .product:hover .inner {
    opacity: 1; }
  .login-page .ecommerce_26 .inner {
    top: 0;
    left: 0;
    background: rgba(0, 116, 241, 0.7);
    transition: all 0.25s;
    overflow: auto; }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_26 .main_btn {
      margin-top: 25px; } }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_28 .btn {
      margin-top: 40px; } }
  .login-page .ecommerce_29 .inner {
    border: 2px solid #E7EAED; }
  .login-page .ecommerce_29 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_29 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_29 .socials {
      margin-top: 20px; } }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_29 .inner {
      padding: 30px; }
    .login-page .ecommerce_29 .images {
      padding: 0; }
      .login-page .ecommerce_29 .images .img_link {
        width: calc(100% / 2 - 30px); }
      .login-page .ecommerce_29 .images img {
        width: 100%; } }
  .login-page .ecommerce_30 .holder {
    border: 2px solid #E7EAED; }
  .login-page .ecommerce_30 .bg {
    top: -2px;
    right: -2px;
    bottom: -2px;
    background-position: 50% 50%;
    background-size: cover; }
  .login-page .ecommerce_30 .product.big .bg {
    width: 300px;
    border-radius: 0 10px 10px 0; }
  .login-page .ecommerce_30 .product.big .inner {
    margin-right: 320px; }
  .login-page .ecommerce_30 .product.small .bg {
    left: -2px;
    border-radius: 10px; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_30 .inner {
      padding-bottom: 50px; }
    .login-page .ecommerce_30 .product.big .bg {
      width: auto;
      left: -2px;
      border-radius: 10px; }
    .login-page .ecommerce_30 .product.big .inner {
      margin-right: 0; }
    .login-page .ecommerce_30 .product.big .subtitle,
    .login-page .ecommerce_30 .product.big h2 {
      color: #FFFFFF; } }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_30 .inner {
      padding: 50px 30px; } }
  @media (max-width: 469.98px) {
    .login-page .ecommerce_30 .inner {
      padding: 40px 20px; } }
  .login-page .ecommerce_31 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_31 .img_link:hover img {
    opacity: 0.8; }
  .login-page .ecommerce_31 .btn_play {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .login-page .ecommerce_31 input[type="radio"]:checked + label:before {
    content: "\f00c";
    color: #FFFFFF;
    text-align: center; }
  .login-page .ecommerce_31 input[type="radio"] + label:before {
    border: none;
    width: 22px;
    height: 22px;
    line-height: 22px;
    color: #FFFFFF; }
  .login-page .ecommerce_31 input[type="radio"] + label {
    margin: 0 2px;
    width: 22px;
    height: 22px; }
  .login-page .ecommerce_31 .colors_border {
    border: 2px solid #E7EAED;
    padding: 0 12px; }
  .login-page .ecommerce_31 .quantity_selector {
    border: 2px solid #E7EAED;
    overflow: hidden; }
    .login-page .ecommerce_31 .quantity_selector .control {
      width: 36px;
      line-height: 36px;
      background: transparent; }
      .login-page .ecommerce_31 .quantity_selector .control:hover {
        background: #E7EAED; }
      .login-page .ecommerce_31 .quantity_selector .control::-moz-selection {
        background: transparent;
        color: #1e3060; }
      .login-page .ecommerce_31 .quantity_selector .control::-webkit-selection {
        background: transparent;
        color: #1e3060; }
      .login-page .ecommerce_31 .quantity_selector .control::selection {
        background: transparent;
        color: #1e3060; }
    .login-page .ecommerce_31 .quantity_selector .input {
      width: 40px;
      height: 36px;
      border-left: 2px solid #E7EAED;
      border-right: 2px solid #E7EAED;
      color: #1e3060;
      font-weight: 500;
      font-size: 16px;
      text-align: center; }
  .login-page .ecommerce_31 .advantages li {
    list-style: none;
    position: relative; }
    .login-page .ecommerce_31 .advantages li:before {
      content: "";
      display: block;
      position: absolute;
      top: 11px;
      left: -12px;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background: #1E3060; }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_31 .img_link {
      margin-right: 5px; } }
  .login-page .ecommerce_32 .slider_menu .slick-arrow {
    margin-top: -25px;
    width: 50px;
    height: 50px;
    background: #E7EAED;
    border-radius: 100%; }
    .login-page .ecommerce_32 .slider_menu .slick-arrow:before {
      width: 100%;
      height: 100%;
      background-size: 7px auto;
      background-position: 50% 50%; }
    .login-page .ecommerce_32 .slider_menu .slick-arrow:hover {
      background-color: #cad1d7; }
      .login-page .ecommerce_32 .slider_menu .slick-arrow:hover:before {
        opacity: 1; }
  .login-page .ecommerce_32 .slider_menu img {
    cursor: pointer;
    transition: all 0.25s; }
    .login-page .ecommerce_32 .slider_menu img:hover {
      opacity: 0.8; }
  .login-page .ecommerce_32 input[type="radio"]:checked + label:before {
    content: "\f00c";
    color: #FFFFFF;
    text-align: center; }
  .login-page .ecommerce_32 input[type="radio"] + label:before {
    border: none;
    width: 22px;
    height: 22px;
    line-height: 22px;
    color: #FFFFFF; }
  .login-page .ecommerce_32 input[type="radio"] + label {
    margin: 0 2px;
    width: 22px;
    height: 22px; }
  .login-page .ecommerce_32 .colors_border {
    border: 2px solid #E7EAED;
    padding: 0 12px; }
  .login-page .ecommerce_32 .quantity_selector {
    border: 2px solid #E7EAED;
    overflow: hidden; }
    .login-page .ecommerce_32 .quantity_selector .control {
      width: 36px;
      line-height: 36px;
      background: transparent; }
      .login-page .ecommerce_32 .quantity_selector .control:hover {
        background: #E7EAED; }
      .login-page .ecommerce_32 .quantity_selector .control::-moz-selection {
        background: transparent;
        color: #1e3060; }
      .login-page .ecommerce_32 .quantity_selector .control::-webkit-selection {
        background: transparent;
        color: #1e3060; }
      .login-page .ecommerce_32 .quantity_selector .control::selection {
        background: transparent;
        color: #1e3060; }
    .login-page .ecommerce_32 .quantity_selector .input {
      width: 40px;
      height: 36px;
      border-left: 2px solid #E7EAED;
      border-right: 2px solid #E7EAED;
      color: #1e3060;
      font-size: 16px;
      font-weight: 500;
      text-align: center; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_32 .inner {
      margin-left: 0; }
    .login-page .ecommerce_32 .top_form {
      margin-top: 10px; }
    .login-page .ecommerce_32 .buttons {
      margin-top: 30px; }
    .login-page .ecommerce_32 .bottom_form {
      margin-top: 30px; }
      .login-page .ecommerce_32 .bottom_form .text-adaptive {
        max-width: 370px; } }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_32 .text-adaptive {
      margin-left: auto;
      margin-right: auto; } }
  @media (max-width: 369.98px) {
    .login-page .ecommerce_32 .quantity,
    .login-page .ecommerce_32 .colors,
    .login-page .ecommerce_32 .buttons a,
    .login-page .ecommerce_32 .buttons button {
      margin-left: 10px;
      margin-right: 10px; } }
  .login-page .ecommerce_33 .remove_product {
    width: 45px;
    height: 45px;
    top: 30px;
    right: 0;
    cursor: pointer;
    z-index: 10; }
    .login-page .ecommerce_33 .remove_product:hover svg path {
      stroke: #1e3060; }
    .login-page .ecommerce_33 .remove_product svg path {
      stroke: #1E3060;
      transition: all 0.25s; }
  .login-page .ecommerce_33 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_33 .img_link:hover img {
    opacity: 0.8; }
  .login-page .ecommerce_33 .colors_border {
    border: 2px solid #E7EAED;
    padding: 0 8px; }
    .login-page .ecommerce_33 .colors_border input[type="radio"]:checked + label:before {
      content: "\f00c";
      color: #FFFFFF;
      text-align: center; }
    .login-page .ecommerce_33 .colors_border input[type="radio"] + label:before {
      border: none;
      width: 22px;
      height: 22px;
      line-height: 22px;
      color: #FFFFFF; }
    .login-page .ecommerce_33 .colors_border input[type="radio"] + label {
      margin: 0 2px;
      width: 22px;
      height: 22px; }
  .login-page .ecommerce_33 .sizes input[type="radio"]:checked + label {
    background: #1e3060;
    color: #FFFFFF;
    border-color: #1e3060; }
  .login-page .ecommerce_33 .sizes input[type="radio"] + label:before {
    display: none; }
  .login-page .ecommerce_33 .sizes input[type="radio"] + label {
    width: auto;
    height: 40px;
    line-height: 36px; }
  .login-page .ecommerce_33 .sizes label {
    padding: 0 12px;
    border: 2px solid #E7EAED;
    border-right: none; }
    .login-page .ecommerce_33 .sizes label:first-of-type {
      border-radius: 20px 0 0 20px; }
    .login-page .ecommerce_33 .sizes label:last-of-type {
      border-radius: 0 20px 20px 0;
      border-right: 2px solid #E7EAED; }
    .login-page .ecommerce_33 .sizes label:hover {
      color: #1e3060; }
  .login-page .ecommerce_33 .quantity_selector {
    border: 2px solid #E7EAED;
    overflow: hidden; }
    .login-page .ecommerce_33 .quantity_selector .control {
      width: 36px;
      line-height: 36px;
      background: transparent; }
      .login-page .ecommerce_33 .quantity_selector .control:hover {
        background: #E7EAED; }
      .login-page .ecommerce_33 .quantity_selector .control::-moz-selection {
        background: transparent;
        color: #1e3060; }
      .login-page .ecommerce_33 .quantity_selector .control::-webkit-selection {
        background: transparent;
        color: #1e3060; }
      .login-page .ecommerce_33 .quantity_selector .control::selection {
        background: transparent;
        color: #1e3060; }
    .login-page .ecommerce_33 .quantity_selector .input {
      width: 40px;
      height: 36px;
      border-left: 2px solid #E7EAED;
      border-right: 2px solid #E7EAED;
      color: #1e3060;
      font-weight: 500;
      font-size: 16px;
      text-align: center; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_33 .product .inner {
      margin-left: 15px; }
    .login-page .ecommerce_33 .label_total {
      margin-left: 23px; } }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_33 .label_total {
      margin-left: 0; } }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_33 .product_total {
      height: auto; } }
  @media (max-width: 481.98px) {
    .login-page .ecommerce_33 .product .inner {
      margin-top: 20px;
      margin-left: 0;
      max-width: 100%;
      width: 100%; } }
  .login-page .ecommerce_34 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_34 .img_link:hover img {
    opacity: 0.8; }
  .login-page .ecommerce_34 .quantity_selector {
    border: 2px solid #E7EAED;
    overflow: hidden; }
    .login-page .ecommerce_34 .quantity_selector .control {
      width: 36px;
      line-height: 36px;
      background: transparent; }
      .login-page .ecommerce_34 .quantity_selector .control:hover {
        background: #E7EAED; }
      .login-page .ecommerce_34 .quantity_selector .control::-moz-selection {
        background: transparent;
        color: #1e3060; }
      .login-page .ecommerce_34 .quantity_selector .control::-webkit-selection {
        background: transparent;
        color: #1e3060; }
      .login-page .ecommerce_34 .quantity_selector .control::selection {
        background: transparent;
        color: #1e3060; }
    .login-page .ecommerce_34 .quantity_selector .input {
      width: 40px;
      height: 36px;
      border-left: 2px solid #E7EAED;
      border-right: 2px solid #E7EAED;
      color: #1e3060;
      font-weight: 500;
      font-size: 16px;
      text-align: center; }
  .login-page .ecommerce_34 .total,
  .login-page .ecommerce_34 .subtotal,
  .login-page .ecommerce_34 .delivery {
    min-width: 115px; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_34 .address_form {
      padding-right: 40px; } }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_34 .address_form {
      padding-top: 20px;
      padding-right: 0px; }
      .login-page .ecommerce_34 .address_form .block {
        margin-bottom: 15px; } }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_34 .total,
    .login-page .ecommerce_34 .subtotal,
    .login-page .ecommerce_34 .delivery {
      min-width: 50px; } }
  .login-page .ecommerce_35 .slider_menu .slick-track {
    position: relative;
    border-bottom: 3px solid #E7EAED; }
  .login-page .ecommerce_35 .slider_menu a {
    width: 50%; }
    .login-page .ecommerce_35 .slider_menu a:after {
      content: "";
      display: block;
      width: 0%;
      height: 3px;
      background: #1e3060;
      position: absolute;
      right: 0;
      bottom: -3px;
      transition: width 0.2s;
      z-index: 10000; }
    .login-page .ecommerce_35 .slider_menu a:last-child:after {
      left: 0;
      right: auto; }
    .login-page .ecommerce_35 .slider_menu a.slick-current {
      color: #1e3060; }
      .login-page .ecommerce_35 .slider_menu a.slick-current:after {
        width: 100%; }
  .login-page .ecommerce_35 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_35 .img_link:hover img {
    opacity: 0.8; }
  .login-page .ecommerce_35 .quantity_selector {
    border: 2px solid #E7EAED;
    overflow: hidden; }
    .login-page .ecommerce_35 .quantity_selector .control {
      width: 36px;
      line-height: 36px;
      background: transparent; }
      .login-page .ecommerce_35 .quantity_selector .control:hover {
        background: #E7EAED; }
      .login-page .ecommerce_35 .quantity_selector .control::-moz-selection {
        background: transparent;
        color: #1e3060; }
      .login-page .ecommerce_35 .quantity_selector .control::-webkit-selection {
        background: transparent;
        color: #1e3060; }
      .login-page .ecommerce_35 .quantity_selector .control::selection {
        background: transparent;
        color: #1e3060; }
    .login-page .ecommerce_35 .quantity_selector .input {
      width: 40px;
      height: 36px;
      border-left: 2px solid #E7EAED;
      border-right: 2px solid #E7EAED;
      color: #1e3060;
      font-weight: 500;
      font-size: 16px;
      text-align: center; }
  .login-page .ecommerce_35 .total {
    min-width: 115px; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_35 .slider,
    .login-page .ecommerce_35 .slider_menu {
      padding-right: 40px; } }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_35 .slider,
    .login-page .ecommerce_35 .slider_menu {
      padding-right: 0px; }
    .login-page .ecommerce_35 .products {
      padding-top: 0; } }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_35 .slider_menu a {
      line-height: 60px; }
    .login-page .ecommerce_35 .slider {
      margin-top: 40px; }
    .login-page .ecommerce_35 .total {
      min-width: 50px; } }
  .login-page .ecommerce_36 .with_border {
    border-bottom: 2px solid #E7EAED; }
  .login-page .ecommerce_36 .product .with_border {
    padding-top: 28px;
    padding-bottom: 28px; }
  .login-page .ecommerce_36 .quantity_selector .control {
    right: 20px;
    top: 50%;
    width: 16px;
    height: 12px;
    line-height: 8px;
    font-size: 12px; }
    .login-page .ecommerce_36 .quantity_selector .control.greater {
      transform: translateY(-100%); }
  .login-page .ecommerce_36 .remove_product {
    width: 45px;
    height: 45px;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10; }
    .login-page .ecommerce_36 .remove_product:hover svg path {
      stroke: #1e3060; }
    .login-page .ecommerce_36 .remove_product svg path {
      stroke: #1E3060;
      transition: all 0.25s; }
  .login-page .ecommerce_36 .total {
    min-width: 70px; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_36 .total {
      min-width: 65px; } }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_36 .total {
      min-width: 95px; } }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_36 .product .title,
    .login-page .ecommerce_36 .product .variants {
      padding-top: 0px;
      padding-bottom: 0px; }
    .login-page .ecommerce_36 .with_border {
      border: none; }
    .login-page .ecommerce_36 .price_holder {
      position: static; }
    .login-page .ecommerce_36 .remove_product {
      top: 0;
      transform: none; }
    .login-page .ecommerce_36 .total {
      min-width: 0px; } }
  .login-page .ecommerce_37 .holder {
    border: 2px solid #E7EAED; }
  .login-page .ecommerce_37 .delivery_type input[type="radio"]:checked + label {
    background: #1e3060;
    color: #FFFFFF;
    border-color: #1e3060; }
  .login-page .ecommerce_37 .delivery_type input[type="radio"] + label:before {
    display: none; }
  .login-page .ecommerce_37 .delivery_type input[type="radio"] + label {
    line-height: 46px; }
  .login-page .ecommerce_37 .delivery_type label {
    height: 50px;
    border: 2px solid #E7EAED;
    transition: all 0.25s; }
    .login-page .ecommerce_37 .delivery_type label:hover {
      color: #1e3060; }
  @media (max-width: 399.98px) {
    .login-page .ecommerce_37 .holder {
      padding: 30px 20px; } }
  .login-page .ecommerce_38 .product {
    border-bottom: 2px solid #E7EAED; }
  .login-page .ecommerce_38 .remove_product {
    width: 45px;
    height: 45px;
    top: 8px;
    right: -45px;
    cursor: pointer;
    z-index: 10; }
    .login-page .ecommerce_38 .remove_product:hover svg path {
      stroke: #1e3060; }
    .login-page .ecommerce_38 .remove_product svg path {
      stroke: #1E3060;
      transition: all 0.25s; }
  .login-page .ecommerce_38 .total,
  .login-page .ecommerce_38 .subtotal,
  .login-page .ecommerce_38 .delivery {
    min-width: 30px; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_38 .remove_product {
      right: -15px; } }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_38 .btn {
      margin-top: 0px; } }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_38 .total,
    .login-page .ecommerce_38 .subtotal,
    .login-page .ecommerce_38 .delivery {
      min-width: 50px; } }
  @media (max-width: 767.98px) {
    .login-page .application_2 .application_2_left_img {
      max-height: 50vh; } }
  .login-page .application_2 .with_borders:nth-of-type(1) {
    border-bottom: 2px solid #ebeaed;
    border-right: 2px solid #ebeaed; }
  .login-page .application_2 .with_borders:nth-of-type(2) {
    border-bottom: 2px solid #ebeaed;
    border-right: 2px solid #ebeaed; }
  .login-page .application_2 .with_borders:nth-of-type(3) {
    border-bottom: 2px solid #ebeaed; }
  .login-page .application_2 .with_borders:nth-of-type(4) {
    border-right: 2px solid #ebeaed; }
  .login-page .application_2 .with_borders:nth-of-type(5) {
    border-right: 2px solid #ebeaed; }
  @media (max-width: 991.98px) {
    .login-page .application_2 .with_borders:nth-of-type(2) {
      border-right: none; }
    .login-page .application_2 .with_borders:nth-of-type(3) {
      border-right: 2px solid #ebeaed;
      padding-top: 35px; }
    .login-page .application_2 .with_borders:nth-of-type(4) {
      border-right: none;
      border-bottom: 2px solid #ebeaed; } }
  .login-page .application_3 .bg {
    top: 0;
    right: -50px; }
  @media (max-width: 1199.98px) {
    .login-page .application_3 .inner {
      padding-top: 350px; } }
  @media (max-width: 991.98px) {
    .login-page .application_3 .inner {
      padding-top: 300px; } }
  @media (max-width: 767.98px) {
    .login-page .application_3 .inner {
      padding-top: 200px; } }
  @media (max-width: 576.98px) {
    .login-page .application_3 .inner {
      padding-top: 100px; } }
  @media (max-width: 991.98px) {
    .login-page .application_4 .application_4_left_img {
      max-height: 80vh; } }
  .login-page .application_5 .with_box_shadow {
    box-shadow: -50px 30px 70px rgba(13, 0, 0, 0.11); }
    @media (max-width: 767.98px) {
      .login-page .application_5 .with_box_shadow {
        display: none; } }
  .login-page .application_5 .left .absolute {
    bottom: 120px;
    left: -120px; }
    @media (max-width: 1199.98px) {
      .login-page .application_5 .left .absolute {
        bottom: 50px; } }
    @media (max-width: 991.98px) {
      .login-page .application_5 .left .absolute {
        bottom: 120px;
        left: -110px; } }
  .login-page .application_5 .right .absolute {
    top: 30px;
    left: -175px; }
  @media (max-width: 991.98px) {
    .login-page .application_5 h3 {
      margin-bottom: 40px; } }
  .login-page .application_11 ul {
    list-style-type: none; }
    .login-page .application_11 ul li .fas {
      font-size: 10px;
      color: #0074f1;
      padding-right: 15px; }
  @media (max-width: 767.98px) {
    .login-page .application_13 img {
      max-height: 80vh; } }
  .login-page .application_14 .bg {
    top: 0;
    left: 30px; }
  @media (max-width: 1199.98px) {
    .login-page .application_14 .application_14_inner {
      padding-bottom: 200px; } }
  @media (max-width: 991.98px) {
    .login-page .application_14 .application_14_inner {
      padding-bottom: 180px; } }
  @media (max-width: 767.98px) {
    .login-page .application_14 .application_14_inner {
      padding-bottom: 100px; } }
  @media (max-width: 576.98px) {
    .login-page .application_14 .application_14_inner {
      padding-bottom: 50px; } }
  @media (max-width: 767.98px) {
    .login-page .application_15 img {
      max-height: 80vh; } }
  .login-page .application_16 .with_border_radius_10 {
    border: 2px solid #E7EAED;
    border-radius: 10px; }
  .login-page .application_16 .border_on_right {
    border-right: 2px solid #E7EAED; }
  .login-page .application_16 .border_on_top {
    border-top: 2px solid #E7EAED; }
  .login-page .application_16 .bg {
    top: -377px;
    left: -170px; }
  @media (max-width: 991.98px) {
    .login-page .application_16 .bg {
      left: -100px; } }
  @media (max-width: 767.98px) {
    .login-page .application_16 .bg {
      display: none; } }
  .login-page .application_17 .bg {
    top: -376px;
    left: -30px; }
  @media (max-width: 991.98px) {
    .login-page .application_17 .bg {
      left: 0; } }
  @media (max-width: 767.98px) {
    .login-page .application_17 .bg {
      display: none; } }
  .login-page .application_20 #application_20_bg {
    background-position: 50% 50%;
    background-size: contain; }
  .login-page .application_22 #application_22_bg {
    background-position: 50% 50%;
    background-size: contain; }
  .login-page .application_23 .color-dependent:nth-of-type(even) .radius_full {
    background: #0074f1; }
  .login-page .application_23 .color-dependent:nth-of-type(odd) .radius_full {
    background: #0074f1; }
  .login-page .application_23 i {
    color: #000000; }
  .login-page .application_23 .bg {
    position: absolute;
    top: -230px;
    left: -340px; }
  @media (max-width: 1199.98px) {
    .login-page .application_23 .pb-dependent {
      padding-bottom: 150px; }
    .login-page .application_23 .bg {
      top: -250px;
      left: -300px; } }
  @media (max-width: 991.98px) {
    .login-page .application_23 .pb-dependent {
      padding-bottom: 100px; }
    .login-page .application_23 .bg {
      top: -320px;
      left: -250px; } }
  @media (max-width: 767.98px) {
    .login-page .application_23 .pb-dependent {
      padding-bottom: 40px; }
    .login-page .application_23 .bg {
      top: -530px;
      left: -180px; } }
  @media (max-width: 575.98px) {
    .login-page .application_23 .bg {
      display: none; } }
  .login-page .application_24 ul {
    list-style: none;
    padding-left: 0; }
    .login-page .application_24 ul li {
      display: flex;
      justify-content: flex-start;
      align-items: align-start; }
      .login-page .application_24 ul li:before {
        content: "●";
        color: #1e3060;
        margin-right: 12px; }
  @media (max-width: 767.98px) {
    .login-page .application_25 .text-nowrap {
      white-space: normal !important; } }
  @media (max-width: 767.98px) {
    .login-page .application_26 img {
      max-height: 80vh; } }
