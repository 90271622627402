@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,800);
@charset "UTF-8";
.auth-form {
  max-width: 370px; }

.auth-style {
  font-size: 16px;
  font-family: "Open Sans", sans-serif !important;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  margin: 0; }

@font-face {
  .auth-style {
    font-family: Chinese Quote;
    src: local("PingFang SC"), local("SimSun");
    unicode-range: U+2018, U+2019, U+201c, U+201d; } }
  .auth-style input::-ms-clear,
  .auth-style input::-ms-reveal {
    display: none; }
  .auth-style *,
  .auth-style :after,
  .auth-style :before {
    box-sizing: border-box; }

@-ms-viewport {
  .auth-style {
    width: device-width; } }
  .auth-style article,
  .auth-style aside,
  .auth-style dialog,
  .auth-style figcaption,
  .auth-style figure,
  .auth-style footer,
  .auth-style header,
  .auth-style hgroup,
  .auth-style main,
  .auth-style nav,
  .auth-style section {
    display: block; }
  .auth-style [tabindex="-1"]:focus {
    outline: none !important; }
  .auth-style hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible; }
  .auth-style h1,
  .auth-style h2,
  .auth-style h3,
  .auth-style h4,
  .auth-style h5,
  .auth-style h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-family: "Open Sans", sans-serif !important;
    line-height: 1.5 !important; }
  .auth-style p {
    margin-top: 0;
    margin-bottom: 1em; }
  .auth-style abbr[data-original-title],
  .auth-style abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0; }
  .auth-style address {
    margin-bottom: 1em;
    font-style: normal;
    line-height: inherit; }
  .auth-style input[type="number"],
  .auth-style input[type="password"],
  .auth-style input[type="text"],
  .auth-style textarea {
    -webkit-appearance: none; }
  .auth-style dl,
  .auth-style ol,
  .auth-style ul {
    margin-top: 0;
    margin-bottom: 1em; }
  .auth-style ol ol,
  .auth-style ol ul,
  .auth-style ul ol,
  .auth-style ul ul {
    margin-bottom: 0; }
  .auth-style dt {
    font-weight: 500; }
  .auth-style dd {
    margin-bottom: 0.5em;
    margin-left: 0; }
  .auth-style blockquote {
    margin: 0 0 1em; }
  .auth-style dfn {
    font-style: italic; }
  .auth-style b,
  .auth-style strong {
    font-weight: bolder; }
  .auth-style small {
    font-size: 80%; }
  .auth-style sub,
  .auth-style sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline; }
  .auth-style sub {
    bottom: -0.25em; }
  .auth-style sup {
    top: -0.5em; }
  .auth-style a {
    color: #1890ff;
    background-color: transparent;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects; }
  .auth-style a:focus {
    text-decoration: underline;
    -webkit-text-decoration-skip: ink;
    text-decoration-skip-ink: auto; }
  .auth-style a:hover {
    color: #40a9ff; }
  .auth-style a:active {
    color: #096dd9; }
  .auth-style a:active,
  .auth-style a:hover {
    outline: 0;
    text-decoration: none; }
  .auth-style a[disabled] {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    pointer-events: none; }
  .auth-style code,
  .auth-style kbd,
  .auth-style pre,
  .auth-style samp {
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
    font-size: 1em; }
  .auth-style pre {
    margin-top: 0;
    margin-bottom: 1em;
    overflow: auto; }
  .auth-style figure {
    margin: 0 0 1em; }
  .auth-style img {
    vertical-align: middle;
    border-style: none; }
  .auth-style svg:not(:root) {
    overflow: hidden; }
  .auth-style [role="button"],
  .auth-style a,
  .auth-style area,
  .auth-style button,
  .auth-style input:not([type="range"]),
  .auth-style label,
  .auth-style select,
  .auth-style summary,
  .auth-style textarea {
    touch-action: manipulation; }
  .auth-style table {
    border-collapse: collapse; }
  .auth-style caption {
    padding-top: 0.75em;
    padding-bottom: 0.3em;
    color: rgba(0, 0, 0, 0.45);
    text-align: left;
    caption-side: bottom; }
  .auth-style th {
    text-align: inherit; }
  .auth-style button,
  .auth-style input,
  .auth-style optgroup,
  .auth-style select,
  .auth-style textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit; }
  .auth-style button,
  .auth-style input {
    overflow: visible; }
  .auth-style button,
  .auth-style select {
    text-transform: none; }
  .auth-style [type="reset"],
  .auth-style [type="submit"],
  .auth-style button,
  .auth-style html [type="button"] {
    -webkit-appearance: button; }
  .auth-style [type="button"]::-moz-focus-inner,
  .auth-style [type="reset"]::-moz-focus-inner,
  .auth-style [type="submit"]::-moz-focus-inner,
  .auth-style button::-moz-focus-inner {
    padding: 0;
    border-style: none; }
  .auth-style input[type="checkbox"],
  .auth-style input[type="radio"] {
    box-sizing: border-box;
    padding: 0; }
  .auth-style input[type="date"],
  .auth-style input[type="datetime-local"],
  .auth-style input[type="month"],
  .auth-style input[type="time"] {
    -webkit-appearance: listbox; }
  .auth-style textarea {
    overflow: auto;
    resize: vertical; }
  .auth-style fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0; }
  .auth-style legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5em;
    font-size: 1.5em;
    line-height: inherit;
    color: inherit;
    white-space: normal; }
  .auth-style progress {
    vertical-align: baseline; }
  .auth-style [type="number"]::-webkit-inner-spin-button,
  .auth-style [type="number"]::-webkit-outer-spin-button {
    height: auto; }
  .auth-style [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none; }
  .auth-style [type="search"]::-webkit-search-cancel-button,
  .auth-style [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }
  .auth-style ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button; }
  .auth-style output {
    display: inline-block; }
  .auth-style summary {
    display: list-item; }
  .auth-style template {
    display: none; }
  .auth-style [hidden] {
    display: none !important; }
  .auth-style mark {
    padding: 0.2em;
    background-color: #feffe6; }
  .auth-style ::-moz-selection {
    background: #1890ff;
    color: #fff; }
  .auth-style ::selection {
    background: #1890ff;
    color: #fff; }
  .auth-style .clearfix {
    zoom: 1; }
  .auth-style .clearfix:after,
  .auth-style .clearfix:before {
    content: "";
    display: table; }
  .auth-style .clearfix:after {
    clear: both; }
  .auth-style .anticon {
    display: inline-block;
    font-style: normal;
    vertical-align: 0.1em;
    text-align: center;
    text-transform: none;
    line-height: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .auth-style .anticon > * {
    line-height: 1; }
  .auth-style .anticon svg {
    display: inline-block; }
  .auth-style .anticon:before {
    display: none; }
  .auth-style .anticon .anticon-icon {
    display: block; }
  .auth-style .anticon-spin,
  .auth-style .anticon-spin:before {
    display: inline-block;
    -webkit-animation: loadingCircle 1s linear infinite;
    animation: loadingCircle 1s linear infinite; }
  .auth-style .fade-appear,
  .auth-style .fade-enter,
  .auth-style .fade-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .auth-style .fade-appear.fade-appear-active,
  .auth-style .fade-enter.fade-enter-active {
    -webkit-animation-name: antFadeIn;
    animation-name: antFadeIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .auth-style .fade-leave.fade-leave-active {
    -webkit-animation-name: antFadeOut;
    animation-name: antFadeOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .auth-style .fade-appear,
  .auth-style .fade-enter {
    opacity: 0; }
  .auth-style .fade-appear,
  .auth-style .fade-enter,
  .auth-style .fade-leave {
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear; }

@-webkit-keyframes antFadeIn {
  0% {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes antFadeIn {
  0% {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes antFadeOut {
  0% {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes antFadeOut {
  0% {
    opacity: 1; }
  to {
    opacity: 0; } }
  .auth-style .move-up-appear,
  .auth-style .move-up-enter,
  .auth-style .move-up-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .auth-style .move-up-appear.move-up-appear-active,
  .auth-style .move-up-enter.move-up-enter-active {
    -webkit-animation-name: antMoveUpIn;
    animation-name: antMoveUpIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .auth-style .move-up-leave.move-up-leave-active {
    -webkit-animation-name: antMoveUpOut;
    animation-name: antMoveUpOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .auth-style .move-up-appear,
  .auth-style .move-up-enter {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }
  .auth-style .move-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34); }
  .auth-style .move-down-appear,
  .auth-style .move-down-enter,
  .auth-style .move-down-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .auth-style .move-down-appear.move-down-appear-active,
  .auth-style .move-down-enter.move-down-enter-active {
    -webkit-animation-name: antMoveDownIn;
    animation-name: antMoveDownIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .auth-style .move-down-leave.move-down-leave-active {
    -webkit-animation-name: antMoveDownOut;
    animation-name: antMoveDownOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .auth-style .move-down-appear,
  .auth-style .move-down-enter {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }
  .auth-style .move-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34); }
  .auth-style .move-left-appear,
  .auth-style .move-left-enter,
  .auth-style .move-left-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .auth-style .move-left-appear.move-left-appear-active,
  .auth-style .move-left-enter.move-left-enter-active {
    -webkit-animation-name: antMoveLeftIn;
    animation-name: antMoveLeftIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .auth-style .move-left-leave.move-left-leave-active {
    -webkit-animation-name: antMoveLeftOut;
    animation-name: antMoveLeftOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .auth-style .move-left-appear,
  .auth-style .move-left-enter {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }
  .auth-style .move-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34); }
  .auth-style .move-right-appear,
  .auth-style .move-right-enter,
  .auth-style .move-right-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .auth-style .move-right-appear.move-right-appear-active,
  .auth-style .move-right-enter.move-right-enter-active {
    -webkit-animation-name: antMoveRightIn;
    animation-name: antMoveRightIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .auth-style .move-right-leave.move-right-leave-active {
    -webkit-animation-name: antMoveRightOut;
    animation-name: antMoveRightOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .auth-style .move-right-appear,
  .auth-style .move-right-enter {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }
  .auth-style .move-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
    animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34); }

@-webkit-keyframes antMoveDownIn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0; }
  to {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes antMoveDownIn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0; }
  to {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes antMoveDownOut {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  to {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0; } }

@keyframes antMoveDownOut {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  to {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0; } }

@-webkit-keyframes antMoveLeftIn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0; }
  to {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@keyframes antMoveLeftIn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0; }
  to {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; } }

@-webkit-keyframes antMoveLeftOut {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  to {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0; } }

@keyframes antMoveLeftOut {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  to {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0; } }

@-webkit-keyframes antMoveRightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%); }
  to {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes antMoveRightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%); }
  to {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes antMoveRightOut {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  to {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0; } }

@keyframes antMoveRightOut {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1; }
  to {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    opacity: 0; } }

@-webkit-keyframes antMoveUpIn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0; }
  to {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes antMoveUpIn {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0; }
  to {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@-webkit-keyframes antMoveUpOut {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  to {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0; } }

@keyframes antMoveUpOut {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; }
  to {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    opacity: 0; } }

@-webkit-keyframes loadingCircle {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn); } }

@keyframes loadingCircle {
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn); } }
  .auth-style [ant-click-animating-without-extra-node],
  .auth-style [ant-click-animating] {
    position: relative; }
  .auth-style .ant-click-animating-node,
  .auth-style [ant-click-animating-without-extra-node]:after {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    border-radius: inherit;
    border: 0 solid #1890ff;
    opacity: 0.2;
    -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
    animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    display: block;
    pointer-events: none; }

@-webkit-keyframes waveEffect {
  to {
    top: -6px;
    left: -6px;
    bottom: -6px;
    right: -6px;
    border-width: 6px; } }

@keyframes waveEffect {
  to {
    top: -6px;
    left: -6px;
    bottom: -6px;
    right: -6px;
    border-width: 6px; } }

@-webkit-keyframes fadeEffect {
  to {
    opacity: 0; } }

@keyframes fadeEffect {
  to {
    opacity: 0; } }
  .auth-style .slide-up-appear,
  .auth-style .slide-up-enter,
  .auth-style .slide-up-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .auth-style .slide-up-appear.slide-up-appear-active,
  .auth-style .slide-up-enter.slide-up-enter-active {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .auth-style .slide-up-leave.slide-up-leave-active {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .auth-style .slide-up-appear,
  .auth-style .slide-up-enter {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }
  .auth-style .slide-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }
  .auth-style .slide-down-appear,
  .auth-style .slide-down-enter,
  .auth-style .slide-down-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .auth-style .slide-down-appear.slide-down-appear-active,
  .auth-style .slide-down-enter.slide-down-enter-active {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .auth-style .slide-down-leave.slide-down-leave-active {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .auth-style .slide-down-appear,
  .auth-style .slide-down-enter {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }
  .auth-style .slide-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }
  .auth-style .slide-left-appear,
  .auth-style .slide-left-enter,
  .auth-style .slide-left-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .auth-style .slide-left-appear.slide-left-appear-active,
  .auth-style .slide-left-enter.slide-left-enter-active {
    -webkit-animation-name: antSlideLeftIn;
    animation-name: antSlideLeftIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .auth-style .slide-left-leave.slide-left-leave-active {
    -webkit-animation-name: antSlideLeftOut;
    animation-name: antSlideLeftOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .auth-style .slide-left-appear,
  .auth-style .slide-left-enter {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }
  .auth-style .slide-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }
  .auth-style .slide-right-appear,
  .auth-style .slide-right-enter,
  .auth-style .slide-right-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .auth-style .slide-right-appear.slide-right-appear-active,
  .auth-style .slide-right-enter.slide-right-enter-active {
    -webkit-animation-name: antSlideRightIn;
    animation-name: antSlideRightIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .auth-style .slide-right-leave.slide-right-leave-active {
    -webkit-animation-name: antSlideRightOut;
    animation-name: antSlideRightOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .auth-style .slide-right-appear,
  .auth-style .slide-right-enter {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1); }
  .auth-style .slide-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06); }

@-webkit-keyframes antSlideUpIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8); }
  to {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); } }

@keyframes antSlideUpIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8); }
  to {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); } }

@-webkit-keyframes antSlideUpOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); }
  to {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8); } }

@keyframes antSlideUpOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); }
  to {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8); } }

@-webkit-keyframes antSlideDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8); }
  to {
    opacity: 1;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); } }

@keyframes antSlideDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8); }
  to {
    opacity: 1;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); } }

@-webkit-keyframes antSlideDownOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); }
  to {
    opacity: 0;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8); } }

@keyframes antSlideDownOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); }
  to {
    opacity: 0;
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    -webkit-transform: scaleY(0.8);
    transform: scaleY(0.8); } }

@-webkit-keyframes antSlideLeftIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); }
  to {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); } }

@keyframes antSlideLeftIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); }
  to {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); } }

@-webkit-keyframes antSlideLeftOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); }
  to {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); } }

@keyframes antSlideLeftOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); }
  to {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); } }

@-webkit-keyframes antSlideRightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); }
  to {
    opacity: 1;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); } }

@keyframes antSlideRightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); }
  to {
    opacity: 1;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); } }

@-webkit-keyframes antSlideRightOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); }
  to {
    opacity: 0;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); } }

@keyframes antSlideRightOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); }
  to {
    opacity: 0;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scaleX(0.8);
    transform: scaleX(0.8); } }
  .auth-style .swing-appear,
  .auth-style .swing-enter {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .auth-style .swing-appear.swing-appear-active,
  .auth-style .swing-enter.swing-enter-active {
    -webkit-animation-name: antSwingIn;
    animation-name: antSwingIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }

@-webkit-keyframes antSwingIn {
  0%,
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  20% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  40% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  60% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px); }
  80% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px); } }

@keyframes antSwingIn {
  0%,
  to {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  20% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  40% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  60% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px); }
  80% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px); } }
  .auth-style .zoom-appear,
  .auth-style .zoom-enter,
  .auth-style .zoom-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .auth-style .zoom-appear.zoom-appear-active,
  .auth-style .zoom-enter.zoom-enter-active {
    -webkit-animation-name: antZoomIn;
    animation-name: antZoomIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .auth-style .zoom-leave.zoom-leave-active {
    -webkit-animation-name: antZoomOut;
    animation-name: antZoomOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .auth-style .zoom-appear,
  .auth-style .zoom-enter {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }
  .auth-style .zoom-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }
  .auth-style .zoom-big-appear,
  .auth-style .zoom-big-enter,
  .auth-style .zoom-big-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .auth-style .zoom-big-appear.zoom-big-appear-active,
  .auth-style .zoom-big-enter.zoom-big-enter-active {
    -webkit-animation-name: antZoomBigIn;
    animation-name: antZoomBigIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .auth-style .zoom-big-leave.zoom-big-leave-active {
    -webkit-animation-name: antZoomBigOut;
    animation-name: antZoomBigOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .auth-style .zoom-big-appear,
  .auth-style .zoom-big-enter {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }
  .auth-style .zoom-big-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }
  .auth-style .zoom-big-fast-appear,
  .auth-style .zoom-big-fast-enter,
  .auth-style .zoom-big-fast-leave {
    -webkit-animation-duration: 0.1s;
    animation-duration: 0.1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .auth-style .zoom-big-fast-appear.zoom-big-fast-appear-active,
  .auth-style .zoom-big-fast-enter.zoom-big-fast-enter-active {
    -webkit-animation-name: antZoomBigIn;
    animation-name: antZoomBigIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .auth-style .zoom-big-fast-leave.zoom-big-fast-leave-active {
    -webkit-animation-name: antZoomBigOut;
    animation-name: antZoomBigOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .auth-style .zoom-big-fast-appear,
  .auth-style .zoom-big-fast-enter {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }
  .auth-style .zoom-big-fast-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }
  .auth-style .zoom-up-appear,
  .auth-style .zoom-up-enter,
  .auth-style .zoom-up-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .auth-style .zoom-up-appear.zoom-up-appear-active,
  .auth-style .zoom-up-enter.zoom-up-enter-active {
    -webkit-animation-name: antZoomUpIn;
    animation-name: antZoomUpIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .auth-style .zoom-up-leave.zoom-up-leave-active {
    -webkit-animation-name: antZoomUpOut;
    animation-name: antZoomUpOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .auth-style .zoom-up-appear,
  .auth-style .zoom-up-enter {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }
  .auth-style .zoom-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }
  .auth-style .zoom-down-appear,
  .auth-style .zoom-down-enter,
  .auth-style .zoom-down-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .auth-style .zoom-down-appear.zoom-down-appear-active,
  .auth-style .zoom-down-enter.zoom-down-enter-active {
    -webkit-animation-name: antZoomDownIn;
    animation-name: antZoomDownIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .auth-style .zoom-down-leave.zoom-down-leave-active {
    -webkit-animation-name: antZoomDownOut;
    animation-name: antZoomDownOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .auth-style .zoom-down-appear,
  .auth-style .zoom-down-enter {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }
  .auth-style .zoom-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }
  .auth-style .zoom-left-appear,
  .auth-style .zoom-left-enter,
  .auth-style .zoom-left-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .auth-style .zoom-left-appear.zoom-left-appear-active,
  .auth-style .zoom-left-enter.zoom-left-enter-active {
    -webkit-animation-name: antZoomLeftIn;
    animation-name: antZoomLeftIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .auth-style .zoom-left-leave.zoom-left-leave-active {
    -webkit-animation-name: antZoomLeftOut;
    animation-name: antZoomLeftOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .auth-style .zoom-left-appear,
  .auth-style .zoom-left-enter {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }
  .auth-style .zoom-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }
  .auth-style .zoom-right-appear,
  .auth-style .zoom-right-enter,
  .auth-style .zoom-right-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .auth-style .zoom-right-appear.zoom-right-appear-active,
  .auth-style .zoom-right-enter.zoom-right-enter-active {
    -webkit-animation-name: antZoomRightIn;
    animation-name: antZoomRightIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .auth-style .zoom-right-leave.zoom-right-leave-active {
    -webkit-animation-name: antZoomRightOut;
    animation-name: antZoomRightOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .auth-style .zoom-right-appear,
  .auth-style .zoom-right-enter {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
    animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1); }
  .auth-style .zoom-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86); }

@-webkit-keyframes antZoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.2);
    transform: scale(0.2); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes antZoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.2);
    transform: scale(0.2); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.2);
    transform: scale(0.2); } }

@keyframes antZoomOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.2);
    transform: scale(0.2); } }

@-webkit-keyframes antZoomBigIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  to {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes antZoomBigIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  to {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

@keyframes antZoomBigOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

@-webkit-keyframes antZoomUpIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  to {
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes antZoomUpIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  to {
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes antZoomUpOut {
  0% {
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transform: scale(1);
    transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

@keyframes antZoomUpOut {
  0% {
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transform: scale(1);
    transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

@-webkit-keyframes antZoomLeftIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  to {
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes antZoomLeftIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  to {
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes antZoomLeftOut {
  0% {
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transform: scale(1);
    transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

@keyframes antZoomLeftOut {
  0% {
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transform: scale(1);
    transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

@-webkit-keyframes antZoomRightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  to {
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes antZoomRightIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  to {
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes antZoomRightOut {
  0% {
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(1);
    transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

@keyframes antZoomRightOut {
  0% {
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(1);
    transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

@-webkit-keyframes antZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  to {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes antZoomDownIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  to {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes antZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1);
    transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }

@keyframes antZoomDownOut {
  0% {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(1);
    transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); } }
  .auth-style .ant-motion-collapse {
    overflow: hidden; }
  .auth-style .ant-motion-collapse-active {
    transition: height 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important; }
  .auth-style .ant-affix {
    position: fixed;
    z-index: 10; }
  .auth-style .ant-alert {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    list-style: none;
    position: relative;
    padding: 8px 15px 8px 37px;
    border-radius: 4px; }
  .auth-style .ant-alert.ant-alert-no-icon {
    padding: 8px 15px; }
  .auth-style .ant-alert.ant-alert-closable {
    padding-right: 30px; }
  .auth-style .ant-alert-icon {
    top: 11.5px;
    left: 16px;
    position: absolute; }
  .auth-style .ant-alert-description {
    font-size: 14px;
    line-height: 22px;
    display: none; }
  .auth-style .ant-alert-success {
    border: 1px solid #b7eb8f;
    background-color: #f6ffed; }
  .auth-style .ant-alert-success .ant-alert-icon {
    color: #52c41a; }
  .auth-style .ant-alert-info {
    border: 1px solid #91d5ff;
    background-color: #e6f7ff; }
  .auth-style .ant-alert-info .ant-alert-icon {
    color: #1890ff; }
  .auth-style .ant-alert-warning {
    border: 1px solid #ffe58f;
    background-color: #fffbe6; }
  .auth-style .ant-alert-warning .ant-alert-icon {
    color: #faad14; }
  .auth-style .ant-alert-error {
    border: 1px solid #ffa39e;
    background-color: #fff1f0; }
  .auth-style .ant-alert-error .ant-alert-icon {
    color: #f5222d; }
  .auth-style .ant-alert-close-icon {
    font-size: 12px;
    position: absolute;
    right: 16px;
    top: 8px;
    line-height: 22px;
    overflow: hidden;
    cursor: pointer; }
  .auth-style .ant-alert-close-icon .anticon-close {
    color: rgba(0, 0, 0, 0.45);
    transition: color 0.3s; }
  .auth-style .ant-alert-close-icon .anticon-close:hover {
    color: rgba(0, 0, 0, 0.75); }
  .auth-style .ant-alert-close-text {
    position: absolute;
    right: 16px; }
  .auth-style .ant-alert-with-description {
    padding: 15px 15px 15px 64px;
    position: relative;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 1.5; }
  .auth-style .ant-alert-with-description.ant-alert-no-icon {
    padding: 15px; }
  .auth-style .ant-alert-with-description .ant-alert-icon {
    position: absolute;
    top: 16px;
    left: 24px;
    font-size: 24px; }
  .auth-style .ant-alert-with-description .ant-alert-close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    font-size: 14px; }
  .auth-style .ant-alert-with-description .ant-alert-message {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    display: block;
    margin-bottom: 4px; }
  .auth-style .ant-alert-with-description .ant-alert-description {
    display: block; }
  .auth-style .ant-alert.ant-alert-close {
    height: 0 !important;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0; }
  .auth-style .ant-alert-slide-up-leave {
    -webkit-animation: antAlertSlideUpOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation: antAlertSlideUpOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; }
  .auth-style .ant-alert-banner {
    border-radius: 0;
    border: 0;
    margin-bottom: 0; }

@-webkit-keyframes antAlertSlideUpIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(0);
    transform: scaleY(0); }
  to {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); } }

@keyframes antAlertSlideUpIn {
  0% {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(0);
    transform: scaleY(0); }
  to {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); } }

@-webkit-keyframes antAlertSlideUpOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); }
  to {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(0);
    transform: scaleY(0); } }

@keyframes antAlertSlideUpOut {
  0% {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); }
  to {
    opacity: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scaleY(0);
    transform: scaleY(0); } }
  .auth-style .ant-anchor {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    list-style: none;
    position: relative;
    padding: 0 0 0 2px; }
  .auth-style .ant-anchor-wrapper {
    background-color: #fff;
    overflow: auto;
    padding-left: 4px;
    margin-left: -4px; }
  .auth-style .ant-anchor-ink {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0; }
  .auth-style .ant-anchor-ink:before {
    content: " ";
    position: relative;
    width: 2px;
    height: 100%;
    display: block;
    background-color: #e8e8e8;
    margin: 0 auto; }
  .auth-style .ant-anchor-ink-ball {
    display: none;
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    border: 2px solid #1890ff;
    background-color: #fff;
    left: 50%;
    transition: top 0.3s ease-in-out;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%); }
  .auth-style .ant-anchor-ink-ball.visible {
    display: inline-block; }
  .auth-style .ant-anchor.fixed .ant-anchor-ink .ant-anchor-ink-ball {
    display: none; }
  .auth-style .ant-anchor-link {
    padding: 7px 0 7px 16px;
    line-height: 1.143; }
  .auth-style .ant-anchor-link-title {
    display: block;
    position: relative;
    transition: all 0.3s;
    color: rgba(0, 0, 0, 0.65);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 6px; }
  .auth-style .ant-anchor-link-title:only-child {
    margin-bottom: 0; }
  .auth-style .ant-anchor-link-active > .ant-anchor-link-title {
    color: #1890ff; }
  .auth-style .ant-anchor-link .ant-anchor-link {
    padding-top: 5px;
    padding-bottom: 5px; }
  .auth-style .ant-select-auto-complete {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none; }
  .auth-style .ant-select-auto-complete.ant-select .ant-select-selection {
    border: 0;
    box-shadow: none; }
  .auth-style .ant-select-auto-complete.ant-select .ant-select-selection__rendered {
    margin-left: 0;
    margin-right: 0;
    height: 100%;
    line-height: 32px; }
  .auth-style .ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
    margin-left: 12px;
    margin-right: 12px; }
  .auth-style .ant-select-auto-complete.ant-select .ant-select-selection--single {
    height: auto; }
  .auth-style .ant-select-auto-complete.ant-select .ant-select-search--inline {
    position: static;
    float: left; }
  .auth-style .ant-select-auto-complete.ant-select-allow-clear
.ant-select-selection:hover
.ant-select-selection__rendered {
    margin-right: 0 !important; }
  .auth-style .ant-select-auto-complete.ant-select .ant-input {
    background: transparent;
    border-width: 1px;
    line-height: 1.5;
    height: 32px; }
  .auth-style .ant-select-auto-complete.ant-select .ant-input:focus,
  .auth-style .ant-select-auto-complete.ant-select .ant-input:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important; }
  .auth-style .ant-select-auto-complete.ant-select .ant-input[disabled] {
    background-color: #f5f5f5;
    opacity: 1;
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-select-auto-complete.ant-select .ant-input[disabled]:hover {
    border-color: #e6d8d8;
    border-right-width: 1px !important; }
  .auth-style .ant-select-auto-complete.ant-select-lg .ant-select-selection__rendered {
    line-height: 40px; }
  .auth-style .ant-select-auto-complete.ant-select-lg .ant-input {
    padding-top: 6px;
    padding-bottom: 6px;
    height: 40px; }
  .auth-style .ant-select-auto-complete.ant-select-sm .ant-select-selection__rendered {
    line-height: 24px; }
  .auth-style .ant-select-auto-complete.ant-select-sm .ant-input {
    padding-top: 1px;
    padding-bottom: 1px;
    height: 24px; }
  .auth-style .ant-avatar {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;
    text-align: center;
    background: #ccc;
    color: #fff;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 50%; }
  .auth-style .ant-avatar-image {
    background: transparent; }
  .auth-style .ant-avatar-string {
    position: absolute;
    left: 50%;
    -webkit-transform-origin: 0 center;
    transform-origin: 0 center; }
  .auth-style .ant-avatar.ant-avatar-icon {
    font-size: 18px; }
  .auth-style .ant-avatar-lg {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%; }
  .auth-style .ant-avatar-lg-string {
    position: absolute;
    left: 50%;
    -webkit-transform-origin: 0 center;
    transform-origin: 0 center; }
  .auth-style .ant-avatar-lg.ant-avatar-icon {
    font-size: 24px; }
  .auth-style .ant-avatar-sm {
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%; }
  .auth-style .ant-avatar-sm-string {
    position: absolute;
    left: 50%;
    -webkit-transform-origin: 0 center;
    transform-origin: 0 center; }
  .auth-style .ant-avatar-sm.ant-avatar-icon {
    font-size: 14px; }
  .auth-style .ant-avatar-square {
    border-radius: 4px; }
  .auth-style .ant-avatar > img {
    width: 100%;
    height: 100%;
    display: block; }
  .auth-style .ant-back-top {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    z-index: 10;
    position: fixed;
    right: 100px;
    bottom: 50px;
    height: 40px;
    width: 40px;
    cursor: pointer; }
  .auth-style .ant-back-top-content {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.45);
    color: #fff;
    text-align: center;
    overflow: hidden; }
  .auth-style .ant-back-top-content,
  .auth-style .ant-back-top-content:hover {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .auth-style .ant-back-top-content:hover {
    background-color: rgba(0, 0, 0, 0.65); }
  .auth-style .ant-back-top-icon {
    margin: 12px auto;
    width: 14px;
    height: 16px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAAABGdBTUEAALGPC/xhBQAAAbtJREFUWAntmMtKw0AUhhMvS5cuxILgQlRUpIggIoKIIoigG1eC+AA+jo+i6FIXBfeuXIgoeKVeitVWJX5HWhhDksnUpp3FDPyZk3Nm5nycmZKkXhAEOXSA3lG7muTeRzmfy6HneUvIhnYkQK+Q9NhAA0Opg0vBEhjBKHiyb8iGMyQMOYuK41BcBSypAL+MYXSKjtFAW7EAGEO3qN4uMQbbAkXiSfRQJ1H6a+yhlkKRcAoVFYiweYNjtCVQJJpBz2GCiPt7fBOZQpFgDpUikse5HgnkM4Fi4QX0Fpc5wf9EbLqpUCy4jMoJSXWhFwbMNgWKhVbRhy5jirhs9fy/oFhgHVVTJEs7RLZ8sSEoJm6iz7SZDMbJ+/OKERQTttCXQRLToRUmrKWCYuA2+jbN0MB4OQobYShfdTCgn/sL1K36M7TLrN3n+758aPy2rrpR6+/od5E8tf/A1uLS9aId5T7J3CNYihkQ4D9PiMdMC7mp4rjB9kjFjZp8BlnVHJBuO1yFXIV0FdDF3RlyFdJVQBdv5AxVdIsq8apiZ2PyYO1EVykesGfZEESsCkweyR8MUW+V8uJ1gkYipmpdP1pm2aJVPEGzAAAAAElFTkSuQmCC) 100%/100% no-repeat; }
  @media screen and (max-width: 768px) {
    .auth-style .ant-back-top {
      right: 60px; } }
  @media screen and (max-width: 480px) {
    .auth-style .ant-back-top {
      right: 20px; } }
  .auth-style .ant-badge {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    line-height: 1;
    color: unset; }
  .auth-style .ant-badge-count {
    height: 20px;
    border-radius: 10px;
    min-width: 20px;
    background: #f5222d;
    color: #fff;
    line-height: 20px;
    text-align: center;
    padding: 0 6px;
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    box-shadow: 0 0 0 1px #fff;
    z-index: 10; }
  .auth-style .ant-badge-count a,
  .auth-style .ant-badge-count a:hover {
    color: #fff; }
  .auth-style .ant-badge-multiple-words {
    padding: 0 8px; }
  .auth-style .ant-badge-dot {
    height: 6px;
    width: 6px;
    border-radius: 100%;
    background: #f5222d;
    z-index: 10;
    box-shadow: 0 0 0 1px #fff; }
  .auth-style .ant-badge-count,
  .auth-style .ant-badge-dot,
  .auth-style .ant-badge .ant-scroll-number-custom-component {
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0; }
  .auth-style .ant-badge-status {
    line-height: inherit;
    vertical-align: baseline; }
  .auth-style .ant-badge-status-dot {
    width: 6px;
    height: 6px;
    display: inline-block;
    border-radius: 50%;
    vertical-align: middle;
    position: relative;
    top: -1px; }
  .auth-style .ant-badge-status-success {
    background-color: #52c41a; }
  .auth-style .ant-badge-status-processing {
    background-color: #1890ff;
    position: relative; }
  .auth-style .ant-badge-status-processing:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #1890ff;
    content: "";
    -webkit-animation: antStatusProcessing 1.2s ease-in-out infinite;
    animation: antStatusProcessing 1.2s ease-in-out infinite; }
  .auth-style .ant-badge-status-default {
    background-color: #d9d9d9; }
  .auth-style .ant-badge-status-error {
    background-color: #f5222d; }
  .auth-style .ant-badge-status-warning {
    background-color: #faad14; }
  .auth-style .ant-badge-status-text {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    margin-left: 8px; }
  .auth-style .ant-badge-zoom-appear,
  .auth-style .ant-badge-zoom-enter {
    -webkit-animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; }
  .auth-style .ant-badge-zoom-leave {
    -webkit-animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
    animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; }
  .auth-style .ant-badge-not-a-wrapper {
    vertical-align: middle; }
  .auth-style .ant-badge-not-a-wrapper .ant-scroll-number {
    top: auto;
    display: block;
    position: relative; }
  .auth-style .ant-badge-not-a-wrapper .ant-badge-count {
    -webkit-transform: none;
    transform: none; }

@-webkit-keyframes antStatusProcessing {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.5; }
  to {
    -webkit-transform: scale(2.4);
    transform: scale(2.4);
    opacity: 0; } }

@keyframes antStatusProcessing {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0.5; }
  to {
    -webkit-transform: scale(2.4);
    transform: scale(2.4);
    opacity: 0; } }
  .auth-style .ant-scroll-number {
    overflow: hidden; }
  .auth-style .ant-scroll-number-only {
    display: inline-block;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 20px; }
  .auth-style .ant-scroll-number-only > p {
    height: 20px;
    margin: 0; }

@-webkit-keyframes antZoomBadgeIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translate(50%, -50%);
    transform: scale(0) translate(50%, -50%); }
  to {
    -webkit-transform: scale(1) translate(50%, -50%);
    transform: scale(1) translate(50%, -50%); } }

@keyframes antZoomBadgeIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0) translate(50%, -50%);
    transform: scale(0) translate(50%, -50%); }
  to {
    -webkit-transform: scale(1) translate(50%, -50%);
    transform: scale(1) translate(50%, -50%); } }

@-webkit-keyframes antZoomBadgeOut {
  0% {
    -webkit-transform: scale(1) translate(50%, -50%);
    transform: scale(1) translate(50%, -50%); }
  to {
    opacity: 0;
    -webkit-transform: scale(0) translate(50%, -50%);
    transform: scale(0) translate(50%, -50%); } }

@keyframes antZoomBadgeOut {
  0% {
    -webkit-transform: scale(1) translate(50%, -50%);
    transform: scale(1) translate(50%, -50%); }
  to {
    opacity: 0;
    -webkit-transform: scale(0) translate(50%, -50%);
    transform: scale(0) translate(50%, -50%); } }
  .auth-style .ant-breadcrumb {
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px; }
  .auth-style .ant-breadcrumb .anticon {
    font-size: 14px; }
  .auth-style .ant-breadcrumb a {
    color: rgba(0, 0, 0, 0.45);
    transition: color 0.3s; }
  .auth-style .ant-breadcrumb a:hover {
    color: #40a9ff; }
  .auth-style .ant-breadcrumb > span:last-child {
    color: rgba(0, 0, 0, 0.65); }
  .auth-style .ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
    display: none; }
  .auth-style .ant-breadcrumb-separator {
    margin: 0 8px;
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-breadcrumb-link > .anticon + span {
    margin-left: 4px; }
  .auth-style .ant-btn {
    line-height: 1.499;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    padding: 0 15px;
    font-size: 14px;
    border-radius: 4px;
    height: 32px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    position: relative;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    border: 1px solid #d9d9d9; }
  .auth-style .ant-btn > .anticon {
    line-height: 1; }
  .auth-style .ant-btn,
  .auth-style .ant-btn:active,
  .auth-style .ant-btn:focus {
    outline: 0; }
  .auth-style .ant-btn:not([disabled]):hover {
    text-decoration: none; }
  .auth-style .ant-btn:not([disabled]):active {
    outline: 0;
    box-shadow: none; }
  .auth-style .ant-btn.disabled,
  .auth-style .ant-btn[disabled] {
    cursor: not-allowed; }
  .auth-style .ant-btn.disabled > *,
  .auth-style .ant-btn[disabled] > * {
    pointer-events: none; }
  .auth-style .ant-btn-lg {
    padding: 0 15px;
    font-size: 16px;
    border-radius: 4px;
    height: 40px; }
  .auth-style .ant-btn-sm {
    padding: 0 7px;
    font-size: 14px;
    border-radius: 4px;
    height: 24px; }
  .auth-style .ant-btn > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn:focus,
  .auth-style .ant-btn:hover {
    color: #40a9ff;
    background-color: #fff;
    border-color: #40a9ff; }
  .auth-style .ant-btn:focus > a:only-child,
  .auth-style .ant-btn:hover > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn:focus > a:only-child:after,
  .auth-style .ant-btn:hover > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn.active,
  .auth-style .ant-btn:active {
    color: #096dd9;
    background-color: #fff;
    border-color: #096dd9; }
  .auth-style .ant-btn.active > a:only-child,
  .auth-style .ant-btn:active > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn.active > a:only-child:after,
  .auth-style .ant-btn:active > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-disabled,
  .auth-style .ant-btn-disabled.active,
  .auth-style .ant-btn-disabled:active,
  .auth-style .ant-btn-disabled:focus,
  .auth-style .ant-btn-disabled:hover,
  .auth-style .ant-btn.disabled,
  .auth-style .ant-btn.disabled.active,
  .auth-style .ant-btn.disabled:active,
  .auth-style .ant-btn.disabled:focus,
  .auth-style .ant-btn.disabled:hover,
  .auth-style .ant-btn[disabled],
  .auth-style .ant-btn[disabled].active,
  .auth-style .ant-btn[disabled]:active,
  .auth-style .ant-btn[disabled]:focus,
  .auth-style .ant-btn[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none; }
  .auth-style .ant-btn-disabled.active > a:only-child,
  .auth-style .ant-btn-disabled:active > a:only-child,
  .auth-style .ant-btn-disabled:focus > a:only-child,
  .auth-style .ant-btn-disabled:hover > a:only-child,
  .auth-style .ant-btn-disabled > a:only-child,
  .auth-style .ant-btn.disabled.active > a:only-child,
  .auth-style .ant-btn.disabled:active > a:only-child,
  .auth-style .ant-btn.disabled:focus > a:only-child,
  .auth-style .ant-btn.disabled:hover > a:only-child,
  .auth-style .ant-btn.disabled > a:only-child,
  .auth-style .ant-btn[disabled].active > a:only-child,
  .auth-style .ant-btn[disabled]:active > a:only-child,
  .auth-style .ant-btn[disabled]:focus > a:only-child,
  .auth-style .ant-btn[disabled]:hover > a:only-child,
  .auth-style .ant-btn[disabled] > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-disabled.active > a:only-child:after,
  .auth-style .ant-btn-disabled:active > a:only-child:after,
  .auth-style .ant-btn-disabled:focus > a:only-child:after,
  .auth-style .ant-btn-disabled:hover > a:only-child:after,
  .auth-style .ant-btn-disabled > a:only-child:after,
  .auth-style .ant-btn.disabled.active > a:only-child:after,
  .auth-style .ant-btn.disabled:active > a:only-child:after,
  .auth-style .ant-btn.disabled:focus > a:only-child:after,
  .auth-style .ant-btn.disabled:hover > a:only-child:after,
  .auth-style .ant-btn.disabled > a:only-child:after,
  .auth-style .ant-btn[disabled].active > a:only-child:after,
  .auth-style .ant-btn[disabled]:active > a:only-child:after,
  .auth-style .ant-btn[disabled]:focus > a:only-child:after,
  .auth-style .ant-btn[disabled]:hover > a:only-child:after,
  .auth-style .ant-btn[disabled] > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn.active,
  .auth-style .ant-btn:active,
  .auth-style .ant-btn:focus,
  .auth-style .ant-btn:hover {
    background: #fff;
    text-decoration: none; }
  .auth-style .ant-btn > i,
  .auth-style .ant-btn > span {
    pointer-events: none;
    display: inline-block; }
  .auth-style .ant-btn-primary {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }
  .auth-style .ant-btn-primary > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-primary > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-primary:focus,
  .auth-style .ant-btn-primary:hover {
    color: #fff;
    background-color: #40a9ff;
    border-color: #40a9ff; }
  .auth-style .ant-btn-primary:focus > a:only-child,
  .auth-style .ant-btn-primary:hover > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-primary:focus > a:only-child:after,
  .auth-style .ant-btn-primary:hover > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-primary.active,
  .auth-style .ant-btn-primary:active {
    color: #fff;
    background-color: #096dd9;
    border-color: #096dd9; }
  .auth-style .ant-btn-primary.active > a:only-child,
  .auth-style .ant-btn-primary:active > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-primary.active > a:only-child:after,
  .auth-style .ant-btn-primary:active > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-primary-disabled,
  .auth-style .ant-btn-primary-disabled.active,
  .auth-style .ant-btn-primary-disabled:active,
  .auth-style .ant-btn-primary-disabled:focus,
  .auth-style .ant-btn-primary-disabled:hover,
  .auth-style .ant-btn-primary.disabled,
  .auth-style .ant-btn-primary.disabled.active,
  .auth-style .ant-btn-primary.disabled:active,
  .auth-style .ant-btn-primary.disabled:focus,
  .auth-style .ant-btn-primary.disabled:hover,
  .auth-style .ant-btn-primary[disabled],
  .auth-style .ant-btn-primary[disabled].active,
  .auth-style .ant-btn-primary[disabled]:active,
  .auth-style .ant-btn-primary[disabled]:focus,
  .auth-style .ant-btn-primary[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none; }
  .auth-style .ant-btn-primary-disabled.active > a:only-child,
  .auth-style .ant-btn-primary-disabled:active > a:only-child,
  .auth-style .ant-btn-primary-disabled:focus > a:only-child,
  .auth-style .ant-btn-primary-disabled:hover > a:only-child,
  .auth-style .ant-btn-primary-disabled > a:only-child,
  .auth-style .ant-btn-primary.disabled.active > a:only-child,
  .auth-style .ant-btn-primary.disabled:active > a:only-child,
  .auth-style .ant-btn-primary.disabled:focus > a:only-child,
  .auth-style .ant-btn-primary.disabled:hover > a:only-child,
  .auth-style .ant-btn-primary.disabled > a:only-child,
  .auth-style .ant-btn-primary[disabled].active > a:only-child,
  .auth-style .ant-btn-primary[disabled]:active > a:only-child,
  .auth-style .ant-btn-primary[disabled]:focus > a:only-child,
  .auth-style .ant-btn-primary[disabled]:hover > a:only-child,
  .auth-style .ant-btn-primary[disabled] > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-primary-disabled.active > a:only-child:after,
  .auth-style .ant-btn-primary-disabled:active > a:only-child:after,
  .auth-style .ant-btn-primary-disabled:focus > a:only-child:after,
  .auth-style .ant-btn-primary-disabled:hover > a:only-child:after,
  .auth-style .ant-btn-primary-disabled > a:only-child:after,
  .auth-style .ant-btn-primary.disabled.active > a:only-child:after,
  .auth-style .ant-btn-primary.disabled:active > a:only-child:after,
  .auth-style .ant-btn-primary.disabled:focus > a:only-child:after,
  .auth-style .ant-btn-primary.disabled:hover > a:only-child:after,
  .auth-style .ant-btn-primary.disabled > a:only-child:after,
  .auth-style .ant-btn-primary[disabled].active > a:only-child:after,
  .auth-style .ant-btn-primary[disabled]:active > a:only-child:after,
  .auth-style .ant-btn-primary[disabled]:focus > a:only-child:after,
  .auth-style .ant-btn-primary[disabled]:hover > a:only-child:after,
  .auth-style .ant-btn-primary[disabled] > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
    border-right-color: #40a9ff;
    border-left-color: #40a9ff; }
  .auth-style .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
    border-color: #d9d9d9; }
  .auth-style .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
    border-right-color: #40a9ff; }
  .auth-style .ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
    border-right-color: #d9d9d9; }
  .auth-style .ant-btn-group .ant-btn-primary + .ant-btn-primary,
  .auth-style .ant-btn-group .ant-btn-primary:last-child:not(:first-child) {
    border-left-color: #40a9ff; }
  .auth-style .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled],
  .auth-style .ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled] {
    border-left-color: #d9d9d9; }
  .auth-style .ant-btn-ghost {
    color: rgba(0, 0, 0, 0.65);
    background-color: transparent;
    border-color: #d9d9d9; }
  .auth-style .ant-btn-ghost > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-ghost > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-ghost:focus,
  .auth-style .ant-btn-ghost:hover {
    color: #40a9ff;
    background-color: transparent;
    border-color: #40a9ff; }
  .auth-style .ant-btn-ghost:focus > a:only-child,
  .auth-style .ant-btn-ghost:hover > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-ghost:focus > a:only-child:after,
  .auth-style .ant-btn-ghost:hover > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-ghost.active,
  .auth-style .ant-btn-ghost:active {
    color: #096dd9;
    background-color: transparent;
    border-color: #096dd9; }
  .auth-style .ant-btn-ghost.active > a:only-child,
  .auth-style .ant-btn-ghost:active > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-ghost.active > a:only-child:after,
  .auth-style .ant-btn-ghost:active > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-ghost-disabled,
  .auth-style .ant-btn-ghost-disabled.active,
  .auth-style .ant-btn-ghost-disabled:active,
  .auth-style .ant-btn-ghost-disabled:focus,
  .auth-style .ant-btn-ghost-disabled:hover,
  .auth-style .ant-btn-ghost.disabled,
  .auth-style .ant-btn-ghost.disabled.active,
  .auth-style .ant-btn-ghost.disabled:active,
  .auth-style .ant-btn-ghost.disabled:focus,
  .auth-style .ant-btn-ghost.disabled:hover,
  .auth-style .ant-btn-ghost[disabled],
  .auth-style .ant-btn-ghost[disabled].active,
  .auth-style .ant-btn-ghost[disabled]:active,
  .auth-style .ant-btn-ghost[disabled]:focus,
  .auth-style .ant-btn-ghost[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none; }
  .auth-style .ant-btn-ghost-disabled.active > a:only-child,
  .auth-style .ant-btn-ghost-disabled:active > a:only-child,
  .auth-style .ant-btn-ghost-disabled:focus > a:only-child,
  .auth-style .ant-btn-ghost-disabled:hover > a:only-child,
  .auth-style .ant-btn-ghost-disabled > a:only-child,
  .auth-style .ant-btn-ghost.disabled.active > a:only-child,
  .auth-style .ant-btn-ghost.disabled:active > a:only-child,
  .auth-style .ant-btn-ghost.disabled:focus > a:only-child,
  .auth-style .ant-btn-ghost.disabled:hover > a:only-child,
  .auth-style .ant-btn-ghost.disabled > a:only-child,
  .auth-style .ant-btn-ghost[disabled].active > a:only-child,
  .auth-style .ant-btn-ghost[disabled]:active > a:only-child,
  .auth-style .ant-btn-ghost[disabled]:focus > a:only-child,
  .auth-style .ant-btn-ghost[disabled]:hover > a:only-child,
  .auth-style .ant-btn-ghost[disabled] > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-ghost-disabled.active > a:only-child:after,
  .auth-style .ant-btn-ghost-disabled:active > a:only-child:after,
  .auth-style .ant-btn-ghost-disabled:focus > a:only-child:after,
  .auth-style .ant-btn-ghost-disabled:hover > a:only-child:after,
  .auth-style .ant-btn-ghost-disabled > a:only-child:after,
  .auth-style .ant-btn-ghost.disabled.active > a:only-child:after,
  .auth-style .ant-btn-ghost.disabled:active > a:only-child:after,
  .auth-style .ant-btn-ghost.disabled:focus > a:only-child:after,
  .auth-style .ant-btn-ghost.disabled:hover > a:only-child:after,
  .auth-style .ant-btn-ghost.disabled > a:only-child:after,
  .auth-style .ant-btn-ghost[disabled].active > a:only-child:after,
  .auth-style .ant-btn-ghost[disabled]:active > a:only-child:after,
  .auth-style .ant-btn-ghost[disabled]:focus > a:only-child:after,
  .auth-style .ant-btn-ghost[disabled]:hover > a:only-child:after,
  .auth-style .ant-btn-ghost[disabled] > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-dashed {
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    border-color: #d9d9d9;
    border-style: dashed; }
  .auth-style .ant-btn-dashed > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-dashed > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-dashed:focus,
  .auth-style .ant-btn-dashed:hover {
    color: #40a9ff;
    background-color: #fff;
    border-color: #40a9ff; }
  .auth-style .ant-btn-dashed:focus > a:only-child,
  .auth-style .ant-btn-dashed:hover > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-dashed:focus > a:only-child:after,
  .auth-style .ant-btn-dashed:hover > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-dashed.active,
  .auth-style .ant-btn-dashed:active {
    color: #096dd9;
    background-color: #fff;
    border-color: #096dd9; }
  .auth-style .ant-btn-dashed.active > a:only-child,
  .auth-style .ant-btn-dashed:active > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-dashed.active > a:only-child:after,
  .auth-style .ant-btn-dashed:active > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-dashed-disabled,
  .auth-style .ant-btn-dashed-disabled.active,
  .auth-style .ant-btn-dashed-disabled:active,
  .auth-style .ant-btn-dashed-disabled:focus,
  .auth-style .ant-btn-dashed-disabled:hover,
  .auth-style .ant-btn-dashed.disabled,
  .auth-style .ant-btn-dashed.disabled.active,
  .auth-style .ant-btn-dashed.disabled:active,
  .auth-style .ant-btn-dashed.disabled:focus,
  .auth-style .ant-btn-dashed.disabled:hover,
  .auth-style .ant-btn-dashed[disabled],
  .auth-style .ant-btn-dashed[disabled].active,
  .auth-style .ant-btn-dashed[disabled]:active,
  .auth-style .ant-btn-dashed[disabled]:focus,
  .auth-style .ant-btn-dashed[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none; }
  .auth-style .ant-btn-dashed-disabled.active > a:only-child,
  .auth-style .ant-btn-dashed-disabled:active > a:only-child,
  .auth-style .ant-btn-dashed-disabled:focus > a:only-child,
  .auth-style .ant-btn-dashed-disabled:hover > a:only-child,
  .auth-style .ant-btn-dashed-disabled > a:only-child,
  .auth-style .ant-btn-dashed.disabled.active > a:only-child,
  .auth-style .ant-btn-dashed.disabled:active > a:only-child,
  .auth-style .ant-btn-dashed.disabled:focus > a:only-child,
  .auth-style .ant-btn-dashed.disabled:hover > a:only-child,
  .auth-style .ant-btn-dashed.disabled > a:only-child,
  .auth-style .ant-btn-dashed[disabled].active > a:only-child,
  .auth-style .ant-btn-dashed[disabled]:active > a:only-child,
  .auth-style .ant-btn-dashed[disabled]:focus > a:only-child,
  .auth-style .ant-btn-dashed[disabled]:hover > a:only-child,
  .auth-style .ant-btn-dashed[disabled] > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-dashed-disabled.active > a:only-child:after,
  .auth-style .ant-btn-dashed-disabled:active > a:only-child:after,
  .auth-style .ant-btn-dashed-disabled:focus > a:only-child:after,
  .auth-style .ant-btn-dashed-disabled:hover > a:only-child:after,
  .auth-style .ant-btn-dashed-disabled > a:only-child:after,
  .auth-style .ant-btn-dashed.disabled.active > a:only-child:after,
  .auth-style .ant-btn-dashed.disabled:active > a:only-child:after,
  .auth-style .ant-btn-dashed.disabled:focus > a:only-child:after,
  .auth-style .ant-btn-dashed.disabled:hover > a:only-child:after,
  .auth-style .ant-btn-dashed.disabled > a:only-child:after,
  .auth-style .ant-btn-dashed[disabled].active > a:only-child:after,
  .auth-style .ant-btn-dashed[disabled]:active > a:only-child:after,
  .auth-style .ant-btn-dashed[disabled]:focus > a:only-child:after,
  .auth-style .ant-btn-dashed[disabled]:hover > a:only-child:after,
  .auth-style .ant-btn-dashed[disabled] > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-danger {
    color: #f5222d;
    background-color: #f5f5f5;
    border-color: #d9d9d9; }
  .auth-style .ant-btn-danger > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-danger > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-danger:hover {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f; }
  .auth-style .ant-btn-danger:hover > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-danger:hover > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-danger:focus {
    color: #ff4d4f;
    background-color: #fff;
    border-color: #ff4d4f; }
  .auth-style .ant-btn-danger:focus > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-danger:focus > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-danger.active,
  .auth-style .ant-btn-danger:active {
    color: #fff;
    background-color: #cf1322;
    border-color: #cf1322; }
  .auth-style .ant-btn-danger.active > a:only-child,
  .auth-style .ant-btn-danger:active > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-danger.active > a:only-child:after,
  .auth-style .ant-btn-danger:active > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-danger-disabled,
  .auth-style .ant-btn-danger-disabled.active,
  .auth-style .ant-btn-danger-disabled:active,
  .auth-style .ant-btn-danger-disabled:focus,
  .auth-style .ant-btn-danger-disabled:hover,
  .auth-style .ant-btn-danger.disabled,
  .auth-style .ant-btn-danger.disabled.active,
  .auth-style .ant-btn-danger.disabled:active,
  .auth-style .ant-btn-danger.disabled:focus,
  .auth-style .ant-btn-danger.disabled:hover,
  .auth-style .ant-btn-danger[disabled],
  .auth-style .ant-btn-danger[disabled].active,
  .auth-style .ant-btn-danger[disabled]:active,
  .auth-style .ant-btn-danger[disabled]:focus,
  .auth-style .ant-btn-danger[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none; }
  .auth-style .ant-btn-danger-disabled.active > a:only-child,
  .auth-style .ant-btn-danger-disabled:active > a:only-child,
  .auth-style .ant-btn-danger-disabled:focus > a:only-child,
  .auth-style .ant-btn-danger-disabled:hover > a:only-child,
  .auth-style .ant-btn-danger-disabled > a:only-child,
  .auth-style .ant-btn-danger.disabled.active > a:only-child,
  .auth-style .ant-btn-danger.disabled:active > a:only-child,
  .auth-style .ant-btn-danger.disabled:focus > a:only-child,
  .auth-style .ant-btn-danger.disabled:hover > a:only-child,
  .auth-style .ant-btn-danger.disabled > a:only-child,
  .auth-style .ant-btn-danger[disabled].active > a:only-child,
  .auth-style .ant-btn-danger[disabled]:active > a:only-child,
  .auth-style .ant-btn-danger[disabled]:focus > a:only-child,
  .auth-style .ant-btn-danger[disabled]:hover > a:only-child,
  .auth-style .ant-btn-danger[disabled] > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-danger-disabled.active > a:only-child:after,
  .auth-style .ant-btn-danger-disabled:active > a:only-child:after,
  .auth-style .ant-btn-danger-disabled:focus > a:only-child:after,
  .auth-style .ant-btn-danger-disabled:hover > a:only-child:after,
  .auth-style .ant-btn-danger-disabled > a:only-child:after,
  .auth-style .ant-btn-danger.disabled.active > a:only-child:after,
  .auth-style .ant-btn-danger.disabled:active > a:only-child:after,
  .auth-style .ant-btn-danger.disabled:focus > a:only-child:after,
  .auth-style .ant-btn-danger.disabled:hover > a:only-child:after,
  .auth-style .ant-btn-danger.disabled > a:only-child:after,
  .auth-style .ant-btn-danger[disabled].active > a:only-child:after,
  .auth-style .ant-btn-danger[disabled]:active > a:only-child:after,
  .auth-style .ant-btn-danger[disabled]:focus > a:only-child:after,
  .auth-style .ant-btn-danger[disabled]:hover > a:only-child:after,
  .auth-style .ant-btn-danger[disabled] > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-circle,
  .auth-style .ant-btn-circle-outline {
    width: 32px;
    padding: 0;
    font-size: 16px;
    border-radius: 50%;
    height: 32px; }
  .auth-style .ant-btn-circle-outline.ant-btn-lg,
  .auth-style .ant-btn-circle.ant-btn-lg {
    width: 40px;
    padding: 0;
    font-size: 18px;
    border-radius: 50%;
    height: 40px; }
  .auth-style .ant-btn-circle-outline.ant-btn-sm,
  .auth-style .ant-btn-circle.ant-btn-sm {
    width: 24px;
    padding: 0;
    font-size: 14px;
    border-radius: 50%;
    height: 24px; }
  .auth-style .ant-btn:before {
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    background: #fff;
    opacity: 0.35;
    content: "";
    border-radius: inherit;
    z-index: 1;
    transition: opacity 0.2s;
    pointer-events: none;
    display: none; }
  .auth-style .ant-btn .anticon {
    transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .auth-style .ant-btn .anticon.anticon-minus > svg,
  .auth-style .ant-btn .anticon.anticon-plus > svg {
    shape-rendering: optimizeSpeed; }
  .auth-style .ant-btn.ant-btn-loading:before {
    display: block; }
  .auth-style .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
    padding-left: 29px;
    pointer-events: none;
    position: relative; }
  .auth-style .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only)
.anticon:not(:last-child) {
    margin-left: -14px; }
  .auth-style .ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
    padding-left: 24px; }
  .auth-style .ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only)
.anticon {
    margin-left: -17px; }
  .auth-style .ant-btn-group {
    display: inline-block; }
  .auth-style .ant-btn-group,
  .auth-style .ant-btn-group > .ant-btn,
  .auth-style .ant-btn-group > span > .ant-btn {
    position: relative; }
  .auth-style .ant-btn-group > .ant-btn.active,
  .auth-style .ant-btn-group > .ant-btn:active,
  .auth-style .ant-btn-group > .ant-btn:focus,
  .auth-style .ant-btn-group > .ant-btn:hover,
  .auth-style .ant-btn-group > span > .ant-btn.active,
  .auth-style .ant-btn-group > span > .ant-btn:active,
  .auth-style .ant-btn-group > span > .ant-btn:focus,
  .auth-style .ant-btn-group > span > .ant-btn:hover {
    z-index: 2; }
  .auth-style .ant-btn-group > .ant-btn:disabled,
  .auth-style .ant-btn-group > span > .ant-btn:disabled {
    z-index: 0; }
  .auth-style .ant-btn-group-lg > .ant-btn,
  .auth-style .ant-btn-group-lg > span > .ant-btn {
    padding: 0 15px;
    font-size: 16px;
    border-radius: 0;
    height: 40px;
    line-height: 38px; }
  .auth-style .ant-btn-group-sm > .ant-btn,
  .auth-style .ant-btn-group-sm > span > .ant-btn {
    padding: 0 7px;
    font-size: 14px;
    border-radius: 0;
    height: 24px;
    line-height: 22px; }
  .auth-style .ant-btn-group-sm > .ant-btn > .anticon,
  .auth-style .ant-btn-group-sm > span > .ant-btn > .anticon {
    font-size: 14px; }
  .auth-style .ant-btn + .ant-btn-group,
  .auth-style .ant-btn-group + .ant-btn,
  .auth-style .ant-btn-group + .ant-btn-group,
  .auth-style .ant-btn-group .ant-btn + .ant-btn,
  .auth-style .ant-btn-group .ant-btn + span,
  .auth-style .ant-btn-group > span + span,
  .auth-style .ant-btn-group span + .ant-btn {
    margin-left: -1px; }
  .auth-style .ant-btn-group
.ant-btn-primary
+ .ant-btn:not(.ant-btn-primary):not([disabled]) {
    border-left-color: transparent; }
  .auth-style .ant-btn-group .ant-btn {
    border-radius: 0; }
  .auth-style .ant-btn-group > .ant-btn:first-child,
  .auth-style .ant-btn-group > span:first-child > .ant-btn {
    margin-left: 0; }
  .auth-style .ant-btn-group > .ant-btn:only-child,
  .auth-style .ant-btn-group > span:only-child > .ant-btn {
    border-radius: 4px; }
  .auth-style .ant-btn-group > .ant-btn:first-child:not(:last-child),
  .auth-style .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px; }
  .auth-style .ant-btn-group > .ant-btn:last-child:not(:first-child),
  .auth-style .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px; }
  .auth-style .ant-btn-group-sm > .ant-btn:only-child,
  .auth-style .ant-btn-group-sm > span:only-child > .ant-btn {
    border-radius: 4px; }
  .auth-style .ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
  .auth-style .ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px; }
  .auth-style .ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
  .auth-style .ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px; }
  .auth-style .ant-btn-group > .ant-btn-group {
    float: left; }
  .auth-style .ant-btn-group
> .ant-btn-group:not(:first-child):not(:last-child)
> .ant-btn {
    border-radius: 0; }
  .auth-style .ant-btn-group
> .ant-btn-group:first-child:not(:last-child)
> .ant-btn:last-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding-right: 8px; }
  .auth-style .ant-btn-group
> .ant-btn-group:last-child:not(:first-child)
> .ant-btn:first-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    padding-left: 8px; }
  .auth-style .ant-btn:not(.ant-btn-circle):not(.ant-btn-circle-outline).ant-btn-icon-only {
    padding-left: 8px;
    padding-right: 8px; }
  .auth-style .ant-btn:active > span,
  .auth-style .ant-btn:focus > span {
    position: relative; }
  .auth-style .ant-btn > .anticon + span,
  .auth-style .ant-btn > span + .anticon {
    margin-left: 8px; }
  .auth-style .ant-btn-background-ghost {
    background: transparent !important;
    border-color: #fff;
    color: #fff; }
  .auth-style .ant-btn-background-ghost.ant-btn-primary {
    color: #1890ff;
    background-color: transparent;
    border-color: #1890ff;
    text-shadow: none; }
  .auth-style .ant-btn-background-ghost.ant-btn-primary > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-background-ghost.ant-btn-primary > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-background-ghost.ant-btn-primary:focus,
  .auth-style .ant-btn-background-ghost.ant-btn-primary:hover {
    color: #40a9ff;
    background-color: transparent;
    border-color: #40a9ff; }
  .auth-style .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-background-ghost.ant-btn-primary.active,
  .auth-style .ant-btn-background-ghost.ant-btn-primary:active {
    color: #096dd9;
    background-color: transparent;
    border-color: #096dd9; }
  .auth-style .ant-btn-background-ghost.ant-btn-primary.active > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-background-ghost.ant-btn-primary.active > a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-primary:active > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-background-ghost.ant-btn-primary-disabled,
  .auth-style .ant-btn-background-ghost.ant-btn-primary-disabled.active,
  .auth-style .ant-btn-background-ghost.ant-btn-primary-disabled:active,
  .auth-style .ant-btn-background-ghost.ant-btn-primary-disabled:focus,
  .auth-style .ant-btn-background-ghost.ant-btn-primary-disabled:hover,
  .auth-style .ant-btn-background-ghost.ant-btn-primary.disabled,
  .auth-style .ant-btn-background-ghost.ant-btn-primary.disabled.active,
  .auth-style .ant-btn-background-ghost.ant-btn-primary.disabled:active,
  .auth-style .ant-btn-background-ghost.ant-btn-primary.disabled:focus,
  .auth-style .ant-btn-background-ghost.ant-btn-primary.disabled:hover,
  .auth-style .ant-btn-background-ghost.ant-btn-primary[disabled],
  .auth-style .ant-btn-background-ghost.ant-btn-primary[disabled].active,
  .auth-style .ant-btn-background-ghost.ant-btn-primary[disabled]:active,
  .auth-style .ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
  .auth-style .ant-btn-background-ghost.ant-btn-primary[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none; }
  .auth-style .ant-btn-background-ghost.ant-btn-primary-disabled.active > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-primary-disabled:active > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-primary-disabled:focus > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-primary-disabled:hover > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-primary-disabled > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-primary.disabled.active > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-primary.disabled:active > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-primary[disabled].active > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-background-ghost.ant-btn-primary-disabled.active
> a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-primary-disabled:active
> a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-primary-disabled:focus > a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-primary-disabled:hover > a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-primary-disabled > a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-primary.disabled.active
> a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-primary.disabled:active
> a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-primary.disabled:focus > a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-primary.disabled:hover > a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-primary.disabled > a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-primary[disabled].active
> a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-primary[disabled]:active
> a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-primary[disabled]:focus
> a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-primary[disabled]:hover
> a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-background-ghost.ant-btn-danger {
    color: #f5222d;
    background-color: transparent;
    border-color: #f5222d;
    text-shadow: none; }
  .auth-style .ant-btn-background-ghost.ant-btn-danger > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-background-ghost.ant-btn-danger > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-background-ghost.ant-btn-danger:focus,
  .auth-style .ant-btn-background-ghost.ant-btn-danger:hover {
    color: #ff4d4f;
    background-color: transparent;
    border-color: #ff4d4f; }
  .auth-style .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-background-ghost.ant-btn-danger.active,
  .auth-style .ant-btn-background-ghost.ant-btn-danger:active {
    color: #cf1322;
    background-color: transparent;
    border-color: #cf1322; }
  .auth-style .ant-btn-background-ghost.ant-btn-danger.active > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-background-ghost.ant-btn-danger.active > a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-danger:active > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-background-ghost.ant-btn-danger-disabled,
  .auth-style .ant-btn-background-ghost.ant-btn-danger-disabled.active,
  .auth-style .ant-btn-background-ghost.ant-btn-danger-disabled:active,
  .auth-style .ant-btn-background-ghost.ant-btn-danger-disabled:focus,
  .auth-style .ant-btn-background-ghost.ant-btn-danger-disabled:hover,
  .auth-style .ant-btn-background-ghost.ant-btn-danger.disabled,
  .auth-style .ant-btn-background-ghost.ant-btn-danger.disabled.active,
  .auth-style .ant-btn-background-ghost.ant-btn-danger.disabled:active,
  .auth-style .ant-btn-background-ghost.ant-btn-danger.disabled:focus,
  .auth-style .ant-btn-background-ghost.ant-btn-danger.disabled:hover,
  .auth-style .ant-btn-background-ghost.ant-btn-danger[disabled],
  .auth-style .ant-btn-background-ghost.ant-btn-danger[disabled].active,
  .auth-style .ant-btn-background-ghost.ant-btn-danger[disabled]:active,
  .auth-style .ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
  .auth-style .ant-btn-background-ghost.ant-btn-danger[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none; }
  .auth-style .ant-btn-background-ghost.ant-btn-danger-disabled.active > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-danger-disabled:active > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-danger-disabled:focus > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-danger-disabled:hover > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-danger-disabled > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-danger[disabled].active > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
  .auth-style .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child {
    color: currentColor; }
  .auth-style .ant-btn-background-ghost.ant-btn-danger-disabled.active > a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-danger-disabled:active > a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-danger-disabled:focus > a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-danger-disabled:hover > a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-danger-disabled > a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-danger.disabled.active > a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-danger.disabled:active > a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-danger.disabled:focus > a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-danger.disabled:hover > a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-danger.disabled > a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-danger[disabled].active
> a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-danger[disabled]:active
> a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child:after,
  .auth-style .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-btn-two-chinese-chars:first-letter {
    letter-spacing: 0.34em; }
  .auth-style .ant-btn-two-chinese-chars > :not(.anticon) {
    letter-spacing: 0.34em;
    margin-right: -0.34em; }
  .auth-style .ant-btn-block {
    width: 100%; }
  .auth-style .ant-btn:empty {
    vertical-align: top; }
  .auth-style a.ant-btn {
    line-height: 30px; }
  .auth-style a.ant-btn-lg {
    line-height: 38px; }
  .auth-style a.ant-btn-sm {
    line-height: 22px; }
  .auth-style .ant-fullcalendar {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    outline: none;
    border-top: 1px solid #d9d9d9; }
  .auth-style .ant-fullcalendar-month-select {
    margin-left: 5px; }
  .auth-style .ant-fullcalendar-header {
    padding: 11px 16px 11px 0;
    text-align: right; }
  .auth-style .ant-fullcalendar-header .ant-select-dropdown {
    text-align: left; }
  .auth-style .ant-fullcalendar-header .ant-radio-group {
    margin-left: 8px;
    text-align: left; }
  .auth-style .ant-fullcalendar-header label.ant-radio-button {
    height: 22px;
    line-height: 20px;
    padding: 0 10px; }
  .auth-style .ant-fullcalendar-date-panel {
    position: relative;
    outline: none; }
  .auth-style .ant-fullcalendar-calendar-body {
    padding: 8px 12px; }
  .auth-style .ant-fullcalendar table {
    border-collapse: collapse;
    max-width: 100%;
    background-color: transparent;
    width: 100%;
    height: 256px; }
  .auth-style .ant-fullcalendar table,
  .auth-style .ant-fullcalendar td,
  .auth-style .ant-fullcalendar th {
    border: 0; }
  .auth-style .ant-fullcalendar td {
    position: relative; }
  .auth-style .ant-fullcalendar-calendar-table {
    border-spacing: 0;
    margin-bottom: 0; }
  .auth-style .ant-fullcalendar-column-header {
    line-height: 18px;
    padding: 0;
    width: 33px;
    text-align: center; }
  .auth-style .ant-fullcalendar-column-header .ant-fullcalendar-column-header-inner {
    display: block;
    font-weight: 400; }
  .auth-style .ant-fullcalendar-week-number-header .ant-fullcalendar-column-header-inner {
    display: none; }
  .auth-style .ant-fullcalendar-date,
  .auth-style .ant-fullcalendar-month {
    text-align: center;
    transition: all 0.3s; }
  .auth-style .ant-fullcalendar-value {
    display: block;
    margin: 0 auto;
    color: rgba(0, 0, 0, 0.65);
    border-radius: 2px;
    width: 24px;
    height: 24px;
    padding: 0;
    background: transparent;
    line-height: 24px;
    transition: all 0.3s; }
  .auth-style .ant-fullcalendar-value:hover {
    background: #e6f7ff;
    cursor: pointer; }
  .auth-style .ant-fullcalendar-value:active {
    background: #1890ff;
    color: #fff; }
  .auth-style .ant-fullcalendar-month-panel-cell .ant-fullcalendar-value {
    width: 48px; }
  .auth-style .ant-fullcalendar-month-panel-current-cell .ant-fullcalendar-value,
  .auth-style .ant-fullcalendar-today .ant-fullcalendar-value {
    box-shadow: inset 0 0 0 1px #1890ff; }
  .auth-style .ant-fullcalendar-month-panel-selected-cell .ant-fullcalendar-value,
  .auth-style .ant-fullcalendar-selected-day .ant-fullcalendar-value {
    background: #1890ff;
    color: #fff; }
  .auth-style .ant-fullcalendar-disabled-cell-first-of-row .ant-fullcalendar-value {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .auth-style .ant-fullcalendar-disabled-cell-last-of-row .ant-fullcalendar-value {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
  .auth-style .ant-fullcalendar-last-month-cell .ant-fullcalendar-value,
  .auth-style .ant-fullcalendar-next-month-btn-day .ant-fullcalendar-value {
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-fullcalendar-month-panel-table {
    table-layout: fixed;
    width: 100%;
    border-collapse: separate; }
  .auth-style .ant-fullcalendar-content {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -9px; }
  .auth-style .ant-fullcalendar-fullscreen {
    border-top: 0; }
  .auth-style .ant-fullcalendar-fullscreen .ant-fullcalendar-table {
    table-layout: fixed; }
  .auth-style .ant-fullcalendar-fullscreen .ant-fullcalendar-header .ant-radio-group {
    margin-left: 16px; }
  .auth-style .ant-fullcalendar-fullscreen .ant-fullcalendar-header label.ant-radio-button {
    height: 32px;
    line-height: 30px; }
  .auth-style .ant-fullcalendar-fullscreen .ant-fullcalendar-date,
  .auth-style .ant-fullcalendar-fullscreen .ant-fullcalendar-month {
    text-align: left;
    margin: 0 4px;
    display: block;
    color: rgba(0, 0, 0, 0.65);
    height: 116px;
    padding: 4px 8px;
    border-top: 2px solid #e8e8e8;
    transition: background 0.3s; }
  .auth-style .ant-fullcalendar-fullscreen .ant-fullcalendar-date:hover,
  .auth-style .ant-fullcalendar-fullscreen .ant-fullcalendar-month:hover {
    background: #e6f7ff;
    cursor: pointer; }
  .auth-style .ant-fullcalendar-fullscreen .ant-fullcalendar-date:active,
  .auth-style .ant-fullcalendar-fullscreen .ant-fullcalendar-month:active {
    background: #bae7ff; }
  .auth-style .ant-fullcalendar-fullscreen .ant-fullcalendar-column-header {
    text-align: right;
    padding-right: 12px;
    padding-bottom: 5px; }
  .auth-style .ant-fullcalendar-fullscreen .ant-fullcalendar-value {
    text-align: right;
    background: transparent;
    width: auto; }
  .auth-style .ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-value {
    color: rgba(0, 0, 0, 0.65); }
  .auth-style .ant-fullcalendar-fullscreen
.ant-fullcalendar-month-panel-current-cell
.ant-fullcalendar-month,
  .auth-style .ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-date {
    border-top-color: #1890ff;
    background: transparent; }
  .auth-style .ant-fullcalendar-fullscreen
.ant-fullcalendar-month-panel-current-cell
.ant-fullcalendar-value,
  .auth-style .ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-value {
    box-shadow: none; }
  .auth-style .ant-fullcalendar-fullscreen
.ant-fullcalendar-month-panel-selected-cell
.ant-fullcalendar-month,
  .auth-style .ant-fullcalendar-fullscreen
.ant-fullcalendar-selected-day
.ant-fullcalendar-date {
    background: #e6f7ff; }
  .auth-style .ant-fullcalendar-fullscreen
.ant-fullcalendar-month-panel-selected-cell
.ant-fullcalendar-value,
  .auth-style .ant-fullcalendar-fullscreen
.ant-fullcalendar-selected-day
.ant-fullcalendar-value {
    color: #1890ff; }
  .auth-style .ant-fullcalendar-fullscreen
.ant-fullcalendar-last-month-cell
.ant-fullcalendar-date,
  .auth-style .ant-fullcalendar-fullscreen
.ant-fullcalendar-next-month-btn-day
.ant-fullcalendar-date {
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-fullcalendar-fullscreen .ant-fullcalendar-content {
    height: 88px;
    overflow-y: auto;
    position: static;
    width: auto;
    left: auto;
    bottom: auto; }
  .auth-style .ant-fullcalendar-disabled-cell .ant-fullcalendar-date,
  .auth-style .ant-fullcalendar-disabled-cell .ant-fullcalendar-date:hover {
    cursor: not-allowed; }
  .auth-style .ant-fullcalendar-disabled-cell:not(.ant-fullcalendar-today)
.ant-fullcalendar-date,
  .auth-style .ant-fullcalendar-disabled-cell:not(.ant-fullcalendar-today)
.ant-fullcalendar-date:hover {
    background: transparent; }
  .auth-style .ant-fullcalendar-disabled-cell .ant-fullcalendar-value {
    color: rgba(0, 0, 0, 0.25);
    border-radius: 0;
    width: auto;
    cursor: not-allowed; }
  .auth-style .ant-card {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fff;
    border-radius: 2px;
    position: relative;
    transition: all 0.3s; }
  .auth-style .ant-card-hoverable {
    cursor: pointer; }
  .auth-style .ant-card-hoverable:hover {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
    border-color: rgba(0, 0, 0, 0.09); }
  .auth-style .ant-card-bordered {
    border: 1px solid #e8e8e8; }
  .auth-style .ant-card-head {
    background: transparent;
    border-bottom: 1px solid #e8e8e8;
    padding: 0 24px;
    border-radius: 2px 2px 0 0;
    zoom: 1;
    margin-bottom: -1px;
    min-height: 48px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500; }
  .auth-style .ant-card-head:after,
  .auth-style .ant-card-head:before {
    content: "";
    display: table; }
  .auth-style .ant-card-head:after {
    clear: both; }
  .auth-style .ant-card-head-wrapper {
    display: flex;
    align-items: center; }
  .auth-style .ant-card-head-title {
    padding: 16px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex: 1 1;
    display: inline-block; }
  .auth-style .ant-card-head .ant-tabs {
    margin-bottom: -17px;
    clear: both;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 400; }
  .auth-style .ant-card-head .ant-tabs-bar {
    border-bottom: 1px solid #e8e8e8; }
  .auth-style .ant-card-extra {
    float: right;
    padding: 16px 0;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 400;
    margin-left: auto; }
  .auth-style .ant-card-body {
    padding: 24px;
    zoom: 1; }
  .auth-style .ant-card-body:after,
  .auth-style .ant-card-body:before {
    content: "";
    display: table; }
  .auth-style .ant-card-body:after {
    clear: both; }
  .auth-style .ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
    margin: -1px 0 0 -1px;
    padding: 0; }
  .auth-style .ant-card-grid {
    border-radius: 0;
    border: 0;
    box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8, inset 1px 0 0 0 #e8e8e8, inset 0 1px 0 0 #e8e8e8;
    width: 33.33%;
    float: left;
    padding: 24px;
    transition: all 0.3s; }
  .auth-style .ant-card-grid:hover {
    position: relative;
    z-index: 1;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); }
  .auth-style .ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
    padding-bottom: 0;
    min-height: 32px; }
  .auth-style .ant-card-contain-tabs .ant-card-extra {
    padding-bottom: 0; }
  .auth-style .ant-card-cover > * {
    width: 100%;
    display: block; }
  .auth-style .ant-card-cover img {
    border-radius: 2px 2px 0 0; }
  .auth-style .ant-card-actions {
    border-top: 1px solid #e8e8e8;
    background: #fafafa;
    zoom: 1;
    list-style: none;
    margin: 0;
    padding: 0; }
  .auth-style .ant-card-actions:after,
  .auth-style .ant-card-actions:before {
    content: "";
    display: table; }
  .auth-style .ant-card-actions:after {
    clear: both; }
  .auth-style .ant-card-actions > li {
    float: left;
    text-align: center;
    margin: 12px 0;
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-card-actions > li > span {
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    line-height: 22px;
    min-width: 32px;
    position: relative; }
  .auth-style .ant-card-actions > li > span:hover {
    color: #1890ff;
    transition: color 0.3s; }
  .auth-style .ant-card-actions > li > span > .anticon {
    font-size: 16px;
    line-height: 22px; }
  .auth-style .ant-card-actions > li > span a {
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px;
    display: inline-block;
    width: 100%; }
  .auth-style .ant-card-actions > li > span a:hover {
    color: #1890ff; }
  .auth-style .ant-card-actions > li:not(:last-child) {
    border-right: 1px solid #e8e8e8; }
  .auth-style .ant-card-wider-padding .ant-card-head {
    padding: 0 32px; }
  .auth-style .ant-card-wider-padding .ant-card-body {
    padding: 24px 32px; }
  .auth-style .ant-card-padding-transition .ant-card-body,
  .auth-style .ant-card-padding-transition .ant-card-head {
    transition: padding 0.3s; }
  .auth-style .ant-card-type-inner .ant-card-head {
    padding: 0 24px;
    background: #fafafa; }
  .auth-style .ant-card-type-inner .ant-card-head-title {
    padding: 12px 0;
    font-size: 14px; }
  .auth-style .ant-card-type-inner .ant-card-body {
    padding: 16px 24px; }
  .auth-style .ant-card-type-inner .ant-card-extra {
    padding: 13.5px 0; }
  .auth-style .ant-card-meta {
    margin: -4px 0;
    zoom: 1; }
  .auth-style .ant-card-meta:after,
  .auth-style .ant-card-meta:before {
    content: "";
    display: table; }
  .auth-style .ant-card-meta:after {
    clear: both; }
  .auth-style .ant-card-meta-avatar {
    padding-right: 16px;
    float: left; }
  .auth-style .ant-card-meta-detail {
    overflow: hidden; }
  .auth-style .ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 8px; }
  .auth-style .ant-card-meta-title {
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500; }
  .auth-style .ant-card-meta-description {
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-card-loading {
    overflow: hidden;
    position: relative; }
  .auth-style .ant-card-loading:after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 24px;
    background: #fff;
    content: ""; }
  .auth-style .ant-card-loading .ant-card-body {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .auth-style .ant-card-loading-content p {
    margin: 0; }
  .auth-style .ant-card-loading-block {
    height: 14px;
    margin: 4px 0;
    border-radius: 2px;
    background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
    -webkit-animation: card-loading 1.4s ease infinite;
    animation: card-loading 1.4s ease infinite;
    background-size: 600% 600%; }

@-webkit-keyframes card-loading {
  0%,
  to {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; } }

@keyframes card-loading {
  0%,
  to {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; } }
  .auth-style .ant-card-small > .ant-card-head {
    min-height: 36px;
    padding: 0 12px;
    font-size: 14px; }
  .auth-style .ant-card-small
> .ant-card-head
> .ant-card-head-wrapper
> .ant-card-head-title {
    padding: 8px 0; }
  .auth-style .ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
    padding: 8px 0;
    font-size: 14px; }
  .auth-style .ant-card-small > .ant-card-body {
    padding: 12px; }
  .auth-style .ant-carousel {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    margin: 0;
    padding: 0;
    list-style: none; }
  .auth-style .ant-carousel,
  .auth-style .ant-carousel .slick-slider {
    box-sizing: border-box; }
  .auth-style .ant-carousel .slick-slider {
    position: relative;
    display: block;
    -webkit-touch-callout: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent; }
  .auth-style .ant-carousel .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0; }
  .auth-style .ant-carousel .slick-list:focus {
    outline: none; }
  .auth-style .ant-carousel .slick-list.dragging {
    cursor: pointer; }
  .auth-style .ant-carousel .slick-list .slick-slide {
    pointer-events: none; }
  .auth-style .ant-carousel .slick-list .slick-slide.slick-active {
    pointer-events: auto; }
  .auth-style .ant-carousel .slick-slider .slick-list,
  .auth-style .ant-carousel .slick-slider .slick-track {
    -webkit-transform: translateZ(0);
    transform: translateZ(0); }
  .auth-style .ant-carousel .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block; }
  .auth-style .ant-carousel .slick-track:after,
  .auth-style .ant-carousel .slick-track:before {
    content: "";
    display: table; }
  .auth-style .ant-carousel .slick-track:after {
    clear: both; }
  .auth-style .slick-loading .ant-carousel .slick-track {
    visibility: hidden; }
  .auth-style .ant-carousel .slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none; }
  .auth-style [dir="rtl"] .ant-carousel .slick-slide {
    float: right; }
  .auth-style .ant-carousel .slick-slide img {
    display: block; }
  .auth-style .ant-carousel .slick-slide.slick-loading img {
    display: none; }
  .auth-style .ant-carousel .slick-slide.dragging img {
    pointer-events: none; }
  .auth-style .ant-carousel .slick-initialized .slick-slide {
    display: block; }
  .auth-style .ant-carousel .slick-loading .slick-slide {
    visibility: hidden; }
  .auth-style .ant-carousel .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }
  .auth-style .ant-carousel .slick-arrow.slick-hidden {
    display: none; }
  .auth-style .ant-carousel .slick-next,
  .auth-style .ant-carousel .slick-prev {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    top: 50%;
    margin-top: -10px;
    padding: 0;
    border: 0; }
  .auth-style .ant-carousel .slick-next,
  .auth-style .ant-carousel .slick-next:focus,
  .auth-style .ant-carousel .slick-next:hover,
  .auth-style .ant-carousel .slick-prev,
  .auth-style .ant-carousel .slick-prev:focus,
  .auth-style .ant-carousel .slick-prev:hover {
    background: transparent;
    color: transparent;
    outline: none; }
  .auth-style .ant-carousel .slick-next:focus:before,
  .auth-style .ant-carousel .slick-next:hover:before,
  .auth-style .ant-carousel .slick-prev:focus:before,
  .auth-style .ant-carousel .slick-prev:hover:before {
    opacity: 1; }
  .auth-style .ant-carousel .slick-next.slick-disabled:before,
  .auth-style .ant-carousel .slick-prev.slick-disabled:before {
    opacity: 0.25; }
  .auth-style .ant-carousel .slick-prev {
    left: -25px; }
  .auth-style .ant-carousel .slick-prev:before {
    content: "\2190"; }
  .auth-style .ant-carousel .slick-next {
    right: -25px; }
  .auth-style .ant-carousel .slick-next:before {
    content: "\2192"; }
  .auth-style .ant-carousel .slick-dots {
    position: absolute;
    bottom: 12px;
    list-style: none;
    display: block;
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 3px; }
  .auth-style .ant-carousel .slick-dots li {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    margin: 0 2px;
    padding: 0; }
  .auth-style .ant-carousel .slick-dots li button {
    border: 0;
    cursor: pointer;
    background: #fff;
    opacity: 0.3;
    display: block;
    width: 16px;
    height: 3px;
    border-radius: 1px;
    outline: none;
    font-size: 0;
    color: transparent;
    transition: all 0.5s;
    padding: 0; }
  .auth-style .ant-carousel .slick-dots li button:focus,
  .auth-style .ant-carousel .slick-dots li button:hover {
    opacity: 0.75; }
  .auth-style .ant-carousel .slick-dots li.slick-active button {
    background: #fff;
    opacity: 1;
    width: 24px; }
  .auth-style .ant-carousel .slick-dots li.slick-active button:focus,
  .auth-style .ant-carousel .slick-dots li.slick-active button:hover {
    opacity: 1; }
  .auth-style .ant-carousel-vertical .slick-dots {
    width: 3px;
    bottom: auto;
    right: 12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    height: auto; }
  .auth-style .ant-carousel-vertical .slick-dots li {
    margin: 0 2px;
    vertical-align: baseline; }
  .auth-style .ant-carousel-vertical .slick-dots li button {
    width: 3px;
    height: 16px; }
  .auth-style .ant-carousel-vertical .slick-dots li.slick-active button {
    width: 3px;
    height: 24px; }
  .auth-style .ant-cascader {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none; }
  .auth-style .ant-cascader-input.ant-input {
    background-color: transparent !important;
    cursor: pointer;
    width: 100%;
    position: relative; }
  .auth-style .ant-cascader-picker-show-search .ant-cascader-input.ant-input {
    position: relative; }
  .auth-style .ant-cascader-picker {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    cursor: pointer;
    background-color: #fff;
    border-radius: 4px;
    outline: 0;
    transition: color 0.3s; }
  .auth-style .ant-cascader-picker-with-value .ant-cascader-picker-label {
    color: transparent; }
  .auth-style .ant-cascader-picker-disabled {
    cursor: not-allowed;
    background: #f5f5f5;
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-cascader-picker-disabled .ant-cascader-input {
    cursor: not-allowed; }
  .auth-style .ant-cascader-picker:focus .ant-cascader-input {
    border-color: #40a9ff;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    border-right-width: 1px !important; }
  .auth-style .ant-cascader-picker-show-search.ant-cascader-picker-focused {
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-cascader-picker-label {
    position: absolute;
    left: 0;
    height: 20px;
    line-height: 20px;
    top: 50%;
    margin-top: -10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    padding: 0 12px; }
  .auth-style .ant-cascader-picker-clear {
    opacity: 0;
    position: absolute;
    right: 12px;
    z-index: 2;
    background: #fff;
    top: 50%;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.25);
    width: 12px;
    height: 12px;
    margin-top: -6px;
    line-height: 12px;
    cursor: pointer;
    transition: color 0.3s ease, opacity 0.15s ease; }
  .auth-style .ant-cascader-picker-clear:hover {
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-cascader-picker:hover .ant-cascader-picker-clear {
    opacity: 1; }
  .auth-style .ant-cascader-picker-arrow {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 12px;
    width: 12px;
    height: 12px;
    font-size: 12px;
    margin-top: -6px;
    line-height: 12px;
    color: rgba(0, 0, 0, 0.25);
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s; }
  .auth-style .ant-cascader-picker-arrow.ant-cascader-picker-arrow-expand {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  .auth-style .ant-cascader-picker-small .ant-cascader-picker-arrow,
  .auth-style .ant-cascader-picker-small .ant-cascader-picker-clear {
    right: 8px; }
  .auth-style .ant-cascader-menus {
    font-size: 14px;
    background: #fff;
    position: absolute;
    z-index: 1050;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    white-space: nowrap; }
  .auth-style .ant-cascader-menus ol,
  .auth-style .ant-cascader-menus ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .auth-style .ant-cascader-menus-empty,
  .auth-style .ant-cascader-menus-hidden {
    display: none; }
  .auth-style .ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomLeft,
  .auth-style .ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomLeft {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn; }
  .auth-style .ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topLeft,
  .auth-style .ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topLeft {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn; }
  .auth-style .ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomLeft {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut; }
  .auth-style .ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topLeft {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut; }
  .auth-style .ant-cascader-menu {
    display: inline-block;
    vertical-align: top;
    min-width: 111px;
    height: 180px;
    list-style: none;
    margin: 0;
    padding: 0;
    border-right: 1px solid #e8e8e8;
    overflow: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .auth-style .ant-cascader-menu:first-child {
    border-radius: 4px 0 0 4px; }
  .auth-style .ant-cascader-menu:last-child {
    border-right-color: transparent;
    margin-right: -1px;
    border-radius: 0 4px 4px 0; }
  .auth-style .ant-cascader-menu:only-child {
    border-radius: 4px; }
  .auth-style .ant-cascader-menu-item {
    padding: 5px 12px;
    line-height: 22px;
    cursor: pointer;
    white-space: nowrap;
    transition: all 0.3s; }
  .auth-style .ant-cascader-menu-item:hover {
    background: #e6f7ff; }
  .auth-style .ant-cascader-menu-item-disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-cascader-menu-item-disabled:hover {
    background: transparent; }
  .auth-style .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
  .auth-style .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    background: #f5f5f5;
    font-weight: 600; }
  .auth-style .ant-cascader-menu-item-expand {
    position: relative;
    padding-right: 24px; }
  .auth-style .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
  .auth-style .ant-cascader-menu-item-expand .ant-cascader-menu-item-loading-icon {
    display: inline-block;
    font-size: 12px;
    font-size: 10px\9;
    -webkit-transform: scale(0.83333) rotate(0deg);
    transform: scale(0.83333) rotate(0deg);
    color: rgba(0, 0, 0, 0.45);
    position: absolute;
    right: 12px; }
  .auth-style :root .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
  .auth-style :root .ant-cascader-menu-item-expand .ant-cascader-menu-item-loading-icon {
    font-size: 12px; }
  .auth-style .ant-cascader-menu-item .ant-cascader-menu-item-keyword {
    color: #f5222d; }
  .auth-style .ant-checkbox {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    display: inline-block;
    line-height: 1;
    position: relative;
    vertical-align: middle;
    top: -0.09em; }
  .auth-style .ant-checkbox-input:focus + .ant-checkbox-inner,
  .auth-style .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .auth-style .ant-checkbox:hover .ant-checkbox-inner {
    border-color: #1890ff; }
  .auth-style .ant-checkbox-checked:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    border: 1px solid #1890ff;
    content: "";
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
    animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: hidden; }
  .auth-style .ant-checkbox-wrapper:hover .ant-checkbox:after,
  .auth-style .ant-checkbox:hover:after {
    visibility: visible; }
  .auth-style .ant-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    background-color: #fff;
    transition: all 0.3s;
    border-collapse: separate; }
  .auth-style .ant-checkbox-inner:after {
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
    position: absolute;
    left: 4.57142857px;
    top: 1.14285714px;
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    content: " ";
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    opacity: 0; }
  .auth-style .ant-checkbox-input {
    position: absolute;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%; }
  .auth-style .ant-checkbox-checked .ant-checkbox-inner:after {
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    content: " ";
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    opacity: 1; }
  .auth-style .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1890ff;
    border-color: #1890ff; }
  .auth-style .ant-checkbox-disabled {
    cursor: not-allowed; }
  .auth-style .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
    -webkit-animation-name: none;
    animation-name: none;
    border-color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-checkbox-disabled .ant-checkbox-input {
    cursor: not-allowed; }
  .auth-style .ant-checkbox-disabled .ant-checkbox-inner {
    border-color: #d9d9d9 !important;
    background-color: #f5f5f5; }
  .auth-style .ant-checkbox-disabled .ant-checkbox-inner:after {
    -webkit-animation-name: none;
    animation-name: none;
    border-color: #f5f5f5;
    border-collapse: separate; }
  .auth-style .ant-checkbox-disabled + span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed; }
  .auth-style .ant-checkbox-wrapper {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: unset;
    cursor: pointer;
    display: inline-block; }
  .auth-style .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 8px; }
  .auth-style .ant-checkbox + span,
  .auth-style .ant-checkbox-wrapper + span {
    padding-left: 8px;
    padding-right: 8px; }
  .auth-style .ant-checkbox-group {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block; }
  .auth-style .ant-checkbox-group-item {
    display: inline-block;
    margin-right: 8px; }
  .auth-style .ant-checkbox-group-item:last-child {
    margin-right: 0; }
  .auth-style .ant-checkbox-group-item + .ant-checkbox-group-item {
    margin-left: 0; }
  .auth-style .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: #fff;
    border-color: #d9d9d9; }
  .auth-style .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    content: " ";
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    border: 0;
    left: 50%;
    top: 50%;
    width: 8px;
    height: 8px;
    background-color: #1890ff;
    opacity: 1; }
  .auth-style .ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner:after {
    border-color: rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-row {
    position: relative;
    margin-left: 0;
    margin-right: 0;
    height: auto;
    zoom: 1;
    display: block;
    box-sizing: border-box; }
  .auth-style .ant-row:after,
  .auth-style .ant-row:before {
    content: "";
    display: table; }
  .auth-style .ant-row:after {
    clear: both; }
  .auth-style .ant-row-flex {
    flex-flow: row wrap; }
  .auth-style .ant-row-flex,
  .auth-style .ant-row-flex:after,
  .auth-style .ant-row-flex:before {
    display: flex; }
  .auth-style .ant-row-flex-start {
    justify-content: flex-start; }
  .auth-style .ant-row-flex-center {
    justify-content: center; }
  .auth-style .ant-row-flex-end {
    justify-content: flex-end; }
  .auth-style .ant-row-flex-space-between {
    justify-content: space-between; }
  .auth-style .ant-row-flex-space-around {
    justify-content: space-around; }
  .auth-style .ant-row-flex-top {
    align-items: flex-start; }
  .auth-style .ant-row-flex-middle {
    align-items: center; }
  .auth-style .ant-row-flex-bottom {
    align-items: flex-end; }
  .auth-style .ant-col {
    position: relative;
    display: block; }
  .auth-style .ant-col-1,
  .auth-style .ant-col-2,
  .auth-style .ant-col-3,
  .auth-style .ant-col-4,
  .auth-style .ant-col-5,
  .auth-style .ant-col-6,
  .auth-style .ant-col-7,
  .auth-style .ant-col-8,
  .auth-style .ant-col-9,
  .auth-style .ant-col-10,
  .auth-style .ant-col-11,
  .auth-style .ant-col-12,
  .auth-style .ant-col-13,
  .auth-style .ant-col-14,
  .auth-style .ant-col-15,
  .auth-style .ant-col-16,
  .auth-style .ant-col-17,
  .auth-style .ant-col-18,
  .auth-style .ant-col-19,
  .auth-style .ant-col-20,
  .auth-style .ant-col-21,
  .auth-style .ant-col-22,
  .auth-style .ant-col-23,
  .auth-style .ant-col-24,
  .auth-style .ant-col-lg-1,
  .auth-style .ant-col-lg-2,
  .auth-style .ant-col-lg-3,
  .auth-style .ant-col-lg-4,
  .auth-style .ant-col-lg-5,
  .auth-style .ant-col-lg-6,
  .auth-style .ant-col-lg-7,
  .auth-style .ant-col-lg-8,
  .auth-style .ant-col-lg-9,
  .auth-style .ant-col-lg-10,
  .auth-style .ant-col-lg-11,
  .auth-style .ant-col-lg-12,
  .auth-style .ant-col-lg-13,
  .auth-style .ant-col-lg-14,
  .auth-style .ant-col-lg-15,
  .auth-style .ant-col-lg-16,
  .auth-style .ant-col-lg-17,
  .auth-style .ant-col-lg-18,
  .auth-style .ant-col-lg-19,
  .auth-style .ant-col-lg-20,
  .auth-style .ant-col-lg-21,
  .auth-style .ant-col-lg-22,
  .auth-style .ant-col-lg-23,
  .auth-style .ant-col-lg-24,
  .auth-style .ant-col-md-1,
  .auth-style .ant-col-md-2,
  .auth-style .ant-col-md-3,
  .auth-style .ant-col-md-4,
  .auth-style .ant-col-md-5,
  .auth-style .ant-col-md-6,
  .auth-style .ant-col-md-7,
  .auth-style .ant-col-md-8,
  .auth-style .ant-col-md-9,
  .auth-style .ant-col-md-10,
  .auth-style .ant-col-md-11,
  .auth-style .ant-col-md-12,
  .auth-style .ant-col-md-13,
  .auth-style .ant-col-md-14,
  .auth-style .ant-col-md-15,
  .auth-style .ant-col-md-16,
  .auth-style .ant-col-md-17,
  .auth-style .ant-col-md-18,
  .auth-style .ant-col-md-19,
  .auth-style .ant-col-md-20,
  .auth-style .ant-col-md-21,
  .auth-style .ant-col-md-22,
  .auth-style .ant-col-md-23,
  .auth-style .ant-col-md-24,
  .auth-style .ant-col-sm-1,
  .auth-style .ant-col-sm-2,
  .auth-style .ant-col-sm-3,
  .auth-style .ant-col-sm-4,
  .auth-style .ant-col-sm-5,
  .auth-style .ant-col-sm-6,
  .auth-style .ant-col-sm-7,
  .auth-style .ant-col-sm-8,
  .auth-style .ant-col-sm-9,
  .auth-style .ant-col-sm-10,
  .auth-style .ant-col-sm-11,
  .auth-style .ant-col-sm-12,
  .auth-style .ant-col-sm-13,
  .auth-style .ant-col-sm-14,
  .auth-style .ant-col-sm-15,
  .auth-style .ant-col-sm-16,
  .auth-style .ant-col-sm-17,
  .auth-style .ant-col-sm-18,
  .auth-style .ant-col-sm-19,
  .auth-style .ant-col-sm-20,
  .auth-style .ant-col-sm-21,
  .auth-style .ant-col-sm-22,
  .auth-style .ant-col-sm-23,
  .auth-style .ant-col-sm-24,
  .auth-style .ant-col-xs-1,
  .auth-style .ant-col-xs-2,
  .auth-style .ant-col-xs-3,
  .auth-style .ant-col-xs-4,
  .auth-style .ant-col-xs-5,
  .auth-style .ant-col-xs-6,
  .auth-style .ant-col-xs-7,
  .auth-style .ant-col-xs-8,
  .auth-style .ant-col-xs-9,
  .auth-style .ant-col-xs-10,
  .auth-style .ant-col-xs-11,
  .auth-style .ant-col-xs-12,
  .auth-style .ant-col-xs-13,
  .auth-style .ant-col-xs-14,
  .auth-style .ant-col-xs-15,
  .auth-style .ant-col-xs-16,
  .auth-style .ant-col-xs-17,
  .auth-style .ant-col-xs-18,
  .auth-style .ant-col-xs-19,
  .auth-style .ant-col-xs-20,
  .auth-style .ant-col-xs-21,
  .auth-style .ant-col-xs-22,
  .auth-style .ant-col-xs-23,
  .auth-style .ant-col-xs-24 {
    position: relative;
    min-height: 1px;
    padding-left: 0;
    padding-right: 0; }
  .auth-style .ant-col-1,
  .auth-style .ant-col-2,
  .auth-style .ant-col-3,
  .auth-style .ant-col-4,
  .auth-style .ant-col-5,
  .auth-style .ant-col-6,
  .auth-style .ant-col-7,
  .auth-style .ant-col-8,
  .auth-style .ant-col-9,
  .auth-style .ant-col-10,
  .auth-style .ant-col-11,
  .auth-style .ant-col-12,
  .auth-style .ant-col-13,
  .auth-style .ant-col-14,
  .auth-style .ant-col-15,
  .auth-style .ant-col-16,
  .auth-style .ant-col-17,
  .auth-style .ant-col-18,
  .auth-style .ant-col-19,
  .auth-style .ant-col-20,
  .auth-style .ant-col-21,
  .auth-style .ant-col-22,
  .auth-style .ant-col-23,
  .auth-style .ant-col-24 {
    float: left;
    flex: 0 0 auto; }
  .auth-style .ant-col-24 {
    display: block;
    box-sizing: border-box;
    width: 100%; }
  .auth-style .ant-col-push-24 {
    left: 100%; }
  .auth-style .ant-col-pull-24 {
    right: 100%; }
  .auth-style .ant-col-offset-24 {
    margin-left: 100%; }
  .auth-style .ant-col-order-24 {
    order: 24; }
  .auth-style .ant-col-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%; }
  .auth-style .ant-col-push-23 {
    left: 95.83333333%; }
  .auth-style .ant-col-pull-23 {
    right: 95.83333333%; }
  .auth-style .ant-col-offset-23 {
    margin-left: 95.83333333%; }
  .auth-style .ant-col-order-23 {
    order: 23; }
  .auth-style .ant-col-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%; }
  .auth-style .ant-col-push-22 {
    left: 91.66666667%; }
  .auth-style .ant-col-pull-22 {
    right: 91.66666667%; }
  .auth-style .ant-col-offset-22 {
    margin-left: 91.66666667%; }
  .auth-style .ant-col-order-22 {
    order: 22; }
  .auth-style .ant-col-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%; }
  .auth-style .ant-col-push-21 {
    left: 87.5%; }
  .auth-style .ant-col-pull-21 {
    right: 87.5%; }
  .auth-style .ant-col-offset-21 {
    margin-left: 87.5%; }
  .auth-style .ant-col-order-21 {
    order: 21; }
  .auth-style .ant-col-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%; }
  .auth-style .ant-col-push-20 {
    left: 83.33333333%; }
  .auth-style .ant-col-pull-20 {
    right: 83.33333333%; }
  .auth-style .ant-col-offset-20 {
    margin-left: 83.33333333%; }
  .auth-style .ant-col-order-20 {
    order: 20; }
  .auth-style .ant-col-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%; }
  .auth-style .ant-col-push-19 {
    left: 79.16666667%; }
  .auth-style .ant-col-pull-19 {
    right: 79.16666667%; }
  .auth-style .ant-col-offset-19 {
    margin-left: 79.16666667%; }
  .auth-style .ant-col-order-19 {
    order: 19; }
  .auth-style .ant-col-18 {
    display: block;
    box-sizing: border-box;
    width: 75%; }
  .auth-style .ant-col-push-18 {
    left: 75%; }
  .auth-style .ant-col-pull-18 {
    right: 75%; }
  .auth-style .ant-col-offset-18 {
    margin-left: 75%; }
  .auth-style .ant-col-order-18 {
    order: 18; }
  .auth-style .ant-col-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%; }
  .auth-style .ant-col-push-17 {
    left: 70.83333333%; }
  .auth-style .ant-col-pull-17 {
    right: 70.83333333%; }
  .auth-style .ant-col-offset-17 {
    margin-left: 70.83333333%; }
  .auth-style .ant-col-order-17 {
    order: 17; }
  .auth-style .ant-col-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%; }
  .auth-style .ant-col-push-16 {
    left: 66.66666667%; }
  .auth-style .ant-col-pull-16 {
    right: 66.66666667%; }
  .auth-style .ant-col-offset-16 {
    margin-left: 66.66666667%; }
  .auth-style .ant-col-order-16 {
    order: 16; }
  .auth-style .ant-col-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%; }
  .auth-style .ant-col-push-15 {
    left: 62.5%; }
  .auth-style .ant-col-pull-15 {
    right: 62.5%; }
  .auth-style .ant-col-offset-15 {
    margin-left: 62.5%; }
  .auth-style .ant-col-order-15 {
    order: 15; }
  .auth-style .ant-col-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%; }
  .auth-style .ant-col-push-14 {
    left: 58.33333333%; }
  .auth-style .ant-col-pull-14 {
    right: 58.33333333%; }
  .auth-style .ant-col-offset-14 {
    margin-left: 58.33333333%; }
  .auth-style .ant-col-order-14 {
    order: 14; }
  .auth-style .ant-col-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%; }
  .auth-style .ant-col-push-13 {
    left: 54.16666667%; }
  .auth-style .ant-col-pull-13 {
    right: 54.16666667%; }
  .auth-style .ant-col-offset-13 {
    margin-left: 54.16666667%; }
  .auth-style .ant-col-order-13 {
    order: 13; }
  .auth-style .ant-col-12 {
    display: block;
    box-sizing: border-box;
    width: 50%; }
  .auth-style .ant-col-push-12 {
    left: 50%; }
  .auth-style .ant-col-pull-12 {
    right: 50%; }
  .auth-style .ant-col-offset-12 {
    margin-left: 50%; }
  .auth-style .ant-col-order-12 {
    order: 12; }
  .auth-style .ant-col-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%; }
  .auth-style .ant-col-push-11 {
    left: 45.83333333%; }
  .auth-style .ant-col-pull-11 {
    right: 45.83333333%; }
  .auth-style .ant-col-offset-11 {
    margin-left: 45.83333333%; }
  .auth-style .ant-col-order-11 {
    order: 11; }
  .auth-style .ant-col-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%; }
  .auth-style .ant-col-push-10 {
    left: 41.66666667%; }
  .auth-style .ant-col-pull-10 {
    right: 41.66666667%; }
  .auth-style .ant-col-offset-10 {
    margin-left: 41.66666667%; }
  .auth-style .ant-col-order-10 {
    order: 10; }
  .auth-style .ant-col-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%; }
  .auth-style .ant-col-push-9 {
    left: 37.5%; }
  .auth-style .ant-col-pull-9 {
    right: 37.5%; }
  .auth-style .ant-col-offset-9 {
    margin-left: 37.5%; }
  .auth-style .ant-col-order-9 {
    order: 9; }
  .auth-style .ant-col-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%; }
  .auth-style .ant-col-push-8 {
    left: 33.33333333%; }
  .auth-style .ant-col-pull-8 {
    right: 33.33333333%; }
  .auth-style .ant-col-offset-8 {
    margin-left: 33.33333333%; }
  .auth-style .ant-col-order-8 {
    order: 8; }
  .auth-style .ant-col-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%; }
  .auth-style .ant-col-push-7 {
    left: 29.16666667%; }
  .auth-style .ant-col-pull-7 {
    right: 29.16666667%; }
  .auth-style .ant-col-offset-7 {
    margin-left: 29.16666667%; }
  .auth-style .ant-col-order-7 {
    order: 7; }
  .auth-style .ant-col-6 {
    display: block;
    box-sizing: border-box;
    width: 25%; }
  .auth-style .ant-col-push-6 {
    left: 25%; }
  .auth-style .ant-col-pull-6 {
    right: 25%; }
  .auth-style .ant-col-offset-6 {
    margin-left: 25%; }
  .auth-style .ant-col-order-6 {
    order: 6; }
  .auth-style .ant-col-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%; }
  .auth-style .ant-col-push-5 {
    left: 20.83333333%; }
  .auth-style .ant-col-pull-5 {
    right: 20.83333333%; }
  .auth-style .ant-col-offset-5 {
    margin-left: 20.83333333%; }
  .auth-style .ant-col-order-5 {
    order: 5; }
  .auth-style .ant-col-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%; }
  .auth-style .ant-col-push-4 {
    left: 16.66666667%; }
  .auth-style .ant-col-pull-4 {
    right: 16.66666667%; }
  .auth-style .ant-col-offset-4 {
    margin-left: 16.66666667%; }
  .auth-style .ant-col-order-4 {
    order: 4; }
  .auth-style .ant-col-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%; }
  .auth-style .ant-col-push-3 {
    left: 12.5%; }
  .auth-style .ant-col-pull-3 {
    right: 12.5%; }
  .auth-style .ant-col-offset-3 {
    margin-left: 12.5%; }
  .auth-style .ant-col-order-3 {
    order: 3; }
  .auth-style .ant-col-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%; }
  .auth-style .ant-col-push-2 {
    left: 8.33333333%; }
  .auth-style .ant-col-pull-2 {
    right: 8.33333333%; }
  .auth-style .ant-col-offset-2 {
    margin-left: 8.33333333%; }
  .auth-style .ant-col-order-2 {
    order: 2; }
  .auth-style .ant-col-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%; }
  .auth-style .ant-col-push-1 {
    left: 4.16666667%; }
  .auth-style .ant-col-pull-1 {
    right: 4.16666667%; }
  .auth-style .ant-col-offset-1 {
    margin-left: 4.16666667%; }
  .auth-style .ant-col-order-1 {
    order: 1; }
  .auth-style .ant-col-0 {
    display: none; }
  .auth-style .ant-col-offset-0 {
    margin-left: 0; }
  .auth-style .ant-col-order-0 {
    order: 0; }
  .auth-style .ant-col-xs-1,
  .auth-style .ant-col-xs-2,
  .auth-style .ant-col-xs-3,
  .auth-style .ant-col-xs-4,
  .auth-style .ant-col-xs-5,
  .auth-style .ant-col-xs-6,
  .auth-style .ant-col-xs-7,
  .auth-style .ant-col-xs-8,
  .auth-style .ant-col-xs-9,
  .auth-style .ant-col-xs-10,
  .auth-style .ant-col-xs-11,
  .auth-style .ant-col-xs-12,
  .auth-style .ant-col-xs-13,
  .auth-style .ant-col-xs-14,
  .auth-style .ant-col-xs-15,
  .auth-style .ant-col-xs-16,
  .auth-style .ant-col-xs-17,
  .auth-style .ant-col-xs-18,
  .auth-style .ant-col-xs-19,
  .auth-style .ant-col-xs-20,
  .auth-style .ant-col-xs-21,
  .auth-style .ant-col-xs-22,
  .auth-style .ant-col-xs-23,
  .auth-style .ant-col-xs-24 {
    float: left;
    flex: 0 0 auto; }
  .auth-style .ant-col-xs-24 {
    display: block;
    box-sizing: border-box;
    width: 100%; }
  .auth-style .ant-col-xs-push-24 {
    left: 100%; }
  .auth-style .ant-col-xs-pull-24 {
    right: 100%; }
  .auth-style .ant-col-xs-offset-24 {
    margin-left: 100%; }
  .auth-style .ant-col-xs-order-24 {
    order: 24; }
  .auth-style .ant-col-xs-23 {
    display: block;
    box-sizing: border-box;
    width: 95.83333333%; }
  .auth-style .ant-col-xs-push-23 {
    left: 95.83333333%; }
  .auth-style .ant-col-xs-pull-23 {
    right: 95.83333333%; }
  .auth-style .ant-col-xs-offset-23 {
    margin-left: 95.83333333%; }
  .auth-style .ant-col-xs-order-23 {
    order: 23; }
  .auth-style .ant-col-xs-22 {
    display: block;
    box-sizing: border-box;
    width: 91.66666667%; }
  .auth-style .ant-col-xs-push-22 {
    left: 91.66666667%; }
  .auth-style .ant-col-xs-pull-22 {
    right: 91.66666667%; }
  .auth-style .ant-col-xs-offset-22 {
    margin-left: 91.66666667%; }
  .auth-style .ant-col-xs-order-22 {
    order: 22; }
  .auth-style .ant-col-xs-21 {
    display: block;
    box-sizing: border-box;
    width: 87.5%; }
  .auth-style .ant-col-xs-push-21 {
    left: 87.5%; }
  .auth-style .ant-col-xs-pull-21 {
    right: 87.5%; }
  .auth-style .ant-col-xs-offset-21 {
    margin-left: 87.5%; }
  .auth-style .ant-col-xs-order-21 {
    order: 21; }
  .auth-style .ant-col-xs-20 {
    display: block;
    box-sizing: border-box;
    width: 83.33333333%; }
  .auth-style .ant-col-xs-push-20 {
    left: 83.33333333%; }
  .auth-style .ant-col-xs-pull-20 {
    right: 83.33333333%; }
  .auth-style .ant-col-xs-offset-20 {
    margin-left: 83.33333333%; }
  .auth-style .ant-col-xs-order-20 {
    order: 20; }
  .auth-style .ant-col-xs-19 {
    display: block;
    box-sizing: border-box;
    width: 79.16666667%; }
  .auth-style .ant-col-xs-push-19 {
    left: 79.16666667%; }
  .auth-style .ant-col-xs-pull-19 {
    right: 79.16666667%; }
  .auth-style .ant-col-xs-offset-19 {
    margin-left: 79.16666667%; }
  .auth-style .ant-col-xs-order-19 {
    order: 19; }
  .auth-style .ant-col-xs-18 {
    display: block;
    box-sizing: border-box;
    width: 75%; }
  .auth-style .ant-col-xs-push-18 {
    left: 75%; }
  .auth-style .ant-col-xs-pull-18 {
    right: 75%; }
  .auth-style .ant-col-xs-offset-18 {
    margin-left: 75%; }
  .auth-style .ant-col-xs-order-18 {
    order: 18; }
  .auth-style .ant-col-xs-17 {
    display: block;
    box-sizing: border-box;
    width: 70.83333333%; }
  .auth-style .ant-col-xs-push-17 {
    left: 70.83333333%; }
  .auth-style .ant-col-xs-pull-17 {
    right: 70.83333333%; }
  .auth-style .ant-col-xs-offset-17 {
    margin-left: 70.83333333%; }
  .auth-style .ant-col-xs-order-17 {
    order: 17; }
  .auth-style .ant-col-xs-16 {
    display: block;
    box-sizing: border-box;
    width: 66.66666667%; }
  .auth-style .ant-col-xs-push-16 {
    left: 66.66666667%; }
  .auth-style .ant-col-xs-pull-16 {
    right: 66.66666667%; }
  .auth-style .ant-col-xs-offset-16 {
    margin-left: 66.66666667%; }
  .auth-style .ant-col-xs-order-16 {
    order: 16; }
  .auth-style .ant-col-xs-15 {
    display: block;
    box-sizing: border-box;
    width: 62.5%; }
  .auth-style .ant-col-xs-push-15 {
    left: 62.5%; }
  .auth-style .ant-col-xs-pull-15 {
    right: 62.5%; }
  .auth-style .ant-col-xs-offset-15 {
    margin-left: 62.5%; }
  .auth-style .ant-col-xs-order-15 {
    order: 15; }
  .auth-style .ant-col-xs-14 {
    display: block;
    box-sizing: border-box;
    width: 58.33333333%; }
  .auth-style .ant-col-xs-push-14 {
    left: 58.33333333%; }
  .auth-style .ant-col-xs-pull-14 {
    right: 58.33333333%; }
  .auth-style .ant-col-xs-offset-14 {
    margin-left: 58.33333333%; }
  .auth-style .ant-col-xs-order-14 {
    order: 14; }
  .auth-style .ant-col-xs-13 {
    display: block;
    box-sizing: border-box;
    width: 54.16666667%; }
  .auth-style .ant-col-xs-push-13 {
    left: 54.16666667%; }
  .auth-style .ant-col-xs-pull-13 {
    right: 54.16666667%; }
  .auth-style .ant-col-xs-offset-13 {
    margin-left: 54.16666667%; }
  .auth-style .ant-col-xs-order-13 {
    order: 13; }
  .auth-style .ant-col-xs-12 {
    display: block;
    box-sizing: border-box;
    width: 50%; }
  .auth-style .ant-col-xs-push-12 {
    left: 50%; }
  .auth-style .ant-col-xs-pull-12 {
    right: 50%; }
  .auth-style .ant-col-xs-offset-12 {
    margin-left: 50%; }
  .auth-style .ant-col-xs-order-12 {
    order: 12; }
  .auth-style .ant-col-xs-11 {
    display: block;
    box-sizing: border-box;
    width: 45.83333333%; }
  .auth-style .ant-col-xs-push-11 {
    left: 45.83333333%; }
  .auth-style .ant-col-xs-pull-11 {
    right: 45.83333333%; }
  .auth-style .ant-col-xs-offset-11 {
    margin-left: 45.83333333%; }
  .auth-style .ant-col-xs-order-11 {
    order: 11; }
  .auth-style .ant-col-xs-10 {
    display: block;
    box-sizing: border-box;
    width: 41.66666667%; }
  .auth-style .ant-col-xs-push-10 {
    left: 41.66666667%; }
  .auth-style .ant-col-xs-pull-10 {
    right: 41.66666667%; }
  .auth-style .ant-col-xs-offset-10 {
    margin-left: 41.66666667%; }
  .auth-style .ant-col-xs-order-10 {
    order: 10; }
  .auth-style .ant-col-xs-9 {
    display: block;
    box-sizing: border-box;
    width: 37.5%; }
  .auth-style .ant-col-xs-push-9 {
    left: 37.5%; }
  .auth-style .ant-col-xs-pull-9 {
    right: 37.5%; }
  .auth-style .ant-col-xs-offset-9 {
    margin-left: 37.5%; }
  .auth-style .ant-col-xs-order-9 {
    order: 9; }
  .auth-style .ant-col-xs-8 {
    display: block;
    box-sizing: border-box;
    width: 33.33333333%; }
  .auth-style .ant-col-xs-push-8 {
    left: 33.33333333%; }
  .auth-style .ant-col-xs-pull-8 {
    right: 33.33333333%; }
  .auth-style .ant-col-xs-offset-8 {
    margin-left: 33.33333333%; }
  .auth-style .ant-col-xs-order-8 {
    order: 8; }
  .auth-style .ant-col-xs-7 {
    display: block;
    box-sizing: border-box;
    width: 29.16666667%; }
  .auth-style .ant-col-xs-push-7 {
    left: 29.16666667%; }
  .auth-style .ant-col-xs-pull-7 {
    right: 29.16666667%; }
  .auth-style .ant-col-xs-offset-7 {
    margin-left: 29.16666667%; }
  .auth-style .ant-col-xs-order-7 {
    order: 7; }
  .auth-style .ant-col-xs-6 {
    display: block;
    box-sizing: border-box;
    width: 25%; }
  .auth-style .ant-col-xs-push-6 {
    left: 25%; }
  .auth-style .ant-col-xs-pull-6 {
    right: 25%; }
  .auth-style .ant-col-xs-offset-6 {
    margin-left: 25%; }
  .auth-style .ant-col-xs-order-6 {
    order: 6; }
  .auth-style .ant-col-xs-5 {
    display: block;
    box-sizing: border-box;
    width: 20.83333333%; }
  .auth-style .ant-col-xs-push-5 {
    left: 20.83333333%; }
  .auth-style .ant-col-xs-pull-5 {
    right: 20.83333333%; }
  .auth-style .ant-col-xs-offset-5 {
    margin-left: 20.83333333%; }
  .auth-style .ant-col-xs-order-5 {
    order: 5; }
  .auth-style .ant-col-xs-4 {
    display: block;
    box-sizing: border-box;
    width: 16.66666667%; }
  .auth-style .ant-col-xs-push-4 {
    left: 16.66666667%; }
  .auth-style .ant-col-xs-pull-4 {
    right: 16.66666667%; }
  .auth-style .ant-col-xs-offset-4 {
    margin-left: 16.66666667%; }
  .auth-style .ant-col-xs-order-4 {
    order: 4; }
  .auth-style .ant-col-xs-3 {
    display: block;
    box-sizing: border-box;
    width: 12.5%; }
  .auth-style .ant-col-xs-push-3 {
    left: 12.5%; }
  .auth-style .ant-col-xs-pull-3 {
    right: 12.5%; }
  .auth-style .ant-col-xs-offset-3 {
    margin-left: 12.5%; }
  .auth-style .ant-col-xs-order-3 {
    order: 3; }
  .auth-style .ant-col-xs-2 {
    display: block;
    box-sizing: border-box;
    width: 8.33333333%; }
  .auth-style .ant-col-xs-push-2 {
    left: 8.33333333%; }
  .auth-style .ant-col-xs-pull-2 {
    right: 8.33333333%; }
  .auth-style .ant-col-xs-offset-2 {
    margin-left: 8.33333333%; }
  .auth-style .ant-col-xs-order-2 {
    order: 2; }
  .auth-style .ant-col-xs-1 {
    display: block;
    box-sizing: border-box;
    width: 4.16666667%; }
  .auth-style .ant-col-xs-push-1 {
    left: 4.16666667%; }
  .auth-style .ant-col-xs-pull-1 {
    right: 4.16666667%; }
  .auth-style .ant-col-xs-offset-1 {
    margin-left: 4.16666667%; }
  .auth-style .ant-col-xs-order-1 {
    order: 1; }
  .auth-style .ant-col-xs-0 {
    display: none; }
  .auth-style .ant-col-push-0 {
    left: auto; }
  .auth-style .ant-col-pull-0 {
    right: auto; }
  .auth-style .ant-col-xs-push-0 {
    left: auto; }
  .auth-style .ant-col-xs-pull-0 {
    right: auto; }
  .auth-style .ant-col-xs-offset-0 {
    margin-left: 0; }
  .auth-style .ant-col-xs-order-0 {
    order: 0; }
  @media (min-width: 576px) {
    .auth-style .ant-col-sm-1,
    .auth-style .ant-col-sm-2,
    .auth-style .ant-col-sm-3,
    .auth-style .ant-col-sm-4,
    .auth-style .ant-col-sm-5,
    .auth-style .ant-col-sm-6,
    .auth-style .ant-col-sm-7,
    .auth-style .ant-col-sm-8,
    .auth-style .ant-col-sm-9,
    .auth-style .ant-col-sm-10,
    .auth-style .ant-col-sm-11,
    .auth-style .ant-col-sm-12,
    .auth-style .ant-col-sm-13,
    .auth-style .ant-col-sm-14,
    .auth-style .ant-col-sm-15,
    .auth-style .ant-col-sm-16,
    .auth-style .ant-col-sm-17,
    .auth-style .ant-col-sm-18,
    .auth-style .ant-col-sm-19,
    .auth-style .ant-col-sm-20,
    .auth-style .ant-col-sm-21,
    .auth-style .ant-col-sm-22,
    .auth-style .ant-col-sm-23,
    .auth-style .ant-col-sm-24 {
      float: left;
      flex: 0 0 auto; }
    .auth-style .ant-col-sm-24 {
      display: block;
      box-sizing: border-box;
      width: 100%; }
    .auth-style .ant-col-sm-push-24 {
      left: 100%; }
    .auth-style .ant-col-sm-pull-24 {
      right: 100%; }
    .auth-style .ant-col-sm-offset-24 {
      margin-left: 100%; }
    .auth-style .ant-col-sm-order-24 {
      order: 24; }
    .auth-style .ant-col-sm-23 {
      display: block;
      box-sizing: border-box;
      width: 95.83333333%; }
    .auth-style .ant-col-sm-push-23 {
      left: 95.83333333%; }
    .auth-style .ant-col-sm-pull-23 {
      right: 95.83333333%; }
    .auth-style .ant-col-sm-offset-23 {
      margin-left: 95.83333333%; }
    .auth-style .ant-col-sm-order-23 {
      order: 23; }
    .auth-style .ant-col-sm-22 {
      display: block;
      box-sizing: border-box;
      width: 91.66666667%; }
    .auth-style .ant-col-sm-push-22 {
      left: 91.66666667%; }
    .auth-style .ant-col-sm-pull-22 {
      right: 91.66666667%; }
    .auth-style .ant-col-sm-offset-22 {
      margin-left: 91.66666667%; }
    .auth-style .ant-col-sm-order-22 {
      order: 22; }
    .auth-style .ant-col-sm-21 {
      display: block;
      box-sizing: border-box;
      width: 87.5%; }
    .auth-style .ant-col-sm-push-21 {
      left: 87.5%; }
    .auth-style .ant-col-sm-pull-21 {
      right: 87.5%; }
    .auth-style .ant-col-sm-offset-21 {
      margin-left: 87.5%; }
    .auth-style .ant-col-sm-order-21 {
      order: 21; }
    .auth-style .ant-col-sm-20 {
      display: block;
      box-sizing: border-box;
      width: 83.33333333%; }
    .auth-style .ant-col-sm-push-20 {
      left: 83.33333333%; }
    .auth-style .ant-col-sm-pull-20 {
      right: 83.33333333%; }
    .auth-style .ant-col-sm-offset-20 {
      margin-left: 83.33333333%; }
    .auth-style .ant-col-sm-order-20 {
      order: 20; }
    .auth-style .ant-col-sm-19 {
      display: block;
      box-sizing: border-box;
      width: 79.16666667%; }
    .auth-style .ant-col-sm-push-19 {
      left: 79.16666667%; }
    .auth-style .ant-col-sm-pull-19 {
      right: 79.16666667%; }
    .auth-style .ant-col-sm-offset-19 {
      margin-left: 79.16666667%; }
    .auth-style .ant-col-sm-order-19 {
      order: 19; }
    .auth-style .ant-col-sm-18 {
      display: block;
      box-sizing: border-box;
      width: 75%; }
    .auth-style .ant-col-sm-push-18 {
      left: 75%; }
    .auth-style .ant-col-sm-pull-18 {
      right: 75%; }
    .auth-style .ant-col-sm-offset-18 {
      margin-left: 75%; }
    .auth-style .ant-col-sm-order-18 {
      order: 18; }
    .auth-style .ant-col-sm-17 {
      display: block;
      box-sizing: border-box;
      width: 70.83333333%; }
    .auth-style .ant-col-sm-push-17 {
      left: 70.83333333%; }
    .auth-style .ant-col-sm-pull-17 {
      right: 70.83333333%; }
    .auth-style .ant-col-sm-offset-17 {
      margin-left: 70.83333333%; }
    .auth-style .ant-col-sm-order-17 {
      order: 17; }
    .auth-style .ant-col-sm-16 {
      display: block;
      box-sizing: border-box;
      width: 66.66666667%; }
    .auth-style .ant-col-sm-push-16 {
      left: 66.66666667%; }
    .auth-style .ant-col-sm-pull-16 {
      right: 66.66666667%; }
    .auth-style .ant-col-sm-offset-16 {
      margin-left: 66.66666667%; }
    .auth-style .ant-col-sm-order-16 {
      order: 16; }
    .auth-style .ant-col-sm-15 {
      display: block;
      box-sizing: border-box;
      width: 62.5%; }
    .auth-style .ant-col-sm-push-15 {
      left: 62.5%; }
    .auth-style .ant-col-sm-pull-15 {
      right: 62.5%; }
    .auth-style .ant-col-sm-offset-15 {
      margin-left: 62.5%; }
    .auth-style .ant-col-sm-order-15 {
      order: 15; }
    .auth-style .ant-col-sm-14 {
      display: block;
      box-sizing: border-box;
      width: 58.33333333%; }
    .auth-style .ant-col-sm-push-14 {
      left: 58.33333333%; }
    .auth-style .ant-col-sm-pull-14 {
      right: 58.33333333%; }
    .auth-style .ant-col-sm-offset-14 {
      margin-left: 58.33333333%; }
    .auth-style .ant-col-sm-order-14 {
      order: 14; }
    .auth-style .ant-col-sm-13 {
      display: block;
      box-sizing: border-box;
      width: 54.16666667%; }
    .auth-style .ant-col-sm-push-13 {
      left: 54.16666667%; }
    .auth-style .ant-col-sm-pull-13 {
      right: 54.16666667%; }
    .auth-style .ant-col-sm-offset-13 {
      margin-left: 54.16666667%; }
    .auth-style .ant-col-sm-order-13 {
      order: 13; }
    .auth-style .ant-col-sm-12 {
      display: block;
      box-sizing: border-box;
      width: 50%; }
    .auth-style .ant-col-sm-push-12 {
      left: 50%; }
    .auth-style .ant-col-sm-pull-12 {
      right: 50%; }
    .auth-style .ant-col-sm-offset-12 {
      margin-left: 50%; }
    .auth-style .ant-col-sm-order-12 {
      order: 12; }
    .auth-style .ant-col-sm-11 {
      display: block;
      box-sizing: border-box;
      width: 45.83333333%; }
    .auth-style .ant-col-sm-push-11 {
      left: 45.83333333%; }
    .auth-style .ant-col-sm-pull-11 {
      right: 45.83333333%; }
    .auth-style .ant-col-sm-offset-11 {
      margin-left: 45.83333333%; }
    .auth-style .ant-col-sm-order-11 {
      order: 11; }
    .auth-style .ant-col-sm-10 {
      display: block;
      box-sizing: border-box;
      width: 41.66666667%; }
    .auth-style .ant-col-sm-push-10 {
      left: 41.66666667%; }
    .auth-style .ant-col-sm-pull-10 {
      right: 41.66666667%; }
    .auth-style .ant-col-sm-offset-10 {
      margin-left: 41.66666667%; }
    .auth-style .ant-col-sm-order-10 {
      order: 10; }
    .auth-style .ant-col-sm-9 {
      display: block;
      box-sizing: border-box;
      width: 37.5%; }
    .auth-style .ant-col-sm-push-9 {
      left: 37.5%; }
    .auth-style .ant-col-sm-pull-9 {
      right: 37.5%; }
    .auth-style .ant-col-sm-offset-9 {
      margin-left: 37.5%; }
    .auth-style .ant-col-sm-order-9 {
      order: 9; }
    .auth-style .ant-col-sm-8 {
      display: block;
      box-sizing: border-box;
      width: 33.33333333%; }
    .auth-style .ant-col-sm-push-8 {
      left: 33.33333333%; }
    .auth-style .ant-col-sm-pull-8 {
      right: 33.33333333%; }
    .auth-style .ant-col-sm-offset-8 {
      margin-left: 33.33333333%; }
    .auth-style .ant-col-sm-order-8 {
      order: 8; }
    .auth-style .ant-col-sm-7 {
      display: block;
      box-sizing: border-box;
      width: 29.16666667%; }
    .auth-style .ant-col-sm-push-7 {
      left: 29.16666667%; }
    .auth-style .ant-col-sm-pull-7 {
      right: 29.16666667%; }
    .auth-style .ant-col-sm-offset-7 {
      margin-left: 29.16666667%; }
    .auth-style .ant-col-sm-order-7 {
      order: 7; }
    .auth-style .ant-col-sm-6 {
      display: block;
      box-sizing: border-box;
      width: 25%; }
    .auth-style .ant-col-sm-push-6 {
      left: 25%; }
    .auth-style .ant-col-sm-pull-6 {
      right: 25%; }
    .auth-style .ant-col-sm-offset-6 {
      margin-left: 25%; }
    .auth-style .ant-col-sm-order-6 {
      order: 6; }
    .auth-style .ant-col-sm-5 {
      display: block;
      box-sizing: border-box;
      width: 20.83333333%; }
    .auth-style .ant-col-sm-push-5 {
      left: 20.83333333%; }
    .auth-style .ant-col-sm-pull-5 {
      right: 20.83333333%; }
    .auth-style .ant-col-sm-offset-5 {
      margin-left: 20.83333333%; }
    .auth-style .ant-col-sm-order-5 {
      order: 5; }
    .auth-style .ant-col-sm-4 {
      display: block;
      box-sizing: border-box;
      width: 16.66666667%; }
    .auth-style .ant-col-sm-push-4 {
      left: 16.66666667%; }
    .auth-style .ant-col-sm-pull-4 {
      right: 16.66666667%; }
    .auth-style .ant-col-sm-offset-4 {
      margin-left: 16.66666667%; }
    .auth-style .ant-col-sm-order-4 {
      order: 4; }
    .auth-style .ant-col-sm-3 {
      display: block;
      box-sizing: border-box;
      width: 12.5%; }
    .auth-style .ant-col-sm-push-3 {
      left: 12.5%; }
    .auth-style .ant-col-sm-pull-3 {
      right: 12.5%; }
    .auth-style .ant-col-sm-offset-3 {
      margin-left: 12.5%; }
    .auth-style .ant-col-sm-order-3 {
      order: 3; }
    .auth-style .ant-col-sm-2 {
      display: block;
      box-sizing: border-box;
      width: 8.33333333%; }
    .auth-style .ant-col-sm-push-2 {
      left: 8.33333333%; }
    .auth-style .ant-col-sm-pull-2 {
      right: 8.33333333%; }
    .auth-style .ant-col-sm-offset-2 {
      margin-left: 8.33333333%; }
    .auth-style .ant-col-sm-order-2 {
      order: 2; }
    .auth-style .ant-col-sm-1 {
      display: block;
      box-sizing: border-box;
      width: 4.16666667%; }
    .auth-style .ant-col-sm-push-1 {
      left: 4.16666667%; }
    .auth-style .ant-col-sm-pull-1 {
      right: 4.16666667%; }
    .auth-style .ant-col-sm-offset-1 {
      margin-left: 4.16666667%; }
    .auth-style .ant-col-sm-order-1 {
      order: 1; }
    .auth-style .ant-col-sm-0 {
      display: none; }
    .auth-style .ant-col-push-0 {
      left: auto; }
    .auth-style .ant-col-pull-0 {
      right: auto; }
    .auth-style .ant-col-sm-push-0 {
      left: auto; }
    .auth-style .ant-col-sm-pull-0 {
      right: auto; }
    .auth-style .ant-col-sm-offset-0 {
      margin-left: 0; }
    .auth-style .ant-col-sm-order-0 {
      order: 0; } }
  @media (min-width: 768px) {
    .auth-style .ant-col-md-1,
    .auth-style .ant-col-md-2,
    .auth-style .ant-col-md-3,
    .auth-style .ant-col-md-4,
    .auth-style .ant-col-md-5,
    .auth-style .ant-col-md-6,
    .auth-style .ant-col-md-7,
    .auth-style .ant-col-md-8,
    .auth-style .ant-col-md-9,
    .auth-style .ant-col-md-10,
    .auth-style .ant-col-md-11,
    .auth-style .ant-col-md-12,
    .auth-style .ant-col-md-13,
    .auth-style .ant-col-md-14,
    .auth-style .ant-col-md-15,
    .auth-style .ant-col-md-16,
    .auth-style .ant-col-md-17,
    .auth-style .ant-col-md-18,
    .auth-style .ant-col-md-19,
    .auth-style .ant-col-md-20,
    .auth-style .ant-col-md-21,
    .auth-style .ant-col-md-22,
    .auth-style .ant-col-md-23,
    .auth-style .ant-col-md-24 {
      float: left;
      flex: 0 0 auto; }
    .auth-style .ant-col-md-24 {
      display: block;
      box-sizing: border-box;
      width: 100%; }
    .auth-style .ant-col-md-push-24 {
      left: 100%; }
    .auth-style .ant-col-md-pull-24 {
      right: 100%; }
    .auth-style .ant-col-md-offset-24 {
      margin-left: 100%; }
    .auth-style .ant-col-md-order-24 {
      order: 24; }
    .auth-style .ant-col-md-23 {
      display: block;
      box-sizing: border-box;
      width: 95.83333333%; }
    .auth-style .ant-col-md-push-23 {
      left: 95.83333333%; }
    .auth-style .ant-col-md-pull-23 {
      right: 95.83333333%; }
    .auth-style .ant-col-md-offset-23 {
      margin-left: 95.83333333%; }
    .auth-style .ant-col-md-order-23 {
      order: 23; }
    .auth-style .ant-col-md-22 {
      display: block;
      box-sizing: border-box;
      width: 91.66666667%; }
    .auth-style .ant-col-md-push-22 {
      left: 91.66666667%; }
    .auth-style .ant-col-md-pull-22 {
      right: 91.66666667%; }
    .auth-style .ant-col-md-offset-22 {
      margin-left: 91.66666667%; }
    .auth-style .ant-col-md-order-22 {
      order: 22; }
    .auth-style .ant-col-md-21 {
      display: block;
      box-sizing: border-box;
      width: 87.5%; }
    .auth-style .ant-col-md-push-21 {
      left: 87.5%; }
    .auth-style .ant-col-md-pull-21 {
      right: 87.5%; }
    .auth-style .ant-col-md-offset-21 {
      margin-left: 87.5%; }
    .auth-style .ant-col-md-order-21 {
      order: 21; }
    .auth-style .ant-col-md-20 {
      display: block;
      box-sizing: border-box;
      width: 83.33333333%; }
    .auth-style .ant-col-md-push-20 {
      left: 83.33333333%; }
    .auth-style .ant-col-md-pull-20 {
      right: 83.33333333%; }
    .auth-style .ant-col-md-offset-20 {
      margin-left: 83.33333333%; }
    .auth-style .ant-col-md-order-20 {
      order: 20; }
    .auth-style .ant-col-md-19 {
      display: block;
      box-sizing: border-box;
      width: 79.16666667%; }
    .auth-style .ant-col-md-push-19 {
      left: 79.16666667%; }
    .auth-style .ant-col-md-pull-19 {
      right: 79.16666667%; }
    .auth-style .ant-col-md-offset-19 {
      margin-left: 79.16666667%; }
    .auth-style .ant-col-md-order-19 {
      order: 19; }
    .auth-style .ant-col-md-18 {
      display: block;
      box-sizing: border-box;
      width: 75%; }
    .auth-style .ant-col-md-push-18 {
      left: 75%; }
    .auth-style .ant-col-md-pull-18 {
      right: 75%; }
    .auth-style .ant-col-md-offset-18 {
      margin-left: 75%; }
    .auth-style .ant-col-md-order-18 {
      order: 18; }
    .auth-style .ant-col-md-17 {
      display: block;
      box-sizing: border-box;
      width: 70.83333333%; }
    .auth-style .ant-col-md-push-17 {
      left: 70.83333333%; }
    .auth-style .ant-col-md-pull-17 {
      right: 70.83333333%; }
    .auth-style .ant-col-md-offset-17 {
      margin-left: 70.83333333%; }
    .auth-style .ant-col-md-order-17 {
      order: 17; }
    .auth-style .ant-col-md-16 {
      display: block;
      box-sizing: border-box;
      width: 66.66666667%; }
    .auth-style .ant-col-md-push-16 {
      left: 66.66666667%; }
    .auth-style .ant-col-md-pull-16 {
      right: 66.66666667%; }
    .auth-style .ant-col-md-offset-16 {
      margin-left: 66.66666667%; }
    .auth-style .ant-col-md-order-16 {
      order: 16; }
    .auth-style .ant-col-md-15 {
      display: block;
      box-sizing: border-box;
      width: 62.5%; }
    .auth-style .ant-col-md-push-15 {
      left: 62.5%; }
    .auth-style .ant-col-md-pull-15 {
      right: 62.5%; }
    .auth-style .ant-col-md-offset-15 {
      margin-left: 62.5%; }
    .auth-style .ant-col-md-order-15 {
      order: 15; }
    .auth-style .ant-col-md-14 {
      display: block;
      box-sizing: border-box;
      width: 58.33333333%; }
    .auth-style .ant-col-md-push-14 {
      left: 58.33333333%; }
    .auth-style .ant-col-md-pull-14 {
      right: 58.33333333%; }
    .auth-style .ant-col-md-offset-14 {
      margin-left: 58.33333333%; }
    .auth-style .ant-col-md-order-14 {
      order: 14; }
    .auth-style .ant-col-md-13 {
      display: block;
      box-sizing: border-box;
      width: 54.16666667%; }
    .auth-style .ant-col-md-push-13 {
      left: 54.16666667%; }
    .auth-style .ant-col-md-pull-13 {
      right: 54.16666667%; }
    .auth-style .ant-col-md-offset-13 {
      margin-left: 54.16666667%; }
    .auth-style .ant-col-md-order-13 {
      order: 13; }
    .auth-style .ant-col-md-12 {
      display: block;
      box-sizing: border-box;
      width: 50%; }
    .auth-style .ant-col-md-push-12 {
      left: 50%; }
    .auth-style .ant-col-md-pull-12 {
      right: 50%; }
    .auth-style .ant-col-md-offset-12 {
      margin-left: 50%; }
    .auth-style .ant-col-md-order-12 {
      order: 12; }
    .auth-style .ant-col-md-11 {
      display: block;
      box-sizing: border-box;
      width: 45.83333333%; }
    .auth-style .ant-col-md-push-11 {
      left: 45.83333333%; }
    .auth-style .ant-col-md-pull-11 {
      right: 45.83333333%; }
    .auth-style .ant-col-md-offset-11 {
      margin-left: 45.83333333%; }
    .auth-style .ant-col-md-order-11 {
      order: 11; }
    .auth-style .ant-col-md-10 {
      display: block;
      box-sizing: border-box;
      width: 41.66666667%; }
    .auth-style .ant-col-md-push-10 {
      left: 41.66666667%; }
    .auth-style .ant-col-md-pull-10 {
      right: 41.66666667%; }
    .auth-style .ant-col-md-offset-10 {
      margin-left: 41.66666667%; }
    .auth-style .ant-col-md-order-10 {
      order: 10; }
    .auth-style .ant-col-md-9 {
      display: block;
      box-sizing: border-box;
      width: 37.5%; }
    .auth-style .ant-col-md-push-9 {
      left: 37.5%; }
    .auth-style .ant-col-md-pull-9 {
      right: 37.5%; }
    .auth-style .ant-col-md-offset-9 {
      margin-left: 37.5%; }
    .auth-style .ant-col-md-order-9 {
      order: 9; }
    .auth-style .ant-col-md-8 {
      display: block;
      box-sizing: border-box;
      width: 33.33333333%; }
    .auth-style .ant-col-md-push-8 {
      left: 33.33333333%; }
    .auth-style .ant-col-md-pull-8 {
      right: 33.33333333%; }
    .auth-style .ant-col-md-offset-8 {
      margin-left: 33.33333333%; }
    .auth-style .ant-col-md-order-8 {
      order: 8; }
    .auth-style .ant-col-md-7 {
      display: block;
      box-sizing: border-box;
      width: 29.16666667%; }
    .auth-style .ant-col-md-push-7 {
      left: 29.16666667%; }
    .auth-style .ant-col-md-pull-7 {
      right: 29.16666667%; }
    .auth-style .ant-col-md-offset-7 {
      margin-left: 29.16666667%; }
    .auth-style .ant-col-md-order-7 {
      order: 7; }
    .auth-style .ant-col-md-6 {
      display: block;
      box-sizing: border-box;
      width: 25%; }
    .auth-style .ant-col-md-push-6 {
      left: 25%; }
    .auth-style .ant-col-md-pull-6 {
      right: 25%; }
    .auth-style .ant-col-md-offset-6 {
      margin-left: 25%; }
    .auth-style .ant-col-md-order-6 {
      order: 6; }
    .auth-style .ant-col-md-5 {
      display: block;
      box-sizing: border-box;
      width: 20.83333333%; }
    .auth-style .ant-col-md-push-5 {
      left: 20.83333333%; }
    .auth-style .ant-col-md-pull-5 {
      right: 20.83333333%; }
    .auth-style .ant-col-md-offset-5 {
      margin-left: 20.83333333%; }
    .auth-style .ant-col-md-order-5 {
      order: 5; }
    .auth-style .ant-col-md-4 {
      display: block;
      box-sizing: border-box;
      width: 16.66666667%; }
    .auth-style .ant-col-md-push-4 {
      left: 16.66666667%; }
    .auth-style .ant-col-md-pull-4 {
      right: 16.66666667%; }
    .auth-style .ant-col-md-offset-4 {
      margin-left: 16.66666667%; }
    .auth-style .ant-col-md-order-4 {
      order: 4; }
    .auth-style .ant-col-md-3 {
      display: block;
      box-sizing: border-box;
      width: 12.5%; }
    .auth-style .ant-col-md-push-3 {
      left: 12.5%; }
    .auth-style .ant-col-md-pull-3 {
      right: 12.5%; }
    .auth-style .ant-col-md-offset-3 {
      margin-left: 12.5%; }
    .auth-style .ant-col-md-order-3 {
      order: 3; }
    .auth-style .ant-col-md-2 {
      display: block;
      box-sizing: border-box;
      width: 8.33333333%; }
    .auth-style .ant-col-md-push-2 {
      left: 8.33333333%; }
    .auth-style .ant-col-md-pull-2 {
      right: 8.33333333%; }
    .auth-style .ant-col-md-offset-2 {
      margin-left: 8.33333333%; }
    .auth-style .ant-col-md-order-2 {
      order: 2; }
    .auth-style .ant-col-md-1 {
      display: block;
      box-sizing: border-box;
      width: 4.16666667%; }
    .auth-style .ant-col-md-push-1 {
      left: 4.16666667%; }
    .auth-style .ant-col-md-pull-1 {
      right: 4.16666667%; }
    .auth-style .ant-col-md-offset-1 {
      margin-left: 4.16666667%; }
    .auth-style .ant-col-md-order-1 {
      order: 1; }
    .auth-style .ant-col-md-0 {
      display: none; }
    .auth-style .ant-col-push-0 {
      left: auto; }
    .auth-style .ant-col-pull-0 {
      right: auto; }
    .auth-style .ant-col-md-push-0 {
      left: auto; }
    .auth-style .ant-col-md-pull-0 {
      right: auto; }
    .auth-style .ant-col-md-offset-0 {
      margin-left: 0; }
    .auth-style .ant-col-md-order-0 {
      order: 0; } }
  @media (min-width: 992px) {
    .auth-style .ant-col-lg-1,
    .auth-style .ant-col-lg-2,
    .auth-style .ant-col-lg-3,
    .auth-style .ant-col-lg-4,
    .auth-style .ant-col-lg-5,
    .auth-style .ant-col-lg-6,
    .auth-style .ant-col-lg-7,
    .auth-style .ant-col-lg-8,
    .auth-style .ant-col-lg-9,
    .auth-style .ant-col-lg-10,
    .auth-style .ant-col-lg-11,
    .auth-style .ant-col-lg-12,
    .auth-style .ant-col-lg-13,
    .auth-style .ant-col-lg-14,
    .auth-style .ant-col-lg-15,
    .auth-style .ant-col-lg-16,
    .auth-style .ant-col-lg-17,
    .auth-style .ant-col-lg-18,
    .auth-style .ant-col-lg-19,
    .auth-style .ant-col-lg-20,
    .auth-style .ant-col-lg-21,
    .auth-style .ant-col-lg-22,
    .auth-style .ant-col-lg-23,
    .auth-style .ant-col-lg-24 {
      float: left;
      flex: 0 0 auto; }
    .auth-style .ant-col-lg-24 {
      display: block;
      box-sizing: border-box;
      width: 100%; }
    .auth-style .ant-col-lg-push-24 {
      left: 100%; }
    .auth-style .ant-col-lg-pull-24 {
      right: 100%; }
    .auth-style .ant-col-lg-offset-24 {
      margin-left: 100%; }
    .auth-style .ant-col-lg-order-24 {
      order: 24; }
    .auth-style .ant-col-lg-23 {
      display: block;
      box-sizing: border-box;
      width: 95.83333333%; }
    .auth-style .ant-col-lg-push-23 {
      left: 95.83333333%; }
    .auth-style .ant-col-lg-pull-23 {
      right: 95.83333333%; }
    .auth-style .ant-col-lg-offset-23 {
      margin-left: 95.83333333%; }
    .auth-style .ant-col-lg-order-23 {
      order: 23; }
    .auth-style .ant-col-lg-22 {
      display: block;
      box-sizing: border-box;
      width: 91.66666667%; }
    .auth-style .ant-col-lg-push-22 {
      left: 91.66666667%; }
    .auth-style .ant-col-lg-pull-22 {
      right: 91.66666667%; }
    .auth-style .ant-col-lg-offset-22 {
      margin-left: 91.66666667%; }
    .auth-style .ant-col-lg-order-22 {
      order: 22; }
    .auth-style .ant-col-lg-21 {
      display: block;
      box-sizing: border-box;
      width: 87.5%; }
    .auth-style .ant-col-lg-push-21 {
      left: 87.5%; }
    .auth-style .ant-col-lg-pull-21 {
      right: 87.5%; }
    .auth-style .ant-col-lg-offset-21 {
      margin-left: 87.5%; }
    .auth-style .ant-col-lg-order-21 {
      order: 21; }
    .auth-style .ant-col-lg-20 {
      display: block;
      box-sizing: border-box;
      width: 83.33333333%; }
    .auth-style .ant-col-lg-push-20 {
      left: 83.33333333%; }
    .auth-style .ant-col-lg-pull-20 {
      right: 83.33333333%; }
    .auth-style .ant-col-lg-offset-20 {
      margin-left: 83.33333333%; }
    .auth-style .ant-col-lg-order-20 {
      order: 20; }
    .auth-style .ant-col-lg-19 {
      display: block;
      box-sizing: border-box;
      width: 79.16666667%; }
    .auth-style .ant-col-lg-push-19 {
      left: 79.16666667%; }
    .auth-style .ant-col-lg-pull-19 {
      right: 79.16666667%; }
    .auth-style .ant-col-lg-offset-19 {
      margin-left: 79.16666667%; }
    .auth-style .ant-col-lg-order-19 {
      order: 19; }
    .auth-style .ant-col-lg-18 {
      display: block;
      box-sizing: border-box;
      width: 75%; }
    .auth-style .ant-col-lg-push-18 {
      left: 75%; }
    .auth-style .ant-col-lg-pull-18 {
      right: 75%; }
    .auth-style .ant-col-lg-offset-18 {
      margin-left: 75%; }
    .auth-style .ant-col-lg-order-18 {
      order: 18; }
    .auth-style .ant-col-lg-17 {
      display: block;
      box-sizing: border-box;
      width: 70.83333333%; }
    .auth-style .ant-col-lg-push-17 {
      left: 70.83333333%; }
    .auth-style .ant-col-lg-pull-17 {
      right: 70.83333333%; }
    .auth-style .ant-col-lg-offset-17 {
      margin-left: 70.83333333%; }
    .auth-style .ant-col-lg-order-17 {
      order: 17; }
    .auth-style .ant-col-lg-16 {
      display: block;
      box-sizing: border-box;
      width: 66.66666667%; }
    .auth-style .ant-col-lg-push-16 {
      left: 66.66666667%; }
    .auth-style .ant-col-lg-pull-16 {
      right: 66.66666667%; }
    .auth-style .ant-col-lg-offset-16 {
      margin-left: 66.66666667%; }
    .auth-style .ant-col-lg-order-16 {
      order: 16; }
    .auth-style .ant-col-lg-15 {
      display: block;
      box-sizing: border-box;
      width: 62.5%; }
    .auth-style .ant-col-lg-push-15 {
      left: 62.5%; }
    .auth-style .ant-col-lg-pull-15 {
      right: 62.5%; }
    .auth-style .ant-col-lg-offset-15 {
      margin-left: 62.5%; }
    .auth-style .ant-col-lg-order-15 {
      order: 15; }
    .auth-style .ant-col-lg-14 {
      display: block;
      box-sizing: border-box;
      width: 58.33333333%; }
    .auth-style .ant-col-lg-push-14 {
      left: 58.33333333%; }
    .auth-style .ant-col-lg-pull-14 {
      right: 58.33333333%; }
    .auth-style .ant-col-lg-offset-14 {
      margin-left: 58.33333333%; }
    .auth-style .ant-col-lg-order-14 {
      order: 14; }
    .auth-style .ant-col-lg-13 {
      display: block;
      box-sizing: border-box;
      width: 54.16666667%; }
    .auth-style .ant-col-lg-push-13 {
      left: 54.16666667%; }
    .auth-style .ant-col-lg-pull-13 {
      right: 54.16666667%; }
    .auth-style .ant-col-lg-offset-13 {
      margin-left: 54.16666667%; }
    .auth-style .ant-col-lg-order-13 {
      order: 13; }
    .auth-style .ant-col-lg-12 {
      display: block;
      box-sizing: border-box;
      width: 50%; }
    .auth-style .ant-col-lg-push-12 {
      left: 50%; }
    .auth-style .ant-col-lg-pull-12 {
      right: 50%; }
    .auth-style .ant-col-lg-offset-12 {
      margin-left: 50%; }
    .auth-style .ant-col-lg-order-12 {
      order: 12; }
    .auth-style .ant-col-lg-11 {
      display: block;
      box-sizing: border-box;
      width: 45.83333333%; }
    .auth-style .ant-col-lg-push-11 {
      left: 45.83333333%; }
    .auth-style .ant-col-lg-pull-11 {
      right: 45.83333333%; }
    .auth-style .ant-col-lg-offset-11 {
      margin-left: 45.83333333%; }
    .auth-style .ant-col-lg-order-11 {
      order: 11; }
    .auth-style .ant-col-lg-10 {
      display: block;
      box-sizing: border-box;
      width: 41.66666667%; }
    .auth-style .ant-col-lg-push-10 {
      left: 41.66666667%; }
    .auth-style .ant-col-lg-pull-10 {
      right: 41.66666667%; }
    .auth-style .ant-col-lg-offset-10 {
      margin-left: 41.66666667%; }
    .auth-style .ant-col-lg-order-10 {
      order: 10; }
    .auth-style .ant-col-lg-9 {
      display: block;
      box-sizing: border-box;
      width: 37.5%; }
    .auth-style .ant-col-lg-push-9 {
      left: 37.5%; }
    .auth-style .ant-col-lg-pull-9 {
      right: 37.5%; }
    .auth-style .ant-col-lg-offset-9 {
      margin-left: 37.5%; }
    .auth-style .ant-col-lg-order-9 {
      order: 9; }
    .auth-style .ant-col-lg-8 {
      display: block;
      box-sizing: border-box;
      width: 33.33333333%; }
    .auth-style .ant-col-lg-push-8 {
      left: 33.33333333%; }
    .auth-style .ant-col-lg-pull-8 {
      right: 33.33333333%; }
    .auth-style .ant-col-lg-offset-8 {
      margin-left: 33.33333333%; }
    .auth-style .ant-col-lg-order-8 {
      order: 8; }
    .auth-style .ant-col-lg-7 {
      display: block;
      box-sizing: border-box;
      width: 29.16666667%; }
    .auth-style .ant-col-lg-push-7 {
      left: 29.16666667%; }
    .auth-style .ant-col-lg-pull-7 {
      right: 29.16666667%; }
    .auth-style .ant-col-lg-offset-7 {
      margin-left: 29.16666667%; }
    .auth-style .ant-col-lg-order-7 {
      order: 7; }
    .auth-style .ant-col-lg-6 {
      display: block;
      box-sizing: border-box;
      width: 25%; }
    .auth-style .ant-col-lg-push-6 {
      left: 25%; }
    .auth-style .ant-col-lg-pull-6 {
      right: 25%; }
    .auth-style .ant-col-lg-offset-6 {
      margin-left: 25%; }
    .auth-style .ant-col-lg-order-6 {
      order: 6; }
    .auth-style .ant-col-lg-5 {
      display: block;
      box-sizing: border-box;
      width: 20.83333333%; }
    .auth-style .ant-col-lg-push-5 {
      left: 20.83333333%; }
    .auth-style .ant-col-lg-pull-5 {
      right: 20.83333333%; }
    .auth-style .ant-col-lg-offset-5 {
      margin-left: 20.83333333%; }
    .auth-style .ant-col-lg-order-5 {
      order: 5; }
    .auth-style .ant-col-lg-4 {
      display: block;
      box-sizing: border-box;
      width: 16.66666667%; }
    .auth-style .ant-col-lg-push-4 {
      left: 16.66666667%; }
    .auth-style .ant-col-lg-pull-4 {
      right: 16.66666667%; }
    .auth-style .ant-col-lg-offset-4 {
      margin-left: 16.66666667%; }
    .auth-style .ant-col-lg-order-4 {
      order: 4; }
    .auth-style .ant-col-lg-3 {
      display: block;
      box-sizing: border-box;
      width: 12.5%; }
    .auth-style .ant-col-lg-push-3 {
      left: 12.5%; }
    .auth-style .ant-col-lg-pull-3 {
      right: 12.5%; }
    .auth-style .ant-col-lg-offset-3 {
      margin-left: 12.5%; }
    .auth-style .ant-col-lg-order-3 {
      order: 3; }
    .auth-style .ant-col-lg-2 {
      display: block;
      box-sizing: border-box;
      width: 8.33333333%; }
    .auth-style .ant-col-lg-push-2 {
      left: 8.33333333%; }
    .auth-style .ant-col-lg-pull-2 {
      right: 8.33333333%; }
    .auth-style .ant-col-lg-offset-2 {
      margin-left: 8.33333333%; }
    .auth-style .ant-col-lg-order-2 {
      order: 2; }
    .auth-style .ant-col-lg-1 {
      display: block;
      box-sizing: border-box;
      width: 4.16666667%; }
    .auth-style .ant-col-lg-push-1 {
      left: 4.16666667%; }
    .auth-style .ant-col-lg-pull-1 {
      right: 4.16666667%; }
    .auth-style .ant-col-lg-offset-1 {
      margin-left: 4.16666667%; }
    .auth-style .ant-col-lg-order-1 {
      order: 1; }
    .auth-style .ant-col-lg-0 {
      display: none; }
    .auth-style .ant-col-push-0 {
      left: auto; }
    .auth-style .ant-col-pull-0 {
      right: auto; }
    .auth-style .ant-col-lg-push-0 {
      left: auto; }
    .auth-style .ant-col-lg-pull-0 {
      right: auto; }
    .auth-style .ant-col-lg-offset-0 {
      margin-left: 0; }
    .auth-style .ant-col-lg-order-0 {
      order: 0; } }
  @media (min-width: 1200px) {
    .auth-style .ant-col-xl-1,
    .auth-style .ant-col-xl-2,
    .auth-style .ant-col-xl-3,
    .auth-style .ant-col-xl-4,
    .auth-style .ant-col-xl-5,
    .auth-style .ant-col-xl-6,
    .auth-style .ant-col-xl-7,
    .auth-style .ant-col-xl-8,
    .auth-style .ant-col-xl-9,
    .auth-style .ant-col-xl-10,
    .auth-style .ant-col-xl-11,
    .auth-style .ant-col-xl-12,
    .auth-style .ant-col-xl-13,
    .auth-style .ant-col-xl-14,
    .auth-style .ant-col-xl-15,
    .auth-style .ant-col-xl-16,
    .auth-style .ant-col-xl-17,
    .auth-style .ant-col-xl-18,
    .auth-style .ant-col-xl-19,
    .auth-style .ant-col-xl-20,
    .auth-style .ant-col-xl-21,
    .auth-style .ant-col-xl-22,
    .auth-style .ant-col-xl-23,
    .auth-style .ant-col-xl-24 {
      float: left;
      flex: 0 0 auto; }
    .auth-style .ant-col-xl-24 {
      display: block;
      box-sizing: border-box;
      width: 100%; }
    .auth-style .ant-col-xl-push-24 {
      left: 100%; }
    .auth-style .ant-col-xl-pull-24 {
      right: 100%; }
    .auth-style .ant-col-xl-offset-24 {
      margin-left: 100%; }
    .auth-style .ant-col-xl-order-24 {
      order: 24; }
    .auth-style .ant-col-xl-23 {
      display: block;
      box-sizing: border-box;
      width: 95.83333333%; }
    .auth-style .ant-col-xl-push-23 {
      left: 95.83333333%; }
    .auth-style .ant-col-xl-pull-23 {
      right: 95.83333333%; }
    .auth-style .ant-col-xl-offset-23 {
      margin-left: 95.83333333%; }
    .auth-style .ant-col-xl-order-23 {
      order: 23; }
    .auth-style .ant-col-xl-22 {
      display: block;
      box-sizing: border-box;
      width: 91.66666667%; }
    .auth-style .ant-col-xl-push-22 {
      left: 91.66666667%; }
    .auth-style .ant-col-xl-pull-22 {
      right: 91.66666667%; }
    .auth-style .ant-col-xl-offset-22 {
      margin-left: 91.66666667%; }
    .auth-style .ant-col-xl-order-22 {
      order: 22; }
    .auth-style .ant-col-xl-21 {
      display: block;
      box-sizing: border-box;
      width: 87.5%; }
    .auth-style .ant-col-xl-push-21 {
      left: 87.5%; }
    .auth-style .ant-col-xl-pull-21 {
      right: 87.5%; }
    .auth-style .ant-col-xl-offset-21 {
      margin-left: 87.5%; }
    .auth-style .ant-col-xl-order-21 {
      order: 21; }
    .auth-style .ant-col-xl-20 {
      display: block;
      box-sizing: border-box;
      width: 83.33333333%; }
    .auth-style .ant-col-xl-push-20 {
      left: 83.33333333%; }
    .auth-style .ant-col-xl-pull-20 {
      right: 83.33333333%; }
    .auth-style .ant-col-xl-offset-20 {
      margin-left: 83.33333333%; }
    .auth-style .ant-col-xl-order-20 {
      order: 20; }
    .auth-style .ant-col-xl-19 {
      display: block;
      box-sizing: border-box;
      width: 79.16666667%; }
    .auth-style .ant-col-xl-push-19 {
      left: 79.16666667%; }
    .auth-style .ant-col-xl-pull-19 {
      right: 79.16666667%; }
    .auth-style .ant-col-xl-offset-19 {
      margin-left: 79.16666667%; }
    .auth-style .ant-col-xl-order-19 {
      order: 19; }
    .auth-style .ant-col-xl-18 {
      display: block;
      box-sizing: border-box;
      width: 75%; }
    .auth-style .ant-col-xl-push-18 {
      left: 75%; }
    .auth-style .ant-col-xl-pull-18 {
      right: 75%; }
    .auth-style .ant-col-xl-offset-18 {
      margin-left: 75%; }
    .auth-style .ant-col-xl-order-18 {
      order: 18; }
    .auth-style .ant-col-xl-17 {
      display: block;
      box-sizing: border-box;
      width: 70.83333333%; }
    .auth-style .ant-col-xl-push-17 {
      left: 70.83333333%; }
    .auth-style .ant-col-xl-pull-17 {
      right: 70.83333333%; }
    .auth-style .ant-col-xl-offset-17 {
      margin-left: 70.83333333%; }
    .auth-style .ant-col-xl-order-17 {
      order: 17; }
    .auth-style .ant-col-xl-16 {
      display: block;
      box-sizing: border-box;
      width: 66.66666667%; }
    .auth-style .ant-col-xl-push-16 {
      left: 66.66666667%; }
    .auth-style .ant-col-xl-pull-16 {
      right: 66.66666667%; }
    .auth-style .ant-col-xl-offset-16 {
      margin-left: 66.66666667%; }
    .auth-style .ant-col-xl-order-16 {
      order: 16; }
    .auth-style .ant-col-xl-15 {
      display: block;
      box-sizing: border-box;
      width: 62.5%; }
    .auth-style .ant-col-xl-push-15 {
      left: 62.5%; }
    .auth-style .ant-col-xl-pull-15 {
      right: 62.5%; }
    .auth-style .ant-col-xl-offset-15 {
      margin-left: 62.5%; }
    .auth-style .ant-col-xl-order-15 {
      order: 15; }
    .auth-style .ant-col-xl-14 {
      display: block;
      box-sizing: border-box;
      width: 58.33333333%; }
    .auth-style .ant-col-xl-push-14 {
      left: 58.33333333%; }
    .auth-style .ant-col-xl-pull-14 {
      right: 58.33333333%; }
    .auth-style .ant-col-xl-offset-14 {
      margin-left: 58.33333333%; }
    .auth-style .ant-col-xl-order-14 {
      order: 14; }
    .auth-style .ant-col-xl-13 {
      display: block;
      box-sizing: border-box;
      width: 54.16666667%; }
    .auth-style .ant-col-xl-push-13 {
      left: 54.16666667%; }
    .auth-style .ant-col-xl-pull-13 {
      right: 54.16666667%; }
    .auth-style .ant-col-xl-offset-13 {
      margin-left: 54.16666667%; }
    .auth-style .ant-col-xl-order-13 {
      order: 13; }
    .auth-style .ant-col-xl-12 {
      display: block;
      box-sizing: border-box;
      width: 50%; }
    .auth-style .ant-col-xl-push-12 {
      left: 50%; }
    .auth-style .ant-col-xl-pull-12 {
      right: 50%; }
    .auth-style .ant-col-xl-offset-12 {
      margin-left: 50%; }
    .auth-style .ant-col-xl-order-12 {
      order: 12; }
    .auth-style .ant-col-xl-11 {
      display: block;
      box-sizing: border-box;
      width: 45.83333333%; }
    .auth-style .ant-col-xl-push-11 {
      left: 45.83333333%; }
    .auth-style .ant-col-xl-pull-11 {
      right: 45.83333333%; }
    .auth-style .ant-col-xl-offset-11 {
      margin-left: 45.83333333%; }
    .auth-style .ant-col-xl-order-11 {
      order: 11; }
    .auth-style .ant-col-xl-10 {
      display: block;
      box-sizing: border-box;
      width: 41.66666667%; }
    .auth-style .ant-col-xl-push-10 {
      left: 41.66666667%; }
    .auth-style .ant-col-xl-pull-10 {
      right: 41.66666667%; }
    .auth-style .ant-col-xl-offset-10 {
      margin-left: 41.66666667%; }
    .auth-style .ant-col-xl-order-10 {
      order: 10; }
    .auth-style .ant-col-xl-9 {
      display: block;
      box-sizing: border-box;
      width: 37.5%; }
    .auth-style .ant-col-xl-push-9 {
      left: 37.5%; }
    .auth-style .ant-col-xl-pull-9 {
      right: 37.5%; }
    .auth-style .ant-col-xl-offset-9 {
      margin-left: 37.5%; }
    .auth-style .ant-col-xl-order-9 {
      order: 9; }
    .auth-style .ant-col-xl-8 {
      display: block;
      box-sizing: border-box;
      width: 33.33333333%; }
    .auth-style .ant-col-xl-push-8 {
      left: 33.33333333%; }
    .auth-style .ant-col-xl-pull-8 {
      right: 33.33333333%; }
    .auth-style .ant-col-xl-offset-8 {
      margin-left: 33.33333333%; }
    .auth-style .ant-col-xl-order-8 {
      order: 8; }
    .auth-style .ant-col-xl-7 {
      display: block;
      box-sizing: border-box;
      width: 29.16666667%; }
    .auth-style .ant-col-xl-push-7 {
      left: 29.16666667%; }
    .auth-style .ant-col-xl-pull-7 {
      right: 29.16666667%; }
    .auth-style .ant-col-xl-offset-7 {
      margin-left: 29.16666667%; }
    .auth-style .ant-col-xl-order-7 {
      order: 7; }
    .auth-style .ant-col-xl-6 {
      display: block;
      box-sizing: border-box;
      width: 25%; }
    .auth-style .ant-col-xl-push-6 {
      left: 25%; }
    .auth-style .ant-col-xl-pull-6 {
      right: 25%; }
    .auth-style .ant-col-xl-offset-6 {
      margin-left: 25%; }
    .auth-style .ant-col-xl-order-6 {
      order: 6; }
    .auth-style .ant-col-xl-5 {
      display: block;
      box-sizing: border-box;
      width: 20.83333333%; }
    .auth-style .ant-col-xl-push-5 {
      left: 20.83333333%; }
    .auth-style .ant-col-xl-pull-5 {
      right: 20.83333333%; }
    .auth-style .ant-col-xl-offset-5 {
      margin-left: 20.83333333%; }
    .auth-style .ant-col-xl-order-5 {
      order: 5; }
    .auth-style .ant-col-xl-4 {
      display: block;
      box-sizing: border-box;
      width: 16.66666667%; }
    .auth-style .ant-col-xl-push-4 {
      left: 16.66666667%; }
    .auth-style .ant-col-xl-pull-4 {
      right: 16.66666667%; }
    .auth-style .ant-col-xl-offset-4 {
      margin-left: 16.66666667%; }
    .auth-style .ant-col-xl-order-4 {
      order: 4; }
    .auth-style .ant-col-xl-3 {
      display: block;
      box-sizing: border-box;
      width: 12.5%; }
    .auth-style .ant-col-xl-push-3 {
      left: 12.5%; }
    .auth-style .ant-col-xl-pull-3 {
      right: 12.5%; }
    .auth-style .ant-col-xl-offset-3 {
      margin-left: 12.5%; }
    .auth-style .ant-col-xl-order-3 {
      order: 3; }
    .auth-style .ant-col-xl-2 {
      display: block;
      box-sizing: border-box;
      width: 8.33333333%; }
    .auth-style .ant-col-xl-push-2 {
      left: 8.33333333%; }
    .auth-style .ant-col-xl-pull-2 {
      right: 8.33333333%; }
    .auth-style .ant-col-xl-offset-2 {
      margin-left: 8.33333333%; }
    .auth-style .ant-col-xl-order-2 {
      order: 2; }
    .auth-style .ant-col-xl-1 {
      display: block;
      box-sizing: border-box;
      width: 4.16666667%; }
    .auth-style .ant-col-xl-push-1 {
      left: 4.16666667%; }
    .auth-style .ant-col-xl-pull-1 {
      right: 4.16666667%; }
    .auth-style .ant-col-xl-offset-1 {
      margin-left: 4.16666667%; }
    .auth-style .ant-col-xl-order-1 {
      order: 1; }
    .auth-style .ant-col-xl-0 {
      display: none; }
    .auth-style .ant-col-push-0 {
      left: auto; }
    .auth-style .ant-col-pull-0 {
      right: auto; }
    .auth-style .ant-col-xl-push-0 {
      left: auto; }
    .auth-style .ant-col-xl-pull-0 {
      right: auto; }
    .auth-style .ant-col-xl-offset-0 {
      margin-left: 0; }
    .auth-style .ant-col-xl-order-0 {
      order: 0; } }
  @media (min-width: 1600px) {
    .auth-style .ant-col-xxl-1,
    .auth-style .ant-col-xxl-2,
    .auth-style .ant-col-xxl-3,
    .auth-style .ant-col-xxl-4,
    .auth-style .ant-col-xxl-5,
    .auth-style .ant-col-xxl-6,
    .auth-style .ant-col-xxl-7,
    .auth-style .ant-col-xxl-8,
    .auth-style .ant-col-xxl-9,
    .auth-style .ant-col-xxl-10,
    .auth-style .ant-col-xxl-11,
    .auth-style .ant-col-xxl-12,
    .auth-style .ant-col-xxl-13,
    .auth-style .ant-col-xxl-14,
    .auth-style .ant-col-xxl-15,
    .auth-style .ant-col-xxl-16,
    .auth-style .ant-col-xxl-17,
    .auth-style .ant-col-xxl-18,
    .auth-style .ant-col-xxl-19,
    .auth-style .ant-col-xxl-20,
    .auth-style .ant-col-xxl-21,
    .auth-style .ant-col-xxl-22,
    .auth-style .ant-col-xxl-23,
    .auth-style .ant-col-xxl-24 {
      float: left;
      flex: 0 0 auto; }
    .auth-style .ant-col-xxl-24 {
      display: block;
      box-sizing: border-box;
      width: 100%; }
    .auth-style .ant-col-xxl-push-24 {
      left: 100%; }
    .auth-style .ant-col-xxl-pull-24 {
      right: 100%; }
    .auth-style .ant-col-xxl-offset-24 {
      margin-left: 100%; }
    .auth-style .ant-col-xxl-order-24 {
      order: 24; }
    .auth-style .ant-col-xxl-23 {
      display: block;
      box-sizing: border-box;
      width: 95.83333333%; }
    .auth-style .ant-col-xxl-push-23 {
      left: 95.83333333%; }
    .auth-style .ant-col-xxl-pull-23 {
      right: 95.83333333%; }
    .auth-style .ant-col-xxl-offset-23 {
      margin-left: 95.83333333%; }
    .auth-style .ant-col-xxl-order-23 {
      order: 23; }
    .auth-style .ant-col-xxl-22 {
      display: block;
      box-sizing: border-box;
      width: 91.66666667%; }
    .auth-style .ant-col-xxl-push-22 {
      left: 91.66666667%; }
    .auth-style .ant-col-xxl-pull-22 {
      right: 91.66666667%; }
    .auth-style .ant-col-xxl-offset-22 {
      margin-left: 91.66666667%; }
    .auth-style .ant-col-xxl-order-22 {
      order: 22; }
    .auth-style .ant-col-xxl-21 {
      display: block;
      box-sizing: border-box;
      width: 87.5%; }
    .auth-style .ant-col-xxl-push-21 {
      left: 87.5%; }
    .auth-style .ant-col-xxl-pull-21 {
      right: 87.5%; }
    .auth-style .ant-col-xxl-offset-21 {
      margin-left: 87.5%; }
    .auth-style .ant-col-xxl-order-21 {
      order: 21; }
    .auth-style .ant-col-xxl-20 {
      display: block;
      box-sizing: border-box;
      width: 83.33333333%; }
    .auth-style .ant-col-xxl-push-20 {
      left: 83.33333333%; }
    .auth-style .ant-col-xxl-pull-20 {
      right: 83.33333333%; }
    .auth-style .ant-col-xxl-offset-20 {
      margin-left: 83.33333333%; }
    .auth-style .ant-col-xxl-order-20 {
      order: 20; }
    .auth-style .ant-col-xxl-19 {
      display: block;
      box-sizing: border-box;
      width: 79.16666667%; }
    .auth-style .ant-col-xxl-push-19 {
      left: 79.16666667%; }
    .auth-style .ant-col-xxl-pull-19 {
      right: 79.16666667%; }
    .auth-style .ant-col-xxl-offset-19 {
      margin-left: 79.16666667%; }
    .auth-style .ant-col-xxl-order-19 {
      order: 19; }
    .auth-style .ant-col-xxl-18 {
      display: block;
      box-sizing: border-box;
      width: 75%; }
    .auth-style .ant-col-xxl-push-18 {
      left: 75%; }
    .auth-style .ant-col-xxl-pull-18 {
      right: 75%; }
    .auth-style .ant-col-xxl-offset-18 {
      margin-left: 75%; }
    .auth-style .ant-col-xxl-order-18 {
      order: 18; }
    .auth-style .ant-col-xxl-17 {
      display: block;
      box-sizing: border-box;
      width: 70.83333333%; }
    .auth-style .ant-col-xxl-push-17 {
      left: 70.83333333%; }
    .auth-style .ant-col-xxl-pull-17 {
      right: 70.83333333%; }
    .auth-style .ant-col-xxl-offset-17 {
      margin-left: 70.83333333%; }
    .auth-style .ant-col-xxl-order-17 {
      order: 17; }
    .auth-style .ant-col-xxl-16 {
      display: block;
      box-sizing: border-box;
      width: 66.66666667%; }
    .auth-style .ant-col-xxl-push-16 {
      left: 66.66666667%; }
    .auth-style .ant-col-xxl-pull-16 {
      right: 66.66666667%; }
    .auth-style .ant-col-xxl-offset-16 {
      margin-left: 66.66666667%; }
    .auth-style .ant-col-xxl-order-16 {
      order: 16; }
    .auth-style .ant-col-xxl-15 {
      display: block;
      box-sizing: border-box;
      width: 62.5%; }
    .auth-style .ant-col-xxl-push-15 {
      left: 62.5%; }
    .auth-style .ant-col-xxl-pull-15 {
      right: 62.5%; }
    .auth-style .ant-col-xxl-offset-15 {
      margin-left: 62.5%; }
    .auth-style .ant-col-xxl-order-15 {
      order: 15; }
    .auth-style .ant-col-xxl-14 {
      display: block;
      box-sizing: border-box;
      width: 58.33333333%; }
    .auth-style .ant-col-xxl-push-14 {
      left: 58.33333333%; }
    .auth-style .ant-col-xxl-pull-14 {
      right: 58.33333333%; }
    .auth-style .ant-col-xxl-offset-14 {
      margin-left: 58.33333333%; }
    .auth-style .ant-col-xxl-order-14 {
      order: 14; }
    .auth-style .ant-col-xxl-13 {
      display: block;
      box-sizing: border-box;
      width: 54.16666667%; }
    .auth-style .ant-col-xxl-push-13 {
      left: 54.16666667%; }
    .auth-style .ant-col-xxl-pull-13 {
      right: 54.16666667%; }
    .auth-style .ant-col-xxl-offset-13 {
      margin-left: 54.16666667%; }
    .auth-style .ant-col-xxl-order-13 {
      order: 13; }
    .auth-style .ant-col-xxl-12 {
      display: block;
      box-sizing: border-box;
      width: 50%; }
    .auth-style .ant-col-xxl-push-12 {
      left: 50%; }
    .auth-style .ant-col-xxl-pull-12 {
      right: 50%; }
    .auth-style .ant-col-xxl-offset-12 {
      margin-left: 50%; }
    .auth-style .ant-col-xxl-order-12 {
      order: 12; }
    .auth-style .ant-col-xxl-11 {
      display: block;
      box-sizing: border-box;
      width: 45.83333333%; }
    .auth-style .ant-col-xxl-push-11 {
      left: 45.83333333%; }
    .auth-style .ant-col-xxl-pull-11 {
      right: 45.83333333%; }
    .auth-style .ant-col-xxl-offset-11 {
      margin-left: 45.83333333%; }
    .auth-style .ant-col-xxl-order-11 {
      order: 11; }
    .auth-style .ant-col-xxl-10 {
      display: block;
      box-sizing: border-box;
      width: 41.66666667%; }
    .auth-style .ant-col-xxl-push-10 {
      left: 41.66666667%; }
    .auth-style .ant-col-xxl-pull-10 {
      right: 41.66666667%; }
    .auth-style .ant-col-xxl-offset-10 {
      margin-left: 41.66666667%; }
    .auth-style .ant-col-xxl-order-10 {
      order: 10; }
    .auth-style .ant-col-xxl-9 {
      display: block;
      box-sizing: border-box;
      width: 37.5%; }
    .auth-style .ant-col-xxl-push-9 {
      left: 37.5%; }
    .auth-style .ant-col-xxl-pull-9 {
      right: 37.5%; }
    .auth-style .ant-col-xxl-offset-9 {
      margin-left: 37.5%; }
    .auth-style .ant-col-xxl-order-9 {
      order: 9; }
    .auth-style .ant-col-xxl-8 {
      display: block;
      box-sizing: border-box;
      width: 33.33333333%; }
    .auth-style .ant-col-xxl-push-8 {
      left: 33.33333333%; }
    .auth-style .ant-col-xxl-pull-8 {
      right: 33.33333333%; }
    .auth-style .ant-col-xxl-offset-8 {
      margin-left: 33.33333333%; }
    .auth-style .ant-col-xxl-order-8 {
      order: 8; }
    .auth-style .ant-col-xxl-7 {
      display: block;
      box-sizing: border-box;
      width: 29.16666667%; }
    .auth-style .ant-col-xxl-push-7 {
      left: 29.16666667%; }
    .auth-style .ant-col-xxl-pull-7 {
      right: 29.16666667%; }
    .auth-style .ant-col-xxl-offset-7 {
      margin-left: 29.16666667%; }
    .auth-style .ant-col-xxl-order-7 {
      order: 7; }
    .auth-style .ant-col-xxl-6 {
      display: block;
      box-sizing: border-box;
      width: 25%; }
    .auth-style .ant-col-xxl-push-6 {
      left: 25%; }
    .auth-style .ant-col-xxl-pull-6 {
      right: 25%; }
    .auth-style .ant-col-xxl-offset-6 {
      margin-left: 25%; }
    .auth-style .ant-col-xxl-order-6 {
      order: 6; }
    .auth-style .ant-col-xxl-5 {
      display: block;
      box-sizing: border-box;
      width: 20.83333333%; }
    .auth-style .ant-col-xxl-push-5 {
      left: 20.83333333%; }
    .auth-style .ant-col-xxl-pull-5 {
      right: 20.83333333%; }
    .auth-style .ant-col-xxl-offset-5 {
      margin-left: 20.83333333%; }
    .auth-style .ant-col-xxl-order-5 {
      order: 5; }
    .auth-style .ant-col-xxl-4 {
      display: block;
      box-sizing: border-box;
      width: 16.66666667%; }
    .auth-style .ant-col-xxl-push-4 {
      left: 16.66666667%; }
    .auth-style .ant-col-xxl-pull-4 {
      right: 16.66666667%; }
    .auth-style .ant-col-xxl-offset-4 {
      margin-left: 16.66666667%; }
    .auth-style .ant-col-xxl-order-4 {
      order: 4; }
    .auth-style .ant-col-xxl-3 {
      display: block;
      box-sizing: border-box;
      width: 12.5%; }
    .auth-style .ant-col-xxl-push-3 {
      left: 12.5%; }
    .auth-style .ant-col-xxl-pull-3 {
      right: 12.5%; }
    .auth-style .ant-col-xxl-offset-3 {
      margin-left: 12.5%; }
    .auth-style .ant-col-xxl-order-3 {
      order: 3; }
    .auth-style .ant-col-xxl-2 {
      display: block;
      box-sizing: border-box;
      width: 8.33333333%; }
    .auth-style .ant-col-xxl-push-2 {
      left: 8.33333333%; }
    .auth-style .ant-col-xxl-pull-2 {
      right: 8.33333333%; }
    .auth-style .ant-col-xxl-offset-2 {
      margin-left: 8.33333333%; }
    .auth-style .ant-col-xxl-order-2 {
      order: 2; }
    .auth-style .ant-col-xxl-1 {
      display: block;
      box-sizing: border-box;
      width: 4.16666667%; }
    .auth-style .ant-col-xxl-push-1 {
      left: 4.16666667%; }
    .auth-style .ant-col-xxl-pull-1 {
      right: 4.16666667%; }
    .auth-style .ant-col-xxl-offset-1 {
      margin-left: 4.16666667%; }
    .auth-style .ant-col-xxl-order-1 {
      order: 1; }
    .auth-style .ant-col-xxl-0 {
      display: none; }
    .auth-style .ant-col-push-0 {
      left: auto; }
    .auth-style .ant-col-pull-0 {
      right: auto; }
    .auth-style .ant-col-xxl-push-0 {
      left: auto; }
    .auth-style .ant-col-xxl-pull-0 {
      right: auto; }
    .auth-style .ant-col-xxl-offset-0 {
      margin-left: 0; }
    .auth-style .ant-col-xxl-order-0 {
      order: 0; } }
  .auth-style .ant-collapse {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #fafafa;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    border-bottom: 0; }
  .auth-style .ant-collapse > .ant-collapse-item {
    border-bottom: 1px solid #d9d9d9; }
  .auth-style .ant-collapse > .ant-collapse-item:last-child,
  .auth-style .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 0 0 4px 4px; }
  .auth-style .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    line-height: 22px;
    padding: 12px 0 12px 40px;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    position: relative;
    transition: all 0.3s; }
  .auth-style .ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow {
    font-style: normal;
    vertical-align: -0.125em;
    text-align: center;
    text-transform: none;
    line-height: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    position: absolute;
    display: inline-block;
    line-height: 46px;
    vertical-align: top;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 16px; }
  .auth-style .ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow > * {
    line-height: 1; }
  .auth-style .ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow svg {
    display: inline-block; }
  .auth-style .ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow:before {
    display: none; }
  .auth-style .ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.arrow
.ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.arrow-icon {
    display: block; }
  .auth-style .ant-collapse > .ant-collapse-item > .ant-collapse-header .arrow svg {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    transition: -webkit-transform 0.24s;
    transition: transform 0.24s;
    transition: transform 0.24s, -webkit-transform 0.24s; }
  .auth-style .ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
    outline: none; }
  .auth-style .ant-collapse
> .ant-collapse-item.ant-collapse-no-arrow
> .ant-collapse-header {
    padding-left: 12px; }
  .auth-style .ant-collapse-anim-active {
    transition: height 0.2s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .auth-style .ant-collapse-content {
    overflow: hidden;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    border-top: 1px solid #d9d9d9; }
  .auth-style .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px; }
  .auth-style .ant-collapse-content-inactive {
    display: none; }
  .auth-style .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0 0 4px 4px; }
  .auth-style .ant-collapse
> .ant-collapse-item
> .ant-collapse-header[aria-expanded="true"]
.anticon-right
svg {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg); }
  .auth-style .ant-collapse-borderless {
    background-color: #fff;
    border: 0; }
  .auth-style .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: 1px solid #d9d9d9; }
  .auth-style .ant-collapse-borderless > .ant-collapse-item:last-child,
  .auth-style .ant-collapse-borderless
> .ant-collapse-item:last-child
.ant-collapse-header {
    border-radius: 0; }
  .auth-style .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    background-color: transparent;
    border-top: 0; }
  .auth-style .ant-collapse-borderless
> .ant-collapse-item
> .ant-collapse-content
> .ant-collapse-content-box {
    padding-top: 4px; }
  .auth-style .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
  .auth-style .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-comment {
    position: relative; }
  .auth-style .ant-comment-inner {
    padding: 16px 0;
    display: flex; }
  .auth-style .ant-comment-avatar {
    flex-shrink: 0;
    position: relative;
    margin-right: 12px;
    cursor: pointer; }
  .auth-style .ant-comment-avatar img {
    width: 32px;
    height: 32px;
    border-radius: 50%; }
  .auth-style .ant-comment-content {
    position: relative;
    font-size: 14px;
    flex: 1 1 auto;
    min-width: 1px;
    word-wrap: break-word; }
  .auth-style .ant-comment-content-author {
    margin-bottom: 4px;
    font-size: 14px;
    display: flex;
    justify-content: flex-start; }
  .auth-style .ant-comment-content-author > a,
  .auth-style .ant-comment-content-author > span {
    height: 18px;
    font-size: 12px;
    line-height: 18px;
    padding-right: 8px; }
  .auth-style .ant-comment-content-author-name {
    transition: color 0.3s;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-comment-content-author-name > *,
  .auth-style .ant-comment-content-author-name > :hover {
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-comment-content-author-time {
    cursor: auto;
    color: #ccc;
    white-space: nowrap; }
  .auth-style .ant-comment-content-detail p {
    white-space: pre-wrap; }
  .auth-style .ant-comment-actions {
    margin-top: 12px;
    padding-left: 0; }
  .auth-style .ant-comment-actions > li {
    display: inline-block;
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-comment-actions > li > span {
    padding-right: 10px;
    transition: color 0.3s;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .auth-style .ant-comment-actions > li > span:hover {
    color: #595959; }
  .auth-style .ant-comment-nested {
    margin-left: 44px; }
  .auth-style .ant-calendar-picker-container {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    font-family: Chinese Quote, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    position: absolute;
    z-index: 1050; }
  .auth-style .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topLeft,
  .auth-style .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-topRight,
  .auth-style .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topLeft,
  .auth-style .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-topRight {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn; }
  .auth-style .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomLeft,
  .auth-style .ant-calendar-picker-container.slide-up-appear.slide-up-appear-active.ant-calendar-picker-container-placement-bottomRight,
  .auth-style .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomLeft,
  .auth-style .ant-calendar-picker-container.slide-up-enter.slide-up-enter-active.ant-calendar-picker-container-placement-bottomRight {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn; }
  .auth-style .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topLeft,
  .auth-style .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-topRight {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut; }
  .auth-style .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomLeft,
  .auth-style .ant-calendar-picker-container.slide-up-leave.slide-up-leave-active.ant-calendar-picker-container-placement-bottomRight {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut; }
  .auth-style .ant-calendar-picker {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    outline: none;
    transition: opacity 0.3s; }
  .auth-style .ant-calendar-picker-input {
    outline: none; }
  .auth-style .ant-calendar-picker-input.ant-input-sm {
    padding-top: 0;
    padding-bottom: 0; }
  .auth-style .ant-calendar-picker:hover
.ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: #1890ff; }
  .auth-style .ant-calendar-picker:focus
.ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: #40a9ff;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    border-right-width: 1px !important; }
  .auth-style .ant-calendar-picker-clear,
  .auth-style .ant-calendar-picker-icon {
    position: absolute;
    width: 14px;
    height: 14px;
    right: 12px;
    top: 50%;
    margin-top: -7px;
    line-height: 14px;
    font-size: 12px;
    transition: all 0.3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 1; }
  .auth-style .ant-calendar-picker-clear {
    opacity: 0;
    z-index: 2;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.25);
    background: #fff;
    pointer-events: none;
    cursor: pointer; }
  .auth-style .ant-calendar-picker-clear:hover {
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-calendar-picker:hover .ant-calendar-picker-clear {
    opacity: 1;
    pointer-events: auto; }
  .auth-style .ant-calendar-picker-icon {
    font-family: anticon;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.25);
    display: inline-block;
    line-height: 1; }
  .auth-style .ant-calendar-picker-small .ant-calendar-picker-clear,
  .auth-style .ant-calendar-picker-small .ant-calendar-picker-icon {
    right: 8px; }
  .auth-style .ant-calendar {
    position: relative;
    outline: none;
    width: 280px;
    border: 1px solid #fff;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    background-clip: padding-box;
    line-height: 1.5; }
  .auth-style .ant-calendar-input-wrap {
    height: 34px;
    padding: 6px 10px;
    border-bottom: 1px solid #e8e8e8; }
  .auth-style .ant-calendar-input {
    border: 0;
    width: 100%;
    cursor: auto;
    outline: 0;
    height: 22px;
    color: rgba(0, 0, 0, 0.65);
    background: #fff; }
  .auth-style .ant-calendar-input::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1; }
  .auth-style .ant-calendar-input:-ms-input-placeholder {
    color: #bfbfbf; }
  .auth-style .ant-calendar-input::-webkit-input-placeholder {
    color: #bfbfbf; }
  .auth-style .ant-calendar-week-number {
    width: 286px; }
  .auth-style .ant-calendar-week-number-cell {
    text-align: center; }
  .auth-style .ant-calendar-header {
    height: 40px;
    line-height: 40px;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom: 1px solid #e8e8e8; }
  .auth-style .ant-calendar-header a:hover {
    color: #40a9ff; }
  .auth-style .ant-calendar-header .ant-calendar-century-select,
  .auth-style .ant-calendar-header .ant-calendar-decade-select,
  .auth-style .ant-calendar-header .ant-calendar-month-select,
  .auth-style .ant-calendar-header .ant-calendar-year-select {
    padding: 0 2px;
    font-weight: 500;
    display: inline-block;
    color: rgba(0, 0, 0, 0.85);
    line-height: 40px; }
  .auth-style .ant-calendar-header .ant-calendar-century-select-arrow,
  .auth-style .ant-calendar-header .ant-calendar-decade-select-arrow,
  .auth-style .ant-calendar-header .ant-calendar-month-select-arrow,
  .auth-style .ant-calendar-header .ant-calendar-year-select-arrow {
    display: none; }
  .auth-style .ant-calendar-header .ant-calendar-next-century-btn,
  .auth-style .ant-calendar-header .ant-calendar-next-decade-btn,
  .auth-style .ant-calendar-header .ant-calendar-next-month-btn,
  .auth-style .ant-calendar-header .ant-calendar-next-year-btn,
  .auth-style .ant-calendar-header .ant-calendar-prev-century-btn,
  .auth-style .ant-calendar-header .ant-calendar-prev-decade-btn,
  .auth-style .ant-calendar-header .ant-calendar-prev-month-btn,
  .auth-style .ant-calendar-header .ant-calendar-prev-year-btn {
    position: absolute;
    top: 0;
    color: rgba(0, 0, 0, 0.45);
    font-family: Arial, Hiragino Sans GB, Microsoft Yahei, "Microsoft Sans Serif", sans-serif;
    padding: 0 5px;
    font-size: 16px;
    display: inline-block;
    line-height: 40px; }
  .auth-style .ant-calendar-header .ant-calendar-prev-century-btn,
  .auth-style .ant-calendar-header .ant-calendar-prev-decade-btn,
  .auth-style .ant-calendar-header .ant-calendar-prev-year-btn {
    left: 7px; }
  .auth-style .ant-calendar-header .ant-calendar-prev-century-btn:after,
  .auth-style .ant-calendar-header .ant-calendar-prev-decade-btn:after,
  .auth-style .ant-calendar-header .ant-calendar-prev-year-btn:after {
    content: "\AB"; }
  .auth-style .ant-calendar-header .ant-calendar-next-century-btn,
  .auth-style .ant-calendar-header .ant-calendar-next-decade-btn,
  .auth-style .ant-calendar-header .ant-calendar-next-year-btn {
    right: 7px; }
  .auth-style .ant-calendar-header .ant-calendar-next-century-btn:after,
  .auth-style .ant-calendar-header .ant-calendar-next-decade-btn:after,
  .auth-style .ant-calendar-header .ant-calendar-next-year-btn:after {
    content: "\BB"; }
  .auth-style .ant-calendar-header .ant-calendar-prev-month-btn {
    left: 29px; }
  .auth-style .ant-calendar-header .ant-calendar-prev-month-btn:after {
    content: "\2039"; }
  .auth-style .ant-calendar-header .ant-calendar-next-month-btn {
    right: 29px; }
  .auth-style .ant-calendar-header .ant-calendar-next-month-btn:after {
    content: "\203A"; }
  .auth-style .ant-calendar-body {
    padding: 8px 12px; }
  .auth-style .ant-calendar table {
    border-collapse: collapse;
    max-width: 100%;
    background-color: transparent;
    width: 100%; }
  .auth-style .ant-calendar table,
  .auth-style .ant-calendar td,
  .auth-style .ant-calendar th {
    border: 0;
    text-align: center; }
  .auth-style .ant-calendar-calendar-table {
    border-spacing: 0;
    margin-bottom: 0; }
  .auth-style .ant-calendar-column-header {
    line-height: 18px;
    width: 33px;
    padding: 6px 0;
    text-align: center; }
  .auth-style .ant-calendar-column-header .ant-calendar-column-header-inner {
    display: block;
    font-weight: 400; }
  .auth-style .ant-calendar-week-number-header .ant-calendar-column-header-inner {
    display: none; }
  .auth-style .ant-calendar-cell {
    padding: 3px 0;
    height: 30px; }
  .auth-style .ant-calendar-date {
    display: block;
    margin: 0 auto;
    color: rgba(0, 0, 0, 0.65);
    border-radius: 2px;
    width: 24px;
    height: 24px;
    line-height: 22px;
    border: 1px solid transparent;
    padding: 0;
    background: transparent;
    text-align: center;
    transition: background 0.3s ease; }
  .auth-style .ant-calendar-date-panel {
    position: relative;
    outline: none; }
  .auth-style .ant-calendar-date:hover {
    background: #e6f7ff;
    cursor: pointer; }
  .auth-style .ant-calendar-date:active {
    color: #fff;
    background: #40a9ff; }
  .auth-style .ant-calendar-today .ant-calendar-date {
    border-color: #1890ff;
    font-weight: 700;
    color: #1890ff; }
  .auth-style .ant-calendar-last-month-cell .ant-calendar-date,
  .auth-style .ant-calendar-next-month-btn-day .ant-calendar-date {
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-calendar-selected-day .ant-calendar-date {
    background: #d1e9ff; }
  .auth-style .ant-calendar-selected-date .ant-calendar-date,
  .auth-style .ant-calendar-selected-end-date .ant-calendar-date,
  .auth-style .ant-calendar-selected-start-date .ant-calendar-date {
    background: #1890ff;
    color: #fff;
    border: 1px solid transparent; }
  .auth-style .ant-calendar-selected-date .ant-calendar-date:hover,
  .auth-style .ant-calendar-selected-end-date .ant-calendar-date:hover,
  .auth-style .ant-calendar-selected-start-date .ant-calendar-date:hover {
    background: #1890ff; }
  .auth-style .ant-calendar-disabled-cell .ant-calendar-date {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-radius: 0;
    width: auto;
    border: 1px solid transparent; }
  .auth-style .ant-calendar-disabled-cell .ant-calendar-date:hover {
    background: #f5f5f5; }
  .auth-style .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date {
    position: relative;
    padding-right: 5px;
    padding-left: 5px; }
  .auth-style .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date:before {
    content: " ";
    position: absolute;
    top: -1px;
    left: 5px;
    width: 24px;
    height: 24px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 2px; }
  .auth-style .ant-calendar-disabled-cell-first-of-row .ant-calendar-date {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .auth-style .ant-calendar-disabled-cell-last-of-row .ant-calendar-date {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
  .auth-style .ant-calendar-footer {
    border-top: 1px solid #e8e8e8;
    line-height: 38px;
    padding: 0 12px; }
  .auth-style .ant-calendar-footer:empty {
    border-top: 0; }
  .auth-style .ant-calendar-footer-btn {
    text-align: center;
    display: block; }
  .auth-style .ant-calendar-footer-extra {
    text-align: left; }
  .auth-style .ant-calendar .ant-calendar-clear-btn,
  .auth-style .ant-calendar .ant-calendar-today-btn {
    display: inline-block;
    text-align: center;
    margin: 0 0 0 8px; }
  .auth-style .ant-calendar .ant-calendar-clear-btn-disabled,
  .auth-style .ant-calendar .ant-calendar-today-btn-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed; }
  .auth-style .ant-calendar .ant-calendar-clear-btn:only-child,
  .auth-style .ant-calendar .ant-calendar-today-btn:only-child {
    margin: 0; }
  .auth-style .ant-calendar .ant-calendar-clear-btn {
    display: none;
    position: absolute;
    right: 5px;
    text-indent: -76px;
    overflow: hidden;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    top: 7px;
    margin: 0; }
  .auth-style .ant-calendar .ant-calendar-clear-btn:after {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.25);
    display: inline-block;
    line-height: 1;
    width: 20px;
    text-indent: 43px;
    transition: color 0.3s ease; }
  .auth-style .ant-calendar .ant-calendar-clear-btn:hover:after {
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-calendar .ant-calendar-ok-btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    height: 32px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    position: relative;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    color: #fff;
    background-color: #1890ff;
    border: 1px solid #1890ff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    padding: 0 7px;
    font-size: 14px;
    border-radius: 4px;
    height: 24px;
    line-height: 22px; }
  .auth-style .ant-calendar .ant-calendar-ok-btn > .anticon {
    line-height: 1; }
  .auth-style .ant-calendar .ant-calendar-ok-btn,
  .auth-style .ant-calendar .ant-calendar-ok-btn:active,
  .auth-style .ant-calendar .ant-calendar-ok-btn:focus {
    outline: 0; }
  .auth-style .ant-calendar .ant-calendar-ok-btn:not([disabled]):hover {
    text-decoration: none; }
  .auth-style .ant-calendar .ant-calendar-ok-btn:not([disabled]):active {
    outline: 0;
    box-shadow: none; }
  .auth-style .ant-calendar .ant-calendar-ok-btn.disabled,
  .auth-style .ant-calendar .ant-calendar-ok-btn[disabled] {
    cursor: not-allowed; }
  .auth-style .ant-calendar .ant-calendar-ok-btn.disabled > *,
  .auth-style .ant-calendar .ant-calendar-ok-btn[disabled] > * {
    pointer-events: none; }
  .auth-style .ant-calendar .ant-calendar-ok-btn-lg {
    padding: 0 15px;
    font-size: 16px;
    border-radius: 4px;
    height: 40px; }
  .auth-style .ant-calendar .ant-calendar-ok-btn-sm {
    padding: 0 7px;
    font-size: 14px;
    border-radius: 4px;
    height: 24px; }
  .auth-style .ant-calendar .ant-calendar-ok-btn > a:only-child {
    color: currentColor; }
  .auth-style .ant-calendar .ant-calendar-ok-btn > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-calendar .ant-calendar-ok-btn:focus,
  .auth-style .ant-calendar .ant-calendar-ok-btn:hover {
    color: #fff;
    background-color: #40a9ff;
    border-color: #40a9ff; }
  .auth-style .ant-calendar .ant-calendar-ok-btn:focus > a:only-child,
  .auth-style .ant-calendar .ant-calendar-ok-btn:hover > a:only-child {
    color: currentColor; }
  .auth-style .ant-calendar .ant-calendar-ok-btn:focus > a:only-child:after,
  .auth-style .ant-calendar .ant-calendar-ok-btn:hover > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-calendar .ant-calendar-ok-btn.active,
  .auth-style .ant-calendar .ant-calendar-ok-btn:active {
    color: #fff;
    background-color: #096dd9;
    border-color: #096dd9; }
  .auth-style .ant-calendar .ant-calendar-ok-btn.active > a:only-child,
  .auth-style .ant-calendar .ant-calendar-ok-btn:active > a:only-child {
    color: currentColor; }
  .auth-style .ant-calendar .ant-calendar-ok-btn.active > a:only-child:after,
  .auth-style .ant-calendar .ant-calendar-ok-btn:active > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-calendar .ant-calendar-ok-btn-disabled,
  .auth-style .ant-calendar .ant-calendar-ok-btn-disabled.active,
  .auth-style .ant-calendar .ant-calendar-ok-btn-disabled:active,
  .auth-style .ant-calendar .ant-calendar-ok-btn-disabled:focus,
  .auth-style .ant-calendar .ant-calendar-ok-btn-disabled:hover,
  .auth-style .ant-calendar .ant-calendar-ok-btn.disabled,
  .auth-style .ant-calendar .ant-calendar-ok-btn.disabled.active,
  .auth-style .ant-calendar .ant-calendar-ok-btn.disabled:active,
  .auth-style .ant-calendar .ant-calendar-ok-btn.disabled:focus,
  .auth-style .ant-calendar .ant-calendar-ok-btn.disabled:hover,
  .auth-style .ant-calendar .ant-calendar-ok-btn[disabled],
  .auth-style .ant-calendar .ant-calendar-ok-btn[disabled].active,
  .auth-style .ant-calendar .ant-calendar-ok-btn[disabled]:active,
  .auth-style .ant-calendar .ant-calendar-ok-btn[disabled]:focus,
  .auth-style .ant-calendar .ant-calendar-ok-btn[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none; }
  .auth-style .ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child,
  .auth-style .ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child,
  .auth-style .ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child,
  .auth-style .ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child,
  .auth-style .ant-calendar .ant-calendar-ok-btn-disabled > a:only-child,
  .auth-style .ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child,
  .auth-style .ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child,
  .auth-style .ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child,
  .auth-style .ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child,
  .auth-style .ant-calendar .ant-calendar-ok-btn.disabled > a:only-child,
  .auth-style .ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child,
  .auth-style .ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child,
  .auth-style .ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child,
  .auth-style .ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child,
  .auth-style .ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child {
    color: currentColor; }
  .auth-style .ant-calendar .ant-calendar-ok-btn-disabled.active > a:only-child:after,
  .auth-style .ant-calendar .ant-calendar-ok-btn-disabled:active > a:only-child:after,
  .auth-style .ant-calendar .ant-calendar-ok-btn-disabled:focus > a:only-child:after,
  .auth-style .ant-calendar .ant-calendar-ok-btn-disabled:hover > a:only-child:after,
  .auth-style .ant-calendar .ant-calendar-ok-btn-disabled > a:only-child:after,
  .auth-style .ant-calendar .ant-calendar-ok-btn.disabled.active > a:only-child:after,
  .auth-style .ant-calendar .ant-calendar-ok-btn.disabled:active > a:only-child:after,
  .auth-style .ant-calendar .ant-calendar-ok-btn.disabled:focus > a:only-child:after,
  .auth-style .ant-calendar .ant-calendar-ok-btn.disabled:hover > a:only-child:after,
  .auth-style .ant-calendar .ant-calendar-ok-btn.disabled > a:only-child:after,
  .auth-style .ant-calendar .ant-calendar-ok-btn[disabled].active > a:only-child:after,
  .auth-style .ant-calendar .ant-calendar-ok-btn[disabled]:active > a:only-child:after,
  .auth-style .ant-calendar .ant-calendar-ok-btn[disabled]:focus > a:only-child:after,
  .auth-style .ant-calendar .ant-calendar-ok-btn[disabled]:hover > a:only-child:after,
  .auth-style .ant-calendar .ant-calendar-ok-btn[disabled] > a:only-child:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: transparent; }
  .auth-style .ant-calendar-range-picker-input {
    background-color: transparent;
    border: 0;
    height: 99%;
    outline: 0;
    width: 44%;
    text-align: center; }
  .auth-style .ant-calendar-range-picker-input::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1; }
  .auth-style .ant-calendar-range-picker-input:-ms-input-placeholder {
    color: #bfbfbf; }
  .auth-style .ant-calendar-range-picker-input::-webkit-input-placeholder {
    color: #bfbfbf; }
  .auth-style .ant-calendar-range-picker-input[disabled] {
    cursor: not-allowed; }
  .auth-style .ant-calendar-range-picker-separator {
    color: rgba(0, 0, 0, 0.45);
    width: 10px;
    display: inline-block;
    height: 100%;
    vertical-align: top; }
  .auth-style .ant-calendar-range {
    width: 552px;
    overflow: hidden; }
  .auth-style .ant-calendar-range .ant-calendar-date-panel:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden; }
  .auth-style .ant-calendar-range-part {
    width: 50%;
    position: relative; }
  .auth-style .ant-calendar-range-left {
    float: left; }
  .auth-style .ant-calendar-range-left .ant-calendar-time-picker-inner {
    border-right: 1px solid #e8e8e8; }
  .auth-style .ant-calendar-range-right {
    float: right; }
  .auth-style .ant-calendar-range-right .ant-calendar-time-picker-inner {
    border-left: 1px solid #e8e8e8; }
  .auth-style .ant-calendar-range-middle {
    position: absolute;
    left: 50%;
    width: 20px;
    margin-left: -132px;
    text-align: center;
    height: 34px;
    line-height: 34px;
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-calendar-range-right .ant-calendar-date-input-wrap {
    margin-left: -118px; }
  .auth-style .ant-calendar-range.ant-calendar-time .ant-calendar-range-middle {
    margin-left: -12px; }
  .auth-style .ant-calendar-range.ant-calendar-time
.ant-calendar-range-right
.ant-calendar-date-input-wrap {
    margin-left: 0; }
  .auth-style .ant-calendar-range .ant-calendar-input-wrap {
    position: relative;
    height: 34px; }
  .auth-style .ant-calendar-range .ant-calendar-input,
  .auth-style .ant-calendar-range .ant-calendar-time-picker-input {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 32px;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    transition: all 0.3s;
    height: 24px;
    border: 0;
    box-shadow: none;
    padding: 4px 0; }
  .auth-style .ant-calendar-range .ant-calendar-input::-moz-placeholder,
  .auth-style .ant-calendar-range .ant-calendar-time-picker-input::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1; }
  .auth-style .ant-calendar-range .ant-calendar-input:-ms-input-placeholder,
  .auth-style .ant-calendar-range .ant-calendar-time-picker-input:-ms-input-placeholder {
    color: #bfbfbf; }
  .auth-style .ant-calendar-range .ant-calendar-input::-webkit-input-placeholder,
  .auth-style .ant-calendar-range
.ant-calendar-time-picker-input::-webkit-input-placeholder {
    color: #bfbfbf; }
  .auth-style .ant-calendar-range .ant-calendar-input:hover,
  .auth-style .ant-calendar-range .ant-calendar-time-picker-input:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important; }
  .auth-style .ant-calendar-range .ant-calendar-input:focus,
  .auth-style .ant-calendar-range .ant-calendar-time-picker-input:focus {
    border-color: #40a9ff;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    border-right-width: 1px !important; }
  .auth-style .ant-calendar-range .ant-calendar-input-disabled,
  .auth-style .ant-calendar-range .ant-calendar-time-picker-input-disabled {
    background-color: #f5f5f5;
    opacity: 1;
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-calendar-range .ant-calendar-input-disabled:hover,
  .auth-style .ant-calendar-range .ant-calendar-time-picker-input-disabled:hover {
    border-color: #e6d8d8;
    border-right-width: 1px !important; }
  .auth-style textarea.ant-calendar-range .ant-calendar-input,
  .auth-style textarea.ant-calendar-range .ant-calendar-time-picker-input {
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
    min-height: 32px; }
  .auth-style .ant-calendar-range .ant-calendar-input-lg,
  .auth-style .ant-calendar-range .ant-calendar-time-picker-input-lg {
    padding: 6px 11px;
    height: 40px;
    font-size: 16px; }
  .auth-style .ant-calendar-range .ant-calendar-input-sm,
  .auth-style .ant-calendar-range .ant-calendar-time-picker-input-sm {
    padding: 1px 7px;
    height: 24px; }
  .auth-style .ant-calendar-range .ant-calendar-input:focus,
  .auth-style .ant-calendar-range .ant-calendar-time-picker-input:focus {
    box-shadow: none; }
  .auth-style .ant-calendar-range .ant-calendar-time-picker-icon {
    display: none; }
  .auth-style .ant-calendar-range.ant-calendar-week-number {
    width: 574px; }
  .auth-style .ant-calendar-range.ant-calendar-week-number .ant-calendar-range-part {
    width: 286px; }
  .auth-style .ant-calendar-range .ant-calendar-decade-panel,
  .auth-style .ant-calendar-range .ant-calendar-month-panel,
  .auth-style .ant-calendar-range .ant-calendar-year-panel {
    top: 34px; }
  .auth-style .ant-calendar-range .ant-calendar-month-panel .ant-calendar-year-panel {
    top: 0; }
  .auth-style .ant-calendar-range .ant-calendar-decade-panel-table,
  .auth-style .ant-calendar-range .ant-calendar-month-panel-table,
  .auth-style .ant-calendar-range .ant-calendar-year-panel-table {
    height: 208px; }
  .auth-style .ant-calendar-range .ant-calendar-in-range-cell {
    border-radius: 0;
    position: relative; }
  .auth-style .ant-calendar-range .ant-calendar-in-range-cell > div {
    position: relative;
    z-index: 1; }
  .auth-style .ant-calendar-range .ant-calendar-in-range-cell:before {
    content: "";
    display: block;
    background: #e6f7ff;
    border-radius: 0;
    border: 0;
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 0;
    right: 0; }
  .auth-style .ant-calendar-range .ant-calendar-footer-extra {
    float: left; }
  .auth-style div.ant-calendar-range-quick-selector {
    text-align: left; }
  .auth-style div.ant-calendar-range-quick-selector > a {
    margin-right: 8px; }
  .auth-style .ant-calendar-range .ant-calendar-header,
  .auth-style .ant-calendar-range .ant-calendar-month-panel-header,
  .auth-style .ant-calendar-range .ant-calendar-year-panel-header {
    border-bottom: 0; }
  .auth-style .ant-calendar-range .ant-calendar-body,
  .auth-style .ant-calendar-range .ant-calendar-month-panel-body,
  .auth-style .ant-calendar-range .ant-calendar-year-panel-body {
    border-top: 1px solid #e8e8e8; }
  .auth-style .ant-calendar-range.ant-calendar-time .ant-calendar-time-picker {
    height: 207px;
    width: 100%;
    top: 68px;
    z-index: 2; }
  .auth-style .ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-panel {
    height: 267px;
    margin-top: -34px; }
  .auth-style .ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-inner {
    padding-top: 40px;
    height: 100%;
    background: none; }
  .auth-style .ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-combobox {
    display: inline-block;
    height: 100%;
    background-color: #fff;
    border-top: 1px solid #e8e8e8; }
  .auth-style .ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select {
    height: 100%; }
  .auth-style .ant-calendar-range.ant-calendar-time .ant-calendar-time-picker-select ul {
    max-height: 100%; }
  .auth-style .ant-calendar-range.ant-calendar-time
.ant-calendar-footer
.ant-calendar-time-picker-btn {
    margin-right: 8px; }
  .auth-style .ant-calendar-range.ant-calendar-time .ant-calendar-today-btn {
    margin: 8px 12px;
    height: 22px;
    line-height: 22px; }
  .auth-style .ant-calendar-range-with-ranges.ant-calendar-time .ant-calendar-time-picker {
    height: 233px; }
  .auth-style .ant-calendar-range.ant-calendar-show-time-picker .ant-calendar-body {
    border-top-color: transparent; }
  .auth-style .ant-calendar-time-picker {
    position: absolute;
    width: 100%;
    top: 40px;
    background-color: #fff; }
  .auth-style .ant-calendar-time-picker-panel {
    z-index: 1050;
    position: absolute;
    width: 100%; }
  .auth-style .ant-calendar-time-picker-inner {
    display: inline-block;
    position: relative;
    outline: none;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    line-height: 1.5;
    overflow: hidden;
    width: 100%; }
  .auth-style .ant-calendar-time-picker-column-1,
  .auth-style .ant-calendar-time-picker-column-1 .ant-calendar-time-picker-select,
  .auth-style .ant-calendar-time-picker-combobox {
    width: 100%; }
  .auth-style .ant-calendar-time-picker-column-2 .ant-calendar-time-picker-select {
    width: 50%; }
  .auth-style .ant-calendar-time-picker-column-3 .ant-calendar-time-picker-select {
    width: 33.33%; }
  .auth-style .ant-calendar-time-picker-column-4 .ant-calendar-time-picker-select {
    width: 25%; }
  .auth-style .ant-calendar-time-picker-input-wrap {
    display: none; }
  .auth-style .ant-calendar-time-picker-select {
    float: left;
    font-size: 14px;
    border-right: 1px solid #e8e8e8;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    height: 226px; }
  .auth-style .ant-calendar-time-picker-select:hover {
    overflow-y: auto; }
  .auth-style .ant-calendar-time-picker-select:first-child {
    border-left: 0;
    margin-left: 0; }
  .auth-style .ant-calendar-time-picker-select:last-child {
    border-right: 0; }
  .auth-style .ant-calendar-time-picker-select ul {
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100%;
    max-height: 206px; }
  .auth-style .ant-calendar-time-picker-select li {
    padding-left: 32px;
    list-style: none;
    box-sizing: content-box;
    margin: 0;
    width: 100%;
    height: 24px;
    line-height: 24px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: background 0.3s ease; }
  .auth-style .ant-calendar-time-picker-select li:last-child:after {
    content: "";
    height: 202px;
    display: block; }
  .auth-style .ant-calendar-time-picker-select li:hover {
    background: #e6f7ff; }
  .auth-style li.ant-calendar-time-picker-select-option-selected {
    background: #f5f5f5;
    font-weight: 700; }
  .auth-style li.ant-calendar-time-picker-select-option-disabled {
    color: rgba(0, 0, 0, 0.25); }
  .auth-style li.ant-calendar-time-picker-select-option-disabled:hover {
    background: transparent;
    cursor: not-allowed; }
  .auth-style .ant-calendar-time .ant-calendar-day-select {
    padding: 0 2px;
    font-weight: 500;
    display: inline-block;
    color: rgba(0, 0, 0, 0.85);
    line-height: 34px; }
  .auth-style .ant-calendar-time .ant-calendar-footer {
    position: relative;
    height: auto; }
  .auth-style .ant-calendar-time .ant-calendar-footer-btn {
    text-align: right; }
  .auth-style .ant-calendar-time .ant-calendar-footer .ant-calendar-today-btn {
    float: left;
    margin: 0; }
  .auth-style .ant-calendar-time .ant-calendar-footer .ant-calendar-time-picker-btn {
    display: inline-block;
    margin-right: 8px; }
  .auth-style .ant-calendar-time
.ant-calendar-footer
.ant-calendar-time-picker-btn-disabled {
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-calendar-month-panel {
    position: absolute;
    top: 1px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    border-radius: 4px;
    background: #fff;
    outline: none; }
  .auth-style .ant-calendar-month-panel > div {
    height: 100%;
    display: flex;
    flex-direction: column; }
  .auth-style .ant-calendar-month-panel-hidden {
    display: none; }
  .auth-style .ant-calendar-month-panel-header {
    height: 40px;
    line-height: 40px;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom: 1px solid #e8e8e8; }
  .auth-style .ant-calendar-month-panel-header a:hover {
    color: #40a9ff; }
  .auth-style .ant-calendar-month-panel-header .ant-calendar-month-panel-century-select,
  .auth-style .ant-calendar-month-panel-header .ant-calendar-month-panel-decade-select,
  .auth-style .ant-calendar-month-panel-header .ant-calendar-month-panel-month-select,
  .auth-style .ant-calendar-month-panel-header .ant-calendar-month-panel-year-select {
    padding: 0 2px;
    font-weight: 500;
    display: inline-block;
    color: rgba(0, 0, 0, 0.85);
    line-height: 40px; }
  .auth-style .ant-calendar-month-panel-header
.ant-calendar-month-panel-century-select-arrow,
  .auth-style .ant-calendar-month-panel-header
.ant-calendar-month-panel-decade-select-arrow,
  .auth-style .ant-calendar-month-panel-header .ant-calendar-month-panel-month-select-arrow,
  .auth-style .ant-calendar-month-panel-header .ant-calendar-month-panel-year-select-arrow {
    display: none; }
  .auth-style .ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
  .auth-style .ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
  .auth-style .ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn,
  .auth-style .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn,
  .auth-style .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
  .auth-style .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
  .auth-style .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn,
  .auth-style .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
    position: absolute;
    top: 0;
    color: rgba(0, 0, 0, 0.45);
    font-family: Arial, Hiragino Sans GB, Microsoft Yahei, "Microsoft Sans Serif", sans-serif;
    padding: 0 5px;
    font-size: 16px;
    display: inline-block;
    line-height: 40px; }
  .auth-style .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-century-btn,
  .auth-style .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-decade-btn,
  .auth-style .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-year-btn {
    left: 7px; }
  .auth-style .ant-calendar-month-panel-header
.ant-calendar-month-panel-prev-century-btn:after,
  .auth-style .ant-calendar-month-panel-header
.ant-calendar-month-panel-prev-decade-btn:after,
  .auth-style .ant-calendar-month-panel-header
.ant-calendar-month-panel-prev-year-btn:after {
    content: "\AB"; }
  .auth-style .ant-calendar-month-panel-header .ant-calendar-month-panel-next-century-btn,
  .auth-style .ant-calendar-month-panel-header .ant-calendar-month-panel-next-decade-btn,
  .auth-style .ant-calendar-month-panel-header .ant-calendar-month-panel-next-year-btn {
    right: 7px; }
  .auth-style .ant-calendar-month-panel-header
.ant-calendar-month-panel-next-century-btn:after,
  .auth-style .ant-calendar-month-panel-header
.ant-calendar-month-panel-next-decade-btn:after,
  .auth-style .ant-calendar-month-panel-header
.ant-calendar-month-panel-next-year-btn:after {
    content: "\BB"; }
  .auth-style .ant-calendar-month-panel-header .ant-calendar-month-panel-prev-month-btn {
    left: 29px; }
  .auth-style .ant-calendar-month-panel-header
.ant-calendar-month-panel-prev-month-btn:after {
    content: "\2039"; }
  .auth-style .ant-calendar-month-panel-header .ant-calendar-month-panel-next-month-btn {
    right: 29px; }
  .auth-style .ant-calendar-month-panel-header
.ant-calendar-month-panel-next-month-btn:after {
    content: "\203A"; }
  .auth-style .ant-calendar-month-panel-body {
    flex: 1 1; }
  .auth-style .ant-calendar-month-panel-footer {
    border-top: 1px solid #e8e8e8; }
  .auth-style .ant-calendar-month-panel-footer .ant-calendar-footer-extra {
    padding: 0 12px; }
  .auth-style .ant-calendar-month-panel-table {
    table-layout: fixed;
    width: 100%;
    height: 100%;
    border-collapse: separate; }
  .auth-style .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month,
  .auth-style .ant-calendar-month-panel-selected-cell
.ant-calendar-month-panel-month:hover {
    background: #1890ff;
    color: #fff; }
  .auth-style .ant-calendar-month-panel-cell {
    text-align: center; }
  .auth-style .ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month,
  .auth-style .ant-calendar-month-panel-cell-disabled
.ant-calendar-month-panel-month:hover {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5; }
  .auth-style .ant-calendar-month-panel-month {
    display: inline-block;
    margin: 0 auto;
    color: rgba(0, 0, 0, 0.65);
    background: transparent;
    text-align: center;
    height: 24px;
    line-height: 24px;
    padding: 0 8px;
    border-radius: 2px;
    transition: background 0.3s ease; }
  .auth-style .ant-calendar-month-panel-month:hover {
    background: #e6f7ff;
    cursor: pointer; }
  .auth-style .ant-calendar-year-panel {
    position: absolute;
    top: 1px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    border-radius: 4px;
    background: #fff;
    outline: none; }
  .auth-style .ant-calendar-year-panel > div {
    height: 100%;
    display: flex;
    flex-direction: column; }
  .auth-style .ant-calendar-year-panel-hidden {
    display: none; }
  .auth-style .ant-calendar-year-panel-header {
    height: 40px;
    line-height: 40px;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom: 1px solid #e8e8e8; }
  .auth-style .ant-calendar-year-panel-header a:hover {
    color: #40a9ff; }
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-century-select,
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select,
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-month-select,
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-year-select {
    padding: 0 2px;
    font-weight: 500;
    display: inline-block;
    color: rgba(0, 0, 0, 0.85);
    line-height: 40px; }
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-century-select-arrow,
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-decade-select-arrow,
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-month-select-arrow,
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-year-select-arrow {
    display: none; }
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn,
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn,
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn,
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn {
    position: absolute;
    top: 0;
    color: rgba(0, 0, 0, 0.45);
    font-family: Arial, Hiragino Sans GB, Microsoft Yahei, "Microsoft Sans Serif", sans-serif;
    padding: 0 5px;
    font-size: 16px;
    display: inline-block;
    line-height: 40px; }
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-century-btn,
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-decade-btn,
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn {
    left: 7px; }
  .auth-style .ant-calendar-year-panel-header
.ant-calendar-year-panel-prev-century-btn:after,
  .auth-style .ant-calendar-year-panel-header
.ant-calendar-year-panel-prev-decade-btn:after,
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-year-btn:after {
    content: "\AB"; }
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-next-century-btn,
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-next-decade-btn,
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn {
    right: 7px; }
  .auth-style .ant-calendar-year-panel-header
.ant-calendar-year-panel-next-century-btn:after,
  .auth-style .ant-calendar-year-panel-header
.ant-calendar-year-panel-next-decade-btn:after,
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-next-year-btn:after {
    content: "\BB"; }
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-prev-month-btn {
    left: 29px; }
  .auth-style .ant-calendar-year-panel-header
.ant-calendar-year-panel-prev-month-btn:after {
    content: "\2039"; }
  .auth-style .ant-calendar-year-panel-header .ant-calendar-year-panel-next-month-btn {
    right: 29px; }
  .auth-style .ant-calendar-year-panel-header
.ant-calendar-year-panel-next-month-btn:after {
    content: "\203A"; }
  .auth-style .ant-calendar-year-panel-body {
    flex: 1 1; }
  .auth-style .ant-calendar-year-panel-footer {
    border-top: 1px solid #e8e8e8; }
  .auth-style .ant-calendar-year-panel-footer .ant-calendar-footer-extra {
    padding: 0 12px; }
  .auth-style .ant-calendar-year-panel-table {
    table-layout: fixed;
    width: 100%;
    height: 100%;
    border-collapse: separate; }
  .auth-style .ant-calendar-year-panel-cell {
    text-align: center; }
  .auth-style .ant-calendar-year-panel-year {
    display: inline-block;
    margin: 0 auto;
    color: rgba(0, 0, 0, 0.65);
    background: transparent;
    text-align: center;
    height: 24px;
    line-height: 24px;
    padding: 0 8px;
    border-radius: 2px;
    transition: background 0.3s ease; }
  .auth-style .ant-calendar-year-panel-year:hover {
    background: #e6f7ff;
    cursor: pointer; }
  .auth-style .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
  .auth-style .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year:hover {
    background: #1890ff;
    color: #fff; }
  .auth-style .ant-calendar-year-panel-last-decade-cell .ant-calendar-year-panel-year,
  .auth-style .ant-calendar-year-panel-next-decade-cell .ant-calendar-year-panel-year {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-calendar-decade-panel {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background: #fff;
    border-radius: 4px;
    outline: none; }
  .auth-style .ant-calendar-decade-panel-hidden {
    display: none; }
  .auth-style .ant-calendar-decade-panel-header {
    height: 40px;
    line-height: 40px;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom: 1px solid #e8e8e8; }
  .auth-style .ant-calendar-decade-panel-header a:hover {
    color: #40a9ff; }
  .auth-style .ant-calendar-decade-panel-header .ant-calendar-decade-panel-century-select,
  .auth-style .ant-calendar-decade-panel-header .ant-calendar-decade-panel-decade-select,
  .auth-style .ant-calendar-decade-panel-header .ant-calendar-decade-panel-month-select,
  .auth-style .ant-calendar-decade-panel-header .ant-calendar-decade-panel-year-select {
    padding: 0 2px;
    font-weight: 500;
    display: inline-block;
    color: rgba(0, 0, 0, 0.85);
    line-height: 40px; }
  .auth-style .ant-calendar-decade-panel-header
.ant-calendar-decade-panel-century-select-arrow,
  .auth-style .ant-calendar-decade-panel-header
.ant-calendar-decade-panel-decade-select-arrow,
  .auth-style .ant-calendar-decade-panel-header
.ant-calendar-decade-panel-month-select-arrow,
  .auth-style .ant-calendar-decade-panel-header
.ant-calendar-decade-panel-year-select-arrow {
    display: none; }
  .auth-style .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
  .auth-style .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
  .auth-style .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn,
  .auth-style .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn,
  .auth-style .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
  .auth-style .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
  .auth-style .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn,
  .auth-style .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn {
    position: absolute;
    top: 0;
    color: rgba(0, 0, 0, 0.45);
    font-family: Arial, Hiragino Sans GB, Microsoft Yahei, "Microsoft Sans Serif", sans-serif;
    padding: 0 5px;
    font-size: 16px;
    display: inline-block;
    line-height: 40px; }
  .auth-style .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-century-btn,
  .auth-style .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-decade-btn,
  .auth-style .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-year-btn {
    left: 7px; }
  .auth-style .ant-calendar-decade-panel-header
.ant-calendar-decade-panel-prev-century-btn:after,
  .auth-style .ant-calendar-decade-panel-header
.ant-calendar-decade-panel-prev-decade-btn:after,
  .auth-style .ant-calendar-decade-panel-header
.ant-calendar-decade-panel-prev-year-btn:after {
    content: "\AB"; }
  .auth-style .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-century-btn,
  .auth-style .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-decade-btn,
  .auth-style .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-year-btn {
    right: 7px; }
  .auth-style .ant-calendar-decade-panel-header
.ant-calendar-decade-panel-next-century-btn:after,
  .auth-style .ant-calendar-decade-panel-header
.ant-calendar-decade-panel-next-decade-btn:after,
  .auth-style .ant-calendar-decade-panel-header
.ant-calendar-decade-panel-next-year-btn:after {
    content: "\BB"; }
  .auth-style .ant-calendar-decade-panel-header .ant-calendar-decade-panel-prev-month-btn {
    left: 29px; }
  .auth-style .ant-calendar-decade-panel-header
.ant-calendar-decade-panel-prev-month-btn:after {
    content: "\2039"; }
  .auth-style .ant-calendar-decade-panel-header .ant-calendar-decade-panel-next-month-btn {
    right: 29px; }
  .auth-style .ant-calendar-decade-panel-header
.ant-calendar-decade-panel-next-month-btn:after {
    content: "\203A"; }
  .auth-style .ant-calendar-decade-panel-body {
    flex: 1 1; }
  .auth-style .ant-calendar-decade-panel-footer {
    border-top: 1px solid #e8e8e8; }
  .auth-style .ant-calendar-decade-panel-footer .ant-calendar-footer-extra {
    padding: 0 12px; }
  .auth-style .ant-calendar-decade-panel-table {
    table-layout: fixed;
    width: 100%;
    height: 100%;
    border-collapse: separate; }
  .auth-style .ant-calendar-decade-panel-cell {
    text-align: center;
    white-space: nowrap; }
  .auth-style .ant-calendar-decade-panel-decade {
    display: inline-block;
    margin: 0 auto;
    color: rgba(0, 0, 0, 0.65);
    background: transparent;
    text-align: center;
    height: 24px;
    line-height: 24px;
    padding: 0 6px;
    border-radius: 2px;
    transition: background 0.3s ease; }
  .auth-style .ant-calendar-decade-panel-decade:hover {
    background: #e6f7ff;
    cursor: pointer; }
  .auth-style .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade,
  .auth-style .ant-calendar-decade-panel-selected-cell
.ant-calendar-decade-panel-decade:hover {
    background: #1890ff;
    color: #fff; }
  .auth-style .ant-calendar-decade-panel-last-century-cell
.ant-calendar-decade-panel-decade,
  .auth-style .ant-calendar-decade-panel-next-century-cell
.ant-calendar-decade-panel-decade {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-calendar-month .ant-calendar-month-header-wrap {
    position: relative;
    height: 288px; }
  .auth-style .ant-calendar-month .ant-calendar-month-panel,
  .auth-style .ant-calendar-month .ant-calendar-year-panel {
    top: 0;
    height: 100%; }
  .auth-style .ant-calendar-week-number-cell {
    opacity: 0.5; }
  .auth-style .ant-calendar-week-number .ant-calendar-body tr {
    transition: all 0.3s;
    cursor: pointer; }
  .auth-style .ant-calendar-week-number .ant-calendar-body tr:hover {
    background: #e6f7ff; }
  .auth-style .ant-calendar-week-number .ant-calendar-body tr.ant-calendar-active-week {
    background: #bae7ff;
    font-weight: 700; }
  .auth-style .ant-calendar-week-number
.ant-calendar-body
tr
.ant-calendar-selected-day
.ant-calendar-date,
  .auth-style .ant-calendar-week-number
.ant-calendar-body
tr
.ant-calendar-selected-day:hover
.ant-calendar-date {
    background: transparent;
    color: rgba(0, 0, 0, 0.65); }
  .auth-style .ant-divider {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    background: #e8e8e8; }
  .auth-style .ant-divider,
  .auth-style .ant-divider-vertical {
    margin: 0 8px;
    display: inline-block;
    height: 0.9em;
    width: 1px;
    vertical-align: middle;
    position: relative;
    top: -0.06em; }
  .auth-style .ant-divider-horizontal {
    display: block;
    height: 1px;
    width: 100%;
    min-width: 100%;
    margin: 24px 0;
    clear: both; }
  .auth-style .ant-divider-horizontal.ant-divider-with-text,
  .auth-style .ant-divider-horizontal.ant-divider-with-text-left,
  .auth-style .ant-divider-horizontal.ant-divider-with-text-right {
    display: table;
    white-space: nowrap;
    text-align: center;
    background: transparent;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    margin: 16px 0; }
  .auth-style .ant-divider-horizontal.ant-divider-with-text-left:after,
  .auth-style .ant-divider-horizontal.ant-divider-with-text-left:before,
  .auth-style .ant-divider-horizontal.ant-divider-with-text-right:after,
  .auth-style .ant-divider-horizontal.ant-divider-with-text-right:before,
  .auth-style .ant-divider-horizontal.ant-divider-with-text:after,
  .auth-style .ant-divider-horizontal.ant-divider-with-text:before {
    content: "";
    display: table-cell;
    position: relative;
    top: 50%;
    width: 50%;
    border-top: 1px solid #e8e8e8;
    -webkit-transform: translateY(50%);
    transform: translateY(50%); }
  .auth-style .ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text,
  .auth-style .ant-divider-horizontal.ant-divider-with-text-right .ant-divider-inner-text {
    display: inline-block;
    padding: 0 10px; }
  .auth-style .ant-divider-horizontal.ant-divider-with-text-left:before {
    top: 50%;
    width: 5%; }
  .auth-style .ant-divider-horizontal.ant-divider-with-text-left:after,
  .auth-style .ant-divider-horizontal.ant-divider-with-text-right:before {
    top: 50%;
    width: 95%; }
  .auth-style .ant-divider-horizontal.ant-divider-with-text-right:after {
    top: 50%;
    width: 5%; }
  .auth-style .ant-divider-inner-text {
    display: inline-block;
    padding: 0 24px; }
  .auth-style .ant-divider-dashed {
    background: none;
    border-top: 1px dashed #e8e8e8; }
  .auth-style .ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed,
  .auth-style .ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed,
  .auth-style .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed {
    border-top: 0; }
  .auth-style .ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed:after,
  .auth-style .ant-divider-horizontal.ant-divider-with-text-left.ant-divider-dashed:before,
  .auth-style .ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed:after,
  .auth-style .ant-divider-horizontal.ant-divider-with-text-right.ant-divider-dashed:before,
  .auth-style .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed:after,
  .auth-style .ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed:before {
    border-style: dashed none none; }
  .auth-style .ant-drawer {
    position: fixed;
    top: 0;
    width: 0;
    height: 100%;
    z-index: 1000; }
  .auth-style .ant-drawer > * {
    transition: -webkit-transform 0.3s cubic-bezier(0.9, 0, 0.3, 0.7);
    transition: transform 0.3s cubic-bezier(0.9, 0, 0.3, 0.7);
    transition: transform 0.3s cubic-bezier(0.9, 0, 0.3, 0.7), -webkit-transform 0.3s cubic-bezier(0.9, 0, 0.3, 0.7); }
  .auth-style .ant-drawer-content-wrapper {
    position: fixed; }
  .auth-style .ant-drawer .ant-drawer-content {
    width: 100%;
    height: 100%; }
  .auth-style .ant-drawer-left,
  .auth-style .ant-drawer-right {
    width: 0;
    height: 100%; }
  .auth-style .ant-drawer-left .ant-drawer-content-wrapper,
  .auth-style .ant-drawer-right .ant-drawer-content-wrapper {
    height: 100%; }
  .auth-style .ant-drawer-left.ant-drawer-open,
  .auth-style .ant-drawer-right.ant-drawer-open {
    width: 100%; }
  .auth-style .ant-drawer-left.ant-drawer-open.no-mask,
  .auth-style .ant-drawer-right.ant-drawer-open.no-mask {
    width: 0; }
  .auth-style .ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15); }
  .auth-style .ant-drawer-right .ant-drawer-content-wrapper {
    right: 0; }
  .auth-style .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15); }
  .auth-style .ant-drawer-bottom,
  .auth-style .ant-drawer-top {
    width: 100%;
    height: 0%; }
  .auth-style .ant-drawer-bottom .ant-drawer-content-wrapper,
  .auth-style .ant-drawer-top .ant-drawer-content-wrapper {
    width: 100%; }
  .auth-style .ant-drawer-bottom.ant-drawer-open,
  .auth-style .ant-drawer-top.ant-drawer-open {
    height: 100%; }
  .auth-style .ant-drawer-bottom.ant-drawer-open.no-mask,
  .auth-style .ant-drawer-top.ant-drawer-open.no-mask {
    height: 0%; }
  .auth-style .ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); }
  .auth-style .ant-drawer-bottom .ant-drawer-content-wrapper {
    bottom: 0; }
  .auth-style .ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15); }
  .auth-style .ant-drawer.ant-drawer-open .ant-drawer-mask {
    opacity: 0.3;
    height: 100%;
    -webkit-animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    transition: none; }
  .auth-style .ant-drawer-title {
    margin: 0;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85); }
  .auth-style .ant-drawer-content {
    position: relative;
    background-color: #fff;
    border: 0;
    background-clip: padding-box;
    z-index: 1; }
  .auth-style .ant-drawer-close {
    cursor: pointer;
    border: 0;
    background: transparent;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    font-weight: 700;
    text-decoration: none;
    transition: color 0.3s;
    color: rgba(0, 0, 0, 0.45);
    outline: 0;
    padding: 0;
    display: block;
    font-style: normal;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
    width: 56px;
    height: 56px;
    line-height: 56px;
    font-size: 16px; }
  .auth-style .ant-drawer-close:focus,
  .auth-style .ant-drawer-close:hover {
    color: rgba(0, 0, 0, 0.75);
    text-decoration: none; }
  .auth-style .ant-drawer-header {
    padding: 16px 24px;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid #e8e8e8;
    position: relative; }
  .auth-style .ant-drawer-header,
  .auth-style .ant-drawer-header-no-title {
    background: #fff;
    color: rgba(0, 0, 0, 0.65); }
  .auth-style .ant-drawer-body {
    padding: 24px;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word; }
  .auth-style .ant-drawer-mask {
    position: fixed;
    width: 100%;
    height: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.65);
    filter: alpha(opacity=50);
    transition: opacity 0.3s linear, height 0s ease 0.3s; }
  .auth-style .ant-drawer-open {
    transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1); }
  .auth-style .ant-drawer-open-content {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); }

@-webkit-keyframes antdDrawerFadeIn {
  0% {
    opacity: 0; }
  to {
    opacity: 0.3; } }

@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0; }
  to {
    opacity: 0.3; } }
  .auth-style .ant-dropdown {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    left: -9999px;
    top: -9999px;
    z-index: 1050;
    display: block; }
  .auth-style .ant-dropdown:before {
    position: absolute;
    top: -7px;
    left: -7px;
    right: 0;
    bottom: -7px;
    content: " ";
    opacity: 0.0001; }
  .auth-style .ant-dropdown-wrap {
    position: relative; }
  .auth-style .ant-dropdown-wrap .ant-btn > .anticon-down {
    display: inline-block;
    font-size: 12px;
    font-size: 10px\9;
    -webkit-transform: scale(0.83333) rotate(0deg);
    transform: scale(0.83333) rotate(0deg); }
  .auth-style :root .ant-dropdown-wrap .ant-btn > .anticon-down {
    font-size: 12px; }
  .auth-style .ant-dropdown-wrap .anticon-down:before {
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s; }
  .auth-style .ant-dropdown-wrap-open .anticon-down:before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  .auth-style .ant-dropdown-hidden,
  .auth-style .ant-dropdown-menu-hidden {
    display: none; }
  .auth-style .ant-dropdown-menu {
    outline: none;
    position: relative;
    list-style-type: none;
    padding: 4px 0;
    margin: 0;
    text-align: left;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    background-clip: padding-box;
    -webkit-transform: translateZ(0); }
  .auth-style .ant-dropdown-menu-item-group-title {
    color: rgba(0, 0, 0, 0.45);
    padding: 5px 12px;
    transition: all 0.3s; }
  .auth-style .ant-dropdown-menu-submenu-popup {
    position: absolute;
    z-index: 1050; }
  .auth-style .ant-dropdown-menu-submenu-popup > .ant-dropdown-menu {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0; }
  .auth-style .ant-dropdown-menu-item,
  .auth-style .ant-dropdown-menu-submenu-title {
    padding: 5px 12px;
    margin: 0;
    clear: both;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.3s;
    line-height: 22px; }
  .auth-style .ant-dropdown-menu-item > .anticon:first-child,
  .auth-style .ant-dropdown-menu-submenu-title > .anticon:first-child {
    min-width: 12px;
    margin-right: 8px; }
  .auth-style .ant-dropdown-menu-item > a,
  .auth-style .ant-dropdown-menu-submenu-title > a {
    color: rgba(0, 0, 0, 0.65);
    display: block;
    padding: 5px 12px;
    margin: -5px -12px;
    transition: all 0.3s; }
  .auth-style .ant-dropdown-menu-item > a:focus,
  .auth-style .ant-dropdown-menu-submenu-title > a:focus {
    text-decoration: none; }
  .auth-style .ant-dropdown-menu-item-selected,
  .auth-style .ant-dropdown-menu-item-selected > a,
  .auth-style .ant-dropdown-menu-submenu-title-selected,
  .auth-style .ant-dropdown-menu-submenu-title-selected > a {
    color: #1890ff;
    background-color: #e6f7ff; }
  .auth-style .ant-dropdown-menu-item:hover,
  .auth-style .ant-dropdown-menu-submenu-title:hover {
    background-color: #e6f7ff; }
  .auth-style .ant-dropdown-menu-item-disabled,
  .auth-style .ant-dropdown-menu-submenu-title-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed; }
  .auth-style .ant-dropdown-menu-item-disabled:hover,
  .auth-style .ant-dropdown-menu-submenu-title-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: not-allowed; }
  .auth-style .ant-dropdown-menu-item-divider,
  .auth-style .ant-dropdown-menu-submenu-title-divider {
    height: 1px;
    overflow: hidden;
    background-color: #e8e8e8;
    line-height: 0;
    margin: 4px 0; }
  .auth-style .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
  .auth-style .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
    position: absolute;
    right: 8px; }
  .auth-style .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
  .auth-style .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
    font-style: normal;
    color: rgba(0, 0, 0, 0.45);
    display: inline-block;
    font-size: 12px;
    font-size: 10px\9;
    -webkit-transform: scale(0.83333) rotate(0deg);
    transform: scale(0.83333) rotate(0deg); }
  .auth-style :root .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
  .auth-style :root .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
    font-size: 12px; }
  .auth-style .ant-dropdown-menu-submenu-title {
    padding-right: 26px; }
  .auth-style .ant-dropdown-menu-submenu-vertical {
    position: relative; }
  .auth-style .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
    top: 0;
    left: 100%;
    position: absolute;
    min-width: 100%;
    margin-left: 4px;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0; }
  .auth-style .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled
.ant-dropdown-menu-submenu-title,
  .auth-style .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled
.ant-dropdown-menu-submenu-title
.ant-dropdown-menu-submenu-arrow-icon {
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
  .auth-style .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
  .auth-style .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight,
  .auth-style .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
  .auth-style .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
  .auth-style .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn; }
  .auth-style .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
  .auth-style .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
  .auth-style .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight,
  .auth-style .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
  .auth-style .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
  .auth-style .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn; }
  .auth-style .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
  .auth-style .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
  .auth-style .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut; }
  .auth-style .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
  .auth-style .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
  .auth-style .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut; }
  .auth-style .ant-dropdown-link > .anticon.anticon-down,
  .auth-style .ant-dropdown-trigger > .anticon.anticon-down {
    display: inline-block;
    font-size: 12px;
    font-size: 10px\9;
    -webkit-transform: scale(0.83333) rotate(0deg);
    transform: scale(0.83333) rotate(0deg); }
  .auth-style :root .ant-dropdown-link > .anticon.anticon-down,
  .auth-style :root .ant-dropdown-trigger > .anticon.anticon-down {
    font-size: 12px; }
  .auth-style .ant-dropdown-button {
    white-space: nowrap; }
  .auth-style .ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child) {
    padding-left: 8px;
    padding-right: 8px; }
  .auth-style .ant-dropdown-button .anticon.anticon-down {
    display: inline-block;
    font-size: 12px;
    font-size: 10px\9;
    -webkit-transform: scale(0.83333) rotate(0deg);
    transform: scale(0.83333) rotate(0deg); }
  .auth-style :root .ant-dropdown-button .anticon.anticon-down {
    font-size: 12px; }
  .auth-style .ant-dropdown-menu-dark,
  .auth-style .ant-dropdown-menu-dark .ant-dropdown-menu {
    background: #001529; }
  .auth-style .ant-dropdown-menu-dark .ant-dropdown-menu-item,
  .auth-style .ant-dropdown-menu-dark
.ant-dropdown-menu-item
.ant-dropdown-menu-submenu-arrow:after,
  .auth-style .ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
  .auth-style .ant-dropdown-menu-dark
.ant-dropdown-menu-item
> a
.ant-dropdown-menu-submenu-arrow:after,
  .auth-style .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
  .auth-style .ant-dropdown-menu-dark
.ant-dropdown-menu-submenu-title
.ant-dropdown-menu-submenu-arrow:after {
    color: rgba(255, 255, 255, 0.65); }
  .auth-style .ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
  .auth-style .ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
  .auth-style .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover {
    color: #fff;
    background: transparent; }
  .auth-style .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
  .auth-style .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
  .auth-style .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
    background: #1890ff;
    color: #fff; }
  .auth-style .ant-empty {
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    margin: 0 8px; }
  .auth-style .ant-empty-image {
    margin-bottom: 8px;
    height: 100px; }
  .auth-style .ant-empty-image img {
    height: 100%; }
  .auth-style .ant-empty-description {
    margin: 0; }
  .auth-style .ant-empty-footer {
    margin-top: 16px; }
  .auth-style .ant-empty-small {
    margin: 8px 0; }
  .auth-style .ant-empty-small .ant-empty-image {
    height: 35px; }
  .auth-style .ant-empty-normal {
    margin: 32px 0; }
  .auth-style .ant-empty-normal .ant-empty-image {
    height: 40px; }
  .auth-style .ant-select-dropdown--multiple
.ant-select-dropdown-menu-item
.ant-empty-small {
    margin-left: 32px; }
  .auth-style .ant-form {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none; }
  .auth-style .ant-form legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: inherit;
    color: rgba(0, 0, 0, 0.45);
    border: 0;
    border-bottom: 1px solid #d9d9d9; }
  .auth-style .ant-form label {
    font-size: 14px; }
  .auth-style .ant-form input[type="search"] {
    box-sizing: border-box; }
  .auth-style .ant-form input[type="checkbox"],
  .auth-style .ant-form input[type="radio"] {
    line-height: normal; }
  .auth-style .ant-form input[type="file"] {
    display: block; }
  .auth-style .ant-form input[type="range"] {
    display: block;
    width: 100%; }
  .auth-style .ant-form select[multiple],
  .auth-style .ant-form select[size] {
    height: auto; }
  .auth-style .ant-form input[type="checkbox"]:focus,
  .auth-style .ant-form input[type="file"]:focus,
  .auth-style .ant-form input[type="radio"]:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px; }
  .auth-style .ant-form output {
    display: block;
    padding-top: 15px;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65); }
  .auth-style .ant-form-item-required:before {
    display: inline-block;
    margin-right: 4px;
    content: "*";
    font-family: SimSun;
    line-height: 1;
    font-size: 14px;
    color: #f5222d; }
  .auth-style .ant-form-hide-required-mark .ant-form-item-required:before {
    display: none; }
  .auth-style .ant-checkbox-inline.disabled,
  .auth-style .ant-checkbox-vertical.disabled,
  .auth-style .ant-checkbox.disabled label,
  .auth-style .ant-radio-inline.disabled,
  .auth-style .ant-radio-vertical.disabled,
  .auth-style .ant-radio.disabled label,
  .auth-style input[type="checkbox"].disabled,
  .auth-style input[type="checkbox"][disabled],
  .auth-style input[type="radio"].disabled,
  .auth-style input[type="radio"][disabled] {
    cursor: not-allowed; }
  .auth-style .ant-form-item {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    padding: 0;
    list-style: none;
    margin: 0 0 24px;
    vertical-align: top; }
  .auth-style .ant-form-item label {
    position: relative; }
  .auth-style .ant-form-item label > .anticon {
    vertical-align: top;
    font-size: 14px; }
  .auth-style .ant-form-item-control {
    line-height: 39.9999px;
    position: relative;
    zoom: 1; }
  .auth-style .ant-form-item-control:after,
  .auth-style .ant-form-item-control:before {
    content: "";
    display: table; }
  .auth-style .ant-form-item-control:after {
    clear: both; }
  .auth-style .ant-form-item-children {
    position: relative; }
  .auth-style .ant-form-item-with-help {
    margin-bottom: 5px; }
  .auth-style .ant-form-item-label {
    text-align: right;
    vertical-align: middle;
    line-height: 39.9999px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap; }
  .auth-style .ant-form-item-label label {
    color: rgba(0, 0, 0, 0.85); }
  .auth-style .ant-form-item-label label:after {
    content: ":";
    margin: 0 8px 0 2px;
    position: relative;
    top: -0.5px; }
  .auth-style .ant-form-item .ant-switch {
    margin: 2px 0 4px; }
  .auth-style .ant-form-item-no-colon .ant-form-item-label label:after {
    content: " "; }
  .auth-style .ant-form-explain,
  .auth-style .ant-form-extra {
    color: rgba(0, 0, 0, 0.45);
    line-height: 1.524;
    transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    margin-top: -2px;
    clear: both; }
  .auth-style .ant-form-extra {
    padding-top: 4px; }
  .auth-style .ant-form-text {
    display: inline-block;
    padding-right: 8px; }
  .auth-style .ant-form-split {
    display: block;
    text-align: center; }
  .auth-style form .has-feedback .ant-input {
    padding-right: 24px; }
  .auth-style form
.has-feedback
:not(.ant-input-group-addon)
> .ant-select
.ant-select-arrow,
  .auth-style form
.has-feedback
:not(.ant-input-group-addon)
> .ant-select
.ant-select-selection__clear,
  .auth-style form .has-feedback > .ant-select .ant-select-arrow,
  .auth-style form .has-feedback > .ant-select .ant-select-selection__clear {
    right: 28px; }
  .auth-style form
.has-feedback
:not(.ant-input-group-addon)
> .ant-select
.ant-select-selection-selected-value,
  .auth-style form .has-feedback > .ant-select .ant-select-selection-selected-value {
    padding-right: 42px; }
  .auth-style form .has-feedback .ant-cascader-picker-arrow {
    margin-right: 17px; }
  .auth-style form .has-feedback .ant-calendar-picker-clear,
  .auth-style form .has-feedback .ant-calendar-picker-icon,
  .auth-style form .has-feedback .ant-cascader-picker-clear,
  .auth-style form
.has-feedback
.ant-input-search:not(.ant-input-search-enter-button)
.ant-input-suffix,
  .auth-style form .has-feedback .ant-time-picker-clear,
  .auth-style form .has-feedback .ant-time-picker-icon {
    right: 28px; }
  .auth-style form textarea.ant-input {
    height: auto;
    margin-bottom: 4px; }
  .auth-style form .ant-upload {
    background: transparent; }
  .auth-style form input[type="checkbox"],
  .auth-style form input[type="radio"] {
    width: 14px;
    height: 14px; }
  .auth-style form .ant-checkbox-inline,
  .auth-style form .ant-radio-inline {
    display: inline-block;
    vertical-align: middle;
    font-weight: 400;
    cursor: pointer;
    margin-left: 8px; }
  .auth-style form .ant-checkbox-inline:first-child,
  .auth-style form .ant-radio-inline:first-child {
    margin-left: 0; }
  .auth-style form .ant-checkbox-vertical,
  .auth-style form .ant-radio-vertical {
    display: block; }
  .auth-style form .ant-checkbox-vertical + .ant-checkbox-vertical,
  .auth-style form .ant-radio-vertical + .ant-radio-vertical {
    margin-left: 0; }
  .auth-style form .ant-input-number + .ant-form-text {
    margin-left: 8px; }
  .auth-style form .ant-input-number-handler-wrap {
    z-index: 2; }
  .auth-style form .ant-cascader-picker,
  .auth-style form .ant-select {
    width: 100%; }
  .auth-style form .ant-input-group .ant-cascader-picker,
  .auth-style form .ant-input-group .ant-select {
    width: auto; }
  .auth-style form .ant-input-group-wrapper,
  .auth-style form :not(.ant-input-group-wrapper) > .ant-input-group {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px; }
  .auth-style .ant-input-group-wrap .ant-select-selection {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .auth-style .ant-input-group-wrap .ant-select-selection:hover {
    border-color: #d9d9d9; }
  .auth-style .ant-input-group-wrap .ant-select-selection--single {
    margin-left: -1px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.07); }
  .auth-style .ant-input-group-wrap
.ant-select-selection--single
.ant-select-selection__rendered {
    padding-left: 8px;
    padding-right: 25px;
    line-height: 30px; }
  .auth-style .ant-input-group-wrap .ant-select-open .ant-select-selection {
    border-color: #d9d9d9;
    box-shadow: none; }
  .auth-style .ant-col-24.ant-form-item-label,
  .auth-style .ant-col-xl-24.ant-form-item-label,
  .auth-style .ant-form-vertical .ant-form-item-label {
    padding: 0 0 8px;
    margin: 0;
    display: block;
    text-align: left;
    line-height: 1.5;
    white-space: normal; }
  .auth-style .ant-col-24.ant-form-item-label label:after,
  .auth-style .ant-col-xl-24.ant-form-item-label label:after,
  .auth-style .ant-form-vertical .ant-form-item-label label:after {
    display: none; }
  .auth-style .ant-form-vertical .ant-form-item {
    padding-bottom: 8px; }
  .auth-style .ant-form-vertical .ant-form-item-control {
    line-height: 1.5; }
  .auth-style .ant-form-vertical .ant-form-explain,
  .auth-style .ant-form-vertical .ant-form-extra {
    margin-top: 2px;
    margin-bottom: -4px; }
  @media (max-width: 575px) {
    .auth-style .ant-form-item-control-wrapper,
    .auth-style .ant-form-item-label {
      display: block;
      width: 100%; }
    .auth-style .ant-form-item-label {
      padding: 0 0 8px;
      margin: 0;
      display: block;
      text-align: left;
      line-height: 1.5;
      white-space: normal; }
    .auth-style .ant-form-item-label label:after {
      display: none; }
    .auth-style .ant-col-xs-24.ant-form-item-label {
      padding: 0 0 8px;
      margin: 0;
      display: block;
      text-align: left;
      line-height: 1.5;
      white-space: normal; }
    .auth-style .ant-col-xs-24.ant-form-item-label label:after {
      display: none; } }
  @media (max-width: 767px) {
    .auth-style .ant-col-sm-24.ant-form-item-label {
      padding: 0 0 8px;
      margin: 0;
      display: block;
      text-align: left;
      line-height: 1.5;
      white-space: normal; }
    .auth-style .ant-col-sm-24.ant-form-item-label label:after {
      display: none; } }
  @media (max-width: 991px) {
    .auth-style .ant-col-md-24.ant-form-item-label {
      padding: 0 0 8px;
      margin: 0;
      display: block;
      text-align: left;
      line-height: 1.5;
      white-space: normal; }
    .auth-style .ant-col-md-24.ant-form-item-label label:after {
      display: none; } }
  @media (max-width: 1199px) {
    .auth-style .ant-col-lg-24.ant-form-item-label {
      padding: 0 0 8px;
      margin: 0;
      display: block;
      text-align: left;
      line-height: 1.5;
      white-space: normal; }
    .auth-style .ant-col-lg-24.ant-form-item-label label:after {
      display: none; } }
  @media (max-width: 1599px) {
    .auth-style .ant-col-xl-24.ant-form-item-label {
      padding: 0 0 8px;
      margin: 0;
      display: block;
      text-align: left;
      line-height: 1.5;
      white-space: normal; }
    .auth-style .ant-col-xl-24.ant-form-item-label label:after {
      display: none; } }
  .auth-style .ant-form-inline .ant-form-item {
    display: inline-block;
    margin-right: 16px;
    margin-bottom: 0; }
  .auth-style .ant-form-inline .ant-form-item-with-help {
    margin-bottom: 24px; }
  .auth-style .ant-form-inline .ant-form-item > .ant-form-item-control-wrapper,
  .auth-style .ant-form-inline .ant-form-item > .ant-form-item-label {
    display: inline-block;
    vertical-align: top; }
  .auth-style .ant-form-inline .ant-form-text,
  .auth-style .ant-form-inline .has-feedback {
    display: inline-block; }
  .auth-style .has-error.has-feedback .ant-form-item-children-icon,
  .auth-style .has-success.has-feedback .ant-form-item-children-icon,
  .auth-style .has-warning.has-feedback .ant-form-item-children-icon,
  .auth-style .is-validating.has-feedback .ant-form-item-children-icon {
    position: absolute;
    top: 50%;
    right: 0;
    visibility: visible;
    pointer-events: none;
    width: 32px;
    height: 20px;
    line-height: 20px;
    margin-top: -10px;
    text-align: center;
    font-size: 14px;
    -webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
    z-index: 1; }
  .auth-style .has-error.has-feedback .ant-form-item-children-icon svg,
  .auth-style .has-success.has-feedback .ant-form-item-children-icon svg,
  .auth-style .has-warning.has-feedback .ant-form-item-children-icon svg,
  .auth-style .is-validating.has-feedback .ant-form-item-children-icon svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto; }
  .auth-style .has-success.has-feedback .ant-form-item-children-icon {
    -webkit-animation-name: diffZoomIn1 !important;
    animation-name: diffZoomIn1 !important;
    color: #52c41a; }
  .auth-style .has-warning .ant-form-explain,
  .auth-style .has-warning .ant-form-split {
    color: #faad14; }
  .auth-style .has-warning .ant-input,
  .auth-style .has-warning .ant-input:hover {
    border-color: #faad14; }
  .auth-style .has-warning .ant-input:focus {
    border-color: #ffc53d;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    border-right-width: 1px !important; }
  .auth-style .has-warning .ant-input:not([disabled]):hover {
    border-color: #faad14; }
  .auth-style .has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
    border-color: #ffc53d;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    border-right-width: 1px !important; }
  .auth-style .has-warning .ant-input-affix-wrapper .ant-input,
  .auth-style .has-warning .ant-input-affix-wrapper .ant-input:hover {
    border-color: #faad14; }
  .auth-style .has-warning .ant-input-affix-wrapper .ant-input:focus {
    border-color: #ffc53d;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    border-right-width: 1px !important; }
  .auth-style .has-warning
.ant-input-affix-wrapper:hover
.ant-input:not(.ant-input-disabled) {
    border-color: #faad14; }
  .auth-style .has-warning .ant-input-prefix {
    color: #faad14; }
  .auth-style .has-warning .ant-input-group-addon {
    color: #faad14;
    border-color: #faad14;
    background-color: #fff; }
  .auth-style .has-warning .has-feedback {
    color: #faad14; }
  .auth-style .has-warning.has-feedback .ant-form-item-children-icon {
    color: #faad14;
    -webkit-animation-name: diffZoomIn3 !important;
    animation-name: diffZoomIn3 !important; }
  .auth-style .has-warning .ant-select-selection,
  .auth-style .has-warning .ant-select-selection:hover {
    border-color: #faad14; }
  .auth-style .has-warning .ant-select-focused .ant-select-selection,
  .auth-style .has-warning .ant-select-open .ant-select-selection {
    border-color: #ffc53d;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    border-right-width: 1px !important; }
  .auth-style .has-warning .ant-calendar-picker-icon:after,
  .auth-style .has-warning .ant-cascader-picker-arrow,
  .auth-style .has-warning .ant-picker-icon:after,
  .auth-style .has-warning .ant-select-arrow,
  .auth-style .has-warning .ant-time-picker-icon:after {
    color: #faad14; }
  .auth-style .has-warning .ant-input-number,
  .auth-style .has-warning .ant-time-picker-input {
    border-color: #faad14; }
  .auth-style .has-warning .ant-input-number-focused,
  .auth-style .has-warning .ant-input-number:focus,
  .auth-style .has-warning .ant-time-picker-input-focused,
  .auth-style .has-warning .ant-time-picker-input:focus {
    border-color: #ffc53d;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    border-right-width: 1px !important; }
  .auth-style .has-warning .ant-input-number:not([disabled]):hover,
  .auth-style .has-warning .ant-time-picker-input:not([disabled]):hover {
    border-color: #faad14; }
  .auth-style .has-warning .ant-cascader-picker:focus .ant-cascader-input {
    border-color: #ffc53d;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
    border-right-width: 1px !important; }
  .auth-style .has-error .ant-form-explain,
  .auth-style .has-error .ant-form-split {
    color: #f5222d; }
  .auth-style .has-error .ant-input,
  .auth-style .has-error .ant-input:hover {
    border-color: #f5222d; }
  .auth-style .has-error .ant-input:focus {
    border-color: #ff4d4f;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
    border-right-width: 1px !important; }
  .auth-style .has-error .ant-input:not([disabled]):hover {
    border-color: #f5222d; }
  .auth-style .has-error .ant-calendar-picker-open .ant-calendar-picker-input {
    border-color: #ff4d4f;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
    border-right-width: 1px !important; }
  .auth-style .has-error .ant-input-affix-wrapper .ant-input,
  .auth-style .has-error .ant-input-affix-wrapper .ant-input:hover {
    border-color: #f5222d; }
  .auth-style .has-error .ant-input-affix-wrapper .ant-input:focus {
    border-color: #ff4d4f;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
    border-right-width: 1px !important; }
  .auth-style .has-error
.ant-input-affix-wrapper:hover
.ant-input:not(.ant-input-disabled) {
    border-color: #f5222d; }
  .auth-style .has-error .ant-input-prefix {
    color: #f5222d; }
  .auth-style .has-error .ant-input-group-addon {
    color: #f5222d;
    border-color: #f5222d;
    background-color: #fff; }
  .auth-style .has-error .has-feedback {
    color: #f5222d; }
  .auth-style .has-error.has-feedback .ant-form-item-children-icon {
    color: #f5222d;
    -webkit-animation-name: diffZoomIn2 !important;
    animation-name: diffZoomIn2 !important; }
  .auth-style .has-error .ant-select-selection,
  .auth-style .has-error .ant-select-selection:hover {
    border-color: #f5222d; }
  .auth-style .has-error .ant-select-focused .ant-select-selection,
  .auth-style .has-error .ant-select-open .ant-select-selection {
    border-color: #ff4d4f;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
    border-right-width: 1px !important; }
  .auth-style .has-error .ant-select.ant-select-auto-complete .ant-input:focus {
    border-color: #f5222d; }
  .auth-style .has-error .ant-input-group-addon .ant-select-selection {
    border-color: transparent;
    box-shadow: none; }
  .auth-style .has-error .ant-calendar-picker-icon:after,
  .auth-style .has-error .ant-cascader-picker-arrow,
  .auth-style .has-error .ant-picker-icon:after,
  .auth-style .has-error .ant-select-arrow,
  .auth-style .has-error .ant-time-picker-icon:after {
    color: #f5222d; }
  .auth-style .has-error .ant-input-number,
  .auth-style .has-error .ant-time-picker-input {
    border-color: #f5222d; }
  .auth-style .has-error .ant-input-number-focused,
  .auth-style .has-error .ant-input-number:focus,
  .auth-style .has-error .ant-time-picker-input-focused,
  .auth-style .has-error .ant-time-picker-input:focus {
    border-color: #ff4d4f;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
    border-right-width: 1px !important; }
  .auth-style .has-error .ant-input-number:not([disabled]):hover,
  .auth-style .has-error .ant-mention-wrapper .ant-mention-editor,
  .auth-style .has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover,
  .auth-style .has-error .ant-time-picker-input:not([disabled]):hover {
    border-color: #f5222d; }
  .auth-style .has-error .ant-cascader-picker:focus .ant-cascader-input,
  .auth-style .has-error
.ant-mention-wrapper.ant-mention-active:not([disabled])
.ant-mention-editor,
  .auth-style .has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
    border-color: #ff4d4f;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
    border-right-width: 1px !important; }
  .auth-style .has-error .ant-transfer-list {
    border-color: #f5222d; }
  .auth-style .has-error .ant-transfer-list-search:not([disabled]) {
    border-color: #d9d9d9; }
  .auth-style .has-error .ant-transfer-list-search:not([disabled]):hover {
    border-color: #40a9ff;
    border-right-width: 1px !important; }
  .auth-style .has-error .ant-transfer-list-search:not([disabled]):focus {
    border-color: #40a9ff;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    border-right-width: 1px !important; }
  .auth-style .is-validating.has-feedback .ant-form-item-children-icon {
    display: inline-block;
    color: #1890ff; }
  .auth-style .ant-advanced-search-form .ant-form-item {
    margin-bottom: 24px; }
  .auth-style .ant-advanced-search-form .ant-form-item-with-help {
    margin-bottom: 5px; }
  .auth-style .show-help-appear,
  .auth-style .show-help-enter,
  .auth-style .show-help-leave {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .auth-style .show-help-appear.show-help-appear-active,
  .auth-style .show-help-enter.show-help-enter-active {
    -webkit-animation-name: antShowHelpIn;
    animation-name: antShowHelpIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .auth-style .show-help-leave.show-help-leave-active {
    -webkit-animation-name: antShowHelpOut;
    animation-name: antShowHelpOut;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    pointer-events: none; }
  .auth-style .show-help-appear,
  .auth-style .show-help-enter {
    opacity: 0; }
  .auth-style .show-help-appear,
  .auth-style .show-help-enter,
  .auth-style .show-help-leave {
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }

@-webkit-keyframes antShowHelpIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes antShowHelpIn {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px); }
  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes antShowHelpOut {
  to {
    opacity: 0;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px); } }

@keyframes antShowHelpOut {
  to {
    opacity: 0;
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px); } }

@-webkit-keyframes diffZoomIn1 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  to {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes diffZoomIn1 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  to {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes diffZoomIn2 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  to {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes diffZoomIn2 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  to {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes diffZoomIn3 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  to {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes diffZoomIn3 {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  to {
    -webkit-transform: scale(1);
    transform: scale(1); } }
  .auth-style .ant-input-number {
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    box-sizing: border-box;
    list-style: none;
    position: relative;
    width: 100%;
    height: 32px;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    background-image: none;
    transition: all 0.3s;
    margin: 0;
    padding: 0;
    display: inline-block;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    width: 90px; }
  .auth-style .ant-input-number::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1; }
  .auth-style .ant-input-number:-ms-input-placeholder {
    color: #bfbfbf; }
  .auth-style .ant-input-number::-webkit-input-placeholder {
    color: #bfbfbf; }
  .auth-style .ant-input-number:focus {
    border-color: #40a9ff;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    border-right-width: 1px !important; }
  .auth-style textarea.ant-input-number {
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
    min-height: 32px; }
  .auth-style .ant-input-number-lg {
    padding: 6px 11px;
    height: 40px; }
  .auth-style .ant-input-number-sm {
    padding: 1px 7px;
    height: 24px; }
  .auth-style .ant-input-number-handler {
    text-align: center;
    line-height: 0;
    height: 50%;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.45);
    position: relative;
    transition: all 0.1s linear;
    display: block;
    width: 100%;
    font-weight: 700; }
  .auth-style .ant-input-number-handler:active {
    background: #f4f4f4; }
  .auth-style .ant-input-number-handler:hover .ant-input-number-handler-down-inner,
  .auth-style .ant-input-number-handler:hover .ant-input-number-handler-up-inner {
    color: #40a9ff; }
  .auth-style .ant-input-number-handler-down-inner,
  .auth-style .ant-input-number-handler-up-inner {
    display: inline-block;
    font-style: normal;
    vertical-align: -0.125em;
    text-align: center;
    text-transform: none;
    line-height: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: absolute;
    width: 12px;
    height: 12px;
    transition: all 0.1s linear;
    right: 4px;
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-input-number-handler-down-inner > *,
  .auth-style .ant-input-number-handler-up-inner > * {
    line-height: 1; }
  .auth-style .ant-input-number-handler-down-inner svg,
  .auth-style .ant-input-number-handler-up-inner svg {
    display: inline-block; }
  .auth-style .ant-input-number-handler-down-inner:before,
  .auth-style .ant-input-number-handler-up-inner:before {
    display: none; }
  .auth-style .ant-input-number-handler-down-inner
.ant-input-number-handler-down-inner-icon,
  .auth-style .ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
  .auth-style .ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
  .auth-style .ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon {
    display: block; }
  .auth-style .ant-input-number-focused,
  .auth-style .ant-input-number:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important; }
  .auth-style .ant-input-number-focused {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }
  .auth-style .ant-input-number-disabled {
    background-color: #f5f5f5;
    opacity: 1;
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-input-number-disabled:hover {
    border-color: #e6d8d8;
    border-right-width: 1px !important; }
  .auth-style .ant-input-number-disabled .ant-input-number-input {
    cursor: not-allowed; }
  .auth-style .ant-input-number-disabled .ant-input-number-handler-wrap {
    display: none; }
  .auth-style .ant-input-number-input {
    width: 100%;
    text-align: left;
    outline: 0;
    -moz-appearance: textfield;
    height: 30px;
    transition: all 0.3s linear;
    background-color: transparent;
    border: 0;
    border-radius: 4px;
    padding: 0 11px; }
  .auth-style .ant-input-number-input::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1; }
  .auth-style .ant-input-number-input:-ms-input-placeholder {
    color: #bfbfbf; }
  .auth-style .ant-input-number-input::-webkit-input-placeholder {
    color: #bfbfbf; }
  .auth-style .ant-input-number-lg {
    padding: 0;
    font-size: 16px; }
  .auth-style .ant-input-number-lg input {
    height: 38px; }
  .auth-style .ant-input-number-sm {
    padding: 0; }
  .auth-style .ant-input-number-sm input {
    height: 22px;
    padding: 0 7px; }
  .auth-style .ant-input-number-handler-wrap {
    border-left: 1px solid #d9d9d9;
    width: 22px;
    height: 100%;
    background: #fff;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    border-radius: 0 4px 4px 0;
    transition: opacity 0.24s linear 0.1s; }
  .auth-style .ant-input-number-handler-wrap
.ant-input-number-handler
.ant-input-number-handler-down-inner,
  .auth-style .ant-input-number-handler-wrap
.ant-input-number-handler
.ant-input-number-handler-up-inner {
    display: inline-block;
    font-size: 12px;
    font-size: 7px\9;
    -webkit-transform: scale(0.58333) rotate(0deg);
    transform: scale(0.58333) rotate(0deg);
    min-width: auto;
    margin-right: 0; }
  .auth-style :root
.ant-input-number-handler-wrap
.ant-input-number-handler
.ant-input-number-handler-down-inner,
  .auth-style :root
.ant-input-number-handler-wrap
.ant-input-number-handler
.ant-input-number-handler-up-inner {
    font-size: 12px; }
  .auth-style .ant-input-number-handler-wrap:hover .ant-input-number-handler {
    height: 40%; }
  .auth-style .ant-input-number:hover .ant-input-number-handler-wrap {
    opacity: 1; }
  .auth-style .ant-input-number-handler-up {
    cursor: pointer; }
  .auth-style .ant-input-number-handler-up-inner {
    top: 50%;
    margin-top: -5px;
    text-align: center; }
  .auth-style .ant-input-number-handler-up:hover {
    height: 60% !important; }
  .auth-style .ant-input-number-handler-down {
    border-top: 1px solid #d9d9d9;
    top: 0;
    cursor: pointer; }
  .auth-style .ant-input-number-handler-down-inner {
    top: 50%;
    margin-top: -6px;
    text-align: center; }
  .auth-style .ant-input-number-handler-down:hover {
    height: 60% !important; }
  .auth-style .ant-input-number-handler-down-disabled,
  .auth-style .ant-input-number-handler-up-disabled {
    cursor: not-allowed; }
  .auth-style .ant-input-number-handler-down-disabled:hover
.ant-input-number-handler-down-inner,
  .auth-style .ant-input-number-handler-up-disabled:hover
.ant-input-number-handler-up-inner {
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-input {
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    box-sizing: border-box;
    margin: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    padding: 4px 11px;
    width: 100%;
    height: 32px;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all 0.3s; }
  .auth-style .ant-input::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1; }
  .auth-style .ant-input:-ms-input-placeholder {
    color: #bfbfbf; }
  .auth-style .ant-input::-webkit-input-placeholder {
    color: #bfbfbf; }
  .auth-style .ant-input:focus,
  .auth-style .ant-input:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important; }
  .auth-style .ant-input:focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }
  .auth-style .ant-input-disabled {
    background-color: #f5f5f5;
    opacity: 1;
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-input-disabled:hover {
    border-color: #e6d8d8;
    border-right-width: 1px !important; }
  .auth-style textarea.ant-input {
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
    min-height: 32px; }
  .auth-style .ant-input-lg {
    padding: 6px 11px;
    height: 40px;
    font-size: 16px; }
  .auth-style .ant-input-sm {
    padding: 1px 7px;
    height: 24px; }
  .auth-style .ant-input-group {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: table;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%; }
  .auth-style .ant-input-group[class*="col-"] {
    float: none;
    padding-left: 0;
    padding-right: 0; }
  .auth-style .ant-input-group > [class*="col-"] {
    padding-right: 8px; }
  .auth-style .ant-input-group > [class*="col-"]:last-child {
    padding-right: 0; }
  .auth-style .ant-input-group-addon,
  .auth-style .ant-input-group-wrap,
  .auth-style .ant-input-group > .ant-input {
    display: table-cell; }
  .auth-style .ant-input-group-addon:not(:first-child):not(:last-child),
  .auth-style .ant-input-group-wrap:not(:first-child):not(:last-child),
  .auth-style .ant-input-group > .ant-input:not(:first-child):not(:last-child) {
    border-radius: 0; }
  .auth-style .ant-input-group-addon,
  .auth-style .ant-input-group-wrap {
    width: 1px;
    white-space: nowrap;
    vertical-align: middle; }
  .auth-style .ant-input-group-wrap > * {
    display: block !important; }
  .auth-style .ant-input-group .ant-input {
    float: left;
    width: 100%;
    margin-bottom: 0; }
  .auth-style .ant-input-group .ant-input:focus,
  .auth-style .ant-input-group .ant-input:hover {
    z-index: 1;
    border-right-width: 1px; }
  .auth-style .ant-input-group-addon {
    padding: 0 11px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: rgba(0, 0, 0, 0.65);
    text-align: center;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    position: relative;
    transition: all 0.3s; }
  .auth-style .ant-input-group-addon .ant-select {
    margin: -5px -11px; }
  .auth-style .ant-input-group-addon .ant-select .ant-select-selection {
    background-color: inherit;
    margin: -1px;
    border: 1px solid transparent;
    box-shadow: none; }
  .auth-style .ant-input-group-addon .ant-select-focused .ant-select-selection,
  .auth-style .ant-input-group-addon .ant-select-open .ant-select-selection {
    color: #1890ff; }
  .auth-style .ant-input-group-addon > i:only-child:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .auth-style .ant-input-group-addon:first-child,
  .auth-style .ant-input-group-addon:first-child .ant-select .ant-select-selection,
  .auth-style .ant-input-group > .ant-input:first-child,
  .auth-style .ant-input-group > .ant-input:first-child .ant-select .ant-select-selection {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .auth-style .ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .auth-style .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .auth-style .ant-input-group-addon:first-child {
    border-right: 0; }
  .auth-style .ant-input-group-addon:last-child {
    border-left: 0; }
  .auth-style .ant-input-group-addon:last-child,
  .auth-style .ant-input-group-addon:last-child .ant-select .ant-select-selection,
  .auth-style .ant-input-group > .ant-input:last-child,
  .auth-style .ant-input-group > .ant-input:last-child .ant-select .ant-select-selection {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }
  .auth-style .ant-input-group-lg .ant-input,
  .auth-style .ant-input-group-lg > .ant-input-group-addon {
    padding: 6px 11px;
    height: 40px;
    font-size: 16px; }
  .auth-style .ant-input-group-sm .ant-input,
  .auth-style .ant-input-group-sm > .ant-input-group-addon {
    padding: 1px 7px;
    height: 24px; }
  .auth-style .ant-input-group-lg .ant-select-selection--single {
    height: 40px; }
  .auth-style .ant-input-group-sm .ant-select-selection--single {
    height: 24px; }
  .auth-style .ant-input-group .ant-input-affix-wrapper {
    display: table-cell;
    width: 100%;
    float: left; }
  .auth-style .ant-input-group.ant-input-group-compact {
    display: block;
    zoom: 1; }
  .auth-style .ant-input-group.ant-input-group-compact:after,
  .auth-style .ant-input-group.ant-input-group-compact:before {
    content: "";
    display: table; }
  .auth-style .ant-input-group.ant-input-group-compact:after {
    clear: both; }
  .auth-style .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
  .auth-style .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-input:not(:first-child):not(:last-child) {
    border-right-width: 1px; }
  .auth-style .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
  .auth-style .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
  .auth-style .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
  .auth-style .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-input:not(:first-child):not(:last-child):focus,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-input:not(:first-child):not(:last-child):hover {
    z-index: 1; }
  .auth-style .ant-input-group.ant-input-group-compact > * {
    border-radius: 0;
    vertical-align: top;
    float: none;
    display: inline-block; }
  .auth-style .ant-input-group.ant-input-group-compact > :not(:last-child) {
    border-right-width: 1px;
    margin-right: -1px; }
  .auth-style .ant-input-group.ant-input-group-compact .ant-input {
    float: none; }
  .auth-style .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input,
  .auth-style .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-mention-wrapper
.ant-mention-editor,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-select-auto-complete
.ant-input,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-select
> .ant-select-selection,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-time-picker
.ant-time-picker-input {
    border-radius: 0;
    border-right-width: 1px; }
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-calendar-picker
.ant-input:focus,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-calendar-picker
.ant-input:hover,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-cascader-picker
.ant-input:focus,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-cascader-picker
.ant-input:hover,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-mention-wrapper
.ant-mention-editor:focus,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-mention-wrapper
.ant-mention-editor:hover,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-select-auto-complete
.ant-input:focus,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-select-auto-complete
.ant-input:hover,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-select
> .ant-select-selection:focus,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-select
> .ant-select-selection:hover,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-time-picker
.ant-time-picker-input:focus,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-time-picker
.ant-time-picker-input:hover {
    z-index: 1; }
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-calendar-picker:first-child
.ant-input,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-cascader-picker:first-child
.ant-input,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-mention-wrapper:first-child
.ant-mention-editor,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-select-auto-complete:first-child
.ant-input,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-select:first-child
> .ant-select-selection,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-time-picker:first-child
.ant-time-picker-input,
  .auth-style .ant-input-group.ant-input-group-compact > :first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-calendar-picker:last-child
.ant-input,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-cascader-picker-focused:last-child
.ant-input,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-cascader-picker:last-child
.ant-input,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-mention-wrapper:last-child
.ant-mention-editor,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-select-auto-complete:last-child
.ant-input,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-select:last-child
> .ant-select-selection,
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-time-picker:last-child
.ant-time-picker-input,
  .auth-style .ant-input-group.ant-input-group-compact > :last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right-width: 1px; }
  .auth-style .ant-input-group.ant-input-group-compact
> .ant-select-auto-complete
.ant-input {
    vertical-align: top; }
  .auth-style .ant-input-group-wrapper {
    display: inline-block;
    vertical-align: top;
    width: 100%; }
  .auth-style .ant-input-affix-wrapper {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    width: 100%; }
  .auth-style .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #40a9ff;
    border-right-width: 1px !important; }
  .auth-style .ant-input-affix-wrapper .ant-input {
    position: relative; }
  .auth-style .ant-input-affix-wrapper .ant-input-prefix,
  .auth-style .ant-input-affix-wrapper .ant-input-suffix {
    position: absolute;
    top: 50%;
    z-index: 2;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    line-height: 0;
    color: rgba(0, 0, 0, 0.65); }
  .auth-style .ant-input-affix-wrapper .ant-input-prefix :not(.anticon),
  .auth-style .ant-input-affix-wrapper .ant-input-suffix :not(.anticon) {
    line-height: 1.5; }
  .auth-style .ant-input-affix-wrapper .ant-input-prefix {
    left: 12px; }
  .auth-style .ant-input-affix-wrapper .ant-input-suffix {
    right: 12px; }
  .auth-style .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 30px; }
  .auth-style .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 30px; }
  .auth-style .ant-input-affix-wrapper .ant-input {
    min-height: 100%; }
  .auth-style .ant-input-password-icon {
    cursor: pointer;
    transition: all 0.3s; }
  .auth-style .ant-input-password-icon:hover {
    color: #333; }
  .auth-style .ant-input-search-icon {
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    transition: all 0.3s; }
  .auth-style .ant-input-search-icon:hover {
    color: rgba(0, 0, 0, 0.8); }
  .auth-style .ant-input-search:not(.ant-input-search-small) > .ant-input-suffix {
    right: 12px; }
  .auth-style .ant-input-search > .ant-input-suffix > .ant-input-search-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .auth-style .ant-input-search
> .ant-input-suffix
> .ant-input-search-button
> .anticon-search {
    font-size: 16px; }
  .auth-style .ant-input-search.ant-input-search-enter-button {
    display: table; }
  .auth-style .ant-input-search.ant-input-search-enter-button > * {
    display: table-cell; }
  .auth-style .ant-input-search.ant-input-search-enter-button > .ant-input {
    position: relative;
    display: inline-block;
    padding: 4px 11px;
    height: 32px;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all 0.3s;
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0; }
  .auth-style .ant-input-search.ant-input-search-enter-button
> .ant-input::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1; }
  .auth-style .ant-input-search.ant-input-search-enter-button
> .ant-input:-ms-input-placeholder {
    color: #bfbfbf; }
  .auth-style .ant-input-search.ant-input-search-enter-button
> .ant-input::-webkit-input-placeholder {
    color: #bfbfbf; }
  .auth-style .ant-input-search.ant-input-search-enter-button > .ant-input:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important; }
  .auth-style .ant-input-search.ant-input-search-enter-button > .ant-input:focus {
    border-color: #40a9ff;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    border-right-width: 1px !important; }
  .auth-style .ant-input-search.ant-input-search-enter-button > .ant-input-disabled {
    background-color: #f5f5f5;
    opacity: 1;
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-input-search.ant-input-search-enter-button > .ant-input-disabled:hover {
    border-color: #e6d8d8;
    border-right-width: 1px !important; }
  .auth-style textarea.ant-input-search.ant-input-search-enter-button > .ant-input {
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
    min-height: 32px; }
  .auth-style .ant-input-search.ant-input-search-enter-button > .ant-input-lg {
    padding: 6px 11px;
    height: 40px;
    font-size: 16px; }
  .auth-style .ant-input-search.ant-input-search-enter-button > .ant-input-sm {
    padding: 1px 7px;
    height: 24px; }
  .auth-style .ant-input-search.ant-input-search-enter-button > .ant-input-suffix {
    position: static;
    -webkit-transform: none;
    transform: none; }
  .auth-style .ant-layout {
    display: flex;
    flex-direction: column;
    flex: auto;
    background: #f0f2f5;
    min-height: 0; }
  .auth-style .ant-layout,
  .auth-style .ant-layout * {
    box-sizing: border-box; }
  .auth-style .ant-layout.ant-layout-has-sider {
    flex-direction: row; }
  .auth-style .ant-layout.ant-layout-has-sider > .ant-layout,
  .auth-style .ant-layout.ant-layout-has-sider > .ant-layout-content {
    overflow-x: hidden; }
  .auth-style .ant-layout-footer,
  .auth-style .ant-layout-header {
    flex: 0 0 auto; }
  .auth-style .ant-layout-header {
    background: #001529;
    padding: 0 50px;
    height: 64px;
    line-height: 64px; }
  .auth-style .ant-layout-footer {
    background: #f0f2f5;
    padding: 24px 50px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px; }
  .auth-style .ant-layout-content {
    flex: auto;
    min-height: 0; }
  .auth-style .ant-layout-sider {
    transition: all 0.2s;
    position: relative;
    background: #001529;
    min-width: 0; }
  .auth-style .ant-layout-sider-children {
    height: 100%;
    padding-top: 0.1px;
    margin-top: -0.1px; }
  .auth-style .ant-layout-sider-has-trigger {
    padding-bottom: 48px; }
  .auth-style .ant-layout-sider-right {
    order: 1; }
  .auth-style .ant-layout-sider-trigger {
    position: fixed;
    text-align: center;
    bottom: 0;
    cursor: pointer;
    height: 48px;
    line-height: 48px;
    color: #fff;
    background: #002140;
    z-index: 1;
    transition: all 0.2s; }
  .auth-style .ant-layout-sider-zero-width > * {
    overflow: hidden; }
  .auth-style .ant-layout-sider-zero-width-trigger {
    position: absolute;
    top: 64px;
    right: -36px;
    text-align: center;
    width: 36px;
    height: 42px;
    line-height: 42px;
    background: #001529;
    color: #fff;
    font-size: 18px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background 0.3s ease; }
  .auth-style .ant-layout-sider-zero-width-trigger:hover {
    background: #192c3e; }
  .auth-style .ant-layout-sider-light {
    background: #fff; }
  .auth-style .ant-layout-sider-light .ant-layout-sider-trigger,
  .auth-style .ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
    color: rgba(0, 0, 0, 0.65);
    background: #fff; }
  .auth-style .ant-list {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative; }
  .auth-style .ant-list * {
    outline: none; }
  .auth-style .ant-list-pagination {
    margin-top: 24px;
    text-align: right; }
  .auth-style .ant-list-more {
    margin-top: 12px;
    text-align: center; }
  .auth-style .ant-list-more button {
    padding-left: 32px;
    padding-right: 32px; }
  .auth-style .ant-list-spin {
    text-align: center;
    min-height: 40px; }
  .auth-style .ant-list-empty-text {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    padding: 16px;
    text-align: center; }
  .auth-style .ant-list-item {
    align-items: center;
    padding: 12px 0; }
  .auth-style .ant-list-item,
  .auth-style .ant-list-item-meta {
    display: flex; }
  .auth-style .ant-list-item-meta {
    align-items: flex-start;
    flex: 1 1;
    font-size: 0; }
  .auth-style .ant-list-item-meta-avatar {
    margin-right: 16px; }
  .auth-style .ant-list-item-meta-content {
    flex: 1 0; }
  .auth-style .ant-list-item-meta-title {
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 22px; }
  .auth-style .ant-list-item-meta-title > a {
    color: rgba(0, 0, 0, 0.65);
    transition: all 0.3s; }
  .auth-style .ant-list-item-meta-title > a:hover {
    color: #1890ff; }
  .auth-style .ant-list-item-meta-description {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px; }
  .auth-style .ant-list-item-content {
    display: flex;
    flex: 1 1;
    justify-content: flex-end; }
  .auth-style .ant-list-item-content-single {
    justify-content: flex-start; }
  .auth-style .ant-list-item-action {
    font-size: 0;
    flex: 0 0 auto;
    margin-left: 48px;
    padding: 0;
    list-style: none; }
  .auth-style .ant-list-item-action > li {
    display: inline-block;
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    padding: 0 8px;
    position: relative;
    font-size: 14px;
    line-height: 22px;
    text-align: center; }
  .auth-style .ant-list-item-action > li:first-child {
    padding-left: 0; }
  .auth-style .ant-list-item-action-split {
    background-color: #e8e8e8;
    margin-top: -7px;
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 14px; }
  .auth-style .ant-list-item-main {
    display: flex;
    flex: 1 1; }
  .auth-style .ant-list-footer,
  .auth-style .ant-list-header {
    padding-top: 12px;
    padding-bottom: 12px; }
  .auth-style .ant-list-empty {
    color: rgba(0, 0, 0, 0.45);
    padding: 16px 0;
    font-size: 12px;
    text-align: center; }
  .auth-style .ant-list-split .ant-list-item {
    border-bottom: 1px solid #e8e8e8; }
  .auth-style .ant-list-split .ant-list-item:last-child {
    border-bottom: none; }
  .auth-style .ant-list-split .ant-list-header {
    border-bottom: 1px solid #e8e8e8; }
  .auth-style .ant-list-loading .ant-list-spin-nested-loading {
    min-height: 32px; }
  .auth-style .ant-list-something-after-last-item
.ant-spin-container
> .ant-list-item:last-child {
    border-bottom: 1px solid #e8e8e8; }
  .auth-style .ant-list-lg .ant-list-item {
    padding-top: 16px;
    padding-bottom: 16px; }
  .auth-style .ant-list-sm .ant-list-item {
    padding-top: 8px;
    padding-bottom: 8px; }
  .auth-style .ant-list-vertical .ant-list-item {
    display: block; }
  .auth-style .ant-list-vertical .ant-list-item-extra-wrap {
    display: flex; }
  .auth-style .ant-list-vertical .ant-list-item-main {
    display: block;
    flex: 1 1; }
  .auth-style .ant-list-vertical .ant-list-item-extra {
    margin-left: 58px; }
  .auth-style .ant-list-vertical .ant-list-item-meta {
    margin-bottom: 16px; }
  .auth-style .ant-list-vertical .ant-list-item-meta-title {
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 24px; }
  .auth-style .ant-list-vertical .ant-list-item-content {
    display: block;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    margin: 0 0 16px; }
  .auth-style .ant-list-vertical .ant-list-item-action {
    margin-left: auto; }
  .auth-style .ant-list-vertical .ant-list-item-action > li {
    padding: 0 16px; }
  .auth-style .ant-list-vertical .ant-list-item-action > li:first-child {
    padding-left: 0; }
  .auth-style .ant-list-grid .ant-list-item {
    border-bottom: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 16px; }
  .auth-style .ant-list-grid .ant-list-item-content {
    display: block;
    max-width: 100%; }
  .auth-style .ant-list-bordered {
    border-radius: 4px;
    border: 1px solid #d9d9d9; }
  .auth-style .ant-list-bordered .ant-list-footer,
  .auth-style .ant-list-bordered .ant-list-header,
  .auth-style .ant-list-bordered .ant-list-item {
    padding-left: 24px;
    padding-right: 24px; }
  .auth-style .ant-list-bordered .ant-list-item {
    border-bottom: 1px solid #e8e8e8; }
  .auth-style .ant-list-bordered .ant-list-pagination {
    margin: 16px 24px; }
  .auth-style .ant-list-bordered.ant-list-sm .ant-list-item {
    padding-left: 16px;
    padding-right: 16px; }
  .auth-style .ant-list-bordered.ant-list-sm .ant-list-footer,
  .auth-style .ant-list-bordered.ant-list-sm .ant-list-header {
    padding: 8px 16px; }
  .auth-style .ant-list-bordered.ant-list-lg .ant-list-footer,
  .auth-style .ant-list-bordered.ant-list-lg .ant-list-header {
    padding: 16px 24px; }
  @media screen and (max-width: 768px) {
    .auth-style .ant-list-item-action,
    .auth-style .ant-list-vertical .ant-list-item-extra {
      margin-left: 24px; } }
  @media screen and (max-width: 480px) {
    .auth-style .ant-list-item {
      flex-wrap: wrap; }
    .auth-style .ant-list-item-action {
      margin-left: 12px; }
    .auth-style .ant-list-vertical .ant-list-item-extra-wrap {
      flex-wrap: wrap-reverse; }
    .auth-style .ant-list-vertical .ant-list-item-main {
      min-width: 220px; }
    .auth-style .ant-list-vertical .ant-list-item-extra {
      margin-left: 0; } }
  .auth-style .ant-mention-wrapper {
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    box-sizing: border-box;
    margin: 0;
    list-style: none;
    display: inline-block;
    vertical-align: middle; }
  .auth-style .ant-mention-wrapper,
  .auth-style .ant-mention-wrapper .ant-mention-editor {
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    padding: 0;
    position: relative;
    width: 100%; }
  .auth-style .ant-mention-wrapper .ant-mention-editor {
    display: inline-block;
    height: 32px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all 0.3s;
    min-height: 32px;
    height: auto;
    display: block; }
  .auth-style .ant-mention-wrapper .ant-mention-editor::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1; }
  .auth-style .ant-mention-wrapper .ant-mention-editor:-ms-input-placeholder {
    color: #bfbfbf; }
  .auth-style .ant-mention-wrapper .ant-mention-editor::-webkit-input-placeholder {
    color: #bfbfbf; }
  .auth-style .ant-mention-wrapper .ant-mention-editor:focus,
  .auth-style .ant-mention-wrapper .ant-mention-editor:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important; }
  .auth-style .ant-mention-wrapper .ant-mention-editor:focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }
  .auth-style .ant-mention-wrapper .ant-mention-editor-disabled {
    background-color: #f5f5f5;
    opacity: 1;
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-mention-wrapper .ant-mention-editor-disabled:hover {
    border-color: #e6d8d8;
    border-right-width: 1px !important; }
  .auth-style textarea.ant-mention-wrapper .ant-mention-editor {
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
    min-height: 32px; }
  .auth-style .ant-mention-wrapper .ant-mention-editor-lg {
    padding: 6px 11px;
    height: 40px;
    font-size: 16px; }
  .auth-style .ant-mention-wrapper .ant-mention-editor-sm {
    padding: 1px 7px;
    height: 24px; }
  .auth-style .ant-mention-wrapper .ant-mention-editor-wrapper {
    overflow-y: auto;
    height: auto; }
  .auth-style .ant-mention-wrapper.ant-mention-active:not(.disabled) .ant-mention-editor {
    border-color: #40a9ff;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    border-right-width: 1px !important; }
  .auth-style .ant-mention-wrapper.disabled .ant-mention-editor {
    background-color: #f5f5f5;
    opacity: 1;
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-mention-wrapper.disabled .ant-mention-editor:hover {
    border-color: #e6d8d8;
    border-right-width: 1px !important; }
  .auth-style .ant-mention-wrapper .public-DraftEditorPlaceholder-root {
    position: absolute;
    pointer-events: none; }
  .auth-style .ant-mention-wrapper
.public-DraftEditorPlaceholder-root
.public-DraftEditorPlaceholder-inner {
    color: #bfbfbf;
    opacity: 1;
    outline: none;
    white-space: pre-wrap;
    word-wrap: break-word;
    height: auto;
    padding: 5px 11px; }
  .auth-style .ant-mention-wrapper
.DraftEditor-editorContainer
.public-DraftEditor-content {
    height: auto;
    padding: 5px 11px; }
  .auth-style .ant-mention-dropdown {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    padding: 0;
    list-style: none;
    margin: 1.5em 0 0;
    max-height: 250px;
    min-width: 120px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    z-index: 1050;
    left: -9999px;
    top: -9999px;
    position: absolute;
    outline: none;
    overflow-x: hidden;
    overflow-y: auto; }
  .auth-style .ant-mention-dropdown-placement-top {
    margin-top: -0.1em; }
  .auth-style .ant-mention-dropdown-notfound.ant-mention-dropdown-item {
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-mention-dropdown-notfound.ant-mention-dropdown-item .anticon-loading {
    color: #1890ff;
    text-align: center;
    display: block; }
  .auth-style .ant-mention-dropdown-item {
    position: relative;
    display: block;
    padding: 5px 12px;
    line-height: 22px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    white-space: nowrap;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: background 0.3s; }
  .auth-style .ant-mention-dropdown-item-active,
  .auth-style .ant-mention-dropdown-item.focus,
  .auth-style .ant-mention-dropdown-item:hover {
    background-color: #e6f7ff; }
  .auth-style .ant-mention-dropdown-item-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed; }
  .auth-style .ant-mention-dropdown-item-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: not-allowed; }
  .auth-style .ant-mention-dropdown-item-selected,
  .auth-style .ant-mention-dropdown-item-selected:hover {
    background-color: #f5f5f5;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.65); }
  .auth-style .ant-mention-dropdown-item-divider {
    height: 1px;
    margin: 1px 0;
    overflow: hidden;
    background-color: #e8e8e8;
    line-height: 0; }
  .auth-style .ant-menu {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    box-sizing: border-box;
    outline: none;
    margin: 0;
    padding: 0;
    list-style: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    line-height: 0;
    transition: background 0.3s, width 0.2s;
    zoom: 1; }
  .auth-style .ant-menu:after,
  .auth-style .ant-menu:before {
    content: "";
    display: table; }
  .auth-style .ant-menu:after {
    clear: both; }
  .auth-style .ant-menu ol,
  .auth-style .ant-menu ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .auth-style .ant-menu-hidden {
    display: none; }
  .auth-style .ant-menu-item-group-title {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.5;
    padding: 8px 16px;
    transition: all 0.3s; }
  .auth-style .ant-menu-submenu,
  .auth-style .ant-menu-submenu-inline {
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .auth-style .ant-menu-item:active,
  .auth-style .ant-menu-submenu-title:active {
    background: #e6f7ff; }
  .auth-style .ant-menu-submenu .ant-menu-sub {
    cursor: auto;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .auth-style .ant-menu-item > a {
    display: block;
    color: rgba(0, 0, 0, 0.65); }
  .auth-style .ant-menu-item > a:hover {
    color: #1890ff; }
  .auth-style .ant-menu-item > a:focus {
    text-decoration: none; }
  .auth-style .ant-menu-item > a:before {
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: ""; }
  .auth-style .ant-menu-item-divider {
    height: 1px;
    overflow: hidden;
    background-color: #e8e8e8;
    line-height: 0; }
  .auth-style .ant-menu-item-active,
  .auth-style .ant-menu-item:hover,
  .auth-style .ant-menu-submenu-active,
  .auth-style .ant-menu-submenu-title:hover,
  .auth-style .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
    color: #1890ff; }
  .auth-style .ant-menu-horizontal .ant-menu-item,
  .auth-style .ant-menu-horizontal .ant-menu-submenu {
    margin-top: -1px; }
  .auth-style .ant-menu-horizontal > .ant-menu-item-active,
  .auth-style .ant-menu-horizontal > .ant-menu-item:hover,
  .auth-style .ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
    background-color: transparent; }
  .auth-style .ant-menu-item-selected,
  .auth-style .ant-menu-item-selected > a,
  .auth-style .ant-menu-item-selected > a:hover {
    color: #1890ff; }
  .auth-style .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #e6f7ff; }
  .auth-style .ant-menu-inline,
  .auth-style .ant-menu-vertical,
  .auth-style .ant-menu-vertical-left {
    border-right: 1px solid #e8e8e8; }
  .auth-style .ant-menu-vertical-right {
    border-left: 1px solid #e8e8e8; }
  .auth-style .ant-menu-vertical-left.ant-menu-sub,
  .auth-style .ant-menu-vertical-right.ant-menu-sub,
  .auth-style .ant-menu-vertical.ant-menu-sub {
    border-right: 0;
    padding: 0;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0; }
  .auth-style .ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
  .auth-style .ant-menu-vertical-right.ant-menu-sub .ant-menu-item,
  .auth-style .ant-menu-vertical.ant-menu-sub .ant-menu-item {
    border-right: 0;
    margin-left: 0;
    left: 0; }
  .auth-style .ant-menu-vertical-left.ant-menu-sub .ant-menu-item:after,
  .auth-style .ant-menu-vertical-right.ant-menu-sub .ant-menu-item:after,
  .auth-style .ant-menu-vertical.ant-menu-sub .ant-menu-item:after {
    border-right: 0; }
  .auth-style .ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
  .auth-style .ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
  .auth-style .ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
  .auth-style .ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu,
  .auth-style .ant-menu-vertical.ant-menu-sub > .ant-menu-item,
  .auth-style .ant-menu-vertical.ant-menu-sub > .ant-menu-submenu {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0; }
  .auth-style .ant-menu-horizontal.ant-menu-sub,
  .auth-style .ant-menu-vertical-left.ant-menu-sub,
  .auth-style .ant-menu-vertical-right.ant-menu-sub,
  .auth-style .ant-menu-vertical.ant-menu-sub {
    min-width: 160px; }
  .auth-style .ant-menu-item,
  .auth-style .ant-menu-submenu-title {
    cursor: pointer;
    margin: 0;
    padding: 0 20px;
    position: relative;
    display: block;
    white-space: nowrap;
    transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .auth-style .ant-menu-item .anticon,
  .auth-style .ant-menu-submenu-title .anticon {
    min-width: 14px;
    margin-right: 10px;
    font-size: 14px;
    transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .auth-style .ant-menu-item .anticon + span,
  .auth-style .ant-menu-submenu-title .anticon + span {
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 1; }
  .auth-style .ant-menu > .ant-menu-item-divider {
    height: 1px;
    margin: 1px 0;
    overflow: hidden;
    padding: 0;
    line-height: 0;
    background-color: #e8e8e8; }
  .auth-style .ant-menu-submenu-popup {
    position: absolute;
    border-radius: 4px;
    z-index: 1050;
    background: #fff; }
  .auth-style .ant-menu-submenu-popup .submenu-title-wrapper {
    padding-right: 20px; }
  .auth-style .ant-menu-submenu-popup:before {
    position: absolute;
    top: -7px;
    left: 0;
    right: 0;
    bottom: 0;
    content: " ";
    opacity: 0.0001; }
  .auth-style .ant-menu-submenu > .ant-menu {
    background-color: #fff;
    border-radius: 4px; }
  .auth-style .ant-menu-submenu > .ant-menu-submenu-title:after {
    transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .auth-style .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .auth-style .ant-menu-submenu-vertical-left
> .ant-menu-submenu-title
.ant-menu-submenu-arrow,
  .auth-style .ant-menu-submenu-vertical-right
> .ant-menu-submenu-title
.ant-menu-submenu-arrow,
  .auth-style .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    position: absolute;
    top: 50%;
    right: 16px;
    width: 10px; }
  .auth-style .ant-menu-submenu-inline
> .ant-menu-submenu-title
.ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-submenu-inline
> .ant-menu-submenu-title
.ant-menu-submenu-arrow:before,
  .auth-style .ant-menu-submenu-vertical-left
> .ant-menu-submenu-title
.ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-submenu-vertical-left
> .ant-menu-submenu-title
.ant-menu-submenu-arrow:before,
  .auth-style .ant-menu-submenu-vertical-right
> .ant-menu-submenu-title
.ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-submenu-vertical-right
> .ant-menu-submenu-title
.ant-menu-submenu-arrow:before,
  .auth-style .ant-menu-submenu-vertical
> .ant-menu-submenu-title
.ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-submenu-vertical
> .ant-menu-submenu-title
.ant-menu-submenu-arrow:before {
    content: "";
    position: absolute;
    vertical-align: baseline;
    background: #fff;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65));
    width: 6px;
    height: 1.5px;
    border-radius: 2px;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .auth-style .ant-menu-submenu-inline
> .ant-menu-submenu-title
.ant-menu-submenu-arrow:before,
  .auth-style .ant-menu-submenu-vertical-left
> .ant-menu-submenu-title
.ant-menu-submenu-arrow:before,
  .auth-style .ant-menu-submenu-vertical-right
> .ant-menu-submenu-title
.ant-menu-submenu-arrow:before,
  .auth-style .ant-menu-submenu-vertical
> .ant-menu-submenu-title
.ant-menu-submenu-arrow:before {
    -webkit-transform: rotate(45deg) translateY(-2px);
    transform: rotate(45deg) translateY(-2px); }
  .auth-style .ant-menu-submenu-inline
> .ant-menu-submenu-title
.ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-submenu-vertical-left
> .ant-menu-submenu-title
.ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-submenu-vertical-right
> .ant-menu-submenu-title
.ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-submenu-vertical
> .ant-menu-submenu-title
.ant-menu-submenu-arrow:after {
    -webkit-transform: rotate(-45deg) translateY(2px);
    transform: rotate(-45deg) translateY(2px); }
  .auth-style .ant-menu-submenu-inline
> .ant-menu-submenu-title:hover
.ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-submenu-inline
> .ant-menu-submenu-title:hover
.ant-menu-submenu-arrow:before,
  .auth-style .ant-menu-submenu-vertical-left
> .ant-menu-submenu-title:hover
.ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-submenu-vertical-left
> .ant-menu-submenu-title:hover
.ant-menu-submenu-arrow:before,
  .auth-style .ant-menu-submenu-vertical-right
> .ant-menu-submenu-title:hover
.ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-submenu-vertical-right
> .ant-menu-submenu-title:hover
.ant-menu-submenu-arrow:before,
  .auth-style .ant-menu-submenu-vertical
> .ant-menu-submenu-title:hover
.ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-submenu-vertical
> .ant-menu-submenu-title:hover
.ant-menu-submenu-arrow:before {
    background: linear-gradient(90deg, #1890ff, #1890ff); }
  .auth-style .ant-menu-submenu-inline
> .ant-menu-submenu-title
.ant-menu-submenu-arrow:before {
    -webkit-transform: rotate(-45deg) translateX(2px);
    transform: rotate(-45deg) translateX(2px); }
  .auth-style .ant-menu-submenu-inline
> .ant-menu-submenu-title
.ant-menu-submenu-arrow:after {
    -webkit-transform: rotate(45deg) translateX(-2px);
    transform: rotate(45deg) translateX(-2px); }
  .auth-style .ant-menu-submenu-open.ant-menu-submenu-inline
> .ant-menu-submenu-title
.ant-menu-submenu-arrow {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px); }
  .auth-style .ant-menu-submenu-open.ant-menu-submenu-inline
> .ant-menu-submenu-title
.ant-menu-submenu-arrow:after {
    -webkit-transform: rotate(-45deg) translateX(-2px);
    transform: rotate(-45deg) translateX(-2px); }
  .auth-style .ant-menu-submenu-open.ant-menu-submenu-inline
> .ant-menu-submenu-title
.ant-menu-submenu-arrow:before {
    -webkit-transform: rotate(45deg) translateX(2px);
    transform: rotate(45deg) translateX(2px); }
  .auth-style .ant-menu-vertical-left .ant-menu-submenu-selected,
  .auth-style .ant-menu-vertical-left .ant-menu-submenu-selected > a,
  .auth-style .ant-menu-vertical-right .ant-menu-submenu-selected,
  .auth-style .ant-menu-vertical-right .ant-menu-submenu-selected > a,
  .auth-style .ant-menu-vertical .ant-menu-submenu-selected,
  .auth-style .ant-menu-vertical .ant-menu-submenu-selected > a {
    color: #1890ff; }
  .auth-style .ant-menu-horizontal {
    border: 0;
    border-bottom: 1px solid #e8e8e8;
    box-shadow: none;
    line-height: 46px;
    white-space: nowrap; }
  .auth-style .ant-menu-horizontal > .ant-menu-item,
  .auth-style .ant-menu-horizontal > .ant-menu-submenu {
    position: relative;
    top: 1px;
    display: inline-block;
    vertical-align: bottom;
    border-bottom: 2px solid transparent; }
  .auth-style .ant-menu-horizontal > .ant-menu-item-active,
  .auth-style .ant-menu-horizontal > .ant-menu-item-open,
  .auth-style .ant-menu-horizontal > .ant-menu-item-selected,
  .auth-style .ant-menu-horizontal > .ant-menu-item:hover,
  .auth-style .ant-menu-horizontal > .ant-menu-submenu-active,
  .auth-style .ant-menu-horizontal > .ant-menu-submenu-open,
  .auth-style .ant-menu-horizontal > .ant-menu-submenu-selected,
  .auth-style .ant-menu-horizontal > .ant-menu-submenu:hover {
    border-bottom: 2px solid #1890ff;
    color: #1890ff; }
  .auth-style .ant-menu-horizontal > .ant-menu-item > a {
    display: block;
    color: rgba(0, 0, 0, 0.65); }
  .auth-style .ant-menu-horizontal > .ant-menu-item > a:hover {
    color: #1890ff; }
  .auth-style .ant-menu-horizontal > .ant-menu-item > a:before {
    bottom: -2px; }
  .auth-style .ant-menu-horizontal > .ant-menu-item-selected > a {
    color: #1890ff; }
  .auth-style .ant-menu-horizontal:after {
    content: " ";
    display: block;
    height: 0;
    clear: both; }
  .auth-style .ant-menu-inline .ant-menu-item,
  .auth-style .ant-menu-vertical-left .ant-menu-item,
  .auth-style .ant-menu-vertical-right .ant-menu-item,
  .auth-style .ant-menu-vertical .ant-menu-item {
    position: relative; }
  .auth-style .ant-menu-inline .ant-menu-item:after,
  .auth-style .ant-menu-vertical-left .ant-menu-item:after,
  .auth-style .ant-menu-vertical-right .ant-menu-item:after,
  .auth-style .ant-menu-vertical .ant-menu-item:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-right: 3px solid #1890ff;
    -webkit-transform: scaleY(0.0001);
    transform: scaleY(0.0001);
    opacity: 0;
    transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .auth-style .ant-menu-inline .ant-menu-item,
  .auth-style .ant-menu-inline .ant-menu-submenu-title,
  .auth-style .ant-menu-vertical-left .ant-menu-item,
  .auth-style .ant-menu-vertical-left .ant-menu-submenu-title,
  .auth-style .ant-menu-vertical-right .ant-menu-item,
  .auth-style .ant-menu-vertical-right .ant-menu-submenu-title,
  .auth-style .ant-menu-vertical .ant-menu-item,
  .auth-style .ant-menu-vertical .ant-menu-submenu-title {
    padding: 0 16px;
    font-size: 14px;
    line-height: 40px;
    height: 40px;
    margin-top: 4px;
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis; }
  .auth-style .ant-menu-inline .ant-menu-submenu,
  .auth-style .ant-menu-vertical-left .ant-menu-submenu,
  .auth-style .ant-menu-vertical-right .ant-menu-submenu,
  .auth-style .ant-menu-vertical .ant-menu-submenu {
    padding-bottom: 0.01px; }
  .auth-style .ant-menu-inline .ant-menu-item:not(:last-child),
  .auth-style .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .auth-style .ant-menu-vertical-right .ant-menu-item:not(:last-child),
  .auth-style .ant-menu-vertical .ant-menu-item:not(:last-child) {
    margin-bottom: 8px; }
  .auth-style .ant-menu-inline > .ant-menu-item,
  .auth-style .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
  .auth-style .ant-menu-vertical-left > .ant-menu-item,
  .auth-style .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
  .auth-style .ant-menu-vertical-right > .ant-menu-item,
  .auth-style .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
  .auth-style .ant-menu-vertical > .ant-menu-item,
  .auth-style .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
    line-height: 40px;
    height: 40px; }
  .auth-style .ant-menu-inline {
    width: 100%; }
  .auth-style .ant-menu-inline .ant-menu-item-selected:after,
  .auth-style .ant-menu-inline .ant-menu-selected:after {
    transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 1;
    -webkit-transform: scaleY(1);
    transform: scaleY(1); }
  .auth-style .ant-menu-inline .ant-menu-item,
  .auth-style .ant-menu-inline .ant-menu-submenu-title {
    width: calc(100% + 1px); }
  .auth-style .ant-menu-inline .ant-menu-submenu-title {
    padding-right: 34px; }
  .auth-style .ant-menu-inline-collapsed {
    width: 80px; }
  .auth-style .ant-menu-inline-collapsed > .ant-menu-item,
  .auth-style .ant-menu-inline-collapsed
> .ant-menu-item-group
> .ant-menu-item-group-list
> .ant-menu-item,
  .auth-style .ant-menu-inline-collapsed
> .ant-menu-item-group
> .ant-menu-item-group-list
> .ant-menu-submenu
> .ant-menu-submenu-title,
  .auth-style .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    left: 0;
    text-overflow: clip;
    padding: 0 32px !important; }
  .auth-style .ant-menu-inline-collapsed
> .ant-menu-item-group
> .ant-menu-item-group-list
> .ant-menu-item
.ant-menu-submenu-arrow,
  .auth-style .ant-menu-inline-collapsed
> .ant-menu-item-group
> .ant-menu-item-group-list
> .ant-menu-submenu
> .ant-menu-submenu-title
.ant-menu-submenu-arrow,
  .auth-style .ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
  .auth-style .ant-menu-inline-collapsed
> .ant-menu-submenu
> .ant-menu-submenu-title
.ant-menu-submenu-arrow {
    display: none; }
  .auth-style .ant-menu-inline-collapsed
> .ant-menu-item-group
> .ant-menu-item-group-list
> .ant-menu-item
.anticon,
  .auth-style .ant-menu-inline-collapsed
> .ant-menu-item-group
> .ant-menu-item-group-list
> .ant-menu-submenu
> .ant-menu-submenu-title
.anticon,
  .auth-style .ant-menu-inline-collapsed > .ant-menu-item .anticon,
  .auth-style .ant-menu-inline-collapsed
> .ant-menu-submenu
> .ant-menu-submenu-title
.anticon {
    font-size: 16px;
    line-height: 40px;
    margin: 0; }
  .auth-style .ant-menu-inline-collapsed
> .ant-menu-item-group
> .ant-menu-item-group-list
> .ant-menu-item
.anticon
+ span,
  .auth-style .ant-menu-inline-collapsed
> .ant-menu-item-group
> .ant-menu-item-group-list
> .ant-menu-submenu
> .ant-menu-submenu-title
.anticon
+ span,
  .auth-style .ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
  .auth-style .ant-menu-inline-collapsed
> .ant-menu-submenu
> .ant-menu-submenu-title
.anticon
+ span {
    max-width: 0;
    display: inline-block;
    opacity: 0; }
  .auth-style .ant-menu-inline-collapsed-tooltip {
    pointer-events: none; }
  .auth-style .ant-menu-inline-collapsed-tooltip .anticon {
    display: none; }
  .auth-style .ant-menu-inline-collapsed-tooltip a {
    color: rgba(255, 255, 255, 0.85); }
  .auth-style .ant-menu-inline-collapsed .ant-menu-item-group-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-left: 4px;
    padding-right: 4px; }
  .auth-style .ant-menu-item-group-list {
    margin: 0;
    padding: 0; }
  .auth-style .ant-menu-item-group-list .ant-menu-item,
  .auth-style .ant-menu-item-group-list .ant-menu-submenu-title {
    padding: 0 16px 0 28px; }
  .auth-style .ant-menu-root.ant-menu-inline,
  .auth-style .ant-menu-root.ant-menu-vertical,
  .auth-style .ant-menu-root.ant-menu-vertical-left,
  .auth-style .ant-menu-root.ant-menu-vertical-right,
  .auth-style .ant-menu-sub.ant-menu-inline {
    box-shadow: none; }
  .auth-style .ant-menu-sub.ant-menu-inline {
    padding: 0;
    border: 0;
    border-radius: 0; }
  .auth-style .ant-menu-sub.ant-menu-inline > .ant-menu-item,
  .auth-style .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    line-height: 40px;
    height: 40px;
    list-style-type: disc;
    list-style-position: inside; }
  .auth-style .ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
    padding-left: 32px; }
  .auth-style .ant-menu-item-disabled,
  .auth-style .ant-menu-submenu-disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    cursor: not-allowed;
    background: none;
    border-color: transparent !important; }
  .auth-style .ant-menu-item-disabled > a,
  .auth-style .ant-menu-submenu-disabled > a {
    color: rgba(0, 0, 0, 0.25) !important;
    pointer-events: none; }
  .auth-style .ant-menu-item-disabled > .ant-menu-submenu-title,
  .auth-style .ant-menu-submenu-disabled > .ant-menu-submenu-title {
    color: rgba(0, 0, 0, 0.25) !important;
    cursor: not-allowed; }
  .auth-style .ant-menu-item-disabled
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-item-disabled
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow:before,
  .auth-style .ant-menu-submenu-disabled
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-submenu-disabled
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow:before {
    background: rgba(0, 0, 0, 0.25) !important; }
  .auth-style .ant-menu-dark,
  .auth-style .ant-menu-dark .ant-menu-sub {
    color: rgba(255, 255, 255, 0.65);
    background: #001529; }
  .auth-style .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .auth-style .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow {
    opacity: 0.45;
    transition: all 0.3s; }
  .auth-style .ant-menu-dark
.ant-menu-sub
.ant-menu-submenu-title
.ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-dark
.ant-menu-sub
.ant-menu-submenu-title
.ant-menu-submenu-arrow:before,
  .auth-style .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
    background: #fff; }
  .auth-style .ant-menu-dark.ant-menu-submenu-popup {
    background: transparent; }
  .auth-style .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #000c17;
    box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.45); }
  .auth-style .ant-menu-dark.ant-menu-horizontal {
    border-bottom: 0; }
  .auth-style .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
  .auth-style .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
    border-color: #001529;
    border-bottom: 0;
    top: 0;
    margin-top: 0; }
  .auth-style .ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a:before {
    bottom: 0; }
  .auth-style .ant-menu-dark .ant-menu-item,
  .auth-style .ant-menu-dark .ant-menu-item-group-title,
  .auth-style .ant-menu-dark .ant-menu-item > a {
    color: rgba(255, 255, 255, 0.65); }
  .auth-style .ant-menu-dark.ant-menu-inline,
  .auth-style .ant-menu-dark.ant-menu-vertical,
  .auth-style .ant-menu-dark.ant-menu-vertical-left,
  .auth-style .ant-menu-dark.ant-menu-vertical-right {
    border-right: 0; }
  .auth-style .ant-menu-dark.ant-menu-inline .ant-menu-item,
  .auth-style .ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
  .auth-style .ant-menu-dark.ant-menu-vertical-right .ant-menu-item,
  .auth-style .ant-menu-dark.ant-menu-vertical .ant-menu-item {
    border-right: 0;
    margin-left: 0;
    left: 0; }
  .auth-style .ant-menu-dark.ant-menu-inline .ant-menu-item:after,
  .auth-style .ant-menu-dark.ant-menu-vertical-left .ant-menu-item:after,
  .auth-style .ant-menu-dark.ant-menu-vertical-right .ant-menu-item:after,
  .auth-style .ant-menu-dark.ant-menu-vertical .ant-menu-item:after {
    border-right: 0; }
  .auth-style .ant-menu-dark.ant-menu-inline .ant-menu-item,
  .auth-style .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
    width: 100%; }
  .auth-style .ant-menu-dark .ant-menu-item-active,
  .auth-style .ant-menu-dark .ant-menu-item:hover,
  .auth-style .ant-menu-dark .ant-menu-submenu-active,
  .auth-style .ant-menu-dark .ant-menu-submenu-open,
  .auth-style .ant-menu-dark .ant-menu-submenu-selected,
  .auth-style .ant-menu-dark .ant-menu-submenu-title:hover {
    background-color: transparent;
    color: #fff; }
  .auth-style .ant-menu-dark .ant-menu-item-active > a,
  .auth-style .ant-menu-dark .ant-menu-item:hover > a,
  .auth-style .ant-menu-dark .ant-menu-submenu-active > a,
  .auth-style .ant-menu-dark .ant-menu-submenu-open > a,
  .auth-style .ant-menu-dark .ant-menu-submenu-selected > a,
  .auth-style .ant-menu-dark .ant-menu-submenu-title:hover > a {
    color: #fff; }
  .auth-style .ant-menu-dark
.ant-menu-item-active
> .ant-menu-submenu-title:hover
> .ant-menu-submenu-arrow,
  .auth-style .ant-menu-dark
.ant-menu-item-active
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow,
  .auth-style .ant-menu-dark
.ant-menu-item:hover
> .ant-menu-submenu-title:hover
> .ant-menu-submenu-arrow,
  .auth-style .ant-menu-dark
.ant-menu-item:hover
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow,
  .auth-style .ant-menu-dark
.ant-menu-submenu-active
> .ant-menu-submenu-title:hover
> .ant-menu-submenu-arrow,
  .auth-style .ant-menu-dark
.ant-menu-submenu-active
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow,
  .auth-style .ant-menu-dark
.ant-menu-submenu-open
> .ant-menu-submenu-title:hover
> .ant-menu-submenu-arrow,
  .auth-style .ant-menu-dark
.ant-menu-submenu-open
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow,
  .auth-style .ant-menu-dark
.ant-menu-submenu-selected
> .ant-menu-submenu-title:hover
> .ant-menu-submenu-arrow,
  .auth-style .ant-menu-dark
.ant-menu-submenu-selected
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow,
  .auth-style .ant-menu-dark
.ant-menu-submenu-title:hover
> .ant-menu-submenu-title:hover
> .ant-menu-submenu-arrow,
  .auth-style .ant-menu-dark
.ant-menu-submenu-title:hover
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow {
    opacity: 1; }
  .auth-style .ant-menu-dark
.ant-menu-item-active
> .ant-menu-submenu-title:hover
> .ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-dark
.ant-menu-item-active
> .ant-menu-submenu-title:hover
> .ant-menu-submenu-arrow:before,
  .auth-style .ant-menu-dark
.ant-menu-item-active
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-dark
.ant-menu-item-active
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow:before,
  .auth-style .ant-menu-dark
.ant-menu-item:hover
> .ant-menu-submenu-title:hover
> .ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-dark
.ant-menu-item:hover
> .ant-menu-submenu-title:hover
> .ant-menu-submenu-arrow:before,
  .auth-style .ant-menu-dark
.ant-menu-item:hover
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-dark
.ant-menu-item:hover
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow:before,
  .auth-style .ant-menu-dark
.ant-menu-submenu-active
> .ant-menu-submenu-title:hover
> .ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-dark
.ant-menu-submenu-active
> .ant-menu-submenu-title:hover
> .ant-menu-submenu-arrow:before,
  .auth-style .ant-menu-dark
.ant-menu-submenu-active
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-dark
.ant-menu-submenu-active
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow:before,
  .auth-style .ant-menu-dark
.ant-menu-submenu-open
> .ant-menu-submenu-title:hover
> .ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-dark
.ant-menu-submenu-open
> .ant-menu-submenu-title:hover
> .ant-menu-submenu-arrow:before,
  .auth-style .ant-menu-dark
.ant-menu-submenu-open
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-dark
.ant-menu-submenu-open
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow:before,
  .auth-style .ant-menu-dark
.ant-menu-submenu-selected
> .ant-menu-submenu-title:hover
> .ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-dark
.ant-menu-submenu-selected
> .ant-menu-submenu-title:hover
> .ant-menu-submenu-arrow:before,
  .auth-style .ant-menu-dark
.ant-menu-submenu-selected
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-dark
.ant-menu-submenu-selected
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow:before,
  .auth-style .ant-menu-dark
.ant-menu-submenu-title:hover
> .ant-menu-submenu-title:hover
> .ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-dark
.ant-menu-submenu-title:hover
> .ant-menu-submenu-title:hover
> .ant-menu-submenu-arrow:before,
  .auth-style .ant-menu-dark
.ant-menu-submenu-title:hover
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-dark
.ant-menu-submenu-title:hover
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow:before {
    background: #fff; }
  .auth-style .ant-menu-dark .ant-menu-item-selected {
    border-right: 0;
    color: #fff; }
  .auth-style .ant-menu-dark .ant-menu-item-selected:after {
    border-right: 0; }
  .auth-style .ant-menu-dark .ant-menu-item-selected > a,
  .auth-style .ant-menu-dark .ant-menu-item-selected > a:hover {
    color: #fff; }
  .auth-style .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
  .auth-style .ant-menu.ant-menu-dark .ant-menu-item-selected {
    background-color: #1890ff; }
  .auth-style .ant-menu-dark .ant-menu-item-disabled,
  .auth-style .ant-menu-dark .ant-menu-item-disabled > a,
  .auth-style .ant-menu-dark .ant-menu-submenu-disabled,
  .auth-style .ant-menu-dark .ant-menu-submenu-disabled > a {
    opacity: 0.8;
    color: rgba(255, 255, 255, 0.35) !important; }
  .auth-style .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
  .auth-style .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
    color: rgba(255, 255, 255, 0.35) !important; }
  .auth-style .ant-menu-dark
.ant-menu-item-disabled
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-dark
.ant-menu-item-disabled
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow:before,
  .auth-style .ant-menu-dark
.ant-menu-submenu-disabled
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow:after,
  .auth-style .ant-menu-dark
.ant-menu-submenu-disabled
> .ant-menu-submenu-title
> .ant-menu-submenu-arrow:before {
    background: rgba(255, 255, 255, 0.35) !important; }
  .auth-style .ant-message {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: fixed;
    z-index: 1010;
    width: 100%;
    top: 16px;
    left: 0;
    pointer-events: none; }
  .auth-style .ant-message-notice {
    padding: 8px;
    text-align: center; }
  .auth-style .ant-message-notice:first-child {
    margin-top: -8px; }
  .auth-style .ant-message-notice-content {
    padding: 10px 16px;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    background: #fff;
    display: inline-block;
    pointer-events: all; }
  .auth-style .ant-message-success .anticon {
    color: #52c41a; }
  .auth-style .ant-message-error .anticon {
    color: #f5222d; }
  .auth-style .ant-message-warning .anticon {
    color: #faad14; }
  .auth-style .ant-message-info .anticon,
  .auth-style .ant-message-loading .anticon {
    color: #1890ff; }
  .auth-style .ant-message .anticon {
    margin-right: 8px;
    font-size: 16px;
    top: 1px;
    position: relative; }
  .auth-style .ant-message-notice.move-up-leave.move-up-leave-active {
    -webkit-animation-name: MessageMoveOut;
    animation-name: MessageMoveOut;
    overflow: hidden;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s; }

@-webkit-keyframes MessageMoveOut {
  0% {
    opacity: 1;
    max-height: 150px;
    padding: 8px; }
  to {
    opacity: 0;
    max-height: 0;
    padding: 0; } }

@keyframes MessageMoveOut {
  0% {
    opacity: 1;
    max-height: 150px;
    padding: 8px; }
  to {
    opacity: 0;
    max-height: 0;
    padding: 0; } }
  .auth-style .ant-modal {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    list-style: none;
    position: relative;
    width: auto;
    margin: 0 auto;
    top: 100px;
    padding: 0 0 24px; }
  .auth-style .ant-modal-wrap {
    position: fixed;
    overflow: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
    outline: 0; }
  .auth-style .ant-modal-title {
    margin: 0;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85); }
  .auth-style .ant-modal-content {
    position: relative;
    background-color: #fff;
    border: 0;
    border-radius: 4px;
    background-clip: padding-box;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); }
  .auth-style .ant-modal-close {
    cursor: pointer;
    border: 0;
    background: transparent;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    transition: color 0.3s;
    color: rgba(0, 0, 0, 0.45);
    outline: 0;
    padding: 0; }
  .auth-style .ant-modal-close-x {
    display: block;
    font-style: normal;
    vertical-align: baseline;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
    width: 56px;
    height: 56px;
    line-height: 56px;
    font-size: 16px; }
  .auth-style .ant-modal-close:focus,
  .auth-style .ant-modal-close:hover {
    color: rgba(0, 0, 0, 0.75);
    text-decoration: none; }
  .auth-style .ant-modal-header {
    padding: 16px 24px;
    border-radius: 4px 4px 0 0;
    background: #fff;
    color: rgba(0, 0, 0, 0.65);
    border-bottom: 1px solid #e8e8e8; }
  .auth-style .ant-modal-body {
    padding: 24px;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word; }
  .auth-style .ant-modal-footer {
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    border-radius: 0 0 4px 4px; }
  .auth-style .ant-modal-footer button + button {
    margin-left: 8px;
    margin-bottom: 0; }
  .auth-style .ant-modal.zoom-appear,
  .auth-style .ant-modal.zoom-enter {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-transform: none;
    transform: none;
    opacity: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .auth-style .ant-modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.65);
    height: 100%;
    z-index: 1000;
    filter: alpha(opacity=50); }
  .auth-style .ant-modal-mask-hidden {
    display: none; }
  .auth-style .ant-modal-open {
    overflow: hidden; }
  .auth-style .ant-modal-centered {
    text-align: center; }
  .auth-style .ant-modal-centered:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    width: 0; }
  .auth-style .ant-modal-centered .ant-modal {
    display: inline-block;
    vertical-align: middle;
    top: 0;
    text-align: left; }
  @media (max-width: 767px) {
    .auth-style .ant-modal {
      max-width: calc(100vw - 16px);
      margin: 8px auto; }
    .auth-style .ant-modal-centered .ant-modal {
      flex: 1 1; } }
  .auth-style .ant-modal-confirm .ant-modal-close,
  .auth-style .ant-modal-confirm .ant-modal-header {
    display: none; }
  .auth-style .ant-modal-confirm .ant-modal-body {
    padding: 32px 32px 24px; }
  .auth-style .ant-modal-confirm-body-wrapper {
    zoom: 1; }
  .auth-style .ant-modal-confirm-body-wrapper:after,
  .auth-style .ant-modal-confirm-body-wrapper:before {
    content: "";
    display: table; }
  .auth-style .ant-modal-confirm-body-wrapper:after {
    clear: both; }
  .auth-style .ant-modal-confirm-body .ant-modal-confirm-title {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    line-height: 1.4;
    display: block;
    overflow: hidden; }
  .auth-style .ant-modal-confirm-body .ant-modal-confirm-content {
    margin-left: 38px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    margin-top: 8px; }
  .auth-style .ant-modal-confirm-body > .anticon {
    font-size: 22px;
    margin-right: 16px;
    float: left; }
  .auth-style .ant-modal-confirm .ant-modal-confirm-btns {
    margin-top: 24px;
    float: right; }
  .auth-style .ant-modal-confirm .ant-modal-confirm-btns button + button {
    margin-left: 8px;
    margin-bottom: 0; }
  .auth-style .ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
    color: #f5222d; }
  .auth-style .ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon,
  .auth-style .ant-modal-confirm-warning .ant-modal-confirm-body > .anticon {
    color: #faad14; }
  .auth-style .ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
    color: #1890ff; }
  .auth-style .ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
    color: #52c41a; }
  .auth-style .ant-notification {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    padding: 0;
    list-style: none;
    position: fixed;
    z-index: 1010;
    width: 384px;
    max-width: calc(100vw - 32px);
    margin: 0 24px 0 0; }
  .auth-style .ant-notification-bottomLeft,
  .auth-style .ant-notification-topLeft {
    margin-left: 24px;
    margin-right: 0; }
  .auth-style .ant-notification-bottomLeft
.ant-notification-fade-appear.ant-notification-fade-appear-active,
  .auth-style .ant-notification-bottomLeft
.ant-notification-fade-enter.ant-notification-fade-enter-active,
  .auth-style .ant-notification-topLeft
.ant-notification-fade-appear.ant-notification-fade-appear-active,
  .auth-style .ant-notification-topLeft
.ant-notification-fade-enter.ant-notification-fade-enter-active {
    -webkit-animation-name: NotificationLeftFadeIn;
    animation-name: NotificationLeftFadeIn; }
  .auth-style .ant-notification-close-icon {
    font-size: 14px;
    cursor: pointer; }
  .auth-style .ant-notification-notice {
    padding: 16px 24px;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    background: #fff;
    line-height: 1.5;
    position: relative;
    margin-bottom: 16px;
    overflow: hidden; }
  .auth-style .ant-notification-notice-message {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 8px;
    line-height: 24px;
    display: inline-block; }
  .auth-style .ant-notification-notice-message-single-line-auto-margin {
    width: calc(264px - 100%);
    background-color: transparent;
    pointer-events: none;
    display: block;
    max-width: 4px; }
  .auth-style .ant-notification-notice-message-single-line-auto-margin:before {
    content: "";
    display: block; }
  .auth-style .ant-notification-notice-description {
    font-size: 14px; }
  .auth-style .ant-notification-notice-closable .ant-notification-notice-message {
    padding-right: 24px; }
  .auth-style .ant-notification-notice-with-icon .ant-notification-notice-message {
    font-size: 16px;
    margin-left: 48px;
    margin-bottom: 4px; }
  .auth-style .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-left: 48px;
    font-size: 14px; }
  .auth-style .ant-notification-notice-icon {
    position: absolute;
    font-size: 24px;
    line-height: 24px;
    margin-left: 4px; }
  .auth-style .ant-notification-notice-icon-success {
    color: #52c41a; }
  .auth-style .ant-notification-notice-icon-info {
    color: #1890ff; }
  .auth-style .ant-notification-notice-icon-warning {
    color: #faad14; }
  .auth-style .ant-notification-notice-icon-error {
    color: #f5222d; }
  .auth-style .ant-notification-notice-close {
    position: absolute;
    right: 22px;
    top: 16px;
    color: rgba(0, 0, 0, 0.45);
    outline: none; }
  .auth-style a.ant-notification-notice-close:focus {
    text-decoration: none; }
  .auth-style .ant-notification-notice-close:hover {
    color: rgba(0, 0, 0, 0.67); }
  .auth-style .ant-notification-notice-btn {
    float: right;
    margin-top: 16px; }
  .auth-style .ant-notification .notification-fade-effect {
    -webkit-animation-duration: 0.24s;
    animation-duration: 0.24s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  .auth-style .ant-notification-fade-appear,
  .auth-style .ant-notification-fade-enter {
    opacity: 0;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .auth-style .ant-notification-fade-appear,
  .auth-style .ant-notification-fade-enter,
  .auth-style .ant-notification-fade-leave {
    -webkit-animation-duration: 0.24s;
    animation-duration: 0.24s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
    animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); }
  .auth-style .ant-notification-fade-leave {
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-play-state: paused;
    animation-play-state: paused; }
  .auth-style .ant-notification-fade-appear.ant-notification-fade-appear-active,
  .auth-style .ant-notification-fade-enter.ant-notification-fade-enter-active {
    -webkit-animation-name: NotificationFadeIn;
    animation-name: NotificationFadeIn;
    -webkit-animation-play-state: running;
    animation-play-state: running; }
  .auth-style .ant-notification-fade-leave.ant-notification-fade-leave-active {
    -webkit-animation-name: NotificationFadeOut;
    animation-name: NotificationFadeOut;
    -webkit-animation-play-state: running;
    animation-play-state: running; }

@-webkit-keyframes NotificationFadeIn {
  0% {
    opacity: 0;
    left: 384px; }
  to {
    left: 0;
    opacity: 1; } }

@keyframes NotificationFadeIn {
  0% {
    opacity: 0;
    left: 384px; }
  to {
    left: 0;
    opacity: 1; } }

@-webkit-keyframes NotificationLeftFadeIn {
  0% {
    opacity: 0;
    right: 384px; }
  to {
    right: 0;
    opacity: 1; } }

@keyframes NotificationLeftFadeIn {
  0% {
    opacity: 0;
    right: 384px; }
  to {
    right: 0;
    opacity: 1; } }

@-webkit-keyframes NotificationFadeOut {
  0% {
    opacity: 1;
    margin-bottom: 16px;
    padding-top: 16px 24px;
    padding-bottom: 16px 24px;
    max-height: 150px; }
  to {
    opacity: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    max-height: 0; } }

@keyframes NotificationFadeOut {
  0% {
    opacity: 1;
    margin-bottom: 16px;
    padding-top: 16px 24px;
    padding-bottom: 16px 24px;
    max-height: 150px; }
  to {
    opacity: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    max-height: 0; } }
  .auth-style .ant-pagination {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box; }
  .auth-style .ant-pagination,
  .auth-style .ant-pagination ol,
  .auth-style .ant-pagination ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  .auth-style .ant-pagination:after {
    content: " ";
    display: block;
    height: 0;
    clear: both;
    overflow: hidden;
    visibility: hidden; }
  .auth-style .ant-pagination-item,
  .auth-style .ant-pagination-total-text {
    display: inline-block;
    vertical-align: middle;
    height: 32px;
    line-height: 30px;
    margin-right: 8px; }
  .auth-style .ant-pagination-item {
    cursor: pointer;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-width: 32px;
    text-align: center;
    list-style: none;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    font-family: Arial;
    outline: 0; }
  .auth-style .ant-pagination-item a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.65);
    transition: none;
    margin: 0 6px; }
  .auth-style .ant-pagination-item:focus,
  .auth-style .ant-pagination-item:hover {
    transition: all 0.3s;
    border-color: #1890ff; }
  .auth-style .ant-pagination-item:focus a,
  .auth-style .ant-pagination-item:hover a {
    color: #1890ff; }
  .auth-style .ant-pagination-item-active {
    border-color: #1890ff;
    font-weight: 500; }
  .auth-style .ant-pagination-item-active a {
    color: #1890ff; }
  .auth-style .ant-pagination-item-active:focus,
  .auth-style .ant-pagination-item-active:hover {
    border-color: #40a9ff; }
  .auth-style .ant-pagination-item-active:focus a,
  .auth-style .ant-pagination-item-active:hover a {
    color: #40a9ff; }
  .auth-style .ant-pagination-jump-next,
  .auth-style .ant-pagination-jump-prev {
    outline: 0; }
  .auth-style .ant-pagination-jump-next .ant-pagination-item-container,
  .auth-style .ant-pagination-jump-prev .ant-pagination-item-container {
    position: relative; }
  .auth-style .ant-pagination-jump-next
.ant-pagination-item-container
.ant-pagination-item-link-icon,
  .auth-style .ant-pagination-jump-prev
.ant-pagination-item-container
.ant-pagination-item-link-icon {
    display: inline-block;
    font-size: 12px;
    font-size: 12px\9;
    -webkit-transform: scale(1) rotate(0deg);
    transform: scale(1) rotate(0deg);
    color: #1890ff;
    letter-spacing: -1px;
    opacity: 0;
    transition: all 0.2s; }
  .auth-style :root
.ant-pagination-jump-next
.ant-pagination-item-container
.ant-pagination-item-link-icon,
  .auth-style :root
.ant-pagination-jump-prev
.ant-pagination-item-container
.ant-pagination-item-link-icon {
    font-size: 12px; }
  .auth-style .ant-pagination-jump-next
.ant-pagination-item-container
.ant-pagination-item-link-icon-svg,
  .auth-style .ant-pagination-jump-prev
.ant-pagination-item-container
.ant-pagination-item-link-icon-svg {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto; }
  .auth-style .ant-pagination-jump-next
.ant-pagination-item-container
.ant-pagination-item-ellipsis,
  .auth-style .ant-pagination-jump-prev
.ant-pagination-item-container
.ant-pagination-item-ellipsis {
    position: absolute;
    display: block;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.25);
    text-align: center;
    opacity: 1;
    transition: all 0.2s;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto; }
  .auth-style .ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
  .auth-style .ant-pagination-jump-next:hover .ant-pagination-item-link-icon,
  .auth-style .ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
  .auth-style .ant-pagination-jump-prev:hover .ant-pagination-item-link-icon {
    opacity: 1; }
  .auth-style .ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
  .auth-style .ant-pagination-jump-next:hover .ant-pagination-item-ellipsis,
  .auth-style .ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
  .auth-style .ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis {
    opacity: 0; }
  .auth-style .ant-pagination-jump-next,
  .auth-style .ant-pagination-jump-prev,
  .auth-style .ant-pagination-prev {
    margin-right: 8px; }
  .auth-style .ant-pagination-jump-next,
  .auth-style .ant-pagination-jump-prev,
  .auth-style .ant-pagination-next,
  .auth-style .ant-pagination-prev {
    font-family: Arial;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.65);
    border-radius: 4px;
    list-style: none;
    min-width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    transition: all 0.3s;
    display: inline-block;
    vertical-align: middle; }
  .auth-style .ant-pagination-next,
  .auth-style .ant-pagination-prev {
    outline: 0; }
  .auth-style .ant-pagination-next a,
  .auth-style .ant-pagination-prev a {
    color: rgba(0, 0, 0, 0.65);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .auth-style .ant-pagination-next:hover a,
  .auth-style .ant-pagination-prev:hover a {
    border-color: #40a9ff; }
  .auth-style .ant-pagination-next .ant-pagination-item-link,
  .auth-style .ant-pagination-prev .ant-pagination-item-link {
    border: 1px solid #d9d9d9;
    background-color: #fff;
    border-radius: 4px;
    outline: none;
    display: block;
    transition: all 0.3s;
    font-size: 12px;
    height: 100%;
    text-align: center; }
  .auth-style .ant-pagination-next:focus .ant-pagination-item-link,
  .auth-style .ant-pagination-next:hover .ant-pagination-item-link,
  .auth-style .ant-pagination-prev:focus .ant-pagination-item-link,
  .auth-style .ant-pagination-prev:hover .ant-pagination-item-link {
    border-color: #1890ff;
    color: #1890ff; }
  .auth-style .ant-pagination-disabled,
  .auth-style .ant-pagination-disabled:focus,
  .auth-style .ant-pagination-disabled:hover {
    cursor: not-allowed; }
  .auth-style .ant-pagination-disabled .ant-pagination-item-link,
  .auth-style .ant-pagination-disabled:focus .ant-pagination-item-link,
  .auth-style .ant-pagination-disabled:focus a,
  .auth-style .ant-pagination-disabled:hover .ant-pagination-item-link,
  .auth-style .ant-pagination-disabled:hover a,
  .auth-style .ant-pagination-disabled a {
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed; }
  .auth-style .ant-pagination-slash {
    margin: 0 10px 0 5px; }
  .auth-style .ant-pagination-options {
    display: inline-block;
    vertical-align: middle;
    margin-left: 16px; }
  .auth-style .ant-pagination-options-size-changer.ant-select {
    display: inline-block;
    width: auto;
    margin-right: 8px; }
  .auth-style .ant-pagination-options-quick-jumper {
    display: inline-block;
    vertical-align: top;
    height: 32px;
    line-height: 32px; }
  .auth-style .ant-pagination-options-quick-jumper input {
    position: relative;
    display: inline-block;
    padding: 4px 11px;
    width: 100%;
    height: 32px;
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all 0.3s;
    margin: 0 8px;
    width: 50px; }
  .auth-style .ant-pagination-options-quick-jumper input::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1; }
  .auth-style .ant-pagination-options-quick-jumper input:-ms-input-placeholder {
    color: #bfbfbf; }
  .auth-style .ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
    color: #bfbfbf; }
  .auth-style .ant-pagination-options-quick-jumper input:focus,
  .auth-style .ant-pagination-options-quick-jumper input:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important; }
  .auth-style .ant-pagination-options-quick-jumper input:focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }
  .auth-style .ant-pagination-options-quick-jumper input-disabled {
    background-color: #f5f5f5;
    opacity: 1;
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-pagination-options-quick-jumper input-disabled:hover {
    border-color: #e6d8d8;
    border-right-width: 1px !important; }
  .auth-style textarea.ant-pagination-options-quick-jumper input {
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
    min-height: 32px; }
  .auth-style .ant-pagination-options-quick-jumper input-lg {
    padding: 6px 11px;
    height: 40px;
    font-size: 16px; }
  .auth-style .ant-pagination-options-quick-jumper input-sm {
    padding: 1px 7px;
    height: 24px; }
  .auth-style .ant-pagination-simple .ant-pagination-next,
  .auth-style .ant-pagination-simple .ant-pagination-prev {
    height: 24px;
    line-height: 24px;
    vertical-align: top; }
  .auth-style .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link,
  .auth-style .ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link {
    border: 0;
    height: 24px; }
  .auth-style .ant-pagination-simple .ant-pagination-next .ant-pagination-item-link:after,
  .auth-style .ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link:after {
    height: 24px;
    line-height: 24px; }
  .auth-style .ant-pagination-simple .ant-pagination-simple-pager {
    display: inline-block;
    margin-right: 8px;
    height: 24px; }
  .auth-style .ant-pagination-simple .ant-pagination-simple-pager input {
    margin-right: 8px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    outline: none;
    padding: 0 6px;
    height: 100%;
    text-align: center;
    transition: border-color 0.3s; }
  .auth-style .ant-pagination-simple .ant-pagination-simple-pager input:hover {
    border-color: #1890ff; }
  .auth-style .ant-pagination.mini .ant-pagination-simple-pager,
  .auth-style .ant-pagination.mini .ant-pagination-total-text {
    height: 24px;
    line-height: 24px; }
  .auth-style .ant-pagination.mini .ant-pagination-item {
    margin: 0;
    min-width: 24px;
    height: 24px;
    line-height: 22px; }
  .auth-style .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
    background: transparent;
    border-color: transparent; }
  .auth-style .ant-pagination.mini .ant-pagination-next,
  .auth-style .ant-pagination.mini .ant-pagination-prev {
    margin: 0;
    min-width: 24px;
    height: 24px;
    line-height: 24px; }
  .auth-style .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link,
  .auth-style .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link {
    border-color: transparent;
    background: transparent; }
  .auth-style .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link:after,
  .auth-style .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link:after {
    height: 24px;
    line-height: 24px; }
  .auth-style .ant-pagination.mini .ant-pagination-jump-next,
  .auth-style .ant-pagination.mini .ant-pagination-jump-prev {
    height: 24px;
    line-height: 24px;
    margin-right: 0; }
  .auth-style .ant-pagination.mini .ant-pagination-options {
    margin-left: 2px; }
  .auth-style .ant-pagination.mini .ant-pagination-options-quick-jumper {
    height: 24px;
    line-height: 24px; }
  .auth-style .ant-pagination.mini .ant-pagination-options-quick-jumper input {
    padding: 1px 7px;
    height: 24px;
    width: 44px; }
  @media only screen and (max-width: 992px) {
    .auth-style .ant-pagination-item-after-jump-prev,
    .auth-style .ant-pagination-item-before-jump-next {
      display: none; } }
  @media only screen and (max-width: 576px) {
    .auth-style .ant-pagination-options {
      display: none; } }
  .auth-style .ant-popover {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1030;
    cursor: auto;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    white-space: normal;
    font-weight: 400;
    text-align: left; }
  .auth-style .ant-popover:after {
    content: "";
    position: absolute;
    background: rgba(255, 255, 255, 0.01); }
  .auth-style .ant-popover-hidden {
    display: none; }
  .auth-style .ant-popover-placement-top,
  .auth-style .ant-popover-placement-topLeft,
  .auth-style .ant-popover-placement-topRight {
    padding-bottom: 10px; }
  .auth-style .ant-popover-placement-right,
  .auth-style .ant-popover-placement-rightBottom,
  .auth-style .ant-popover-placement-rightTop {
    padding-left: 10px; }
  .auth-style .ant-popover-placement-bottom,
  .auth-style .ant-popover-placement-bottomLeft,
  .auth-style .ant-popover-placement-bottomRight {
    padding-top: 10px; }
  .auth-style .ant-popover-placement-left,
  .auth-style .ant-popover-placement-leftBottom,
  .auth-style .ant-popover-placement-leftTop {
    padding-right: 10px; }
  .auth-style .ant-popover-inner {
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9; }
  @media (-ms-high-contrast: none), screen and (-ms-high-contrast: active) {
    .auth-style .ant-popover-inner {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); } }
  .auth-style .ant-popover-title {
    min-width: 177px;
    margin: 0;
    padding: 5px 16px 4px;
    min-height: 32px;
    border-bottom: 1px solid #e8e8e8;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500; }
  .auth-style .ant-popover-inner-content {
    padding: 12px 16px;
    color: rgba(0, 0, 0, 0.65); }
  .auth-style .ant-popover-message {
    padding: 4px 0 12px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    position: relative; }
  .auth-style .ant-popover-message > .anticon {
    position: absolute;
    top: 8px;
    color: #faad14;
    font-size: 14px; }
  .auth-style .ant-popover-message-title {
    padding-left: 22px; }
  .auth-style .ant-popover-buttons {
    text-align: right;
    margin-bottom: 4px; }
  .auth-style .ant-popover-buttons button {
    margin-left: 8px; }
  .auth-style .ant-popover-arrow {
    background: transparent;
    width: 8.48528137px;
    height: 8.48528137px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    display: block;
    border-width: 4.24264069px;
    border-style: solid; }
  .auth-style .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
  .auth-style .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
  .auth-style .ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
    bottom: 6.2px;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    border-color: transparent #fff #fff transparent; }
  .auth-style .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(45deg);
    transform: translateX(-50%) rotate(45deg); }
  .auth-style .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
    left: 16px; }
  .auth-style .ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
    right: 16px; }
  .auth-style .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
  .auth-style .ant-popover-placement-rightBottom
> .ant-popover-content
> .ant-popover-arrow,
  .auth-style .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
    left: 6px;
    box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
    border-color: transparent transparent #fff #fff; }
  .auth-style .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg); }
  .auth-style .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
    top: 12px; }
  .auth-style .ant-popover-placement-rightBottom
> .ant-popover-content
> .ant-popover-arrow {
    bottom: 12px; }
  .auth-style .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
  .auth-style .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
  .auth-style .ant-popover-placement-bottomRight
> .ant-popover-content
> .ant-popover-arrow {
    top: 6px;
    box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
    border-color: #fff transparent transparent #fff; }
  .auth-style .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(45deg);
    transform: translateX(-50%) rotate(45deg); }
  .auth-style .ant-popover-placement-bottomLeft
> .ant-popover-content
> .ant-popover-arrow {
    left: 16px; }
  .auth-style .ant-popover-placement-bottomRight
> .ant-popover-content
> .ant-popover-arrow {
    right: 16px; }
  .auth-style .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
  .auth-style .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow,
  .auth-style .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
    right: 6px;
    box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
    border-color: #fff #fff transparent transparent; }
  .auth-style .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg); }
  .auth-style .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
    top: 12px; }
  .auth-style .ant-popover-placement-leftBottom
> .ant-popover-content
> .ant-popover-arrow {
    bottom: 12px; }
  .auth-style .ant-progress {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block; }
  .auth-style .ant-progress-line {
    width: 100%;
    font-size: 14px;
    position: relative; }
  .auth-style .ant-progress-small.ant-progress-line,
  .auth-style .ant-progress-small.ant-progress-line .ant-progress-text .anticon {
    font-size: 12px; }
  .auth-style .ant-progress-outer {
    display: inline-block;
    width: 100%;
    margin-right: 0;
    padding-right: 0; }
  .auth-style .ant-progress-show-info .ant-progress-outer {
    padding-right: calc(2em + 8px);
    margin-right: calc(-2em - 8px); }
  .auth-style .ant-progress-inner {
    display: inline-block;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 100px;
    vertical-align: middle;
    position: relative; }
  .auth-style .ant-progress-circle-trail {
    stroke: #f5f5f5; }
  .auth-style .ant-progress-circle-path {
    stroke: #1890ff;
    -webkit-animation: ant-progress-appear 0.3s;
    animation: ant-progress-appear 0.3s; }
  .auth-style .ant-progress-bg,
  .auth-style .ant-progress-success-bg {
    background-color: #1890ff;
    transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
    position: relative; }
  .auth-style .ant-progress-success-bg {
    background-color: #52c41a;
    position: absolute;
    top: 0;
    left: 0; }
  .auth-style .ant-progress-text {
    word-break: normal;
    width: 2em;
    text-align: left;
    font-size: 1em;
    margin-left: 8px;
    vertical-align: middle;
    display: inline-block;
    white-space: nowrap;
    color: rgba(0, 0, 0, 0.45);
    line-height: 1; }
  .auth-style .ant-progress-text .anticon {
    font-size: 14px; }
  .auth-style .ant-progress-status-active .ant-progress-bg:before {
    content: "";
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    border-radius: 10px;
    -webkit-animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
    animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite; }
  .auth-style .ant-progress-status-exception .ant-progress-bg {
    background-color: #f5222d; }
  .auth-style .ant-progress-status-exception .ant-progress-text {
    color: #f5222d; }
  .auth-style .ant-progress-status-exception .ant-progress-circle-path {
    stroke: #f5222d; }
  .auth-style .ant-progress-status-success .ant-progress-bg {
    background-color: #52c41a; }
  .auth-style .ant-progress-status-success .ant-progress-text {
    color: #52c41a; }
  .auth-style .ant-progress-status-success .ant-progress-circle-path {
    stroke: #52c41a; }
  .auth-style .ant-progress-circle .ant-progress-inner {
    position: relative;
    line-height: 1;
    background-color: transparent; }
  .auth-style .ant-progress-circle .ant-progress-text {
    position: absolute;
    width: 100%;
    text-align: center;
    line-height: 1;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    white-space: normal; }
  .auth-style .ant-progress-circle .ant-progress-text .anticon {
    font-size: 1.16666667em; }
  .auth-style .ant-progress-circle.ant-progress-status-exception .ant-progress-text {
    color: #f5222d; }
  .auth-style .ant-progress-circle.ant-progress-status-success .ant-progress-text {
    color: #52c41a; }

@-webkit-keyframes ant-progress-active {
  0% {
    opacity: 0.1;
    width: 0; }
  20% {
    opacity: 0.5;
    width: 0; }
  to {
    opacity: 0;
    width: 100%; } }

@keyframes ant-progress-active {
  0% {
    opacity: 0.1;
    width: 0; }
  20% {
    opacity: 0.5;
    width: 0; }
  to {
    opacity: 0;
    width: 100%; } }
  .auth-style .ant-radio-group {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block;
    line-height: unset; }
  .auth-style .ant-radio-wrapper {
    margin: 0 8px 0 0; }
  .auth-style .ant-radio,
  .auth-style .ant-radio-wrapper {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    padding: 0;
    list-style: none;
    display: inline-block;
    position: relative;
    white-space: nowrap;
    cursor: pointer; }
  .auth-style .ant-radio {
    margin: 0;
    outline: none;
    line-height: 1;
    vertical-align: sub; }
  .auth-style .ant-radio-input:focus + .ant-radio-inner,
  .auth-style .ant-radio-wrapper:hover .ant-radio,
  .auth-style .ant-radio:hover .ant-radio-inner {
    border-color: #1890ff; }
  .auth-style .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08); }
  .auth-style .ant-radio-checked:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid #1890ff;
    content: "";
    -webkit-animation: antRadioEffect 0.36s ease-in-out;
    animation: antRadioEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: hidden; }
  .auth-style .ant-radio-wrapper:hover .ant-radio:after,
  .auth-style .ant-radio:hover:after {
    visibility: visible; }
  .auth-style .ant-radio-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 100px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    transition: all 0.3s; }
  .auth-style .ant-radio-inner:after {
    position: absolute;
    width: 8px;
    height: 8px;
    left: 3px;
    top: 3px;
    border-radius: 8px;
    display: table;
    border-top: 0;
    border-left: 0;
    content: " ";
    background-color: #1890ff;
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86); }
  .auth-style .ant-radio-input {
    position: absolute;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    top: 0;
    bottom: 0;
    right: 0; }
  .auth-style .ant-radio-checked .ant-radio-inner {
    border-color: #1890ff; }
  .auth-style .ant-radio-checked .ant-radio-inner:after {
    -webkit-transform: scale(0.875);
    transform: scale(0.875);
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86); }
  .auth-style .ant-radio-disabled .ant-radio-inner {
    border-color: #d9d9d9 !important;
    background-color: #f5f5f5;
    cursor: not-allowed; }
  .auth-style .ant-radio-disabled .ant-radio-inner:after {
    background-color: rgba(0, 0, 0, 0.2); }
  .auth-style .ant-radio-disabled .ant-radio-input {
    cursor: not-allowed; }
  .auth-style .ant-radio-disabled + span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed; }
  .auth-style span.ant-radio + * {
    padding-left: 8px;
    padding-right: 8px; }
  .auth-style .ant-radio-button-wrapper {
    margin: 0;
    height: 32px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.65);
    display: inline-block;
    transition: color 0.3s, background 0.3s, border-color 0.3s;
    cursor: pointer;
    border-bottom: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    border-left: 0;
    border-top: 1.02px solid #d9d9d9;
    background: #fff;
    padding: 0 15px;
    position: relative; }
  .auth-style .ant-radio-button-wrapper a {
    color: rgba(0, 0, 0, 0.65); }
  .auth-style .ant-radio-button-wrapper > .ant-radio-button {
    margin-left: 0;
    display: block;
    width: 0;
    height: 0; }
  .auth-style .ant-radio-group-large .ant-radio-button-wrapper {
    height: 40px;
    line-height: 38px;
    font-size: 16px; }
  .auth-style .ant-radio-group-small .ant-radio-button-wrapper {
    height: 24px;
    line-height: 22px;
    padding: 0 7px; }
  .auth-style .ant-radio-button-wrapper:not(:first-child):before {
    content: "";
    display: block;
    top: 0;
    left: -1px;
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9d9; }
  .auth-style .ant-radio-button-wrapper:first-child {
    border-radius: 4px 0 0 4px;
    border-left: 1px solid #d9d9d9; }
  .auth-style .ant-radio-button-wrapper:last-child {
    border-radius: 0 4px 4px 0; }
  .auth-style .ant-radio-button-wrapper:first-child:last-child {
    border-radius: 4px; }
  .auth-style .ant-radio-button-wrapper:hover {
    color: #1890ff;
    position: relative; }
  .auth-style .ant-radio-button-wrapper:focus-within {
    outline: 3px solid rgba(24, 144, 255, 0.06); }
  .auth-style .ant-radio-button-wrapper .ant-radio-inner,
  .auth-style .ant-radio-button-wrapper input[type="checkbox"],
  .auth-style .ant-radio-button-wrapper input[type="radio"] {
    opacity: 0;
    width: 0;
    height: 0;
    pointer-events: none; }
  .auth-style .ant-radio-button-wrapper-checked {
    background: #fff;
    border-color: #1890ff;
    color: #1890ff;
    box-shadow: -1px 0 0 0 #1890ff;
    z-index: 1; }
  .auth-style .ant-radio-button-wrapper-checked:before {
    background-color: #1890ff !important;
    opacity: 0.1; }
  .auth-style .ant-radio-button-wrapper-checked:first-child {
    border-color: #1890ff;
    box-shadow: none !important; }
  .auth-style .ant-radio-button-wrapper-checked:hover {
    border-color: #40a9ff;
    box-shadow: -1px 0 0 0 #40a9ff;
    color: #40a9ff; }
  .auth-style .ant-radio-button-wrapper-checked:active {
    border-color: #096dd9;
    box-shadow: -1px 0 0 0 #096dd9;
    color: #096dd9; }
  .auth-style .ant-radio-button-wrapper-checked:focus-within {
    outline: 3px solid rgba(24, 144, 255, 0.06); }
  .auth-style .ant-radio-group-solid
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #1890ff;
    border-color: #1890ff;
    color: #fff; }
  .auth-style .ant-radio-group-solid
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    border-color: #40a9ff;
    background: #40a9ff;
    color: #fff; }
  .auth-style .ant-radio-group-solid
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
    border-color: #096dd9;
    background: #096dd9;
    color: #fff; }
  .auth-style .ant-radio-group-solid
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    outline: 3px solid rgba(24, 144, 255, 0.06); }
  .auth-style .ant-radio-button-wrapper-disabled {
    cursor: not-allowed; }
  .auth-style .ant-radio-button-wrapper-disabled,
  .auth-style .ant-radio-button-wrapper-disabled:first-child,
  .auth-style .ant-radio-button-wrapper-disabled:hover {
    border-color: #d9d9d9;
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-radio-button-wrapper-disabled:first-child {
    border-left-color: #d9d9d9; }
  .auth-style .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
    color: #fff;
    background-color: #e6e6e6;
    border-color: #d9d9d9;
    box-shadow: none; }

@-webkit-keyframes antRadioEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  to {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0; } }

@keyframes antRadioEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  to {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0; } }
  @supports (-moz-appearance: meterbar) and (background-blend-mode: difference, normal) {
    .auth-style .ant-radio {
      vertical-align: text-bottom; } }
  .auth-style .ant-rate {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    line-height: unset;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 20px;
    display: inline-block;
    color: #fadb14;
    outline: none; }
  .auth-style .ant-rate-disabled .ant-rate-star {
    cursor: default; }
  .auth-style .ant-rate-disabled .ant-rate-star:hover {
    -webkit-transform: scale(1);
    transform: scale(1); }
  .auth-style .ant-rate-star {
    padding: 0;
    display: inline-block;
    margin: 0 8px 0 0;
    position: relative;
    transition: all 0.3s;
    color: inherit;
    cursor: pointer; }
  .auth-style .ant-rate-star > div:focus {
    outline: 0; }
  .auth-style .ant-rate-star > div:focus,
  .auth-style .ant-rate-star > div:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  .auth-style .ant-rate-star-first,
  .auth-style .ant-rate-star-second {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.3s;
    color: #e8e8e8; }
  .auth-style .ant-rate-star-first .anticon,
  .auth-style .ant-rate-star-second .anticon {
    vertical-align: middle; }
  .auth-style .ant-rate-star-first {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
    opacity: 0; }
  .auth-style .ant-rate-star-half .ant-rate-star-first,
  .auth-style .ant-rate-star-half .ant-rate-star-second {
    opacity: 1; }
  .auth-style .ant-rate-star-full .ant-rate-star-second,
  .auth-style .ant-rate-star-half .ant-rate-star-first {
    color: inherit; }
  .auth-style .ant-rate-text {
    margin-left: 8px;
    display: inline-block;
    font-size: 14px; }
  .auth-style .ant-select {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    outline: 0; }
  .auth-style .ant-select,
  .auth-style .ant-select ol,
  .auth-style .ant-select ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  .auth-style .ant-select > ul > li > a {
    padding: 0;
    background-color: #fff; }
  .auth-style .ant-select-arrow {
    display: inline-block;
    font-style: normal;
    vertical-align: -0.125em;
    text-align: center;
    text-transform: none;
    line-height: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    right: 11px;
    line-height: 1;
    margin-top: -6px;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px; }
  .auth-style .ant-select-arrow > * {
    line-height: 1; }
  .auth-style .ant-select-arrow svg {
    display: inline-block; }
  .auth-style .ant-select-arrow:before {
    display: none; }
  .auth-style .ant-select-arrow .ant-select-arrow-icon {
    display: block; }
  .auth-style .ant-select-arrow .ant-select-arrow-icon svg {
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s; }
  .auth-style .ant-select-selection {
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: border-box;
    display: block;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    border-top: 1.02px solid #d9d9d9;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .auth-style .ant-select-selection:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important; }
  .auth-style .ant-select-focused .ant-select-selection,
  .auth-style .ant-select-selection:active,
  .auth-style .ant-select-selection:focus {
    border-color: #40a9ff;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    border-right-width: 1px !important; }
  .auth-style .ant-select-selection__clear {
    display: inline-block;
    font-style: normal;
    vertical-align: baseline;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
    opacity: 0;
    position: absolute;
    right: 11px;
    z-index: 1;
    background: #fff;
    top: 50%;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.25);
    width: 12px;
    height: 12px;
    margin-top: -6px;
    line-height: 12px;
    cursor: pointer;
    transition: color 0.3s ease, opacity 0.15s ease; }
  .auth-style .ant-select-selection__clear:before {
    display: block; }
  .auth-style .ant-select-selection__clear:hover {
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-select-selection:hover .ant-select-selection__clear {
    opacity: 1; }
  .auth-style .ant-select-selection-selected-value {
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    padding-right: 20px; }
  .auth-style .ant-select-no-arrow .ant-select-selection-selected-value {
    padding-right: 0; }
  .auth-style .ant-select-disabled {
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-select-disabled .ant-select-selection {
    background: #f5f5f5;
    cursor: not-allowed; }
  .auth-style .ant-select-disabled .ant-select-selection:active,
  .auth-style .ant-select-disabled .ant-select-selection:focus,
  .auth-style .ant-select-disabled .ant-select-selection:hover {
    border-color: #d9d9d9;
    box-shadow: none; }
  .auth-style .ant-select-disabled .ant-select-selection__clear {
    display: none;
    visibility: hidden;
    pointer-events: none; }
  .auth-style .ant-select-disabled
.ant-select-selection--multiple
.ant-select-selection__choice {
    background: #f5f5f5;
    color: rgba(0, 0, 0, 0.33);
    padding-right: 10px; }
  .auth-style .ant-select-disabled
.ant-select-selection--multiple
.ant-select-selection__choice__remove {
    display: none; }
  .auth-style .ant-select-selection--single {
    height: 32px;
    position: relative;
    cursor: pointer; }
  .auth-style .ant-select-selection__rendered {
    display: block;
    margin-left: 11px;
    margin-right: 11px;
    position: relative;
    line-height: 30px; }
  .auth-style .ant-select-selection__rendered:after {
    content: ".";
    visibility: hidden;
    pointer-events: none;
    display: inline-block;
    width: 0; }
  .auth-style .ant-select-lg {
    font-size: 16px; }
  .auth-style .ant-select-lg .ant-select-selection--single {
    height: 40px; }
  .auth-style .ant-select-lg .ant-select-selection__rendered {
    line-height: 38px; }
  .auth-style .ant-select-lg .ant-select-selection--multiple {
    min-height: 40px; }
  .auth-style .ant-select-lg
.ant-select-selection--multiple
.ant-select-selection__rendered
li {
    height: 32px;
    line-height: 32px; }
  .auth-style .ant-select-lg .ant-select-selection--multiple .ant-select-selection__clear {
    top: 20px; }
  .auth-style .ant-select-sm .ant-select-selection--single {
    height: 24px; }
  .auth-style .ant-select-sm .ant-select-selection__rendered {
    line-height: 22px;
    margin: 0 7px; }
  .auth-style .ant-select-sm .ant-select-selection--multiple {
    min-height: 24px; }
  .auth-style .ant-select-sm
.ant-select-selection--multiple
.ant-select-selection__rendered
li {
    height: 16px;
    line-height: 14px; }
  .auth-style .ant-select-sm .ant-select-selection--multiple .ant-select-selection__clear {
    top: 12px; }
  .auth-style .ant-select-sm .ant-select-arrow,
  .auth-style .ant-select-sm .ant-select-selection__clear {
    right: 8px; }
  .auth-style .ant-select-disabled .ant-select-selection__choice__remove {
    color: rgba(0, 0, 0, 0.25);
    cursor: default; }
  .auth-style .ant-select-disabled .ant-select-selection__choice__remove:hover {
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-select-search__field__wrap {
    display: inline-block;
    position: relative; }
  .auth-style .ant-select-search__field__placeholder,
  .auth-style .ant-select-selection__placeholder {
    position: absolute;
    top: 50%;
    left: 0;
    right: 9px;
    color: #bfbfbf;
    line-height: 20px;
    height: 20px;
    max-width: 100%;
    margin-top: -10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left; }
  .auth-style .ant-select-search__field__placeholder {
    left: 12px; }
  .auth-style .ant-select-search__field__mirror {
    position: absolute;
    top: 0;
    left: 0;
    white-space: pre;
    pointer-events: none;
    opacity: 0; }
  .auth-style .ant-select-search--inline {
    position: absolute;
    height: 100%;
    width: 100%; }
  .auth-style .ant-select-search--inline .ant-select-search__field__wrap {
    width: 100%;
    height: 100%; }
  .auth-style .ant-select-search--inline .ant-select-search__field {
    border-width: 0;
    font-size: 100%;
    height: 100%;
    width: 100%;
    background: transparent;
    outline: 0;
    border-radius: 4px;
    line-height: 1; }
  .auth-style .ant-select-search--inline > i {
    float: right; }
  .auth-style .ant-select-selection--multiple {
    min-height: 32px;
    cursor: text;
    padding-bottom: 3px;
    zoom: 1; }
  .auth-style .ant-select-selection--multiple:after,
  .auth-style .ant-select-selection--multiple:before {
    content: "";
    display: table; }
  .auth-style .ant-select-selection--multiple:after {
    clear: both; }
  .auth-style .ant-select-selection--multiple .ant-select-search--inline {
    float: left;
    position: static;
    width: auto;
    padding: 0;
    max-width: 100%; }
  .auth-style .ant-select-selection--multiple
.ant-select-search--inline
.ant-select-search__field {
    max-width: 100%;
    width: 0.75em; }
  .auth-style .ant-select-selection--multiple .ant-select-selection__rendered {
    margin-left: 5px;
    margin-bottom: -3px;
    height: auto; }
  .auth-style .ant-select-selection--multiple .ant-select-selection__placeholder {
    margin-left: 6px; }
  .auth-style .ant-select-selection--multiple .ant-select-selection__rendered > ul > li,
  .auth-style .ant-select-selection--multiple > ul > li {
    margin-top: 3px;
    height: 24px;
    line-height: 22px; }
  .auth-style .ant-select-selection--multiple .ant-select-selection__choice {
    color: rgba(0, 0, 0, 0.65);
    background-color: #fafafa;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    cursor: default;
    float: left;
    margin-right: 4px;
    max-width: 99%;
    position: relative;
    overflow: hidden;
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 0 20px 0 10px; }
  .auth-style .ant-select-selection--multiple .ant-select-selection__choice__disabled {
    padding: 0 10px; }
  .auth-style .ant-select-selection--multiple .ant-select-selection__choice__content {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    transition: margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .auth-style .ant-select-selection--multiple .ant-select-selection__choice__remove {
    font-style: normal;
    vertical-align: -0.125em;
    text-align: center;
    text-transform: none;
    line-height: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgba(0, 0, 0, 0.45);
    line-height: inherit;
    cursor: pointer;
    font-weight: 700;
    transition: all 0.3s;
    display: inline-block;
    font-size: 12px;
    font-size: 10px\9;
    -webkit-transform: scale(0.83333) rotate(0deg);
    transform: scale(0.83333) rotate(0deg);
    position: absolute;
    right: 4px; }
  .auth-style .ant-select-selection--multiple .ant-select-selection__choice__remove > * {
    line-height: 1; }
  .auth-style .ant-select-selection--multiple .ant-select-selection__choice__remove svg {
    display: inline-block; }
  .auth-style .ant-select-selection--multiple .ant-select-selection__choice__remove:before {
    display: none; }
  .auth-style .ant-select-selection--multiple
.ant-select-selection__choice__remove
.ant-select-selection--multiple
.ant-select-selection__choice__remove-icon {
    display: block; }
  .auth-style :root .ant-select-selection--multiple .ant-select-selection__choice__remove {
    font-size: 12px; }
  .auth-style .ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
    color: rgba(0, 0, 0, 0.75); }
  .auth-style .ant-select-selection--multiple .ant-select-selection__clear {
    top: 16px; }
  .auth-style .ant-select-allow-clear
.ant-select-selection--single
.ant-select-selection-selected-value {
    padding-right: 16px; }
  .auth-style .ant-select-allow-clear
.ant-select-selection--multiple
.ant-select-selection__rendered {
    margin-right: 20px; }
  .auth-style .ant-select-open .ant-select-arrow-icon svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  .auth-style .ant-select-open .ant-select-selection {
    border-color: #40a9ff;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    border-right-width: 1px !important; }
  .auth-style .ant-select-combobox .ant-select-arrow {
    display: none; }
  .auth-style .ant-select-combobox .ant-select-search--inline {
    height: 100%;
    width: 100%;
    float: none; }
  .auth-style .ant-select-combobox .ant-select-search__field__wrap {
    width: 100%;
    height: 100%; }
  .auth-style .ant-select-combobox .ant-select-search__field {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0s;
    box-shadow: none; }
  .auth-style .ant-select-combobox.ant-select-allow-clear
.ant-select-selection:hover
.ant-select-selection__rendered {
    margin-right: 20px; }
  .auth-style .ant-select-dropdown {
    -webkit-font-feature-settings: "tnum", normal;
            font-feature-settings: "tnum", normal;
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    margin: 0;
    padding: 0;
    list-style: none;
    font-variant: normal;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-sizing: border-box;
    z-index: 1050;
    left: -9999px;
    top: -9999px;
    position: absolute;
    outline: none;
    font-size: 14px; }
  .auth-style .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft,
  .auth-style .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn; }
  .auth-style .ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft,
  .auth-style .ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn; }
  .auth-style .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut; }
  .auth-style .ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut; }
  .auth-style .ant-select-dropdown-hidden {
    display: none; }
  .auth-style .ant-select-dropdown-menu {
    outline: none;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    max-height: 250px;
    overflow: auto; }
  .auth-style .ant-select-dropdown-menu-item-group-list {
    margin: 0;
    padding: 0; }
  .auth-style .ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item {
    padding-left: 20px; }
  .auth-style .ant-select-dropdown-menu-item-group-title {
    color: rgba(0, 0, 0, 0.45);
    padding: 0 12px;
    height: 32px;
    line-height: 32px;
    font-size: 12px; }
  .auth-style .ant-select-dropdown-menu-item-group-list
.ant-select-dropdown-menu-item:first-child:not(:last-child),
  .auth-style .ant-select-dropdown-menu-item-group:not(:last-child)
.ant-select-dropdown-menu-item-group-list
.ant-select-dropdown-menu-item:last-child {
    border-radius: 0; }
  .auth-style .ant-select-dropdown-menu-item {
    position: relative;
    display: block;
    padding: 5px 12px;
    line-height: 22px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: background 0.3s ease; }
  .auth-style .ant-select-dropdown-menu-item:hover {
    background-color: #e6f7ff; }
  .auth-style .ant-select-dropdown-menu-item:first-child {
    border-radius: 4px 4px 0 0; }
  .auth-style .ant-select-dropdown-menu-item:last-child {
    border-radius: 0 0 4px 4px; }
  .auth-style .ant-select-dropdown-menu-item-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed; }
  .auth-style .ant-select-dropdown-menu-item-disabled:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #fff;
    cursor: not-allowed; }
  .auth-style .ant-select-dropdown-menu-item-selected,
  .auth-style .ant-select-dropdown-menu-item-selected:hover {
    background-color: #fafafa;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.65); }
  .auth-style .ant-select-dropdown-menu-item-active {
    background-color: #e6f7ff; }
  .auth-style .ant-select-dropdown-menu-item-divider {
    height: 1px;
    margin: 1px 0;
    overflow: hidden;
    background-color: #e8e8e8;
    line-height: 0; }
  .auth-style .ant-select-dropdown.ant-select-dropdown--multiple
.ant-select-dropdown-menu-item {
    padding-right: 32px; }
  .auth-style .ant-select-dropdown.ant-select-dropdown--multiple
.ant-select-dropdown-menu-item
.ant-select-selected-icon {
    color: transparent;
    display: inline-block;
    font-size: 12px;
    font-size: 10px\9;
    -webkit-transform: scale(0.83333) rotate(0deg);
    transform: scale(0.83333) rotate(0deg);
    transition: all 0.2s ease;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 12px;
    font-weight: 700;
    text-shadow: 0 0.1px 0, 0.1px 0 0, 0 -0.1px 0, -0.1px 0; }
  .auth-style :root
.ant-select-dropdown.ant-select-dropdown--multiple
.ant-select-dropdown-menu-item
.ant-select-selected-icon {
    font-size: 12px; }
  .auth-style .ant-select-dropdown.ant-select-dropdown--multiple
.ant-select-dropdown-menu-item:hover
.ant-select-selected-icon {
    color: rgba(0, 0, 0, 0.87); }
  .auth-style .ant-select-dropdown.ant-select-dropdown--multiple
.ant-select-dropdown-menu-item-disabled
.ant-select-selected-icon {
    display: none; }
  .auth-style .ant-select-dropdown.ant-select-dropdown--multiple
.ant-select-dropdown-menu-item-selected
.ant-select-selected-icon,
  .auth-style .ant-select-dropdown.ant-select-dropdown--multiple
.ant-select-dropdown-menu-item-selected:hover
.ant-select-selected-icon {
    color: #1890ff;
    display: inline-block; }
  .auth-style .ant-select-dropdown-container-open .ant-select-dropdown,
  .auth-style .ant-select-dropdown-open .ant-select-dropdown {
    display: block; }
  .auth-style .ant-skeleton {
    display: table;
    width: 100%; }
  .auth-style .ant-skeleton-header {
    display: table-cell;
    vertical-align: top;
    padding-right: 16px; }
  .auth-style .ant-skeleton-header .ant-skeleton-avatar {
    display: inline-block;
    vertical-align: top;
    background: #f2f2f2;
    width: 32px;
    height: 32px;
    line-height: 32px; }
  .auth-style .ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
    border-radius: 50%; }
  .auth-style .ant-skeleton-header .ant-skeleton-avatar-lg {
    width: 40px;
    height: 40px;
    line-height: 40px; }
  .auth-style .ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
    border-radius: 50%; }
  .auth-style .ant-skeleton-header .ant-skeleton-avatar-sm {
    width: 24px;
    height: 24px;
    line-height: 24px; }
  .auth-style .ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
    border-radius: 50%; }
  .auth-style .ant-skeleton-content {
    display: table-cell;
    vertical-align: top;
    width: 100%; }
  .auth-style .ant-skeleton-content .ant-skeleton-title {
    margin-top: 16px;
    height: 16px;
    width: 100%;
    background: #f2f2f2; }
  .auth-style .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
    margin-top: 24px; }
  .auth-style .ant-skeleton-content .ant-skeleton-paragraph > li {
    height: 16px;
    background: #f2f2f2;
    list-style: none;
    width: 100%; }
  .auth-style .ant-skeleton-content
.ant-skeleton-paragraph
> li:last-child:not(:first-child):not(:nth-child(2)) {
    width: 61%; }
  .auth-style .ant-skeleton-content .ant-skeleton-paragraph > li + li {
    margin-top: 16px; }
  .auth-style .ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
    margin-top: 12px; }
  .auth-style .ant-skeleton-with-avatar
.ant-skeleton-content
.ant-skeleton-title
+ .ant-skeleton-paragraph {
    margin-top: 28px; }
  .auth-style .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar,
  .auth-style .ant-skeleton.ant-skeleton-active
.ant-skeleton-content
.ant-skeleton-paragraph
> li,
  .auth-style .ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title {
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
    animation: ant-skeleton-loading 1.4s ease infinite;
    background-size: 400% 100%; }

@-webkit-keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%; }
  to {
    background-position: 0 50%; } }

@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%; }
  to {
    background-position: 0 50%; } }
  .auth-style .ant-slider {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    list-style: none;
    position: relative;
    margin: 14px 6px 10px;
    padding: 4px 0;
    height: 12px;
    cursor: pointer;
    touch-action: none; }
  .auth-style .ant-slider-vertical {
    width: 12px;
    height: 100%;
    margin: 6px 10px;
    padding: 0 4px; }
  .auth-style .ant-slider-vertical .ant-slider-rail {
    height: 100%;
    width: 4px; }
  .auth-style .ant-slider-vertical .ant-slider-track {
    width: 4px; }
  .auth-style .ant-slider-vertical .ant-slider-handle {
    margin-left: -5px;
    margin-bottom: -7px; }
  .auth-style .ant-slider-vertical .ant-slider-mark {
    top: 0;
    left: 12px;
    width: 18px;
    height: 100%; }
  .auth-style .ant-slider-vertical .ant-slider-mark-text {
    left: 4px;
    white-space: nowrap; }
  .auth-style .ant-slider-vertical .ant-slider-step {
    width: 4px;
    height: 100%; }
  .auth-style .ant-slider-vertical .ant-slider-dot {
    top: auto;
    left: 2px;
    margin-bottom: -4px; }
  .auth-style .ant-slider-with-marks {
    margin-bottom: 28px; }
  .auth-style .ant-slider-rail {
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: #f5f5f5;
    transition: background-color 0.3s; }
  .auth-style .ant-slider-track {
    position: absolute;
    height: 4px;
    border-radius: 4px;
    background-color: #91d5ff;
    transition: background-color 0.3s ease; }
  .auth-style .ant-slider-handle {
    position: absolute;
    margin-left: -7px;
    margin-top: -5px;
    width: 14px;
    height: 14px;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid #91d5ff;
    background-color: #fff;
    box-shadow: 0;
    transition: border-color 0.3s, box-shadow 0.6s, -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28), -webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28); }
  .auth-style .ant-slider-handle:focus {
    border-color: #46a6ff;
    box-shadow: 0 0 0 5px rgba(24, 144, 255, 0.2);
    outline: none; }
  .auth-style .ant-slider-handle.ant-tooltip-open {
    border-color: #1890ff; }
  .auth-style .ant-slider:hover .ant-slider-rail {
    background-color: #e1e1e1; }
  .auth-style .ant-slider:hover .ant-slider-track {
    background-color: #69c0ff; }
  .auth-style .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: #69c0ff; }
  .auth-style .ant-slider-mark {
    position: absolute;
    top: 14px;
    left: 0;
    width: 100%;
    font-size: 14px; }
  .auth-style .ant-slider-mark-text {
    position: absolute;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-slider-mark-text-active {
    color: rgba(0, 0, 0, 0.65); }
  .auth-style .ant-slider-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent; }
  .auth-style .ant-slider-dot {
    position: absolute;
    top: -2px;
    width: 8px;
    height: 8px;
    border: 2px solid #e8e8e8;
    background-color: #fff;
    cursor: pointer;
    border-radius: 50%;
    vertical-align: middle; }
  .auth-style .ant-slider-dot,
  .auth-style .ant-slider-dot:first-child,
  .auth-style .ant-slider-dot:last-child {
    margin-left: -4px; }
  .auth-style .ant-slider-dot-active {
    border-color: #8cc8ff; }
  .auth-style .ant-slider-disabled {
    cursor: not-allowed; }
  .auth-style .ant-slider-disabled .ant-slider-track {
    background-color: rgba(0, 0, 0, 0.25) !important; }
  .auth-style .ant-slider-disabled .ant-slider-dot,
  .auth-style .ant-slider-disabled .ant-slider-handle {
    border-color: rgba(0, 0, 0, 0.25) !important;
    background-color: #fff;
    cursor: not-allowed;
    box-shadow: none; }
  .auth-style .ant-slider-disabled .ant-slider-dot,
  .auth-style .ant-slider-disabled .ant-slider-mark-text {
    cursor: not-allowed !important; }
  .auth-style .ant-spin {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    color: #1890ff;
    vertical-align: middle;
    text-align: center;
    opacity: 0;
    position: absolute;
    transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    display: none; }
  .auth-style .ant-spin-spinning {
    opacity: 1;
    position: static;
    display: inline-block; }
  .auth-style .ant-spin-nested-loading {
    position: relative; }
  .auth-style .ant-spin-nested-loading > div > .ant-spin {
    display: block;
    position: absolute;
    height: 100%;
    max-height: 400px;
    width: 100%;
    z-index: 4; }
  .auth-style .ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -10px; }
  .auth-style .ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
    position: absolute;
    top: 50%;
    width: 100%;
    padding-top: 5px;
    text-shadow: 0 1px 2px #fff; }
  .auth-style .ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
    margin-top: -20px; }
  .auth-style .ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
    margin: -7px; }
  .auth-style .ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
    padding-top: 2px; }
  .auth-style .ant-spin-nested-loading
> div
> .ant-spin-sm.ant-spin-show-text
.ant-spin-dot {
    margin-top: -17px; }
  .auth-style .ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
    margin: -16px; }
  .auth-style .ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
    padding-top: 11px; }
  .auth-style .ant-spin-nested-loading
> div
> .ant-spin-lg.ant-spin-show-text
.ant-spin-dot {
    margin-top: -26px; }
  .auth-style .ant-spin-container {
    position: relative;
    transition: opacity 0.3s; }
  .auth-style .ant-spin-container:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #fff;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;
    height: 100%;
    width: 100%;
    z-index: 10; }
  .auth-style .ant-spin-blur {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    opacity: 0.5; }
  .auth-style .ant-spin-blur:after {
    opacity: 0.4;
    pointer-events: auto; }
  .auth-style .ant-spin-tip {
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-spin-dot {
    position: relative;
    display: inline-block;
    font-size: 20px;
    width: 20px;
    height: 20px; }
  .auth-style .ant-spin-dot i {
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background-color: #1890ff;
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
    display: block;
    position: absolute;
    opacity: 0.3;
    -webkit-animation: antSpinMove 1s linear infinite alternate;
    animation: antSpinMove 1s linear infinite alternate;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%; }
  .auth-style .ant-spin-dot i:first-child {
    left: 0;
    top: 0; }
  .auth-style .ant-spin-dot i:nth-child(2) {
    right: 0;
    top: 0;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s; }
  .auth-style .ant-spin-dot i:nth-child(3) {
    right: 0;
    bottom: 0;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s; }
  .auth-style .ant-spin-dot i:nth-child(4) {
    left: 0;
    bottom: 0;
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s; }
  .auth-style .ant-spin-dot-spin {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-animation: antRotate 1.2s linear infinite;
    animation: antRotate 1.2s linear infinite; }
  .auth-style .ant-spin-sm .ant-spin-dot {
    font-size: 14px;
    width: 14px;
    height: 14px; }
  .auth-style .ant-spin-sm .ant-spin-dot i {
    width: 6px;
    height: 6px; }
  .auth-style .ant-spin-lg .ant-spin-dot {
    font-size: 32px;
    width: 32px;
    height: 32px; }
  .auth-style .ant-spin-lg .ant-spin-dot i {
    width: 14px;
    height: 14px; }
  .auth-style .ant-spin.ant-spin-show-text .ant-spin-text {
    display: block; }
  @media (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .auth-style .ant-spin-blur {
      background: #fff;
      opacity: 0.5; } }

@-webkit-keyframes antSpinMove {
  to {
    opacity: 1; } }

@keyframes antSpinMove {
  to {
    opacity: 1; } }

@-webkit-keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg); } }

@keyframes antRotate {
  to {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg); } }
  .auth-style .ant-steps {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0;
    width: 100%;
    display: flex; }
  .auth-style .ant-steps-item {
    position: relative;
    display: inline-block;
    vertical-align: top;
    flex: 1 1;
    overflow: hidden; }
  .auth-style .ant-steps-item:last-child {
    flex: none; }
  .auth-style .ant-steps-item:last-child .ant-steps-item-tail,
  .auth-style .ant-steps-item:last-child .ant-steps-item-title:after {
    display: none; }
  .auth-style .ant-steps-item-content,
  .auth-style .ant-steps-item-icon {
    display: inline-block;
    vertical-align: top; }
  .auth-style .ant-steps-item-icon {
    border: 1px solid rgba(0, 0, 0, 0.25);
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 32px;
    font-size: 16px;
    margin-right: 8px;
    transition: background-color 0.3s, border-color 0.3s;
    font-family: Chinese Quote, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol; }
  .auth-style .ant-steps-item-icon > .ant-steps-icon {
    line-height: 1;
    top: -1px;
    color: #1890ff;
    position: relative; }
  .auth-style .ant-steps-item-tail {
    position: absolute;
    left: 0;
    width: 100%;
    top: 12px;
    padding: 0 10px; }
  .auth-style .ant-steps-item-tail:after {
    content: "";
    display: inline-block;
    background: #e8e8e8;
    height: 1px;
    border-radius: 1px;
    width: 100%;
    transition: background 0.3s; }
  .auth-style .ant-steps-item-title {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
    display: inline-block;
    padding-right: 16px;
    position: relative;
    line-height: 32px; }
  .auth-style .ant-steps-item-title:after {
    content: "";
    height: 1px;
    width: 9999px;
    background: #e8e8e8;
    display: block;
    position: absolute;
    top: 16px;
    left: 100%; }
  .auth-style .ant-steps-item-description {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-steps-item-wait .ant-steps-item-icon {
    border-color: rgba(0, 0, 0, 0.25);
    background-color: #fff; }
  .auth-style .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-steps-item-wait
.ant-steps-item-icon
> .ant-steps-icon
.ant-steps-icon-dot {
    background: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title:after {
    background-color: #e8e8e8; }
  .auth-style .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-description {
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-steps-item-wait > .ant-steps-item-tail:after {
    background-color: #e8e8e8; }
  .auth-style .ant-steps-item-process .ant-steps-item-icon {
    border-color: #1890ff;
    background-color: #fff; }
  .auth-style .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #1890ff; }
  .auth-style .ant-steps-item-process
.ant-steps-item-icon
> .ant-steps-icon
.ant-steps-icon-dot {
    background: #1890ff; }
  .auth-style .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.85); }
  .auth-style .ant-steps-item-process
> .ant-steps-item-content
> .ant-steps-item-title:after {
    background-color: #e8e8e8; }
  .auth-style .ant-steps-item-process
> .ant-steps-item-content
> .ant-steps-item-description {
    color: rgba(0, 0, 0, 0.65); }
  .auth-style .ant-steps-item-process > .ant-steps-item-tail:after {
    background-color: #e8e8e8; }
  .auth-style .ant-steps-item-process .ant-steps-item-icon {
    background: #1890ff; }
  .auth-style .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    color: #fff; }
  .auth-style .ant-steps-item-process .ant-steps-item-title {
    font-weight: 500; }
  .auth-style .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #1890ff;
    background-color: #fff; }
  .auth-style .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #1890ff; }
  .auth-style .ant-steps-item-finish
.ant-steps-item-icon
> .ant-steps-icon
.ant-steps-icon-dot {
    background: #1890ff; }
  .auth-style .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title {
    color: rgba(0, 0, 0, 0.65); }
  .auth-style .ant-steps-item-finish
> .ant-steps-item-content
> .ant-steps-item-title:after {
    background-color: #1890ff; }
  .auth-style .ant-steps-item-finish
> .ant-steps-item-content
> .ant-steps-item-description {
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-steps-item-finish > .ant-steps-item-tail:after {
    background-color: #1890ff; }
  .auth-style .ant-steps-item-error .ant-steps-item-icon {
    border-color: #f5222d;
    background-color: #fff; }
  .auth-style .ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
    color: #f5222d; }
  .auth-style .ant-steps-item-error
.ant-steps-item-icon
> .ant-steps-icon
.ant-steps-icon-dot {
    background: #f5222d; }
  .auth-style .ant-steps-item-error > .ant-steps-item-content > .ant-steps-item-title {
    color: #f5222d; }
  .auth-style .ant-steps-item-error
> .ant-steps-item-content
> .ant-steps-item-title:after {
    background-color: #e8e8e8; }
  .auth-style .ant-steps-item-error
> .ant-steps-item-content
> .ant-steps-item-description {
    color: #f5222d; }
  .auth-style .ant-steps-item-error > .ant-steps-item-tail:after {
    background-color: #e8e8e8; }
  .auth-style .ant-steps-item.ant-steps-next-error .ant-steps-item-title:after {
    background: #f5222d; }
  .auth-style .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    margin-right: 16px;
    white-space: nowrap; }
  .auth-style .ant-steps-horizontal:not(.ant-steps-label-vertical)
.ant-steps-item:last-child {
    margin-right: 0; }
  .auth-style .ant-steps-horizontal:not(.ant-steps-label-vertical)
.ant-steps-item:last-child
.ant-steps-item-title {
    padding-right: 0; }
  .auth-style .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
    display: none; }
  .auth-style .ant-steps-horizontal:not(.ant-steps-label-vertical)
.ant-steps-item-description {
    max-width: 140px;
    white-space: normal; }
  .auth-style .ant-steps-item-custom .ant-steps-item-icon {
    background: none;
    border: 0;
    width: auto;
    height: auto; }
  .auth-style .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
    font-size: 24px;
    line-height: 32px;
    top: 0;
    left: 0.5px;
    width: 32px;
    height: 32px; }
  .auth-style .ant-steps-item-custom.ant-steps-item-process
.ant-steps-item-icon
> .ant-steps-icon {
    color: #1890ff; }
  .auth-style .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical)
.ant-steps-item {
    margin-right: 12px; }
  .auth-style .ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical)
.ant-steps-item:last-child {
    margin-right: 0; }
  .auth-style .ant-steps-small .ant-steps-item-icon {
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 24px;
    font-size: 12px; }
  .auth-style .ant-steps-small .ant-steps-item-title {
    font-size: 14px;
    line-height: 24px;
    padding-right: 12px; }
  .auth-style .ant-steps-small .ant-steps-item-title:after {
    top: 12px; }
  .auth-style .ant-steps-small .ant-steps-item-description {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-steps-small .ant-steps-item-tail {
    top: 8px;
    padding: 0 8px; }
  .auth-style .ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
    width: inherit;
    height: inherit;
    line-height: inherit;
    border-radius: 0;
    border: 0;
    background: none; }
  .auth-style .ant-steps-small
.ant-steps-item-custom
.ant-steps-item-icon
> .ant-steps-icon {
    font-size: 24px;
    line-height: 24px;
    -webkit-transform: none;
    transform: none; }
  .auth-style .ant-steps-vertical {
    display: block; }
  .auth-style .ant-steps-vertical .ant-steps-item {
    display: block;
    overflow: visible; }
  .auth-style .ant-steps-vertical .ant-steps-item-icon {
    float: left;
    margin-right: 16px; }
  .auth-style .ant-steps-vertical .ant-steps-item-content {
    min-height: 48px;
    overflow: hidden;
    display: block; }
  .auth-style .ant-steps-vertical .ant-steps-item-title {
    line-height: 32px; }
  .auth-style .ant-steps-vertical .ant-steps-item-description {
    padding-bottom: 12px; }
  .auth-style .ant-steps-vertical > .ant-steps-item > .ant-steps-item-tail {
    position: absolute;
    left: 16px;
    top: 0;
    height: 100%;
    width: 1px;
    padding: 38px 0 6px; }
  .auth-style .ant-steps-vertical > .ant-steps-item > .ant-steps-item-tail:after {
    height: 100%;
    width: 1px; }
  .auth-style .ant-steps-vertical
> .ant-steps-item:not(:last-child)
> .ant-steps-item-tail {
    display: block; }
  .auth-style .ant-steps-vertical
> .ant-steps-item
> .ant-steps-item-content
> .ant-steps-item-title:after {
    display: none; }
  .auth-style .ant-steps-vertical.ant-steps-small .ant-steps-item-tail {
    position: absolute;
    left: 12px;
    top: 0;
    padding: 30px 0 6px; }
  .auth-style .ant-steps-vertical.ant-steps-small .ant-steps-item-title {
    line-height: 24px; }
  @media (max-width: 480px) {
    .auth-style .ant-steps-horizontal.ant-steps-label-horizontal {
      display: block; }
    .auth-style .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
      display: block;
      overflow: visible; }
    .auth-style .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
      float: left;
      margin-right: 16px; }
    .auth-style .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-content {
      min-height: 48px;
      overflow: hidden;
      display: block; }
    .auth-style .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-title {
      line-height: 32px; }
    .auth-style .ant-steps-horizontal.ant-steps-label-horizontal
.ant-steps-item-description {
      padding-bottom: 12px; }
    .auth-style .ant-steps-horizontal.ant-steps-label-horizontal
> .ant-steps-item
> .ant-steps-item-tail {
      position: absolute;
      left: 16px;
      top: 0;
      height: 100%;
      width: 1px;
      padding: 38px 0 6px; }
    .auth-style .ant-steps-horizontal.ant-steps-label-horizontal
> .ant-steps-item
> .ant-steps-item-tail:after {
      height: 100%;
      width: 1px; }
    .auth-style .ant-steps-horizontal.ant-steps-label-horizontal
> .ant-steps-item:not(:last-child)
> .ant-steps-item-tail {
      display: block; }
    .auth-style .ant-steps-horizontal.ant-steps-label-horizontal
> .ant-steps-item
> .ant-steps-item-content
> .ant-steps-item-title:after {
      display: none; }
    .auth-style .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small
.ant-steps-item-tail {
      position: absolute;
      left: 12px;
      top: 0;
      padding: 30px 0 6px; }
    .auth-style .ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small
.ant-steps-item-title {
      line-height: 24px; } }
  .auth-style .ant-steps-label-vertical .ant-steps-item {
    overflow: visible; }
  .auth-style .ant-steps-label-vertical .ant-steps-item-tail {
    padding: 3.5px 24px;
    margin-left: 51px; }
  .auth-style .ant-steps-label-vertical .ant-steps-item-content {
    display: block;
    text-align: center;
    margin-top: 8px;
    width: 104px; }
  .auth-style .ant-steps-label-vertical .ant-steps-item-icon {
    display: inline-block;
    margin-left: 36px; }
  .auth-style .ant-steps-label-vertical .ant-steps-item-title {
    padding-right: 0; }
  .auth-style .ant-steps-label-vertical .ant-steps-item-title:after {
    display: none; }
  .auth-style .ant-steps-dot .ant-steps-item-title {
    line-height: 1.5; }
  .auth-style .ant-steps-dot .ant-steps-item-tail {
    width: 100%;
    top: 2px;
    margin: 0 0 0 70px;
    padding: 0; }
  .auth-style .ant-steps-dot .ant-steps-item-tail:after {
    height: 3px;
    width: calc(100% - 20px);
    margin-left: 12px; }
  .auth-style .ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
    left: 2px; }
  .auth-style .ant-steps-dot .ant-steps-item-icon {
    padding-right: 0;
    width: 8px;
    height: 8px;
    line-height: 8px;
    border: 0;
    margin-left: 67px;
    background: transparent; }
  .auth-style .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot {
    float: left;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    position: relative;
    transition: all 0.3s; }
  .auth-style .ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot:after {
    content: "";
    background: rgba(0, 0, 0, 0.001);
    width: 60px;
    height: 32px;
    position: absolute;
    top: -12px;
    left: -26px; }
  .auth-style .ant-steps-dot .ant-steps-item-content {
    width: 140px; }
  .auth-style .ant-steps-dot .ant-steps-item-process .ant-steps-item-icon {
    width: 10px;
    height: 10px;
    line-height: 10px; }
  .auth-style .ant-steps-dot
.ant-steps-item-process
.ant-steps-item-icon
.ant-steps-icon-dot {
    top: -1px; }
  .auth-style .ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
    margin-left: 0;
    margin-top: 8px; }
  .auth-style .ant-steps-vertical.ant-steps-dot .ant-steps-item-tail {
    margin: 0;
    left: -9px;
    top: 2px;
    padding: 22px 0 4px; }
  .auth-style .ant-steps-vertical.ant-steps-dot
.ant-steps-item:first-child
.ant-steps-icon-dot {
    left: 0; }
  .auth-style .ant-steps-vertical.ant-steps-dot
.ant-steps-item-process
.ant-steps-icon-dot {
    left: -2px; }
  .auth-style .ant-steps-flex-not-supported.ant-steps-horizontal.ant-steps-label-horizontal
.ant-steps-item {
    background: #fff;
    padding-left: 16px;
    margin-left: -16px; }
  .auth-style .ant-steps-flex-not-supported.ant-steps-horizontal.ant-steps-label-horizontal.ant-steps-small
.ant-steps-item {
    padding-left: 12px;
    margin-left: -12px; }
  .auth-style .ant-steps-flex-not-supported.ant-steps-dot .ant-steps-item:last-child {
    overflow: hidden; }
  .auth-style .ant-steps-flex-not-supported.ant-steps-dot
.ant-steps-item:last-child
.ant-steps-icon-dot:after {
    width: 200px;
    right: -200px; }
  .auth-style .ant-steps-flex-not-supported.ant-steps-dot
.ant-steps-item
.ant-steps-icon-dot:after,
  .auth-style .ant-steps-flex-not-supported.ant-steps-dot
.ant-steps-item
.ant-steps-icon-dot:before {
    content: "";
    position: absolute;
    background: #fff;
    width: 10px;
    height: 8px;
    top: 0;
    left: -10px; }
  .auth-style .ant-steps-flex-not-supported.ant-steps-dot
.ant-steps-item
.ant-steps-icon-dot:after {
    left: auto;
    right: -10px; }
  .auth-style .ant-steps-flex-not-supported.ant-steps-dot
.ant-steps-item-wait
.ant-steps-item-icon
> .ant-steps-icon
.ant-steps-icon-dot {
    background: #ccc; }
  .auth-style .ant-switch {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    height: 22px;
    min-width: 44px;
    line-height: 20px;
    vertical-align: middle;
    border-radius: 100px;
    border: 1px solid transparent;
    background-color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transition: all 0.36s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .auth-style .ant-switch-inner {
    color: #fff;
    font-size: 12px;
    margin-left: 24px;
    margin-right: 6px;
    display: block; }
  .auth-style .ant-switch-loading-icon,
  .auth-style .ant-switch:after {
    position: absolute;
    width: 18px;
    height: 18px;
    left: 1px;
    top: 1px;
    border-radius: 18px;
    background-color: #fff;
    content: " ";
    cursor: pointer;
    transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86); }
  .auth-style .ant-switch:after {
    box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2); }
  .auth-style .ant-switch:active:after,
  .auth-style .ant-switch:active:before {
    width: 24px; }
  .auth-style .ant-switch-loading-icon {
    background: transparent;
    z-index: 1;
    display: none;
    font-size: 12px; }
  .auth-style .ant-switch-loading-icon svg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto; }
  .auth-style .ant-switch-loading .ant-switch-loading-icon {
    display: inline-block;
    color: rgba(0, 0, 0, 0.65); }
  .auth-style .ant-switch-checked.ant-switch-loading .ant-switch-loading-icon {
    color: #1890ff; }
  .auth-style .ant-switch:focus {
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    outline: 0; }
  .auth-style .ant-switch:focus:hover {
    box-shadow: none; }
  .auth-style .ant-switch-small {
    height: 16px;
    min-width: 28px;
    line-height: 14px; }
  .auth-style .ant-switch-small .ant-switch-inner {
    margin-left: 18px;
    margin-right: 3px;
    font-size: 12px; }
  .auth-style .ant-switch-small:after {
    width: 12px;
    height: 12px; }
  .auth-style .ant-switch-small:active:after,
  .auth-style .ant-switch-small:active:before {
    width: 16px; }
  .auth-style .ant-switch-small .ant-switch-loading-icon {
    width: 12px;
    height: 12px; }
  .auth-style .ant-switch-small.ant-switch-checked .ant-switch-inner {
    margin-left: 3px;
    margin-right: 18px; }
  .auth-style .ant-switch-small.ant-switch-checked .ant-switch-loading-icon {
    left: 100%;
    margin-left: -13px; }
  .auth-style .ant-switch-small.ant-switch-loading .ant-switch-loading-icon {
    -webkit-transform: scale(0.66667);
    transform: scale(0.66667);
    font-weight: 700; }
  .auth-style .ant-switch-checked {
    background-color: #1890ff; }
  .auth-style .ant-switch-checked .ant-switch-inner {
    margin-left: 6px;
    margin-right: 24px; }
  .auth-style .ant-switch-checked:after {
    left: 100%;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    margin-left: -1px; }
  .auth-style .ant-switch-checked .ant-switch-loading-icon {
    left: 100%;
    margin-left: -19px; }
  .auth-style .ant-switch-disabled,
  .auth-style .ant-switch-loading {
    cursor: not-allowed;
    opacity: 0.4; }
  .auth-style .ant-switch-disabled *,
  .auth-style .ant-switch-loading * {
    cursor: not-allowed; }

@-webkit-keyframes AntSwitchSmallLoadingCircle {
  0% {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: rotate(0deg) scale(0.66667);
    transform: rotate(0deg) scale(0.66667); }
  to {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: rotate(1turn) scale(0.66667);
    transform: rotate(1turn) scale(0.66667); } }

@keyframes AntSwitchSmallLoadingCircle {
  0% {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: rotate(0deg) scale(0.66667);
    transform: rotate(0deg) scale(0.66667); }
  to {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transform: rotate(1turn) scale(0.66667);
    transform: rotate(1turn) scale(0.66667); } }
  .auth-style .ant-table-wrapper {
    zoom: 1; }
  .auth-style .ant-table-wrapper:after,
  .auth-style .ant-table-wrapper:before {
    content: "";
    display: table; }
  .auth-style .ant-table-wrapper:after {
    clear: both; }
  .auth-style .ant-table {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    clear: both; }
  .auth-style .ant-table-body {
    transition: opacity 0.3s; }
  .auth-style .ant-table-empty .ant-table-body {
    overflow: auto !important; }
  .auth-style .ant-table table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    border-radius: 4px 4px 0 0; }
  .auth-style .ant-table-thead > tr > th {
    background: #fafafa;
    transition: background 0.3s ease;
    text-align: left;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    border-bottom: 1px solid #e8e8e8; }
  .auth-style .ant-table-thead > tr > th[colspan] {
    text-align: center; }
  .auth-style .ant-table-thead > tr > th .ant-table-filter-icon,
  .auth-style .ant-table-thead > tr > th .anticon-filter {
    font-size: 12px;
    cursor: pointer;
    color: #bfbfbf;
    transition: all 0.3s;
    width: 28px;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    text-align: center; }
  .auth-style .ant-table-thead > tr > th .ant-table-filter-icon > svg,
  .auth-style .ant-table-thead > tr > th .anticon-filter > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -6px; }
  .auth-style .ant-table-thead > tr > th .ant-table-filter-selected.anticon-filter {
    color: #1890ff; }
  .auth-style .ant-table-thead > tr > th .ant-table-column-sorter {
    position: absolute;
    right: 6px;
    top: 50%;
    width: 14px;
    height: 17px;
    margin-top: -8.5px;
    text-align: center;
    color: #bfbfbf;
    transition: all 0.3s; }
  .auth-style .ant-table-thead > tr > th .ant-table-column-sorter-down,
  .auth-style .ant-table-thead > tr > th .ant-table-column-sorter-up {
    display: inline-block;
    font-size: 12px;
    font-size: 11px\9;
    -webkit-transform: scale(0.91667) rotate(0deg);
    transform: scale(0.91667) rotate(0deg);
    line-height: 4px;
    height: 4px;
    transition: all 0.3s;
    display: block; }
  .auth-style :root .ant-table-thead > tr > th .ant-table-column-sorter-down,
  .auth-style :root .ant-table-thead > tr > th .ant-table-column-sorter-up {
    font-size: 12px; }
  .auth-style .ant-table-thead > tr > th .ant-table-column-sorter-down.on,
  .auth-style .ant-table-thead > tr > th .ant-table-column-sorter-up.on {
    color: #1890ff; }
  .auth-style .ant-table-thead > tr > th .ant-table-column-sorter-down {
    margin-top: 4px; }
  .auth-style .ant-table-thead > tr > th.ant-table-column-has-actions {
    position: relative;
    background-clip: padding-box; }
  .auth-style .ant-table-thead
> tr
> th.ant-table-column-has-actions.ant-table-column-has-filters
.ant-table-filter-icon.ant-table-filter-open,
  .auth-style .ant-table-thead
> tr
> th.ant-table-column-has-actions.ant-table-column-has-filters
.anticon-filter.ant-table-filter-open,
  .auth-style .ant-table-thead
> tr
> th.ant-table-column-has-actions.ant-table-column-has-filters:hover
.ant-table-filter-icon:hover,
  .auth-style .ant-table-thead
> tr
> th.ant-table-column-has-actions.ant-table-column-has-filters:hover
.anticon-filter:hover {
    color: rgba(0, 0, 0, 0.45);
    background: #e5e5e5; }
  .auth-style .ant-table-thead
> tr
> th.ant-table-column-has-actions.ant-table-column-has-filters:hover
.ant-table-filter-icon:active,
  .auth-style .ant-table-thead
> tr
> th.ant-table-column-has-actions.ant-table-column-has-filters:hover
.anticon-filter:active {
    color: rgba(0, 0, 0, 0.65); }
  .auth-style .ant-table-thead
> tr
> th.ant-table-column-has-actions.ant-table-column-has-sorters {
    cursor: pointer; }
  .auth-style .ant-table-thead
> tr
> th.ant-table-column-has-actions.ant-table-column-has-sorters:hover,
  .auth-style .ant-table-thead
> tr
> th.ant-table-column-has-actions.ant-table-column-has-sorters:hover
.ant-table-filter-icon,
  .auth-style .ant-table-thead
> tr
> th.ant-table-column-has-actions.ant-table-column-has-sorters:hover
.anticon-filter {
    background: #f2f2f2; }
  .auth-style .ant-table-thead
> tr
> th.ant-table-column-has-actions.ant-table-column-has-sorters:active
.ant-table-column-sorter-down:not(.on),
  .auth-style .ant-table-thead
> tr
> th.ant-table-column-has-actions.ant-table-column-has-sorters:active
.ant-table-column-sorter-up:not(.on) {
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-table-thead
> tr
> th.ant-table-column-has-actions.ant-table-column-has-filters,
  .auth-style .ant-table-thead
> tr
> th.ant-table-column-has-actions.ant-table-column-has-sorters {
    padding-right: 30px !important; }
  .auth-style .ant-table-thead
> tr
> th.ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-column-has-filters {
    padding-right: 54px !important; }
  .auth-style .ant-table-thead
> tr
> th
.ant-table-column-sorters
> :not(.ant-table-column-sorter) {
    position: relative; }
  .auth-style .ant-table-thead > tr > th .ant-table-column-sorters:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    transition: all 0.3s; }
  .auth-style .ant-table-thead > tr > th .ant-table-column-sorters:hover:before {
    background: rgba(0, 0, 0, 0.04); }
  .auth-style .ant-table-thead
> tr
> th.ant-table-column-has-filters
.ant-table-column-sorter {
    right: 34px; }
  .auth-style .ant-table-thead > tr > th.ant-table-column-has-sorters {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .auth-style .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: 4px; }
  .auth-style .ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: 4px; }
  .auth-style .ant-table-thead > tr:not(:last-child) > th[colspan] {
    border-bottom: 0; }
  .auth-style .ant-table-tbody > tr > td {
    border-bottom: 1px solid #e8e8e8;
    transition: all 0.3s, border 0s; }
  .auth-style .ant-table-tbody > tr,
  .auth-style .ant-table-thead > tr {
    transition: all 0.3s, height 0s; }
  .auth-style .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .auth-style .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td,
  .auth-style .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .auth-style .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td {
    background: #e6f7ff; }
  .auth-style .ant-table-thead > tr:hover {
    background: none; }
  .auth-style .ant-table-footer {
    padding: 16px;
    background: #fafafa;
    border-radius: 0 0 4px 4px;
    position: relative;
    border-top: 1px solid #e8e8e8; }
  .auth-style .ant-table-footer:before {
    content: "";
    height: 1px;
    background: #fafafa;
    position: absolute;
    top: -1px;
    width: 100%;
    left: 0; }
  .auth-style .ant-table.ant-table-bordered .ant-table-footer {
    border: 1px solid #e8e8e8; }
  .auth-style .ant-table-title {
    padding: 16px 0;
    position: relative;
    top: 1px;
    border-radius: 4px 4px 0 0; }
  .auth-style .ant-table.ant-table-bordered .ant-table-title {
    border: 1px solid #e8e8e8;
    padding-left: 16px;
    padding-right: 16px; }
  .auth-style .ant-table-title + .ant-table-content {
    position: relative;
    border-radius: 4px 4px 0 0;
    overflow: hidden; }
  .auth-style .ant-table-bordered .ant-table-title + .ant-table-content,
  .auth-style .ant-table-bordered
.ant-table-title
+ .ant-table-content
.ant-table-thead
> tr:first-child
> th,
  .auth-style .ant-table-bordered .ant-table-title + .ant-table-content table,
  .auth-style .ant-table-without-column-header .ant-table-title + .ant-table-content,
  .auth-style .ant-table-without-column-header table {
    border-radius: 0; }
  .auth-style .ant-table-tbody > tr.ant-table-row-selected td {
    background: #fafafa; }
  .auth-style .ant-table-thead > tr > th.ant-table-column-sort {
    background: #f5f5f5; }
  .auth-style .ant-table-tbody > tr > td.ant-table-column-sort {
    background: rgba(0, 0, 0, 0.01); }
  .auth-style .ant-table-tbody > tr > td,
  .auth-style .ant-table-thead > tr > th {
    padding: 16px; }
  .auth-style .ant-table-thead
> tr
> th.ant-table-selection-column-custom
.ant-table-selection {
    margin-right: -15px; }
  .auth-style .ant-table-tbody > tr > td.ant-table-selection-column,
  .auth-style .ant-table-thead > tr > th.ant-table-selection-column {
    text-align: center; }
  .auth-style .ant-table-tbody > tr > td.ant-table-selection-column .ant-radio-wrapper,
  .auth-style .ant-table-thead > tr > th.ant-table-selection-column .ant-radio-wrapper {
    margin-right: 0; }
  .auth-style .ant-table-expand-icon-th,
  .auth-style .ant-table-row-expand-icon-cell {
    text-align: center;
    min-width: 50px;
    width: 50px; }
  .auth-style .ant-table-header {
    background: #fafafa;
    overflow: hidden; }
  .auth-style .ant-table-header table {
    border-radius: 4px 4px 0 0; }
  .auth-style .ant-table-loading {
    position: relative; }
  .auth-style .ant-table-loading .ant-table-body {
    background: #fff;
    opacity: 0.5; }
  .auth-style .ant-table-loading .ant-table-spin-holder {
    height: 20px;
    line-height: 20px;
    left: 50%;
    top: 50%;
    margin-left: -30px;
    position: absolute; }
  .auth-style .ant-table-loading .ant-table-with-pagination {
    margin-top: -20px; }
  .auth-style .ant-table-loading .ant-table-without-pagination {
    margin-top: 10px; }
  .auth-style .ant-table-bordered .ant-table-body > table,
  .auth-style .ant-table-bordered .ant-table-fixed-left table,
  .auth-style .ant-table-bordered .ant-table-fixed-right table,
  .auth-style .ant-table-bordered .ant-table-header > table {
    border: 1px solid #e8e8e8;
    border-right: 0;
    border-bottom: 0; }
  .auth-style .ant-table-bordered.ant-table-empty .ant-table-placeholder {
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8; }
  .auth-style .ant-table-bordered.ant-table-fixed-header .ant-table-header > table {
    border-bottom: 0; }
  .auth-style .ant-table-bordered.ant-table-fixed-header .ant-table-body > table {
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .auth-style .ant-table-bordered.ant-table-fixed-header .ant-table-body-inner > table {
    border-top: 0; }
  .auth-style .ant-table-bordered.ant-table-fixed-header .ant-table-placeholder {
    border: 0; }
  .auth-style .ant-table-bordered .ant-table-thead > tr:not(:last-child) > th {
    border-bottom: 1px solid #e8e8e8; }
  .auth-style .ant-table-bordered .ant-table-tbody > tr > td,
  .auth-style .ant-table-bordered .ant-table-thead > tr > th {
    border-right: 1px solid #e8e8e8; }
  .auth-style .ant-table-placeholder {
    position: relative;
    padding: 16px;
    background: #fff;
    border-radius: 4px;
    border-bottom: 1px solid #e8e8e8;
    text-align: center;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    z-index: 1; }
  .auth-style .ant-table-placeholder .anticon {
    margin-right: 4px; }
  .auth-style .ant-table-pagination.ant-pagination {
    margin: 16px 0;
    float: right; }
  .auth-style .ant-table-filter-dropdown {
    min-width: 96px;
    margin-left: -8px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    position: relative; }
  .auth-style .ant-table-filter-dropdown .ant-dropdown-menu {
    border: 0;
    box-shadow: none;
    border-radius: 4px 4px 0 0; }
  .auth-style .ant-table-filter-dropdown .ant-dropdown-menu-without-submenu {
    max-height: 400px;
    overflow-x: hidden; }
  .auth-style .ant-table-filter-dropdown .ant-dropdown-menu-item > label + span {
    padding-right: 0; }
  .auth-style .ant-table-filter-dropdown .ant-dropdown-menu-sub {
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); }
  .auth-style .ant-table-filter-dropdown
.ant-dropdown-menu
.ant-dropdown-submenu-contain-selected
.ant-dropdown-menu-submenu-title:after {
    color: #1890ff;
    font-weight: 700;
    text-shadow: 0 0 2px #bae7ff; }
  .auth-style .ant-table-filter-dropdown .ant-dropdown-menu-item {
    overflow: hidden; }
  .auth-style .ant-table-filter-dropdown
> .ant-dropdown-menu
> .ant-dropdown-menu-item:last-child,
  .auth-style .ant-table-filter-dropdown
> .ant-dropdown-menu
> .ant-dropdown-menu-submenu:last-child
.ant-dropdown-menu-submenu-title {
    border-radius: 0; }
  .auth-style .ant-table-filter-dropdown-btns {
    overflow: hidden;
    padding: 7px 8px;
    border-top: 1px solid #e8e8e8; }
  .auth-style .ant-table-filter-dropdown-link {
    color: #1890ff; }
  .auth-style .ant-table-filter-dropdown-link:hover {
    color: #40a9ff; }
  .auth-style .ant-table-filter-dropdown-link:active {
    color: #096dd9; }
  .auth-style .ant-table-filter-dropdown-link.confirm {
    float: left; }
  .auth-style .ant-table-filter-dropdown-link.clear {
    float: right; }
  .auth-style .ant-table-selection-select-all-custom {
    margin-right: 4px !important; }
  .auth-style .ant-table-selection .anticon-down {
    color: #bfbfbf;
    transition: all 0.3s; }
  .auth-style .ant-table-selection-menu {
    min-width: 96px;
    margin-top: 5px;
    margin-left: -30px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); }
  .auth-style .ant-table-selection-menu .ant-action-down {
    color: #bfbfbf; }
  .auth-style .ant-table-selection-down {
    cursor: pointer;
    padding: 0;
    display: inline-block;
    line-height: 1; }
  .auth-style .ant-table-selection-down:hover .anticon-down {
    color: rgba(0, 0, 0, 0.6); }
  .auth-style .ant-table-row-expand-icon {
    cursor: pointer;
    display: inline-block;
    width: 17px;
    height: 17px;
    text-align: center;
    line-height: 14px;
    border: 1px solid #e8e8e8;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #fff; }
  .auth-style .ant-table-row-expanded:after {
    content: "-"; }
  .auth-style .ant-table-row-collapsed:after {
    content: "+"; }
  .auth-style .ant-table-row-spaced {
    visibility: hidden; }
  .auth-style .ant-table-row-spaced:after {
    content: "."; }
  .auth-style .ant-table-row[class*="ant-table-row-level-0"]
.ant-table-selection-column
> span {
    display: inline-block; }
  .auth-style tr.ant-table-expanded-row,
  .auth-style tr.ant-table-expanded-row:hover {
    background: #fbfbfb; }
  .auth-style tr.ant-table-expanded-row td > .ant-table-wrapper {
    margin: -16px -16px -17px; }
  .auth-style .ant-table .ant-table-row-indent + .ant-table-row-expand-icon {
    margin-right: 8px; }
  .auth-style .ant-table-scroll {
    overflow: auto;
    overflow-x: hidden; }
  .auth-style .ant-table-scroll table {
    width: auto;
    min-width: 100%; }
  .auth-style .ant-table-body-inner {
    height: 100%; }
  .auth-style .ant-table-fixed-header
> .ant-table-content
> .ant-table-scroll
> .ant-table-body {
    position: relative;
    background: #fff; }
  .auth-style .ant-table-fixed-header .ant-table-body-inner {
    overflow: scroll; }
  .auth-style .ant-table-fixed-header .ant-table-scroll .ant-table-header {
    overflow: scroll;
    padding-bottom: 20px;
    margin-bottom: -20px;
    opacity: 0.9999; }
  .auth-style .ant-table-fixed-left,
  .auth-style .ant-table-fixed-right {
    position: absolute;
    top: 0;
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    border-radius: 0;
    z-index: 20; }
  .auth-style .ant-table-fixed-left table,
  .auth-style .ant-table-fixed-right table {
    width: auto;
    background: #fff; }
  .auth-style .ant-table-fixed-header
.ant-table-fixed-left
.ant-table-body-outer
.ant-table-fixed,
  .auth-style .ant-table-fixed-header
.ant-table-fixed-right
.ant-table-body-outer
.ant-table-fixed {
    border-radius: 0; }
  .auth-style .ant-table-fixed-left {
    left: 0;
    box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15); }
  .auth-style .ant-table-fixed-left .ant-table-header {
    overflow-y: hidden; }
  .auth-style .ant-table-fixed-left .ant-table-body-inner {
    margin-right: -20px;
    padding-right: 20px; }
  .auth-style .ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
    padding-right: 0; }
  .auth-style .ant-table-fixed-left,
  .auth-style .ant-table-fixed-left table {
    border-radius: 4px 0 0 0; }
  .auth-style .ant-table-fixed-left .ant-table-thead > tr > th:last-child {
    border-top-right-radius: 0; }
  .auth-style .ant-table-fixed-right {
    right: 0;
    box-shadow: -6px 0 6px -4px rgba(0, 0, 0, 0.15); }
  .auth-style .ant-table-fixed-right,
  .auth-style .ant-table-fixed-right table {
    border-radius: 0 4px 0 0; }
  .auth-style .ant-table-fixed-right .ant-table-expanded-row {
    color: transparent;
    pointer-events: none; }
  .auth-style .ant-table-fixed-right .ant-table-thead > tr > th:first-child {
    border-top-left-radius: 0; }
  .auth-style .ant-table.ant-table-scroll-position-left .ant-table-fixed-left,
  .auth-style .ant-table.ant-table-scroll-position-right .ant-table-fixed-right {
    box-shadow: none; }
  .auth-style .ant-table-middle
> .ant-table-content
> .ant-table-body
> table
> .ant-table-tbody
> tr
> td,
  .auth-style .ant-table-middle
> .ant-table-content
> .ant-table-body
> table
> .ant-table-thead
> tr
> th,
  .auth-style .ant-table-middle
> .ant-table-content
> .ant-table-fixed-left
> .ant-table-body-outer
> .ant-table-body-inner
> table
> .ant-table-tbody
> tr
> td,
  .auth-style .ant-table-middle
> .ant-table-content
> .ant-table-fixed-left
> .ant-table-body-outer
> .ant-table-body-inner
> table
> .ant-table-thead
> tr
> th,
  .auth-style .ant-table-middle
> .ant-table-content
> .ant-table-fixed-left
> .ant-table-header
> table
> .ant-table-tbody
> tr
> td,
  .auth-style .ant-table-middle
> .ant-table-content
> .ant-table-fixed-left
> .ant-table-header
> table
> .ant-table-thead
> tr
> th,
  .auth-style .ant-table-middle
> .ant-table-content
> .ant-table-fixed-right
> .ant-table-body-outer
> .ant-table-body-inner
> table
> .ant-table-tbody
> tr
> td,
  .auth-style .ant-table-middle
> .ant-table-content
> .ant-table-fixed-right
> .ant-table-body-outer
> .ant-table-body-inner
> table
> .ant-table-thead
> tr
> th,
  .auth-style .ant-table-middle
> .ant-table-content
> .ant-table-fixed-right
> .ant-table-header
> table
> .ant-table-tbody
> tr
> td,
  .auth-style .ant-table-middle
> .ant-table-content
> .ant-table-fixed-right
> .ant-table-header
> table
> .ant-table-thead
> tr
> th,
  .auth-style .ant-table-middle
> .ant-table-content
> .ant-table-header
> table
> .ant-table-tbody
> tr
> td,
  .auth-style .ant-table-middle
> .ant-table-content
> .ant-table-header
> table
> .ant-table-thead
> tr
> th,
  .auth-style .ant-table-middle
> .ant-table-content
> .ant-table-scroll
> .ant-table-body
> table
> .ant-table-tbody
> tr
> td,
  .auth-style .ant-table-middle
> .ant-table-content
> .ant-table-scroll
> .ant-table-body
> table
> .ant-table-thead
> tr
> th,
  .auth-style .ant-table-middle
> .ant-table-content
> .ant-table-scroll
> .ant-table-header
> table
> .ant-table-tbody
> tr
> td,
  .auth-style .ant-table-middle
> .ant-table-content
> .ant-table-scroll
> .ant-table-header
> table
> .ant-table-thead
> tr
> th,
  .auth-style .ant-table-middle > .ant-table-footer,
  .auth-style .ant-table-middle > .ant-table-title {
    padding: 12px 8px; }
  .auth-style .ant-table-middle tr.ant-table-expanded-row td > .ant-table-wrapper {
    margin: -12px -16px -13px; }
  .auth-style .ant-table-small {
    border: 1px solid #e8e8e8;
    border-radius: 4px; }
  .auth-style .ant-table-small > .ant-table-footer,
  .auth-style .ant-table-small > .ant-table-title {
    padding: 8px; }
  .auth-style .ant-table-small > .ant-table-title {
    border-bottom: 1px solid #e8e8e8;
    top: 0; }
  .auth-style .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0 8px; }
  .auth-style .ant-table-small > .ant-table-content > .ant-table-body > table,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-left
> .ant-table-body-outer
> .ant-table-body-inner
> table,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-left
> .ant-table-header
> table,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-right
> .ant-table-body-outer
> .ant-table-body-inner
> table,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-right
> .ant-table-header
> table,
  .auth-style .ant-table-small > .ant-table-content > .ant-table-header > table,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-scroll
> .ant-table-body
> table,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-scroll
> .ant-table-header
> table {
    border: 0; }
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-body
> table
> .ant-table-tbody
> tr
> td,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-body
> table
> .ant-table-thead
> tr
> th,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-left
> .ant-table-body-outer
> .ant-table-body-inner
> table
> .ant-table-tbody
> tr
> td,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-left
> .ant-table-body-outer
> .ant-table-body-inner
> table
> .ant-table-thead
> tr
> th,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-left
> .ant-table-header
> table
> .ant-table-tbody
> tr
> td,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-left
> .ant-table-header
> table
> .ant-table-thead
> tr
> th,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-right
> .ant-table-body-outer
> .ant-table-body-inner
> table
> .ant-table-tbody
> tr
> td,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-right
> .ant-table-body-outer
> .ant-table-body-inner
> table
> .ant-table-thead
> tr
> th,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-right
> .ant-table-header
> table
> .ant-table-tbody
> tr
> td,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-right
> .ant-table-header
> table
> .ant-table-thead
> tr
> th,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-header
> table
> .ant-table-tbody
> tr
> td,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-header
> table
> .ant-table-thead
> tr
> th,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-scroll
> .ant-table-body
> table
> .ant-table-tbody
> tr
> td,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-scroll
> .ant-table-body
> table
> .ant-table-thead
> tr
> th,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-scroll
> .ant-table-header
> table
> .ant-table-tbody
> tr
> td,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-scroll
> .ant-table-header
> table
> .ant-table-thead
> tr
> th {
    padding: 8px; }
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-body
> table
> .ant-table-thead
> tr
> th,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-left
> .ant-table-body-outer
> .ant-table-body-inner
> table
> .ant-table-thead
> tr
> th,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-left
> .ant-table-header
> table
> .ant-table-thead
> tr
> th,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-right
> .ant-table-body-outer
> .ant-table-body-inner
> table
> .ant-table-thead
> tr
> th,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-right
> .ant-table-header
> table
> .ant-table-thead
> tr
> th,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-header
> table
> .ant-table-thead
> tr
> th,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-scroll
> .ant-table-body
> table
> .ant-table-thead
> tr
> th,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-scroll
> .ant-table-header
> table
> .ant-table-thead
> tr
> th {
    background-color: #fff;
    border-bottom: 1px solid #e8e8e8; }
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-body
> table
> .ant-table-thead
> tr
> th.ant-table-column-sort,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-left
> .ant-table-body-outer
> .ant-table-body-inner
> table
> .ant-table-thead
> tr
> th.ant-table-column-sort,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-left
> .ant-table-header
> table
> .ant-table-thead
> tr
> th.ant-table-column-sort,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-right
> .ant-table-body-outer
> .ant-table-body-inner
> table
> .ant-table-thead
> tr
> th.ant-table-column-sort,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-right
> .ant-table-header
> table
> .ant-table-thead
> tr
> th.ant-table-column-sort,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-header
> table
> .ant-table-thead
> tr
> th.ant-table-column-sort,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-scroll
> .ant-table-body
> table
> .ant-table-thead
> tr
> th.ant-table-column-sort,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-scroll
> .ant-table-header
> table
> .ant-table-thead
> tr
> th.ant-table-column-sort {
    background-color: rgba(0, 0, 0, 0.01); }
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-left
> .ant-table-body-outer
> .ant-table-body-inner
> table,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-left
> .ant-table-header
> table,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-right
> .ant-table-body-outer
> .ant-table-body-inner
> table,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-fixed-right
> .ant-table-header
> table,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-scroll
> .ant-table-body
> table,
  .auth-style .ant-table-small
> .ant-table-content
> .ant-table-scroll
> .ant-table-header
> table {
    padding: 0; }
  .auth-style .ant-table-small > .ant-table-content .ant-table-header {
    background-color: #fff; }
  .auth-style .ant-table-small > .ant-table-content .ant-table-placeholder,
  .auth-style .ant-table-small > .ant-table-content .ant-table-row:last-child td {
    border-bottom: 0; }
  .auth-style .ant-table-small.ant-table-bordered {
    border-right: 0; }
  .auth-style .ant-table-small.ant-table-bordered .ant-table-title {
    border: 0;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8; }
  .auth-style .ant-table-small.ant-table-bordered .ant-table-content {
    border-right: 1px solid #e8e8e8; }
  .auth-style .ant-table-small.ant-table-bordered .ant-table-footer {
    border: 0;
    border-top: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8; }
  .auth-style .ant-table-small.ant-table-bordered .ant-table-footer:before {
    display: none; }
  .auth-style .ant-table-small.ant-table-bordered .ant-table-placeholder {
    border-left: 0;
    border-bottom: 0;
    border-right: 0; }
  .auth-style .ant-table-small.ant-table-bordered .ant-table-tbody > tr > td:last-child,
  .auth-style .ant-table-small.ant-table-bordered .ant-table-thead > tr > th:last-child {
    border-right: none; }
  .auth-style .ant-table-small.ant-table-bordered
.ant-table-fixed-left
.ant-table-tbody
> tr
> td:last-child,
  .auth-style .ant-table-small.ant-table-bordered
.ant-table-fixed-left
.ant-table-thead
> tr
> th:last-child {
    border-right: 1px solid #e8e8e8; }
  .auth-style .ant-table-small.ant-table-bordered .ant-table-fixed-right {
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8; }
  .auth-style .ant-table-small tr.ant-table-expanded-row td > .ant-table-wrapper {
    margin: -8px -16px -9px; }
  .auth-style .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-container {
    height: 40px; }
  .auth-style .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-ink-bar {
    visibility: hidden; }
  .auth-style .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    border: 1px solid #e8e8e8;
    border-bottom: 0;
    border-radius: 4px 4px 0 0;
    background: #fafafa;
    margin: 0 2px 0 0;
    padding: 0 16px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    line-height: 38px; }
  .auth-style .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    background: #fff;
    border-color: #e8e8e8;
    color: #1890ff;
    padding-bottom: 1px; }
  .auth-style .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-inactive {
    padding: 0; }
  .auth-style .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-nav-wrap {
    margin-bottom: 0; }
  .auth-style .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab .ant-tabs-close-x {
    color: rgba(0, 0, 0, 0.45);
    transition: all 0.3s;
    font-size: 12px;
    margin-left: 3px;
    margin-right: -5px;
    overflow: hidden;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    height: 14px; }
  .auth-style .ant-tabs.ant-tabs-card
.ant-tabs-card-bar
.ant-tabs-tab
.ant-tabs-close-x:hover {
    color: rgba(0, 0, 0, 0.85); }
  .auth-style .ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane,
  .auth-style .ant-tabs.ant-tabs-editable-card .ant-tabs-card-content > .ant-tabs-tabpane {
    transition: none !important; }
  .auth-style .ant-tabs.ant-tabs-card .ant-tabs-card-content > .ant-tabs-tabpane-inactive,
  .auth-style .ant-tabs.ant-tabs-editable-card
.ant-tabs-card-content
> .ant-tabs-tabpane-inactive {
    overflow: hidden; }
  .auth-style .ant-tabs.ant-tabs-card
.ant-tabs-card-bar
.ant-tabs-tab:hover
.anticon-close {
    opacity: 1; }
  .auth-style .ant-tabs-extra-content {
    line-height: 40px; }
  .auth-style .ant-tabs-extra-content .ant-tabs-new-tab {
    position: relative;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    border-radius: 2px;
    border: 1px solid #e8e8e8;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    transition: all 0.3s; }
  .auth-style .ant-tabs-extra-content .ant-tabs-new-tab:hover {
    color: #1890ff;
    border-color: #1890ff; }
  .auth-style .ant-tabs-extra-content .ant-tabs-new-tab svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto; }
  .auth-style .ant-tabs-vertical.ant-tabs-card
.ant-tabs-card-bar.ant-tabs-left-bar
.ant-tabs-nav-container,
  .auth-style .ant-tabs-vertical.ant-tabs-card
.ant-tabs-card-bar.ant-tabs-right-bar
.ant-tabs-nav-container {
    height: auto; }
  .auth-style .ant-tabs-vertical.ant-tabs-card
.ant-tabs-card-bar.ant-tabs-left-bar
.ant-tabs-tab,
  .auth-style .ant-tabs-vertical.ant-tabs-card
.ant-tabs-card-bar.ant-tabs-right-bar
.ant-tabs-tab {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 8px; }
  .auth-style .ant-tabs-vertical.ant-tabs-card
.ant-tabs-card-bar.ant-tabs-left-bar
.ant-tabs-tab-active,
  .auth-style .ant-tabs-vertical.ant-tabs-card
.ant-tabs-card-bar.ant-tabs-right-bar
.ant-tabs-tab-active {
    padding-bottom: 4px; }
  .auth-style .ant-tabs-vertical.ant-tabs-card
.ant-tabs-card-bar.ant-tabs-left-bar
.ant-tabs-tab:last-child,
  .auth-style .ant-tabs-vertical.ant-tabs-card
.ant-tabs-card-bar.ant-tabs-right-bar
.ant-tabs-tab:last-child {
    margin-bottom: 8px; }
  .auth-style .ant-tabs-vertical.ant-tabs-card
.ant-tabs-card-bar.ant-tabs-left-bar
.ant-tabs-new-tab,
  .auth-style .ant-tabs-vertical.ant-tabs-card
.ant-tabs-card-bar.ant-tabs-right-bar
.ant-tabs-new-tab {
    width: 90%; }
  .auth-style .ant-tabs-vertical.ant-tabs-card.ant-tabs-left
.ant-tabs-card-bar.ant-tabs-left-bar
.ant-tabs-nav-wrap {
    margin-right: 0; }
  .auth-style .ant-tabs-vertical.ant-tabs-card.ant-tabs-left
.ant-tabs-card-bar.ant-tabs-left-bar
.ant-tabs-tab {
    border-right: 0;
    border-radius: 4px 0 0 4px;
    margin-right: 1px; }
  .auth-style .ant-tabs-vertical.ant-tabs-card.ant-tabs-left
.ant-tabs-card-bar.ant-tabs-left-bar
.ant-tabs-tab-active {
    margin-right: -1px;
    padding-right: 18px; }
  .auth-style .ant-tabs-vertical.ant-tabs-card.ant-tabs-right
.ant-tabs-card-bar.ant-tabs-right-bar
.ant-tabs-nav-wrap {
    margin-left: 0; }
  .auth-style .ant-tabs-vertical.ant-tabs-card.ant-tabs-right
.ant-tabs-card-bar.ant-tabs-right-bar
.ant-tabs-tab {
    border-left: 0;
    border-radius: 0 4px 4px 0;
    margin-left: 1px; }
  .auth-style .ant-tabs-vertical.ant-tabs-card.ant-tabs-right
.ant-tabs-card-bar.ant-tabs-right-bar
.ant-tabs-tab-active {
    margin-left: -1px;
    padding-left: 18px; }
  .auth-style .ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab {
    border-bottom: 1px solid #e8e8e8;
    border-top: 0;
    border-radius: 0 0 4px 4px; }
  .auth-style .ant-tabs .ant-tabs-card-bar.ant-tabs-bottom-bar .ant-tabs-tab-active {
    color: #1890ff;
    padding-bottom: 0;
    padding-top: 1px; }
  .auth-style .ant-tabs {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative;
    overflow: hidden;
    zoom: 1; }
  .auth-style .ant-tabs:after,
  .auth-style .ant-tabs:before {
    content: "";
    display: table; }
  .auth-style .ant-tabs:after {
    clear: both; }
  .auth-style .ant-tabs-ink-bar {
    z-index: 1;
    position: absolute;
    left: 0;
    bottom: 1px;
    box-sizing: border-box;
    height: 2px;
    background-color: #1890ff;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0; }
  .auth-style .ant-tabs-bar {
    border-bottom: 1px solid #e8e8e8;
    margin: 0 0 16px;
    outline: none; }
  .auth-style .ant-tabs-bar,
  .auth-style .ant-tabs-nav-container {
    transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .auth-style .ant-tabs-nav-container {
    overflow: hidden;
    font-size: 14px;
    line-height: 1.5;
    box-sizing: border-box;
    position: relative;
    white-space: nowrap;
    margin-bottom: -1px;
    zoom: 1; }
  .auth-style .ant-tabs-nav-container:after,
  .auth-style .ant-tabs-nav-container:before {
    content: "";
    display: table; }
  .auth-style .ant-tabs-nav-container:after {
    clear: both; }
  .auth-style .ant-tabs-nav-container-scrolling {
    padding-left: 32px;
    padding-right: 32px; }
  .auth-style .ant-tabs-bottom .ant-tabs-bottom-bar {
    margin-bottom: 0;
    margin-top: 16px;
    border-bottom: none;
    border-top: 1px solid #e8e8e8; }
  .auth-style .ant-tabs-bottom .ant-tabs-bottom-bar .ant-tabs-ink-bar {
    bottom: auto;
    top: 1px; }
  .auth-style .ant-tabs-bottom .ant-tabs-bottom-bar .ant-tabs-nav-container {
    margin-bottom: 0;
    margin-top: -1px; }
  .auth-style .ant-tabs-tab-next,
  .auth-style .ant-tabs-tab-prev {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 2;
    width: 0;
    height: 100%;
    cursor: pointer;
    border: 0;
    background-color: transparent;
    position: absolute;
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
    transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 0;
    pointer-events: none; }
  .auth-style .ant-tabs-tab-next.ant-tabs-tab-arrow-show,
  .auth-style .ant-tabs-tab-prev.ant-tabs-tab-arrow-show {
    opacity: 1;
    width: 32px;
    height: 100%;
    pointer-events: auto; }
  .auth-style .ant-tabs-tab-next:hover,
  .auth-style .ant-tabs-tab-prev:hover {
    color: rgba(0, 0, 0, 0.65); }
  .auth-style .ant-tabs-tab-next-icon,
  .auth-style .ant-tabs-tab-prev-icon {
    font-style: normal;
    font-weight: 700;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    line-height: inherit;
    vertical-align: baseline;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    text-transform: none; }
  .auth-style .ant-tabs-tab-next-icon-target,
  .auth-style .ant-tabs-tab-prev-icon-target {
    display: block;
    display: inline-block;
    font-size: 12px;
    font-size: 10px\9;
    -webkit-transform: scale(0.83333) rotate(0deg);
    transform: scale(0.83333) rotate(0deg); }
  .auth-style :root .ant-tabs-tab-next-icon-target,
  .auth-style :root .ant-tabs-tab-prev-icon-target {
    font-size: 12px; }
  .auth-style .ant-tabs-tab-btn-disabled {
    cursor: not-allowed; }
  .auth-style .ant-tabs-tab-btn-disabled,
  .auth-style .ant-tabs-tab-btn-disabled:hover {
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-tabs-tab-next {
    right: 2px; }
  .auth-style .ant-tabs-tab-prev {
    left: 0; }
  .auth-style :root .ant-tabs-tab-prev {
    -webkit-filter: none;
    filter: none; }
  .auth-style .ant-tabs-nav-wrap {
    overflow: hidden;
    margin-bottom: -1px; }
  .auth-style .ant-tabs-nav-scroll {
    overflow: hidden;
    white-space: nowrap; }
  .auth-style .ant-tabs-nav {
    box-sizing: border-box;
    padding-left: 0;
    transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    position: relative;
    margin: 0;
    list-style: none;
    display: inline-block; }
  .auth-style .ant-tabs-nav:after,
  .auth-style .ant-tabs-nav:before {
    display: table;
    content: " "; }
  .auth-style .ant-tabs-nav:after {
    clear: both; }
  .auth-style .ant-tabs-nav .ant-tabs-tab {
    display: inline-block;
    height: 100%;
    margin: 0 32px 0 0;
    padding: 12px 16px;
    box-sizing: border-box;
    position: relative;
    transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    cursor: pointer;
    text-decoration: none; }
  .auth-style .ant-tabs-nav .ant-tabs-tab:last-child {
    margin-right: 0; }
  .auth-style .ant-tabs-nav .ant-tabs-tab:hover {
    color: #40a9ff; }
  .auth-style .ant-tabs-nav .ant-tabs-tab:active {
    color: #096dd9; }
  .auth-style .ant-tabs-nav .ant-tabs-tab .anticon {
    margin-right: 8px; }
  .auth-style .ant-tabs-nav .ant-tabs-tab-disabled,
  .auth-style .ant-tabs-nav .ant-tabs-tab-disabled:hover {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-tabs-nav .ant-tabs-tab-active {
    color: #1890ff;
    font-weight: 500; }
  .auth-style .ant-tabs .ant-tabs-large-bar .ant-tabs-nav-container {
    font-size: 16px; }
  .auth-style .ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
    padding: 16px; }
  .auth-style .ant-tabs .ant-tabs-small-bar .ant-tabs-nav-container {
    font-size: 14px; }
  .auth-style .ant-tabs .ant-tabs-small-bar .ant-tabs-tab {
    padding: 8px 16px; }
  .auth-style .ant-tabs .ant-tabs-bottom-content,
  .auth-style .ant-tabs .ant-tabs-top-content {
    width: 100%; }
  .auth-style .ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane,
  .auth-style .ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane {
    flex-shrink: 0;
    width: 100%;
    transition: opacity 0.45s;
    opacity: 1; }
  .auth-style .ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane-inactive,
  .auth-style .ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane-inactive {
    opacity: 0;
    height: 0;
    padding: 0 !important;
    pointer-events: none; }
  .auth-style .ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane-inactive input,
  .auth-style .ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane-inactive input {
    visibility: hidden; }
  .auth-style .ant-tabs .ant-tabs-bottom-content.ant-tabs-content-animated,
  .auth-style .ant-tabs .ant-tabs-top-content.ant-tabs-content-animated {
    display: flex;
    flex-direction: row;
    will-change: margin-left;
    transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .auth-style .ant-tabs .ant-tabs-left-bar,
  .auth-style .ant-tabs .ant-tabs-right-bar {
    border-bottom: 0;
    height: 100%; }
  .auth-style .ant-tabs .ant-tabs-left-bar-tab-next,
  .auth-style .ant-tabs .ant-tabs-left-bar-tab-prev,
  .auth-style .ant-tabs .ant-tabs-right-bar-tab-next,
  .auth-style .ant-tabs .ant-tabs-right-bar-tab-prev {
    width: 32px;
    height: 0;
    transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .auth-style .ant-tabs .ant-tabs-left-bar-tab-next.ant-tabs-tab-arrow-show,
  .auth-style .ant-tabs .ant-tabs-left-bar-tab-prev.ant-tabs-tab-arrow-show,
  .auth-style .ant-tabs .ant-tabs-right-bar-tab-next.ant-tabs-tab-arrow-show,
  .auth-style .ant-tabs .ant-tabs-right-bar-tab-prev.ant-tabs-tab-arrow-show {
    width: 100%;
    height: 32px; }
  .auth-style .ant-tabs .ant-tabs-left-bar .ant-tabs-tab,
  .auth-style .ant-tabs .ant-tabs-right-bar .ant-tabs-tab {
    float: none;
    margin: 0 0 16px;
    padding: 8px 24px;
    display: block; }
  .auth-style .ant-tabs .ant-tabs-left-bar .ant-tabs-tab:last-child,
  .auth-style .ant-tabs .ant-tabs-right-bar .ant-tabs-tab:last-child {
    margin-bottom: 0; }
  .auth-style .ant-tabs .ant-tabs-left-bar .ant-tabs-extra-content,
  .auth-style .ant-tabs .ant-tabs-right-bar .ant-tabs-extra-content {
    text-align: center; }
  .auth-style .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-scroll,
  .auth-style .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-scroll {
    width: auto; }
  .auth-style .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container,
  .auth-style .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap,
  .auth-style .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container,
  .auth-style .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
    height: 100%; }
  .auth-style .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container,
  .auth-style .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container {
    margin-bottom: 0; }
  .auth-style .ant-tabs
.ant-tabs-left-bar
.ant-tabs-nav-container.ant-tabs-nav-container-scrolling,
  .auth-style .ant-tabs
.ant-tabs-right-bar
.ant-tabs-nav-container.ant-tabs-nav-container-scrolling {
    padding: 32px 0; }
  .auth-style .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap,
  .auth-style .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
    margin-bottom: 0; }
  .auth-style .ant-tabs .ant-tabs-left-bar .ant-tabs-nav,
  .auth-style .ant-tabs .ant-tabs-right-bar .ant-tabs-nav {
    width: 100%; }
  .auth-style .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar,
  .auth-style .ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
    width: 2px;
    top: 0;
    left: auto;
    height: auto;
    bottom: auto; }
  .auth-style .ant-tabs .ant-tabs-left-bar .ant-tabs-tab-next,
  .auth-style .ant-tabs .ant-tabs-right-bar .ant-tabs-tab-next {
    width: 100%;
    bottom: 0;
    height: 32px; }
  .auth-style .ant-tabs .ant-tabs-left-bar .ant-tabs-tab-prev,
  .auth-style .ant-tabs .ant-tabs-right-bar .ant-tabs-tab-prev {
    top: 0;
    width: 100%;
    height: 32px; }
  .auth-style .ant-tabs .ant-tabs-left-content,
  .auth-style .ant-tabs .ant-tabs-right-content {
    overflow: hidden;
    width: auto;
    margin-top: 0 !important; }
  .auth-style .ant-tabs .ant-tabs-left-bar {
    float: left;
    border-right: 1px solid #e8e8e8;
    margin-right: -1px;
    margin-bottom: 0; }
  .auth-style .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
    text-align: right; }
  .auth-style .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-container,
  .auth-style .ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap {
    margin-right: -1px; }
  .auth-style .ant-tabs .ant-tabs-left-bar .ant-tabs-ink-bar {
    right: 1px; }
  .auth-style .ant-tabs .ant-tabs-left-content {
    padding-left: 24px;
    border-left: 1px solid #e8e8e8; }
  .auth-style .ant-tabs .ant-tabs-right-bar {
    float: right;
    border-left: 1px solid #e8e8e8;
    margin-left: -1px;
    margin-bottom: 0; }
  .auth-style .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-container,
  .auth-style .ant-tabs .ant-tabs-right-bar .ant-tabs-nav-wrap {
    margin-left: -1px; }
  .auth-style .ant-tabs .ant-tabs-right-bar .ant-tabs-ink-bar {
    left: 1px; }
  .auth-style .ant-tabs .ant-tabs-right-content {
    padding-right: 24px;
    border-right: 1px solid #e8e8e8; }
  .auth-style .ant-tabs-bottom .ant-tabs-ink-bar-animated,
  .auth-style .ant-tabs-top .ant-tabs-ink-bar-animated {
    transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .auth-style .ant-tabs-left .ant-tabs-ink-bar-animated,
  .auth-style .ant-tabs-right .ant-tabs-ink-bar-animated {
    transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .auth-style .ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-content-animated,
  .auth-style .no-flex > .ant-tabs-content > .ant-tabs-content-animated {
    -webkit-transform: none !important;
    transform: none !important;
    margin-left: 0 !important; }
  .auth-style .ant-tabs-no-animation > .ant-tabs-content > .ant-tabs-tabpane-inactive,
  .auth-style .no-flex > .ant-tabs-content > .ant-tabs-tabpane-inactive {
    display: none; }
  .auth-style .ant-tabs-left-content > .ant-tabs-content-animated,
  .auth-style .ant-tabs-right-content > .ant-tabs-content-animated {
    -webkit-transform: none !important;
    transform: none !important;
    margin-left: 0 !important; }
  .auth-style .ant-tabs-left-content > .ant-tabs-tabpane-inactive,
  .auth-style .ant-tabs-right-content > .ant-tabs-tabpane-inactive {
    display: none; }
  .auth-style .ant-tag {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    list-style: none;
    display: inline-block;
    line-height: 20px;
    height: 22px;
    padding: 0 7px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    background: #fafafa;
    font-size: 12px;
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    opacity: 1;
    margin: 0 8px 0 0;
    cursor: pointer;
    white-space: nowrap; }
  .auth-style .ant-tag:hover {
    opacity: 0.85; }
  .auth-style .ant-tag,
  .auth-style .ant-tag a,
  .auth-style .ant-tag a:hover {
    color: rgba(0, 0, 0, 0.65); }
  .auth-style .ant-tag > a:first-child:last-child {
    display: inline-block;
    margin: 0 -8px;
    padding: 0 8px; }
  .auth-style .ant-tag .anticon-close {
    display: inline-block;
    font-size: 12px;
    font-size: 10px\9;
    -webkit-transform: scale(0.83333) rotate(0deg);
    transform: scale(0.83333) rotate(0deg);
    cursor: pointer;
    margin-left: 3px;
    transition: all 0.3s;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700; }
  .auth-style :root .ant-tag .anticon-close {
    font-size: 12px; }
  .auth-style .ant-tag .anticon-close:hover {
    color: rgba(0, 0, 0, 0.85); }
  .auth-style .ant-tag-has-color {
    border-color: transparent; }
  .auth-style .ant-tag-has-color,
  .auth-style .ant-tag-has-color .anticon-close,
  .auth-style .ant-tag-has-color .anticon-close:hover,
  .auth-style .ant-tag-has-color a,
  .auth-style .ant-tag-has-color a:hover {
    color: #fff; }
  .auth-style .ant-tag-checkable {
    background-color: transparent;
    border-color: transparent; }
  .auth-style .ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
    color: #1890ff; }
  .auth-style .ant-tag-checkable-checked,
  .auth-style .ant-tag-checkable:active {
    color: #fff; }
  .auth-style .ant-tag-checkable-checked {
    background-color: #1890ff; }
  .auth-style .ant-tag-checkable:active {
    background-color: #096dd9; }
  .auth-style .ant-tag-close {
    width: 0 !important;
    padding: 0;
    margin: 0; }
  .auth-style .ant-tag-zoom-appear,
  .auth-style .ant-tag-zoom-enter {
    -webkit-animation: antFadeIn 0.2s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation: antFadeIn 0.2s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; }
  .auth-style .ant-tag-zoom-leave {
    -webkit-animation: antZoomOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation: antZoomOut 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; }
  .auth-style .ant-tag-hidden {
    display: none; }
  .auth-style .ant-tag-pink {
    color: #eb2f96;
    background: #fff0f6;
    border-color: #ffadd2; }
  .auth-style .ant-tag-pink-inverse {
    background: #eb2f96;
    border-color: #eb2f96;
    color: #fff; }
  .auth-style .ant-tag-magenta {
    color: #eb2f96;
    background: #fff0f6;
    border-color: #ffadd2; }
  .auth-style .ant-tag-magenta-inverse {
    background: #eb2f96;
    border-color: #eb2f96;
    color: #fff; }
  .auth-style .ant-tag-red {
    color: #f5222d;
    background: #fff1f0;
    border-color: #ffa39e; }
  .auth-style .ant-tag-red-inverse {
    background: #f5222d;
    border-color: #f5222d;
    color: #fff; }
  .auth-style .ant-tag-volcano {
    color: #fa541c;
    background: #fff2e8;
    border-color: #ffbb96; }
  .auth-style .ant-tag-volcano-inverse {
    background: #fa541c;
    border-color: #fa541c;
    color: #fff; }
  .auth-style .ant-tag-orange {
    color: #fa8c16;
    background: #fff7e6;
    border-color: #ffd591; }
  .auth-style .ant-tag-orange-inverse {
    background: #fa8c16;
    border-color: #fa8c16;
    color: #fff; }
  .auth-style .ant-tag-yellow {
    color: #fadb14;
    background: #feffe6;
    border-color: #fffb8f; }
  .auth-style .ant-tag-yellow-inverse {
    background: #fadb14;
    border-color: #fadb14;
    color: #fff; }
  .auth-style .ant-tag-gold {
    color: #faad14;
    background: #fffbe6;
    border-color: #ffe58f; }
  .auth-style .ant-tag-gold-inverse {
    background: #faad14;
    border-color: #faad14;
    color: #fff; }
  .auth-style .ant-tag-cyan {
    color: #13c2c2;
    background: #e6fffb;
    border-color: #87e8de; }
  .auth-style .ant-tag-cyan-inverse {
    background: #13c2c2;
    border-color: #13c2c2;
    color: #fff; }
  .auth-style .ant-tag-lime {
    color: #a0d911;
    background: #fcffe6;
    border-color: #eaff8f; }
  .auth-style .ant-tag-lime-inverse {
    background: #a0d911;
    border-color: #a0d911;
    color: #fff; }
  .auth-style .ant-tag-green {
    color: #52c41a;
    background: #f6ffed;
    border-color: #b7eb8f; }
  .auth-style .ant-tag-green-inverse {
    background: #52c41a;
    border-color: #52c41a;
    color: #fff; }
  .auth-style .ant-tag-blue {
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff; }
  .auth-style .ant-tag-blue-inverse {
    background: #1890ff;
    border-color: #1890ff;
    color: #fff; }
  .auth-style .ant-tag-geekblue {
    color: #2f54eb;
    background: #f0f5ff;
    border-color: #adc6ff; }
  .auth-style .ant-tag-geekblue-inverse {
    background: #2f54eb;
    border-color: #2f54eb;
    color: #fff; }
  .auth-style .ant-tag-purple {
    color: #722ed1;
    background: #f9f0ff;
    border-color: #d3adf7; }
  .auth-style .ant-tag-purple-inverse {
    background: #722ed1;
    border-color: #722ed1;
    color: #fff; }
  .auth-style .ant-time-picker-panel {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    font-family: Chinese Quote, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    z-index: 1050;
    position: absolute; }
  .auth-style .ant-time-picker-panel-inner {
    position: relative;
    outline: none;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    background-clip: padding-box;
    left: -2px; }
  .auth-style .ant-time-picker-panel-input {
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    max-width: 154px;
    cursor: auto;
    outline: 0;
    line-height: normal; }
  .auth-style .ant-time-picker-panel-input::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1; }
  .auth-style .ant-time-picker-panel-input:-ms-input-placeholder {
    color: #bfbfbf; }
  .auth-style .ant-time-picker-panel-input::-webkit-input-placeholder {
    color: #bfbfbf; }
  .auth-style .ant-time-picker-panel-input-wrap {
    box-sizing: border-box;
    position: relative;
    padding: 7px 2px 7px 12px;
    border-bottom: 1px solid #e8e8e8; }
  .auth-style .ant-time-picker-panel-input-invalid {
    border-color: #f5222d; }
  .auth-style .ant-time-picker-panel-clear-btn {
    position: absolute;
    right: 8px;
    cursor: pointer;
    overflow: hidden;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    top: 7px;
    margin: 0; }
  .auth-style .ant-time-picker-panel-clear-btn-icon svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.25);
    display: inline-block;
    transition: color 0.3s ease; }
  .auth-style .ant-time-picker-panel-clear-btn-icon svg:hover {
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-time-picker-panel-narrow .ant-time-picker-panel-input-wrap {
    max-width: 112px; }
  .auth-style .ant-time-picker-panel-select {
    float: left;
    font-size: 14px;
    border-left: 1px solid #e8e8e8;
    box-sizing: border-box;
    width: 56px;
    overflow: hidden;
    position: relative;
    max-height: 192px; }
  .auth-style .ant-time-picker-panel-select:hover {
    overflow-y: auto; }
  .auth-style .ant-time-picker-panel-select:first-child {
    border-left: 0;
    margin-left: 0; }
  .auth-style .ant-time-picker-panel-select:last-child {
    border-right: 0; }
  .auth-style .ant-time-picker-panel-select:only-child {
    width: 100%; }
  .auth-style .ant-time-picker-panel-select ul {
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0 0 160px;
    width: 100%; }
  .auth-style .ant-time-picker-panel-select li {
    list-style: none;
    box-sizing: content-box;
    margin: 0;
    padding: 0 0 0 12px;
    width: 100%;
    height: 32px;
    line-height: 32px;
    text-align: left;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: background 0.3s; }
  .auth-style .ant-time-picker-panel-select li:hover {
    background: #e6f7ff; }
  .auth-style li.ant-time-picker-panel-select-option-selected {
    background: #f5f5f5;
    font-weight: 700; }
  .auth-style li.ant-time-picker-panel-select-option-selected:hover {
    background: #f5f5f5; }
  .auth-style li.ant-time-picker-panel-select-option-disabled {
    color: rgba(0, 0, 0, 0.25); }
  .auth-style li.ant-time-picker-panel-select-option-disabled:hover {
    background: transparent;
    cursor: not-allowed; }
  .auth-style .ant-time-picker-panel-combobox {
    zoom: 1; }
  .auth-style .ant-time-picker-panel-combobox:after,
  .auth-style .ant-time-picker-panel-combobox:before {
    content: "";
    display: table; }
  .auth-style .ant-time-picker-panel-combobox:after {
    clear: both; }
  .auth-style .ant-time-picker-panel-addon {
    padding: 8px;
    border-top: 1px solid #e8e8e8; }
  .auth-style .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topLeft,
  .auth-style .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-topRight,
  .auth-style .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topLeft,
  .auth-style .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-topRight {
    -webkit-animation-name: antSlideDownIn;
    animation-name: antSlideDownIn; }
  .auth-style .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomLeft,
  .auth-style .ant-time-picker-panel.slide-up-appear.slide-up-appear-active.ant-time-picker-panel-placement-bottomRight,
  .auth-style .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomLeft,
  .auth-style .ant-time-picker-panel.slide-up-enter.slide-up-enter-active.ant-time-picker-panel-placement-bottomRight {
    -webkit-animation-name: antSlideUpIn;
    animation-name: antSlideUpIn; }
  .auth-style .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topLeft,
  .auth-style .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-topRight {
    -webkit-animation-name: antSlideDownOut;
    animation-name: antSlideDownOut; }
  .auth-style .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomLeft,
  .auth-style .ant-time-picker-panel.slide-up-leave.slide-up-leave-active.ant-time-picker-panel-placement-bottomRight {
    -webkit-animation-name: antSlideUpOut;
    animation-name: antSlideUpOut; }
  .auth-style .ant-time-picker {
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    outline: none;
    transition: opacity 0.3s;
    width: 128px; }
  .auth-style .ant-time-picker,
  .auth-style .ant-time-picker-input {
    font-size: 14px;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    position: relative;
    display: inline-block; }
  .auth-style .ant-time-picker-input {
    padding: 4px 11px;
    width: 100%;
    height: 32px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all 0.3s; }
  .auth-style .ant-time-picker-input::-moz-placeholder {
    color: #bfbfbf;
    opacity: 1; }
  .auth-style .ant-time-picker-input:-ms-input-placeholder {
    color: #bfbfbf; }
  .auth-style .ant-time-picker-input::-webkit-input-placeholder {
    color: #bfbfbf; }
  .auth-style .ant-time-picker-input:focus,
  .auth-style .ant-time-picker-input:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important; }
  .auth-style .ant-time-picker-input:focus {
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); }
  .auth-style .ant-time-picker-input-disabled {
    background-color: #f5f5f5;
    opacity: 1;
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-time-picker-input-disabled:hover {
    border-color: #e6d8d8;
    border-right-width: 1px !important; }
  .auth-style textarea.ant-time-picker-input {
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
    min-height: 32px; }
  .auth-style .ant-time-picker-input-lg {
    padding: 6px 11px;
    height: 40px;
    font-size: 16px; }
  .auth-style .ant-time-picker-input-sm {
    padding: 1px 7px;
    height: 24px; }
  .auth-style .ant-time-picker-input[disabled] {
    background-color: #f5f5f5;
    opacity: 1;
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-time-picker-input[disabled]:hover {
    border-color: #e6d8d8;
    border-right-width: 1px !important; }
  .auth-style .ant-time-picker-open {
    opacity: 0; }
  .auth-style .ant-time-picker-icon {
    position: absolute;
    z-index: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 14px;
    height: 14px;
    line-height: 14px;
    right: 11px;
    color: rgba(0, 0, 0, 0.25);
    top: 50%;
    margin-top: -7px; }
  .auth-style .ant-time-picker-icon .ant-time-picker-clock-icon {
    color: rgba(0, 0, 0, 0.25);
    display: block;
    line-height: 1; }
  .auth-style .ant-time-picker-large .ant-time-picker-input {
    padding: 6px 11px;
    height: 40px;
    font-size: 16px; }
  .auth-style .ant-time-picker-small .ant-time-picker-input {
    padding: 1px 7px;
    height: 24px; }
  .auth-style .ant-time-picker-small .ant-time-picker-icon {
    right: 7px; }
  .auth-style .ant-timeline {
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    padding: 0; }
  .auth-style .ant-timeline,
  .auth-style .ant-timeline-item {
    font-size: 14px;
    list-style: none;
    margin: 0; }
  .auth-style .ant-timeline-item {
    position: relative;
    padding: 0 0 20px; }
  .auth-style .ant-timeline-item-tail {
    position: absolute;
    left: 4px;
    top: 0.75em;
    height: 100%;
    border-left: 2px solid #e8e8e8; }
  .auth-style .ant-timeline-item-pending .ant-timeline-item-head {
    font-size: 12px; }
  .auth-style .ant-timeline-item-pending .ant-timeline-item-tail {
    display: none; }
  .auth-style .ant-timeline-item-head {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #fff;
    border-radius: 100px;
    border: 2px solid transparent; }
  .auth-style .ant-timeline-item-head-blue {
    border-color: #1890ff;
    color: #1890ff; }
  .auth-style .ant-timeline-item-head-red {
    border-color: #f5222d;
    color: #f5222d; }
  .auth-style .ant-timeline-item-head-green {
    border-color: #52c41a;
    color: #52c41a; }
  .auth-style .ant-timeline-item-head-custom {
    position: absolute;
    text-align: center;
    line-height: 1;
    margin-top: 0;
    border: 0;
    height: auto;
    border-radius: 0;
    padding: 3px 1px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 5.5px;
    left: 5px;
    width: auto; }
  .auth-style .ant-timeline-item-content {
    margin: 0 0 0 18px;
    position: relative;
    top: -6px; }
  .auth-style .ant-timeline-item-last > .ant-timeline-item-tail {
    display: none; }
  .auth-style .ant-timeline-item-last > .ant-timeline-item-content {
    min-height: 48px; }
  .auth-style .ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
  .auth-style .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
  .auth-style .ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
  .auth-style .ant-timeline.ant-timeline-right .ant-timeline-item-head,
  .auth-style .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
  .auth-style .ant-timeline.ant-timeline-right .ant-timeline-item-tail {
    left: 50%; }
  .auth-style .ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
  .auth-style .ant-timeline.ant-timeline-right .ant-timeline-item-head {
    margin-left: -4px; }
  .auth-style .ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
  .auth-style .ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
    margin-left: 1px; }
  .auth-style .ant-timeline.ant-timeline-alternate
.ant-timeline-item-left
.ant-timeline-item-content,
  .auth-style .ant-timeline.ant-timeline-right
.ant-timeline-item-left
.ant-timeline-item-content {
    text-align: left;
    left: 50%;
    width: 50%; }
  .auth-style .ant-timeline.ant-timeline-alternate
.ant-timeline-item-right
.ant-timeline-item-content,
  .auth-style .ant-timeline.ant-timeline-right
.ant-timeline-item-right
.ant-timeline-item-content {
    text-align: right;
    right: 50%;
    margin-right: 18px;
    width: 50%;
    left: -30px; }
  .auth-style .ant-timeline.ant-timeline-right
.ant-timeline-item-right
.ant-timeline-item-head,
  .auth-style .ant-timeline.ant-timeline-right
.ant-timeline-item-right
.ant-timeline-item-head-custom,
  .auth-style .ant-timeline.ant-timeline-right
.ant-timeline-item-right
.ant-timeline-item-tail {
    left: 100%; }
  .auth-style .ant-timeline.ant-timeline-right
.ant-timeline-item-right
.ant-timeline-item-content {
    right: 0;
    width: 100%;
    left: -30px; }
  .auth-style .ant-timeline.ant-timeline-pending
.ant-timeline-item-last
.ant-timeline-item-tail {
    border-left: 2px dotted #e8e8e8;
    display: block; }
  .auth-style .ant-timeline.ant-timeline-reverse
.ant-timeline-item-last
.ant-timeline-item-tail {
    display: none; }
  .auth-style .ant-timeline.ant-timeline-reverse
.ant-timeline-item-pending
.ant-timeline-item-tail {
    border-left: 2px dotted #e8e8e8;
    display: block; }
  .auth-style .ant-timeline.ant-timeline-reverse
.ant-timeline-item-pending
.ant-timeline-item-content {
    min-height: 48px; }
  .auth-style .ant-tooltip {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    z-index: 1060;
    display: block;
    visibility: visible;
    max-width: 250px; }
  .auth-style .ant-tooltip-hidden {
    display: none; }
  .auth-style .ant-tooltip-placement-top,
  .auth-style .ant-tooltip-placement-topLeft,
  .auth-style .ant-tooltip-placement-topRight {
    padding-bottom: 8px; }
  .auth-style .ant-tooltip-placement-right,
  .auth-style .ant-tooltip-placement-rightBottom,
  .auth-style .ant-tooltip-placement-rightTop {
    padding-left: 8px; }
  .auth-style .ant-tooltip-placement-bottom,
  .auth-style .ant-tooltip-placement-bottomLeft,
  .auth-style .ant-tooltip-placement-bottomRight {
    padding-top: 8px; }
  .auth-style .ant-tooltip-placement-left,
  .auth-style .ant-tooltip-placement-leftBottom,
  .auth-style .ant-tooltip-placement-leftTop {
    padding-right: 8px; }
  .auth-style .ant-tooltip-inner {
    padding: 6px 8px;
    color: #fff;
    text-align: left;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    min-height: 32px;
    word-wrap: break-word; }
  .auth-style .ant-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid; }
  .auth-style .ant-tooltip-placement-top .ant-tooltip-arrow,
  .auth-style .ant-tooltip-placement-topLeft .ant-tooltip-arrow,
  .auth-style .ant-tooltip-placement-topRight .ant-tooltip-arrow {
    bottom: 3px;
    border-width: 5px 5px 0;
    border-top-color: rgba(0, 0, 0, 0.75); }
  .auth-style .ant-tooltip-placement-top .ant-tooltip-arrow {
    left: 50%;
    margin-left: -5px; }
  .auth-style .ant-tooltip-placement-topLeft .ant-tooltip-arrow {
    left: 16px; }
  .auth-style .ant-tooltip-placement-topRight .ant-tooltip-arrow {
    right: 16px; }
  .auth-style .ant-tooltip-placement-right .ant-tooltip-arrow,
  .auth-style .ant-tooltip-placement-rightBottom .ant-tooltip-arrow,
  .auth-style .ant-tooltip-placement-rightTop .ant-tooltip-arrow {
    left: 3px;
    border-width: 5px 5px 5px 0;
    border-right-color: rgba(0, 0, 0, 0.75); }
  .auth-style .ant-tooltip-placement-right .ant-tooltip-arrow {
    top: 50%;
    margin-top: -5px; }
  .auth-style .ant-tooltip-placement-rightTop .ant-tooltip-arrow {
    top: 8px; }
  .auth-style .ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
    bottom: 8px; }
  .auth-style .ant-tooltip-placement-left .ant-tooltip-arrow,
  .auth-style .ant-tooltip-placement-leftBottom .ant-tooltip-arrow,
  .auth-style .ant-tooltip-placement-leftTop .ant-tooltip-arrow {
    right: 3px;
    border-width: 5px 0 5px 5px;
    border-left-color: rgba(0, 0, 0, 0.75); }
  .auth-style .ant-tooltip-placement-left .ant-tooltip-arrow {
    top: 50%;
    margin-top: -5px; }
  .auth-style .ant-tooltip-placement-leftTop .ant-tooltip-arrow {
    top: 8px; }
  .auth-style .ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
    bottom: 8px; }
  .auth-style .ant-tooltip-placement-bottom .ant-tooltip-arrow,
  .auth-style .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
  .auth-style .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    top: 3px;
    border-width: 0 5px 5px;
    border-bottom-color: rgba(0, 0, 0, 0.75); }
  .auth-style .ant-tooltip-placement-bottom .ant-tooltip-arrow {
    left: 50%;
    margin-left: -5px; }
  .auth-style .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
    left: 16px; }
  .auth-style .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    right: 16px; }
  .auth-style .ant-transfer {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: relative; }
  .auth-style .ant-transfer-disabled .ant-transfer-list {
    background: #f5f5f5; }
  .auth-style .ant-transfer-list {
    border: 1px solid #d9d9d9;
    display: inline-block;
    border-radius: 4px;
    vertical-align: middle;
    position: relative;
    width: 180px;
    height: 200px;
    padding-top: 34px; }
  .auth-style .ant-transfer-list-with-footer {
    padding-bottom: 34px; }
  .auth-style .ant-transfer-list-search {
    padding: 0 8px; }
  .auth-style .ant-transfer-list-search-action {
    color: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 4px;
    right: 4px;
    bottom: 4px;
    width: 28px;
    line-height: 32px;
    text-align: center; }
  .auth-style .ant-transfer-list-search-action .anticon {
    transition: all 0.3s;
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-transfer-list-search-action .anticon:hover {
    color: rgba(0, 0, 0, 0.45); }
  .auth-style span.ant-transfer-list-search-action {
    pointer-events: none; }
  .auth-style .ant-transfer-list-header {
    padding: 6px 12px;
    border-radius: 4px 4px 0 0;
    background: #fff;
    color: rgba(0, 0, 0, 0.65);
    border-bottom: 1px solid #e8e8e8;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
  .auth-style .ant-transfer-list-header-title {
    position: absolute;
    right: 12px; }
  .auth-style .ant-transfer-list-body {
    font-size: 14px;
    position: relative;
    height: 100%; }
  .auth-style .ant-transfer-list-body-search-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    padding: 4px;
    width: 100%; }
  .auth-style .ant-transfer-list-body-with-search {
    padding-top: 40px; }
  .auth-style .ant-transfer-list-content {
    height: 100%;
    overflow: auto;
    list-style: none;
    padding: 0;
    margin: 0; }
  .auth-style .ant-transfer-list-content > .LazyLoad {
    -webkit-animation: transferHighlightIn 1s;
    animation: transferHighlightIn 1s; }
  .auth-style .ant-transfer-list-content-item {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 6px 12px;
    min-height: 32px;
    transition: all 0.3s; }
  .auth-style .ant-transfer-list-content-item > span {
    padding-right: 0; }
  .auth-style .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
    cursor: pointer;
    background-color: #e6f7ff; }
  .auth-style .ant-transfer-list-content-item-disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-transfer-list-body-not-found {
    padding-top: 0;
    color: rgba(0, 0, 0, 0.25);
    text-align: center;
    position: absolute;
    top: 50%;
    width: 100%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); }
  .auth-style .ant-transfer-list-body-with-search .ant-transfer-list-body-not-found {
    margin-top: 16px; }
  .auth-style .ant-transfer-list-footer {
    border-top: 1px solid #e8e8e8;
    border-radius: 0 0 4px 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; }
  .auth-style .ant-transfer-operation {
    display: inline-block;
    overflow: hidden;
    margin: 0 8px;
    vertical-align: middle; }
  .auth-style .ant-transfer-operation .ant-btn {
    display: block; }
  .auth-style .ant-transfer-operation .ant-btn:first-child {
    margin-bottom: 4px; }
  .auth-style .ant-transfer-operation .ant-btn .anticon {
    font-size: 12px; }

@-webkit-keyframes transferHighlightIn {
  0% {
    background: #bae7ff; }
  to {
    background: transparent; } }

@keyframes transferHighlightIn {
  0% {
    background: #bae7ff; }
  to {
    background: transparent; } }
  .auth-style .ant-select-tree-checkbox {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    display: inline-block;
    line-height: 1;
    position: relative;
    vertical-align: middle;
    top: -0.09em; }
  .auth-style .ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner,
  .auth-style .ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
  .auth-style .ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner {
    border-color: #1890ff; }
  .auth-style .ant-select-tree-checkbox-checked:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    border: 1px solid #1890ff;
    content: "";
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
    animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: hidden; }
  .auth-style .ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox:after,
  .auth-style .ant-select-tree-checkbox:hover:after {
    visibility: visible; }
  .auth-style .ant-select-tree-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    background-color: #fff;
    transition: all 0.3s;
    border-collapse: separate; }
  .auth-style .ant-select-tree-checkbox-inner:after {
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
    position: absolute;
    left: 4.57142857px;
    top: 1.14285714px;
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    content: " ";
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    opacity: 0; }
  .auth-style .ant-select-tree-checkbox-input {
    position: absolute;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%; }
  .auth-style .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner:after {
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    content: " ";
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    opacity: 1; }
  .auth-style .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
    background-color: #1890ff;
    border-color: #1890ff; }
  .auth-style .ant-select-tree-checkbox-disabled {
    cursor: not-allowed; }
  .auth-style .ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked
.ant-select-tree-checkbox-inner:after {
    -webkit-animation-name: none;
    animation-name: none;
    border-color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
    cursor: not-allowed; }
  .auth-style .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
    border-color: #d9d9d9 !important;
    background-color: #f5f5f5; }
  .auth-style .ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner:after {
    -webkit-animation-name: none;
    animation-name: none;
    border-color: #f5f5f5;
    border-collapse: separate; }
  .auth-style .ant-select-tree-checkbox-disabled + span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed; }
  .auth-style .ant-select-tree-checkbox-wrapper {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: unset;
    cursor: pointer;
    display: inline-block; }
  .auth-style .ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
    margin-left: 8px; }
  .auth-style .ant-select-tree-checkbox + span,
  .auth-style .ant-select-tree-checkbox-wrapper + span {
    padding-left: 8px;
    padding-right: 8px; }
  .auth-style .ant-select-tree-checkbox-group {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block; }
  .auth-style .ant-select-tree-checkbox-group-item {
    display: inline-block;
    margin-right: 8px; }
  .auth-style .ant-select-tree-checkbox-group-item:last-child {
    margin-right: 0; }
  .auth-style .ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
    margin-left: 0; }
  .auth-style .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
    background-color: #fff;
    border-color: #d9d9d9; }
  .auth-style .ant-select-tree-checkbox-indeterminate
.ant-select-tree-checkbox-inner:after {
    content: " ";
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    border: 0;
    left: 50%;
    top: 50%;
    width: 8px;
    height: 8px;
    background-color: #1890ff;
    opacity: 1; }
  .auth-style .ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled
.ant-select-tree-checkbox-inner:after {
    border-color: rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-select-tree {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    list-style: none;
    padding: 0 4px;
    margin: -4px 0 0; }
  .auth-style .ant-select-tree li {
    padding: 0;
    margin: 8px 0;
    list-style: none;
    white-space: nowrap;
    outline: 0; }
  .auth-style .ant-select-tree li.filter-node > span {
    font-weight: 500; }
  .auth-style .ant-select-tree li ul {
    margin: 0;
    padding: 0 0 0 18px; }
  .auth-style .ant-select-tree li .ant-select-tree-node-content-wrapper {
    display: inline-block;
    padding: 3px 5px;
    border-radius: 2px;
    margin: 0;
    cursor: pointer;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.65);
    transition: all 0.3s;
    width: calc(100% - 24px); }
  .auth-style .ant-select-tree li .ant-select-tree-node-content-wrapper:hover {
    background-color: #e6f7ff; }
  .auth-style .ant-select-tree
li
.ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
    background-color: #bae7ff; }
  .auth-style .ant-select-tree li span.ant-select-tree-checkbox {
    margin: 0 4px 0 0; }
  .auth-style .ant-select-tree
li
span.ant-select-tree-checkbox
+ .ant-select-tree-node-content-wrapper {
    width: calc(100% - 46px); }
  .auth-style .ant-select-tree li span.ant-select-tree-iconEle,
  .auth-style .ant-select-tree li span.ant-select-tree-switcher {
    margin: 0;
    width: 24px;
    height: 24px;
    line-height: 22px;
    display: inline-block;
    vertical-align: middle;
    border: 0;
    cursor: pointer;
    outline: none;
    text-align: center; }
  .auth-style .ant-select-tree
li
span.ant-select-icon_loading
.ant-select-switcher-loading-icon {
    display: inline-block;
    position: absolute;
    left: 0;
    color: #1890ff;
    -webkit-transform: none;
    transform: none;
    font-size: 14px; }
  .auth-style .ant-select-tree
li
span.ant-select-icon_loading
.ant-select-switcher-loading-icon
svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto; }
  .auth-style .ant-select-tree li span.ant-select-tree-switcher {
    position: relative; }
  .auth-style .ant-select-tree
li
span.ant-select-tree-switcher.ant-select-tree-switcher-noop {
    cursor: auto; }
  .auth-style .ant-select-tree
li
span.ant-select-tree-switcher.ant-select-tree-switcher_open
.ant-select-switcher-icon,
  .auth-style .ant-select-tree
li
span.ant-select-tree-switcher.ant-select-tree-switcher_open
.ant-tree-switcher-icon {
    font-size: 12px;
    font-size: 10px\9;
    -webkit-transform: scale(0.83333) rotate(0deg);
    transform: scale(0.83333) rotate(0deg);
    display: inline-block;
    font-weight: 700; }
  .auth-style :root
.ant-select-tree
li
span.ant-select-tree-switcher.ant-select-tree-switcher_open
.ant-select-switcher-icon,
  .auth-style :root
.ant-select-tree
li
span.ant-select-tree-switcher.ant-select-tree-switcher_open
.ant-tree-switcher-icon {
    font-size: 12px; }
  .auth-style .ant-select-tree
li
span.ant-select-tree-switcher.ant-select-tree-switcher_open
.ant-select-switcher-icon
svg,
  .auth-style .ant-select-tree
li
span.ant-select-tree-switcher.ant-select-tree-switcher_open
.ant-tree-switcher-icon
svg {
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s; }
  .auth-style .ant-select-tree
li
span.ant-select-tree-switcher.ant-select-tree-switcher_close
.ant-select-switcher-icon,
  .auth-style .ant-select-tree
li
span.ant-select-tree-switcher.ant-select-tree-switcher_close
.ant-tree-switcher-icon {
    font-size: 12px;
    font-size: 10px\9;
    -webkit-transform: scale(0.83333) rotate(0deg);
    transform: scale(0.83333) rotate(0deg);
    display: inline-block;
    font-weight: 700; }
  .auth-style :root
.ant-select-tree
li
span.ant-select-tree-switcher.ant-select-tree-switcher_close
.ant-select-switcher-icon,
  .auth-style :root
.ant-select-tree
li
span.ant-select-tree-switcher.ant-select-tree-switcher_close
.ant-tree-switcher-icon {
    font-size: 12px; }
  .auth-style .ant-select-tree
li
span.ant-select-tree-switcher.ant-select-tree-switcher_close
.ant-select-switcher-icon
svg,
  .auth-style .ant-select-tree
li
span.ant-select-tree-switcher.ant-select-tree-switcher_close
.ant-tree-switcher-icon
svg {
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s; }
  .auth-style .ant-select-tree
li
span.ant-select-tree-switcher.ant-select-tree-switcher_close
.ant-select-switcher-icon
svg {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg); }
  .auth-style .ant-select-tree
li
span.ant-select-tree-switcher.ant-select-tree-switcher_close
.ant-select-switcher-loading-icon,
  .auth-style .ant-select-tree
li
span.ant-select-tree-switcher.ant-select-tree-switcher_open
.ant-select-switcher-loading-icon {
    display: inline-block;
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
    color: #1890ff;
    -webkit-transform: none;
    transform: none;
    font-size: 14px; }
  .auth-style .ant-select-tree
li
span.ant-select-tree-switcher.ant-select-tree-switcher_close
.ant-select-switcher-loading-icon
svg,
  .auth-style .ant-select-tree
li
span.ant-select-tree-switcher.ant-select-tree-switcher_open
.ant-select-switcher-loading-icon
svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto; }
  .auth-style .ant-select-tree-child-tree,
  .auth-style .ant-select-tree .ant-select-tree-treenode-loading .ant-select-tree-iconEle {
    display: none; }
  .auth-style .ant-select-tree-child-tree-open {
    display: block; }
  .auth-style li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper,
  .auth-style li.ant-select-tree-treenode-disabled
> .ant-select-tree-node-content-wrapper
span,
  .auth-style li.ant-select-tree-treenode-disabled > span:not(.ant-select-tree-switcher) {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed; }
  .auth-style li.ant-select-tree-treenode-disabled
> .ant-select-tree-node-content-wrapper:hover {
    background: transparent; }
  .auth-style .ant-select-tree-icon__close,
  .auth-style .ant-select-tree-icon__open {
    margin-right: 2px;
    vertical-align: top; }
  .auth-style .ant-select-tree-dropdown {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none; }
  .auth-style .ant-select-tree-dropdown .ant-select-dropdown-search {
    display: block;
    padding: 4px; }
  .auth-style .ant-select-tree-dropdown
.ant-select-dropdown-search
.ant-select-search__field__wrap {
    width: 100%; }
  .auth-style .ant-select-tree-dropdown
.ant-select-dropdown-search
.ant-select-search__field {
    padding: 4px 7px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    outline: none; }
  .auth-style .ant-select-tree-dropdown
.ant-select-dropdown-search.ant-select-search--hide {
    display: none; }
  .auth-style .ant-select-tree-dropdown .ant-select-not-found {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25);
    padding: 7px 16px;
    display: block; }

@-webkit-keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  to {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0; } }

@keyframes antCheckboxEffect {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5; }
  to {
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    opacity: 0; } }
  .auth-style .ant-tree.ant-tree-directory {
    position: relative; }
  .auth-style .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-switcher,
  .auth-style .ant-tree.ant-tree-directory > li span.ant-tree-switcher {
    position: relative;
    z-index: 1; }
  .auth-style .ant-tree.ant-tree-directory
.ant-tree-child-tree
> li
span.ant-tree-switcher.ant-tree-switcher-noop,
  .auth-style .ant-tree.ant-tree-directory
> li
span.ant-tree-switcher.ant-tree-switcher-noop {
    pointer-events: none; }
  .auth-style .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-checkbox,
  .auth-style .ant-tree.ant-tree-directory > li span.ant-tree-checkbox {
    position: relative;
    z-index: 1; }
  .auth-style .ant-tree.ant-tree-directory
.ant-tree-child-tree
> li
span.ant-tree-node-content-wrapper,
  .auth-style .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 0; }
  .auth-style .ant-tree.ant-tree-directory
.ant-tree-child-tree
> li
span.ant-tree-node-content-wrapper:hover,
  .auth-style .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover {
    background: transparent; }
  .auth-style .ant-tree.ant-tree-directory
.ant-tree-child-tree
> li
span.ant-tree-node-content-wrapper:hover:before,
  .auth-style .ant-tree.ant-tree-directory
> li
span.ant-tree-node-content-wrapper:hover:before {
    background: #e6f7ff; }
  .auth-style .ant-tree.ant-tree-directory
.ant-tree-child-tree
> li
span.ant-tree-node-content-wrapper.ant-tree-node-selected,
  .auth-style .ant-tree.ant-tree-directory
> li
span.ant-tree-node-content-wrapper.ant-tree-node-selected {
    color: #fff;
    background: transparent; }
  .auth-style .ant-tree.ant-tree-directory
.ant-tree-child-tree
> li
span.ant-tree-node-content-wrapper:before,
  .auth-style .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    height: 24px;
    transition: all 0.3s; }
  .auth-style .ant-tree.ant-tree-directory
.ant-tree-child-tree
> li
span.ant-tree-node-content-wrapper
> span,
  .auth-style .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper > span {
    position: relative;
    z-index: 1; }
  .auth-style .ant-tree.ant-tree-directory
.ant-tree-child-tree
> li.ant-tree-treenode-selected
> span.ant-tree-switcher,
  .auth-style .ant-tree.ant-tree-directory
> li.ant-tree-treenode-selected
> span.ant-tree-switcher {
    color: #fff; }
  .auth-style .ant-tree.ant-tree-directory
.ant-tree-child-tree
> li.ant-tree-treenode-selected
> span.ant-tree-checkbox
.ant-tree-checkbox-inner,
  .auth-style .ant-tree.ant-tree-directory
> li.ant-tree-treenode-selected
> span.ant-tree-checkbox
.ant-tree-checkbox-inner {
    border-color: #1890ff; }
  .auth-style .ant-tree.ant-tree-directory
.ant-tree-child-tree
> li.ant-tree-treenode-selected
> span.ant-tree-checkbox.ant-tree-checkbox-checked:after,
  .auth-style .ant-tree.ant-tree-directory
> li.ant-tree-treenode-selected
> span.ant-tree-checkbox.ant-tree-checkbox-checked:after {
    border-color: #fff; }
  .auth-style .ant-tree.ant-tree-directory
.ant-tree-child-tree
> li.ant-tree-treenode-selected
> span.ant-tree-checkbox.ant-tree-checkbox-checked
.ant-tree-checkbox-inner,
  .auth-style .ant-tree.ant-tree-directory
> li.ant-tree-treenode-selected
> span.ant-tree-checkbox.ant-tree-checkbox-checked
.ant-tree-checkbox-inner {
    background: #fff; }
  .auth-style .ant-tree.ant-tree-directory
.ant-tree-child-tree
> li.ant-tree-treenode-selected
> span.ant-tree-checkbox.ant-tree-checkbox-checked
.ant-tree-checkbox-inner:after,
  .auth-style .ant-tree.ant-tree-directory
> li.ant-tree-treenode-selected
> span.ant-tree-checkbox.ant-tree-checkbox-checked
.ant-tree-checkbox-inner:after {
    border-color: #1890ff; }
  .auth-style .ant-tree.ant-tree-directory
.ant-tree-child-tree
> li.ant-tree-treenode-selected
> span.ant-tree-node-content-wrapper:before,
  .auth-style .ant-tree.ant-tree-directory
> li.ant-tree-treenode-selected
> span.ant-tree-node-content-wrapper:before {
    background: #1890ff; }
  .auth-style .ant-tree-checkbox {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    display: inline-block;
    line-height: 1;
    position: relative;
    vertical-align: middle;
    top: -0.09em; }
  .auth-style .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner,
  .auth-style .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
  .auth-style .ant-tree-checkbox:hover .ant-tree-checkbox-inner {
    border-color: #1890ff; }
  .auth-style .ant-tree-checkbox-checked:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    border: 1px solid #1890ff;
    content: "";
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
    animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: hidden; }
  .auth-style .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox:after,
  .auth-style .ant-tree-checkbox:hover:after {
    visibility: visible; }
  .auth-style .ant-tree-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    background-color: #fff;
    transition: all 0.3s;
    border-collapse: separate; }
  .auth-style .ant-tree-checkbox-inner:after {
    -webkit-transform: rotate(45deg) scale(0);
    transform: rotate(45deg) scale(0);
    position: absolute;
    left: 4.57142857px;
    top: 1.14285714px;
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    content: " ";
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    opacity: 0; }
  .auth-style .ant-tree-checkbox-input {
    position: absolute;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%; }
  .auth-style .ant-tree-checkbox-checked .ant-tree-checkbox-inner:after {
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    content: " ";
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    opacity: 1; }
  .auth-style .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: #1890ff;
    border-color: #1890ff; }
  .auth-style .ant-tree-checkbox-disabled {
    cursor: not-allowed; }
  .auth-style .ant-tree-checkbox-disabled.ant-tree-checkbox-checked
.ant-tree-checkbox-inner:after {
    -webkit-animation-name: none;
    animation-name: none;
    border-color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-tree-checkbox-disabled .ant-tree-checkbox-input {
    cursor: not-allowed; }
  .auth-style .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
    border-color: #d9d9d9 !important;
    background-color: #f5f5f5; }
  .auth-style .ant-tree-checkbox-disabled .ant-tree-checkbox-inner:after {
    -webkit-animation-name: none;
    animation-name: none;
    border-color: #f5f5f5;
    border-collapse: separate; }
  .auth-style .ant-tree-checkbox-disabled + span {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed; }
  .auth-style .ant-tree-checkbox-wrapper {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: unset;
    cursor: pointer;
    display: inline-block; }
  .auth-style .ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
    margin-left: 8px; }
  .auth-style .ant-tree-checkbox + span,
  .auth-style .ant-tree-checkbox-wrapper + span {
    padding-left: 8px;
    padding-right: 8px; }
  .auth-style .ant-tree-checkbox-group {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-block; }
  .auth-style .ant-tree-checkbox-group-item {
    display: inline-block;
    margin-right: 8px; }
  .auth-style .ant-tree-checkbox-group-item:last-child {
    margin-right: 0; }
  .auth-style .ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
    margin-left: 0; }
  .auth-style .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
    background-color: #fff;
    border-color: #d9d9d9; }
  .auth-style .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
    content: " ";
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    border: 0;
    left: 50%;
    top: 50%;
    width: 8px;
    height: 8px;
    background-color: #1890ff;
    opacity: 1; }
  .auth-style .ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled
.ant-tree-checkbox-inner:after {
    border-color: rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-tree {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box; }
  .auth-style .ant-tree,
  .auth-style .ant-tree ol,
  .auth-style .ant-tree ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .auth-style .ant-tree li {
    padding: 4px 0;
    margin: 0;
    list-style: none;
    white-space: nowrap;
    outline: 0; }
  .auth-style .ant-tree li span[draggable="true"],
  .auth-style .ant-tree li span[draggable] {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    -khtml-user-drag: element;
    -webkit-user-drag: element;
    line-height: 20px; }
  .auth-style .ant-tree li.drag-over > span[draggable] {
    background-color: #1890ff;
    color: #fff;
    opacity: 0.8; }
  .auth-style .ant-tree li.drag-over-gap-top > span[draggable] {
    border-top-color: #1890ff; }
  .auth-style .ant-tree li.drag-over-gap-bottom > span[draggable] {
    border-bottom-color: #1890ff; }
  .auth-style .ant-tree li.filter-node > span {
    color: #f5222d !important;
    font-weight: 500 !important; }
  .auth-style .ant-tree
li.ant-tree-treenode-loading
span.ant-tree-switcher.ant-tree-switcher_close
.ant-tree-switcher-loading-icon,
  .auth-style .ant-tree
li.ant-tree-treenode-loading
span.ant-tree-switcher.ant-tree-switcher_open
.ant-tree-switcher-loading-icon {
    display: inline-block;
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
    color: #1890ff;
    -webkit-transform: none;
    transform: none;
    font-size: 14px; }
  .auth-style .ant-tree
li.ant-tree-treenode-loading
span.ant-tree-switcher.ant-tree-switcher_close
.ant-tree-switcher-loading-icon
svg,
  .auth-style .ant-tree
li.ant-tree-treenode-loading
span.ant-tree-switcher.ant-tree-switcher_open
.ant-tree-switcher-loading-icon
svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto; }
  .auth-style :root
.ant-tree
li.ant-tree-treenode-loading
span.ant-tree-switcher.ant-tree-switcher_close:after,
  .auth-style :root
.ant-tree
li.ant-tree-treenode-loading
span.ant-tree-switcher.ant-tree-switcher_open:after {
    opacity: 0; }
  .auth-style .ant-tree li ul {
    margin: 0;
    padding: 0 0 0 18px; }
  .auth-style .ant-tree li .ant-tree-node-content-wrapper {
    display: inline-block;
    padding: 0 5px;
    border-radius: 2px;
    margin: 0;
    cursor: pointer;
    text-decoration: none;
    vertical-align: top;
    color: rgba(0, 0, 0, 0.65);
    transition: all 0.3s;
    height: 24px;
    line-height: 24px; }
  .auth-style .ant-tree li .ant-tree-node-content-wrapper:hover {
    background-color: #e6f7ff; }
  .auth-style .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: #bae7ff; }
  .auth-style .ant-tree li span.ant-tree-checkbox {
    margin: 4px 4px 0 2px; }
  .auth-style .ant-tree li span.ant-tree-iconEle,
  .auth-style .ant-tree li span.ant-tree-switcher {
    margin: 0;
    width: 24px;
    height: 24px;
    line-height: 24px;
    display: inline-block;
    vertical-align: top;
    border: 0;
    cursor: pointer;
    outline: none;
    text-align: center; }
  .auth-style .ant-tree li span.ant-tree-switcher {
    position: relative; }
  .auth-style .ant-tree li span.ant-tree-switcher.ant-tree-switcher-noop {
    cursor: default; }
  .auth-style .ant-tree
li
span.ant-tree-switcher.ant-tree-switcher_open
.ant-select-switcher-icon,
  .auth-style .ant-tree
li
span.ant-tree-switcher.ant-tree-switcher_open
.ant-tree-switcher-icon {
    font-size: 12px;
    font-size: 10px\9;
    -webkit-transform: scale(0.83333) rotate(0deg);
    transform: scale(0.83333) rotate(0deg);
    display: inline-block;
    font-weight: 700; }
  .auth-style :root
.ant-tree
li
span.ant-tree-switcher.ant-tree-switcher_open
.ant-select-switcher-icon,
  .auth-style :root
.ant-tree
li
span.ant-tree-switcher.ant-tree-switcher_open
.ant-tree-switcher-icon {
    font-size: 12px; }
  .auth-style .ant-tree
li
span.ant-tree-switcher.ant-tree-switcher_open
.ant-select-switcher-icon
svg,
  .auth-style .ant-tree
li
span.ant-tree-switcher.ant-tree-switcher_open
.ant-tree-switcher-icon
svg {
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s; }
  .auth-style .ant-tree
li
span.ant-tree-switcher.ant-tree-switcher_close
.ant-select-switcher-icon,
  .auth-style .ant-tree
li
span.ant-tree-switcher.ant-tree-switcher_close
.ant-tree-switcher-icon {
    font-size: 12px;
    font-size: 10px\9;
    -webkit-transform: scale(0.83333) rotate(0deg);
    transform: scale(0.83333) rotate(0deg);
    display: inline-block;
    font-weight: 700; }
  .auth-style :root
.ant-tree
li
span.ant-tree-switcher.ant-tree-switcher_close
.ant-select-switcher-icon,
  .auth-style :root
.ant-tree
li
span.ant-tree-switcher.ant-tree-switcher_close
.ant-tree-switcher-icon {
    font-size: 12px; }
  .auth-style .ant-tree
li
span.ant-tree-switcher.ant-tree-switcher_close
.ant-select-switcher-icon
svg,
  .auth-style .ant-tree
li
span.ant-tree-switcher.ant-tree-switcher_close
.ant-tree-switcher-icon
svg {
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s; }
  .auth-style .ant-tree
li
span.ant-tree-switcher.ant-tree-switcher_close
.ant-tree-switcher-icon
svg {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg); }
  .auth-style .ant-tree li:last-child > span.ant-tree-iconEle:before,
  .auth-style .ant-tree li:last-child > span.ant-tree-switcher:before {
    display: none; }
  .auth-style .ant-tree > li:first-child {
    padding-top: 7px; }
  .auth-style .ant-tree > li:last-child {
    padding-bottom: 7px; }
  .auth-style .ant-tree-child-tree {
    display: none; }
  .auth-style .ant-tree-child-tree-open {
    display: block; }
  .auth-style li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper,
  .auth-style li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper span,
  .auth-style li.ant-tree-treenode-disabled > span:not(.ant-tree-switcher) {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed; }
  .auth-style li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper:hover {
    background: transparent; }
  .auth-style .ant-tree-icon__close,
  .auth-style .ant-tree-icon__open {
    margin-right: 2px;
    vertical-align: top; }
  .auth-style .ant-tree.ant-tree-show-line li {
    position: relative; }
  .auth-style .ant-tree.ant-tree-show-line li span.ant-tree-switcher {
    background: #fff;
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-tree.ant-tree-show-line
li
span.ant-tree-switcher.ant-tree-switcher-noop
.ant-select-switcher-icon,
  .auth-style .ant-tree.ant-tree-show-line
li
span.ant-tree-switcher.ant-tree-switcher-noop
.ant-tree-switcher-icon {
    font-size: 12px;
    display: inline-block;
    font-weight: 400; }
  .auth-style .ant-tree.ant-tree-show-line
li
span.ant-tree-switcher.ant-tree-switcher-noop
.ant-select-switcher-icon
svg,
  .auth-style .ant-tree.ant-tree-show-line
li
span.ant-tree-switcher.ant-tree-switcher-noop
.ant-tree-switcher-icon
svg {
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s; }
  .auth-style .ant-tree.ant-tree-show-line
li
span.ant-tree-switcher.ant-tree-switcher_open
.ant-select-switcher-icon,
  .auth-style .ant-tree.ant-tree-show-line
li
span.ant-tree-switcher.ant-tree-switcher_open
.ant-tree-switcher-icon {
    font-size: 12px;
    display: inline-block;
    font-weight: 400; }
  .auth-style .ant-tree.ant-tree-show-line
li
span.ant-tree-switcher.ant-tree-switcher_open
.ant-select-switcher-icon
svg,
  .auth-style .ant-tree.ant-tree-show-line
li
span.ant-tree-switcher.ant-tree-switcher_open
.ant-tree-switcher-icon
svg {
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s; }
  .auth-style .ant-tree.ant-tree-show-line
li
span.ant-tree-switcher.ant-tree-switcher_close
.ant-select-switcher-icon,
  .auth-style .ant-tree.ant-tree-show-line
li
span.ant-tree-switcher.ant-tree-switcher_close
.ant-tree-switcher-icon {
    font-size: 12px;
    display: inline-block;
    font-weight: 400; }
  .auth-style .ant-tree.ant-tree-show-line
li
span.ant-tree-switcher.ant-tree-switcher_close
.ant-select-switcher-icon
svg,
  .auth-style .ant-tree.ant-tree-show-line
li
span.ant-tree-switcher.ant-tree-switcher_close
.ant-tree-switcher-icon
svg {
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s; }
  .auth-style .ant-tree.ant-tree-show-line li:not(:last-child):before {
    content: " ";
    width: 1px;
    border-left: 1px solid #d9d9d9;
    height: 100%;
    position: absolute;
    left: 12px;
    margin: 22px 0; }
  .auth-style .ant-tree.ant-tree-icon-hide .ant-tree-treenode-loading .ant-tree-iconEle {
    display: none; }
  .auth-style .ant-upload {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    outline: 0; }
  .auth-style .ant-upload p {
    margin: 0; }
  .auth-style .ant-upload-btn {
    display: block;
    width: 100%;
    outline: none; }
  .auth-style .ant-upload input[type="file"] {
    cursor: pointer; }
  .auth-style .ant-upload.ant-upload-select {
    display: inline-block; }
  .auth-style .ant-upload.ant-upload-select-picture-card {
    border: 1px dashed #d9d9d9;
    width: 104px;
    height: 104px;
    border-radius: 4px;
    background-color: #fafafa;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s ease;
    vertical-align: top;
    margin-right: 8px;
    margin-bottom: 8px;
    display: table; }
  .auth-style .ant-upload.ant-upload-select-picture-card > .ant-upload {
    width: 100%;
    height: 100%;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding: 8px; }
  .auth-style .ant-upload.ant-upload-select-picture-card:hover {
    border-color: #1890ff; }
  .auth-style .ant-upload.ant-upload-drag {
    border: 1px dashed #d9d9d9;
    transition: border-color 0.3s;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
    width: 100%;
    height: 100%;
    position: relative;
    background: #fafafa; }
  .auth-style .ant-upload.ant-upload-drag .ant-upload {
    padding: 16px 0; }
  .auth-style .ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
    border: 2px dashed #40a9ff; }
  .auth-style .ant-upload.ant-upload-drag.ant-upload-disabled {
    cursor: not-allowed; }
  .auth-style .ant-upload.ant-upload-drag .ant-upload-btn {
    display: table;
    height: 100%; }
  .auth-style .ant-upload.ant-upload-drag .ant-upload-drag-container {
    display: table-cell;
    vertical-align: middle; }
  .auth-style .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #40a9ff; }
  .auth-style .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    margin-bottom: 20px; }
  .auth-style .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    font-size: 48px;
    color: #40a9ff; }
  .auth-style .ant-upload.ant-upload-drag p.ant-upload-text {
    font-size: 16px;
    margin: 0 0 4px;
    color: rgba(0, 0, 0, 0.85); }
  .auth-style .ant-upload.ant-upload-drag p.ant-upload-hint {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-upload.ant-upload-drag .anticon-plus {
    font-size: 30px;
    transition: all 0.3s;
    color: rgba(0, 0, 0, 0.25); }
  .auth-style .ant-upload.ant-upload-drag .anticon-plus:hover,
  .auth-style .ant-upload.ant-upload-drag:hover .anticon-plus {
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-upload-list {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    zoom: 1; }
  .auth-style .ant-upload-list:after,
  .auth-style .ant-upload-list:before {
    content: "";
    display: table; }
  .auth-style .ant-upload-list:after {
    clear: both; }
  .auth-style .ant-upload-list-item {
    margin-top: 8px;
    font-size: 14px;
    position: relative;
    height: 22px; }
  .auth-style .ant-upload-list-item-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 22px;
    width: 100%;
    display: inline-block; }
  .auth-style .ant-upload-list-item-info {
    height: 100%;
    padding: 0 12px 0 4px;
    transition: background-color 0.3s; }
  .auth-style .ant-upload-list-item-info > span {
    display: block; }
  .auth-style .ant-upload-list-item-info .anticon-loading,
  .auth-style .ant-upload-list-item-info .anticon-paper-clip {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    position: absolute;
    top: 5px; }
  .auth-style .ant-upload-list-item .anticon-close {
    display: inline-block;
    font-size: 12px;
    font-size: 10px\9;
    -webkit-transform: scale(0.83333) rotate(0deg);
    transform: scale(0.83333) rotate(0deg);
    transition: all 0.3s;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 6px;
    right: 4px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 0; }
  .auth-style :root .ant-upload-list-item .anticon-close {
    font-size: 12px; }
  .auth-style .ant-upload-list-item .anticon-close:hover {
    color: rgba(0, 0, 0, 0.65); }
  .auth-style .ant-upload-list-item:hover .ant-upload-list-item-info {
    background-color: #e6f7ff; }
  .auth-style .ant-upload-list-item:hover .anticon-close {
    opacity: 1; }
  .auth-style .ant-upload-list-item-error,
  .auth-style .ant-upload-list-item-error .ant-upload-list-item-name,
  .auth-style .ant-upload-list-item-error .anticon-paper-clip {
    color: #f5222d; }
  .auth-style .ant-upload-list-item-error .anticon-close {
    opacity: 1;
    color: #f5222d !important; }
  .auth-style .ant-upload-list-item-progress {
    line-height: 0;
    font-size: 14px;
    position: absolute;
    width: 100%;
    bottom: -12px;
    padding-left: 26px; }
  .auth-style .ant-upload-list-picture-card .ant-upload-list-item,
  .auth-style .ant-upload-list-picture .ant-upload-list-item {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    height: 66px;
    position: relative; }
  .auth-style .ant-upload-list-picture-card .ant-upload-list-item:hover,
  .auth-style .ant-upload-list-picture .ant-upload-list-item:hover {
    background: transparent; }
  .auth-style .ant-upload-list-picture-card .ant-upload-list-item-error,
  .auth-style .ant-upload-list-picture .ant-upload-list-item-error {
    border-color: #f5222d; }
  .auth-style .ant-upload-list-picture-card .ant-upload-list-item-info,
  .auth-style .ant-upload-list-picture .ant-upload-list-item-info {
    padding: 0; }
  .auth-style .ant-upload-list-picture-card
.ant-upload-list-item:hover
.ant-upload-list-item-info,
  .auth-style .ant-upload-list-picture
.ant-upload-list-item:hover
.ant-upload-list-item-info {
    background: transparent; }
  .auth-style .ant-upload-list-picture-card .ant-upload-list-item-uploading,
  .auth-style .ant-upload-list-picture .ant-upload-list-item-uploading {
    border-style: dashed; }
  .auth-style .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
  .auth-style .ant-upload-list-picture .ant-upload-list-item-thumbnail {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 8px;
    left: 8px;
    text-align: center;
    line-height: 54px;
    font-size: 26px;
    opacity: 0.8; }
  .auth-style .ant-upload-list-picture-card .ant-upload-list-item-icon,
  .auth-style .ant-upload-list-picture .ant-upload-list-item-icon {
    font-size: 26px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .auth-style .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img,
  .auth-style .ant-upload-list-picture .ant-upload-list-item-thumbnail img {
    width: 48px;
    height: 48px;
    display: block;
    overflow: hidden; }
  .auth-style .ant-upload-list-picture-card .ant-upload-list-item-name,
  .auth-style .ant-upload-list-picture .ant-upload-list-item-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 0 0 8px;
    line-height: 44px;
    transition: all 0.3s;
    padding-left: 48px;
    padding-right: 8px;
    max-width: 100%;
    display: inline-block;
    box-sizing: border-box; }
  .auth-style .ant-upload-list-picture-card
.ant-upload-list-item-uploading
.ant-upload-list-item-name,
  .auth-style .ant-upload-list-picture
.ant-upload-list-item-uploading
.ant-upload-list-item-name {
    line-height: 28px; }
  .auth-style .ant-upload-list-picture-card .ant-upload-list-item-progress,
  .auth-style .ant-upload-list-picture .ant-upload-list-item-progress {
    padding-left: 56px;
    margin-top: 0;
    bottom: 14px;
    width: calc(100% - 24px); }
  .auth-style .ant-upload-list-picture-card .anticon-close,
  .auth-style .ant-upload-list-picture .anticon-close {
    position: absolute;
    right: 8px;
    top: 8px;
    line-height: 1;
    opacity: 1; }
  .auth-style .ant-upload-list-picture-card {
    float: left; }
  .auth-style .ant-upload-list-picture-card.ant-upload-list:after {
    display: none; }
  .auth-style .ant-upload-list-picture-card .ant-upload-list-item {
    float: left;
    width: 104px;
    height: 104px;
    margin: 0 8px 8px 0; }
  .auth-style .ant-upload-list-picture-card .ant-upload-list-item-info {
    height: 100%;
    position: relative;
    overflow: hidden; }
  .auth-style .ant-upload-list-picture-card .ant-upload-list-item-info:before {
    content: " ";
    position: absolute;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.3s;
    width: 100%;
    height: 100%;
    opacity: 0; }
  .auth-style .ant-upload-list-picture-card
.ant-upload-list-item:hover
.ant-upload-list-item-info:before {
    opacity: 1; }
  .auth-style .ant-upload-list-picture-card .ant-upload-list-item-actions {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 10;
    white-space: nowrap;
    opacity: 0;
    transition: all 0.3s; }
  .auth-style .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete,
  .auth-style .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o {
    z-index: 10;
    transition: all 0.3s;
    cursor: pointer;
    font-size: 16px;
    width: 16px;
    color: rgba(255, 255, 255, 0.85);
    margin: 0 4px; }
  .auth-style .ant-upload-list-picture-card
.ant-upload-list-item-actions
.anticon-delete:hover,
  .auth-style .ant-upload-list-picture-card
.ant-upload-list-item-actions
.anticon-eye-o:hover {
    color: #fff; }
  .auth-style .ant-upload-list-picture-card .ant-upload-list-item-actions:hover,
  .auth-style .ant-upload-list-picture-card
.ant-upload-list-item-info:hover
+ .ant-upload-list-item-actions {
    opacity: 1; }
  .auth-style .ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
  .auth-style .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    display: block;
    width: 100%;
    height: 100%;
    position: static; }
  .auth-style .ant-upload-list-picture-card .ant-upload-list-item-name {
    margin: 8px 0 0;
    padding: 0;
    text-align: center;
    line-height: 1.5;
    display: none; }
  .auth-style .ant-upload-list-picture-card .anticon-picture + .ant-upload-list-item-name {
    display: block; }
  .auth-style .ant-upload-list-picture-card
.ant-upload-list-item-uploading.ant-upload-list-item {
    background-color: #fafafa; }
  .auth-style .ant-upload-list-picture-card
.ant-upload-list-item-uploading
.ant-upload-list-item-info {
    height: auto; }
  .auth-style .ant-upload-list-picture-card
.ant-upload-list-item-uploading
.ant-upload-list-item-info
.anticon-delete,
  .auth-style .ant-upload-list-picture-card
.ant-upload-list-item-uploading
.ant-upload-list-item-info
.anticon-eye-o,
  .auth-style .ant-upload-list-picture-card
.ant-upload-list-item-uploading
.ant-upload-list-item-info:before {
    display: none; }
  .auth-style .ant-upload-list-picture-card .ant-upload-list-item-uploading-text {
    margin-top: 18px;
    color: rgba(0, 0, 0, 0.45); }
  .auth-style .ant-upload-list-picture-card .ant-upload-list-item-progress {
    padding-left: 0;
    bottom: 32px; }
  .auth-style .ant-upload-list .ant-upload-success-icon {
    color: #52c41a;
    font-weight: 700; }
  .auth-style .ant-upload-list .ant-upload-animate-enter,
  .auth-style .ant-upload-list .ant-upload-animate-inline-enter,
  .auth-style .ant-upload-list .ant-upload-animate-inline-leave,
  .auth-style .ant-upload-list .ant-upload-animate-leave {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
    animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86); }
  .auth-style .ant-upload-list .ant-upload-animate-enter {
    -webkit-animation-name: uploadAnimateIn;
    animation-name: uploadAnimateIn; }
  .auth-style .ant-upload-list .ant-upload-animate-leave {
    -webkit-animation-name: uploadAnimateOut;
    animation-name: uploadAnimateOut; }
  .auth-style .ant-upload-list .ant-upload-animate-inline-enter {
    -webkit-animation-name: uploadAnimateInlineIn;
    animation-name: uploadAnimateInlineIn; }
  .auth-style .ant-upload-list .ant-upload-animate-inline-leave {
    -webkit-animation-name: uploadAnimateInlineOut;
    animation-name: uploadAnimateInlineOut; }

@-webkit-keyframes uploadAnimateIn {
  0% {
    height: 0;
    margin: 0;
    opacity: 0;
    padding: 0; } }

@keyframes uploadAnimateIn {
  0% {
    height: 0;
    margin: 0;
    opacity: 0;
    padding: 0; } }

@-webkit-keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0; } }

@keyframes uploadAnimateOut {
  to {
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0; } }

@-webkit-keyframes uploadAnimateInlineIn {
  0% {
    width: 0;
    height: 0;
    margin: 0;
    opacity: 0;
    padding: 0; } }

@keyframes uploadAnimateInlineIn {
  0% {
    width: 0;
    height: 0;
    margin: 0;
    opacity: 0;
    padding: 0; } }

@-webkit-keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0; } }

@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0; } }
  .auth-style h1,
  .auth-style h2 {
    font-weight: 400; }
  .auth-style #root {
    max-width: 1170px;
    margin: 0 auto; }
  .auth-style .copy-to-clipboard {
    cursor: pointer;
    color: #999; }
    .auth-style .copy-to-clipboard:hover {
      color: #111; }
  .auth-style .ant-message {
    z-index: 6000; }
  .auth-style .h-overflow {
    overflow: hidden; }
  .auth-style .center {
    margin-left: auto !important;
    margin-right: auto !important; }
  .auth-style .pull-left {
    float: left; }
  .auth-style .pull-right {
    float: right; }
  .auth-style .sidebar2__field {
    padding-bottom: 10px;
    margin-bottom: 5px;
    border-bottom: 1px solid #f1f1f1; }
  .auth-style .sidebar2__field__description {
    font-size: 13px; }
  .auth-style .sidebar2__field__variable {
    font-weight: bold;
    font-size: 13px;
    margin-top: 3px; }
  .auth-style .sidebar2__size-variable__input {
    font-size: 12px !important;
    float: left !important; }
  .auth-style .sidebar2__size-variable__buttons {
    float: right;
    position: relative;
    margin-top: -32px; }
  .auth-style .preview__menu {
    overflow: hidden;
    padding: 5px;
    background-color: #ffffff; }
  .auth-style .sidebar1 a {
    display: block;
    text-align: center;
    padding: 10px; }
  .auth-style .preview__content--split {
    bottom: 50%; }
  .auth-style .ace_scrollbar::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 0; }
  .auth-style .ace_scrollbar::-webkit-scrollbar {
    width: 7px;
    background-color: #f9f9f9; }
  .auth-style .ace_scrollbar::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: #dddddd; }
  .auth-style .ace_scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #cccccc; }
  .auth-style .scroll-style::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 0; }
  .auth-style .scroll-style::-webkit-scrollbar {
    width: 7px;
    background-color: #f9f9f9; }
  .auth-style .scroll-style::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: #dddddd; }
  .auth-style .scroll-style::-webkit-scrollbar-thumb:hover {
    background-color: #cccccc; }
  .auth-style .blur {
    -webkit-filter: blur(5px);
            filter: blur(5px); }
  .auth-style .loader {
    text-align: center;
    padding-top: 60px;
    z-index: 3;
    background-color: rgba(255, 255, 255, 0.8); }
    .auth-style .loader--inner {
      width: 80px;
      text-align: center;
      position: absolute;
      z-index: 1001;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  .auth-style .ant-input-suffix {
    pointer-events: none; }
  .auth-style .color-swatch {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0 0 5px #ddd; }
  .auth-style .code-editor {
    border-top: 2px solid #ddd;
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: vertical-text; }
  .auth-style #brace-editor {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto !important;
    height: auto !important; }
  .auth-style .ace_scrollbar-h {
    height: 7px !important; }
  .auth-style .ant-notification-notice-description {
    white-space: pre-wrap; }
  .auth-style .content {
    margin-top: 110px; }
  .auth-style .form {
    margin-left: auto;
    margin-right: auto;
    width: 370px;
    border-radius: 6px;
    float: left;
    padding-left: 10px; }
  .auth-style .form-padder {
    padding: 10px 0; }
  .auth-style .form-error {
    background-color: #ff0033;
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 6px;
    margin-bottom: 5px; }
  .auth-style .center-800 {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px; }
  .auth-style .terms h2 {
    padding-top: 15px; }
  .auth-style .ads {
    position: fixed;
    z-index: 11111;
    top: 65px;
    right: 310px;
    background: #fff;
    height: 80px;
    width: 550px;
    box-shadow: 0 0 4px #eee; }

@charset "UTF-8";
.login-page {
  /*
	Table of contents:
	1. Common styles
	2. Common responsive styles
	3. Headers
	4. Navigations
	5. Contents
	6. Testimonials
	7. Features
	8. Forms
	9. Call to Actions
	10. Teams
	11. Pricing Tables
	12. Contacts
	13. Showcases
	14. Footers
	15. Ecommerce
	16. Applications
*/
  /* 1. Common styles */
  /* Change AOS plugin default settings */
  /* Slick slider */
  /* Arrows */
  /* Dots */
  /* 2. Common responsive styles */
  /* Smaller than 1200px */
  /* Smaller than 992px */
  /* Smaller than 768px */
  /* Smaller than 576px */
  /* 3. Headers */
  /* 4. Navigations */
  /* 5. Contents */
  /* 6. Testimonials */
  /* 7. Features */
  /* 8. Forms */
  /* 9. Call to Actions */
  /* 10. Teams */
  /* 11. Pricing Tables */
  /* 12. Contacts */
  /* 13. Showcases */
  /* 14. Footers */
  /* 15. Ecommerce */
  /* 16. Applications */ }
  .login-page body {
    font-family: Nunito Sans, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #1e3060;
    -webkit-font-smoothing: antialiased; }
    .login-page body.dev {
      background: #ccc;
      min-height: 10000px; }
      .login-page body.dev .js-content-editable {
        outline: rgba(113, 33, 244, 0.7) dashed 2px !important;
        outline-offset: -2px; }
        .login-page body.dev .js-content-editable:hover {
          outline-color: #0074f1 !important;
          outline-style: solid !important; }
      .login-page body.dev section.js-content-editable,
      .login-page body.dev header.js-content-editable,
      .login-page body.dev nav.js-content-editable,
      .login-page body.dev footer.js-content-editable {
        outline-width: 10px !important;
        outline-offset: -10px; }
  .login-page nav,
  .login-page header,
  .login-page section,
  .login-page footer {
    position: relative; }
  .login-page header,
  .login-page section {
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover; }
  .login-page header nav {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000; }
  .login-page nav {
    width: 100%; }
    .login-page nav.transparent {
      background: transparent; }
  .login-page a,
  .login-page button {
    cursor: pointer;
    transition: all 0.25s; }
    .login-page a:hover,
    .login-page button:hover {
      text-decoration: none; }
    .login-page a:active, .login-page a:focus,
    .login-page button:active,
    .login-page button:focus {
      text-decoration: none;
      outline: none; }
  .login-page button {
    border: none;
    outline: none !important; }
  .login-page .header_menu_1 {
    padding-bottom: 40px !important; }
  .login-page input,
  .login-page textarea {
    border: 0;
    outline: none;
    background: transparent;
    color: #1e3060;
    resize: none; }
  .login-page label {
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer; }
  .login-page h1,
  .login-page h2,
  .login-page h3,
  .login-page h4,
  .login-page h5,
  .login-page h6 {
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    margin: 0;
    font-weight: 700;
    font-family: Nunito Sans, Helvetica, Arial, sans-serif; }
  .login-page h1,
  .login-page h2 {
    font-size: 58px;
    line-height: 70px; }
    .login-page h1.big,
    .login-page h2.big {
      font-size: 72px;
      line-height: 86px; }
    .login-page h1.small,
    .login-page h2.small {
      font-size: 42px;
      line-height: 52px;
      font-weight: 700; }
  .login-page h3 {
    font-size: 42px;
    line-height: 52px; }
  .login-page b,
  .login-page strong {
    font-weight: 700; }
  .login-page [data-aos="fade-down"] {
    -webkit-transform: translate3d(0, -35px, 0);
            transform: translate3d(0, -35px, 0); }
  .login-page [data-aos="fade-up"] {
    -webkit-transform: translate3d(0, 35px, 0);
            transform: translate3d(0, 35px, 0); }
  .login-page .slick-prev,
  .login-page .slick-next {
    position: absolute;
    display: block;
    height: 32px;
    width: 32px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    margin-top: -16px;
    padding: 0;
    border: none;
    outline: none;
    z-index: 1; }
    .login-page .slick-prev:hover, .login-page .slick-prev:focus,
    .login-page .slick-next:hover,
    .login-page .slick-next:focus {
      outline: none;
      background: transparent;
      color: transparent; }
      .login-page .slick-prev:hover:before, .login-page .slick-prev:focus:before,
      .login-page .slick-next:hover:before,
      .login-page .slick-next:focus:before {
        opacity: 0.5; }
    .login-page .slick-prev.slick-disabled:before,
    .login-page .slick-next.slick-disabled:before {
      opacity: 0.3; }
    .login-page .slick-prev:before,
    .login-page .slick-next:before {
      display: block;
      content: "";
      width: 32px;
      height: 32px;
      opacity: 1;
      transition: opacity 0.2s; }
  .login-page .slick-prev {
    left: 0; }
    [dir="rtl"] .login-page .slick-prev {
      left: auto;
      right: 0; }
  .login-page .slick-next {
    right: 0; }
    [dir="rtl"] .login-page .slick-next {
      left: 0;
      right: auto; }
  .login-page .slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
    margin: 0;
    left: 0;
    right: 0; }
    .login-page .slick-dots li {
      position: relative;
      display: inline-block;
      height: 10px;
      width: 10px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer; }
      .login-page .slick-dots li button {
        box-sizing: border-box;
        border: 2px solid #FFFFFF;
        border-radius: 50%;
        background: transparent;
        display: block;
        height: 10px;
        width: 10px;
        padding: 0;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        color: transparent;
        opacity: 0.3;
        cursor: pointer; }
        .login-page .slick-dots li button:hover {
          outline: none;
          opacity: 0.8; }
      .login-page .slick-dots li.slick-active button {
        background: #FFFFFF;
        opacity: 1; }
        .login-page .slick-dots li.slick-active button:hover {
          opacity: 1; }
  .login-page .slick-slide {
    outline: none; }
  .login-page .container {
    position: relative; }
  .login-page ::-moz-selection {
    background: #7121F4;
    color: #FFFFFF; }
  .login-page ::-webkit-selection {
    background: #7121F4;
    color: #FFFFFF; }
  .login-page ::selection {
    background: #7121F4;
    color: #FFFFFF; }
  @media (max-width: 1199.98px) {
    .login-page header[class^="pt-"], .login-page header[class*=" pt-"],
    .login-page section[class^="pt-"],
    .login-page section[class*=" pt-"] {
      padding-top: 80px; }
    .login-page header[class^="pb-"], .login-page header[class*=" pb-"],
    .login-page section[class^="pb-"],
    .login-page section[class*=" pb-"] {
      padding-bottom: 80px; }
    .login-page h1 br,
    .login-page h2 br,
    .login-page h3 br,
    .login-page .text-adaptive br {
      display: none; }
    .login-page h1,
    .login-page h2 {
      font-size: 52px;
      line-height: 62px; }
      .login-page h1.big,
      .login-page h2.big {
        font-size: 62px;
        line-height: 72px; }
      .login-page h1.small,
      .login-page h2.small {
        font-size: 36px;
        line-height: 46px; }
    .login-page h3 {
      font-size: 36px;
      line-height: 46px; }
    .login-page .navigation_mobile {
      display: block; } }
  @media (max-width: 991.98px) {
    .login-page h1,
    .login-page h2 {
      font-size: 42px;
      line-height: 52px; }
      .login-page h1.big,
      .login-page h2.big {
        font-size: 52px;
        line-height: 62px; }
      .login-page h1.small,
      .login-page h2.small {
        font-size: 32px;
        line-height: 42px; }
    .login-page h3 {
      font-size: 32px;
      line-height: 42px; }
    .login-page .text-adaptive.f-24 {
      font-size: 22px;
      line-height: 32px; }
    .login-page .text-adaptive.f-22 {
      font-size: 20px;
      line-height: 30px; }
    .login-page .text-adaptive.f-20 {
      font-size: 18px;
      line-height: 28px; }
    .login-page .alert {
      top: 10px;
      left: 20px;
      right: 20px;
      -webkit-transform: none;
              transform: none;
      width: auto;
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    .login-page h1,
    .login-page h2 {
      font-size: 32px;
      line-height: 42px; }
      .login-page h1.big,
      .login-page h2.big {
        font-size: 42px;
        line-height: 52px; }
      .login-page h1.small,
      .login-page h2.small {
        font-size: 26px;
        line-height: 36px; }
    .login-page h3 {
      font-size: 26px;
      line-height: 36px; }
    .login-page .text-adaptive.f-24 {
      font-size: 20px;
      line-height: 30px; }
    .login-page .text-adaptive.f-22 {
      font-size: 18px;
      line-height: 28px; }
    .login-page .text-adaptive.f-20 {
      font-size: 16px;
      line-height: 26px; }
    .login-page .text-adaptive.f-18 {
      font-size: 16px;
      line-height: 26px; }
    .login-page .btn.lg {
      height: 50px;
      line-height: 50px;
      padding: 0 30px;
      font-size: 18px;
      font-weight: 500; }
      .login-page .btn.lg[class^="border-"], .login-page .btn.lg[class*=" border-"] {
        line-height: 46px; }
    .login-page .btn.xl {
      height: 60px;
      line-height: 60px;
      padding: 0 35px;
      font-size: 18px;
      font-weight: 500; }
      .login-page .btn.xl[class^="border-"], .login-page .btn.xl[class*=" border-"] {
        line-height: 56px; }
    .login-page .input.lg {
      height: 50px;
      font-size: 18px;
      font-weight: 500; } }
  @media (max-width: 575.98px) {
    .login-page h1,
    .login-page h2 {
      font-size: 26px;
      line-height: 36px; }
      .login-page h1.big,
      .login-page h2.big {
        font-size: 32px;
        line-height: 42px; }
      .login-page h1.small,
      .login-page h2.small {
        font-size: 22px;
        line-height: 32px; }
    .login-page .btn.xl {
      height: 50px;
      line-height: 50px;
      padding: 0 30px; }
      .login-page .btn.xl[class^="border-"], .login-page .btn.xl[class*=" border-"] {
        line-height: 46px; } }
  @media (max-width: 767.98px) {
    .login-page .header_1 .text-adaptive {
      margin-top: 20px; }
    .login-page .header_1 .buttons {
      margin-top: 40px; } }
  @media (max-width: 767.98px) {
    .login-page .header_2 .text-adaptive {
      margin-top: 20px; }
    .login-page .header_2 form {
      margin-top: 40px; }
      .login-page .header_2 form .input {
        text-align: center;
        max-width: 100%;
        width: 100%; }
      .login-page .header_2 form .btn {
        margin-top: 15px; } }
  @media (max-width: 1199.98px) {
    .login-page .header_3 form {
      margin-top: 40px; } }
  @media (max-width: 575.98px) {
    .login-page .header_3 .input {
      margin-bottom: 20px; } }
  @media (max-width: 767.98px) {
    .login-page .header_4 .poster {
      margin-top: 30px; } }
  .login-page .header_5 .block_with_border {
    border-left: 2px solid rgba(30, 48, 96, 0.3);
    border-right: 2px solid rgba(30, 48, 96, 0.3); }
  @media (max-width: 991.98px) {
    .login-page .header_5 .block_with_border {
      border: none;
      padding: 0 15px; } }
  @media (max-width: 767.98px) {
    .login-page .header_5 .btn {
      margin-top: 30px; }
    .login-page .header_5 img {
      margin-bottom: 20px; } }
  @media (max-width: 767.98px) {
    .login-page .header_6 .btn_holder {
      margin-top: 30px;
      margin-bottom: 30px; } }
  .login-page .header_7 .img_holder img {
    left: 50%;
    top: 0;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  @media (max-width: 1199.98px) {
    .login-page .header_7 .inner {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media (max-width: 575.98px) {
    .login-page .header_7 .buttons {
      margin-top: 30px; }
      .login-page .header_7 .buttons .btn {
        margin: 0 auto 10px auto !important;
        display: block;
        max-width: 320px; } }
  .login-page .header_8 .slick-arrow {
    top: 50px; }
  @media (max-width: 1199.98px) {
    .login-page .header_8 .slick-arrow {
      top: 40px; } }
  @media (max-width: 991.98px) {
    .login-page .header_8 .slick-arrow {
      top: 35px; } }
  @media (max-width: 767.98px) {
    .login-page .header_8 .slick-arrow {
      top: 30px; }
    .login-page .header_8 .text-adaptive {
      margin-top: 15px; } }
  @media (max-width: 575.98px) {
    .login-page .header_8 .slider {
      padding: 0; }
    .login-page .header_8 .slick-arrow {
      display: none !important; } }
  @media (max-width: 991.98px) {
    .login-page .header_9 .left {
      padding-top: 40px; } }
  .login-page .header_10 .videos .fa-play {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  @media (max-width: 1199.98px) {
    .login-page .header_10 .btn,
    .login-page .header_10 .videos {
      margin-top: 50px; } }
  @media (max-width: 767.98px) {
    .login-page .header_10 .videos {
      margin-top: 20px; }
      .login-page .header_10 .videos .text-adaptive {
        width: auto;
        margin: 0 auto; }
    .login-page .header_10 .image {
      margin: 0 auto;
      margin-bottom: 15px; } }
  .login-page .header_menu_11 .btn {
    min-width: 80px; }
  @media (max-width: 469.98px) {
    .login-page .header_11 .buttons {
      margin-top: 20px;
      display: block !important; }
      .login-page .header_11 .buttons .btn,
      .login-page .header_11 .buttons .link {
        vertical-align: baseline;
        margin-left: 0;
        margin-top: 20px; } }
  @media (max-width: 991.98px) {
    .login-page .header_12 .logo {
      margin-bottom: 15px; } }
  @media (max-width: 575.98px) {
    .login-page .header_13 .text-adaptive {
      margin-top: 10px; } }
  .login-page .header_14 .separate:before {
    content: " ";
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    border-bottom: 2px solid #E7EAED; }
  @media (max-width: 991.98px) {
    .login-page .header_14 .text-adaptive {
      margin-top: 10px; }
    .login-page .header_14 a.btn {
      margin-top: 20px; }
    .login-page .header_14 form {
      margin-top: 40px; } }
  @media (max-width: 575.98px) {
    .login-page .header_14 form {
      padding-left: 40px;
      padding-right: 40px; } }
  @media (max-width: 374.98px) {
    .login-page .header_14 form {
      padding-left: 20px;
      padding-right: 20px; } }
  @media (max-width: 359.98px) {
    .login-page .header_14 label {
      font-size: 14px; } }
  .login-page .header_15 .separate {
    position: absolute;
    top: 0;
    width: 2px; }
    .login-page .header_15 .separate:nth-of-type(1) {
      left: 0; }
    .login-page .header_15 .separate:nth-of-type(2) {
      right: 0; }
  @media (max-width: 767.98px) {
    .login-page .header_15 h1 {
      margin-bottom: 15px; }
    .login-page .header_15 .btn {
      margin-top: 30px; }
    .login-page .header_15 .blocks {
      margin-top: 20px; } }
  .login-page .header_16 {
    background-position: 50% 100%;
    overflow: hidden; }
    @media (max-width: 991.98px) {
      .login-page .header_16 .blocks {
        margin-top: 20px; } }
    @media (max-width: 767.98px) {
      .login-page .header_16 .img {
        max-width: 100%;
        margin: 0; }
      .login-page .header_16 .blocks .title {
        margin-top: 15px;
        margin-bottom: 15px; } }
  .login-page .header_19 .slick-dots {
    width: 10px;
    height: auto;
    left: 0;
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .login-page .header_19 .slick-dots li {
      margin: 5px auto; }
      .login-page .header_19 .slick-dots li button {
        border: none;
        background: #E7EAED; }
      .login-page .header_19 .slick-dots li.slick-active button {
        background: #7121F4; }
  @media (max-width: 991.98px) {
    .login-page .header_19 .btn {
      margin-top: 40px; }
    .login-page .header_19 .inner {
      padding-left: 30px; } }
  @media (max-width: 767.98px) {
    .login-page .header_19 .slider {
      margin-top: 30px; }
    .login-page .header_19 .slick-dots {
      width: auto;
      top: auto;
      right: 0;
      bottom: -40px;
      flex-direction: row; }
      .login-page .header_19 .slick-dots li {
        margin: 0 5px; }
    .login-page .header_19 .inner {
      margin-top: 40px;
      padding-left: 0; }
      .login-page .header_19 .inner .text-adaptive {
        margin-top: 15px; } }
  @media (max-width: 767.98px) {
    .login-page .header_20 h1 {
      margin-top: 10px; }
    .login-page .header_20 .text-adaptive {
      margin-top: 15px; } }
  @media (max-width: 575.98px) {
    .login-page .header_20 .img {
      margin-bottom: 40px;
      max-height: 70vh; } }
  @media (max-width: 991.98px) {
    .login-page .header_21 .buttons {
      margin-top: 50px;
      margin-bottom: 20px; } }
  @media (max-width: 767.98px) {
    .login-page .header_22 .text-adaptive {
      margin-top: 10px;
      margin-bottom: 40px; }
    .login-page .header_22 .socials {
      margin-top: 40px; }
      .login-page .header_22 .socials .title {
        margin-bottom: 15px; }
      .login-page .header_22 .socials .btn {
        margin-left: 10px;
        margin-right: 10px; }
        .login-page .header_22 .socials .btn:first-of-type {
          margin-left: 0; }
        .login-page .header_22 .socials .btn:last-of-type {
          margin-right: 0; } }
  @media (max-width: 1199.98px) {
    .login-page .navigation_2 a {
      margin: 0 15px; }
    .login-page .navigation_2 .dropdown a {
      margin: 0; } }
  .login-page .navigation_3 form button:hover {
    background: #FFFFFF !important; }
  @media (max-width: 1199.98px) {
    .login-page .navigation_4 a {
      margin: 0 10px; } }
  @media (max-width: 575.98px) {
    .login-page .navigation_8 a {
      margin: 0 10px; } }
  @media (max-width: 767.98px) {
    .login-page .navigation_9 a {
      margin: 0 10px; } }
  .login-page .navigation_13 .link {
    border-bottom: 2px solid transparent; }
    .login-page .navigation_13 .link:hover {
      border-bottom-color: #7121F4; }
  @media (max-width: 991.98px) {
    .login-page .navigation_14 a,
    .login-page .navigation_17 a,
    .login-page .navigation_19 a,
    .login-page .navigation_20 a,
    .login-page .navigation_21 a {
      margin: 0 10px; } }
  @media (max-width: 991.98px) {
    .login-page .navigation_18 a,
    .login-page .navigation_18 form {
      margin-left: 10px;
      margin-right: 10px; } }
  .login-page .navigation_22 .banner {
    overflow: hidden; }
  .login-page .navigation_22 .img {
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    min-width: 100%;
    min-height: 100%; }
  .login-page .navigation_22 .navigation_popup .link {
    padding: 5px 15px; }
    .login-page .navigation_22 .navigation_popup .link:hover {
      background: #E7EAED;
      color: #1e3060; }
  .login-page .navigation_22 .navigation_popup .inner {
    top: 0;
    left: 0; }
  @media (max-width: 399.98px) {
    .login-page .navigation_22 .inner {
      margin-left: 40px; } }
  .login-page .navigation_23 form button:hover {
    background: #FFFFFF !important; }
  .login-page .navigation_23 .navigation_popup .slider .inner {
    top: 0;
    left: 0; }
  .login-page .navigation_23 .navigation_popup .slider .slick-dots {
    bottom: -30px; }
    .login-page .navigation_23 .navigation_popup .slider .slick-dots li.slick-active button {
      background: #0074f1; }
    .login-page .navigation_23 .navigation_popup .slider .slick-dots li button {
      opacity: 1;
      background: #E7EAED;
      border: none; }
  .login-page .navigation_23 .navigation_popup .link:hover img {
    opacity: 0.7; }
  .login-page .navigation_23 .navigation_popup .link img {
    transition: opacity 0.25s; }
  @media (max-width: 991.98px) {
    .login-page .navigation_23 .navigation_popup .categories .link {
      margin: 0 10px; } }
  @media (max-width: 469.98px) {
    .login-page .navigation_23 .slide .inner {
      padding: 30px; }
      .login-page .navigation_23 .slide .inner h2 {
        font-size: 26px;
        line-height: 36px; } }
  @media (max-width: 359.98px) {
    .login-page .navigation_23 .slide .inner h2 {
      font-size: 22px;
      line-height: 32px; } }
  .login-page .navigation_24 .navigation_popup .link:hover img {
    opacity: 0.7; }
  .login-page .navigation_24 .navigation_popup .link img {
    transition: opacity 0.25s; }
  .login-page .navigation_25 .navigation_popup {
    border-color: rgba(255, 255, 255, 0.3); }
  .login-page .navigation_26 .navigation_popup {
    border-color: rgba(255, 255, 255, 0.3); }
  .login-page .navigation_26 .categories .link {
    color: rgba(255, 255, 255, 0.7); }
    .login-page .navigation_26 .categories .link:hover {
      background: rgba(255, 255, 255, 0.3);
      color: rgba(255, 255, 255, 0.7); }
  .login-page .navigation_26 .slider {
    overflow: hidden; }
    .login-page .navigation_26 .slider img {
      min-height: 250px;
      min-width: 100%; }
    .login-page .navigation_26 .slider .inner {
      top: 0;
      left: 0; }
    .login-page .navigation_26 .slider .slick-dots {
      left: 20px;
      right: auto;
      bottom: 25px; }
      .login-page .navigation_26 .slider .slick-dots li.slick-active button {
        background: #1e3060; }
      .login-page .navigation_26 .slider .slick-dots li button {
        background: transparent;
        border: 2px solid #1e3060; }
  .login-page .navigation_26 .right_block {
    overflow: hidden; }
    .login-page .navigation_26 .right_block .bg {
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%; }
  @media (max-width: 1199.98px) {
    .login-page .navigation_26 .categories .title {
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    .login-page .content_1 h2 {
      margin-top: 10px; } }
  @media (max-width: 991.98px) {
    .login-page .content_2 .text-adaptive {
      margin-top: 15px; } }
  @media (max-width: 767.98px) {
    .login-page .content_3 .blocks {
      margin-top: 10px; } }
  @media (max-width: 767.98px) {
    .login-page .content_4 .icons .fa {
      margin: 0 15px; }
    .login-page .content_4 h2 {
      margin-top: 30px; }
    .login-page .content_4 .text-adaptive {
      margin-top: 15px; } }
  @media (max-width: 767.98px) {
    .login-page .content_5 h2 {
      margin-top: 15px; } }
  @media (max-width: 991.98px) {
    .login-page .content_6 .text-adaptive {
      margin-top: 15px; } }
  .login-page .content_13 .inner {
    border: 2px solid #E7EAED; }
  @media (max-width: 767.98px) {
    .login-page .content_13 .inner {
      padding-top: 30px;
      padding-bottom: 30px; }
    .login-page .content_13 h2 {
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    .login-page .content_14 .inner {
      padding-top: 30px;
      padding-bottom: 30px; } }
  @media (max-width: 1199.98px) {
    .login-page .content_15 .container {
      padding-top: 40px; } }
  @media (max-width: 767.98px) {
    .login-page .content_15 .text-adaptive {
      margin-top: 10px; } }
  @media (max-width: 1199.98px) {
    .login-page .content_16 h2,
    .login-page .content_16 .text-adaptive {
      padding-left: 0; } }
  @media (max-width: 767.98px) {
    .login-page .content_16 .text-adaptive {
      margin-top: 5px; } }
  @media (max-width: 359.98px) {
    .login-page .content_17 .block_tip {
      padding-left: 20px;
      padding-right: 20px; } }
  .login-page .content_18 .opening_tabs .tab_opener .fa,
  .login-page .content_18 .opening_tabs .tab_opener .fas,
  .login-page .content_18 .opening_tabs .tab_opener .far,
  .login-page .content_18 .opening_tabs .tab_opener .fab {
    transition: all 0.2s; }
  .login-page .content_18 .opening_tabs .tab_opener.opened .fa,
  .login-page .content_18 .opening_tabs .tab_opener.opened .fas,
  .login-page .content_18 .opening_tabs .tab_opener.opened .far,
  .login-page .content_18 .opening_tabs .tab_opener.opened .fab {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .login-page .content_18 .opening_tabs .tab_text {
    border-bottom: 2px solid #E7EAED; }
    .login-page .content_18 .opening_tabs .tab_text:last-child {
      border: none; }
  @media (max-width: 991.98px) {
    .login-page .content_18 .block_right {
      margin-left: 0;
      width: auto; } }
  .login-page .content_20 .number {
    position: absolute;
    top: 0;
    left: 0; }
  .login-page .content_20 .content_20_line {
    position: absolute;
    top: 0;
    left: 19px;
    width: 2px;
    height: 100%;
    background: #E7EAED; }
  .login-page .content_20 .video_block .fa,
  .login-page .content_20 .video_block .fas,
  .login-page .content_20 .video_block .far,
  .login-page .content_20 .video_block .fab {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  @media (max-width: 575.98px) {
    .login-page .content_20 .video_block .inner {
      margin-left: 0;
      margin-top: 30px; } }
  @media (max-width: 374.98px) {
    .login-page .content_20 .number {
      position: static; }
    .login-page .content_20 .block_inner {
      margin-left: 0; }
    .login-page .content_20 .content_20_line {
      display: none; } }
  @media (max-width: 767.98px) {
    .login-page .content_21[class^="pt-"], .login-page .content_21[class*=" pt-"] {
      padding-top: 40px; }
    .login-page .content_21[class^="pb-"], .login-page .content_21[class*=" pb-"] {
      padding-bottom: 40px; } }
  .login-page .content_22 {
    min-height: 100vh; }
    @media (max-width: 1199.98px) {
      .login-page .content_22[class^="pb-"], .login-page .content_22[class*=" pb-"] {
        padding-bottom: 30px; } }
  @media (max-width: 767.98px) {
    .login-page .content_24[class^="pt-"], .login-page .content_24[class*=" pt-"] {
      padding-top: 40px; }
    .login-page .content_24[class^="pb-"], .login-page .content_24[class*=" pb-"] {
      padding-bottom: 40px; }
    .login-page .content_24 .tip {
      margin-top: 10px;
      margin-left: 10px; } }
  .login-page .content_25 {
    min-height: 100vh; }
  @media (max-width: 991.98px) {
    .login-page .content_26 .btn {
      margin-top: 30px; } }
  .login-page .content_27 .image .fa,
  .login-page .content_27 .image .fas,
  .login-page .content_27 .image .far,
  .login-page .content_27 .image .fab {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  @media (max-width: 767.98px) {
    .login-page .content_27 .poster {
      margin-bottom: 20px; } }
  @media (max-width: 575.98px) {
    .login-page .content_27 .image {
      margin-left: auto;
      margin-right: auto; } }
  @media (max-width: 767.98px) {
    .login-page .content_29[class^="pb-"], .login-page .content_29[class*=" pb-"] {
      padding-bottom: 60px; }
    .login-page .content_29 .holder {
      margin-bottom: 15px; } }
  .login-page .content_30 .block {
    background: rgba(231, 234, 237, 0.4); }
    .login-page .content_30 .block img {
      max-width: 70%;
      max-height: 70%; }
  .login-page .content_31 .block {
    border: 2px solid rgba(255, 255, 255, 0.15); }
    .login-page .content_31 .block img {
      max-width: 70%;
      max-height: 70%; }
  @media (max-width: 767.98px) {
    .login-page .content_31 .text-adaptive {
      margin-top: 10px;
      margin-bottom: 30px; } }
  .login-page .content_32 .left .cell {
    padding: 22px 30px 20px 30px; }
    .login-page .content_32 .left .cell.bordered {
      border-bottom: 2px solid #E7EAED; }
  .login-page .content_32 .right {
    background: rgba(231, 234, 237, 0.6); }
  .login-page .content_33 .table td,
  .login-page .content_33 .table th {
    padding: 24px 15px 20px 15px; }
  .login-page .content_33 .table tr td:first-of-type,
  .login-page .content_33 .table tr th:first-of-type {
    padding-left: 30px; }
  .login-page .content_33 .table tr td:last-of-type,
  .login-page .content_33 .table tr th:last-of-type {
    padding-right: 30px; }
  @media (max-width: 1199.98px) {
    .login-page .content_33 .table-responsive-shadow {
      position: relative; }
      .login-page .content_33 .table-responsive-shadow:after {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 40px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 78%); } }
  .login-page .content_34 #copy_from_me {
    background: rgba(231, 234, 237, 0.5);
    font-family: Courier New, monospace; }
    .login-page .content_34 #copy_from_me pre {
      color: #1e3060; }
  @media (max-width: 992.98px) {
    .login-page .content_34 .right {
      margin-top: 50px;
      padding: 0; } }
  @media (max-width: 767.98px) {
    .login-page .testimonial_2 .text-adaptive {
      margin-top: 10px;
      margin-bottom: 25px; } }
  @media (max-width: 1199.98px) {
    .login-page .testimonial_3[class^="pb-"], .login-page .testimonial_3[class*=" pb-"] {
      padding-bottom: 20px; } }
  @media (max-width: 767.98px) {
    .login-page .testimonial_3 h2,
    .login-page .testimonial_3 .block {
      margin-bottom: 30px; } }
  .login-page .testimonial_4 .block {
    border: 2px solid #E7EAED; }
  @media (max-width: 991.98px) {
    .login-page .testimonial_4 .holder {
      max-width: 570px; } }
  @media (max-width: 767.98px) {
    .login-page .testimonial_4 .block {
      padding: 30px; }
    .login-page .testimonial_4 .text-adaptive {
      margin-bottom: 20px; } }
  @media (max-width: 575.98px) {
    .login-page .testimonial_4 .inner {
      margin-left: 0; } }
  @media (max-width: 1199.98px) {
    .login-page .testimonial_5[class^="pb-"], .login-page .testimonial_5[class*=" pb-"] {
      padding-bottom: 30px; } }
  .login-page .testimonial_6 .block {
    border: 2px solid rgba(255, 255, 255, 0.15); }
  .login-page .testimonial_6 .author {
    left: 0;
    bottom: 0; }
  @media (max-width: 575.98px) {
    .login-page .testimonial_6 .inner {
      padding-left: 20px;
      padding-bottom: 0; }
    .login-page .testimonial_6 .info {
      margin-left: 0; }
    .login-page .testimonial_6 .author {
      margin-top: 10px;
      position: static; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_1[class^="pb-"], .login-page .feature_1[class*=" pb-"] {
      padding-bottom: 30px; } }
  @media (max-width: 767.98px) {
    .login-page .feature_1 .title {
      margin-top: 10px;
      margin-bottom: 10px; }
      .login-page .feature_1 .title br {
        display: none; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_2[class^="pb-"], .login-page .feature_2[class*=" pb-"] {
      padding-bottom: 30px; }
    .login-page .feature_2 .description {
      margin-top: 20px; } }
  .login-page .feature_3 .block {
    border: 2px solid #E7EAED; }
  @media (max-width: 1199.98px) {
    .login-page .feature_3[class^="pb-"], .login-page .feature_3[class*=" pb-"] {
      padding-bottom: 50px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_3 .description {
      margin-top: 10px;
      margin-bottom: 40px; } }
  .login-page .feature_4 .block {
    border: 2px solid rgba(255, 255, 255, 0.3); }
  @media (max-width: 1199.98px) {
    .login-page .feature_4[class^="pb-"], .login-page .feature_4[class*=" pb-"] {
      padding-bottom: 50px; }
    .login-page .feature_4 .description {
      margin-top: 10px;
      margin-bottom: 40px; }
    .login-page .feature_4 .block {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 50px; } }
  .login-page .feature_5 .block {
    border: 2px solid #E7EAED;
    border-top: none; }
  @media (max-width: 1199.98px) {
    .login-page .feature_5[class^="pb-"], .login-page .feature_5[class*=" pb-"] {
      padding-bottom: 30px; }
    .login-page .feature_5 .description {
      margin-top: 10px; }
    .login-page .feature_5 .block {
      padding: 30px; }
      .login-page .feature_5 .block .title {
        margin-bottom: 10px; } }
  @media (max-width: 575.98px) {
    .login-page .feature_5 .holder {
      max-width: 370px; } }
  .login-page .feature_6 .block_bordered {
    border: 2px solid rgba(255, 255, 255, 0.2); }
  @media (max-width: 991.98px) {
    .login-page .feature_6 .block {
      margin-top: 40px; }
    .login-page .feature_6 .title {
      margin-top: 15px;
      margin-bottom: 10px; }
    .login-page .feature_6 .block_bordered {
      margin-top: 50px;
      padding: 30px; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_7[class^="pb-"], .login-page .feature_7[class*=" pb-"] {
      padding-bottom: 20px; } }
  @media (max-width: 767.98px) {
    .login-page .feature_7 h2 {
      margin-bottom: 40px; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_8[class^="pb-"], .login-page .feature_8[class*=" pb-"] {
      padding-bottom: 40px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_8 .icon {
      margin-left: 0;
      width: 25px; }
    .login-page .feature_8 .title {
      margin-bottom: 10px; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_9[class^="pb-"], .login-page .feature_9[class*=" pb-"] {
      padding-bottom: 40px; }
    .login-page .feature_9 h2 {
      margin-bottom: 15px; }
    .login-page .feature_9 .title {
      margin-bottom: 10px; }
    .login-page .feature_9 .hr {
      margin-bottom: 45px; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_10[class^="pb-"], .login-page .feature_10[class*=" pb-"] {
      padding-bottom: 40px; } }
  @media (max-width: 767.98px) {
    .login-page .feature_10 h2 {
      margin-bottom: 10px; } }
  .login-page .feature_11 {
    overflow: hidden; }
    @media (max-width: 992.98px) {
      .login-page .feature_11 .bg {
        max-height: 100vh; }
      .login-page .feature_11 .description {
        margin-top: 10px;
        margin-bottom: 50px; } }
    @media (max-width: 575.98px) {
      .login-page .feature_11 .bg {
        margin-top: 50px;
        max-height: 70vh;
        max-width: 100%; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_12[class^="pb-"], .login-page .feature_12[class*=" pb-"] {
      padding-bottom: 0px; }
    .login-page .feature_12 .icon {
      margin-left: 0; } }
  @media (max-width: 991.98px) {
    .login-page .feature_12 .title {
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    .login-page .feature_12 h2 {
      margin: 10px 0; }
    .login-page .feature_12 .icon {
      margin: 0 auto 10px auto; }
    .login-page .feature_12 .bg {
      margin-top: 50px; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_13[class^="pb-"], .login-page .feature_13[class*=" pb-"] {
      padding-bottom: 40px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_14[class^="pb-"], .login-page .feature_14[class*=" pb-"] {
      padding-bottom: 40px; }
    .login-page .feature_14 h2 {
      margin-bottom: 0; }
    .login-page .feature_14 .main_title {
      margin-top: 10px; }
    .login-page .feature_14 .bg {
      max-height: 80vh;
      margin-bottom: 50px; }
    .login-page .feature_14 .block {
      margin-bottom: 40px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_15 h2 {
      margin: 0px 0 10px 0; }
    .login-page .feature_15 .block {
      margin-top: 40px; }
    .login-page .feature_15 .title {
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    .login-page .feature_15 .bg {
      margin-top: 50px;
      max-height: 80vh; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_16[class^="pb-"], .login-page .feature_16[class*=" pb-"] {
      padding-bottom: 30px; } }
  @media (max-width: 575.98px) {
    .login-page .feature_16 h2 {
      margin-bottom: 10px; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_17[class^="pb-"], .login-page .feature_17[class*=" pb-"] {
      padding-bottom: 30px; }
    .login-page .feature_17 h6 {
      margin-top: 10px;
      margin-bottom: 50px; }
      .login-page .feature_17 h6 br {
        display: none; } }
  @media (max-width: 767.98px) {
    .login-page .feature_17 .img {
      margin-bottom: 40px;
      max-height: 70vh; }
    .login-page .feature_17 .title {
      margin-bottom: 10px; }
    .login-page .feature_17 .block {
      margin-bottom: 40px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_18 .description {
      margin-top: 10px;
      margin-bottom: 40px; }
    .login-page .feature_18 .block {
      margin-bottom: 40px; } }
  @media (max-width: 767.98px) {
    .login-page .feature_18 .description {
      margin-bottom: 0; }
    .login-page .feature_18 .img {
      max-height: 70vh; } }
  @media (max-width: 767.98px) {
    .login-page .feature_19 .icons {
      margin-bottom: 30px;
      font-size: 26px;
      line-height: 36px; }
    .login-page .feature_19 .btn {
      margin-top: 30px; } }
  .login-page .feature_20 .bg {
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  @media (max-width: 1199.98px) {
    .login-page .feature_20 .left_side {
      padding-top: 80px;
      padding-bottom: 40px; }
    .login-page .feature_20 h2 {
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    .login-page .feature_20 .btn {
      margin-bottom: 40px; }
    .login-page .feature_20 .title {
      margin: 10px 0; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_21[class^="pb-"], .login-page .feature_21[class*=" pb-"] {
      padding-bottom: 30px; }
    .login-page .feature_21 .block {
      margin-bottom: 50px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_21 .description {
      margin-top: 10px;
      margin-bottom: 50px; } }
  .login-page .feature_22 .buttons .btn {
    min-width: 200px; }
  @media (max-width: 991.98px) {
    .login-page .feature_22[class^="pb-"], .login-page .feature_22[class*=" pb-"] {
      padding-bottom: 40px; }
    .login-page .feature_22 .description {
      margin-top: 10px;
      margin-bottom: 40px; }
    .login-page .feature_22 .icon i {
      font-size: 32px;
      line-height: 42px; }
    .login-page .feature_22 .title {
      margin-bottom: 10px; }
    .login-page .feature_22 .block {
      margin-bottom: 40px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_23 .description {
      margin-top: 10px;
      margin-bottom: 40px; } }
  @media (max-width: 767.98px) {
    .login-page .feature_23 .info {
      margin-left: 0; } }
  .login-page .feature_25 .bg {
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  @media (max-width: 1199.98px) {
    .login-page .feature_25 .left_side {
      padding-top: 80px;
      padding-bottom: 40px; }
    .login-page .feature_25 .description {
      margin: 10px 0 50px 0; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_26 .link {
      margin-bottom: 40px; } }
  @media (max-width: 575.98px) {
    .login-page .feature_26[class^="pb-"], .login-page .feature_26[class*=" pb-"] {
      padding-bottom: 40px; }
    .login-page .feature_26 .img {
      max-height: 70vh; }
    .login-page .feature_26 h2 {
      margin-bottom: 10px; }
    .login-page .feature_26 .title {
      margin-bottom: 10px; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_27[class^="pb-"], .login-page .feature_27[class*=" pb-"] {
      padding-bottom: 30px; } }
  @media (max-width: 767.98px) {
    .login-page .feature_27 .description {
      margin: 10px 0 50px 0; } }
  @media (max-width: 575.98px) {
    .login-page .feature_28 .description {
      margin: 10px 0 40px 0; }
    .login-page .feature_28 .img {
      margin-top: 40px; } }
  .login-page .feature_29 .slick-slide {
    cursor: -webkit-grab;
    cursor: grab; }
  .login-page .feature_29 .slick-dots {
    left: 0;
    bottom: auto;
    top: 50%;
    width: 10px;
    margin-top: -35px; }
    .login-page .feature_29 .slick-dots li {
      margin: 5px; }
  @media (max-width: 1199.98px) {
    .login-page .feature_29 .slick-slide {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_29 .slick-dots {
      top: auto;
      margin-top: 0;
      width: 100%;
      bottom: 30px; }
    .login-page .feature_29 h2 {
      margin-bottom: 10px; }
    .login-page .feature_29 .title {
      margin: 10px 0; } }
  @media (max-width: 767.98px) {
    .login-page .feature_29 .img {
      max-height: 70vh;
      margin-bottom: 50px; } }
  .login-page .feature_31 .img {
    top: 0;
    right: 15px; }
  .login-page .feature_31 .slick-slide {
    cursor: -webkit-grab;
    cursor: grab; }
  .login-page .feature_31 .slick-dots {
    left: auto;
    right: calc((100% - 1170px) / 2);
    bottom: auto;
    top: 50%;
    width: 10px;
    margin-top: -35px; }
    .login-page .feature_31 .slick-dots li {
      margin: 5px; }
  @media (max-width: 1199.98px) {
    .login-page .feature_31 .slick-dots {
      right: calc((100% - 970px) / 2); }
    .login-page .feature_31 .slick-slide {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_31 .slick-dots {
      right: calc((100% - 750px) / 2); } }
  @media (max-width: 767.98px) {
    .login-page .feature_31 .slick-dots {
      top: auto;
      bottom: 30px;
      left: 0;
      right: 0;
      width: auto; }
    .login-page .feature_31 .slick-slide {
      padding-bottom: 40px; }
    .login-page .feature_31 .right_side {
      padding-bottom: 0; }
    .login-page .feature_31 .img {
      position: static;
      height: auto;
      max-width: 100%; }
    .login-page .feature_31 .title {
      margin-bottom: 10px; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_32 .block {
      margin-left: 20px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_32 .decription {
      margin: 10px 0 40px 0; }
    .login-page .feature_32 .left_side,
    .login-page .feature_32 .right_side {
      margin-top: 0; }
    .login-page .feature_32 .block {
      margin-left: 0; } }
  @media (max-width: 991.98px) {
    .login-page .feature_33 .description {
      margin-top: 10px; }
    .login-page .feature_33 .title {
      margin-bottom: 0px; } }
  .login-page .feature_34 {
    overflow: hidden; }
    @media (max-width: 767.98px) {
      .login-page .feature_34[class^="pb-"], .login-page .feature_34[class*=" pb-"] {
        padding-bottom: 30px; }
      .login-page .feature_34 .title {
        margin: 10px 0; } }
    @media (max-width: 575.98px) {
      .login-page .feature_34 h2 {
        margin-bottom: 10px; }
      .login-page .feature_34 .img {
        max-width: 100%; }
      .login-page .feature_34 .blocks {
        margin-top: 50px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_36 h2 {
      margin-top: 0;
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    .login-page .feature_36 .img {
      max-height: 70vh; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_37[class^="pb-"], .login-page .feature_37[class*=" pb-"] {
      padding-bottom: 40px; }
    .login-page .feature_37 h2 {
      margin-bottom: 10px; }
    .login-page .feature_37 .advantage {
      margin-bottom: 40px; } }
  .login-page .feature_38 {
    background-position: 50% 100%;
    overflow: hidden;
    padding-bottom: 370px; }
    .login-page .feature_38 .img {
      top: 70px;
      left: 50%;
      -webkit-transform: translateX(-44%);
              transform: translateX(-44%); }
    @media (max-width: 1199.98px) {
      .login-page .feature_38 {
        padding-bottom: 300px; }
        .login-page .feature_38 .img {
          height: 150%; } }
    @media (max-width: 991.98px) {
      .login-page .feature_38 {
        padding-bottom: 250px; }
        .login-page .feature_38 .img {
          top: 200px;
          height: 125%; } }
    @media (max-width: 767.98px) {
      .login-page .feature_38 h2 {
        margin-bottom: 10px; }
      .login-page .feature_38 .link {
        margin-top: 20px; } }
    @media (max-width: 575.98px) {
      .login-page .feature_38 {
        padding-bottom: 200px; }
        .login-page .feature_38 .img {
          top: 50%;
          height: 100%; }
        .login-page .feature_38 .link {
          font-size: 16px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_39 .description {
      margin: 10px 0 0 0; }
    .login-page .feature_39 .advantage {
      margin-top: 40px; } }
  @media (max-width: 767.98px) {
    .login-page .feature_40[class^="pb-"], .login-page .feature_40[class*=" pb-"] {
      padding-bottom: 45px; } }
  @media (max-width: 991.98px) {
    .login-page .feature_41 .description {
      margin: 10px 0 40px 0; }
    .login-page .feature_41 .inner {
      padding-bottom: 60px; } }
  .login-page .feature_42 .bg {
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  @media (max-width: 575.98px) {
    .login-page .feature_42 .inner {
      padding-top: 80px;
      padding-bottom: 60px; }
    .login-page .feature_42 h2 {
      margin-bottom: 10px; }
    .login-page .feature_42 .hr {
      margin: 40px 0 0 0; } }
  .login-page .feature_43 .bg {
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  @media (max-width: 575.98px) {
    .login-page .feature_43 .inner {
      padding-top: 80px;
      padding-bottom: 80px; }
    .login-page .feature_43 h2 {
      margin-bottom: 10px; } }
  .login-page .feature_44 {
    overflow: hidden; }
    @media (max-width: 767.98px) {
      .login-page .feature_44 h2 {
        margin-top: 0;
        margin-bottom: 10px; }
      .login-page .feature_44 .img {
        margin-top: 40px;
        max-width: 100%; }
      .login-page .feature_44 .row2 {
        margin-top: 50px; }
      .login-page .feature_44 .inner2 {
        padding-bottom: 0; } }
  @media (max-width: 1199.98px) {
    .login-page .feature_45[class^="pb-"], .login-page .feature_45[class*=" pb-"] {
      padding-bottom: 35px; } }
  @media (max-width: 575.98px) {
    .login-page .feature_45 h2,
    .login-page .feature_45 .title {
      margin-bottom: 10px; }
    .login-page .feature_45 .description {
      margin-bottom: 40px; } }
  .login-page .feature_46 .block:hover, .login-page .feature_46 .block:focus {
    opacity: 0.7; }
  @media (max-width: 991.98px) {
    .login-page .feature_46 h2 {
      margin-bottom: 10px; }
    .login-page .feature_46 .block {
      margin-bottom: 50px; } }
  .login-page .feature_47 .block {
    border: 2px solid rgba(255, 255, 255, 0.2); }
  @media (max-width: 1199.98px) {
    .login-page .feature_47[class^="pb-"], .login-page .feature_47[class*=" pb-"] {
      padding-bottom: 50px; }
    .login-page .feature_47 h2 {
      margin-bottom: 30px; }
    .login-page .feature_47 .block {
      padding: 30px; } }
  @media (max-width: 467.98px) {
    .login-page .form_1 h2 {
      margin-bottom: 20px; }
    .login-page .form_1 form {
      padding: 30px; } }
  @media (max-width: 359.98px) {
    .login-page .form_1 form {
      padding: 30px 20px; } }
  .login-page .form_2 .img_holder img {
    left: 50%;
    top: 0;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  @media (max-width: 1199.98px) {
    .login-page .form_2 form {
      padding: 80px 0; }
    .login-page .form_2 .buttons {
      margin-top: 10px; }
    .login-page .form_2 button {
      margin-right: 0px;
      width: 100%; } }
  @media (max-width: 575.98px) {
    .login-page .form_2 form {
      max-width: 290px;
      margin: 0 auto; } }
  .login-page .form_4 {
    background-image: url("/images/form_4_bg@2x.jpg"); }
    .login-page .form_4 .form_4_menu {
      display: grid;
      grid-template-columns: 1fr 1fr; }
      .login-page .form_4 .form_4_menu.forgot-password {
        display: flex;
        justify-content: center; }
        .login-page .form_4 .form_4_menu.forgot-password .link {
          border-bottom-color: #e7eaed !important; }
      .login-page .form_4 .form_4_menu .link {
        border-bottom: 3px solid #e7eaed;
        transition: all .2s ease;
        cursor: pointer; }
        .login-page .form_4 .form_4_menu .link.active {
          border-bottom-color: #1e3060; }
      .login-page .form_4 .form_4_menu .slick-track {
        position: relative;
        border-bottom: 3px solid #E7EAED; }
      .login-page .form_4 .form_4_menu a {
        width: 50%; }
        .login-page .form_4 .form_4_menu a:after {
          content: "";
          display: block;
          width: 0%;
          height: 3px;
          background: #1e3060;
          position: absolute;
          right: 0;
          bottom: -3px;
          transition: width 0.2s;
          z-index: 10000; }
        .login-page .form_4 .form_4_menu a:last-child:after {
          left: 0;
          right: auto; }
        .login-page .form_4 .form_4_menu a.slick-current {
          color: #1e3060; }
          .login-page .form_4 .form_4_menu a.slick-current:after {
            width: 100%; }
    .login-page .form_4 .separate {
      position: relative; }
      .login-page .form_4 .separate:before {
        content: " ";
        width: 100%;
        position: absolute;
        left: 0;
        top: 50%;
        border-bottom: 1px solid #E7EAED; }
    @media (max-width: 575.98px) {
      .login-page .form_4 h2 {
        margin-bottom: 10px; }
      .login-page .form_4 .form_4_menu a {
        line-height: 60px; }
      .login-page .form_4 .slider {
        margin-top: 40px;
        padding-bottom: 40px; } }
  @media (max-width: 767.98px) {
    .login-page .form_6 form {
      padding-top: 40px; }
    .login-page .form_6 h2 {
      margin-bottom: 30px; }
    .login-page .form_6 .hr {
      margin-top: 30px; } }
  .login-page .form_7 .right_side {
    border: 2px solid rgba(255, 255, 255, 0.2); }
  @media (max-width: 767.98px) {
    .login-page .form_7 h2 {
      margin-bottom: 10px; }
    .login-page .form_7 .right_side {
      margin-top: 50px;
      padding: 30px; } }
  @media (max-width: 991.98px) {
    .login-page .form_9 form {
      margin-top: 40px; }
    .login-page .form_9 .input {
      width: 100%;
      margin-right: 0; } }
  @media (max-width: 767.98px) {
    .login-page .form_10 .icons {
      font-size: 32px;
      line-height: 42px; }
      .login-page .form_10 .icons i {
        margin: 0 15px; }
    .login-page .form_10 h2 {
      margin-bottom: 10px; }
    .login-page .form_10 form {
      margin-top: 40px; }
    .login-page .form_10 .input {
      width: 100%;
      margin-right: 0; } }
  .login-page .form_11 form {
    border: 2px solid #E7EAED; }
  @media (max-width: 767.98px) {
    .login-page .form_11 form {
      padding: 50px 30px; }
    .login-page .form_11 .input {
      width: 100%;
      margin-right: 0; } }
  .login-page .form_13 .js-form-block {
    border: 2px solid #E7EAED;
    transition: all 0.2s; }
    .login-page .form_13 .js-form-block.active {
      border-color: #0074f1; }
  .login-page .form_13 .icons {
    top: 25px;
    right: 30px;
    line-height: 1; }
  @media (max-width: 991.98px) {
    .login-page .form_13 .block {
      width: 250px; } }
  @media (max-width: 767.98px) {
    .login-page .form_13 h2 {
      margin-bottom: 30px; }
    .login-page .form_13 .js-form-block {
      padding: 30px; }
    .login-page .form_13 .icons {
      font-size: 32px; }
    .login-page .form_13 .block {
      margin-right: 0;
      width: 100%; } }
  @media (max-width: 469.98px) {
    .login-page .form_13 .inner,
    .login-page .form_13 .text-adaptive {
      margin-left: 0; } }
  .login-page .form_14 form {
    border: 2px solid #E7EAED; }
  @media (max-width: 575.98px) {
    .login-page .form_14 form {
      padding: 50px 30px; } }
  .login-page .form_15 .form_15_menu .slick-track {
    position: relative;
    border-bottom: 3px solid #E7EAED; }
  .login-page .form_15 .form_15_menu a {
    width: 50%; }
    .login-page .form_15 .form_15_menu a:after {
      content: "";
      display: block;
      width: 0%;
      height: 3px;
      background: #1e3060;
      position: absolute;
      right: 0;
      bottom: -3px;
      transition: width 0.2s;
      z-index: 10000; }
    .login-page .form_15 .form_15_menu a:last-child:after {
      left: 0;
      right: auto; }
    .login-page .form_15 .form_15_menu a.slick-current {
      color: #1e3060; }
      .login-page .form_15 .form_15_menu a.slick-current:after {
        width: 100%; }
  @media (max-width: 575.98px) {
    .login-page .form_15 h2 {
      margin-bottom: 30px; }
    .login-page .form_15 .form_15_menu a {
      line-height: 60px; }
    .login-page .form_15 .slider {
      margin-top: 40px;
      padding: 0 20px 40px 20px; } }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_1 .description {
      margin: 10px 0 30px 0; }
    .login-page .call_to_action_1 .btn {
      margin-bottom: 15px; } }
  .login-page .call_to_action_2 .inner {
    border: 2px solid #E7EAED; }
  @media (max-width: 991.98px) {
    .login-page .call_to_action_2 .inner {
      padding: 40px 30px; } }
  @media (max-width: 575.98px) {
    .login-page .call_to_action_2 .inner {
      padding: 30px 20px; } }
  @media (max-width: 575.98px) {
    .login-page .call_to_action_3 .inner {
      padding: 50px 30px; } }
  @media (max-width: 991.98px) {
    .login-page .call_to_action_4 .text-adaptive {
      margin-top: 10px; } }
  .login-page .call_to_action_5 .inner {
    min-height: 100vh; }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_7 h2 {
      margin-bottom: 10px; }
    .login-page .call_to_action_7 .btn {
      margin-top: 30px; } }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_8 h2 {
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_9 .btn {
      margin-top: 30px; } }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_10 h2 {
      margin-bottom: 0; } }
  .login-page .call_to_action_12 .btn {
    height: auto; }
  @media (max-width: 575.98px) {
    .login-page .call_to_action_13 .btn {
      margin: 20px 10px 0 10px; } }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_14 h2 {
      margin-bottom: 10px; }
    .login-page .call_to_action_14 .title {
      margin: 15px 0 10px 0; }
    .login-page .call_to_action_14 .btn {
      margin-top: 25px; } }
  .login-page .call_to_action_15 .inner {
    border: 2px solid #E7EAED; }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_15 h2 {
      margin: 10px 0; }
    .login-page .call_to_action_15 .inner {
      padding-top: 30px;
      padding-bottom: 40px; } }
  @media (max-width: 575.98px) {
    .login-page .call_to_action_16 .btn {
      margin-left: 10px;
      margin-right: 10px; } }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_17 .buttons {
      font-size: 32px;
      line-height: 42px; }
      .login-page .call_to_action_17 .buttons a {
        margin: 0 10px 20px 10px; } }
  .login-page .call_to_action_19 .link {
    display: flex;
    width: 20%; }
    .login-page .call_to_action_19 .link:hover i {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    .login-page .call_to_action_19 .link i {
      transition: all 0.2s; }
  .login-page .call_to_action_19 .border_right {
    border-right: 2px solid #E7EAED; }
  @media (max-width: 1199.98px) {
    .login-page .call_to_action_19 .inner {
      height: 200px; } }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_19 .inner {
      height: 140px; }
      .login-page .call_to_action_19 .inner i {
        font-size: 36px; } }
  @media (max-width: 575.98px) {
    .login-page .call_to_action_19 .inner {
      height: 100px; }
      .login-page .call_to_action_19 .inner i {
        font-size: 28px; } }
  @media (max-width: 1199.98px) {
    .login-page .call_to_action_20[class^="pb-"], .login-page .call_to_action_20[class*=" pb-"] {
      padding-bottom: 50px; } }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_21 .text-adaptive {
      margin-top: 10px;
      margin-bottom: 40px; }
    .login-page .call_to_action_21 .play {
      width: 60px;
      height: 60px;
      line-height: 60px; } }
  @media (max-width: 575.98px) {
    .login-page .call_to_action_21 .play {
      margin: 0 auto; }
    .login-page .call_to_action_21 .btn {
      margin-left: 0; } }
  .login-page .call_to_action_22 .inner {
    border: 2px solid rgba(255, 255, 255, 0.3); }
  @media (max-width: 575.98px) {
    .login-page .call_to_action_22 .inner {
      padding: 40px 15px; }
    .login-page .call_to_action_22 .play {
      margin: 30px 0; } }
  .login-page .call_to_action_23 .img {
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  @media (max-width: 991.98px) {
    .login-page .call_to_action_23 {
      padding: 80px 0; }
      .login-page .call_to_action_23 .img {
        position: static;
        -webkit-transform: none;
                transform: none;
        height: auto;
        width: 100%;
        border-radius: 10px; }
      .login-page .call_to_action_23 .inner {
        padding: 40px 0 0 0; }
      .login-page .call_to_action_23 .text-adaptive {
        margin: 10px 0 40px 0; } }
  @media (max-width: 575.98px) {
    .login-page .call_to_action_26 .btn {
      margin: 0 10px 20px 10px; }
    .login-page .call_to_action_26 .img {
      margin-top: 40px; } }
  @media (max-width: 575.98px) {
    .login-page .call_to_action_27 .input {
      max-width: 290px; }
    .login-page .call_to_action_27 .btn {
      margin: 20px 0px 0px 0px; } }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_28 .inner {
      padding-left: 0; } }
  .login-page .call_to_action_29 .img {
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  @media (max-width: 991.98px) {
    .login-page .call_to_action_29 {
      padding-top: 60px; }
      .login-page .call_to_action_29 .img {
        position: static;
        max-width: 100%;
        height: auto;
        max-height: 70vh;
        -webkit-transform: none;
                transform: none; }
      .login-page .call_to_action_29 .inner {
        padding-top: 40px;
        padding-bottom: 60px; }
      .login-page .call_to_action_29 h2 {
        margin-bottom: 10px; }
      .login-page .call_to_action_29 .btn {
        margin-top: 30px; } }
  .login-page .call_to_action_30 {
    overflow: hidden; }
    @media (max-width: 575.98px) {
      .login-page .call_to_action_30 .img {
        position: static;
        max-width: 100%; }
      .login-page .call_to_action_30 .inner {
        padding-top: 0;
        padding-bottom: 60px; }
      .login-page .call_to_action_30 .btn {
        margin: 20px 10px 0 10px; } }
  .login-page .call_to_action_31 {
    overflow: hidden; }
    .login-page .call_to_action_31 .img {
      bottom: 0;
      left: 0px; }
    @media (max-width: 767.98px) {
      .login-page .call_to_action_31 .inner {
        padding-bottom: 60px; }
      .login-page .call_to_action_31 .img {
        position: static;
        max-width: 100%; } }
  @media (max-width: 767.98px) {
    .login-page .call_to_action_32 {
      padding-bottom: 40px; }
      .login-page .call_to_action_32 .logos {
        margin-bottom: 0; }
        .login-page .call_to_action_32 .logos img {
          margin: 0 10px 20px 10px; }
      .login-page .call_to_action_32 .inner {
        padding: 80px 0px 60px 0px; }
      .login-page .call_to_action_32 .img {
        max-width: 100%; } }
  .login-page .team_1 .block {
    border: 2px solid #E7EAED; }
  .login-page .team_1 .block_socials {
    border-top: 2px solid #E7EAED; }
    .login-page .team_1 .block_socials .border_right {
      border-right: 2px solid #E7EAED; }
  .login-page .team_2 .bordered {
    border-left: 2px solid #E7EAED;
    border-right: 2px solid #E7EAED; }
  @media (max-width: 767.98px) {
    .login-page .team_2 .bordered {
      border: none;
      border-top: 2px solid #E7EAED;
      border-bottom: 2px solid #E7EAED; } }
  @media (max-width: 991.98px) {
    .login-page .team_4 h2 {
      margin-bottom: 0; } }
  @media (max-width: 575.98px) {
    .login-page .team_5 .block_socials .link {
      margin: 0 10px; } }
  .login-page .pricing_table_1 .block {
    border: 2px solid #E7EAED;
    border-bottom-left-radius: 10px; }
    .login-page .pricing_table_1 .block.noborder_right {
      border-right: none; }
  .login-page .pricing_table_1 .item {
    padding: 16px 0 14px 0;
    border-top: 2px solid #E7EAED; }
  .login-page .pricing_table_1 .price span.absolute {
    top: 5px;
    left: -15px; }
  @media (max-width: 991.98px) {
    .login-page .pricing_table_1[class^="pb-"], .login-page .pricing_table_1[class*=" pb-"] {
      padding-bottom: 30px; }
    .login-page .pricing_table_1 .block {
      margin: 0 auto 50px auto;
      max-width: 300px;
      border-radius: 10px; }
      .login-page .pricing_table_1 .block.noborder_right {
        border-right: 2px solid #E7EAED; } }
  .login-page .pricing_table_3 .overflow {
    overflow: auto; }
  .login-page .pricing_table_3 .block {
    border-right: 2px solid #E7EAED; }
    .login-page .pricing_table_3 .block.noborder_right {
      border-right: none; }
  .login-page .pricing_table_3 .price span.absolute {
    top: -5px;
    left: -15px; }
  .login-page .pricing_table_3 .table_row {
    border-left: 2px solid #E7EAED;
    border-right: 2px solid #E7EAED;
    border-bottom: 2px solid #E7EAED; }
    .login-page .pricing_table_3 .table_row:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px; }
  .login-page .pricing_table_3 .table_head {
    background: rgba(231, 234, 237, 0.5);
    border: none; }
  @media (max-width: 1199.98px) {
    .login-page .pricing_table_3 .table-responsive-shadow {
      position: relative; }
      .login-page .pricing_table_3 .table-responsive-shadow:after {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 40px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 78%); } }
  .login-page .pricing_table_4 .block {
    border: 2px solid rgba(255, 255, 255, 0.2); }
  .login-page .pricing_table_4 ul {
    list-style: none; }
    .login-page .pricing_table_4 ul li {
      position: relative; }
      .login-page .pricing_table_4 ul li:not(.disabled):before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\F00C";
        display: block;
        position: absolute;
        left: -25px;
        top: 0px;
        color: #18c967;
        font-size: 12px; }
  @media (max-width: 1199.98px) {
    .login-page .pricing_table_4 .block {
      padding-left: 50px;
      padding-right: 30px; } }
  .login-page .pricing_table_5 .block:not(.bg-dark) {
    border: 2px solid #E7EAED; }
  .login-page .pricing_table_5 .price span.absolute {
    top: 0px;
    left: -15px; }
  .login-page .pricing_table_5 ul {
    list-style: none; }
    .login-page .pricing_table_5 ul li {
      position: relative; }
      .login-page .pricing_table_5 ul li:not(.disabled):before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\F00C";
        display: block;
        position: absolute;
        left: -25px;
        top: 0px;
        color: #0074f1;
        font-size: 12px; }
  .login-page .pricing_table_6 .custom-toggle:hover, .login-page .pricing_table_6 .custom-toggle:focus {
    background: #005bbe; }
  .login-page .pricing_table_6 .custom-toggle div {
    left: 3px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 24px;
    height: 24px; }
  .login-page .pricing_table_6 .img {
    margin-left: -2px;
    margin-right: -2px;
    width: auto;
    min-width: calc(100% + 4px);
    max-width: calc(100% + 4px); }
  .login-page .pricing_table_6 .block {
    border: 2px solid #E7EAED;
    border-top: none; }
  @media (max-width: 575.98px) {
    .login-page .pricing_table_6 .block .inner {
      padding: 0 30px; } }
  .login-page .pricing_table_7 .inner {
    border: 2px solid #E7EAED; }
  @media (max-width: 575.98px) {
    .login-page .pricing_table_7 h2 {
      margin-bottom: 10px; } }
  .login-page .contact_1 textarea.input {
    height: 170px; }
  .login-page .contact_1 .separate {
    position: relative; }
    .login-page .contact_1 .separate:before, .login-page .contact_1 .separate:after {
      content: " ";
      display: block;
      position: absolute;
      width: calc(100% / 2 - 60px);
      left: 0;
      top: 50%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
    .login-page .contact_1 .separate:after {
      left: auto;
      right: 0; }
  .login-page .contact_2 textarea.input {
    height: 170px; }
  .login-page .contact_3 textarea.input {
    height: 150px; }
  .login-page .contact_4 textarea.input {
    height: 110px; }
  @media (max-width: 469.98px) {
    .login-page .contact_4 form {
      padding: 40px 20px; } }
  .login-page .contact_5 textarea.input {
    height: 170px; }
  .login-page .contact_6 form {
    background: rgba(231, 234, 237, 0.3); }
  .login-page .contact_6 textarea.input {
    height: 170px; }
  @media (max-width: 1199.98px) {
    .login-page .contact_6 .input_block .input {
      width: 240px; } }
  @media (max-width: 767.98px) {
    .login-page .contact_6 .input_block .input {
      width: 300px; } }
  @media (max-width: 575.98px) {
    .login-page .contact_6 .input_block .input {
      width: 100%; } }
  @media (max-width: 469.98px) {
    .login-page .contact_6 form {
      padding: 40px 20px; } }
  .login-page .contact_7 textarea.input {
    height: 170px; }
  @media (max-width: 991.98px) {
    .login-page .contact_7 .input_block .input {
      width: 100%; } }
  .login-page .contact_8 .js-google-map {
    right: calc((-100vw + 1200px) / 2);
    left: 15px;
    top: 0; }
  @media (max-width: 1199.98px) {
    .login-page .contact_8 .js-google-map {
      right: calc((-100vw + 970px) / 2); }
    .login-page .contact_8 .text_big {
      font-size: 32px;
      line-height: 42px; } }
  @media (max-width: 991.98px) {
    .login-page .contact_8 .js-google-map {
      right: calc((-100vw + 750px) / 2); }
    .login-page .contact_8 .text_big {
      font-size: 26px;
      line-height: 36px; }
    .login-page .contact_8 .title {
      margin-bottom: 0; } }
  @media (max-width: 767.98px) {
    .login-page .contact_8 {
      padding-bottom: 80px; }
      .login-page .contact_8 .left_side {
        padding-top: 80px;
        padding-bottom: 40px; }
      .login-page .contact_8 .js-google-map {
        position: relative;
        left: auto;
        right: auto;
        height: 60vh; }
      .login-page .contact_8 .text-adaptive {
        margin: 10px 0 40px 0; } }
  @media (max-width: 575.98px) {
    .login-page .contact_8 {
      padding-bottom: 0; }
      .login-page .contact_8 .text_big {
        font-size: 22px;
        line-height: 32px; }
      .login-page .contact_8 .js-google-map {
        left: -15px;
        width: calc(100% + 30px); } }
  .login-page .contact_9 .map_overlay,
  .login-page .contact_9 .js-google-map {
    top: 0; }
  .login-page .contact_9 .map_overlay {
    background: rgba(53, 56, 61, 0.85); }
  @media (max-width: 1199.98px) {
    .login-page .contact_9 .text_big {
      font-size: 32px;
      line-height: 42px; } }
  @media (max-width: 991.98px) {
    .login-page .contact_9 .text_big {
      font-size: 26px;
      line-height: 36px; } }
  @media (max-width: 767.98px) {
    .login-page .contact_9 .text-adaptive {
      margin: 10px 0 40px 0; }
    .login-page .contact_9 .text_big {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 40px; } }
  @media (max-width: 991.98px) {
    .login-page .contact_10[class^="pb-"], .login-page .contact_10[class*=" pb-"] {
      padding-bottom: 60px; }
    .login-page .contact_10 h2 {
      margin: 40px 0 60px 0; } }
  @media (max-width: 575.98px) {
    .login-page .contact_10 h2 a {
      font-size: 75%;
      margin-top: 0; }
    .login-page .contact_10 .socials a {
      margin: 0 15px 20px 15px; } }
  .login-page .showcase_1 .block a:hover {
    opacity: 0.8; }
  @media (max-width: 991.98px) {
    .login-page .showcase_1 .description {
      margin: 10px 0 0 0; } }
  .login-page .showcase_2 a:hover {
    opacity: 0.8; }
  .login-page .showcase_3 .block {
    border: 2px solid #E7EAED; }
  .login-page .showcase_3 img {
    vertical-align: bottom;
    position: relative;
    top: 2px; }
  .login-page .showcase_3 a:hover {
    opacity: 0.8; }
  @media (max-width: 575.98px) {
    .login-page .showcase_3 .block .text-adaptive {
      margin: 10px 0 20px 0; } }
  .login-page .showcase_4 .link:hover {
    -webkit-filter: brightness(1.1);
            filter: brightness(1.1); }
  .login-page .showcase_6 img {
    transition: all 0.2s; }
  .login-page .showcase_6 a:hover img {
    opacity: 0.7; }
  .login-page .showcase_8 a:hover {
    -webkit-filter: brightness(1.15);
            filter: brightness(1.15); }
  .login-page .showcase_9 .block {
    border: 2px solid #E7EAED; }
  @media (max-width: 359.98px) {
    .login-page .showcase_9 .block {
      padding-left: 20px;
      padding-right: 20px; } }
  .login-page .showcase_10 .with_border {
    border: 2px solid #E7EAED; }
  @media (max-width: 575.98px) {
    .login-page .footer_1 .links a {
      margin: 0 10px; } }
  @media (max-width: 991.98px) {
    .login-page .footer_3 .description {
      margin: 10px 0; }
    .login-page .footer_3 .socials a {
      margin: 0 10px; }
    .login-page .footer_3 .title {
      margin-bottom: 15px; } }
  @media (max-width: 1199.98px) {
    .login-page .footer_5 .hr {
      margin-top: 30px; }
    .login-page .footer_5 .socials {
      margin-top: 20px; }
    .login-page .footer_5 .logo,
    .login-page .footer_5 .title {
      margin-bottom: 15px; } }
  @media (max-width: 991.98px) {
    .login-page .footer_6 .hr {
      margin: 0 0 50px 0; }
    .login-page .footer_6 .title {
      margin-bottom: 15px; }
    .login-page .footer_6 .links a {
      margin-bottom: 10px; } }
  @media (max-width: 575.98px) {
    .login-page .footer_7 .link {
      margin: 0 10px; } }
  @media (max-width: 1199.98px) {
    .login-page .footer_8 a {
      margin: 0 10px; } }
  @media (max-width: 1199.98px) {
    .login-page .footer_9 form {
      margin-bottom: 40px; }
    .login-page .footer_9 .title {
      margin-bottom: 15px; }
    .login-page .footer_9 .links a {
      margin-bottom: 10px; } }
  @media (max-width: 991.98px) {
    .login-page .footer_10 .socials {
      margin-top: 15px; }
    .login-page .footer_10 .logo,
    .login-page .footer_10 .title {
      margin-bottom: 15px; } }
  @media (max-width: 991.98px) {
    .login-page .footer_11 a {
      margin: 0 10px; } }
  @media (max-width: 991.98px) {
    .login-page .footer_12 {
      padding-top: 50px; }
      .login-page .footer_12 .text-adaptive {
        margin-top: 30px; } }
  @media (max-width: 991.98px) {
    .login-page .footer_13 a {
      margin: 0 10px; } }
  .login-page .ecommerce_1 .product .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_1 .product .img_link:hover img {
    opacity: 0.8; }
  .login-page .ecommerce_1 .product .btn {
    left: 0;
    bottom: 0; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_1 .main_info .btn {
      margin-top: 30px; } }
  .login-page .ecommerce_2 .product {
    border: 2px solid #E7EAED; }
    .login-page .ecommerce_2 .product.big {
      min-height: calc(100% - 30px); }
      .login-page .ecommerce_2 .product.big ul {
        -webkit-column-count: 2;
                column-count: 2; }
    .login-page .ecommerce_2 .product li {
      list-style: none;
      position: relative; }
      .login-page .ecommerce_2 .product li:before {
        content: "";
        display: block;
        position: absolute;
        top: 11px;
        left: -10px;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background: #1E3060; }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_2 .product {
      max-width: 290px;
      padding: 20px 20px 30px 20px; }
      .login-page .ecommerce_2 .product.big ul {
        -webkit-column-count: 1;
                column-count: 1; }
      .login-page .ecommerce_2 .product .left {
        margin-right: 0; }
      .login-page .ecommerce_2 .product img {
        width: 100%;
        max-width: 100%; } }
  .login-page .ecommerce_3 .product .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_3 .product .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_3 h2 {
      margin-bottom: 10px; }
    .login-page .ecommerce_3 .products {
      margin-top: 0; }
      .login-page .ecommerce_3 .products .text-adaptive {
        max-width: 100%; } }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_3 .product {
      max-width: 290px; } }
  .login-page .ecommerce_4 .dropdown-menu {
    box-shadow: 0px 15px 40px rgba(114, 114, 114, 0.3); }
  .login-page .ecommerce_4 .link_holder {
    transition: all 0.25s; }
    .login-page .ecommerce_4 .link_holder:hover {
      background: #E7EAED; }
  .login-page .ecommerce_4 .product .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_4 .product .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_4 h2 {
      margin-bottom: 10px; }
    .login-page .ecommerce_4 .dropdown {
      margin-left: 10px;
      margin-right: 10px; } }
  .login-page .ecommerce_5 .dropdown-menu {
    box-shadow: 0px 15px 40px rgba(114, 114, 114, 0.3); }
  .login-page .ecommerce_5 .link_holder {
    transition: all 0.25s; }
    .login-page .ecommerce_5 .link_holder:hover {
      background: #E7EAED; }
  .login-page .ecommerce_5 .product .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_5 .product .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_5 .categories {
      margin-top: 10px; }
    .login-page .ecommerce_5 .category {
      margin-left: 10px;
      margin-right: 10px; }
    .login-page .ecommerce_5 .product {
      margin-bottom: 0; } }
  .login-page .ecommerce_6 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_6 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_6 {
      padding-top: 80px; }
      .login-page .ecommerce_6 .bg {
        max-width: 100%;
        height: auto; }
      .login-page .ecommerce_6 .inner {
        padding-top: 40px;
        padding-bottom: 80px; }
      .login-page .ecommerce_6 .buttons {
        margin-top: 30px; } }
  .login-page .ecommerce_7 .categories a {
    border-bottom: 2px solid transparent; }
    .login-page .ecommerce_7 .categories a:hover {
      border-color: #3657ae; }
  .login-page .ecommerce_7 .categories .active {
    color: #1E3060;
    border-color: #1E3060; }
    .login-page .ecommerce_7 .categories .active:hover {
      border-color: #1E3060; }
  .login-page .ecommerce_7 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_7 .img_link:hover img {
    opacity: 0.8; }
  .login-page .ecommerce_7 input[type="radio"]:checked + label:before {
    content: "\F00C";
    color: #FFFFFF; }
  .login-page .ecommerce_7 input[type="radio"] + label:before {
    border: none;
    line-height: 20px;
    color: #FFFFFF; }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_7 .categories {
      margin-top: 20px; }
      .login-page .ecommerce_7 .categories a {
        margin: 5px 8px 0 8px;
        font-size: 16px;
        line-height: 26px; } }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_7 .product {
      max-width: 290px; } }
  .login-page .ecommerce_8 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_8 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_8 .product {
      max-width: 290px; } }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_9 {
      padding-top: 80px; }
      .login-page .ecommerce_9 .bg {
        max-width: 100%;
        height: auto; }
      .login-page .ecommerce_9 .inner {
        padding-top: 40px;
        padding-bottom: 80px; }
      .login-page .ecommerce_9 .prices {
        margin-top: 40px; } }
  .login-page .ecommerce_10 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_10 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_10 .inner {
      padding: 0; }
    .login-page .ecommerce_10 .title {
      font-size: 18px;
      line-height: 28px; } }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_10 .product {
      margin-bottom: 0;
      max-width: 370px; } }
  .login-page .ecommerce_11 .slick-arrow {
    margin-top: 0;
    top: -90px;
    width: 50px;
    height: 50px; }
    .login-page .ecommerce_11 .slick-arrow:before {
      width: 50px;
      height: 50px;
      border: 2px solid #E7EAED;
      border-radius: 100%;
      transition: all 0.25s; }
    .login-page .ecommerce_11 .slick-arrow:hover:before {
      opacity: 1;
      background-color: #FFFFFF; }
  .login-page .ecommerce_11 .slick-prev {
    left: auto;
    right: 75px; }
    .login-page .ecommerce_11 .slick-prev:before {
      background-size: 7px auto; }
  .login-page .ecommerce_11 .slick-next {
    right: 15px; }
    .login-page .ecommerce_11 .slick-next:before {
      background-size: 7px auto; }
  .login-page .ecommerce_11 .holder:hover .inner {
    opacity: 1; }
  .login-page .ecommerce_11 .inner {
    top: 0;
    left: 0;
    background: rgba(0, 116, 241, 0.7);
    transition: all 0.25s; }
    .login-page .ecommerce_11 .inner .btn {
      min-width: 145px; }
  .login-page .ecommerce_11 input[type="radio"]:checked + label {
    background: #FFFFFF;
    color: #1e3060; }
  .login-page .ecommerce_11 input[type="radio"] + label {
    height: 50px;
    line-height: 46px;
    border: 2px solid #E7EAED;
    transition: all 0.25s; }
    .login-page .ecommerce_11 input[type="radio"] + label:before {
      display: none; }
    .login-page .ecommerce_11 input[type="radio"] + label:hover {
      color: #1e3060; }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_11 .header_holder {
      padding-right: 0; }
    .login-page .ecommerce_11 .slick-arrow {
      top: 10px; } }
  .login-page .ecommerce_12 .product {
    border: 2px solid #E7EAED; }
    .login-page .ecommerce_12 .product li {
      list-style: none;
      position: relative; }
      .login-page .ecommerce_12 .product li:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background: #1E3060; }
    .login-page .ecommerce_12 .product .btn {
      left: 50%;
      bottom: 30px;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
  .login-page .ecommerce_12 .img_link img {
    margin: -2px -2px 0 -2px;
    max-width: calc(100% + 4px);
    min-width: calc(100% + 4px);
    transition: all 0.25s; }
  .login-page .ecommerce_12 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_12 .product {
      max-width: 370px;
      padding-bottom: 30px; }
      .login-page .ecommerce_12 .product .btn {
        position: static;
        -webkit-transform: none;
                transform: none; }
    .login-page .ecommerce_12 .title {
      font-size: 18px;
      line-height: 28px; } }
  .login-page .ecommerce_13 .category {
    border: 2px solid #E7EAED; }
    .login-page .ecommerce_13 .category .btn {
      left: 50%;
      bottom: 30px;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
  .login-page .ecommerce_13 .img_link img {
    margin: -2px -2px 0 -2px;
    max-width: calc(100% + 4px);
    min-width: calc(100% + 4px);
    transition: all 0.25s; }
  .login-page .ecommerce_13 .img_link:hover img {
    opacity: 0.8; }
  .login-page .ecommerce_14 .product {
    overflow: hidden; }
  .login-page .ecommerce_14 .img_link img {
    min-height: 100%;
    transition: all 0.25s; }
  .login-page .ecommerce_14 .img_link:hover img {
    opacity: 0.8; }
  .login-page .ecommerce_14 .stars .active {
    color: #f2c94c; }
  .login-page .ecommerce_14 .stars .absolute {
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_14 .img_link img {
      width: 100%;
      min-height: 0; } }
  .login-page .ecommerce_15 .slider_menu a img {
    transition: all 0.25s; }
  .login-page .ecommerce_15 .slider_menu a:hover img {
    opacity: 0.8; }
  .login-page .ecommerce_15 .stars .active {
    color: #f2c94c; }
  .login-page .ecommerce_15 .stars .absolute {
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_15 h2 {
      margin-top: 0px;
      margin-bottom: 5px; }
    .login-page .ecommerce_15 .btn {
      margin-top: 50px; } }
  .login-page .ecommerce_16 .dropdown-menu {
    box-shadow: 0px 15px 40px rgba(114, 114, 114, 0.3); }
  .login-page .ecommerce_16 .link_holder {
    transition: all 0.25s; }
    .login-page .ecommerce_16 .link_holder:hover {
      background: #E7EAED; }
  .login-page .ecommerce_16 input[type="radio"]:checked + label:before {
    content: "\F00C";
    color: #FFFFFF; }
  .login-page .ecommerce_16 input[type="radio"] + label:before {
    border: none;
    line-height: 20px;
    color: #FFFFFF; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_16 .inner {
      margin-left: 0;
      width: 390px; } }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_16 .categories {
      margin-top: 10px; }
    .login-page .ecommerce_16 .category {
      margin-left: 10px;
      margin-right: 10px; } }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_16 .product {
      max-width: 370px; }
      .login-page .ecommerce_16 .product .img {
        width: 100%; }
    .login-page .ecommerce_16 .inner {
      max-width: 100%; }
    .login-page .ecommerce_16 .price {
      margin-left: 0; } }
  .login-page .ecommerce_17 .holder:hover .inner {
    opacity: 1; }
  .login-page .ecommerce_17 .inner {
    top: 0;
    left: 0;
    background: rgba(0, 116, 241, 0.7);
    transition: all 0.25s; }
  .login-page .ecommerce_18 .dropdown-menu {
    box-shadow: 0px 15px 40px rgba(114, 114, 114, 0.3); }
  .login-page .ecommerce_18 .link_holder {
    transition: all 0.25s; }
    .login-page .ecommerce_18 .link_holder:hover {
      background: #E7EAED; }
  .login-page .ecommerce_18 .img_link img {
    min-height: 100%;
    transition: all 0.25s; }
  .login-page .ecommerce_18 .img_link:hover img {
    opacity: 0.8; }
  .login-page .ecommerce_18 .buttons {
    left: 0;
    bottom: 10px; }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_18 .inner {
      margin-left: 0;
      padding-bottom: 0; }
    .login-page .ecommerce_18 .buttons {
      position: static; } }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_18 .product {
      max-width: 370px; }
      .login-page .ecommerce_18 .product .img_link img {
        width: 100%; } }
  .login-page .ecommerce_19 .product {
    border: 2px solid #E7EAED; }
    .login-page .ecommerce_19 .product.big {
      min-height: calc(100% - 30px); }
    .login-page .ecommerce_19 .product .img,
    .login-page .ecommerce_19 .product .slider {
      margin-top: -2px;
      margin-right: -2px;
      margin-bottom: -2px; }
    .login-page .ecommerce_19 .product .slider {
      overflow: hidden; }
      .login-page .ecommerce_19 .product .slider .slick-list,
      .login-page .ecommerce_19 .product .slider .slick-track,
      .login-page .ecommerce_19 .product .slider .slick-slide {
        height: 100%; }
    .login-page .ecommerce_19 .product .slider_menu {
      max-width: 100%; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_19 .product.small {
      width: calc(50% - 15px); }
      .login-page .ecommerce_19 .product.small .inner {
        padding-left: 20px;
        padding-right: 20px; }
    .login-page .ecommerce_19 .product.big .inner {
      padding-left: 30px; } }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_19 .product.small {
      width: 100%; }
    .login-page .ecommerce_19 .product .slider {
      margin: -2px;
      width: auto;
      border-radius: 10px 10px 0 0; }
      .login-page .ecommerce_19 .product .slider img {
        width: 100%; } }
  @media (max-width: 369.98px) {
    .login-page .ecommerce_19 .product.big .inner {
      padding: 20px; }
    .login-page .ecommerce_19 .product.big .title {
      font-size: 18px;
      line-height: 28px; }
    .login-page .ecommerce_19 .product.big .btn {
      width: 100%; }
    .login-page .ecommerce_19 .product.big .slider_menu img {
      margin: 0 auto; }
    .login-page .ecommerce_19 .product.small {
      display: block !important; }
      .login-page .ecommerce_19 .product.small .img {
        margin: -2px;
        width: calc(100% + 4px);
        max-width: calc(100% + 4px);
        border-radius: 10px 10px 0 0; } }
  .login-page .ecommerce_20 .category {
    transition: all 0.25s; }
    .login-page .ecommerce_20 .category:hover {
      background: #E7EAED; }
  .login-page .ecommerce_20 .product .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_20 .product .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_20 h2 {
      margin-bottom: 20px; } }
  @media (max-width: 359.98px) {
    .login-page .ecommerce_20 .title {
      font-size: 22px;
      line-height: 32px; } }
  .login-page .ecommerce_21 .category {
    border: 2px solid #E7EAED;
    transition: all 0.25s; }
    .login-page .ecommerce_21 .category:hover {
      border-color: #0074f1; }
  .login-page .ecommerce_21 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_21 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_21 .inner {
      padding-left: 0;
      padding-right: 0; } }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_21 .category {
      max-width: 320px; } }
  .login-page .ecommerce_22 .dropdown-menu {
    box-shadow: 0px 15px 40px rgba(114, 114, 114, 0.3); }
  .login-page .ecommerce_22 .link_holder {
    transition: all 0.25s; }
    .login-page .ecommerce_22 .link_holder:hover {
      background: #E7EAED; }
  .login-page .ecommerce_22 .category {
    transition: all 0.25s; }
    .login-page .ecommerce_22 .category:hover {
      background: #E7EAED; }
  .login-page .ecommerce_22 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_22 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_22[class^="pb-"], .login-page .ecommerce_22[class*=" pb-"] {
      padding-bottom: 30px; }
    .login-page .ecommerce_22 .btn {
      margin-right: 0; } }
  .login-page .ecommerce_23 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_23 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_23 .buttons {
      margin-top: 50px; } }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_23 {
      padding-top: 80px; }
      .login-page .ecommerce_23 .bg {
        max-width: 100%;
        height: auto;
        border-radius: 10px; }
      .login-page .ecommerce_23 .inner {
        padding-top: 40px;
        padding-bottom: 80px; }
      .login-page .ecommerce_23 .buttons {
        margin-top: 30px; } }
  .login-page .ecommerce_24 .bg {
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .login-page .ecommerce_24 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_24 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_24 {
      padding: 80px 0; }
      .login-page .ecommerce_24 .inner {
        padding-top: 0;
        padding-bottom: 0; }
      .login-page .ecommerce_24 .title_price {
        width: 100%; } }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_24 .product {
      max-width: 370px; }
      .login-page .ecommerce_24 .product img {
        width: 100%; }
    .login-page .ecommerce_24 .inner {
      margin-left: 0; } }
  .login-page .ecommerce_26 .product:hover .inner {
    opacity: 1; }
  .login-page .ecommerce_26 .inner {
    top: 0;
    left: 0;
    background: rgba(0, 116, 241, 0.7);
    transition: all 0.25s;
    overflow: auto; }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_26 .main_btn {
      margin-top: 25px; } }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_28 .btn {
      margin-top: 40px; } }
  .login-page .ecommerce_29 .inner {
    border: 2px solid #E7EAED; }
  .login-page .ecommerce_29 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_29 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_29 .socials {
      margin-top: 20px; } }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_29 .inner {
      padding: 30px; }
    .login-page .ecommerce_29 .images {
      padding: 0; }
      .login-page .ecommerce_29 .images .img_link {
        width: calc(100% / 2 - 30px); }
      .login-page .ecommerce_29 .images img {
        width: 100%; } }
  .login-page .ecommerce_30 .holder {
    border: 2px solid #E7EAED; }
  .login-page .ecommerce_30 .bg {
    top: -2px;
    right: -2px;
    bottom: -2px;
    background-position: 50% 50%;
    background-size: cover; }
  .login-page .ecommerce_30 .product.big .bg {
    width: 300px;
    border-radius: 0 10px 10px 0; }
  .login-page .ecommerce_30 .product.big .inner {
    margin-right: 320px; }
  .login-page .ecommerce_30 .product.small .bg {
    left: -2px;
    border-radius: 10px; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_30 .inner {
      padding-bottom: 50px; }
    .login-page .ecommerce_30 .product.big .bg {
      width: auto;
      left: -2px;
      border-radius: 10px; }
    .login-page .ecommerce_30 .product.big .inner {
      margin-right: 0; }
    .login-page .ecommerce_30 .product.big .subtitle,
    .login-page .ecommerce_30 .product.big h2 {
      color: #FFFFFF; } }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_30 .inner {
      padding: 50px 30px; } }
  @media (max-width: 469.98px) {
    .login-page .ecommerce_30 .inner {
      padding: 40px 20px; } }
  .login-page .ecommerce_31 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_31 .img_link:hover img {
    opacity: 0.8; }
  .login-page .ecommerce_31 .btn_play {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .login-page .ecommerce_31 input[type="radio"]:checked + label:before {
    content: "\F00C";
    color: #FFFFFF;
    text-align: center; }
  .login-page .ecommerce_31 input[type="radio"] + label:before {
    border: none;
    width: 22px;
    height: 22px;
    line-height: 22px;
    color: #FFFFFF; }
  .login-page .ecommerce_31 input[type="radio"] + label {
    margin: 0 2px;
    width: 22px;
    height: 22px; }
  .login-page .ecommerce_31 .colors_border {
    border: 2px solid #E7EAED;
    padding: 0 12px; }
  .login-page .ecommerce_31 .quantity_selector {
    border: 2px solid #E7EAED;
    overflow: hidden; }
    .login-page .ecommerce_31 .quantity_selector .control {
      width: 36px;
      line-height: 36px;
      background: transparent; }
      .login-page .ecommerce_31 .quantity_selector .control:hover {
        background: #E7EAED; }
      .login-page .ecommerce_31 .quantity_selector .control::-moz-selection {
        background: transparent;
        color: #1e3060; }
      .login-page .ecommerce_31 .quantity_selector .control::-webkit-selection {
        background: transparent;
        color: #1e3060; }
      .login-page .ecommerce_31 .quantity_selector .control::selection {
        background: transparent;
        color: #1e3060; }
    .login-page .ecommerce_31 .quantity_selector .input {
      width: 40px;
      height: 36px;
      border-left: 2px solid #E7EAED;
      border-right: 2px solid #E7EAED;
      color: #1e3060;
      font-weight: 500;
      font-size: 16px;
      text-align: center; }
  .login-page .ecommerce_31 .advantages li {
    list-style: none;
    position: relative; }
    .login-page .ecommerce_31 .advantages li:before {
      content: "";
      display: block;
      position: absolute;
      top: 11px;
      left: -12px;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background: #1E3060; }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_31 .img_link {
      margin-right: 5px; } }
  .login-page .ecommerce_32 .slider_menu .slick-arrow {
    margin-top: -25px;
    width: 50px;
    height: 50px;
    background: #E7EAED;
    border-radius: 100%; }
    .login-page .ecommerce_32 .slider_menu .slick-arrow:before {
      width: 100%;
      height: 100%;
      background-size: 7px auto;
      background-position: 50% 50%; }
    .login-page .ecommerce_32 .slider_menu .slick-arrow:hover {
      background-color: #cad1d7; }
      .login-page .ecommerce_32 .slider_menu .slick-arrow:hover:before {
        opacity: 1; }
  .login-page .ecommerce_32 .slider_menu img {
    cursor: pointer;
    transition: all 0.25s; }
    .login-page .ecommerce_32 .slider_menu img:hover {
      opacity: 0.8; }
  .login-page .ecommerce_32 input[type="radio"]:checked + label:before {
    content: "\F00C";
    color: #FFFFFF;
    text-align: center; }
  .login-page .ecommerce_32 input[type="radio"] + label:before {
    border: none;
    width: 22px;
    height: 22px;
    line-height: 22px;
    color: #FFFFFF; }
  .login-page .ecommerce_32 input[type="radio"] + label {
    margin: 0 2px;
    width: 22px;
    height: 22px; }
  .login-page .ecommerce_32 .colors_border {
    border: 2px solid #E7EAED;
    padding: 0 12px; }
  .login-page .ecommerce_32 .quantity_selector {
    border: 2px solid #E7EAED;
    overflow: hidden; }
    .login-page .ecommerce_32 .quantity_selector .control {
      width: 36px;
      line-height: 36px;
      background: transparent; }
      .login-page .ecommerce_32 .quantity_selector .control:hover {
        background: #E7EAED; }
      .login-page .ecommerce_32 .quantity_selector .control::-moz-selection {
        background: transparent;
        color: #1e3060; }
      .login-page .ecommerce_32 .quantity_selector .control::-webkit-selection {
        background: transparent;
        color: #1e3060; }
      .login-page .ecommerce_32 .quantity_selector .control::selection {
        background: transparent;
        color: #1e3060; }
    .login-page .ecommerce_32 .quantity_selector .input {
      width: 40px;
      height: 36px;
      border-left: 2px solid #E7EAED;
      border-right: 2px solid #E7EAED;
      color: #1e3060;
      font-size: 16px;
      font-weight: 500;
      text-align: center; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_32 .inner {
      margin-left: 0; }
    .login-page .ecommerce_32 .top_form {
      margin-top: 10px; }
    .login-page .ecommerce_32 .buttons {
      margin-top: 30px; }
    .login-page .ecommerce_32 .bottom_form {
      margin-top: 30px; }
      .login-page .ecommerce_32 .bottom_form .text-adaptive {
        max-width: 370px; } }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_32 .text-adaptive {
      margin-left: auto;
      margin-right: auto; } }
  @media (max-width: 369.98px) {
    .login-page .ecommerce_32 .quantity,
    .login-page .ecommerce_32 .colors,
    .login-page .ecommerce_32 .buttons a,
    .login-page .ecommerce_32 .buttons button {
      margin-left: 10px;
      margin-right: 10px; } }
  .login-page .ecommerce_33 .remove_product {
    width: 45px;
    height: 45px;
    top: 30px;
    right: 0;
    cursor: pointer;
    z-index: 10; }
    .login-page .ecommerce_33 .remove_product:hover svg path {
      stroke: #1e3060; }
    .login-page .ecommerce_33 .remove_product svg path {
      stroke: #1E3060;
      transition: all 0.25s; }
  .login-page .ecommerce_33 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_33 .img_link:hover img {
    opacity: 0.8; }
  .login-page .ecommerce_33 .colors_border {
    border: 2px solid #E7EAED;
    padding: 0 8px; }
    .login-page .ecommerce_33 .colors_border input[type="radio"]:checked + label:before {
      content: "\F00C";
      color: #FFFFFF;
      text-align: center; }
    .login-page .ecommerce_33 .colors_border input[type="radio"] + label:before {
      border: none;
      width: 22px;
      height: 22px;
      line-height: 22px;
      color: #FFFFFF; }
    .login-page .ecommerce_33 .colors_border input[type="radio"] + label {
      margin: 0 2px;
      width: 22px;
      height: 22px; }
  .login-page .ecommerce_33 .sizes input[type="radio"]:checked + label {
    background: #1e3060;
    color: #FFFFFF;
    border-color: #1e3060; }
  .login-page .ecommerce_33 .sizes input[type="radio"] + label:before {
    display: none; }
  .login-page .ecommerce_33 .sizes input[type="radio"] + label {
    width: auto;
    height: 40px;
    line-height: 36px; }
  .login-page .ecommerce_33 .sizes label {
    padding: 0 12px;
    border: 2px solid #E7EAED;
    border-right: none; }
    .login-page .ecommerce_33 .sizes label:first-of-type {
      border-radius: 20px 0 0 20px; }
    .login-page .ecommerce_33 .sizes label:last-of-type {
      border-radius: 0 20px 20px 0;
      border-right: 2px solid #E7EAED; }
    .login-page .ecommerce_33 .sizes label:hover {
      color: #1e3060; }
  .login-page .ecommerce_33 .quantity_selector {
    border: 2px solid #E7EAED;
    overflow: hidden; }
    .login-page .ecommerce_33 .quantity_selector .control {
      width: 36px;
      line-height: 36px;
      background: transparent; }
      .login-page .ecommerce_33 .quantity_selector .control:hover {
        background: #E7EAED; }
      .login-page .ecommerce_33 .quantity_selector .control::-moz-selection {
        background: transparent;
        color: #1e3060; }
      .login-page .ecommerce_33 .quantity_selector .control::-webkit-selection {
        background: transparent;
        color: #1e3060; }
      .login-page .ecommerce_33 .quantity_selector .control::selection {
        background: transparent;
        color: #1e3060; }
    .login-page .ecommerce_33 .quantity_selector .input {
      width: 40px;
      height: 36px;
      border-left: 2px solid #E7EAED;
      border-right: 2px solid #E7EAED;
      color: #1e3060;
      font-weight: 500;
      font-size: 16px;
      text-align: center; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_33 .product .inner {
      margin-left: 15px; }
    .login-page .ecommerce_33 .label_total {
      margin-left: 23px; } }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_33 .label_total {
      margin-left: 0; } }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_33 .product_total {
      height: auto; } }
  @media (max-width: 481.98px) {
    .login-page .ecommerce_33 .product .inner {
      margin-top: 20px;
      margin-left: 0;
      max-width: 100%;
      width: 100%; } }
  .login-page .ecommerce_34 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_34 .img_link:hover img {
    opacity: 0.8; }
  .login-page .ecommerce_34 .quantity_selector {
    border: 2px solid #E7EAED;
    overflow: hidden; }
    .login-page .ecommerce_34 .quantity_selector .control {
      width: 36px;
      line-height: 36px;
      background: transparent; }
      .login-page .ecommerce_34 .quantity_selector .control:hover {
        background: #E7EAED; }
      .login-page .ecommerce_34 .quantity_selector .control::-moz-selection {
        background: transparent;
        color: #1e3060; }
      .login-page .ecommerce_34 .quantity_selector .control::-webkit-selection {
        background: transparent;
        color: #1e3060; }
      .login-page .ecommerce_34 .quantity_selector .control::selection {
        background: transparent;
        color: #1e3060; }
    .login-page .ecommerce_34 .quantity_selector .input {
      width: 40px;
      height: 36px;
      border-left: 2px solid #E7EAED;
      border-right: 2px solid #E7EAED;
      color: #1e3060;
      font-weight: 500;
      font-size: 16px;
      text-align: center; }
  .login-page .ecommerce_34 .total,
  .login-page .ecommerce_34 .subtotal,
  .login-page .ecommerce_34 .delivery {
    min-width: 115px; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_34 .address_form {
      padding-right: 40px; } }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_34 .address_form {
      padding-top: 20px;
      padding-right: 0px; }
      .login-page .ecommerce_34 .address_form .block {
        margin-bottom: 15px; } }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_34 .total,
    .login-page .ecommerce_34 .subtotal,
    .login-page .ecommerce_34 .delivery {
      min-width: 50px; } }
  .login-page .ecommerce_35 .slider_menu .slick-track {
    position: relative;
    border-bottom: 3px solid #E7EAED; }
  .login-page .ecommerce_35 .slider_menu a {
    width: 50%; }
    .login-page .ecommerce_35 .slider_menu a:after {
      content: "";
      display: block;
      width: 0%;
      height: 3px;
      background: #1e3060;
      position: absolute;
      right: 0;
      bottom: -3px;
      transition: width 0.2s;
      z-index: 10000; }
    .login-page .ecommerce_35 .slider_menu a:last-child:after {
      left: 0;
      right: auto; }
    .login-page .ecommerce_35 .slider_menu a.slick-current {
      color: #1e3060; }
      .login-page .ecommerce_35 .slider_menu a.slick-current:after {
        width: 100%; }
  .login-page .ecommerce_35 .img_link img {
    transition: all 0.25s; }
  .login-page .ecommerce_35 .img_link:hover img {
    opacity: 0.8; }
  .login-page .ecommerce_35 .quantity_selector {
    border: 2px solid #E7EAED;
    overflow: hidden; }
    .login-page .ecommerce_35 .quantity_selector .control {
      width: 36px;
      line-height: 36px;
      background: transparent; }
      .login-page .ecommerce_35 .quantity_selector .control:hover {
        background: #E7EAED; }
      .login-page .ecommerce_35 .quantity_selector .control::-moz-selection {
        background: transparent;
        color: #1e3060; }
      .login-page .ecommerce_35 .quantity_selector .control::-webkit-selection {
        background: transparent;
        color: #1e3060; }
      .login-page .ecommerce_35 .quantity_selector .control::selection {
        background: transparent;
        color: #1e3060; }
    .login-page .ecommerce_35 .quantity_selector .input {
      width: 40px;
      height: 36px;
      border-left: 2px solid #E7EAED;
      border-right: 2px solid #E7EAED;
      color: #1e3060;
      font-weight: 500;
      font-size: 16px;
      text-align: center; }
  .login-page .ecommerce_35 .total {
    min-width: 115px; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_35 .slider,
    .login-page .ecommerce_35 .slider_menu {
      padding-right: 40px; } }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_35 .slider,
    .login-page .ecommerce_35 .slider_menu {
      padding-right: 0px; }
    .login-page .ecommerce_35 .products {
      padding-top: 0; } }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_35 .slider_menu a {
      line-height: 60px; }
    .login-page .ecommerce_35 .slider {
      margin-top: 40px; }
    .login-page .ecommerce_35 .total {
      min-width: 50px; } }
  .login-page .ecommerce_36 .with_border {
    border-bottom: 2px solid #E7EAED; }
  .login-page .ecommerce_36 .product .with_border {
    padding-top: 28px;
    padding-bottom: 28px; }
  .login-page .ecommerce_36 .quantity_selector .control {
    right: 20px;
    top: 50%;
    width: 16px;
    height: 12px;
    line-height: 8px;
    font-size: 12px; }
    .login-page .ecommerce_36 .quantity_selector .control.greater {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%); }
  .login-page .ecommerce_36 .remove_product {
    width: 45px;
    height: 45px;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    cursor: pointer;
    z-index: 10; }
    .login-page .ecommerce_36 .remove_product:hover svg path {
      stroke: #1e3060; }
    .login-page .ecommerce_36 .remove_product svg path {
      stroke: #1E3060;
      transition: all 0.25s; }
  .login-page .ecommerce_36 .total {
    min-width: 70px; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_36 .total {
      min-width: 65px; } }
  @media (max-width: 991.98px) {
    .login-page .ecommerce_36 .total {
      min-width: 95px; } }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_36 .product .title,
    .login-page .ecommerce_36 .product .variants {
      padding-top: 0px;
      padding-bottom: 0px; }
    .login-page .ecommerce_36 .with_border {
      border: none; }
    .login-page .ecommerce_36 .price_holder {
      position: static; }
    .login-page .ecommerce_36 .remove_product {
      top: 0;
      -webkit-transform: none;
              transform: none; }
    .login-page .ecommerce_36 .total {
      min-width: 0px; } }
  .login-page .ecommerce_37 .holder {
    border: 2px solid #E7EAED; }
  .login-page .ecommerce_37 .delivery_type input[type="radio"]:checked + label {
    background: #1e3060;
    color: #FFFFFF;
    border-color: #1e3060; }
  .login-page .ecommerce_37 .delivery_type input[type="radio"] + label:before {
    display: none; }
  .login-page .ecommerce_37 .delivery_type input[type="radio"] + label {
    line-height: 46px; }
  .login-page .ecommerce_37 .delivery_type label {
    height: 50px;
    border: 2px solid #E7EAED;
    transition: all 0.25s; }
    .login-page .ecommerce_37 .delivery_type label:hover {
      color: #1e3060; }
  @media (max-width: 399.98px) {
    .login-page .ecommerce_37 .holder {
      padding: 30px 20px; } }
  .login-page .ecommerce_38 .product {
    border-bottom: 2px solid #E7EAED; }
  .login-page .ecommerce_38 .remove_product {
    width: 45px;
    height: 45px;
    top: 8px;
    right: -45px;
    cursor: pointer;
    z-index: 10; }
    .login-page .ecommerce_38 .remove_product:hover svg path {
      stroke: #1e3060; }
    .login-page .ecommerce_38 .remove_product svg path {
      stroke: #1E3060;
      transition: all 0.25s; }
  .login-page .ecommerce_38 .total,
  .login-page .ecommerce_38 .subtotal,
  .login-page .ecommerce_38 .delivery {
    min-width: 30px; }
  @media (max-width: 1199.98px) {
    .login-page .ecommerce_38 .remove_product {
      right: -15px; } }
  @media (max-width: 767.98px) {
    .login-page .ecommerce_38 .btn {
      margin-top: 0px; } }
  @media (max-width: 575.98px) {
    .login-page .ecommerce_38 .total,
    .login-page .ecommerce_38 .subtotal,
    .login-page .ecommerce_38 .delivery {
      min-width: 50px; } }
  @media (max-width: 767.98px) {
    .login-page .application_2 .application_2_left_img {
      max-height: 50vh; } }
  .login-page .application_2 .with_borders:nth-of-type(1) {
    border-bottom: 2px solid #ebeaed;
    border-right: 2px solid #ebeaed; }
  .login-page .application_2 .with_borders:nth-of-type(2) {
    border-bottom: 2px solid #ebeaed;
    border-right: 2px solid #ebeaed; }
  .login-page .application_2 .with_borders:nth-of-type(3) {
    border-bottom: 2px solid #ebeaed; }
  .login-page .application_2 .with_borders:nth-of-type(4) {
    border-right: 2px solid #ebeaed; }
  .login-page .application_2 .with_borders:nth-of-type(5) {
    border-right: 2px solid #ebeaed; }
  @media (max-width: 991.98px) {
    .login-page .application_2 .with_borders:nth-of-type(2) {
      border-right: none; }
    .login-page .application_2 .with_borders:nth-of-type(3) {
      border-right: 2px solid #ebeaed;
      padding-top: 35px; }
    .login-page .application_2 .with_borders:nth-of-type(4) {
      border-right: none;
      border-bottom: 2px solid #ebeaed; } }
  .login-page .application_3 .bg {
    top: 0;
    right: -50px; }
  @media (max-width: 1199.98px) {
    .login-page .application_3 .inner {
      padding-top: 350px; } }
  @media (max-width: 991.98px) {
    .login-page .application_3 .inner {
      padding-top: 300px; } }
  @media (max-width: 767.98px) {
    .login-page .application_3 .inner {
      padding-top: 200px; } }
  @media (max-width: 576.98px) {
    .login-page .application_3 .inner {
      padding-top: 100px; } }
  @media (max-width: 991.98px) {
    .login-page .application_4 .application_4_left_img {
      max-height: 80vh; } }
  .login-page .application_5 .with_box_shadow {
    box-shadow: -50px 30px 70px rgba(13, 0, 0, 0.11); }
    @media (max-width: 767.98px) {
      .login-page .application_5 .with_box_shadow {
        display: none; } }
  .login-page .application_5 .left .absolute {
    bottom: 120px;
    left: -120px; }
    @media (max-width: 1199.98px) {
      .login-page .application_5 .left .absolute {
        bottom: 50px; } }
    @media (max-width: 991.98px) {
      .login-page .application_5 .left .absolute {
        bottom: 120px;
        left: -110px; } }
  .login-page .application_5 .right .absolute {
    top: 30px;
    left: -175px; }
  @media (max-width: 991.98px) {
    .login-page .application_5 h3 {
      margin-bottom: 40px; } }
  .login-page .application_11 ul {
    list-style-type: none; }
    .login-page .application_11 ul li .fas {
      font-size: 10px;
      color: #0074f1;
      padding-right: 15px; }
  @media (max-width: 767.98px) {
    .login-page .application_13 img {
      max-height: 80vh; } }
  .login-page .application_14 .bg {
    top: 0;
    left: 30px; }
  @media (max-width: 1199.98px) {
    .login-page .application_14 .application_14_inner {
      padding-bottom: 200px; } }
  @media (max-width: 991.98px) {
    .login-page .application_14 .application_14_inner {
      padding-bottom: 180px; } }
  @media (max-width: 767.98px) {
    .login-page .application_14 .application_14_inner {
      padding-bottom: 100px; } }
  @media (max-width: 576.98px) {
    .login-page .application_14 .application_14_inner {
      padding-bottom: 50px; } }
  @media (max-width: 767.98px) {
    .login-page .application_15 img {
      max-height: 80vh; } }
  .login-page .application_16 .with_border_radius_10 {
    border: 2px solid #E7EAED;
    border-radius: 10px; }
  .login-page .application_16 .border_on_right {
    border-right: 2px solid #E7EAED; }
  .login-page .application_16 .border_on_top {
    border-top: 2px solid #E7EAED; }
  .login-page .application_16 .bg {
    top: -377px;
    left: -170px; }
  @media (max-width: 991.98px) {
    .login-page .application_16 .bg {
      left: -100px; } }
  @media (max-width: 767.98px) {
    .login-page .application_16 .bg {
      display: none; } }
  .login-page .application_17 .bg {
    top: -376px;
    left: -30px; }
  @media (max-width: 991.98px) {
    .login-page .application_17 .bg {
      left: 0; } }
  @media (max-width: 767.98px) {
    .login-page .application_17 .bg {
      display: none; } }
  .login-page .application_20 #application_20_bg {
    background-position: 50% 50%;
    background-size: contain; }
  .login-page .application_22 #application_22_bg {
    background-position: 50% 50%;
    background-size: contain; }
  .login-page .application_23 .color-dependent:nth-of-type(even) .radius_full {
    background: #0074f1; }
  .login-page .application_23 .color-dependent:nth-of-type(odd) .radius_full {
    background: #0074f1; }
  .login-page .application_23 i {
    color: #000000; }
  .login-page .application_23 .bg {
    position: absolute;
    top: -230px;
    left: -340px; }
  @media (max-width: 1199.98px) {
    .login-page .application_23 .pb-dependent {
      padding-bottom: 150px; }
    .login-page .application_23 .bg {
      top: -250px;
      left: -300px; } }
  @media (max-width: 991.98px) {
    .login-page .application_23 .pb-dependent {
      padding-bottom: 100px; }
    .login-page .application_23 .bg {
      top: -320px;
      left: -250px; } }
  @media (max-width: 767.98px) {
    .login-page .application_23 .pb-dependent {
      padding-bottom: 40px; }
    .login-page .application_23 .bg {
      top: -530px;
      left: -180px; } }
  @media (max-width: 575.98px) {
    .login-page .application_23 .bg {
      display: none; } }
  .login-page .application_24 ul {
    list-style: none;
    padding-left: 0; }
    .login-page .application_24 ul li {
      display: flex;
      justify-content: flex-start;
      align-items: align-start; }
      .login-page .application_24 ul li:before {
        content: "\25CF";
        color: #1e3060;
        margin-right: 12px; }
  @media (max-width: 767.98px) {
    .login-page .application_25 .text-nowrap {
      white-space: normal !important; } }
  @media (max-width: 767.98px) {
    .login-page .application_26 img {
      max-height: 80vh; } }

@charset "UTF-8";
:not(.login-page) {
  /*
	Table of contents:
	1. Notifications
	2. Modals
*/
  /* 1. Notifications */
  /* 1. Modals */
  /* Change bootstrap's .container default width */
  /* Font styles */
  /* Opacity */
  /* z-index */
  /* Links */
  /* Buttons */
  /* inputs */
  /* Selects */
  /* Textareas */
  /* Checkboxes */
  /* Other elements */
  /* Navigation Mobile */
  /* Video block */
  /* Play button */
  /* Opening tabs */
  /* Alerts */
  /* Google ReCaptcha */
  /* Dropdown menu */
  /* Dropdown popup in Navigation */
  /* Slider stylers */
  /* Change some Bootstrap's styles */
  /* Classes for all elements */
  /* Margins and paddings */
  /* width, max-width, height, max-height */
  /* Rewrite some bootstrap's classes */
  /* Font families */
  /* Font sizes */
  /* Colors */
  /* background-colors */
  /* color filters */
  /*
picture[class^="color-filter-"], picture[class*=" color-filter-"]{
display:inline-block;
overflow:hidden;
&:before{
  z-index:1;
}
}
*/
  /* Border-radiuses */
  /* Position */
  /*
	Table of contents:
	1. Common styles
	2. Common responsive styles
	3. Headers
	4. Navigations
	5. Contents
	6. Testimonials
	7. Features
	8. Forms
	9. Call to Actions
	10. Teams
	11. Pricing Tables
	12. Contacts
	13. Showcases
	14. Footers
	15. Ecommerce
	16. Applications
*/
  /* 1. Common styles */
  /* Change AOS plugin default settings */
  /* Slick slider */
  /* Arrows */
  /* Dots */
  /* Slick slider Dark arrows */
  /* 2. Common responsive styles */
  /* Smaller than 1200px */
  /* Smaller than 992px */
  /* Smaller than 768px */
  /* Smaller than 576px */
  /* 3. Headers */
  /* 4. Navigations */
  /* 5. Contents */
  /* 6. Testimonials */
  /* 7. Features */
  /* 8. Forms */
  /* 9. Call to Actions */
  /* 10. Teams */
  /* 11. Pricing Tables */
  /* 12. Contacts */
  /* 13. Showcases */
  /* 14. Footers */
  /* 15. Ecommerce */
  /* 16. Applications */ }
  :not(.login-page) .ant-message {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: fixed;
    z-index: 1010;
    width: 100%;
    top: 16px;
    left: 0;
    pointer-events: none; }
    :not(.login-page) .ant-message-notice {
      padding: 8px;
      text-align: center; }
      :not(.login-page) .ant-message-notice:first-child {
        margin-top: -8px; }
      :not(.login-page) .ant-message-notice-content {
        padding: 10px 16px;
        border-radius: 4px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        background: #fff;
        display: inline-block;
        pointer-events: all; }
    :not(.login-page) .ant-message-success .anticon {
      color: #52c41a; }
    :not(.login-page) .ant-message-warning .anticon {
      color: #faad14; }
    :not(.login-page) .ant-message-error .anticon {
      color: #f5222d; }
    :not(.login-page) .ant-message-info .anticon, :not(.login-page) .ant-message-loading .anticon {
      color: #1890ff; }
    :not(.login-page) .ant-message .anticon {
      margin-right: 8px;
      font-size: 16px;
      top: 1px;
      position: relative; }
    :not(.login-page) .ant-message-notice.move-up-leave.move-up-leave-active {
      -webkit-animation-name: MessageMoveOut;
      animation-name: MessageMoveOut;
      overflow: hidden;
      -webkit-animation-duration: .3s;
      animation-duration: .3s; }

@-webkit-keyframes MessageMoveOut {
  0% {
    opacity: 1;
    max-height: 150px;
    padding: 8px; }
  to {
    opacity: 0;
    max-height: 0;
    padding: 0; } }

@keyframes MessageMoveOut {
  0% {
    opacity: 1;
    max-height: 150px;
    padding: 8px; }
  to {
    opacity: 0;
    max-height: 0;
    padding: 0; } }
  :not(.login-page) .ant-modal {
    font-size: 14px;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    box-sizing: border-box;
    list-style: none;
    position: relative;
    width: auto;
    margin: 0 auto;
    top: 100px;
    padding: 0 0 24px; }
    :not(.login-page) .ant-modal-wrap {
      position: fixed;
      overflow: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1000;
      -webkit-overflow-scrolling: touch;
      outline: 0; }
    :not(.login-page) .ant-modal-title {
      margin: 0;
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85); }
    :not(.login-page) .ant-modal-content {
      position: relative;
      background-color: #fff;
      border: 0;
      border-radius: 4px;
      background-clip: padding-box;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); }
    :not(.login-page) .ant-modal-close {
      cursor: pointer;
      border: 0;
      background: transparent;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10;
      font-weight: 700;
      line-height: 1;
      text-decoration: none;
      transition: color .3s;
      color: rgba(0, 0, 0, 0.45);
      outline: 0;
      padding: 0; }
      :not(.login-page) .ant-modal-close:focus, :not(.login-page) .ant-modal-close:hover {
        color: rgba(0, 0, 0, 0.75);
        text-decoration: none; }
    :not(.login-page) .ant-modal-close-x {
      display: block;
      font-style: normal;
      vertical-align: baseline;
      text-align: center;
      text-transform: none;
      text-rendering: auto;
      width: 56px;
      height: 56px;
      line-height: 56px;
      font-size: 16px; }
    :not(.login-page) .ant-modal-header {
      padding: 16px 24px;
      border-radius: 4px 4px 0 0;
      background: #fff;
      color: rgba(0, 0, 0, 0.65);
      border-bottom: 1px solid #e8e8e8; }
    :not(.login-page) .ant-modal-body {
      padding: 24px;
      font-size: 14px;
      line-height: 1.5;
      word-wrap: break-word; }
    :not(.login-page) .ant-modal-footer {
      border-top: 1px solid #e8e8e8;
      padding: 10px 16px;
      text-align: right;
      border-radius: 0 0 4px 4px; }
      :not(.login-page) .ant-modal-footer button + button {
        margin-left: 8px;
        margin-bottom: 0; }
    :not(.login-page) .ant-modal.zoom-appear, :not(.login-page) .ant-modal.zoom-enter {
      -webkit-animation-duration: .3s;
      animation-duration: .3s;
      -webkit-transform: none;
      transform: none;
      opacity: 0;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
    :not(.login-page) .ant-modal-mask {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.65);
      height: 100%;
      z-index: 1000;
      filter: alpha(opacity=50); }
      :not(.login-page) .ant-modal-mask-hidden {
        display: none; }
    :not(.login-page) .ant-modal-open {
      overflow: hidden; }
    :not(.login-page) .ant-modal-centered {
      text-align: center; }
      :not(.login-page) .ant-modal-centered:before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        width: 0; }
      :not(.login-page) .ant-modal-centered .ant-modal {
        display: inline-block;
        vertical-align: middle;
        top: 0;
        text-align: left; }
  @media (max-width: 767px) {
    :not(.login-page) .ant-modal {
      max-width: calc(100vw - 16px);
      margin: 8px auto; }
      :not(.login-page) .ant-modal-centered .ant-modal {
        flex: 1 1; } }
  @media (min-width: 768px) {
    :not(.login-page) .container {
      max-width: 750px; } }
  @media (min-width: 992px) {
    :not(.login-page) .container {
      max-width: 970px; } }
  @media (min-width: 1200px) {
    :not(.login-page) .container {
      max-width: 1170px; } }
  :not(.login-page) .underline {
    text-decoration: underline; }
  :not(.login-page) .thin {
    font-weight: 100; }
  :not(.login-page) .extralight {
    font-weight: 200; }
  :not(.login-page) .light {
    font-weight: 300; }
  :not(.login-page) .regular {
    font-weight: 400; }
  :not(.login-page) .medium {
    font-weight: 500; }
  :not(.login-page) .semibold {
    font-weight: 600; }
  :not(.login-page) .bold {
    font-weight: 700; }
  :not(.login-page) .extrabold {
    font-weight: 800; }
  :not(.login-page) .heavy {
    font-weight: 900; }
  :not(.login-page) .sp-10 {
    letter-spacing: 1px; }
  :not(.login-page) .sp-15 {
    letter-spacing: 1.5px; }
  :not(.login-page) .sp-20 {
    letter-spacing: 2px; }
  :not(.login-page) .sp-25 {
    letter-spacing: 2.5px; }
  :not(.login-page) .op-0 {
    opacity: 0; }
  :not(.login-page) .op-1 {
    opacity: 0.1; }
  :not(.login-page) .op-2 {
    opacity: 0.2; }
  :not(.login-page) .op-3 {
    opacity: 0.3; }
  :not(.login-page) .op-4 {
    opacity: 0.4; }
  :not(.login-page) .op-5 {
    opacity: 0.5; }
  :not(.login-page) .op-6 {
    opacity: 0.6; }
  :not(.login-page) .op-7 {
    opacity: 0.7; }
  :not(.login-page) .op-8 {
    opacity: 0.8; }
  :not(.login-page) .op-9 {
    opacity: 0.9; }
  :not(.login-page) .op-10 {
    opacity: 1; }
  :not(.login-page) .zi-0 {
    z-index: 0; }
  :not(.login-page) .zi-1 {
    z-index: 1; }
  :not(.login-page) .zi-2 {
    z-index: 2; }
  :not(.login-page) .zi-3 {
    z-index: 3; }
  :not(.login-page) .zi-4 {
    z-index: 4; }
  :not(.login-page) .zi-5 {
    z-index: 5; }
  :not(.login-page) .link {
    display: inline-block; }
    :not(.login-page) .link.underline:hover, :not(.login-page) .link.underline:focus {
      text-decoration: underline; }
  :not(.login-page) .btn {
    vertical-align: top;
    height: 50px;
    line-height: 50px;
    padding: 0px 30px;
    border: none;
    border-radius: 50px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    background: transparent;
    transition: all 0.25s;
    /* sizes */
    /* z-index */
    /* Borders */ }
    :not(.login-page) .btn.active, :not(.login-page) .btn:active {
      box-shadow: none; }
    :not(.login-page) .btn:active, :not(.login-page) .btn:focus {
      box-shadow: none; }
    :not(.login-page) .btn[class^="border-"], :not(.login-page) .btn[class*=" border-"] {
      line-height: 46px; }
    :not(.login-page) .btn.sm {
      height: 40px;
      line-height: 40px;
      padding: 0 15px; }
      :not(.login-page) .btn.sm[class^="border-"], :not(.login-page) .btn.sm[class*=" border-"] {
        line-height: 36px; }
    :not(.login-page) .btn.md {
      /* regular size */ }
    :not(.login-page) .btn.lg {
      height: 60px;
      line-height: 60px;
      padding: 0 40px;
      font-size: 20px;
      font-weight: 600; }
      :not(.login-page) .btn.lg[class^="border-"], :not(.login-page) .btn.lg[class*=" border-"] {
        line-height: 56px; }
    :not(.login-page) .btn.xl {
      height: 70px;
      line-height: 70px;
      padding: 0 45px;
      font-size: 20px;
      font-weight: 600; }
      :not(.login-page) .btn.xl[class^="border-"], :not(.login-page) .btn.xl[class*=" border-"] {
        line-height: 66px; }
    :not(.login-page) .btn .zi-0 {
      z-index: 0; }
    :not(.login-page) .btn.action-2 {
      background-color: #0074f1;
      color: #FFFFFF; }
      :not(.login-page) .btn.action-2:hover {
        background-color: #005bbe; }
    :not(.login-page) .btn.action-3 {
      background-color: #7121F4;
      color: #FFFFFF; }
      :not(.login-page) .btn.action-3:hover {
        background-color: #580bd7; }
    :not(.login-page) .btn.action-white {
      background-color: #FFFFFF;
      color: #0074f1; }
      :not(.login-page) .btn.action-white:hover {
        background-color: #e6e6e6; }
    :not(.login-page) .btn.action-red {
      background-color: #EA3223;
      color: #FFFFFF; }
      :not(.login-page) .btn.action-red:hover {
        background-color: #c72113; }
    :not(.login-page) .btn.action-facebook {
      background-color: #2e4da7;
      color: #FFFFFF; }
      :not(.login-page) .btn.action-facebook:hover {
        background-color: #3e62ca; }
    :not(.login-page) .btn.action-twitter {
      background-color: #0aa0f5;
      color: #FFFFFF; }
      :not(.login-page) .btn.action-twitter:hover {
        background-color: #0880c4; }
    :not(.login-page) .btn.action-dribbble {
      background-color: #E93A7D;
      color: #FFFFFF; }
      :not(.login-page) .btn.action-dribbble:hover {
        background-color: #d81862; }
    :not(.login-page) .btn.border-main {
      border: 2px solid #1e3060; }
      :not(.login-page) .btn.border-main:hover {
        border-color: #3657ae; }
    :not(.login-page) .btn.border-transparent-main {
      color: #1e3060;
      border: 2px solid rgba(30, 48, 96, 0.3); }
      :not(.login-page) .btn.border-transparent-main:hover {
        border-color: rgba(30, 48, 96, 0.7); }
    :not(.login-page) .btn.border-heading {
      border: 2px solid #1E3060; }
      :not(.login-page) .btn.border-heading:hover {
        border-color: #060912; }
    :not(.login-page) .btn.border-white {
      border: 2px solid #FFFFFF; }
      :not(.login-page) .btn.border-white:hover {
        border-color: #cccccc; }
    :not(.login-page) .btn.border-transparent-white {
      border: 2px solid rgba(255, 255, 255, 0.3); }
      :not(.login-page) .btn.border-transparent-white:hover {
        border-color: rgba(255, 255, 255, 0.7);
        color: #FFFFFF; }
    :not(.login-page) .btn.border-gray {
      border: 2px solid #E7EAED; }
      :not(.login-page) .btn.border-gray:hover {
        border-color: #adb7c1; }
    :not(.login-page) .btn.border-light {
      border: 2px solid #FFFFFF; }
      :not(.login-page) .btn.border-light:hover {
        border-color: #cccccc; }
    :not(.login-page) .btn.border-dark {
      border: 2px solid #35383D; }
      :not(.login-page) .btn.border-dark:hover {
        border-color: #646a74; }
    :not(.login-page) .btn.border-action-1 {
      border: 2px solid #0074f1;
      color: #0074f1; }
      :not(.login-page) .btn.border-action-1:hover {
        border-color: #00438b;
        color: #00438b; }
    :not(.login-page) .btn.border-action-2 {
      border: 2px solid #0074f1;
      color: #0074f1; }
      :not(.login-page) .btn.border-action-2:hover {
        border-color: #00438b;
        color: #00438b; }
    :not(.login-page) .btn.border-action-3 {
      border: 2px solid #7121F4;
      color: #7121F4; }
      :not(.login-page) .btn.border-action-3:hover {
        border-color: #4408a7;
        color: #4408a7; }
  :not(.login-page) .input {
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    padding: 0 25px;
    height: 50px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 30px;
    transition: border-color 0.3s;
    /* Sizes */
    /* Borders */
    /* styles on focus */
    /* placeholders */ }
    :not(.login-page) .input:focus::-moz-placeholder {
      color: transparent !important; }
    :not(.login-page) .input:focus::-webkit-input-placeholder {
      color: transparent !important; }
    :not(.login-page) .input:focus:-ms-input-placeholder {
      color: transparent !important; }
    :not(.login-page) .input.sm {
      font-size: 16px;
      height: 40px;
      padding-left: 20px;
      padding-right: 20px;
      font-weight: 400; }
    :not(.login-page) .input.lg {
      font-size: 20px;
      height: 60px;
      padding-left: 25px;
      padding-right: 25px;
      font-weight: 600; }
    :not(.login-page) .input.border-main {
      border: 2px solid #1e3060; }
    :not(.login-page) .input.border-heading {
      border: 2px solid #1E3060; }
    :not(.login-page) .input.border-white {
      border: 2px solid #FFFFFF; }
    :not(.login-page) .input.border-transparent-white {
      border: 2px solid rgba(255, 255, 255, 0.4); }
    :not(.login-page) .input.border-gray {
      border: 2px solid #E7EAED; }
    :not(.login-page) .input.border-light {
      border: 2px solid #FFFFFF; }
    :not(.login-page) .input.border-dark {
      border: 2px solid #35383D; }
    :not(.login-page) .input.border-action-1 {
      border: 2px solid #0074f1; }
    :not(.login-page) .input.border-action-2 {
      border: 2px solid #0074f1; }
    :not(.login-page) .input.border-action-3 {
      border: 2px solid #7121F4; }
    :not(.login-page) .input.focus-main:focus {
      border-color: #1e3060; }
    :not(.login-page) .input.focus-heading:focus {
      border-color: #1E3060; }
    :not(.login-page) .input.focus-white:focus {
      border-color: #FFFFFF; }
    :not(.login-page) .input.focus-gray:focus {
      border-color: #E7EAED; }
    :not(.login-page) .input.focus-light:focus {
      border-color: #FFFFFF; }
    :not(.login-page) .input.focus-dark:focus {
      border-color: #35383D; }
    :not(.login-page) .input.focus-action-1:focus {
      border-color: #0074f1; }
    :not(.login-page) .input.focus-action-2:focus {
      border-color: #0074f1; }
    :not(.login-page) .input.focus-action-3:focus {
      border-color: #7121F4; }
    :not(.login-page) .input.placeholder-white::-moz-placeholder {
      color: #FFFFFF; }
    :not(.login-page) .input.placeholder-white::-webkit-input-placeholder {
      color: #FFFFFF; }
    :not(.login-page) .input.placeholder-white:-ms-input-placeholder {
      color: #FFFFFF; }
    :not(.login-page) .input.placeholder-transparent-white::-moz-placeholder {
      color: rgba(255, 255, 255, 0.4); }
    :not(.login-page) .input.placeholder-transparent-white::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.4); }
    :not(.login-page) .input.placeholder-transparent-white:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.4); }
    :not(.login-page) .input.placeholder-main::-moz-placeholder {
      color: #1e3060; }
    :not(.login-page) .input.placeholder-main::-webkit-input-placeholder {
      color: #1e3060; }
    :not(.login-page) .input.placeholder-main:-ms-input-placeholder {
      color: #1e3060; }
    :not(.login-page) .input.placeholder-heading::-moz-placeholder {
      color: #1E3060; }
    :not(.login-page) .input.placeholder-heading::-webkit-input-placeholder {
      color: #1E3060; }
    :not(.login-page) .input.placeholder-heading:-ms-input-placeholder {
      color: #1E3060; }
    :not(.login-page) .input.placeholder-gray::-moz-placeholder {
      color: #E7EAED; }
    :not(.login-page) .input.placeholder-gray::-webkit-input-placeholder {
      color: #E7EAED; }
    :not(.login-page) .input.placeholder-gray:-ms-input-placeholder {
      color: #E7EAED; }
    :not(.login-page) .input.placeholder-action-1::-moz-placeholder {
      color: #0074f1; }
    :not(.login-page) .input.placeholder-action-1::-webkit-input-placeholder {
      color: #0074f1; }
    :not(.login-page) .input.placeholder-action-1:-ms-input-placeholder {
      color: #0074f1; }
    :not(.login-page) .input.placeholder-action-2::-moz-placeholder {
      color: #0074f1; }
    :not(.login-page) .input.placeholder-action-2::-webkit-input-placeholder {
      color: #0074f1; }
    :not(.login-page) .input.placeholder-action-2:-ms-input-placeholder {
      color: #0074f1; }
    :not(.login-page) .input.placeholder-action-3::-moz-placeholder {
      color: #7121F4; }
    :not(.login-page) .input.placeholder-action-3::-webkit-input-placeholder {
      color: #7121F4; }
    :not(.login-page) .input.placeholder-action-3:-ms-input-placeholder {
      color: #7121F4; }
  :not(.login-page) select {
    appearance: none;
    text-indent: 0.01px;
    text-overflow: "";
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 90% 50%;
    background-position: calc(100% - 20px) 50%;
    background-size: 11px 6px;
    border: 0;
    outline: none;
    color: #1e3060;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-indent: 1px;
    text-overflow: ""; }
    :not(.login-page) select::-ms-expand {
      display: none; }
    :not(.login-page) select.color-white {
      color: #FFFFFF; }
      :not(.login-page) select.color-white option {
        color: #1e3060;
        background: #fff; }
  :not(.login-page) textarea.input {
    padding-top: 15px;
    padding-bottom: 15px; }
  :not(.login-page) input[type="checkbox"],
  :not(.login-page) input[type="radio"] {
    /* Borders */
    /* Backgrounds */
    /* styles on focus */ }
    :not(.login-page) input[type="checkbox"] + label,
    :not(.login-page) input[type="radio"] + label {
      line-height: 24px; }
      :not(.login-page) input[type="checkbox"] + label:before,
      :not(.login-page) input[type="radio"] + label:before {
        display: inline-block;
        vertical-align: top;
        content: "";
        text-align: center;
        width: 24px;
        height: 24px;
        line-height: 20px;
        border-width: 2px;
        border-style: solid;
        margin-right: 10px;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 12px;
        transition: all 0.2s; }
    :not(.login-page) input[type="checkbox"].border-main + label:before,
    :not(.login-page) input[type="radio"].border-main + label:before {
      border: 2px solid #1e3060; }
    :not(.login-page) input[type="checkbox"].border-heading + label:before,
    :not(.login-page) input[type="radio"].border-heading + label:before {
      border: 2px solid #1E3060; }
    :not(.login-page) input[type="checkbox"].border-white + label:before,
    :not(.login-page) input[type="radio"].border-white + label:before {
      border: 2px solid #FFFFFF; }
    :not(.login-page) input[type="checkbox"].border-transparent-white + label:before,
    :not(.login-page) input[type="radio"].border-transparent-white + label:before {
      border: 2px solid rgba(255, 255, 255, 0.4); }
    :not(.login-page) input[type="checkbox"].border-gray + label:before,
    :not(.login-page) input[type="radio"].border-gray + label:before {
      border: 2px solid #E7EAED; }
    :not(.login-page) input[type="checkbox"].border-light + label:before,
    :not(.login-page) input[type="radio"].border-light + label:before {
      border: 2px solid #FFFFFF; }
    :not(.login-page) input[type="checkbox"].border-dark + label:before,
    :not(.login-page) input[type="radio"].border-dark + label:before {
      border: 2px solid #35383D; }
    :not(.login-page) input[type="checkbox"].border-action-1 + label:before,
    :not(.login-page) input[type="radio"].border-action-1 + label:before {
      border: 2px solid #0074f1; }
    :not(.login-page) input[type="checkbox"].border-action-2 + label:before,
    :not(.login-page) input[type="radio"].border-action-2 + label:before {
      border: 2px solid #0074f1; }
    :not(.login-page) input[type="checkbox"].border-action-3 + label:before,
    :not(.login-page) input[type="radio"].border-action-3 + label:before {
      border: 2px solid #7121F4; }
    :not(.login-page) input[type="checkbox"].bg-main + label:before,
    :not(.login-page) input[type="radio"].bg-main + label:before {
      background-color: #1e3060; }
    :not(.login-page) input[type="checkbox"].bg-heading + label:before,
    :not(.login-page) input[type="radio"].bg-heading + label:before {
      background-color: #1E3060; }
    :not(.login-page) input[type="checkbox"].bg-white + label:before,
    :not(.login-page) input[type="radio"].bg-white + label:before {
      background-color: #FFFFFF; }
    :not(.login-page) input[type="checkbox"].bg-transparent-white + label:before,
    :not(.login-page) input[type="radio"].bg-transparent-white + label:before {
      background-color: rgba(255, 255, 255, 0.4); }
    :not(.login-page) input[type="checkbox"].bg-gray + label:before,
    :not(.login-page) input[type="radio"].bg-gray + label:before {
      background-color: #E7EAED; }
    :not(.login-page) input[type="checkbox"].bg-light + label:before,
    :not(.login-page) input[type="radio"].bg-light + label:before {
      background-color: #FFFFFF; }
    :not(.login-page) input[type="checkbox"].bg-dark + label:before,
    :not(.login-page) input[type="radio"].bg-dark + label:before {
      background-color: #35383D; }
    :not(.login-page) input[type="checkbox"].bg-action-1 + label:before,
    :not(.login-page) input[type="radio"].bg-action-1 + label:before {
      background-color: #0074f1; }
    :not(.login-page) input[type="checkbox"].bg-action-2 + label:before,
    :not(.login-page) input[type="radio"].bg-action-2 + label:before {
      background-color: #0074f1; }
    :not(.login-page) input[type="checkbox"].bg-action-3 + label:before,
    :not(.login-page) input[type="radio"].bg-action-3 + label:before {
      background-color: #7121F4; }
    :not(.login-page) input[type="checkbox"].focus-main:checked + label:before,
    :not(.login-page) input[type="radio"].focus-main:checked + label:before {
      border-color: #1e3060;
      background-color: #1e3060;
      color: #FFFFFF; }
    :not(.login-page) input[type="checkbox"].focus-heading:checked + label:before,
    :not(.login-page) input[type="radio"].focus-heading:checked + label:before {
      border-color: #1E3060;
      background-color: #1E3060; }
    :not(.login-page) input[type="checkbox"].focus-white:checked + label:before,
    :not(.login-page) input[type="radio"].focus-white:checked + label:before {
      border-color: #FFFFFF;
      background-color: #FFFFFF;
      color: #1e3060; }
    :not(.login-page) input[type="checkbox"].focus-gray:checked + label:before,
    :not(.login-page) input[type="radio"].focus-gray:checked + label:before {
      border-color: #E7EAED;
      background-color: #E7EAED; }
    :not(.login-page) input[type="checkbox"].focus-light:checked + label:before,
    :not(.login-page) input[type="radio"].focus-light:checked + label:before {
      border-color: #FFFFFF;
      background-color: #FFFFFF; }
    :not(.login-page) input[type="checkbox"].focus-dark:checked + label:before,
    :not(.login-page) input[type="radio"].focus-dark:checked + label:before {
      border-color: #35383D;
      background-color: #35383D;
      color: #FFFFFF; }
    :not(.login-page) input[type="checkbox"].focus-action-1:checked + label:before,
    :not(.login-page) input[type="radio"].focus-action-1:checked + label:before {
      border-color: #0074f1;
      background-color: #0074f1;
      color: #1e3060; }
    :not(.login-page) input[type="checkbox"].focus-action-2:checked + label:before,
    :not(.login-page) input[type="radio"].focus-action-2:checked + label:before {
      border-color: #0074f1;
      background-color: #0074f1;
      color: #FFFFFF; }
    :not(.login-page) input[type="checkbox"].focus-action-3:checked + label:before,
    :not(.login-page) input[type="radio"].focus-action-3:checked + label:before {
      border-color: #7121F4;
      background-color: #7121F4;
      color: #FFFFFF; }
  :not(.login-page) input[type="checkbox"]:checked + label:before {
    content: "\F00C"; }
  :not(.login-page) input[type="checkbox"] + label:before {
    border-radius: 6px; }
  :not(.login-page) input[type="radio"]:checked + label:before {
    content: "\F111"; }
  :not(.login-page) input[type="radio"] + label:before {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    line-height: 16px; }
  :not(.login-page) .logo {
    font-family: Nunito Sans;
    font-size: 24px;
    color: #1e3060;
    font-weight: 700; }
    :not(.login-page) .logo.color-white {
      color: #FFFFFF; }
  :not(.login-page) .navigation_mobile {
    position: fixed;
    top: 0px;
    height: 100%;
    width: 320px;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1000;
    -webkit-transform: translateX(-320px);
            transform: translateX(-320px);
    transition: -webkit-transform 0.4s ease;
    transition: transform 0.4s ease;
    transition: transform 0.4s ease, -webkit-transform 0.4s ease; }
    :not(.login-page) .navigation_mobile .inner {
      display: flex;
      flex-direction: column; }
    :not(.login-page) .navigation_mobile.opened {
      -webkit-transform: translateX(0);
              transform: translateX(0); }
      :not(.login-page) .navigation_mobile.opened .close_menu {
        display: block; }
  :not(.login-page) .open_menu,
  :not(.login-page) .close_menu {
    display: none;
    top: 20px;
    width: 40px;
    height: 40px;
    color: #1e3060;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    z-index: 990; }
    :not(.login-page) .open_menu.color-white,
    :not(.login-page) .close_menu.color-white {
      color: #FFFFFF !important; }
    :not(.login-page) .open_menu.right,
    :not(.login-page) .close_menu.right {
      left: auto;
      right: 20px; }
  :not(.login-page) .open_menu {
    position: fixed;
    left: 20px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.04); }
  :not(.login-page) .close_menu {
    right: 20px;
    position: absolute; }
  :not(.login-page) .video {
    position: relative;
    overflow: hidden; }
    :not(.login-page) .video .poster {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all 0.2s;
      cursor: pointer;
      z-index: 500; }
      :not(.login-page) .video .poster img {
        width: 100%;
        max-width: 100%; }
    :not(.login-page) .video video {
      width: 100%;
      height: 100%;
      display: none; }
  :not(.login-page) .play {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 16px;
    border-radius: 50%;
    text-align: center;
    transition: all 0.2s;
    z-index: 501; }
    :not(.login-page) .play:hover {
      -webkit-transform: scale(1.08);
              transform: scale(1.08); }
    :not(.login-page) .play:not(.relative) {
      margin: -35px 0 0 -35px;
      position: absolute;
      top: 50%;
      left: 50%; }
    :not(.login-page) .play.relative {
      display: inline-block; }
    :not(.login-page) .play.sm {
      width: 60px;
      height: 60px;
      line-height: 60px;
      margin: -30px 0 0 -30px; }
    :not(.login-page) .play.red {
      background: #EA3223;
      color: #FFFFFF; }
    :not(.login-page) .play.white {
      background: #FFFFFF;
      color: #1e3060; }
    :not(.login-page) .play.action-1 {
      background: #0074f1;
      color: #FFFFFF; }
    :not(.login-page) .play.action-2 {
      background: #0074f1;
      color: #FFFFFF; }
    :not(.login-page) .play.action-3 {
      background: #7121F4;
      color: #FFFFFF; }
    :not(.login-page) .play .fa,
    :not(.login-page) .play .fas {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
  :not(.login-page) .opening_tabs .tab_opener {
    cursor: pointer; }
  :not(.login-page) .opening_tabs .tab_text {
    overflow: hidden;
    height: 0; }
  :not(.login-page) .alert {
    display: none;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 100%;
    max-width: 970px;
    color: #FFFFFF;
    z-index: 2000; }
    :not(.login-page) .alert-dismissable .close, :not(.login-page) .alert-dismissible .close {
      top: 0; }
    :not(.login-page) .alert.fixed-top {
      margin-top: 1rem; }
    :not(.login-page) .alert-primary {
      background: #1e3060;
      border-color: #1e3060; }
    :not(.login-page) .alert-secondary {
      background: #1E3060;
      border-color: #1E3060; }
    :not(.login-page) .alert-success {
      background: #0074f1;
      border-color: #0074f1; }
    :not(.login-page) .alert-danger {
      background: #7121F4;
      border-color: #7121F4; }
    :not(.login-page) .alert-warning, :not(.login-page) .alert-info {
      background: #0074f1;
      border-color: #0074f1; }
    :not(.login-page) .alert-light {
      background: #FFFFFF;
      border-color: #FFFFFF;
      color: #1e3060; }
      :not(.login-page) .alert-light.alert .close:hover {
        color: #1e3060; }
    :not(.login-page) .alert-dark {
      background: #35383D;
      border-color: #35383D; }
    :not(.login-page) .alert .close {
      text-shadow: none; }
      :not(.login-page) .alert .close:hover {
        color: #FFFFFF; }
  :not(.login-page) .grecaptcha-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    cursor: pointer; }
  :not(.login-page) .grecaptcha-popup {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 1000; }
  :not(.login-page) .dropdown.show .dropdown-toggle:after {
    -webkit-transform: scaleY(-1);
            transform: scaleY(-1); }
  :not(.login-page) .dropdown a {
    white-space: nowrap; }
  :not(.login-page) .dropdown-toggle:after {
    content: "\F107";
    height: 100%;
    vertical-align: top;
    border: none;
    font-size: 18px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s; }
  :not(.login-page) .dropdown-menu {
    margin-top: 0;
    margin-left: -20px;
    padding: 0px 20px;
    border: none;
    border-radius: 0px; }
    :not(.login-page) .dropdown-menu.dropdown-menu-right {
      margin-left: auto;
      margin-right: -20px; }
  :not(.login-page) .navigation_popup {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border-top: 2px solid #E7EAED;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.25s; }
    :not(.login-page) .navigation_popup.opened {
      opacity: 1;
      visibility: visible; }
  :not(.login-page) .slider.arrows0 .slick-prev {
    left: 0px; }
  :not(.login-page) .slider.arrows0 .slick-next {
    right: 0px; }
  :not(.login-page) .slider.arrows10 .slick-prev {
    left: 10px; }
  :not(.login-page) .slider.arrows10 .slick-next {
    right: 10px; }
  :not(.login-page) .slider.arrows20 .slick-prev {
    left: 20px; }
  :not(.login-page) .slider.arrows20 .slick-next {
    right: 20px; }
  :not(.login-page) .slider.arrows30 .slick-prev {
    left: 30px; }
  :not(.login-page) .slider.arrows30 .slick-next {
    right: 30px; }
  :not(.login-page) .slider.arrows40 .slick-prev {
    left: 40px; }
  :not(.login-page) .slider.arrows40 .slick-next {
    right: 40px; }
  :not(.login-page) .slider.arrows50 .slick-prev {
    left: 50px; }
  :not(.login-page) .slider.arrows50 .slick-next {
    right: 50px; }
  :not(.login-page) .slider.arrows60 .slick-prev {
    left: 60px; }
  :not(.login-page) .slider.arrows60 .slick-next {
    right: 60px; }
  :not(.login-page) .slider.arrows70 .slick-prev {
    left: 70px; }
  :not(.login-page) .slider.arrows70 .slick-next {
    right: 70px; }
  :not(.login-page) .slider.arrows80 .slick-prev {
    left: 80px; }
  :not(.login-page) .slider.arrows80 .slick-next {
    right: 80px; }
  :not(.login-page) .slider.arrows90 .slick-prev {
    left: 90px; }
  :not(.login-page) .slider.arrows90 .slick-next {
    right: 90px; }
  :not(.login-page) .slider.arrows100 .slick-prev {
    left: 100px; }
  :not(.login-page) .slider.arrows100 .slick-next {
    right: 100px; }
  :not(.login-page) .slider.dots_color_main .slick-dots li button {
    border-color: #1e3060; }
  :not(.login-page) .slider.dots_color_main .slick-dots li.slick-active button {
    background: #1e3060; }
  :not(.login-page) .slider.dots-100 .slick-dots {
    bottom: -100px; }
  :not(.login-page) .slider.dots-90 .slick-dots {
    bottom: -90px; }
  :not(.login-page) .slider.dots-80 .slick-dots {
    bottom: -80px; }
  :not(.login-page) .slider.dots-70 .slick-dots {
    bottom: -70px; }
  :not(.login-page) .slider.dots-60 .slick-dots {
    bottom: -60px; }
  :not(.login-page) .slider.dots-50 .slick-dots {
    bottom: -50px; }
  :not(.login-page) .slider.dots-40 .slick-dots {
    bottom: -40px; }
  :not(.login-page) .slider.dots-30 .slick-dots {
    bottom: -30px; }
  :not(.login-page) .slider.dots-20 .slick-dots {
    bottom: -20px; }
  :not(.login-page) .slider.dots-10 .slick-dots {
    bottom: -10px; }
  :not(.login-page) .slider.dots0 .slick-dots {
    bottom: 0px; }
  :not(.login-page) .slider.dots10 .slick-dots {
    bottom: 10px; }
  :not(.login-page) .slider.dots20 .slick-dots {
    bottom: 20px; }
  :not(.login-page) .slider.dots30 .slick-dots {
    bottom: 30px; }
  :not(.login-page) .slider.dots40 .slick-dots {
    bottom: 40px; }
  :not(.login-page) .slider.dots50 .slick-dots {
    bottom: 50px; }
  :not(.login-page) .slider.dots60 .slick-dots {
    bottom: 60px; }
  :not(.login-page) .slider.dots70 .slick-dots {
    bottom: 70px; }
  :not(.login-page) .slider.dots80 .slick-dots {
    bottom: 80px; }
  :not(.login-page) .slider.dots90 .slick-dots {
    bottom: 90px; }
  :not(.login-page) .slider.dots100 .slick-dots {
    bottom: 100px; }
  :not(.login-page) .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(231, 234, 237, 0.5); }
  :not(.login-page) .mt-0 {
    margin-top: 0px; }
  :not(.login-page) .mb-0 {
    margin-bottom: 0px; }
  :not(.login-page) .mr-0 {
    margin-right: 0px; }
  :not(.login-page) .ml-0 {
    margin-left: 0px; }
  :not(.login-page) .mx-0 {
    margin-left: 0px;
    margin-right: 0px; }
  :not(.login-page) .pt-0 {
    padding-top: 0px; }
  :not(.login-page) .pb-0 {
    padding-bottom: 0px; }
  :not(.login-page) .pr-0 {
    padding-right: 0px; }
  :not(.login-page) .pl-0 {
    padding-left: 0px; }
  :not(.login-page) .px-0 {
    padding-left: 0px;
    padding-right: 0px; }
  :not(.login-page) .mt-5 {
    margin-top: 5px; }
  :not(.login-page) .mb-5 {
    margin-bottom: 5px; }
  :not(.login-page) .mr-5 {
    margin-right: 5px; }
  :not(.login-page) .ml-5 {
    margin-left: 5px; }
  :not(.login-page) .mx-5 {
    margin-left: 5px;
    margin-right: 5px; }
  :not(.login-page) .pt-5 {
    padding-top: 5px; }
  :not(.login-page) .pb-5 {
    padding-bottom: 5px; }
  :not(.login-page) .pr-5 {
    padding-right: 5px; }
  :not(.login-page) .pl-5 {
    padding-left: 5px; }
  :not(.login-page) .px-5 {
    padding-left: 5px;
    padding-right: 5px; }
  :not(.login-page) .mt-10 {
    margin-top: 10px; }
  :not(.login-page) .mb-10 {
    margin-bottom: 10px; }
  :not(.login-page) .mr-10 {
    margin-right: 10px; }
  :not(.login-page) .ml-10 {
    margin-left: 10px; }
  :not(.login-page) .mx-10 {
    margin-left: 10px;
    margin-right: 10px; }
  :not(.login-page) .pt-10 {
    padding-top: 10px; }
  :not(.login-page) .pb-10 {
    padding-bottom: 10px; }
  :not(.login-page) .pr-10 {
    padding-right: 10px; }
  :not(.login-page) .pl-10 {
    padding-left: 10px; }
  :not(.login-page) .px-10 {
    padding-left: 10px;
    padding-right: 10px; }
  :not(.login-page) .mt-15 {
    margin-top: 15px; }
  :not(.login-page) .mb-15 {
    margin-bottom: 15px; }
  :not(.login-page) .mr-15 {
    margin-right: 15px; }
  :not(.login-page) .ml-15 {
    margin-left: 15px; }
  :not(.login-page) .mx-15 {
    margin-left: 15px;
    margin-right: 15px; }
  :not(.login-page) .pt-15 {
    padding-top: 15px; }
  :not(.login-page) .pb-15 {
    padding-bottom: 15px; }
  :not(.login-page) .pr-15 {
    padding-right: 15px; }
  :not(.login-page) .pl-15 {
    padding-left: 15px; }
  :not(.login-page) .px-15 {
    padding-left: 15px;
    padding-right: 15px; }
  :not(.login-page) .mt-20 {
    margin-top: 20px; }
  :not(.login-page) .mb-20 {
    margin-bottom: 20px; }
  :not(.login-page) .mr-20 {
    margin-right: 20px; }
  :not(.login-page) .ml-20 {
    margin-left: 20px; }
  :not(.login-page) .mx-20 {
    margin-left: 20px;
    margin-right: 20px; }
  :not(.login-page) .pt-20 {
    padding-top: 20px; }
  :not(.login-page) .pb-20 {
    padding-bottom: 20px; }
  :not(.login-page) .pr-20 {
    padding-right: 20px; }
  :not(.login-page) .pl-20 {
    padding-left: 20px; }
  :not(.login-page) .px-20 {
    padding-left: 20px;
    padding-right: 20px; }
  :not(.login-page) .mt-25 {
    margin-top: 25px; }
  :not(.login-page) .mb-25 {
    margin-bottom: 25px; }
  :not(.login-page) .mr-25 {
    margin-right: 25px; }
  :not(.login-page) .ml-25 {
    margin-left: 25px; }
  :not(.login-page) .mx-25 {
    margin-left: 25px;
    margin-right: 25px; }
  :not(.login-page) .pt-25 {
    padding-top: 25px; }
  :not(.login-page) .pb-25 {
    padding-bottom: 25px; }
  :not(.login-page) .pr-25 {
    padding-right: 25px; }
  :not(.login-page) .pl-25 {
    padding-left: 25px; }
  :not(.login-page) .px-25 {
    padding-left: 25px;
    padding-right: 25px; }
  :not(.login-page) .mt-30 {
    margin-top: 30px; }
  :not(.login-page) .mb-30 {
    margin-bottom: 30px; }
  :not(.login-page) .mr-30 {
    margin-right: 30px; }
  :not(.login-page) .ml-30 {
    margin-left: 30px; }
  :not(.login-page) .mx-30 {
    margin-left: 30px;
    margin-right: 30px; }
  :not(.login-page) .pt-30 {
    padding-top: 30px; }
  :not(.login-page) .pb-30 {
    padding-bottom: 30px; }
  :not(.login-page) .pr-30 {
    padding-right: 30px; }
  :not(.login-page) .pl-30 {
    padding-left: 30px; }
  :not(.login-page) .px-30 {
    padding-left: 30px;
    padding-right: 30px; }
  :not(.login-page) .mt-35 {
    margin-top: 35px; }
  :not(.login-page) .mb-35 {
    margin-bottom: 35px; }
  :not(.login-page) .mr-35 {
    margin-right: 35px; }
  :not(.login-page) .ml-35 {
    margin-left: 35px; }
  :not(.login-page) .mx-35 {
    margin-left: 35px;
    margin-right: 35px; }
  :not(.login-page) .pt-35 {
    padding-top: 35px; }
  :not(.login-page) .pb-35 {
    padding-bottom: 35px; }
  :not(.login-page) .pr-35 {
    padding-right: 35px; }
  :not(.login-page) .pl-35 {
    padding-left: 35px; }
  :not(.login-page) .px-35 {
    padding-left: 35px;
    padding-right: 35px; }
  :not(.login-page) .mt-40 {
    margin-top: 40px; }
  :not(.login-page) .mb-40 {
    margin-bottom: 40px; }
  :not(.login-page) .mr-40 {
    margin-right: 40px; }
  :not(.login-page) .ml-40 {
    margin-left: 40px; }
  :not(.login-page) .mx-40 {
    margin-left: 40px;
    margin-right: 40px; }
  :not(.login-page) .pt-40 {
    padding-top: 40px; }
  :not(.login-page) .pb-40 {
    padding-bottom: 40px; }
  :not(.login-page) .pr-40 {
    padding-right: 40px; }
  :not(.login-page) .pl-40 {
    padding-left: 40px; }
  :not(.login-page) .px-40 {
    padding-left: 40px;
    padding-right: 40px; }
  :not(.login-page) .mt-45 {
    margin-top: 45px; }
  :not(.login-page) .mb-45 {
    margin-bottom: 45px; }
  :not(.login-page) .mr-45 {
    margin-right: 45px; }
  :not(.login-page) .ml-45 {
    margin-left: 45px; }
  :not(.login-page) .mx-45 {
    margin-left: 45px;
    margin-right: 45px; }
  :not(.login-page) .pt-45 {
    padding-top: 45px; }
  :not(.login-page) .pb-45 {
    padding-bottom: 45px; }
  :not(.login-page) .pr-45 {
    padding-right: 45px; }
  :not(.login-page) .pl-45 {
    padding-left: 45px; }
  :not(.login-page) .px-45 {
    padding-left: 45px;
    padding-right: 45px; }
  :not(.login-page) .mt-50 {
    margin-top: 50px; }
  :not(.login-page) .mb-50 {
    margin-bottom: 50px; }
  :not(.login-page) .mr-50 {
    margin-right: 50px; }
  :not(.login-page) .ml-50 {
    margin-left: 50px; }
  :not(.login-page) .mx-50 {
    margin-left: 50px;
    margin-right: 50px; }
  :not(.login-page) .pt-50 {
    padding-top: 50px; }
  :not(.login-page) .pb-50 {
    padding-bottom: 50px; }
  :not(.login-page) .pr-50 {
    padding-right: 50px; }
  :not(.login-page) .pl-50 {
    padding-left: 50px; }
  :not(.login-page) .px-50 {
    padding-left: 50px;
    padding-right: 50px; }
  :not(.login-page) .mt-55 {
    margin-top: 55px; }
  :not(.login-page) .mb-55 {
    margin-bottom: 55px; }
  :not(.login-page) .mr-55 {
    margin-right: 55px; }
  :not(.login-page) .ml-55 {
    margin-left: 55px; }
  :not(.login-page) .mx-55 {
    margin-left: 55px;
    margin-right: 55px; }
  :not(.login-page) .pt-55 {
    padding-top: 55px; }
  :not(.login-page) .pb-55 {
    padding-bottom: 55px; }
  :not(.login-page) .pr-55 {
    padding-right: 55px; }
  :not(.login-page) .pl-55 {
    padding-left: 55px; }
  :not(.login-page) .px-55 {
    padding-left: 55px;
    padding-right: 55px; }
  :not(.login-page) .mt-60 {
    margin-top: 60px; }
  :not(.login-page) .mb-60 {
    margin-bottom: 60px; }
  :not(.login-page) .mr-60 {
    margin-right: 60px; }
  :not(.login-page) .ml-60 {
    margin-left: 60px; }
  :not(.login-page) .mx-60 {
    margin-left: 60px;
    margin-right: 60px; }
  :not(.login-page) .pt-60 {
    padding-top: 60px; }
  :not(.login-page) .pb-60 {
    padding-bottom: 60px; }
  :not(.login-page) .pr-60 {
    padding-right: 60px; }
  :not(.login-page) .pl-60 {
    padding-left: 60px; }
  :not(.login-page) .px-60 {
    padding-left: 60px;
    padding-right: 60px; }
  :not(.login-page) .mt-65 {
    margin-top: 65px; }
  :not(.login-page) .mb-65 {
    margin-bottom: 65px; }
  :not(.login-page) .mr-65 {
    margin-right: 65px; }
  :not(.login-page) .ml-65 {
    margin-left: 65px; }
  :not(.login-page) .mx-65 {
    margin-left: 65px;
    margin-right: 65px; }
  :not(.login-page) .pt-65 {
    padding-top: 65px; }
  :not(.login-page) .pb-65 {
    padding-bottom: 65px; }
  :not(.login-page) .pr-65 {
    padding-right: 65px; }
  :not(.login-page) .pl-65 {
    padding-left: 65px; }
  :not(.login-page) .px-65 {
    padding-left: 65px;
    padding-right: 65px; }
  :not(.login-page) .mt-70 {
    margin-top: 70px; }
  :not(.login-page) .mb-70 {
    margin-bottom: 70px; }
  :not(.login-page) .mr-70 {
    margin-right: 70px; }
  :not(.login-page) .ml-70 {
    margin-left: 70px; }
  :not(.login-page) .mx-70 {
    margin-left: 70px;
    margin-right: 70px; }
  :not(.login-page) .pt-70 {
    padding-top: 70px; }
  :not(.login-page) .pb-70 {
    padding-bottom: 70px; }
  :not(.login-page) .pr-70 {
    padding-right: 70px; }
  :not(.login-page) .pl-70 {
    padding-left: 70px; }
  :not(.login-page) .px-70 {
    padding-left: 70px;
    padding-right: 70px; }
  :not(.login-page) .mt-75 {
    margin-top: 75px; }
  :not(.login-page) .mb-75 {
    margin-bottom: 75px; }
  :not(.login-page) .mr-75 {
    margin-right: 75px; }
  :not(.login-page) .ml-75 {
    margin-left: 75px; }
  :not(.login-page) .mx-75 {
    margin-left: 75px;
    margin-right: 75px; }
  :not(.login-page) .pt-75 {
    padding-top: 75px; }
  :not(.login-page) .pb-75 {
    padding-bottom: 75px; }
  :not(.login-page) .pr-75 {
    padding-right: 75px; }
  :not(.login-page) .pl-75 {
    padding-left: 75px; }
  :not(.login-page) .px-75 {
    padding-left: 75px;
    padding-right: 75px; }
  :not(.login-page) .mt-80 {
    margin-top: 80px; }
  :not(.login-page) .mb-80 {
    margin-bottom: 80px; }
  :not(.login-page) .mr-80 {
    margin-right: 80px; }
  :not(.login-page) .ml-80 {
    margin-left: 80px; }
  :not(.login-page) .mx-80 {
    margin-left: 80px;
    margin-right: 80px; }
  :not(.login-page) .pt-80 {
    padding-top: 80px; }
  :not(.login-page) .pb-80 {
    padding-bottom: 80px; }
  :not(.login-page) .pr-80 {
    padding-right: 80px; }
  :not(.login-page) .pl-80 {
    padding-left: 80px; }
  :not(.login-page) .px-80 {
    padding-left: 80px;
    padding-right: 80px; }
  :not(.login-page) .mt-85 {
    margin-top: 85px; }
  :not(.login-page) .mb-85 {
    margin-bottom: 85px; }
  :not(.login-page) .mr-85 {
    margin-right: 85px; }
  :not(.login-page) .ml-85 {
    margin-left: 85px; }
  :not(.login-page) .mx-85 {
    margin-left: 85px;
    margin-right: 85px; }
  :not(.login-page) .pt-85 {
    padding-top: 85px; }
  :not(.login-page) .pb-85 {
    padding-bottom: 85px; }
  :not(.login-page) .pr-85 {
    padding-right: 85px; }
  :not(.login-page) .pl-85 {
    padding-left: 85px; }
  :not(.login-page) .px-85 {
    padding-left: 85px;
    padding-right: 85px; }
  :not(.login-page) .mt-90 {
    margin-top: 90px; }
  :not(.login-page) .mb-90 {
    margin-bottom: 90px; }
  :not(.login-page) .mr-90 {
    margin-right: 90px; }
  :not(.login-page) .ml-90 {
    margin-left: 90px; }
  :not(.login-page) .mx-90 {
    margin-left: 90px;
    margin-right: 90px; }
  :not(.login-page) .pt-90 {
    padding-top: 90px; }
  :not(.login-page) .pb-90 {
    padding-bottom: 90px; }
  :not(.login-page) .pr-90 {
    padding-right: 90px; }
  :not(.login-page) .pl-90 {
    padding-left: 90px; }
  :not(.login-page) .px-90 {
    padding-left: 90px;
    padding-right: 90px; }
  :not(.login-page) .mt-95 {
    margin-top: 95px; }
  :not(.login-page) .mb-95 {
    margin-bottom: 95px; }
  :not(.login-page) .mr-95 {
    margin-right: 95px; }
  :not(.login-page) .ml-95 {
    margin-left: 95px; }
  :not(.login-page) .mx-95 {
    margin-left: 95px;
    margin-right: 95px; }
  :not(.login-page) .pt-95 {
    padding-top: 95px; }
  :not(.login-page) .pb-95 {
    padding-bottom: 95px; }
  :not(.login-page) .pr-95 {
    padding-right: 95px; }
  :not(.login-page) .pl-95 {
    padding-left: 95px; }
  :not(.login-page) .px-95 {
    padding-left: 95px;
    padding-right: 95px; }
  :not(.login-page) .mt-100 {
    margin-top: 100px; }
  :not(.login-page) .mb-100 {
    margin-bottom: 100px; }
  :not(.login-page) .mr-100 {
    margin-right: 100px; }
  :not(.login-page) .ml-100 {
    margin-left: 100px; }
  :not(.login-page) .mx-100 {
    margin-left: 100px;
    margin-right: 100px; }
  :not(.login-page) .pt-100 {
    padding-top: 100px; }
  :not(.login-page) .pb-100 {
    padding-bottom: 100px; }
  :not(.login-page) .pr-100 {
    padding-right: 100px; }
  :not(.login-page) .pl-100 {
    padding-left: 100px; }
  :not(.login-page) .px-100 {
    padding-left: 100px;
    padding-right: 100px; }
  :not(.login-page) .mt-105 {
    margin-top: 105px; }
  :not(.login-page) .mb-105 {
    margin-bottom: 105px; }
  :not(.login-page) .mr-105 {
    margin-right: 105px; }
  :not(.login-page) .ml-105 {
    margin-left: 105px; }
  :not(.login-page) .mx-105 {
    margin-left: 105px;
    margin-right: 105px; }
  :not(.login-page) .pt-105 {
    padding-top: 105px; }
  :not(.login-page) .pb-105 {
    padding-bottom: 105px; }
  :not(.login-page) .pr-105 {
    padding-right: 105px; }
  :not(.login-page) .pl-105 {
    padding-left: 105px; }
  :not(.login-page) .px-105 {
    padding-left: 105px;
    padding-right: 105px; }
  :not(.login-page) .mt-110 {
    margin-top: 110px; }
  :not(.login-page) .mb-110 {
    margin-bottom: 110px; }
  :not(.login-page) .mr-110 {
    margin-right: 110px; }
  :not(.login-page) .ml-110 {
    margin-left: 110px; }
  :not(.login-page) .mx-110 {
    margin-left: 110px;
    margin-right: 110px; }
  :not(.login-page) .pt-110 {
    padding-top: 110px; }
  :not(.login-page) .pb-110 {
    padding-bottom: 110px; }
  :not(.login-page) .pr-110 {
    padding-right: 110px; }
  :not(.login-page) .pl-110 {
    padding-left: 110px; }
  :not(.login-page) .px-110 {
    padding-left: 110px;
    padding-right: 110px; }
  :not(.login-page) .mt-115 {
    margin-top: 115px; }
  :not(.login-page) .mb-115 {
    margin-bottom: 115px; }
  :not(.login-page) .mr-115 {
    margin-right: 115px; }
  :not(.login-page) .ml-115 {
    margin-left: 115px; }
  :not(.login-page) .mx-115 {
    margin-left: 115px;
    margin-right: 115px; }
  :not(.login-page) .pt-115 {
    padding-top: 115px; }
  :not(.login-page) .pb-115 {
    padding-bottom: 115px; }
  :not(.login-page) .pr-115 {
    padding-right: 115px; }
  :not(.login-page) .pl-115 {
    padding-left: 115px; }
  :not(.login-page) .px-115 {
    padding-left: 115px;
    padding-right: 115px; }
  :not(.login-page) .mt-120 {
    margin-top: 120px; }
  :not(.login-page) .mb-120 {
    margin-bottom: 120px; }
  :not(.login-page) .mr-120 {
    margin-right: 120px; }
  :not(.login-page) .ml-120 {
    margin-left: 120px; }
  :not(.login-page) .mx-120 {
    margin-left: 120px;
    margin-right: 120px; }
  :not(.login-page) .pt-120 {
    padding-top: 120px; }
  :not(.login-page) .pb-120 {
    padding-bottom: 120px; }
  :not(.login-page) .pr-120 {
    padding-right: 120px; }
  :not(.login-page) .pl-120 {
    padding-left: 120px; }
  :not(.login-page) .px-120 {
    padding-left: 120px;
    padding-right: 120px; }
  :not(.login-page) .mt-125 {
    margin-top: 125px; }
  :not(.login-page) .mb-125 {
    margin-bottom: 125px; }
  :not(.login-page) .mr-125 {
    margin-right: 125px; }
  :not(.login-page) .ml-125 {
    margin-left: 125px; }
  :not(.login-page) .mx-125 {
    margin-left: 125px;
    margin-right: 125px; }
  :not(.login-page) .pt-125 {
    padding-top: 125px; }
  :not(.login-page) .pb-125 {
    padding-bottom: 125px; }
  :not(.login-page) .pr-125 {
    padding-right: 125px; }
  :not(.login-page) .pl-125 {
    padding-left: 125px; }
  :not(.login-page) .px-125 {
    padding-left: 125px;
    padding-right: 125px; }
  :not(.login-page) .mt-130 {
    margin-top: 130px; }
  :not(.login-page) .mb-130 {
    margin-bottom: 130px; }
  :not(.login-page) .mr-130 {
    margin-right: 130px; }
  :not(.login-page) .ml-130 {
    margin-left: 130px; }
  :not(.login-page) .mx-130 {
    margin-left: 130px;
    margin-right: 130px; }
  :not(.login-page) .pt-130 {
    padding-top: 130px; }
  :not(.login-page) .pb-130 {
    padding-bottom: 130px; }
  :not(.login-page) .pr-130 {
    padding-right: 130px; }
  :not(.login-page) .pl-130 {
    padding-left: 130px; }
  :not(.login-page) .px-130 {
    padding-left: 130px;
    padding-right: 130px; }
  :not(.login-page) .mt-135 {
    margin-top: 135px; }
  :not(.login-page) .mb-135 {
    margin-bottom: 135px; }
  :not(.login-page) .mr-135 {
    margin-right: 135px; }
  :not(.login-page) .ml-135 {
    margin-left: 135px; }
  :not(.login-page) .mx-135 {
    margin-left: 135px;
    margin-right: 135px; }
  :not(.login-page) .pt-135 {
    padding-top: 135px; }
  :not(.login-page) .pb-135 {
    padding-bottom: 135px; }
  :not(.login-page) .pr-135 {
    padding-right: 135px; }
  :not(.login-page) .pl-135 {
    padding-left: 135px; }
  :not(.login-page) .px-135 {
    padding-left: 135px;
    padding-right: 135px; }
  :not(.login-page) .mt-140 {
    margin-top: 140px; }
  :not(.login-page) .mb-140 {
    margin-bottom: 140px; }
  :not(.login-page) .mr-140 {
    margin-right: 140px; }
  :not(.login-page) .ml-140 {
    margin-left: 140px; }
  :not(.login-page) .mx-140 {
    margin-left: 140px;
    margin-right: 140px; }
  :not(.login-page) .pt-140 {
    padding-top: 140px; }
  :not(.login-page) .pb-140 {
    padding-bottom: 140px; }
  :not(.login-page) .pr-140 {
    padding-right: 140px; }
  :not(.login-page) .pl-140 {
    padding-left: 140px; }
  :not(.login-page) .px-140 {
    padding-left: 140px;
    padding-right: 140px; }
  :not(.login-page) .mt-145 {
    margin-top: 145px; }
  :not(.login-page) .mb-145 {
    margin-bottom: 145px; }
  :not(.login-page) .mr-145 {
    margin-right: 145px; }
  :not(.login-page) .ml-145 {
    margin-left: 145px; }
  :not(.login-page) .mx-145 {
    margin-left: 145px;
    margin-right: 145px; }
  :not(.login-page) .pt-145 {
    padding-top: 145px; }
  :not(.login-page) .pb-145 {
    padding-bottom: 145px; }
  :not(.login-page) .pr-145 {
    padding-right: 145px; }
  :not(.login-page) .pl-145 {
    padding-left: 145px; }
  :not(.login-page) .px-145 {
    padding-left: 145px;
    padding-right: 145px; }
  :not(.login-page) .mt-150 {
    margin-top: 150px; }
  :not(.login-page) .mb-150 {
    margin-bottom: 150px; }
  :not(.login-page) .mr-150 {
    margin-right: 150px; }
  :not(.login-page) .ml-150 {
    margin-left: 150px; }
  :not(.login-page) .mx-150 {
    margin-left: 150px;
    margin-right: 150px; }
  :not(.login-page) .pt-150 {
    padding-top: 150px; }
  :not(.login-page) .pb-150 {
    padding-bottom: 150px; }
  :not(.login-page) .pr-150 {
    padding-right: 150px; }
  :not(.login-page) .pl-150 {
    padding-left: 150px; }
  :not(.login-page) .px-150 {
    padding-left: 150px;
    padding-right: 150px; }
  :not(.login-page) .mt-155 {
    margin-top: 155px; }
  :not(.login-page) .mb-155 {
    margin-bottom: 155px; }
  :not(.login-page) .mr-155 {
    margin-right: 155px; }
  :not(.login-page) .ml-155 {
    margin-left: 155px; }
  :not(.login-page) .mx-155 {
    margin-left: 155px;
    margin-right: 155px; }
  :not(.login-page) .pt-155 {
    padding-top: 155px; }
  :not(.login-page) .pb-155 {
    padding-bottom: 155px; }
  :not(.login-page) .pr-155 {
    padding-right: 155px; }
  :not(.login-page) .pl-155 {
    padding-left: 155px; }
  :not(.login-page) .px-155 {
    padding-left: 155px;
    padding-right: 155px; }
  :not(.login-page) .mt-160 {
    margin-top: 160px; }
  :not(.login-page) .mb-160 {
    margin-bottom: 160px; }
  :not(.login-page) .mr-160 {
    margin-right: 160px; }
  :not(.login-page) .ml-160 {
    margin-left: 160px; }
  :not(.login-page) .mx-160 {
    margin-left: 160px;
    margin-right: 160px; }
  :not(.login-page) .pt-160 {
    padding-top: 160px; }
  :not(.login-page) .pb-160 {
    padding-bottom: 160px; }
  :not(.login-page) .pr-160 {
    padding-right: 160px; }
  :not(.login-page) .pl-160 {
    padding-left: 160px; }
  :not(.login-page) .px-160 {
    padding-left: 160px;
    padding-right: 160px; }
  :not(.login-page) .mt-165 {
    margin-top: 165px; }
  :not(.login-page) .mb-165 {
    margin-bottom: 165px; }
  :not(.login-page) .mr-165 {
    margin-right: 165px; }
  :not(.login-page) .ml-165 {
    margin-left: 165px; }
  :not(.login-page) .mx-165 {
    margin-left: 165px;
    margin-right: 165px; }
  :not(.login-page) .pt-165 {
    padding-top: 165px; }
  :not(.login-page) .pb-165 {
    padding-bottom: 165px; }
  :not(.login-page) .pr-165 {
    padding-right: 165px; }
  :not(.login-page) .pl-165 {
    padding-left: 165px; }
  :not(.login-page) .px-165 {
    padding-left: 165px;
    padding-right: 165px; }
  :not(.login-page) .mt-170 {
    margin-top: 170px; }
  :not(.login-page) .mb-170 {
    margin-bottom: 170px; }
  :not(.login-page) .mr-170 {
    margin-right: 170px; }
  :not(.login-page) .ml-170 {
    margin-left: 170px; }
  :not(.login-page) .mx-170 {
    margin-left: 170px;
    margin-right: 170px; }
  :not(.login-page) .pt-170 {
    padding-top: 170px; }
  :not(.login-page) .pb-170 {
    padding-bottom: 170px; }
  :not(.login-page) .pr-170 {
    padding-right: 170px; }
  :not(.login-page) .pl-170 {
    padding-left: 170px; }
  :not(.login-page) .px-170 {
    padding-left: 170px;
    padding-right: 170px; }
  :not(.login-page) .mt-175 {
    margin-top: 175px; }
  :not(.login-page) .mb-175 {
    margin-bottom: 175px; }
  :not(.login-page) .mr-175 {
    margin-right: 175px; }
  :not(.login-page) .ml-175 {
    margin-left: 175px; }
  :not(.login-page) .mx-175 {
    margin-left: 175px;
    margin-right: 175px; }
  :not(.login-page) .pt-175 {
    padding-top: 175px; }
  :not(.login-page) .pb-175 {
    padding-bottom: 175px; }
  :not(.login-page) .pr-175 {
    padding-right: 175px; }
  :not(.login-page) .pl-175 {
    padding-left: 175px; }
  :not(.login-page) .px-175 {
    padding-left: 175px;
    padding-right: 175px; }
  :not(.login-page) .mt-180 {
    margin-top: 180px; }
  :not(.login-page) .mb-180 {
    margin-bottom: 180px; }
  :not(.login-page) .mr-180 {
    margin-right: 180px; }
  :not(.login-page) .ml-180 {
    margin-left: 180px; }
  :not(.login-page) .mx-180 {
    margin-left: 180px;
    margin-right: 180px; }
  :not(.login-page) .pt-180 {
    padding-top: 180px; }
  :not(.login-page) .pb-180 {
    padding-bottom: 180px; }
  :not(.login-page) .pr-180 {
    padding-right: 180px; }
  :not(.login-page) .pl-180 {
    padding-left: 180px; }
  :not(.login-page) .px-180 {
    padding-left: 180px;
    padding-right: 180px; }
  :not(.login-page) .mt-185 {
    margin-top: 185px; }
  :not(.login-page) .mb-185 {
    margin-bottom: 185px; }
  :not(.login-page) .mr-185 {
    margin-right: 185px; }
  :not(.login-page) .ml-185 {
    margin-left: 185px; }
  :not(.login-page) .mx-185 {
    margin-left: 185px;
    margin-right: 185px; }
  :not(.login-page) .pt-185 {
    padding-top: 185px; }
  :not(.login-page) .pb-185 {
    padding-bottom: 185px; }
  :not(.login-page) .pr-185 {
    padding-right: 185px; }
  :not(.login-page) .pl-185 {
    padding-left: 185px; }
  :not(.login-page) .px-185 {
    padding-left: 185px;
    padding-right: 185px; }
  :not(.login-page) .mt-190 {
    margin-top: 190px; }
  :not(.login-page) .mb-190 {
    margin-bottom: 190px; }
  :not(.login-page) .mr-190 {
    margin-right: 190px; }
  :not(.login-page) .ml-190 {
    margin-left: 190px; }
  :not(.login-page) .mx-190 {
    margin-left: 190px;
    margin-right: 190px; }
  :not(.login-page) .pt-190 {
    padding-top: 190px; }
  :not(.login-page) .pb-190 {
    padding-bottom: 190px; }
  :not(.login-page) .pr-190 {
    padding-right: 190px; }
  :not(.login-page) .pl-190 {
    padding-left: 190px; }
  :not(.login-page) .px-190 {
    padding-left: 190px;
    padding-right: 190px; }
  :not(.login-page) .mt-195 {
    margin-top: 195px; }
  :not(.login-page) .mb-195 {
    margin-bottom: 195px; }
  :not(.login-page) .mr-195 {
    margin-right: 195px; }
  :not(.login-page) .ml-195 {
    margin-left: 195px; }
  :not(.login-page) .mx-195 {
    margin-left: 195px;
    margin-right: 195px; }
  :not(.login-page) .pt-195 {
    padding-top: 195px; }
  :not(.login-page) .pb-195 {
    padding-bottom: 195px; }
  :not(.login-page) .pr-195 {
    padding-right: 195px; }
  :not(.login-page) .pl-195 {
    padding-left: 195px; }
  :not(.login-page) .px-195 {
    padding-left: 195px;
    padding-right: 195px; }
  :not(.login-page) .mt-200 {
    margin-top: 200px; }
  :not(.login-page) .mb-200 {
    margin-bottom: 200px; }
  :not(.login-page) .mr-200 {
    margin-right: 200px; }
  :not(.login-page) .ml-200 {
    margin-left: 200px; }
  :not(.login-page) .mx-200 {
    margin-left: 200px;
    margin-right: 200px; }
  :not(.login-page) .pt-200 {
    padding-top: 200px; }
  :not(.login-page) .pb-200 {
    padding-bottom: 200px; }
  :not(.login-page) .pr-200 {
    padding-right: 200px; }
  :not(.login-page) .pl-200 {
    padding-left: 200px; }
  :not(.login-page) .px-200 {
    padding-left: 200px;
    padding-right: 200px; }
  :not(.login-page) .mt-205 {
    margin-top: 205px; }
  :not(.login-page) .mb-205 {
    margin-bottom: 205px; }
  :not(.login-page) .mr-205 {
    margin-right: 205px; }
  :not(.login-page) .ml-205 {
    margin-left: 205px; }
  :not(.login-page) .mx-205 {
    margin-left: 205px;
    margin-right: 205px; }
  :not(.login-page) .pt-205 {
    padding-top: 205px; }
  :not(.login-page) .pb-205 {
    padding-bottom: 205px; }
  :not(.login-page) .pr-205 {
    padding-right: 205px; }
  :not(.login-page) .pl-205 {
    padding-left: 205px; }
  :not(.login-page) .px-205 {
    padding-left: 205px;
    padding-right: 205px; }
  :not(.login-page) .mt-210 {
    margin-top: 210px; }
  :not(.login-page) .mb-210 {
    margin-bottom: 210px; }
  :not(.login-page) .mr-210 {
    margin-right: 210px; }
  :not(.login-page) .ml-210 {
    margin-left: 210px; }
  :not(.login-page) .mx-210 {
    margin-left: 210px;
    margin-right: 210px; }
  :not(.login-page) .pt-210 {
    padding-top: 210px; }
  :not(.login-page) .pb-210 {
    padding-bottom: 210px; }
  :not(.login-page) .pr-210 {
    padding-right: 210px; }
  :not(.login-page) .pl-210 {
    padding-left: 210px; }
  :not(.login-page) .px-210 {
    padding-left: 210px;
    padding-right: 210px; }
  :not(.login-page) .mt-215 {
    margin-top: 215px; }
  :not(.login-page) .mb-215 {
    margin-bottom: 215px; }
  :not(.login-page) .mr-215 {
    margin-right: 215px; }
  :not(.login-page) .ml-215 {
    margin-left: 215px; }
  :not(.login-page) .mx-215 {
    margin-left: 215px;
    margin-right: 215px; }
  :not(.login-page) .pt-215 {
    padding-top: 215px; }
  :not(.login-page) .pb-215 {
    padding-bottom: 215px; }
  :not(.login-page) .pr-215 {
    padding-right: 215px; }
  :not(.login-page) .pl-215 {
    padding-left: 215px; }
  :not(.login-page) .px-215 {
    padding-left: 215px;
    padding-right: 215px; }
  :not(.login-page) .mt-220 {
    margin-top: 220px; }
  :not(.login-page) .mb-220 {
    margin-bottom: 220px; }
  :not(.login-page) .mr-220 {
    margin-right: 220px; }
  :not(.login-page) .ml-220 {
    margin-left: 220px; }
  :not(.login-page) .mx-220 {
    margin-left: 220px;
    margin-right: 220px; }
  :not(.login-page) .pt-220 {
    padding-top: 220px; }
  :not(.login-page) .pb-220 {
    padding-bottom: 220px; }
  :not(.login-page) .pr-220 {
    padding-right: 220px; }
  :not(.login-page) .pl-220 {
    padding-left: 220px; }
  :not(.login-page) .px-220 {
    padding-left: 220px;
    padding-right: 220px; }
  :not(.login-page) .mt-225 {
    margin-top: 225px; }
  :not(.login-page) .mb-225 {
    margin-bottom: 225px; }
  :not(.login-page) .mr-225 {
    margin-right: 225px; }
  :not(.login-page) .ml-225 {
    margin-left: 225px; }
  :not(.login-page) .mx-225 {
    margin-left: 225px;
    margin-right: 225px; }
  :not(.login-page) .pt-225 {
    padding-top: 225px; }
  :not(.login-page) .pb-225 {
    padding-bottom: 225px; }
  :not(.login-page) .pr-225 {
    padding-right: 225px; }
  :not(.login-page) .pl-225 {
    padding-left: 225px; }
  :not(.login-page) .px-225 {
    padding-left: 225px;
    padding-right: 225px; }
  :not(.login-page) .mt-230 {
    margin-top: 230px; }
  :not(.login-page) .mb-230 {
    margin-bottom: 230px; }
  :not(.login-page) .mr-230 {
    margin-right: 230px; }
  :not(.login-page) .ml-230 {
    margin-left: 230px; }
  :not(.login-page) .mx-230 {
    margin-left: 230px;
    margin-right: 230px; }
  :not(.login-page) .pt-230 {
    padding-top: 230px; }
  :not(.login-page) .pb-230 {
    padding-bottom: 230px; }
  :not(.login-page) .pr-230 {
    padding-right: 230px; }
  :not(.login-page) .pl-230 {
    padding-left: 230px; }
  :not(.login-page) .px-230 {
    padding-left: 230px;
    padding-right: 230px; }
  :not(.login-page) .mt-235 {
    margin-top: 235px; }
  :not(.login-page) .mb-235 {
    margin-bottom: 235px; }
  :not(.login-page) .mr-235 {
    margin-right: 235px; }
  :not(.login-page) .ml-235 {
    margin-left: 235px; }
  :not(.login-page) .mx-235 {
    margin-left: 235px;
    margin-right: 235px; }
  :not(.login-page) .pt-235 {
    padding-top: 235px; }
  :not(.login-page) .pb-235 {
    padding-bottom: 235px; }
  :not(.login-page) .pr-235 {
    padding-right: 235px; }
  :not(.login-page) .pl-235 {
    padding-left: 235px; }
  :not(.login-page) .px-235 {
    padding-left: 235px;
    padding-right: 235px; }
  :not(.login-page) .mt-240 {
    margin-top: 240px; }
  :not(.login-page) .mb-240 {
    margin-bottom: 240px; }
  :not(.login-page) .mr-240 {
    margin-right: 240px; }
  :not(.login-page) .ml-240 {
    margin-left: 240px; }
  :not(.login-page) .mx-240 {
    margin-left: 240px;
    margin-right: 240px; }
  :not(.login-page) .pt-240 {
    padding-top: 240px; }
  :not(.login-page) .pb-240 {
    padding-bottom: 240px; }
  :not(.login-page) .pr-240 {
    padding-right: 240px; }
  :not(.login-page) .pl-240 {
    padding-left: 240px; }
  :not(.login-page) .px-240 {
    padding-left: 240px;
    padding-right: 240px; }
  :not(.login-page) .mt-245 {
    margin-top: 245px; }
  :not(.login-page) .mb-245 {
    margin-bottom: 245px; }
  :not(.login-page) .mr-245 {
    margin-right: 245px; }
  :not(.login-page) .ml-245 {
    margin-left: 245px; }
  :not(.login-page) .mx-245 {
    margin-left: 245px;
    margin-right: 245px; }
  :not(.login-page) .pt-245 {
    padding-top: 245px; }
  :not(.login-page) .pb-245 {
    padding-bottom: 245px; }
  :not(.login-page) .pr-245 {
    padding-right: 245px; }
  :not(.login-page) .pl-245 {
    padding-left: 245px; }
  :not(.login-page) .px-245 {
    padding-left: 245px;
    padding-right: 245px; }
  :not(.login-page) .mt-250 {
    margin-top: 250px; }
  :not(.login-page) .mb-250 {
    margin-bottom: 250px; }
  :not(.login-page) .mr-250 {
    margin-right: 250px; }
  :not(.login-page) .ml-250 {
    margin-left: 250px; }
  :not(.login-page) .mx-250 {
    margin-left: 250px;
    margin-right: 250px; }
  :not(.login-page) .pt-250 {
    padding-top: 250px; }
  :not(.login-page) .pb-250 {
    padding-bottom: 250px; }
  :not(.login-page) .pr-250 {
    padding-right: 250px; }
  :not(.login-page) .pl-250 {
    padding-left: 250px; }
  :not(.login-page) .px-250 {
    padding-left: 250px;
    padding-right: 250px; }
  :not(.login-page) .mt-255 {
    margin-top: 255px; }
  :not(.login-page) .mb-255 {
    margin-bottom: 255px; }
  :not(.login-page) .mr-255 {
    margin-right: 255px; }
  :not(.login-page) .ml-255 {
    margin-left: 255px; }
  :not(.login-page) .mx-255 {
    margin-left: 255px;
    margin-right: 255px; }
  :not(.login-page) .pt-255 {
    padding-top: 255px; }
  :not(.login-page) .pb-255 {
    padding-bottom: 255px; }
  :not(.login-page) .pr-255 {
    padding-right: 255px; }
  :not(.login-page) .pl-255 {
    padding-left: 255px; }
  :not(.login-page) .px-255 {
    padding-left: 255px;
    padding-right: 255px; }
  :not(.login-page) .mt-260 {
    margin-top: 260px; }
  :not(.login-page) .mb-260 {
    margin-bottom: 260px; }
  :not(.login-page) .mr-260 {
    margin-right: 260px; }
  :not(.login-page) .ml-260 {
    margin-left: 260px; }
  :not(.login-page) .mx-260 {
    margin-left: 260px;
    margin-right: 260px; }
  :not(.login-page) .pt-260 {
    padding-top: 260px; }
  :not(.login-page) .pb-260 {
    padding-bottom: 260px; }
  :not(.login-page) .pr-260 {
    padding-right: 260px; }
  :not(.login-page) .pl-260 {
    padding-left: 260px; }
  :not(.login-page) .px-260 {
    padding-left: 260px;
    padding-right: 260px; }
  :not(.login-page) .mt-265 {
    margin-top: 265px; }
  :not(.login-page) .mb-265 {
    margin-bottom: 265px; }
  :not(.login-page) .mr-265 {
    margin-right: 265px; }
  :not(.login-page) .ml-265 {
    margin-left: 265px; }
  :not(.login-page) .mx-265 {
    margin-left: 265px;
    margin-right: 265px; }
  :not(.login-page) .pt-265 {
    padding-top: 265px; }
  :not(.login-page) .pb-265 {
    padding-bottom: 265px; }
  :not(.login-page) .pr-265 {
    padding-right: 265px; }
  :not(.login-page) .pl-265 {
    padding-left: 265px; }
  :not(.login-page) .px-265 {
    padding-left: 265px;
    padding-right: 265px; }
  :not(.login-page) .mt-270 {
    margin-top: 270px; }
  :not(.login-page) .mb-270 {
    margin-bottom: 270px; }
  :not(.login-page) .mr-270 {
    margin-right: 270px; }
  :not(.login-page) .ml-270 {
    margin-left: 270px; }
  :not(.login-page) .mx-270 {
    margin-left: 270px;
    margin-right: 270px; }
  :not(.login-page) .pt-270 {
    padding-top: 270px; }
  :not(.login-page) .pb-270 {
    padding-bottom: 270px; }
  :not(.login-page) .pr-270 {
    padding-right: 270px; }
  :not(.login-page) .pl-270 {
    padding-left: 270px; }
  :not(.login-page) .px-270 {
    padding-left: 270px;
    padding-right: 270px; }
  :not(.login-page) .mt-275 {
    margin-top: 275px; }
  :not(.login-page) .mb-275 {
    margin-bottom: 275px; }
  :not(.login-page) .mr-275 {
    margin-right: 275px; }
  :not(.login-page) .ml-275 {
    margin-left: 275px; }
  :not(.login-page) .mx-275 {
    margin-left: 275px;
    margin-right: 275px; }
  :not(.login-page) .pt-275 {
    padding-top: 275px; }
  :not(.login-page) .pb-275 {
    padding-bottom: 275px; }
  :not(.login-page) .pr-275 {
    padding-right: 275px; }
  :not(.login-page) .pl-275 {
    padding-left: 275px; }
  :not(.login-page) .px-275 {
    padding-left: 275px;
    padding-right: 275px; }
  :not(.login-page) .mt-280 {
    margin-top: 280px; }
  :not(.login-page) .mb-280 {
    margin-bottom: 280px; }
  :not(.login-page) .mr-280 {
    margin-right: 280px; }
  :not(.login-page) .ml-280 {
    margin-left: 280px; }
  :not(.login-page) .mx-280 {
    margin-left: 280px;
    margin-right: 280px; }
  :not(.login-page) .pt-280 {
    padding-top: 280px; }
  :not(.login-page) .pb-280 {
    padding-bottom: 280px; }
  :not(.login-page) .pr-280 {
    padding-right: 280px; }
  :not(.login-page) .pl-280 {
    padding-left: 280px; }
  :not(.login-page) .px-280 {
    padding-left: 280px;
    padding-right: 280px; }
  :not(.login-page) .mt-285 {
    margin-top: 285px; }
  :not(.login-page) .mb-285 {
    margin-bottom: 285px; }
  :not(.login-page) .mr-285 {
    margin-right: 285px; }
  :not(.login-page) .ml-285 {
    margin-left: 285px; }
  :not(.login-page) .mx-285 {
    margin-left: 285px;
    margin-right: 285px; }
  :not(.login-page) .pt-285 {
    padding-top: 285px; }
  :not(.login-page) .pb-285 {
    padding-bottom: 285px; }
  :not(.login-page) .pr-285 {
    padding-right: 285px; }
  :not(.login-page) .pl-285 {
    padding-left: 285px; }
  :not(.login-page) .px-285 {
    padding-left: 285px;
    padding-right: 285px; }
  :not(.login-page) .mt-290 {
    margin-top: 290px; }
  :not(.login-page) .mb-290 {
    margin-bottom: 290px; }
  :not(.login-page) .mr-290 {
    margin-right: 290px; }
  :not(.login-page) .ml-290 {
    margin-left: 290px; }
  :not(.login-page) .mx-290 {
    margin-left: 290px;
    margin-right: 290px; }
  :not(.login-page) .pt-290 {
    padding-top: 290px; }
  :not(.login-page) .pb-290 {
    padding-bottom: 290px; }
  :not(.login-page) .pr-290 {
    padding-right: 290px; }
  :not(.login-page) .pl-290 {
    padding-left: 290px; }
  :not(.login-page) .px-290 {
    padding-left: 290px;
    padding-right: 290px; }
  :not(.login-page) .mt-295 {
    margin-top: 295px; }
  :not(.login-page) .mb-295 {
    margin-bottom: 295px; }
  :not(.login-page) .mr-295 {
    margin-right: 295px; }
  :not(.login-page) .ml-295 {
    margin-left: 295px; }
  :not(.login-page) .mx-295 {
    margin-left: 295px;
    margin-right: 295px; }
  :not(.login-page) .pt-295 {
    padding-top: 295px; }
  :not(.login-page) .pb-295 {
    padding-bottom: 295px; }
  :not(.login-page) .pr-295 {
    padding-right: 295px; }
  :not(.login-page) .pl-295 {
    padding-left: 295px; }
  :not(.login-page) .px-295 {
    padding-left: 295px;
    padding-right: 295px; }
  :not(.login-page) .mt-300 {
    margin-top: 300px; }
  :not(.login-page) .mb-300 {
    margin-bottom: 300px; }
  :not(.login-page) .mr-300 {
    margin-right: 300px; }
  :not(.login-page) .ml-300 {
    margin-left: 300px; }
  :not(.login-page) .mx-300 {
    margin-left: 300px;
    margin-right: 300px; }
  :not(.login-page) .pt-300 {
    padding-top: 300px; }
  :not(.login-page) .pb-300 {
    padding-bottom: 300px; }
  :not(.login-page) .pr-300 {
    padding-right: 300px; }
  :not(.login-page) .pl-300 {
    padding-left: 300px; }
  :not(.login-page) .px-300 {
    padding-left: 300px;
    padding-right: 300px; }
  :not(.login-page) .mt-305 {
    margin-top: 305px; }
  :not(.login-page) .mb-305 {
    margin-bottom: 305px; }
  :not(.login-page) .mr-305 {
    margin-right: 305px; }
  :not(.login-page) .ml-305 {
    margin-left: 305px; }
  :not(.login-page) .mx-305 {
    margin-left: 305px;
    margin-right: 305px; }
  :not(.login-page) .pt-305 {
    padding-top: 305px; }
  :not(.login-page) .pb-305 {
    padding-bottom: 305px; }
  :not(.login-page) .pr-305 {
    padding-right: 305px; }
  :not(.login-page) .pl-305 {
    padding-left: 305px; }
  :not(.login-page) .px-305 {
    padding-left: 305px;
    padding-right: 305px; }
  :not(.login-page) .mt-310 {
    margin-top: 310px; }
  :not(.login-page) .mb-310 {
    margin-bottom: 310px; }
  :not(.login-page) .mr-310 {
    margin-right: 310px; }
  :not(.login-page) .ml-310 {
    margin-left: 310px; }
  :not(.login-page) .mx-310 {
    margin-left: 310px;
    margin-right: 310px; }
  :not(.login-page) .pt-310 {
    padding-top: 310px; }
  :not(.login-page) .pb-310 {
    padding-bottom: 310px; }
  :not(.login-page) .pr-310 {
    padding-right: 310px; }
  :not(.login-page) .pl-310 {
    padding-left: 310px; }
  :not(.login-page) .px-310 {
    padding-left: 310px;
    padding-right: 310px; }
  :not(.login-page) .mt-315 {
    margin-top: 315px; }
  :not(.login-page) .mb-315 {
    margin-bottom: 315px; }
  :not(.login-page) .mr-315 {
    margin-right: 315px; }
  :not(.login-page) .ml-315 {
    margin-left: 315px; }
  :not(.login-page) .mx-315 {
    margin-left: 315px;
    margin-right: 315px; }
  :not(.login-page) .pt-315 {
    padding-top: 315px; }
  :not(.login-page) .pb-315 {
    padding-bottom: 315px; }
  :not(.login-page) .pr-315 {
    padding-right: 315px; }
  :not(.login-page) .pl-315 {
    padding-left: 315px; }
  :not(.login-page) .px-315 {
    padding-left: 315px;
    padding-right: 315px; }
  :not(.login-page) .mt-320 {
    margin-top: 320px; }
  :not(.login-page) .mb-320 {
    margin-bottom: 320px; }
  :not(.login-page) .mr-320 {
    margin-right: 320px; }
  :not(.login-page) .ml-320 {
    margin-left: 320px; }
  :not(.login-page) .mx-320 {
    margin-left: 320px;
    margin-right: 320px; }
  :not(.login-page) .pt-320 {
    padding-top: 320px; }
  :not(.login-page) .pb-320 {
    padding-bottom: 320px; }
  :not(.login-page) .pr-320 {
    padding-right: 320px; }
  :not(.login-page) .pl-320 {
    padding-left: 320px; }
  :not(.login-page) .px-320 {
    padding-left: 320px;
    padding-right: 320px; }
  :not(.login-page) .mt-325 {
    margin-top: 325px; }
  :not(.login-page) .mb-325 {
    margin-bottom: 325px; }
  :not(.login-page) .mr-325 {
    margin-right: 325px; }
  :not(.login-page) .ml-325 {
    margin-left: 325px; }
  :not(.login-page) .mx-325 {
    margin-left: 325px;
    margin-right: 325px; }
  :not(.login-page) .pt-325 {
    padding-top: 325px; }
  :not(.login-page) .pb-325 {
    padding-bottom: 325px; }
  :not(.login-page) .pr-325 {
    padding-right: 325px; }
  :not(.login-page) .pl-325 {
    padding-left: 325px; }
  :not(.login-page) .px-325 {
    padding-left: 325px;
    padding-right: 325px; }
  :not(.login-page) .mt-330 {
    margin-top: 330px; }
  :not(.login-page) .mb-330 {
    margin-bottom: 330px; }
  :not(.login-page) .mr-330 {
    margin-right: 330px; }
  :not(.login-page) .ml-330 {
    margin-left: 330px; }
  :not(.login-page) .mx-330 {
    margin-left: 330px;
    margin-right: 330px; }
  :not(.login-page) .pt-330 {
    padding-top: 330px; }
  :not(.login-page) .pb-330 {
    padding-bottom: 330px; }
  :not(.login-page) .pr-330 {
    padding-right: 330px; }
  :not(.login-page) .pl-330 {
    padding-left: 330px; }
  :not(.login-page) .px-330 {
    padding-left: 330px;
    padding-right: 330px; }
  :not(.login-page) .mt-335 {
    margin-top: 335px; }
  :not(.login-page) .mb-335 {
    margin-bottom: 335px; }
  :not(.login-page) .mr-335 {
    margin-right: 335px; }
  :not(.login-page) .ml-335 {
    margin-left: 335px; }
  :not(.login-page) .mx-335 {
    margin-left: 335px;
    margin-right: 335px; }
  :not(.login-page) .pt-335 {
    padding-top: 335px; }
  :not(.login-page) .pb-335 {
    padding-bottom: 335px; }
  :not(.login-page) .pr-335 {
    padding-right: 335px; }
  :not(.login-page) .pl-335 {
    padding-left: 335px; }
  :not(.login-page) .px-335 {
    padding-left: 335px;
    padding-right: 335px; }
  :not(.login-page) .mt-340 {
    margin-top: 340px; }
  :not(.login-page) .mb-340 {
    margin-bottom: 340px; }
  :not(.login-page) .mr-340 {
    margin-right: 340px; }
  :not(.login-page) .ml-340 {
    margin-left: 340px; }
  :not(.login-page) .mx-340 {
    margin-left: 340px;
    margin-right: 340px; }
  :not(.login-page) .pt-340 {
    padding-top: 340px; }
  :not(.login-page) .pb-340 {
    padding-bottom: 340px; }
  :not(.login-page) .pr-340 {
    padding-right: 340px; }
  :not(.login-page) .pl-340 {
    padding-left: 340px; }
  :not(.login-page) .px-340 {
    padding-left: 340px;
    padding-right: 340px; }
  :not(.login-page) .mt-345 {
    margin-top: 345px; }
  :not(.login-page) .mb-345 {
    margin-bottom: 345px; }
  :not(.login-page) .mr-345 {
    margin-right: 345px; }
  :not(.login-page) .ml-345 {
    margin-left: 345px; }
  :not(.login-page) .mx-345 {
    margin-left: 345px;
    margin-right: 345px; }
  :not(.login-page) .pt-345 {
    padding-top: 345px; }
  :not(.login-page) .pb-345 {
    padding-bottom: 345px; }
  :not(.login-page) .pr-345 {
    padding-right: 345px; }
  :not(.login-page) .pl-345 {
    padding-left: 345px; }
  :not(.login-page) .px-345 {
    padding-left: 345px;
    padding-right: 345px; }
  :not(.login-page) .mt-350 {
    margin-top: 350px; }
  :not(.login-page) .mb-350 {
    margin-bottom: 350px; }
  :not(.login-page) .mr-350 {
    margin-right: 350px; }
  :not(.login-page) .ml-350 {
    margin-left: 350px; }
  :not(.login-page) .mx-350 {
    margin-left: 350px;
    margin-right: 350px; }
  :not(.login-page) .pt-350 {
    padding-top: 350px; }
  :not(.login-page) .pb-350 {
    padding-bottom: 350px; }
  :not(.login-page) .pr-350 {
    padding-right: 350px; }
  :not(.login-page) .pl-350 {
    padding-left: 350px; }
  :not(.login-page) .px-350 {
    padding-left: 350px;
    padding-right: 350px; }
  :not(.login-page) .mt-355 {
    margin-top: 355px; }
  :not(.login-page) .mb-355 {
    margin-bottom: 355px; }
  :not(.login-page) .mr-355 {
    margin-right: 355px; }
  :not(.login-page) .ml-355 {
    margin-left: 355px; }
  :not(.login-page) .mx-355 {
    margin-left: 355px;
    margin-right: 355px; }
  :not(.login-page) .pt-355 {
    padding-top: 355px; }
  :not(.login-page) .pb-355 {
    padding-bottom: 355px; }
  :not(.login-page) .pr-355 {
    padding-right: 355px; }
  :not(.login-page) .pl-355 {
    padding-left: 355px; }
  :not(.login-page) .px-355 {
    padding-left: 355px;
    padding-right: 355px; }
  :not(.login-page) .mt-360 {
    margin-top: 360px; }
  :not(.login-page) .mb-360 {
    margin-bottom: 360px; }
  :not(.login-page) .mr-360 {
    margin-right: 360px; }
  :not(.login-page) .ml-360 {
    margin-left: 360px; }
  :not(.login-page) .mx-360 {
    margin-left: 360px;
    margin-right: 360px; }
  :not(.login-page) .pt-360 {
    padding-top: 360px; }
  :not(.login-page) .pb-360 {
    padding-bottom: 360px; }
  :not(.login-page) .pr-360 {
    padding-right: 360px; }
  :not(.login-page) .pl-360 {
    padding-left: 360px; }
  :not(.login-page) .px-360 {
    padding-left: 360px;
    padding-right: 360px; }
  :not(.login-page) .mt-365 {
    margin-top: 365px; }
  :not(.login-page) .mb-365 {
    margin-bottom: 365px; }
  :not(.login-page) .mr-365 {
    margin-right: 365px; }
  :not(.login-page) .ml-365 {
    margin-left: 365px; }
  :not(.login-page) .mx-365 {
    margin-left: 365px;
    margin-right: 365px; }
  :not(.login-page) .pt-365 {
    padding-top: 365px; }
  :not(.login-page) .pb-365 {
    padding-bottom: 365px; }
  :not(.login-page) .pr-365 {
    padding-right: 365px; }
  :not(.login-page) .pl-365 {
    padding-left: 365px; }
  :not(.login-page) .px-365 {
    padding-left: 365px;
    padding-right: 365px; }
  :not(.login-page) .mt-370 {
    margin-top: 370px; }
  :not(.login-page) .mb-370 {
    margin-bottom: 370px; }
  :not(.login-page) .mr-370 {
    margin-right: 370px; }
  :not(.login-page) .ml-370 {
    margin-left: 370px; }
  :not(.login-page) .mx-370 {
    margin-left: 370px;
    margin-right: 370px; }
  :not(.login-page) .pt-370 {
    padding-top: 370px; }
  :not(.login-page) .pb-370 {
    padding-bottom: 370px; }
  :not(.login-page) .pr-370 {
    padding-right: 370px; }
  :not(.login-page) .pl-370 {
    padding-left: 370px; }
  :not(.login-page) .px-370 {
    padding-left: 370px;
    padding-right: 370px; }
  :not(.login-page) .mt-375 {
    margin-top: 375px; }
  :not(.login-page) .mb-375 {
    margin-bottom: 375px; }
  :not(.login-page) .mr-375 {
    margin-right: 375px; }
  :not(.login-page) .ml-375 {
    margin-left: 375px; }
  :not(.login-page) .mx-375 {
    margin-left: 375px;
    margin-right: 375px; }
  :not(.login-page) .pt-375 {
    padding-top: 375px; }
  :not(.login-page) .pb-375 {
    padding-bottom: 375px; }
  :not(.login-page) .pr-375 {
    padding-right: 375px; }
  :not(.login-page) .pl-375 {
    padding-left: 375px; }
  :not(.login-page) .px-375 {
    padding-left: 375px;
    padding-right: 375px; }
  :not(.login-page) .mt-380 {
    margin-top: 380px; }
  :not(.login-page) .mb-380 {
    margin-bottom: 380px; }
  :not(.login-page) .mr-380 {
    margin-right: 380px; }
  :not(.login-page) .ml-380 {
    margin-left: 380px; }
  :not(.login-page) .mx-380 {
    margin-left: 380px;
    margin-right: 380px; }
  :not(.login-page) .pt-380 {
    padding-top: 380px; }
  :not(.login-page) .pb-380 {
    padding-bottom: 380px; }
  :not(.login-page) .pr-380 {
    padding-right: 380px; }
  :not(.login-page) .pl-380 {
    padding-left: 380px; }
  :not(.login-page) .px-380 {
    padding-left: 380px;
    padding-right: 380px; }
  :not(.login-page) .mt-385 {
    margin-top: 385px; }
  :not(.login-page) .mb-385 {
    margin-bottom: 385px; }
  :not(.login-page) .mr-385 {
    margin-right: 385px; }
  :not(.login-page) .ml-385 {
    margin-left: 385px; }
  :not(.login-page) .mx-385 {
    margin-left: 385px;
    margin-right: 385px; }
  :not(.login-page) .pt-385 {
    padding-top: 385px; }
  :not(.login-page) .pb-385 {
    padding-bottom: 385px; }
  :not(.login-page) .pr-385 {
    padding-right: 385px; }
  :not(.login-page) .pl-385 {
    padding-left: 385px; }
  :not(.login-page) .px-385 {
    padding-left: 385px;
    padding-right: 385px; }
  :not(.login-page) .mt-390 {
    margin-top: 390px; }
  :not(.login-page) .mb-390 {
    margin-bottom: 390px; }
  :not(.login-page) .mr-390 {
    margin-right: 390px; }
  :not(.login-page) .ml-390 {
    margin-left: 390px; }
  :not(.login-page) .mx-390 {
    margin-left: 390px;
    margin-right: 390px; }
  :not(.login-page) .pt-390 {
    padding-top: 390px; }
  :not(.login-page) .pb-390 {
    padding-bottom: 390px; }
  :not(.login-page) .pr-390 {
    padding-right: 390px; }
  :not(.login-page) .pl-390 {
    padding-left: 390px; }
  :not(.login-page) .px-390 {
    padding-left: 390px;
    padding-right: 390px; }
  :not(.login-page) .mt-395 {
    margin-top: 395px; }
  :not(.login-page) .mb-395 {
    margin-bottom: 395px; }
  :not(.login-page) .mr-395 {
    margin-right: 395px; }
  :not(.login-page) .ml-395 {
    margin-left: 395px; }
  :not(.login-page) .mx-395 {
    margin-left: 395px;
    margin-right: 395px; }
  :not(.login-page) .pt-395 {
    padding-top: 395px; }
  :not(.login-page) .pb-395 {
    padding-bottom: 395px; }
  :not(.login-page) .pr-395 {
    padding-right: 395px; }
  :not(.login-page) .pl-395 {
    padding-left: 395px; }
  :not(.login-page) .px-395 {
    padding-left: 395px;
    padding-right: 395px; }
  :not(.login-page) .mt-400 {
    margin-top: 400px; }
  :not(.login-page) .mb-400 {
    margin-bottom: 400px; }
  :not(.login-page) .mr-400 {
    margin-right: 400px; }
  :not(.login-page) .ml-400 {
    margin-left: 400px; }
  :not(.login-page) .mx-400 {
    margin-left: 400px;
    margin-right: 400px; }
  :not(.login-page) .pt-400 {
    padding-top: 400px; }
  :not(.login-page) .pb-400 {
    padding-bottom: 400px; }
  :not(.login-page) .pr-400 {
    padding-right: 400px; }
  :not(.login-page) .pl-400 {
    padding-left: 400px; }
  :not(.login-page) .px-400 {
    padding-left: 400px;
    padding-right: 400px; }
  :not(.login-page) .mt-405 {
    margin-top: 405px; }
  :not(.login-page) .mb-405 {
    margin-bottom: 405px; }
  :not(.login-page) .mr-405 {
    margin-right: 405px; }
  :not(.login-page) .ml-405 {
    margin-left: 405px; }
  :not(.login-page) .mx-405 {
    margin-left: 405px;
    margin-right: 405px; }
  :not(.login-page) .pt-405 {
    padding-top: 405px; }
  :not(.login-page) .pb-405 {
    padding-bottom: 405px; }
  :not(.login-page) .pr-405 {
    padding-right: 405px; }
  :not(.login-page) .pl-405 {
    padding-left: 405px; }
  :not(.login-page) .px-405 {
    padding-left: 405px;
    padding-right: 405px; }
  :not(.login-page) .mt-410 {
    margin-top: 410px; }
  :not(.login-page) .mb-410 {
    margin-bottom: 410px; }
  :not(.login-page) .mr-410 {
    margin-right: 410px; }
  :not(.login-page) .ml-410 {
    margin-left: 410px; }
  :not(.login-page) .mx-410 {
    margin-left: 410px;
    margin-right: 410px; }
  :not(.login-page) .pt-410 {
    padding-top: 410px; }
  :not(.login-page) .pb-410 {
    padding-bottom: 410px; }
  :not(.login-page) .pr-410 {
    padding-right: 410px; }
  :not(.login-page) .pl-410 {
    padding-left: 410px; }
  :not(.login-page) .px-410 {
    padding-left: 410px;
    padding-right: 410px; }
  :not(.login-page) .mt-415 {
    margin-top: 415px; }
  :not(.login-page) .mb-415 {
    margin-bottom: 415px; }
  :not(.login-page) .mr-415 {
    margin-right: 415px; }
  :not(.login-page) .ml-415 {
    margin-left: 415px; }
  :not(.login-page) .mx-415 {
    margin-left: 415px;
    margin-right: 415px; }
  :not(.login-page) .pt-415 {
    padding-top: 415px; }
  :not(.login-page) .pb-415 {
    padding-bottom: 415px; }
  :not(.login-page) .pr-415 {
    padding-right: 415px; }
  :not(.login-page) .pl-415 {
    padding-left: 415px; }
  :not(.login-page) .px-415 {
    padding-left: 415px;
    padding-right: 415px; }
  :not(.login-page) .mt-420 {
    margin-top: 420px; }
  :not(.login-page) .mb-420 {
    margin-bottom: 420px; }
  :not(.login-page) .mr-420 {
    margin-right: 420px; }
  :not(.login-page) .ml-420 {
    margin-left: 420px; }
  :not(.login-page) .mx-420 {
    margin-left: 420px;
    margin-right: 420px; }
  :not(.login-page) .pt-420 {
    padding-top: 420px; }
  :not(.login-page) .pb-420 {
    padding-bottom: 420px; }
  :not(.login-page) .pr-420 {
    padding-right: 420px; }
  :not(.login-page) .pl-420 {
    padding-left: 420px; }
  :not(.login-page) .px-420 {
    padding-left: 420px;
    padding-right: 420px; }
  :not(.login-page) .mt-425 {
    margin-top: 425px; }
  :not(.login-page) .mb-425 {
    margin-bottom: 425px; }
  :not(.login-page) .mr-425 {
    margin-right: 425px; }
  :not(.login-page) .ml-425 {
    margin-left: 425px; }
  :not(.login-page) .mx-425 {
    margin-left: 425px;
    margin-right: 425px; }
  :not(.login-page) .pt-425 {
    padding-top: 425px; }
  :not(.login-page) .pb-425 {
    padding-bottom: 425px; }
  :not(.login-page) .pr-425 {
    padding-right: 425px; }
  :not(.login-page) .pl-425 {
    padding-left: 425px; }
  :not(.login-page) .px-425 {
    padding-left: 425px;
    padding-right: 425px; }
  :not(.login-page) .mt-430 {
    margin-top: 430px; }
  :not(.login-page) .mb-430 {
    margin-bottom: 430px; }
  :not(.login-page) .mr-430 {
    margin-right: 430px; }
  :not(.login-page) .ml-430 {
    margin-left: 430px; }
  :not(.login-page) .mx-430 {
    margin-left: 430px;
    margin-right: 430px; }
  :not(.login-page) .pt-430 {
    padding-top: 430px; }
  :not(.login-page) .pb-430 {
    padding-bottom: 430px; }
  :not(.login-page) .pr-430 {
    padding-right: 430px; }
  :not(.login-page) .pl-430 {
    padding-left: 430px; }
  :not(.login-page) .px-430 {
    padding-left: 430px;
    padding-right: 430px; }
  :not(.login-page) .mt-435 {
    margin-top: 435px; }
  :not(.login-page) .mb-435 {
    margin-bottom: 435px; }
  :not(.login-page) .mr-435 {
    margin-right: 435px; }
  :not(.login-page) .ml-435 {
    margin-left: 435px; }
  :not(.login-page) .mx-435 {
    margin-left: 435px;
    margin-right: 435px; }
  :not(.login-page) .pt-435 {
    padding-top: 435px; }
  :not(.login-page) .pb-435 {
    padding-bottom: 435px; }
  :not(.login-page) .pr-435 {
    padding-right: 435px; }
  :not(.login-page) .pl-435 {
    padding-left: 435px; }
  :not(.login-page) .px-435 {
    padding-left: 435px;
    padding-right: 435px; }
  :not(.login-page) .mt-440 {
    margin-top: 440px; }
  :not(.login-page) .mb-440 {
    margin-bottom: 440px; }
  :not(.login-page) .mr-440 {
    margin-right: 440px; }
  :not(.login-page) .ml-440 {
    margin-left: 440px; }
  :not(.login-page) .mx-440 {
    margin-left: 440px;
    margin-right: 440px; }
  :not(.login-page) .pt-440 {
    padding-top: 440px; }
  :not(.login-page) .pb-440 {
    padding-bottom: 440px; }
  :not(.login-page) .pr-440 {
    padding-right: 440px; }
  :not(.login-page) .pl-440 {
    padding-left: 440px; }
  :not(.login-page) .px-440 {
    padding-left: 440px;
    padding-right: 440px; }
  :not(.login-page) .mt-445 {
    margin-top: 445px; }
  :not(.login-page) .mb-445 {
    margin-bottom: 445px; }
  :not(.login-page) .mr-445 {
    margin-right: 445px; }
  :not(.login-page) .ml-445 {
    margin-left: 445px; }
  :not(.login-page) .mx-445 {
    margin-left: 445px;
    margin-right: 445px; }
  :not(.login-page) .pt-445 {
    padding-top: 445px; }
  :not(.login-page) .pb-445 {
    padding-bottom: 445px; }
  :not(.login-page) .pr-445 {
    padding-right: 445px; }
  :not(.login-page) .pl-445 {
    padding-left: 445px; }
  :not(.login-page) .px-445 {
    padding-left: 445px;
    padding-right: 445px; }
  :not(.login-page) .mt-450 {
    margin-top: 450px; }
  :not(.login-page) .mb-450 {
    margin-bottom: 450px; }
  :not(.login-page) .mr-450 {
    margin-right: 450px; }
  :not(.login-page) .ml-450 {
    margin-left: 450px; }
  :not(.login-page) .mx-450 {
    margin-left: 450px;
    margin-right: 450px; }
  :not(.login-page) .pt-450 {
    padding-top: 450px; }
  :not(.login-page) .pb-450 {
    padding-bottom: 450px; }
  :not(.login-page) .pr-450 {
    padding-right: 450px; }
  :not(.login-page) .pl-450 {
    padding-left: 450px; }
  :not(.login-page) .px-450 {
    padding-left: 450px;
    padding-right: 450px; }
  :not(.login-page) .mt-455 {
    margin-top: 455px; }
  :not(.login-page) .mb-455 {
    margin-bottom: 455px; }
  :not(.login-page) .mr-455 {
    margin-right: 455px; }
  :not(.login-page) .ml-455 {
    margin-left: 455px; }
  :not(.login-page) .mx-455 {
    margin-left: 455px;
    margin-right: 455px; }
  :not(.login-page) .pt-455 {
    padding-top: 455px; }
  :not(.login-page) .pb-455 {
    padding-bottom: 455px; }
  :not(.login-page) .pr-455 {
    padding-right: 455px; }
  :not(.login-page) .pl-455 {
    padding-left: 455px; }
  :not(.login-page) .px-455 {
    padding-left: 455px;
    padding-right: 455px; }
  :not(.login-page) .mt-460 {
    margin-top: 460px; }
  :not(.login-page) .mb-460 {
    margin-bottom: 460px; }
  :not(.login-page) .mr-460 {
    margin-right: 460px; }
  :not(.login-page) .ml-460 {
    margin-left: 460px; }
  :not(.login-page) .mx-460 {
    margin-left: 460px;
    margin-right: 460px; }
  :not(.login-page) .pt-460 {
    padding-top: 460px; }
  :not(.login-page) .pb-460 {
    padding-bottom: 460px; }
  :not(.login-page) .pr-460 {
    padding-right: 460px; }
  :not(.login-page) .pl-460 {
    padding-left: 460px; }
  :not(.login-page) .px-460 {
    padding-left: 460px;
    padding-right: 460px; }
  :not(.login-page) .mt-465 {
    margin-top: 465px; }
  :not(.login-page) .mb-465 {
    margin-bottom: 465px; }
  :not(.login-page) .mr-465 {
    margin-right: 465px; }
  :not(.login-page) .ml-465 {
    margin-left: 465px; }
  :not(.login-page) .mx-465 {
    margin-left: 465px;
    margin-right: 465px; }
  :not(.login-page) .pt-465 {
    padding-top: 465px; }
  :not(.login-page) .pb-465 {
    padding-bottom: 465px; }
  :not(.login-page) .pr-465 {
    padding-right: 465px; }
  :not(.login-page) .pl-465 {
    padding-left: 465px; }
  :not(.login-page) .px-465 {
    padding-left: 465px;
    padding-right: 465px; }
  :not(.login-page) .mt-470 {
    margin-top: 470px; }
  :not(.login-page) .mb-470 {
    margin-bottom: 470px; }
  :not(.login-page) .mr-470 {
    margin-right: 470px; }
  :not(.login-page) .ml-470 {
    margin-left: 470px; }
  :not(.login-page) .mx-470 {
    margin-left: 470px;
    margin-right: 470px; }
  :not(.login-page) .pt-470 {
    padding-top: 470px; }
  :not(.login-page) .pb-470 {
    padding-bottom: 470px; }
  :not(.login-page) .pr-470 {
    padding-right: 470px; }
  :not(.login-page) .pl-470 {
    padding-left: 470px; }
  :not(.login-page) .px-470 {
    padding-left: 470px;
    padding-right: 470px; }
  :not(.login-page) .mt-475 {
    margin-top: 475px; }
  :not(.login-page) .mb-475 {
    margin-bottom: 475px; }
  :not(.login-page) .mr-475 {
    margin-right: 475px; }
  :not(.login-page) .ml-475 {
    margin-left: 475px; }
  :not(.login-page) .mx-475 {
    margin-left: 475px;
    margin-right: 475px; }
  :not(.login-page) .pt-475 {
    padding-top: 475px; }
  :not(.login-page) .pb-475 {
    padding-bottom: 475px; }
  :not(.login-page) .pr-475 {
    padding-right: 475px; }
  :not(.login-page) .pl-475 {
    padding-left: 475px; }
  :not(.login-page) .px-475 {
    padding-left: 475px;
    padding-right: 475px; }
  :not(.login-page) .mt-480 {
    margin-top: 480px; }
  :not(.login-page) .mb-480 {
    margin-bottom: 480px; }
  :not(.login-page) .mr-480 {
    margin-right: 480px; }
  :not(.login-page) .ml-480 {
    margin-left: 480px; }
  :not(.login-page) .mx-480 {
    margin-left: 480px;
    margin-right: 480px; }
  :not(.login-page) .pt-480 {
    padding-top: 480px; }
  :not(.login-page) .pb-480 {
    padding-bottom: 480px; }
  :not(.login-page) .pr-480 {
    padding-right: 480px; }
  :not(.login-page) .pl-480 {
    padding-left: 480px; }
  :not(.login-page) .px-480 {
    padding-left: 480px;
    padding-right: 480px; }
  :not(.login-page) .mt-485 {
    margin-top: 485px; }
  :not(.login-page) .mb-485 {
    margin-bottom: 485px; }
  :not(.login-page) .mr-485 {
    margin-right: 485px; }
  :not(.login-page) .ml-485 {
    margin-left: 485px; }
  :not(.login-page) .mx-485 {
    margin-left: 485px;
    margin-right: 485px; }
  :not(.login-page) .pt-485 {
    padding-top: 485px; }
  :not(.login-page) .pb-485 {
    padding-bottom: 485px; }
  :not(.login-page) .pr-485 {
    padding-right: 485px; }
  :not(.login-page) .pl-485 {
    padding-left: 485px; }
  :not(.login-page) .px-485 {
    padding-left: 485px;
    padding-right: 485px; }
  :not(.login-page) .mt-490 {
    margin-top: 490px; }
  :not(.login-page) .mb-490 {
    margin-bottom: 490px; }
  :not(.login-page) .mr-490 {
    margin-right: 490px; }
  :not(.login-page) .ml-490 {
    margin-left: 490px; }
  :not(.login-page) .mx-490 {
    margin-left: 490px;
    margin-right: 490px; }
  :not(.login-page) .pt-490 {
    padding-top: 490px; }
  :not(.login-page) .pb-490 {
    padding-bottom: 490px; }
  :not(.login-page) .pr-490 {
    padding-right: 490px; }
  :not(.login-page) .pl-490 {
    padding-left: 490px; }
  :not(.login-page) .px-490 {
    padding-left: 490px;
    padding-right: 490px; }
  :not(.login-page) .mt-495 {
    margin-top: 495px; }
  :not(.login-page) .mb-495 {
    margin-bottom: 495px; }
  :not(.login-page) .mr-495 {
    margin-right: 495px; }
  :not(.login-page) .ml-495 {
    margin-left: 495px; }
  :not(.login-page) .mx-495 {
    margin-left: 495px;
    margin-right: 495px; }
  :not(.login-page) .pt-495 {
    padding-top: 495px; }
  :not(.login-page) .pb-495 {
    padding-bottom: 495px; }
  :not(.login-page) .pr-495 {
    padding-right: 495px; }
  :not(.login-page) .pl-495 {
    padding-left: 495px; }
  :not(.login-page) .px-495 {
    padding-left: 495px;
    padding-right: 495px; }
  :not(.login-page) .mt-500 {
    margin-top: 500px; }
  :not(.login-page) .mb-500 {
    margin-bottom: 500px; }
  :not(.login-page) .mr-500 {
    margin-right: 500px; }
  :not(.login-page) .ml-500 {
    margin-left: 500px; }
  :not(.login-page) .mx-500 {
    margin-left: 500px;
    margin-right: 500px; }
  :not(.login-page) .pt-500 {
    padding-top: 500px; }
  :not(.login-page) .pb-500 {
    padding-bottom: 500px; }
  :not(.login-page) .pr-500 {
    padding-right: 500px; }
  :not(.login-page) .pl-500 {
    padding-left: 500px; }
  :not(.login-page) .px-500 {
    padding-left: 500px;
    padding-right: 500px; }
  :not(.login-page) .mt-505 {
    margin-top: 505px; }
  :not(.login-page) .mb-505 {
    margin-bottom: 505px; }
  :not(.login-page) .mr-505 {
    margin-right: 505px; }
  :not(.login-page) .ml-505 {
    margin-left: 505px; }
  :not(.login-page) .mx-505 {
    margin-left: 505px;
    margin-right: 505px; }
  :not(.login-page) .pt-505 {
    padding-top: 505px; }
  :not(.login-page) .pb-505 {
    padding-bottom: 505px; }
  :not(.login-page) .pr-505 {
    padding-right: 505px; }
  :not(.login-page) .pl-505 {
    padding-left: 505px; }
  :not(.login-page) .px-505 {
    padding-left: 505px;
    padding-right: 505px; }
  :not(.login-page) .mt-510 {
    margin-top: 510px; }
  :not(.login-page) .mb-510 {
    margin-bottom: 510px; }
  :not(.login-page) .mr-510 {
    margin-right: 510px; }
  :not(.login-page) .ml-510 {
    margin-left: 510px; }
  :not(.login-page) .mx-510 {
    margin-left: 510px;
    margin-right: 510px; }
  :not(.login-page) .pt-510 {
    padding-top: 510px; }
  :not(.login-page) .pb-510 {
    padding-bottom: 510px; }
  :not(.login-page) .pr-510 {
    padding-right: 510px; }
  :not(.login-page) .pl-510 {
    padding-left: 510px; }
  :not(.login-page) .px-510 {
    padding-left: 510px;
    padding-right: 510px; }
  :not(.login-page) .mt-515 {
    margin-top: 515px; }
  :not(.login-page) .mb-515 {
    margin-bottom: 515px; }
  :not(.login-page) .mr-515 {
    margin-right: 515px; }
  :not(.login-page) .ml-515 {
    margin-left: 515px; }
  :not(.login-page) .mx-515 {
    margin-left: 515px;
    margin-right: 515px; }
  :not(.login-page) .pt-515 {
    padding-top: 515px; }
  :not(.login-page) .pb-515 {
    padding-bottom: 515px; }
  :not(.login-page) .pr-515 {
    padding-right: 515px; }
  :not(.login-page) .pl-515 {
    padding-left: 515px; }
  :not(.login-page) .px-515 {
    padding-left: 515px;
    padding-right: 515px; }
  :not(.login-page) .mt-520 {
    margin-top: 520px; }
  :not(.login-page) .mb-520 {
    margin-bottom: 520px; }
  :not(.login-page) .mr-520 {
    margin-right: 520px; }
  :not(.login-page) .ml-520 {
    margin-left: 520px; }
  :not(.login-page) .mx-520 {
    margin-left: 520px;
    margin-right: 520px; }
  :not(.login-page) .pt-520 {
    padding-top: 520px; }
  :not(.login-page) .pb-520 {
    padding-bottom: 520px; }
  :not(.login-page) .pr-520 {
    padding-right: 520px; }
  :not(.login-page) .pl-520 {
    padding-left: 520px; }
  :not(.login-page) .px-520 {
    padding-left: 520px;
    padding-right: 520px; }
  :not(.login-page) .mt-525 {
    margin-top: 525px; }
  :not(.login-page) .mb-525 {
    margin-bottom: 525px; }
  :not(.login-page) .mr-525 {
    margin-right: 525px; }
  :not(.login-page) .ml-525 {
    margin-left: 525px; }
  :not(.login-page) .mx-525 {
    margin-left: 525px;
    margin-right: 525px; }
  :not(.login-page) .pt-525 {
    padding-top: 525px; }
  :not(.login-page) .pb-525 {
    padding-bottom: 525px; }
  :not(.login-page) .pr-525 {
    padding-right: 525px; }
  :not(.login-page) .pl-525 {
    padding-left: 525px; }
  :not(.login-page) .px-525 {
    padding-left: 525px;
    padding-right: 525px; }
  :not(.login-page) .mt-530 {
    margin-top: 530px; }
  :not(.login-page) .mb-530 {
    margin-bottom: 530px; }
  :not(.login-page) .mr-530 {
    margin-right: 530px; }
  :not(.login-page) .ml-530 {
    margin-left: 530px; }
  :not(.login-page) .mx-530 {
    margin-left: 530px;
    margin-right: 530px; }
  :not(.login-page) .pt-530 {
    padding-top: 530px; }
  :not(.login-page) .pb-530 {
    padding-bottom: 530px; }
  :not(.login-page) .pr-530 {
    padding-right: 530px; }
  :not(.login-page) .pl-530 {
    padding-left: 530px; }
  :not(.login-page) .px-530 {
    padding-left: 530px;
    padding-right: 530px; }
  :not(.login-page) .mt-535 {
    margin-top: 535px; }
  :not(.login-page) .mb-535 {
    margin-bottom: 535px; }
  :not(.login-page) .mr-535 {
    margin-right: 535px; }
  :not(.login-page) .ml-535 {
    margin-left: 535px; }
  :not(.login-page) .mx-535 {
    margin-left: 535px;
    margin-right: 535px; }
  :not(.login-page) .pt-535 {
    padding-top: 535px; }
  :not(.login-page) .pb-535 {
    padding-bottom: 535px; }
  :not(.login-page) .pr-535 {
    padding-right: 535px; }
  :not(.login-page) .pl-535 {
    padding-left: 535px; }
  :not(.login-page) .px-535 {
    padding-left: 535px;
    padding-right: 535px; }
  :not(.login-page) .mt-540 {
    margin-top: 540px; }
  :not(.login-page) .mb-540 {
    margin-bottom: 540px; }
  :not(.login-page) .mr-540 {
    margin-right: 540px; }
  :not(.login-page) .ml-540 {
    margin-left: 540px; }
  :not(.login-page) .mx-540 {
    margin-left: 540px;
    margin-right: 540px; }
  :not(.login-page) .pt-540 {
    padding-top: 540px; }
  :not(.login-page) .pb-540 {
    padding-bottom: 540px; }
  :not(.login-page) .pr-540 {
    padding-right: 540px; }
  :not(.login-page) .pl-540 {
    padding-left: 540px; }
  :not(.login-page) .px-540 {
    padding-left: 540px;
    padding-right: 540px; }
  :not(.login-page) .mt-545 {
    margin-top: 545px; }
  :not(.login-page) .mb-545 {
    margin-bottom: 545px; }
  :not(.login-page) .mr-545 {
    margin-right: 545px; }
  :not(.login-page) .ml-545 {
    margin-left: 545px; }
  :not(.login-page) .mx-545 {
    margin-left: 545px;
    margin-right: 545px; }
  :not(.login-page) .pt-545 {
    padding-top: 545px; }
  :not(.login-page) .pb-545 {
    padding-bottom: 545px; }
  :not(.login-page) .pr-545 {
    padding-right: 545px; }
  :not(.login-page) .pl-545 {
    padding-left: 545px; }
  :not(.login-page) .px-545 {
    padding-left: 545px;
    padding-right: 545px; }
  :not(.login-page) .mt-550 {
    margin-top: 550px; }
  :not(.login-page) .mb-550 {
    margin-bottom: 550px; }
  :not(.login-page) .mr-550 {
    margin-right: 550px; }
  :not(.login-page) .ml-550 {
    margin-left: 550px; }
  :not(.login-page) .mx-550 {
    margin-left: 550px;
    margin-right: 550px; }
  :not(.login-page) .pt-550 {
    padding-top: 550px; }
  :not(.login-page) .pb-550 {
    padding-bottom: 550px; }
  :not(.login-page) .pr-550 {
    padding-right: 550px; }
  :not(.login-page) .pl-550 {
    padding-left: 550px; }
  :not(.login-page) .px-550 {
    padding-left: 550px;
    padding-right: 550px; }
  :not(.login-page) .mt-555 {
    margin-top: 555px; }
  :not(.login-page) .mb-555 {
    margin-bottom: 555px; }
  :not(.login-page) .mr-555 {
    margin-right: 555px; }
  :not(.login-page) .ml-555 {
    margin-left: 555px; }
  :not(.login-page) .mx-555 {
    margin-left: 555px;
    margin-right: 555px; }
  :not(.login-page) .pt-555 {
    padding-top: 555px; }
  :not(.login-page) .pb-555 {
    padding-bottom: 555px; }
  :not(.login-page) .pr-555 {
    padding-right: 555px; }
  :not(.login-page) .pl-555 {
    padding-left: 555px; }
  :not(.login-page) .px-555 {
    padding-left: 555px;
    padding-right: 555px; }
  :not(.login-page) .mt-560 {
    margin-top: 560px; }
  :not(.login-page) .mb-560 {
    margin-bottom: 560px; }
  :not(.login-page) .mr-560 {
    margin-right: 560px; }
  :not(.login-page) .ml-560 {
    margin-left: 560px; }
  :not(.login-page) .mx-560 {
    margin-left: 560px;
    margin-right: 560px; }
  :not(.login-page) .pt-560 {
    padding-top: 560px; }
  :not(.login-page) .pb-560 {
    padding-bottom: 560px; }
  :not(.login-page) .pr-560 {
    padding-right: 560px; }
  :not(.login-page) .pl-560 {
    padding-left: 560px; }
  :not(.login-page) .px-560 {
    padding-left: 560px;
    padding-right: 560px; }
  :not(.login-page) .mt-565 {
    margin-top: 565px; }
  :not(.login-page) .mb-565 {
    margin-bottom: 565px; }
  :not(.login-page) .mr-565 {
    margin-right: 565px; }
  :not(.login-page) .ml-565 {
    margin-left: 565px; }
  :not(.login-page) .mx-565 {
    margin-left: 565px;
    margin-right: 565px; }
  :not(.login-page) .pt-565 {
    padding-top: 565px; }
  :not(.login-page) .pb-565 {
    padding-bottom: 565px; }
  :not(.login-page) .pr-565 {
    padding-right: 565px; }
  :not(.login-page) .pl-565 {
    padding-left: 565px; }
  :not(.login-page) .px-565 {
    padding-left: 565px;
    padding-right: 565px; }
  :not(.login-page) .mt-570 {
    margin-top: 570px; }
  :not(.login-page) .mb-570 {
    margin-bottom: 570px; }
  :not(.login-page) .mr-570 {
    margin-right: 570px; }
  :not(.login-page) .ml-570 {
    margin-left: 570px; }
  :not(.login-page) .mx-570 {
    margin-left: 570px;
    margin-right: 570px; }
  :not(.login-page) .pt-570 {
    padding-top: 570px; }
  :not(.login-page) .pb-570 {
    padding-bottom: 570px; }
  :not(.login-page) .pr-570 {
    padding-right: 570px; }
  :not(.login-page) .pl-570 {
    padding-left: 570px; }
  :not(.login-page) .px-570 {
    padding-left: 570px;
    padding-right: 570px; }
  :not(.login-page) .mt-575 {
    margin-top: 575px; }
  :not(.login-page) .mb-575 {
    margin-bottom: 575px; }
  :not(.login-page) .mr-575 {
    margin-right: 575px; }
  :not(.login-page) .ml-575 {
    margin-left: 575px; }
  :not(.login-page) .mx-575 {
    margin-left: 575px;
    margin-right: 575px; }
  :not(.login-page) .pt-575 {
    padding-top: 575px; }
  :not(.login-page) .pb-575 {
    padding-bottom: 575px; }
  :not(.login-page) .pr-575 {
    padding-right: 575px; }
  :not(.login-page) .pl-575 {
    padding-left: 575px; }
  :not(.login-page) .px-575 {
    padding-left: 575px;
    padding-right: 575px; }
  :not(.login-page) .mt-580 {
    margin-top: 580px; }
  :not(.login-page) .mb-580 {
    margin-bottom: 580px; }
  :not(.login-page) .mr-580 {
    margin-right: 580px; }
  :not(.login-page) .ml-580 {
    margin-left: 580px; }
  :not(.login-page) .mx-580 {
    margin-left: 580px;
    margin-right: 580px; }
  :not(.login-page) .pt-580 {
    padding-top: 580px; }
  :not(.login-page) .pb-580 {
    padding-bottom: 580px; }
  :not(.login-page) .pr-580 {
    padding-right: 580px; }
  :not(.login-page) .pl-580 {
    padding-left: 580px; }
  :not(.login-page) .px-580 {
    padding-left: 580px;
    padding-right: 580px; }
  :not(.login-page) .mt-585 {
    margin-top: 585px; }
  :not(.login-page) .mb-585 {
    margin-bottom: 585px; }
  :not(.login-page) .mr-585 {
    margin-right: 585px; }
  :not(.login-page) .ml-585 {
    margin-left: 585px; }
  :not(.login-page) .mx-585 {
    margin-left: 585px;
    margin-right: 585px; }
  :not(.login-page) .pt-585 {
    padding-top: 585px; }
  :not(.login-page) .pb-585 {
    padding-bottom: 585px; }
  :not(.login-page) .pr-585 {
    padding-right: 585px; }
  :not(.login-page) .pl-585 {
    padding-left: 585px; }
  :not(.login-page) .px-585 {
    padding-left: 585px;
    padding-right: 585px; }
  :not(.login-page) .mt-590 {
    margin-top: 590px; }
  :not(.login-page) .mb-590 {
    margin-bottom: 590px; }
  :not(.login-page) .mr-590 {
    margin-right: 590px; }
  :not(.login-page) .ml-590 {
    margin-left: 590px; }
  :not(.login-page) .mx-590 {
    margin-left: 590px;
    margin-right: 590px; }
  :not(.login-page) .pt-590 {
    padding-top: 590px; }
  :not(.login-page) .pb-590 {
    padding-bottom: 590px; }
  :not(.login-page) .pr-590 {
    padding-right: 590px; }
  :not(.login-page) .pl-590 {
    padding-left: 590px; }
  :not(.login-page) .px-590 {
    padding-left: 590px;
    padding-right: 590px; }
  :not(.login-page) .mt-595 {
    margin-top: 595px; }
  :not(.login-page) .mb-595 {
    margin-bottom: 595px; }
  :not(.login-page) .mr-595 {
    margin-right: 595px; }
  :not(.login-page) .ml-595 {
    margin-left: 595px; }
  :not(.login-page) .mx-595 {
    margin-left: 595px;
    margin-right: 595px; }
  :not(.login-page) .pt-595 {
    padding-top: 595px; }
  :not(.login-page) .pb-595 {
    padding-bottom: 595px; }
  :not(.login-page) .pr-595 {
    padding-right: 595px; }
  :not(.login-page) .pl-595 {
    padding-left: 595px; }
  :not(.login-page) .px-595 {
    padding-left: 595px;
    padding-right: 595px; }
  :not(.login-page) .mt-600 {
    margin-top: 600px; }
  :not(.login-page) .mb-600 {
    margin-bottom: 600px; }
  :not(.login-page) .mr-600 {
    margin-right: 600px; }
  :not(.login-page) .ml-600 {
    margin-left: 600px; }
  :not(.login-page) .mx-600 {
    margin-left: 600px;
    margin-right: 600px; }
  :not(.login-page) .pt-600 {
    padding-top: 600px; }
  :not(.login-page) .pb-600 {
    padding-bottom: 600px; }
  :not(.login-page) .pr-600 {
    padding-right: 600px; }
  :not(.login-page) .pl-600 {
    padding-left: 600px; }
  :not(.login-page) .px-600 {
    padding-left: 600px;
    padding-right: 600px; }
  :not(.login-page) .mt-605 {
    margin-top: 605px; }
  :not(.login-page) .mb-605 {
    margin-bottom: 605px; }
  :not(.login-page) .mr-605 {
    margin-right: 605px; }
  :not(.login-page) .ml-605 {
    margin-left: 605px; }
  :not(.login-page) .mx-605 {
    margin-left: 605px;
    margin-right: 605px; }
  :not(.login-page) .pt-605 {
    padding-top: 605px; }
  :not(.login-page) .pb-605 {
    padding-bottom: 605px; }
  :not(.login-page) .pr-605 {
    padding-right: 605px; }
  :not(.login-page) .pl-605 {
    padding-left: 605px; }
  :not(.login-page) .px-605 {
    padding-left: 605px;
    padding-right: 605px; }
  :not(.login-page) .mt-610 {
    margin-top: 610px; }
  :not(.login-page) .mb-610 {
    margin-bottom: 610px; }
  :not(.login-page) .mr-610 {
    margin-right: 610px; }
  :not(.login-page) .ml-610 {
    margin-left: 610px; }
  :not(.login-page) .mx-610 {
    margin-left: 610px;
    margin-right: 610px; }
  :not(.login-page) .pt-610 {
    padding-top: 610px; }
  :not(.login-page) .pb-610 {
    padding-bottom: 610px; }
  :not(.login-page) .pr-610 {
    padding-right: 610px; }
  :not(.login-page) .pl-610 {
    padding-left: 610px; }
  :not(.login-page) .px-610 {
    padding-left: 610px;
    padding-right: 610px; }
  :not(.login-page) .mt-615 {
    margin-top: 615px; }
  :not(.login-page) .mb-615 {
    margin-bottom: 615px; }
  :not(.login-page) .mr-615 {
    margin-right: 615px; }
  :not(.login-page) .ml-615 {
    margin-left: 615px; }
  :not(.login-page) .mx-615 {
    margin-left: 615px;
    margin-right: 615px; }
  :not(.login-page) .pt-615 {
    padding-top: 615px; }
  :not(.login-page) .pb-615 {
    padding-bottom: 615px; }
  :not(.login-page) .pr-615 {
    padding-right: 615px; }
  :not(.login-page) .pl-615 {
    padding-left: 615px; }
  :not(.login-page) .px-615 {
    padding-left: 615px;
    padding-right: 615px; }
  :not(.login-page) .mt-620 {
    margin-top: 620px; }
  :not(.login-page) .mb-620 {
    margin-bottom: 620px; }
  :not(.login-page) .mr-620 {
    margin-right: 620px; }
  :not(.login-page) .ml-620 {
    margin-left: 620px; }
  :not(.login-page) .mx-620 {
    margin-left: 620px;
    margin-right: 620px; }
  :not(.login-page) .pt-620 {
    padding-top: 620px; }
  :not(.login-page) .pb-620 {
    padding-bottom: 620px; }
  :not(.login-page) .pr-620 {
    padding-right: 620px; }
  :not(.login-page) .pl-620 {
    padding-left: 620px; }
  :not(.login-page) .px-620 {
    padding-left: 620px;
    padding-right: 620px; }
  :not(.login-page) .mt-625 {
    margin-top: 625px; }
  :not(.login-page) .mb-625 {
    margin-bottom: 625px; }
  :not(.login-page) .mr-625 {
    margin-right: 625px; }
  :not(.login-page) .ml-625 {
    margin-left: 625px; }
  :not(.login-page) .mx-625 {
    margin-left: 625px;
    margin-right: 625px; }
  :not(.login-page) .pt-625 {
    padding-top: 625px; }
  :not(.login-page) .pb-625 {
    padding-bottom: 625px; }
  :not(.login-page) .pr-625 {
    padding-right: 625px; }
  :not(.login-page) .pl-625 {
    padding-left: 625px; }
  :not(.login-page) .px-625 {
    padding-left: 625px;
    padding-right: 625px; }
  :not(.login-page) .mt-630 {
    margin-top: 630px; }
  :not(.login-page) .mb-630 {
    margin-bottom: 630px; }
  :not(.login-page) .mr-630 {
    margin-right: 630px; }
  :not(.login-page) .ml-630 {
    margin-left: 630px; }
  :not(.login-page) .mx-630 {
    margin-left: 630px;
    margin-right: 630px; }
  :not(.login-page) .pt-630 {
    padding-top: 630px; }
  :not(.login-page) .pb-630 {
    padding-bottom: 630px; }
  :not(.login-page) .pr-630 {
    padding-right: 630px; }
  :not(.login-page) .pl-630 {
    padding-left: 630px; }
  :not(.login-page) .px-630 {
    padding-left: 630px;
    padding-right: 630px; }
  :not(.login-page) .mt-635 {
    margin-top: 635px; }
  :not(.login-page) .mb-635 {
    margin-bottom: 635px; }
  :not(.login-page) .mr-635 {
    margin-right: 635px; }
  :not(.login-page) .ml-635 {
    margin-left: 635px; }
  :not(.login-page) .mx-635 {
    margin-left: 635px;
    margin-right: 635px; }
  :not(.login-page) .pt-635 {
    padding-top: 635px; }
  :not(.login-page) .pb-635 {
    padding-bottom: 635px; }
  :not(.login-page) .pr-635 {
    padding-right: 635px; }
  :not(.login-page) .pl-635 {
    padding-left: 635px; }
  :not(.login-page) .px-635 {
    padding-left: 635px;
    padding-right: 635px; }
  :not(.login-page) .mt-640 {
    margin-top: 640px; }
  :not(.login-page) .mb-640 {
    margin-bottom: 640px; }
  :not(.login-page) .mr-640 {
    margin-right: 640px; }
  :not(.login-page) .ml-640 {
    margin-left: 640px; }
  :not(.login-page) .mx-640 {
    margin-left: 640px;
    margin-right: 640px; }
  :not(.login-page) .pt-640 {
    padding-top: 640px; }
  :not(.login-page) .pb-640 {
    padding-bottom: 640px; }
  :not(.login-page) .pr-640 {
    padding-right: 640px; }
  :not(.login-page) .pl-640 {
    padding-left: 640px; }
  :not(.login-page) .px-640 {
    padding-left: 640px;
    padding-right: 640px; }
  :not(.login-page) .mt-645 {
    margin-top: 645px; }
  :not(.login-page) .mb-645 {
    margin-bottom: 645px; }
  :not(.login-page) .mr-645 {
    margin-right: 645px; }
  :not(.login-page) .ml-645 {
    margin-left: 645px; }
  :not(.login-page) .mx-645 {
    margin-left: 645px;
    margin-right: 645px; }
  :not(.login-page) .pt-645 {
    padding-top: 645px; }
  :not(.login-page) .pb-645 {
    padding-bottom: 645px; }
  :not(.login-page) .pr-645 {
    padding-right: 645px; }
  :not(.login-page) .pl-645 {
    padding-left: 645px; }
  :not(.login-page) .px-645 {
    padding-left: 645px;
    padding-right: 645px; }
  :not(.login-page) .mt-650 {
    margin-top: 650px; }
  :not(.login-page) .mb-650 {
    margin-bottom: 650px; }
  :not(.login-page) .mr-650 {
    margin-right: 650px; }
  :not(.login-page) .ml-650 {
    margin-left: 650px; }
  :not(.login-page) .mx-650 {
    margin-left: 650px;
    margin-right: 650px; }
  :not(.login-page) .pt-650 {
    padding-top: 650px; }
  :not(.login-page) .pb-650 {
    padding-bottom: 650px; }
  :not(.login-page) .pr-650 {
    padding-right: 650px; }
  :not(.login-page) .pl-650 {
    padding-left: 650px; }
  :not(.login-page) .px-650 {
    padding-left: 650px;
    padding-right: 650px; }
  :not(.login-page) .mt-655 {
    margin-top: 655px; }
  :not(.login-page) .mb-655 {
    margin-bottom: 655px; }
  :not(.login-page) .mr-655 {
    margin-right: 655px; }
  :not(.login-page) .ml-655 {
    margin-left: 655px; }
  :not(.login-page) .mx-655 {
    margin-left: 655px;
    margin-right: 655px; }
  :not(.login-page) .pt-655 {
    padding-top: 655px; }
  :not(.login-page) .pb-655 {
    padding-bottom: 655px; }
  :not(.login-page) .pr-655 {
    padding-right: 655px; }
  :not(.login-page) .pl-655 {
    padding-left: 655px; }
  :not(.login-page) .px-655 {
    padding-left: 655px;
    padding-right: 655px; }
  :not(.login-page) .mt-660 {
    margin-top: 660px; }
  :not(.login-page) .mb-660 {
    margin-bottom: 660px; }
  :not(.login-page) .mr-660 {
    margin-right: 660px; }
  :not(.login-page) .ml-660 {
    margin-left: 660px; }
  :not(.login-page) .mx-660 {
    margin-left: 660px;
    margin-right: 660px; }
  :not(.login-page) .pt-660 {
    padding-top: 660px; }
  :not(.login-page) .pb-660 {
    padding-bottom: 660px; }
  :not(.login-page) .pr-660 {
    padding-right: 660px; }
  :not(.login-page) .pl-660 {
    padding-left: 660px; }
  :not(.login-page) .px-660 {
    padding-left: 660px;
    padding-right: 660px; }
  :not(.login-page) .mt-665 {
    margin-top: 665px; }
  :not(.login-page) .mb-665 {
    margin-bottom: 665px; }
  :not(.login-page) .mr-665 {
    margin-right: 665px; }
  :not(.login-page) .ml-665 {
    margin-left: 665px; }
  :not(.login-page) .mx-665 {
    margin-left: 665px;
    margin-right: 665px; }
  :not(.login-page) .pt-665 {
    padding-top: 665px; }
  :not(.login-page) .pb-665 {
    padding-bottom: 665px; }
  :not(.login-page) .pr-665 {
    padding-right: 665px; }
  :not(.login-page) .pl-665 {
    padding-left: 665px; }
  :not(.login-page) .px-665 {
    padding-left: 665px;
    padding-right: 665px; }
  :not(.login-page) .mt-670 {
    margin-top: 670px; }
  :not(.login-page) .mb-670 {
    margin-bottom: 670px; }
  :not(.login-page) .mr-670 {
    margin-right: 670px; }
  :not(.login-page) .ml-670 {
    margin-left: 670px; }
  :not(.login-page) .mx-670 {
    margin-left: 670px;
    margin-right: 670px; }
  :not(.login-page) .pt-670 {
    padding-top: 670px; }
  :not(.login-page) .pb-670 {
    padding-bottom: 670px; }
  :not(.login-page) .pr-670 {
    padding-right: 670px; }
  :not(.login-page) .pl-670 {
    padding-left: 670px; }
  :not(.login-page) .px-670 {
    padding-left: 670px;
    padding-right: 670px; }
  :not(.login-page) .mt-675 {
    margin-top: 675px; }
  :not(.login-page) .mb-675 {
    margin-bottom: 675px; }
  :not(.login-page) .mr-675 {
    margin-right: 675px; }
  :not(.login-page) .ml-675 {
    margin-left: 675px; }
  :not(.login-page) .mx-675 {
    margin-left: 675px;
    margin-right: 675px; }
  :not(.login-page) .pt-675 {
    padding-top: 675px; }
  :not(.login-page) .pb-675 {
    padding-bottom: 675px; }
  :not(.login-page) .pr-675 {
    padding-right: 675px; }
  :not(.login-page) .pl-675 {
    padding-left: 675px; }
  :not(.login-page) .px-675 {
    padding-left: 675px;
    padding-right: 675px; }
  :not(.login-page) .mt-680 {
    margin-top: 680px; }
  :not(.login-page) .mb-680 {
    margin-bottom: 680px; }
  :not(.login-page) .mr-680 {
    margin-right: 680px; }
  :not(.login-page) .ml-680 {
    margin-left: 680px; }
  :not(.login-page) .mx-680 {
    margin-left: 680px;
    margin-right: 680px; }
  :not(.login-page) .pt-680 {
    padding-top: 680px; }
  :not(.login-page) .pb-680 {
    padding-bottom: 680px; }
  :not(.login-page) .pr-680 {
    padding-right: 680px; }
  :not(.login-page) .pl-680 {
    padding-left: 680px; }
  :not(.login-page) .px-680 {
    padding-left: 680px;
    padding-right: 680px; }
  :not(.login-page) .mt-685 {
    margin-top: 685px; }
  :not(.login-page) .mb-685 {
    margin-bottom: 685px; }
  :not(.login-page) .mr-685 {
    margin-right: 685px; }
  :not(.login-page) .ml-685 {
    margin-left: 685px; }
  :not(.login-page) .mx-685 {
    margin-left: 685px;
    margin-right: 685px; }
  :not(.login-page) .pt-685 {
    padding-top: 685px; }
  :not(.login-page) .pb-685 {
    padding-bottom: 685px; }
  :not(.login-page) .pr-685 {
    padding-right: 685px; }
  :not(.login-page) .pl-685 {
    padding-left: 685px; }
  :not(.login-page) .px-685 {
    padding-left: 685px;
    padding-right: 685px; }
  :not(.login-page) .mt-690 {
    margin-top: 690px; }
  :not(.login-page) .mb-690 {
    margin-bottom: 690px; }
  :not(.login-page) .mr-690 {
    margin-right: 690px; }
  :not(.login-page) .ml-690 {
    margin-left: 690px; }
  :not(.login-page) .mx-690 {
    margin-left: 690px;
    margin-right: 690px; }
  :not(.login-page) .pt-690 {
    padding-top: 690px; }
  :not(.login-page) .pb-690 {
    padding-bottom: 690px; }
  :not(.login-page) .pr-690 {
    padding-right: 690px; }
  :not(.login-page) .pl-690 {
    padding-left: 690px; }
  :not(.login-page) .px-690 {
    padding-left: 690px;
    padding-right: 690px; }
  :not(.login-page) .mt-695 {
    margin-top: 695px; }
  :not(.login-page) .mb-695 {
    margin-bottom: 695px; }
  :not(.login-page) .mr-695 {
    margin-right: 695px; }
  :not(.login-page) .ml-695 {
    margin-left: 695px; }
  :not(.login-page) .mx-695 {
    margin-left: 695px;
    margin-right: 695px; }
  :not(.login-page) .pt-695 {
    padding-top: 695px; }
  :not(.login-page) .pb-695 {
    padding-bottom: 695px; }
  :not(.login-page) .pr-695 {
    padding-right: 695px; }
  :not(.login-page) .pl-695 {
    padding-left: 695px; }
  :not(.login-page) .px-695 {
    padding-left: 695px;
    padding-right: 695px; }
  :not(.login-page) .mt-700 {
    margin-top: 700px; }
  :not(.login-page) .mb-700 {
    margin-bottom: 700px; }
  :not(.login-page) .mr-700 {
    margin-right: 700px; }
  :not(.login-page) .ml-700 {
    margin-left: 700px; }
  :not(.login-page) .mx-700 {
    margin-left: 700px;
    margin-right: 700px; }
  :not(.login-page) .pt-700 {
    padding-top: 700px; }
  :not(.login-page) .pb-700 {
    padding-bottom: 700px; }
  :not(.login-page) .pr-700 {
    padding-right: 700px; }
  :not(.login-page) .pl-700 {
    padding-left: 700px; }
  :not(.login-page) .px-700 {
    padding-left: 700px;
    padding-right: 700px; }
  :not(.login-page) .mt-705 {
    margin-top: 705px; }
  :not(.login-page) .mb-705 {
    margin-bottom: 705px; }
  :not(.login-page) .mr-705 {
    margin-right: 705px; }
  :not(.login-page) .ml-705 {
    margin-left: 705px; }
  :not(.login-page) .mx-705 {
    margin-left: 705px;
    margin-right: 705px; }
  :not(.login-page) .pt-705 {
    padding-top: 705px; }
  :not(.login-page) .pb-705 {
    padding-bottom: 705px; }
  :not(.login-page) .pr-705 {
    padding-right: 705px; }
  :not(.login-page) .pl-705 {
    padding-left: 705px; }
  :not(.login-page) .px-705 {
    padding-left: 705px;
    padding-right: 705px; }
  :not(.login-page) .mt-710 {
    margin-top: 710px; }
  :not(.login-page) .mb-710 {
    margin-bottom: 710px; }
  :not(.login-page) .mr-710 {
    margin-right: 710px; }
  :not(.login-page) .ml-710 {
    margin-left: 710px; }
  :not(.login-page) .mx-710 {
    margin-left: 710px;
    margin-right: 710px; }
  :not(.login-page) .pt-710 {
    padding-top: 710px; }
  :not(.login-page) .pb-710 {
    padding-bottom: 710px; }
  :not(.login-page) .pr-710 {
    padding-right: 710px; }
  :not(.login-page) .pl-710 {
    padding-left: 710px; }
  :not(.login-page) .px-710 {
    padding-left: 710px;
    padding-right: 710px; }
  :not(.login-page) .mt-715 {
    margin-top: 715px; }
  :not(.login-page) .mb-715 {
    margin-bottom: 715px; }
  :not(.login-page) .mr-715 {
    margin-right: 715px; }
  :not(.login-page) .ml-715 {
    margin-left: 715px; }
  :not(.login-page) .mx-715 {
    margin-left: 715px;
    margin-right: 715px; }
  :not(.login-page) .pt-715 {
    padding-top: 715px; }
  :not(.login-page) .pb-715 {
    padding-bottom: 715px; }
  :not(.login-page) .pr-715 {
    padding-right: 715px; }
  :not(.login-page) .pl-715 {
    padding-left: 715px; }
  :not(.login-page) .px-715 {
    padding-left: 715px;
    padding-right: 715px; }
  :not(.login-page) .mt-720 {
    margin-top: 720px; }
  :not(.login-page) .mb-720 {
    margin-bottom: 720px; }
  :not(.login-page) .mr-720 {
    margin-right: 720px; }
  :not(.login-page) .ml-720 {
    margin-left: 720px; }
  :not(.login-page) .mx-720 {
    margin-left: 720px;
    margin-right: 720px; }
  :not(.login-page) .pt-720 {
    padding-top: 720px; }
  :not(.login-page) .pb-720 {
    padding-bottom: 720px; }
  :not(.login-page) .pr-720 {
    padding-right: 720px; }
  :not(.login-page) .pl-720 {
    padding-left: 720px; }
  :not(.login-page) .px-720 {
    padding-left: 720px;
    padding-right: 720px; }
  :not(.login-page) .mt-725 {
    margin-top: 725px; }
  :not(.login-page) .mb-725 {
    margin-bottom: 725px; }
  :not(.login-page) .mr-725 {
    margin-right: 725px; }
  :not(.login-page) .ml-725 {
    margin-left: 725px; }
  :not(.login-page) .mx-725 {
    margin-left: 725px;
    margin-right: 725px; }
  :not(.login-page) .pt-725 {
    padding-top: 725px; }
  :not(.login-page) .pb-725 {
    padding-bottom: 725px; }
  :not(.login-page) .pr-725 {
    padding-right: 725px; }
  :not(.login-page) .pl-725 {
    padding-left: 725px; }
  :not(.login-page) .px-725 {
    padding-left: 725px;
    padding-right: 725px; }
  :not(.login-page) .mt-730 {
    margin-top: 730px; }
  :not(.login-page) .mb-730 {
    margin-bottom: 730px; }
  :not(.login-page) .mr-730 {
    margin-right: 730px; }
  :not(.login-page) .ml-730 {
    margin-left: 730px; }
  :not(.login-page) .mx-730 {
    margin-left: 730px;
    margin-right: 730px; }
  :not(.login-page) .pt-730 {
    padding-top: 730px; }
  :not(.login-page) .pb-730 {
    padding-bottom: 730px; }
  :not(.login-page) .pr-730 {
    padding-right: 730px; }
  :not(.login-page) .pl-730 {
    padding-left: 730px; }
  :not(.login-page) .px-730 {
    padding-left: 730px;
    padding-right: 730px; }
  :not(.login-page) .mt-735 {
    margin-top: 735px; }
  :not(.login-page) .mb-735 {
    margin-bottom: 735px; }
  :not(.login-page) .mr-735 {
    margin-right: 735px; }
  :not(.login-page) .ml-735 {
    margin-left: 735px; }
  :not(.login-page) .mx-735 {
    margin-left: 735px;
    margin-right: 735px; }
  :not(.login-page) .pt-735 {
    padding-top: 735px; }
  :not(.login-page) .pb-735 {
    padding-bottom: 735px; }
  :not(.login-page) .pr-735 {
    padding-right: 735px; }
  :not(.login-page) .pl-735 {
    padding-left: 735px; }
  :not(.login-page) .px-735 {
    padding-left: 735px;
    padding-right: 735px; }
  :not(.login-page) .mt-740 {
    margin-top: 740px; }
  :not(.login-page) .mb-740 {
    margin-bottom: 740px; }
  :not(.login-page) .mr-740 {
    margin-right: 740px; }
  :not(.login-page) .ml-740 {
    margin-left: 740px; }
  :not(.login-page) .mx-740 {
    margin-left: 740px;
    margin-right: 740px; }
  :not(.login-page) .pt-740 {
    padding-top: 740px; }
  :not(.login-page) .pb-740 {
    padding-bottom: 740px; }
  :not(.login-page) .pr-740 {
    padding-right: 740px; }
  :not(.login-page) .pl-740 {
    padding-left: 740px; }
  :not(.login-page) .px-740 {
    padding-left: 740px;
    padding-right: 740px; }
  :not(.login-page) .mt-745 {
    margin-top: 745px; }
  :not(.login-page) .mb-745 {
    margin-bottom: 745px; }
  :not(.login-page) .mr-745 {
    margin-right: 745px; }
  :not(.login-page) .ml-745 {
    margin-left: 745px; }
  :not(.login-page) .mx-745 {
    margin-left: 745px;
    margin-right: 745px; }
  :not(.login-page) .pt-745 {
    padding-top: 745px; }
  :not(.login-page) .pb-745 {
    padding-bottom: 745px; }
  :not(.login-page) .pr-745 {
    padding-right: 745px; }
  :not(.login-page) .pl-745 {
    padding-left: 745px; }
  :not(.login-page) .px-745 {
    padding-left: 745px;
    padding-right: 745px; }
  :not(.login-page) .mt-750 {
    margin-top: 750px; }
  :not(.login-page) .mb-750 {
    margin-bottom: 750px; }
  :not(.login-page) .mr-750 {
    margin-right: 750px; }
  :not(.login-page) .ml-750 {
    margin-left: 750px; }
  :not(.login-page) .mx-750 {
    margin-left: 750px;
    margin-right: 750px; }
  :not(.login-page) .pt-750 {
    padding-top: 750px; }
  :not(.login-page) .pb-750 {
    padding-bottom: 750px; }
  :not(.login-page) .pr-750 {
    padding-right: 750px; }
  :not(.login-page) .pl-750 {
    padding-left: 750px; }
  :not(.login-page) .px-750 {
    padding-left: 750px;
    padding-right: 750px; }
  :not(.login-page) .mt-755 {
    margin-top: 755px; }
  :not(.login-page) .mb-755 {
    margin-bottom: 755px; }
  :not(.login-page) .mr-755 {
    margin-right: 755px; }
  :not(.login-page) .ml-755 {
    margin-left: 755px; }
  :not(.login-page) .mx-755 {
    margin-left: 755px;
    margin-right: 755px; }
  :not(.login-page) .pt-755 {
    padding-top: 755px; }
  :not(.login-page) .pb-755 {
    padding-bottom: 755px; }
  :not(.login-page) .pr-755 {
    padding-right: 755px; }
  :not(.login-page) .pl-755 {
    padding-left: 755px; }
  :not(.login-page) .px-755 {
    padding-left: 755px;
    padding-right: 755px; }
  :not(.login-page) .mt-760 {
    margin-top: 760px; }
  :not(.login-page) .mb-760 {
    margin-bottom: 760px; }
  :not(.login-page) .mr-760 {
    margin-right: 760px; }
  :not(.login-page) .ml-760 {
    margin-left: 760px; }
  :not(.login-page) .mx-760 {
    margin-left: 760px;
    margin-right: 760px; }
  :not(.login-page) .pt-760 {
    padding-top: 760px; }
  :not(.login-page) .pb-760 {
    padding-bottom: 760px; }
  :not(.login-page) .pr-760 {
    padding-right: 760px; }
  :not(.login-page) .pl-760 {
    padding-left: 760px; }
  :not(.login-page) .px-760 {
    padding-left: 760px;
    padding-right: 760px; }
  :not(.login-page) .mt-765 {
    margin-top: 765px; }
  :not(.login-page) .mb-765 {
    margin-bottom: 765px; }
  :not(.login-page) .mr-765 {
    margin-right: 765px; }
  :not(.login-page) .ml-765 {
    margin-left: 765px; }
  :not(.login-page) .mx-765 {
    margin-left: 765px;
    margin-right: 765px; }
  :not(.login-page) .pt-765 {
    padding-top: 765px; }
  :not(.login-page) .pb-765 {
    padding-bottom: 765px; }
  :not(.login-page) .pr-765 {
    padding-right: 765px; }
  :not(.login-page) .pl-765 {
    padding-left: 765px; }
  :not(.login-page) .px-765 {
    padding-left: 765px;
    padding-right: 765px; }
  :not(.login-page) .mt-770 {
    margin-top: 770px; }
  :not(.login-page) .mb-770 {
    margin-bottom: 770px; }
  :not(.login-page) .mr-770 {
    margin-right: 770px; }
  :not(.login-page) .ml-770 {
    margin-left: 770px; }
  :not(.login-page) .mx-770 {
    margin-left: 770px;
    margin-right: 770px; }
  :not(.login-page) .pt-770 {
    padding-top: 770px; }
  :not(.login-page) .pb-770 {
    padding-bottom: 770px; }
  :not(.login-page) .pr-770 {
    padding-right: 770px; }
  :not(.login-page) .pl-770 {
    padding-left: 770px; }
  :not(.login-page) .px-770 {
    padding-left: 770px;
    padding-right: 770px; }
  :not(.login-page) .mt-775 {
    margin-top: 775px; }
  :not(.login-page) .mb-775 {
    margin-bottom: 775px; }
  :not(.login-page) .mr-775 {
    margin-right: 775px; }
  :not(.login-page) .ml-775 {
    margin-left: 775px; }
  :not(.login-page) .mx-775 {
    margin-left: 775px;
    margin-right: 775px; }
  :not(.login-page) .pt-775 {
    padding-top: 775px; }
  :not(.login-page) .pb-775 {
    padding-bottom: 775px; }
  :not(.login-page) .pr-775 {
    padding-right: 775px; }
  :not(.login-page) .pl-775 {
    padding-left: 775px; }
  :not(.login-page) .px-775 {
    padding-left: 775px;
    padding-right: 775px; }
  :not(.login-page) .mt-780 {
    margin-top: 780px; }
  :not(.login-page) .mb-780 {
    margin-bottom: 780px; }
  :not(.login-page) .mr-780 {
    margin-right: 780px; }
  :not(.login-page) .ml-780 {
    margin-left: 780px; }
  :not(.login-page) .mx-780 {
    margin-left: 780px;
    margin-right: 780px; }
  :not(.login-page) .pt-780 {
    padding-top: 780px; }
  :not(.login-page) .pb-780 {
    padding-bottom: 780px; }
  :not(.login-page) .pr-780 {
    padding-right: 780px; }
  :not(.login-page) .pl-780 {
    padding-left: 780px; }
  :not(.login-page) .px-780 {
    padding-left: 780px;
    padding-right: 780px; }
  :not(.login-page) .mt-785 {
    margin-top: 785px; }
  :not(.login-page) .mb-785 {
    margin-bottom: 785px; }
  :not(.login-page) .mr-785 {
    margin-right: 785px; }
  :not(.login-page) .ml-785 {
    margin-left: 785px; }
  :not(.login-page) .mx-785 {
    margin-left: 785px;
    margin-right: 785px; }
  :not(.login-page) .pt-785 {
    padding-top: 785px; }
  :not(.login-page) .pb-785 {
    padding-bottom: 785px; }
  :not(.login-page) .pr-785 {
    padding-right: 785px; }
  :not(.login-page) .pl-785 {
    padding-left: 785px; }
  :not(.login-page) .px-785 {
    padding-left: 785px;
    padding-right: 785px; }
  :not(.login-page) .mt-790 {
    margin-top: 790px; }
  :not(.login-page) .mb-790 {
    margin-bottom: 790px; }
  :not(.login-page) .mr-790 {
    margin-right: 790px; }
  :not(.login-page) .ml-790 {
    margin-left: 790px; }
  :not(.login-page) .mx-790 {
    margin-left: 790px;
    margin-right: 790px; }
  :not(.login-page) .pt-790 {
    padding-top: 790px; }
  :not(.login-page) .pb-790 {
    padding-bottom: 790px; }
  :not(.login-page) .pr-790 {
    padding-right: 790px; }
  :not(.login-page) .pl-790 {
    padding-left: 790px; }
  :not(.login-page) .px-790 {
    padding-left: 790px;
    padding-right: 790px; }
  :not(.login-page) .mt-795 {
    margin-top: 795px; }
  :not(.login-page) .mb-795 {
    margin-bottom: 795px; }
  :not(.login-page) .mr-795 {
    margin-right: 795px; }
  :not(.login-page) .ml-795 {
    margin-left: 795px; }
  :not(.login-page) .mx-795 {
    margin-left: 795px;
    margin-right: 795px; }
  :not(.login-page) .pt-795 {
    padding-top: 795px; }
  :not(.login-page) .pb-795 {
    padding-bottom: 795px; }
  :not(.login-page) .pr-795 {
    padding-right: 795px; }
  :not(.login-page) .pl-795 {
    padding-left: 795px; }
  :not(.login-page) .px-795 {
    padding-left: 795px;
    padding-right: 795px; }
  :not(.login-page) .mt-800 {
    margin-top: 800px; }
  :not(.login-page) .mb-800 {
    margin-bottom: 800px; }
  :not(.login-page) .mr-800 {
    margin-right: 800px; }
  :not(.login-page) .ml-800 {
    margin-left: 800px; }
  :not(.login-page) .mx-800 {
    margin-left: 800px;
    margin-right: 800px; }
  :not(.login-page) .pt-800 {
    padding-top: 800px; }
  :not(.login-page) .pb-800 {
    padding-bottom: 800px; }
  :not(.login-page) .pr-800 {
    padding-right: 800px; }
  :not(.login-page) .pl-800 {
    padding-left: 800px; }
  :not(.login-page) .px-800 {
    padding-left: 800px;
    padding-right: 800px; }
  :not(.login-page) .mt-805 {
    margin-top: 805px; }
  :not(.login-page) .mb-805 {
    margin-bottom: 805px; }
  :not(.login-page) .mr-805 {
    margin-right: 805px; }
  :not(.login-page) .ml-805 {
    margin-left: 805px; }
  :not(.login-page) .mx-805 {
    margin-left: 805px;
    margin-right: 805px; }
  :not(.login-page) .pt-805 {
    padding-top: 805px; }
  :not(.login-page) .pb-805 {
    padding-bottom: 805px; }
  :not(.login-page) .pr-805 {
    padding-right: 805px; }
  :not(.login-page) .pl-805 {
    padding-left: 805px; }
  :not(.login-page) .px-805 {
    padding-left: 805px;
    padding-right: 805px; }
  :not(.login-page) .mt-810 {
    margin-top: 810px; }
  :not(.login-page) .mb-810 {
    margin-bottom: 810px; }
  :not(.login-page) .mr-810 {
    margin-right: 810px; }
  :not(.login-page) .ml-810 {
    margin-left: 810px; }
  :not(.login-page) .mx-810 {
    margin-left: 810px;
    margin-right: 810px; }
  :not(.login-page) .pt-810 {
    padding-top: 810px; }
  :not(.login-page) .pb-810 {
    padding-bottom: 810px; }
  :not(.login-page) .pr-810 {
    padding-right: 810px; }
  :not(.login-page) .pl-810 {
    padding-left: 810px; }
  :not(.login-page) .px-810 {
    padding-left: 810px;
    padding-right: 810px; }
  :not(.login-page) .mt-815 {
    margin-top: 815px; }
  :not(.login-page) .mb-815 {
    margin-bottom: 815px; }
  :not(.login-page) .mr-815 {
    margin-right: 815px; }
  :not(.login-page) .ml-815 {
    margin-left: 815px; }
  :not(.login-page) .mx-815 {
    margin-left: 815px;
    margin-right: 815px; }
  :not(.login-page) .pt-815 {
    padding-top: 815px; }
  :not(.login-page) .pb-815 {
    padding-bottom: 815px; }
  :not(.login-page) .pr-815 {
    padding-right: 815px; }
  :not(.login-page) .pl-815 {
    padding-left: 815px; }
  :not(.login-page) .px-815 {
    padding-left: 815px;
    padding-right: 815px; }
  :not(.login-page) .mt-820 {
    margin-top: 820px; }
  :not(.login-page) .mb-820 {
    margin-bottom: 820px; }
  :not(.login-page) .mr-820 {
    margin-right: 820px; }
  :not(.login-page) .ml-820 {
    margin-left: 820px; }
  :not(.login-page) .mx-820 {
    margin-left: 820px;
    margin-right: 820px; }
  :not(.login-page) .pt-820 {
    padding-top: 820px; }
  :not(.login-page) .pb-820 {
    padding-bottom: 820px; }
  :not(.login-page) .pr-820 {
    padding-right: 820px; }
  :not(.login-page) .pl-820 {
    padding-left: 820px; }
  :not(.login-page) .px-820 {
    padding-left: 820px;
    padding-right: 820px; }
  :not(.login-page) .mt-825 {
    margin-top: 825px; }
  :not(.login-page) .mb-825 {
    margin-bottom: 825px; }
  :not(.login-page) .mr-825 {
    margin-right: 825px; }
  :not(.login-page) .ml-825 {
    margin-left: 825px; }
  :not(.login-page) .mx-825 {
    margin-left: 825px;
    margin-right: 825px; }
  :not(.login-page) .pt-825 {
    padding-top: 825px; }
  :not(.login-page) .pb-825 {
    padding-bottom: 825px; }
  :not(.login-page) .pr-825 {
    padding-right: 825px; }
  :not(.login-page) .pl-825 {
    padding-left: 825px; }
  :not(.login-page) .px-825 {
    padding-left: 825px;
    padding-right: 825px; }
  :not(.login-page) .mt-830 {
    margin-top: 830px; }
  :not(.login-page) .mb-830 {
    margin-bottom: 830px; }
  :not(.login-page) .mr-830 {
    margin-right: 830px; }
  :not(.login-page) .ml-830 {
    margin-left: 830px; }
  :not(.login-page) .mx-830 {
    margin-left: 830px;
    margin-right: 830px; }
  :not(.login-page) .pt-830 {
    padding-top: 830px; }
  :not(.login-page) .pb-830 {
    padding-bottom: 830px; }
  :not(.login-page) .pr-830 {
    padding-right: 830px; }
  :not(.login-page) .pl-830 {
    padding-left: 830px; }
  :not(.login-page) .px-830 {
    padding-left: 830px;
    padding-right: 830px; }
  :not(.login-page) .mt-835 {
    margin-top: 835px; }
  :not(.login-page) .mb-835 {
    margin-bottom: 835px; }
  :not(.login-page) .mr-835 {
    margin-right: 835px; }
  :not(.login-page) .ml-835 {
    margin-left: 835px; }
  :not(.login-page) .mx-835 {
    margin-left: 835px;
    margin-right: 835px; }
  :not(.login-page) .pt-835 {
    padding-top: 835px; }
  :not(.login-page) .pb-835 {
    padding-bottom: 835px; }
  :not(.login-page) .pr-835 {
    padding-right: 835px; }
  :not(.login-page) .pl-835 {
    padding-left: 835px; }
  :not(.login-page) .px-835 {
    padding-left: 835px;
    padding-right: 835px; }
  :not(.login-page) .mt-840 {
    margin-top: 840px; }
  :not(.login-page) .mb-840 {
    margin-bottom: 840px; }
  :not(.login-page) .mr-840 {
    margin-right: 840px; }
  :not(.login-page) .ml-840 {
    margin-left: 840px; }
  :not(.login-page) .mx-840 {
    margin-left: 840px;
    margin-right: 840px; }
  :not(.login-page) .pt-840 {
    padding-top: 840px; }
  :not(.login-page) .pb-840 {
    padding-bottom: 840px; }
  :not(.login-page) .pr-840 {
    padding-right: 840px; }
  :not(.login-page) .pl-840 {
    padding-left: 840px; }
  :not(.login-page) .px-840 {
    padding-left: 840px;
    padding-right: 840px; }
  :not(.login-page) .mt-845 {
    margin-top: 845px; }
  :not(.login-page) .mb-845 {
    margin-bottom: 845px; }
  :not(.login-page) .mr-845 {
    margin-right: 845px; }
  :not(.login-page) .ml-845 {
    margin-left: 845px; }
  :not(.login-page) .mx-845 {
    margin-left: 845px;
    margin-right: 845px; }
  :not(.login-page) .pt-845 {
    padding-top: 845px; }
  :not(.login-page) .pb-845 {
    padding-bottom: 845px; }
  :not(.login-page) .pr-845 {
    padding-right: 845px; }
  :not(.login-page) .pl-845 {
    padding-left: 845px; }
  :not(.login-page) .px-845 {
    padding-left: 845px;
    padding-right: 845px; }
  :not(.login-page) .mt-850 {
    margin-top: 850px; }
  :not(.login-page) .mb-850 {
    margin-bottom: 850px; }
  :not(.login-page) .mr-850 {
    margin-right: 850px; }
  :not(.login-page) .ml-850 {
    margin-left: 850px; }
  :not(.login-page) .mx-850 {
    margin-left: 850px;
    margin-right: 850px; }
  :not(.login-page) .pt-850 {
    padding-top: 850px; }
  :not(.login-page) .pb-850 {
    padding-bottom: 850px; }
  :not(.login-page) .pr-850 {
    padding-right: 850px; }
  :not(.login-page) .pl-850 {
    padding-left: 850px; }
  :not(.login-page) .px-850 {
    padding-left: 850px;
    padding-right: 850px; }
  :not(.login-page) .mt-855 {
    margin-top: 855px; }
  :not(.login-page) .mb-855 {
    margin-bottom: 855px; }
  :not(.login-page) .mr-855 {
    margin-right: 855px; }
  :not(.login-page) .ml-855 {
    margin-left: 855px; }
  :not(.login-page) .mx-855 {
    margin-left: 855px;
    margin-right: 855px; }
  :not(.login-page) .pt-855 {
    padding-top: 855px; }
  :not(.login-page) .pb-855 {
    padding-bottom: 855px; }
  :not(.login-page) .pr-855 {
    padding-right: 855px; }
  :not(.login-page) .pl-855 {
    padding-left: 855px; }
  :not(.login-page) .px-855 {
    padding-left: 855px;
    padding-right: 855px; }
  :not(.login-page) .mt-860 {
    margin-top: 860px; }
  :not(.login-page) .mb-860 {
    margin-bottom: 860px; }
  :not(.login-page) .mr-860 {
    margin-right: 860px; }
  :not(.login-page) .ml-860 {
    margin-left: 860px; }
  :not(.login-page) .mx-860 {
    margin-left: 860px;
    margin-right: 860px; }
  :not(.login-page) .pt-860 {
    padding-top: 860px; }
  :not(.login-page) .pb-860 {
    padding-bottom: 860px; }
  :not(.login-page) .pr-860 {
    padding-right: 860px; }
  :not(.login-page) .pl-860 {
    padding-left: 860px; }
  :not(.login-page) .px-860 {
    padding-left: 860px;
    padding-right: 860px; }
  :not(.login-page) .mt-865 {
    margin-top: 865px; }
  :not(.login-page) .mb-865 {
    margin-bottom: 865px; }
  :not(.login-page) .mr-865 {
    margin-right: 865px; }
  :not(.login-page) .ml-865 {
    margin-left: 865px; }
  :not(.login-page) .mx-865 {
    margin-left: 865px;
    margin-right: 865px; }
  :not(.login-page) .pt-865 {
    padding-top: 865px; }
  :not(.login-page) .pb-865 {
    padding-bottom: 865px; }
  :not(.login-page) .pr-865 {
    padding-right: 865px; }
  :not(.login-page) .pl-865 {
    padding-left: 865px; }
  :not(.login-page) .px-865 {
    padding-left: 865px;
    padding-right: 865px; }
  :not(.login-page) .mt-870 {
    margin-top: 870px; }
  :not(.login-page) .mb-870 {
    margin-bottom: 870px; }
  :not(.login-page) .mr-870 {
    margin-right: 870px; }
  :not(.login-page) .ml-870 {
    margin-left: 870px; }
  :not(.login-page) .mx-870 {
    margin-left: 870px;
    margin-right: 870px; }
  :not(.login-page) .pt-870 {
    padding-top: 870px; }
  :not(.login-page) .pb-870 {
    padding-bottom: 870px; }
  :not(.login-page) .pr-870 {
    padding-right: 870px; }
  :not(.login-page) .pl-870 {
    padding-left: 870px; }
  :not(.login-page) .px-870 {
    padding-left: 870px;
    padding-right: 870px; }
  :not(.login-page) .mt-875 {
    margin-top: 875px; }
  :not(.login-page) .mb-875 {
    margin-bottom: 875px; }
  :not(.login-page) .mr-875 {
    margin-right: 875px; }
  :not(.login-page) .ml-875 {
    margin-left: 875px; }
  :not(.login-page) .mx-875 {
    margin-left: 875px;
    margin-right: 875px; }
  :not(.login-page) .pt-875 {
    padding-top: 875px; }
  :not(.login-page) .pb-875 {
    padding-bottom: 875px; }
  :not(.login-page) .pr-875 {
    padding-right: 875px; }
  :not(.login-page) .pl-875 {
    padding-left: 875px; }
  :not(.login-page) .px-875 {
    padding-left: 875px;
    padding-right: 875px; }
  :not(.login-page) .mt-880 {
    margin-top: 880px; }
  :not(.login-page) .mb-880 {
    margin-bottom: 880px; }
  :not(.login-page) .mr-880 {
    margin-right: 880px; }
  :not(.login-page) .ml-880 {
    margin-left: 880px; }
  :not(.login-page) .mx-880 {
    margin-left: 880px;
    margin-right: 880px; }
  :not(.login-page) .pt-880 {
    padding-top: 880px; }
  :not(.login-page) .pb-880 {
    padding-bottom: 880px; }
  :not(.login-page) .pr-880 {
    padding-right: 880px; }
  :not(.login-page) .pl-880 {
    padding-left: 880px; }
  :not(.login-page) .px-880 {
    padding-left: 880px;
    padding-right: 880px; }
  :not(.login-page) .mt-885 {
    margin-top: 885px; }
  :not(.login-page) .mb-885 {
    margin-bottom: 885px; }
  :not(.login-page) .mr-885 {
    margin-right: 885px; }
  :not(.login-page) .ml-885 {
    margin-left: 885px; }
  :not(.login-page) .mx-885 {
    margin-left: 885px;
    margin-right: 885px; }
  :not(.login-page) .pt-885 {
    padding-top: 885px; }
  :not(.login-page) .pb-885 {
    padding-bottom: 885px; }
  :not(.login-page) .pr-885 {
    padding-right: 885px; }
  :not(.login-page) .pl-885 {
    padding-left: 885px; }
  :not(.login-page) .px-885 {
    padding-left: 885px;
    padding-right: 885px; }
  :not(.login-page) .mt-890 {
    margin-top: 890px; }
  :not(.login-page) .mb-890 {
    margin-bottom: 890px; }
  :not(.login-page) .mr-890 {
    margin-right: 890px; }
  :not(.login-page) .ml-890 {
    margin-left: 890px; }
  :not(.login-page) .mx-890 {
    margin-left: 890px;
    margin-right: 890px; }
  :not(.login-page) .pt-890 {
    padding-top: 890px; }
  :not(.login-page) .pb-890 {
    padding-bottom: 890px; }
  :not(.login-page) .pr-890 {
    padding-right: 890px; }
  :not(.login-page) .pl-890 {
    padding-left: 890px; }
  :not(.login-page) .px-890 {
    padding-left: 890px;
    padding-right: 890px; }
  :not(.login-page) .mt-895 {
    margin-top: 895px; }
  :not(.login-page) .mb-895 {
    margin-bottom: 895px; }
  :not(.login-page) .mr-895 {
    margin-right: 895px; }
  :not(.login-page) .ml-895 {
    margin-left: 895px; }
  :not(.login-page) .mx-895 {
    margin-left: 895px;
    margin-right: 895px; }
  :not(.login-page) .pt-895 {
    padding-top: 895px; }
  :not(.login-page) .pb-895 {
    padding-bottom: 895px; }
  :not(.login-page) .pr-895 {
    padding-right: 895px; }
  :not(.login-page) .pl-895 {
    padding-left: 895px; }
  :not(.login-page) .px-895 {
    padding-left: 895px;
    padding-right: 895px; }
  :not(.login-page) .mt-900 {
    margin-top: 900px; }
  :not(.login-page) .mb-900 {
    margin-bottom: 900px; }
  :not(.login-page) .mr-900 {
    margin-right: 900px; }
  :not(.login-page) .ml-900 {
    margin-left: 900px; }
  :not(.login-page) .mx-900 {
    margin-left: 900px;
    margin-right: 900px; }
  :not(.login-page) .pt-900 {
    padding-top: 900px; }
  :not(.login-page) .pb-900 {
    padding-bottom: 900px; }
  :not(.login-page) .pr-900 {
    padding-right: 900px; }
  :not(.login-page) .pl-900 {
    padding-left: 900px; }
  :not(.login-page) .px-900 {
    padding-left: 900px;
    padding-right: 900px; }
  :not(.login-page) .mt-905 {
    margin-top: 905px; }
  :not(.login-page) .mb-905 {
    margin-bottom: 905px; }
  :not(.login-page) .mr-905 {
    margin-right: 905px; }
  :not(.login-page) .ml-905 {
    margin-left: 905px; }
  :not(.login-page) .mx-905 {
    margin-left: 905px;
    margin-right: 905px; }
  :not(.login-page) .pt-905 {
    padding-top: 905px; }
  :not(.login-page) .pb-905 {
    padding-bottom: 905px; }
  :not(.login-page) .pr-905 {
    padding-right: 905px; }
  :not(.login-page) .pl-905 {
    padding-left: 905px; }
  :not(.login-page) .px-905 {
    padding-left: 905px;
    padding-right: 905px; }
  :not(.login-page) .mt-910 {
    margin-top: 910px; }
  :not(.login-page) .mb-910 {
    margin-bottom: 910px; }
  :not(.login-page) .mr-910 {
    margin-right: 910px; }
  :not(.login-page) .ml-910 {
    margin-left: 910px; }
  :not(.login-page) .mx-910 {
    margin-left: 910px;
    margin-right: 910px; }
  :not(.login-page) .pt-910 {
    padding-top: 910px; }
  :not(.login-page) .pb-910 {
    padding-bottom: 910px; }
  :not(.login-page) .pr-910 {
    padding-right: 910px; }
  :not(.login-page) .pl-910 {
    padding-left: 910px; }
  :not(.login-page) .px-910 {
    padding-left: 910px;
    padding-right: 910px; }
  :not(.login-page) .mt-915 {
    margin-top: 915px; }
  :not(.login-page) .mb-915 {
    margin-bottom: 915px; }
  :not(.login-page) .mr-915 {
    margin-right: 915px; }
  :not(.login-page) .ml-915 {
    margin-left: 915px; }
  :not(.login-page) .mx-915 {
    margin-left: 915px;
    margin-right: 915px; }
  :not(.login-page) .pt-915 {
    padding-top: 915px; }
  :not(.login-page) .pb-915 {
    padding-bottom: 915px; }
  :not(.login-page) .pr-915 {
    padding-right: 915px; }
  :not(.login-page) .pl-915 {
    padding-left: 915px; }
  :not(.login-page) .px-915 {
    padding-left: 915px;
    padding-right: 915px; }
  :not(.login-page) .mt-920 {
    margin-top: 920px; }
  :not(.login-page) .mb-920 {
    margin-bottom: 920px; }
  :not(.login-page) .mr-920 {
    margin-right: 920px; }
  :not(.login-page) .ml-920 {
    margin-left: 920px; }
  :not(.login-page) .mx-920 {
    margin-left: 920px;
    margin-right: 920px; }
  :not(.login-page) .pt-920 {
    padding-top: 920px; }
  :not(.login-page) .pb-920 {
    padding-bottom: 920px; }
  :not(.login-page) .pr-920 {
    padding-right: 920px; }
  :not(.login-page) .pl-920 {
    padding-left: 920px; }
  :not(.login-page) .px-920 {
    padding-left: 920px;
    padding-right: 920px; }
  :not(.login-page) .mt-925 {
    margin-top: 925px; }
  :not(.login-page) .mb-925 {
    margin-bottom: 925px; }
  :not(.login-page) .mr-925 {
    margin-right: 925px; }
  :not(.login-page) .ml-925 {
    margin-left: 925px; }
  :not(.login-page) .mx-925 {
    margin-left: 925px;
    margin-right: 925px; }
  :not(.login-page) .pt-925 {
    padding-top: 925px; }
  :not(.login-page) .pb-925 {
    padding-bottom: 925px; }
  :not(.login-page) .pr-925 {
    padding-right: 925px; }
  :not(.login-page) .pl-925 {
    padding-left: 925px; }
  :not(.login-page) .px-925 {
    padding-left: 925px;
    padding-right: 925px; }
  :not(.login-page) .mt-930 {
    margin-top: 930px; }
  :not(.login-page) .mb-930 {
    margin-bottom: 930px; }
  :not(.login-page) .mr-930 {
    margin-right: 930px; }
  :not(.login-page) .ml-930 {
    margin-left: 930px; }
  :not(.login-page) .mx-930 {
    margin-left: 930px;
    margin-right: 930px; }
  :not(.login-page) .pt-930 {
    padding-top: 930px; }
  :not(.login-page) .pb-930 {
    padding-bottom: 930px; }
  :not(.login-page) .pr-930 {
    padding-right: 930px; }
  :not(.login-page) .pl-930 {
    padding-left: 930px; }
  :not(.login-page) .px-930 {
    padding-left: 930px;
    padding-right: 930px; }
  :not(.login-page) .mt-935 {
    margin-top: 935px; }
  :not(.login-page) .mb-935 {
    margin-bottom: 935px; }
  :not(.login-page) .mr-935 {
    margin-right: 935px; }
  :not(.login-page) .ml-935 {
    margin-left: 935px; }
  :not(.login-page) .mx-935 {
    margin-left: 935px;
    margin-right: 935px; }
  :not(.login-page) .pt-935 {
    padding-top: 935px; }
  :not(.login-page) .pb-935 {
    padding-bottom: 935px; }
  :not(.login-page) .pr-935 {
    padding-right: 935px; }
  :not(.login-page) .pl-935 {
    padding-left: 935px; }
  :not(.login-page) .px-935 {
    padding-left: 935px;
    padding-right: 935px; }
  :not(.login-page) .mt-940 {
    margin-top: 940px; }
  :not(.login-page) .mb-940 {
    margin-bottom: 940px; }
  :not(.login-page) .mr-940 {
    margin-right: 940px; }
  :not(.login-page) .ml-940 {
    margin-left: 940px; }
  :not(.login-page) .mx-940 {
    margin-left: 940px;
    margin-right: 940px; }
  :not(.login-page) .pt-940 {
    padding-top: 940px; }
  :not(.login-page) .pb-940 {
    padding-bottom: 940px; }
  :not(.login-page) .pr-940 {
    padding-right: 940px; }
  :not(.login-page) .pl-940 {
    padding-left: 940px; }
  :not(.login-page) .px-940 {
    padding-left: 940px;
    padding-right: 940px; }
  :not(.login-page) .mt-945 {
    margin-top: 945px; }
  :not(.login-page) .mb-945 {
    margin-bottom: 945px; }
  :not(.login-page) .mr-945 {
    margin-right: 945px; }
  :not(.login-page) .ml-945 {
    margin-left: 945px; }
  :not(.login-page) .mx-945 {
    margin-left: 945px;
    margin-right: 945px; }
  :not(.login-page) .pt-945 {
    padding-top: 945px; }
  :not(.login-page) .pb-945 {
    padding-bottom: 945px; }
  :not(.login-page) .pr-945 {
    padding-right: 945px; }
  :not(.login-page) .pl-945 {
    padding-left: 945px; }
  :not(.login-page) .px-945 {
    padding-left: 945px;
    padding-right: 945px; }
  :not(.login-page) .mt-950 {
    margin-top: 950px; }
  :not(.login-page) .mb-950 {
    margin-bottom: 950px; }
  :not(.login-page) .mr-950 {
    margin-right: 950px; }
  :not(.login-page) .ml-950 {
    margin-left: 950px; }
  :not(.login-page) .mx-950 {
    margin-left: 950px;
    margin-right: 950px; }
  :not(.login-page) .pt-950 {
    padding-top: 950px; }
  :not(.login-page) .pb-950 {
    padding-bottom: 950px; }
  :not(.login-page) .pr-950 {
    padding-right: 950px; }
  :not(.login-page) .pl-950 {
    padding-left: 950px; }
  :not(.login-page) .px-950 {
    padding-left: 950px;
    padding-right: 950px; }
  :not(.login-page) .mt-955 {
    margin-top: 955px; }
  :not(.login-page) .mb-955 {
    margin-bottom: 955px; }
  :not(.login-page) .mr-955 {
    margin-right: 955px; }
  :not(.login-page) .ml-955 {
    margin-left: 955px; }
  :not(.login-page) .mx-955 {
    margin-left: 955px;
    margin-right: 955px; }
  :not(.login-page) .pt-955 {
    padding-top: 955px; }
  :not(.login-page) .pb-955 {
    padding-bottom: 955px; }
  :not(.login-page) .pr-955 {
    padding-right: 955px; }
  :not(.login-page) .pl-955 {
    padding-left: 955px; }
  :not(.login-page) .px-955 {
    padding-left: 955px;
    padding-right: 955px; }
  :not(.login-page) .mt-960 {
    margin-top: 960px; }
  :not(.login-page) .mb-960 {
    margin-bottom: 960px; }
  :not(.login-page) .mr-960 {
    margin-right: 960px; }
  :not(.login-page) .ml-960 {
    margin-left: 960px; }
  :not(.login-page) .mx-960 {
    margin-left: 960px;
    margin-right: 960px; }
  :not(.login-page) .pt-960 {
    padding-top: 960px; }
  :not(.login-page) .pb-960 {
    padding-bottom: 960px; }
  :not(.login-page) .pr-960 {
    padding-right: 960px; }
  :not(.login-page) .pl-960 {
    padding-left: 960px; }
  :not(.login-page) .px-960 {
    padding-left: 960px;
    padding-right: 960px; }
  :not(.login-page) .mt-965 {
    margin-top: 965px; }
  :not(.login-page) .mb-965 {
    margin-bottom: 965px; }
  :not(.login-page) .mr-965 {
    margin-right: 965px; }
  :not(.login-page) .ml-965 {
    margin-left: 965px; }
  :not(.login-page) .mx-965 {
    margin-left: 965px;
    margin-right: 965px; }
  :not(.login-page) .pt-965 {
    padding-top: 965px; }
  :not(.login-page) .pb-965 {
    padding-bottom: 965px; }
  :not(.login-page) .pr-965 {
    padding-right: 965px; }
  :not(.login-page) .pl-965 {
    padding-left: 965px; }
  :not(.login-page) .px-965 {
    padding-left: 965px;
    padding-right: 965px; }
  :not(.login-page) .mt-970 {
    margin-top: 970px; }
  :not(.login-page) .mb-970 {
    margin-bottom: 970px; }
  :not(.login-page) .mr-970 {
    margin-right: 970px; }
  :not(.login-page) .ml-970 {
    margin-left: 970px; }
  :not(.login-page) .mx-970 {
    margin-left: 970px;
    margin-right: 970px; }
  :not(.login-page) .pt-970 {
    padding-top: 970px; }
  :not(.login-page) .pb-970 {
    padding-bottom: 970px; }
  :not(.login-page) .pr-970 {
    padding-right: 970px; }
  :not(.login-page) .pl-970 {
    padding-left: 970px; }
  :not(.login-page) .px-970 {
    padding-left: 970px;
    padding-right: 970px; }
  :not(.login-page) .mt-975 {
    margin-top: 975px; }
  :not(.login-page) .mb-975 {
    margin-bottom: 975px; }
  :not(.login-page) .mr-975 {
    margin-right: 975px; }
  :not(.login-page) .ml-975 {
    margin-left: 975px; }
  :not(.login-page) .mx-975 {
    margin-left: 975px;
    margin-right: 975px; }
  :not(.login-page) .pt-975 {
    padding-top: 975px; }
  :not(.login-page) .pb-975 {
    padding-bottom: 975px; }
  :not(.login-page) .pr-975 {
    padding-right: 975px; }
  :not(.login-page) .pl-975 {
    padding-left: 975px; }
  :not(.login-page) .px-975 {
    padding-left: 975px;
    padding-right: 975px; }
  :not(.login-page) .mt-980 {
    margin-top: 980px; }
  :not(.login-page) .mb-980 {
    margin-bottom: 980px; }
  :not(.login-page) .mr-980 {
    margin-right: 980px; }
  :not(.login-page) .ml-980 {
    margin-left: 980px; }
  :not(.login-page) .mx-980 {
    margin-left: 980px;
    margin-right: 980px; }
  :not(.login-page) .pt-980 {
    padding-top: 980px; }
  :not(.login-page) .pb-980 {
    padding-bottom: 980px; }
  :not(.login-page) .pr-980 {
    padding-right: 980px; }
  :not(.login-page) .pl-980 {
    padding-left: 980px; }
  :not(.login-page) .px-980 {
    padding-left: 980px;
    padding-right: 980px; }
  :not(.login-page) .mt-985 {
    margin-top: 985px; }
  :not(.login-page) .mb-985 {
    margin-bottom: 985px; }
  :not(.login-page) .mr-985 {
    margin-right: 985px; }
  :not(.login-page) .ml-985 {
    margin-left: 985px; }
  :not(.login-page) .mx-985 {
    margin-left: 985px;
    margin-right: 985px; }
  :not(.login-page) .pt-985 {
    padding-top: 985px; }
  :not(.login-page) .pb-985 {
    padding-bottom: 985px; }
  :not(.login-page) .pr-985 {
    padding-right: 985px; }
  :not(.login-page) .pl-985 {
    padding-left: 985px; }
  :not(.login-page) .px-985 {
    padding-left: 985px;
    padding-right: 985px; }
  :not(.login-page) .mt-990 {
    margin-top: 990px; }
  :not(.login-page) .mb-990 {
    margin-bottom: 990px; }
  :not(.login-page) .mr-990 {
    margin-right: 990px; }
  :not(.login-page) .ml-990 {
    margin-left: 990px; }
  :not(.login-page) .mx-990 {
    margin-left: 990px;
    margin-right: 990px; }
  :not(.login-page) .pt-990 {
    padding-top: 990px; }
  :not(.login-page) .pb-990 {
    padding-bottom: 990px; }
  :not(.login-page) .pr-990 {
    padding-right: 990px; }
  :not(.login-page) .pl-990 {
    padding-left: 990px; }
  :not(.login-page) .px-990 {
    padding-left: 990px;
    padding-right: 990px; }
  :not(.login-page) .mt-995 {
    margin-top: 995px; }
  :not(.login-page) .mb-995 {
    margin-bottom: 995px; }
  :not(.login-page) .mr-995 {
    margin-right: 995px; }
  :not(.login-page) .ml-995 {
    margin-left: 995px; }
  :not(.login-page) .mx-995 {
    margin-left: 995px;
    margin-right: 995px; }
  :not(.login-page) .pt-995 {
    padding-top: 995px; }
  :not(.login-page) .pb-995 {
    padding-bottom: 995px; }
  :not(.login-page) .pr-995 {
    padding-right: 995px; }
  :not(.login-page) .pl-995 {
    padding-left: 995px; }
  :not(.login-page) .px-995 {
    padding-left: 995px;
    padding-right: 995px; }
  :not(.login-page) .mt-1000 {
    margin-top: 1000px; }
  :not(.login-page) .mb-1000 {
    margin-bottom: 1000px; }
  :not(.login-page) .mr-1000 {
    margin-right: 1000px; }
  :not(.login-page) .ml-1000 {
    margin-left: 1000px; }
  :not(.login-page) .mx-1000 {
    margin-left: 1000px;
    margin-right: 1000px; }
  :not(.login-page) .pt-1000 {
    padding-top: 1000px; }
  :not(.login-page) .pb-1000 {
    padding-bottom: 1000px; }
  :not(.login-page) .pr-1000 {
    padding-right: 1000px; }
  :not(.login-page) .pl-1000 {
    padding-left: 1000px; }
  :not(.login-page) .px-1000 {
    padding-left: 1000px;
    padding-right: 1000px; }
  :not(.login-page) .w-0 {
    width: 0px; }
  :not(.login-page) .mw-0 {
    max-width: 0px; }
  :not(.login-page) .h-0 {
    height: 0px; }
  :not(.login-page) .mh-0 {
    max-height: 0px; }
  :not(.login-page) .w-10 {
    width: 10px; }
  :not(.login-page) .mw-10 {
    max-width: 10px; }
  :not(.login-page) .h-10 {
    height: 10px; }
  :not(.login-page) .mh-10 {
    max-height: 10px; }
  :not(.login-page) .w-20 {
    width: 20px; }
  :not(.login-page) .mw-20 {
    max-width: 20px; }
  :not(.login-page) .h-20 {
    height: 20px; }
  :not(.login-page) .mh-20 {
    max-height: 20px; }
  :not(.login-page) .w-30 {
    width: 30px; }
  :not(.login-page) .mw-30 {
    max-width: 30px; }
  :not(.login-page) .h-30 {
    height: 30px; }
  :not(.login-page) .mh-30 {
    max-height: 30px; }
  :not(.login-page) .w-40 {
    width: 40px; }
  :not(.login-page) .mw-40 {
    max-width: 40px; }
  :not(.login-page) .h-40 {
    height: 40px; }
  :not(.login-page) .mh-40 {
    max-height: 40px; }
  :not(.login-page) .w-50 {
    width: 50px; }
  :not(.login-page) .mw-50 {
    max-width: 50px; }
  :not(.login-page) .h-50 {
    height: 50px; }
  :not(.login-page) .mh-50 {
    max-height: 50px; }
  :not(.login-page) .w-60 {
    width: 60px; }
  :not(.login-page) .mw-60 {
    max-width: 60px; }
  :not(.login-page) .h-60 {
    height: 60px; }
  :not(.login-page) .mh-60 {
    max-height: 60px; }
  :not(.login-page) .w-70 {
    width: 70px; }
  :not(.login-page) .mw-70 {
    max-width: 70px; }
  :not(.login-page) .h-70 {
    height: 70px; }
  :not(.login-page) .mh-70 {
    max-height: 70px; }
  :not(.login-page) .w-80 {
    width: 80px; }
  :not(.login-page) .mw-80 {
    max-width: 80px; }
  :not(.login-page) .h-80 {
    height: 80px; }
  :not(.login-page) .mh-80 {
    max-height: 80px; }
  :not(.login-page) .w-90 {
    width: 90px; }
  :not(.login-page) .mw-90 {
    max-width: 90px; }
  :not(.login-page) .h-90 {
    height: 90px; }
  :not(.login-page) .mh-90 {
    max-height: 90px; }
  :not(.login-page) .w-100 {
    width: 100px; }
  :not(.login-page) .mw-100 {
    max-width: 100px; }
  :not(.login-page) .h-100 {
    height: 100px; }
  :not(.login-page) .mh-100 {
    max-height: 100px; }
  :not(.login-page) .w-110 {
    width: 110px; }
  :not(.login-page) .mw-110 {
    max-width: 110px; }
  :not(.login-page) .h-110 {
    height: 110px; }
  :not(.login-page) .mh-110 {
    max-height: 110px; }
  :not(.login-page) .w-120 {
    width: 120px; }
  :not(.login-page) .mw-120 {
    max-width: 120px; }
  :not(.login-page) .h-120 {
    height: 120px; }
  :not(.login-page) .mh-120 {
    max-height: 120px; }
  :not(.login-page) .w-130 {
    width: 130px; }
  :not(.login-page) .mw-130 {
    max-width: 130px; }
  :not(.login-page) .h-130 {
    height: 130px; }
  :not(.login-page) .mh-130 {
    max-height: 130px; }
  :not(.login-page) .w-140 {
    width: 140px; }
  :not(.login-page) .mw-140 {
    max-width: 140px; }
  :not(.login-page) .h-140 {
    height: 140px; }
  :not(.login-page) .mh-140 {
    max-height: 140px; }
  :not(.login-page) .w-150 {
    width: 150px; }
  :not(.login-page) .mw-150 {
    max-width: 150px; }
  :not(.login-page) .h-150 {
    height: 150px; }
  :not(.login-page) .mh-150 {
    max-height: 150px; }
  :not(.login-page) .w-160 {
    width: 160px; }
  :not(.login-page) .mw-160 {
    max-width: 160px; }
  :not(.login-page) .h-160 {
    height: 160px; }
  :not(.login-page) .mh-160 {
    max-height: 160px; }
  :not(.login-page) .w-170 {
    width: 170px; }
  :not(.login-page) .mw-170 {
    max-width: 170px; }
  :not(.login-page) .h-170 {
    height: 170px; }
  :not(.login-page) .mh-170 {
    max-height: 170px; }
  :not(.login-page) .w-180 {
    width: 180px; }
  :not(.login-page) .mw-180 {
    max-width: 180px; }
  :not(.login-page) .h-180 {
    height: 180px; }
  :not(.login-page) .mh-180 {
    max-height: 180px; }
  :not(.login-page) .w-190 {
    width: 190px; }
  :not(.login-page) .mw-190 {
    max-width: 190px; }
  :not(.login-page) .h-190 {
    height: 190px; }
  :not(.login-page) .mh-190 {
    max-height: 190px; }
  :not(.login-page) .w-200 {
    width: 200px; }
  :not(.login-page) .mw-200 {
    max-width: 200px; }
  :not(.login-page) .h-200 {
    height: 200px; }
  :not(.login-page) .mh-200 {
    max-height: 200px; }
  :not(.login-page) .w-210 {
    width: 210px; }
  :not(.login-page) .mw-210 {
    max-width: 210px; }
  :not(.login-page) .h-210 {
    height: 210px; }
  :not(.login-page) .mh-210 {
    max-height: 210px; }
  :not(.login-page) .w-220 {
    width: 220px; }
  :not(.login-page) .mw-220 {
    max-width: 220px; }
  :not(.login-page) .h-220 {
    height: 220px; }
  :not(.login-page) .mh-220 {
    max-height: 220px; }
  :not(.login-page) .w-230 {
    width: 230px; }
  :not(.login-page) .mw-230 {
    max-width: 230px; }
  :not(.login-page) .h-230 {
    height: 230px; }
  :not(.login-page) .mh-230 {
    max-height: 230px; }
  :not(.login-page) .w-240 {
    width: 240px; }
  :not(.login-page) .mw-240 {
    max-width: 240px; }
  :not(.login-page) .h-240 {
    height: 240px; }
  :not(.login-page) .mh-240 {
    max-height: 240px; }
  :not(.login-page) .w-250 {
    width: 250px; }
  :not(.login-page) .mw-250 {
    max-width: 250px; }
  :not(.login-page) .h-250 {
    height: 250px; }
  :not(.login-page) .mh-250 {
    max-height: 250px; }
  :not(.login-page) .w-260 {
    width: 260px; }
  :not(.login-page) .mw-260 {
    max-width: 260px; }
  :not(.login-page) .h-260 {
    height: 260px; }
  :not(.login-page) .mh-260 {
    max-height: 260px; }
  :not(.login-page) .w-270 {
    width: 270px; }
  :not(.login-page) .mw-270 {
    max-width: 270px; }
  :not(.login-page) .h-270 {
    height: 270px; }
  :not(.login-page) .mh-270 {
    max-height: 270px; }
  :not(.login-page) .w-280 {
    width: 280px; }
  :not(.login-page) .mw-280 {
    max-width: 280px; }
  :not(.login-page) .h-280 {
    height: 280px; }
  :not(.login-page) .mh-280 {
    max-height: 280px; }
  :not(.login-page) .w-290 {
    width: 290px; }
  :not(.login-page) .mw-290 {
    max-width: 290px; }
  :not(.login-page) .h-290 {
    height: 290px; }
  :not(.login-page) .mh-290 {
    max-height: 290px; }
  :not(.login-page) .w-300 {
    width: 300px; }
  :not(.login-page) .mw-300 {
    max-width: 300px; }
  :not(.login-page) .h-300 {
    height: 300px; }
  :not(.login-page) .mh-300 {
    max-height: 300px; }
  :not(.login-page) .w-310 {
    width: 310px; }
  :not(.login-page) .mw-310 {
    max-width: 310px; }
  :not(.login-page) .h-310 {
    height: 310px; }
  :not(.login-page) .mh-310 {
    max-height: 310px; }
  :not(.login-page) .w-320 {
    width: 320px; }
  :not(.login-page) .mw-320 {
    max-width: 320px; }
  :not(.login-page) .h-320 {
    height: 320px; }
  :not(.login-page) .mh-320 {
    max-height: 320px; }
  :not(.login-page) .w-330 {
    width: 330px; }
  :not(.login-page) .mw-330 {
    max-width: 330px; }
  :not(.login-page) .h-330 {
    height: 330px; }
  :not(.login-page) .mh-330 {
    max-height: 330px; }
  :not(.login-page) .w-340 {
    width: 340px; }
  :not(.login-page) .mw-340 {
    max-width: 340px; }
  :not(.login-page) .h-340 {
    height: 340px; }
  :not(.login-page) .mh-340 {
    max-height: 340px; }
  :not(.login-page) .w-350 {
    width: 350px; }
  :not(.login-page) .mw-350 {
    max-width: 350px; }
  :not(.login-page) .h-350 {
    height: 350px; }
  :not(.login-page) .mh-350 {
    max-height: 350px; }
  :not(.login-page) .w-360 {
    width: 360px; }
  :not(.login-page) .mw-360 {
    max-width: 360px; }
  :not(.login-page) .h-360 {
    height: 360px; }
  :not(.login-page) .mh-360 {
    max-height: 360px; }
  :not(.login-page) .w-370 {
    width: 370px; }
  :not(.login-page) .mw-370 {
    max-width: 370px; }
  :not(.login-page) .h-370 {
    height: 370px; }
  :not(.login-page) .mh-370 {
    max-height: 370px; }
  :not(.login-page) .w-380 {
    width: 380px; }
  :not(.login-page) .mw-380 {
    max-width: 380px; }
  :not(.login-page) .h-380 {
    height: 380px; }
  :not(.login-page) .mh-380 {
    max-height: 380px; }
  :not(.login-page) .w-390 {
    width: 390px; }
  :not(.login-page) .mw-390 {
    max-width: 390px; }
  :not(.login-page) .h-390 {
    height: 390px; }
  :not(.login-page) .mh-390 {
    max-height: 390px; }
  :not(.login-page) .w-400 {
    width: 400px; }
  :not(.login-page) .mw-400 {
    max-width: 400px; }
  :not(.login-page) .h-400 {
    height: 400px; }
  :not(.login-page) .mh-400 {
    max-height: 400px; }
  :not(.login-page) .w-410 {
    width: 410px; }
  :not(.login-page) .mw-410 {
    max-width: 410px; }
  :not(.login-page) .h-410 {
    height: 410px; }
  :not(.login-page) .mh-410 {
    max-height: 410px; }
  :not(.login-page) .w-420 {
    width: 420px; }
  :not(.login-page) .mw-420 {
    max-width: 420px; }
  :not(.login-page) .h-420 {
    height: 420px; }
  :not(.login-page) .mh-420 {
    max-height: 420px; }
  :not(.login-page) .w-430 {
    width: 430px; }
  :not(.login-page) .mw-430 {
    max-width: 430px; }
  :not(.login-page) .h-430 {
    height: 430px; }
  :not(.login-page) .mh-430 {
    max-height: 430px; }
  :not(.login-page) .w-440 {
    width: 440px; }
  :not(.login-page) .mw-440 {
    max-width: 440px; }
  :not(.login-page) .h-440 {
    height: 440px; }
  :not(.login-page) .mh-440 {
    max-height: 440px; }
  :not(.login-page) .w-450 {
    width: 450px; }
  :not(.login-page) .mw-450 {
    max-width: 450px; }
  :not(.login-page) .h-450 {
    height: 450px; }
  :not(.login-page) .mh-450 {
    max-height: 450px; }
  :not(.login-page) .w-460 {
    width: 460px; }
  :not(.login-page) .mw-460 {
    max-width: 460px; }
  :not(.login-page) .h-460 {
    height: 460px; }
  :not(.login-page) .mh-460 {
    max-height: 460px; }
  :not(.login-page) .w-470 {
    width: 470px; }
  :not(.login-page) .mw-470 {
    max-width: 470px; }
  :not(.login-page) .h-470 {
    height: 470px; }
  :not(.login-page) .mh-470 {
    max-height: 470px; }
  :not(.login-page) .w-480 {
    width: 480px; }
  :not(.login-page) .mw-480 {
    max-width: 480px; }
  :not(.login-page) .h-480 {
    height: 480px; }
  :not(.login-page) .mh-480 {
    max-height: 480px; }
  :not(.login-page) .w-490 {
    width: 490px; }
  :not(.login-page) .mw-490 {
    max-width: 490px; }
  :not(.login-page) .h-490 {
    height: 490px; }
  :not(.login-page) .mh-490 {
    max-height: 490px; }
  :not(.login-page) .w-500 {
    width: 500px; }
  :not(.login-page) .mw-500 {
    max-width: 500px; }
  :not(.login-page) .h-500 {
    height: 500px; }
  :not(.login-page) .mh-500 {
    max-height: 500px; }
  :not(.login-page) .w-510 {
    width: 510px; }
  :not(.login-page) .mw-510 {
    max-width: 510px; }
  :not(.login-page) .h-510 {
    height: 510px; }
  :not(.login-page) .mh-510 {
    max-height: 510px; }
  :not(.login-page) .w-520 {
    width: 520px; }
  :not(.login-page) .mw-520 {
    max-width: 520px; }
  :not(.login-page) .h-520 {
    height: 520px; }
  :not(.login-page) .mh-520 {
    max-height: 520px; }
  :not(.login-page) .w-530 {
    width: 530px; }
  :not(.login-page) .mw-530 {
    max-width: 530px; }
  :not(.login-page) .h-530 {
    height: 530px; }
  :not(.login-page) .mh-530 {
    max-height: 530px; }
  :not(.login-page) .w-540 {
    width: 540px; }
  :not(.login-page) .mw-540 {
    max-width: 540px; }
  :not(.login-page) .h-540 {
    height: 540px; }
  :not(.login-page) .mh-540 {
    max-height: 540px; }
  :not(.login-page) .w-550 {
    width: 550px; }
  :not(.login-page) .mw-550 {
    max-width: 550px; }
  :not(.login-page) .h-550 {
    height: 550px; }
  :not(.login-page) .mh-550 {
    max-height: 550px; }
  :not(.login-page) .w-560 {
    width: 560px; }
  :not(.login-page) .mw-560 {
    max-width: 560px; }
  :not(.login-page) .h-560 {
    height: 560px; }
  :not(.login-page) .mh-560 {
    max-height: 560px; }
  :not(.login-page) .w-570 {
    width: 570px; }
  :not(.login-page) .mw-570 {
    max-width: 570px; }
  :not(.login-page) .h-570 {
    height: 570px; }
  :not(.login-page) .mh-570 {
    max-height: 570px; }
  :not(.login-page) .w-580 {
    width: 580px; }
  :not(.login-page) .mw-580 {
    max-width: 580px; }
  :not(.login-page) .h-580 {
    height: 580px; }
  :not(.login-page) .mh-580 {
    max-height: 580px; }
  :not(.login-page) .w-590 {
    width: 590px; }
  :not(.login-page) .mw-590 {
    max-width: 590px; }
  :not(.login-page) .h-590 {
    height: 590px; }
  :not(.login-page) .mh-590 {
    max-height: 590px; }
  :not(.login-page) .w-600 {
    width: 600px; }
  :not(.login-page) .mw-600 {
    max-width: 600px; }
  :not(.login-page) .h-600 {
    height: 600px; }
  :not(.login-page) .mh-600 {
    max-height: 600px; }
  :not(.login-page) .w-610 {
    width: 610px; }
  :not(.login-page) .mw-610 {
    max-width: 610px; }
  :not(.login-page) .h-610 {
    height: 610px; }
  :not(.login-page) .mh-610 {
    max-height: 610px; }
  :not(.login-page) .w-620 {
    width: 620px; }
  :not(.login-page) .mw-620 {
    max-width: 620px; }
  :not(.login-page) .h-620 {
    height: 620px; }
  :not(.login-page) .mh-620 {
    max-height: 620px; }
  :not(.login-page) .w-630 {
    width: 630px; }
  :not(.login-page) .mw-630 {
    max-width: 630px; }
  :not(.login-page) .h-630 {
    height: 630px; }
  :not(.login-page) .mh-630 {
    max-height: 630px; }
  :not(.login-page) .w-640 {
    width: 640px; }
  :not(.login-page) .mw-640 {
    max-width: 640px; }
  :not(.login-page) .h-640 {
    height: 640px; }
  :not(.login-page) .mh-640 {
    max-height: 640px; }
  :not(.login-page) .w-650 {
    width: 650px; }
  :not(.login-page) .mw-650 {
    max-width: 650px; }
  :not(.login-page) .h-650 {
    height: 650px; }
  :not(.login-page) .mh-650 {
    max-height: 650px; }
  :not(.login-page) .w-660 {
    width: 660px; }
  :not(.login-page) .mw-660 {
    max-width: 660px; }
  :not(.login-page) .h-660 {
    height: 660px; }
  :not(.login-page) .mh-660 {
    max-height: 660px; }
  :not(.login-page) .w-670 {
    width: 670px; }
  :not(.login-page) .mw-670 {
    max-width: 670px; }
  :not(.login-page) .h-670 {
    height: 670px; }
  :not(.login-page) .mh-670 {
    max-height: 670px; }
  :not(.login-page) .w-680 {
    width: 680px; }
  :not(.login-page) .mw-680 {
    max-width: 680px; }
  :not(.login-page) .h-680 {
    height: 680px; }
  :not(.login-page) .mh-680 {
    max-height: 680px; }
  :not(.login-page) .w-690 {
    width: 690px; }
  :not(.login-page) .mw-690 {
    max-width: 690px; }
  :not(.login-page) .h-690 {
    height: 690px; }
  :not(.login-page) .mh-690 {
    max-height: 690px; }
  :not(.login-page) .w-700 {
    width: 700px; }
  :not(.login-page) .mw-700 {
    max-width: 700px; }
  :not(.login-page) .h-700 {
    height: 700px; }
  :not(.login-page) .mh-700 {
    max-height: 700px; }
  :not(.login-page) .w-710 {
    width: 710px; }
  :not(.login-page) .mw-710 {
    max-width: 710px; }
  :not(.login-page) .h-710 {
    height: 710px; }
  :not(.login-page) .mh-710 {
    max-height: 710px; }
  :not(.login-page) .w-720 {
    width: 720px; }
  :not(.login-page) .mw-720 {
    max-width: 720px; }
  :not(.login-page) .h-720 {
    height: 720px; }
  :not(.login-page) .mh-720 {
    max-height: 720px; }
  :not(.login-page) .w-730 {
    width: 730px; }
  :not(.login-page) .mw-730 {
    max-width: 730px; }
  :not(.login-page) .h-730 {
    height: 730px; }
  :not(.login-page) .mh-730 {
    max-height: 730px; }
  :not(.login-page) .w-740 {
    width: 740px; }
  :not(.login-page) .mw-740 {
    max-width: 740px; }
  :not(.login-page) .h-740 {
    height: 740px; }
  :not(.login-page) .mh-740 {
    max-height: 740px; }
  :not(.login-page) .w-750 {
    width: 750px; }
  :not(.login-page) .mw-750 {
    max-width: 750px; }
  :not(.login-page) .h-750 {
    height: 750px; }
  :not(.login-page) .mh-750 {
    max-height: 750px; }
  :not(.login-page) .w-760 {
    width: 760px; }
  :not(.login-page) .mw-760 {
    max-width: 760px; }
  :not(.login-page) .h-760 {
    height: 760px; }
  :not(.login-page) .mh-760 {
    max-height: 760px; }
  :not(.login-page) .w-770 {
    width: 770px; }
  :not(.login-page) .mw-770 {
    max-width: 770px; }
  :not(.login-page) .h-770 {
    height: 770px; }
  :not(.login-page) .mh-770 {
    max-height: 770px; }
  :not(.login-page) .w-780 {
    width: 780px; }
  :not(.login-page) .mw-780 {
    max-width: 780px; }
  :not(.login-page) .h-780 {
    height: 780px; }
  :not(.login-page) .mh-780 {
    max-height: 780px; }
  :not(.login-page) .w-790 {
    width: 790px; }
  :not(.login-page) .mw-790 {
    max-width: 790px; }
  :not(.login-page) .h-790 {
    height: 790px; }
  :not(.login-page) .mh-790 {
    max-height: 790px; }
  :not(.login-page) .w-800 {
    width: 800px; }
  :not(.login-page) .mw-800 {
    max-width: 800px; }
  :not(.login-page) .h-800 {
    height: 800px; }
  :not(.login-page) .mh-800 {
    max-height: 800px; }
  :not(.login-page) .w-810 {
    width: 810px; }
  :not(.login-page) .mw-810 {
    max-width: 810px; }
  :not(.login-page) .h-810 {
    height: 810px; }
  :not(.login-page) .mh-810 {
    max-height: 810px; }
  :not(.login-page) .w-820 {
    width: 820px; }
  :not(.login-page) .mw-820 {
    max-width: 820px; }
  :not(.login-page) .h-820 {
    height: 820px; }
  :not(.login-page) .mh-820 {
    max-height: 820px; }
  :not(.login-page) .w-830 {
    width: 830px; }
  :not(.login-page) .mw-830 {
    max-width: 830px; }
  :not(.login-page) .h-830 {
    height: 830px; }
  :not(.login-page) .mh-830 {
    max-height: 830px; }
  :not(.login-page) .w-840 {
    width: 840px; }
  :not(.login-page) .mw-840 {
    max-width: 840px; }
  :not(.login-page) .h-840 {
    height: 840px; }
  :not(.login-page) .mh-840 {
    max-height: 840px; }
  :not(.login-page) .w-850 {
    width: 850px; }
  :not(.login-page) .mw-850 {
    max-width: 850px; }
  :not(.login-page) .h-850 {
    height: 850px; }
  :not(.login-page) .mh-850 {
    max-height: 850px; }
  :not(.login-page) .w-860 {
    width: 860px; }
  :not(.login-page) .mw-860 {
    max-width: 860px; }
  :not(.login-page) .h-860 {
    height: 860px; }
  :not(.login-page) .mh-860 {
    max-height: 860px; }
  :not(.login-page) .w-870 {
    width: 870px; }
  :not(.login-page) .mw-870 {
    max-width: 870px; }
  :not(.login-page) .h-870 {
    height: 870px; }
  :not(.login-page) .mh-870 {
    max-height: 870px; }
  :not(.login-page) .w-880 {
    width: 880px; }
  :not(.login-page) .mw-880 {
    max-width: 880px; }
  :not(.login-page) .h-880 {
    height: 880px; }
  :not(.login-page) .mh-880 {
    max-height: 880px; }
  :not(.login-page) .w-890 {
    width: 890px; }
  :not(.login-page) .mw-890 {
    max-width: 890px; }
  :not(.login-page) .h-890 {
    height: 890px; }
  :not(.login-page) .mh-890 {
    max-height: 890px; }
  :not(.login-page) .w-900 {
    width: 900px; }
  :not(.login-page) .mw-900 {
    max-width: 900px; }
  :not(.login-page) .h-900 {
    height: 900px; }
  :not(.login-page) .mh-900 {
    max-height: 900px; }
  :not(.login-page) .w-910 {
    width: 910px; }
  :not(.login-page) .mw-910 {
    max-width: 910px; }
  :not(.login-page) .h-910 {
    height: 910px; }
  :not(.login-page) .mh-910 {
    max-height: 910px; }
  :not(.login-page) .w-920 {
    width: 920px; }
  :not(.login-page) .mw-920 {
    max-width: 920px; }
  :not(.login-page) .h-920 {
    height: 920px; }
  :not(.login-page) .mh-920 {
    max-height: 920px; }
  :not(.login-page) .w-930 {
    width: 930px; }
  :not(.login-page) .mw-930 {
    max-width: 930px; }
  :not(.login-page) .h-930 {
    height: 930px; }
  :not(.login-page) .mh-930 {
    max-height: 930px; }
  :not(.login-page) .w-940 {
    width: 940px; }
  :not(.login-page) .mw-940 {
    max-width: 940px; }
  :not(.login-page) .h-940 {
    height: 940px; }
  :not(.login-page) .mh-940 {
    max-height: 940px; }
  :not(.login-page) .w-950 {
    width: 950px; }
  :not(.login-page) .mw-950 {
    max-width: 950px; }
  :not(.login-page) .h-950 {
    height: 950px; }
  :not(.login-page) .mh-950 {
    max-height: 950px; }
  :not(.login-page) .w-960 {
    width: 960px; }
  :not(.login-page) .mw-960 {
    max-width: 960px; }
  :not(.login-page) .h-960 {
    height: 960px; }
  :not(.login-page) .mh-960 {
    max-height: 960px; }
  :not(.login-page) .w-970 {
    width: 970px; }
  :not(.login-page) .mw-970 {
    max-width: 970px; }
  :not(.login-page) .h-970 {
    height: 970px; }
  :not(.login-page) .mh-970 {
    max-height: 970px; }
  :not(.login-page) .w-980 {
    width: 980px; }
  :not(.login-page) .mw-980 {
    max-width: 980px; }
  :not(.login-page) .h-980 {
    height: 980px; }
  :not(.login-page) .mh-980 {
    max-height: 980px; }
  :not(.login-page) .w-990 {
    width: 990px; }
  :not(.login-page) .mw-990 {
    max-width: 990px; }
  :not(.login-page) .h-990 {
    height: 990px; }
  :not(.login-page) .mh-990 {
    max-height: 990px; }
  :not(.login-page) .w-1000 {
    width: 1000px; }
  :not(.login-page) .mw-1000 {
    max-width: 1000px; }
  :not(.login-page) .h-1000 {
    height: 1000px; }
  :not(.login-page) .mh-1000 {
    max-height: 1000px; }
  :not(.login-page) .w-1010 {
    width: 1010px; }
  :not(.login-page) .mw-1010 {
    max-width: 1010px; }
  :not(.login-page) .h-1010 {
    height: 1010px; }
  :not(.login-page) .mh-1010 {
    max-height: 1010px; }
  :not(.login-page) .w-1020 {
    width: 1020px; }
  :not(.login-page) .mw-1020 {
    max-width: 1020px; }
  :not(.login-page) .h-1020 {
    height: 1020px; }
  :not(.login-page) .mh-1020 {
    max-height: 1020px; }
  :not(.login-page) .w-1030 {
    width: 1030px; }
  :not(.login-page) .mw-1030 {
    max-width: 1030px; }
  :not(.login-page) .h-1030 {
    height: 1030px; }
  :not(.login-page) .mh-1030 {
    max-height: 1030px; }
  :not(.login-page) .w-1040 {
    width: 1040px; }
  :not(.login-page) .mw-1040 {
    max-width: 1040px; }
  :not(.login-page) .h-1040 {
    height: 1040px; }
  :not(.login-page) .mh-1040 {
    max-height: 1040px; }
  :not(.login-page) .w-1050 {
    width: 1050px; }
  :not(.login-page) .mw-1050 {
    max-width: 1050px; }
  :not(.login-page) .h-1050 {
    height: 1050px; }
  :not(.login-page) .mh-1050 {
    max-height: 1050px; }
  :not(.login-page) .w-1060 {
    width: 1060px; }
  :not(.login-page) .mw-1060 {
    max-width: 1060px; }
  :not(.login-page) .h-1060 {
    height: 1060px; }
  :not(.login-page) .mh-1060 {
    max-height: 1060px; }
  :not(.login-page) .w-1070 {
    width: 1070px; }
  :not(.login-page) .mw-1070 {
    max-width: 1070px; }
  :not(.login-page) .h-1070 {
    height: 1070px; }
  :not(.login-page) .mh-1070 {
    max-height: 1070px; }
  :not(.login-page) .w-1080 {
    width: 1080px; }
  :not(.login-page) .mw-1080 {
    max-width: 1080px; }
  :not(.login-page) .h-1080 {
    height: 1080px; }
  :not(.login-page) .mh-1080 {
    max-height: 1080px; }
  :not(.login-page) .w-1090 {
    width: 1090px; }
  :not(.login-page) .mw-1090 {
    max-width: 1090px; }
  :not(.login-page) .h-1090 {
    height: 1090px; }
  :not(.login-page) .mh-1090 {
    max-height: 1090px; }
  :not(.login-page) .w-1100 {
    width: 1100px; }
  :not(.login-page) .mw-1100 {
    max-width: 1100px; }
  :not(.login-page) .h-1100 {
    height: 1100px; }
  :not(.login-page) .mh-1100 {
    max-height: 1100px; }
  :not(.login-page) .w-1110 {
    width: 1110px; }
  :not(.login-page) .mw-1110 {
    max-width: 1110px; }
  :not(.login-page) .h-1110 {
    height: 1110px; }
  :not(.login-page) .mh-1110 {
    max-height: 1110px; }
  :not(.login-page) .w-1120 {
    width: 1120px; }
  :not(.login-page) .mw-1120 {
    max-width: 1120px; }
  :not(.login-page) .h-1120 {
    height: 1120px; }
  :not(.login-page) .mh-1120 {
    max-height: 1120px; }
  :not(.login-page) .w-1130 {
    width: 1130px; }
  :not(.login-page) .mw-1130 {
    max-width: 1130px; }
  :not(.login-page) .h-1130 {
    height: 1130px; }
  :not(.login-page) .mh-1130 {
    max-height: 1130px; }
  :not(.login-page) .w-1140 {
    width: 1140px; }
  :not(.login-page) .mw-1140 {
    max-width: 1140px; }
  :not(.login-page) .h-1140 {
    height: 1140px; }
  :not(.login-page) .mh-1140 {
    max-height: 1140px; }
  :not(.login-page) .w-1150 {
    width: 1150px; }
  :not(.login-page) .mw-1150 {
    max-width: 1150px; }
  :not(.login-page) .h-1150 {
    height: 1150px; }
  :not(.login-page) .mh-1150 {
    max-height: 1150px; }
  :not(.login-page) .w-1160 {
    width: 1160px; }
  :not(.login-page) .mw-1160 {
    max-width: 1160px; }
  :not(.login-page) .h-1160 {
    height: 1160px; }
  :not(.login-page) .mh-1160 {
    max-height: 1160px; }
  :not(.login-page) .w-1170 {
    width: 1170px; }
  :not(.login-page) .mw-1170 {
    max-width: 1170px; }
  :not(.login-page) .h-1170 {
    height: 1170px; }
  :not(.login-page) .mh-1170 {
    max-height: 1170px; }
  :not(.login-page) .w-full {
    width: 100%; }
  :not(.login-page) .mw-full {
    max-width: 100%; }
  :not(.login-page) .h-full {
    height: 100%; }
  :not(.login-page) .mh-full {
    max-height: 100%; }
  :not(.login-page) .h-1 {
    height: 1px; }
  :not(.login-page) .w-1 {
    width: 1px; }
  :not(.login-page) .ml-1 {
    margin-left: 1px !important; }
  :not(.login-page) .mr-1 {
    margin-right: 1px !important; }
  :not(.login-page) .mx-1 {
    margin-left: 1px !important;
    margin-right: 1px !important; }
  :not(.login-page) .h-2 {
    height: 2px; }
  :not(.login-page) .w-2 {
    width: 2px; }
  :not(.login-page) .ml-2 {
    margin-left: 2px !important; }
  :not(.login-page) .mr-2 {
    margin-right: 2px !important; }
  :not(.login-page) .mx-2 {
    margin-left: 2px !important;
    margin-right: 2px !important; }
  :not(.login-page) .h-3 {
    height: 3px; }
  :not(.login-page) .w-3 {
    width: 3px; }
  :not(.login-page) .ml-3 {
    margin-left: 3px !important; }
  :not(.login-page) .mr-3 {
    margin-right: 3px !important; }
  :not(.login-page) .mx-3 {
    margin-left: 3px !important;
    margin-right: 3px !important; }
  :not(.login-page) .h-4 {
    height: 4px; }
  :not(.login-page) .w-4 {
    width: 4px; }
  :not(.login-page) .ml-4 {
    margin-left: 4px !important; }
  :not(.login-page) .mr-4 {
    margin-right: 4px !important; }
  :not(.login-page) .mx-4 {
    margin-left: 4px !important;
    margin-right: 4px !important; }
  :not(.login-page) .h-5 {
    height: 5px; }
  :not(.login-page) .w-5 {
    width: 5px; }
  :not(.login-page) .ml-5 {
    margin-left: 5px !important; }
  :not(.login-page) .mr-5 {
    margin-right: 5px !important; }
  :not(.login-page) .mx-5 {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  :not(.login-page) .h-6 {
    height: 6px; }
  :not(.login-page) .w-6 {
    width: 6px; }
  :not(.login-page) .ml-6 {
    margin-left: 6px !important; }
  :not(.login-page) .mr-6 {
    margin-right: 6px !important; }
  :not(.login-page) .mx-6 {
    margin-left: 6px !important;
    margin-right: 6px !important; }
  :not(.login-page) .h-7 {
    height: 7px; }
  :not(.login-page) .w-7 {
    width: 7px; }
  :not(.login-page) .ml-7 {
    margin-left: 7px !important; }
  :not(.login-page) .mr-7 {
    margin-right: 7px !important; }
  :not(.login-page) .mx-7 {
    margin-left: 7px !important;
    margin-right: 7px !important; }
  :not(.login-page) .h-8 {
    height: 8px; }
  :not(.login-page) .w-8 {
    width: 8px; }
  :not(.login-page) .ml-8 {
    margin-left: 8px !important; }
  :not(.login-page) .mr-8 {
    margin-right: 8px !important; }
  :not(.login-page) .mx-8 {
    margin-left: 8px !important;
    margin-right: 8px !important; }
  :not(.login-page) .h-9 {
    height: 9px; }
  :not(.login-page) .w-9 {
    width: 9px; }
  :not(.login-page) .ml-9 {
    margin-left: 9px !important; }
  :not(.login-page) .mr-9 {
    margin-right: 9px !important; }
  :not(.login-page) .mx-9 {
    margin-left: 9px !important;
    margin-right: 9px !important; }
  :not(.login-page) .h-10 {
    height: 10px; }
  :not(.login-page) .w-10 {
    width: 10px; }
  :not(.login-page) .ml-10 {
    margin-left: 10px !important; }
  :not(.login-page) .mr-10 {
    margin-right: 10px !important; }
  :not(.login-page) .mx-10 {
    margin-left: 10px !important;
    margin-right: 10px !important; }
  :not(.login-page) .w-25 {
    width: 25px !important; }
  :not(.login-page) .w-50 {
    width: 50px !important; }
  :not(.login-page) .w-75 {
    width: 75px !important; }
  :not(.login-page) .w-100 {
    width: 100px !important; }
  :not(.login-page) .f-heading {
    font-family: Nunito Sans; }
  :not(.login-page) .f-main {
    font-family: Nunito Sans; }
  :not(.login-page) .f-12 {
    font-size: 12px;
    line-height: 22px; }
  :not(.login-page) .f-14 {
    font-size: 14px;
    line-height: 24px; }
  :not(.login-page) .f-16 {
    font-size: 16px;
    line-height: 26px; }
  :not(.login-page) .f-18 {
    font-size: 18px;
    line-height: 28px; }
  :not(.login-page) .f-20 {
    font-size: 20px;
    line-height: 30px; }
  :not(.login-page) .f-22 {
    font-size: 22px;
    line-height: 32px; }
  :not(.login-page) .f-24 {
    font-size: 24px;
    line-height: 34px; }
  :not(.login-page) .f-26 {
    font-size: 26px;
    line-height: 36px; }
  :not(.login-page) .f-28 {
    font-size: 28px;
    line-height: 38px; }
  :not(.login-page) .f-30 {
    font-size: 30px;
    line-height: 40px; }
  :not(.login-page) .f-32 {
    font-size: 32px;
    line-height: 42px; }
  :not(.login-page) .f-34 {
    font-size: 34px;
    line-height: 44px; }
  :not(.login-page) .f-36 {
    font-size: 36px;
    line-height: 46px; }
  :not(.login-page) .f-38 {
    font-size: 38px;
    line-height: 48px; }
  :not(.login-page) .f-40 {
    font-size: 40px;
    line-height: 50px; }
  :not(.login-page) .f-42 {
    font-size: 42px;
    line-height: 52px; }
  :not(.login-page) .f-44 {
    font-size: 44px;
    line-height: 54px; }
  :not(.login-page) .f-46 {
    font-size: 46px;
    line-height: 56px; }
  :not(.login-page) .f-48 {
    font-size: 48px;
    line-height: 58px; }
  :not(.login-page) .f-50 {
    font-size: 50px;
    line-height: 60px; }
  :not(.login-page) .f-52 {
    font-size: 52px;
    line-height: 62px; }
  :not(.login-page) .f-54 {
    font-size: 54px;
    line-height: 64px; }
  :not(.login-page) .f-56 {
    font-size: 56px;
    line-height: 66px; }
  :not(.login-page) .f-58 {
    font-size: 58px;
    line-height: 68px; }
  :not(.login-page) .f-60 {
    font-size: 60px;
    line-height: 70px; }
  :not(.login-page) .f-62 {
    font-size: 62px;
    line-height: 72px; }
  :not(.login-page) .f-64 {
    font-size: 64px;
    line-height: 74px; }
  :not(.login-page) .f-66 {
    font-size: 66px;
    line-height: 76px; }
  :not(.login-page) .f-68 {
    font-size: 68px;
    line-height: 78px; }
  :not(.login-page) .f-70 {
    font-size: 70px;
    line-height: 80px; }
  :not(.login-page) .f-72 {
    font-size: 72px;
    line-height: 82px; }
  :not(.login-page) .f-74 {
    font-size: 74px;
    line-height: 84px; }
  :not(.login-page) .f-76 {
    font-size: 76px;
    line-height: 86px; }
  :not(.login-page) .f-78 {
    font-size: 78px;
    line-height: 88px; }
  :not(.login-page) .f-80 {
    font-size: 80px;
    line-height: 90px; }
  :not(.login-page) .f-82 {
    font-size: 82px;
    line-height: 92px; }
  :not(.login-page) .f-84 {
    font-size: 84px;
    line-height: 94px; }
  :not(.login-page) .f-86 {
    font-size: 86px;
    line-height: 96px; }
  :not(.login-page) .f-88 {
    font-size: 88px;
    line-height: 98px; }
  :not(.login-page) .f-90 {
    font-size: 90px;
    line-height: 100px; }
  :not(.login-page) .f-92 {
    font-size: 92px;
    line-height: 102px; }
  :not(.login-page) .f-94 {
    font-size: 94px;
    line-height: 104px; }
  :not(.login-page) .f-96 {
    font-size: 96px;
    line-height: 106px; }
  :not(.login-page) .f-98 {
    font-size: 98px;
    line-height: 108px; }
  :not(.login-page) .f-100 {
    font-size: 100px;
    line-height: 110px; }
  :not(.login-page) .f-102 {
    font-size: 102px;
    line-height: 112px; }
  :not(.login-page) .lh-12 {
    line-height: 12px; }
  :not(.login-page) .lh-14 {
    line-height: 14px; }
  :not(.login-page) .lh-16 {
    line-height: 16px; }
  :not(.login-page) .lh-18 {
    line-height: 18px; }
  :not(.login-page) .lh-20 {
    line-height: 20px; }
  :not(.login-page) .lh-22 {
    line-height: 22px; }
  :not(.login-page) .lh-24 {
    line-height: 24px; }
  :not(.login-page) .lh-26 {
    line-height: 26px; }
  :not(.login-page) .lh-28 {
    line-height: 28px; }
  :not(.login-page) .lh-30 {
    line-height: 30px; }
  :not(.login-page) .lh-32 {
    line-height: 32px; }
  :not(.login-page) .lh-34 {
    line-height: 34px; }
  :not(.login-page) .lh-36 {
    line-height: 36px; }
  :not(.login-page) .lh-38 {
    line-height: 38px; }
  :not(.login-page) .lh-40 {
    line-height: 40px; }
  :not(.login-page) .lh-42 {
    line-height: 42px; }
  :not(.login-page) .lh-44 {
    line-height: 44px; }
  :not(.login-page) .lh-46 {
    line-height: 46px; }
  :not(.login-page) .lh-48 {
    line-height: 48px; }
  :not(.login-page) .lh-50 {
    line-height: 50px; }
  :not(.login-page) .lh-52 {
    line-height: 52px; }
  :not(.login-page) .lh-54 {
    line-height: 54px; }
  :not(.login-page) .lh-56 {
    line-height: 56px; }
  :not(.login-page) .lh-58 {
    line-height: 58px; }
  :not(.login-page) .lh-60 {
    line-height: 60px; }
  :not(.login-page) .lh-62 {
    line-height: 62px; }
  :not(.login-page) .lh-64 {
    line-height: 64px; }
  :not(.login-page) .lh-66 {
    line-height: 66px; }
  :not(.login-page) .lh-68 {
    line-height: 68px; }
  :not(.login-page) .lh-70 {
    line-height: 70px; }
  :not(.login-page) .lh-72 {
    line-height: 72px; }
  :not(.login-page) .lh-74 {
    line-height: 74px; }
  :not(.login-page) .lh-76 {
    line-height: 76px; }
  :not(.login-page) .lh-78 {
    line-height: 78px; }
  :not(.login-page) .lh-80 {
    line-height: 80px; }
  :not(.login-page) .lh-82 {
    line-height: 82px; }
  :not(.login-page) .lh-84 {
    line-height: 84px; }
  :not(.login-page) .lh-86 {
    line-height: 86px; }
  :not(.login-page) .lh-88 {
    line-height: 88px; }
  :not(.login-page) .lh-90 {
    line-height: 90px; }
  :not(.login-page) .lh-92 {
    line-height: 92px; }
  :not(.login-page) .lh-94 {
    line-height: 94px; }
  :not(.login-page) .lh-96 {
    line-height: 96px; }
  :not(.login-page) .lh-98 {
    line-height: 98px; }
  :not(.login-page) .lh-100 {
    line-height: 100px; }
  :not(.login-page) .lh-102 {
    line-height: 102px; }
  :not(.login-page) .lh-104 {
    line-height: 104px; }
  :not(.login-page) .lh-106 {
    line-height: 106px; }
  :not(.login-page) .lh-108 {
    line-height: 108px; }
  :not(.login-page) .lh-110 {
    line-height: 110px; }
  :not(.login-page) .lh-112 {
    line-height: 112px; }
  :not(.login-page) .lh-114 {
    line-height: 114px; }
  :not(.login-page) .lh-116 {
    line-height: 116px; }
  :not(.login-page) .lh-118 {
    line-height: 118px; }
  :not(.login-page) .lh-120 {
    line-height: 120px; }
  :not(.login-page) .lh-122 {
    line-height: 122px; }
  :not(.login-page) .lh-124 {
    line-height: 124px; }
  :not(.login-page) .lh-126 {
    line-height: 126px; }
  :not(.login-page) .lh-128 {
    line-height: 128px; }
  :not(.login-page) .lh-130 {
    line-height: 130px; }
  :not(.login-page) .lh-132 {
    line-height: 132px; }
  :not(.login-page) .lh-134 {
    line-height: 134px; }
  :not(.login-page) .lh-136 {
    line-height: 136px; }
  :not(.login-page) .lh-138 {
    line-height: 138px; }
  :not(.login-page) .lh-140 {
    line-height: 140px; }
  :not(.login-page) .lh-142 {
    line-height: 142px; }
  :not(.login-page) .lh-144 {
    line-height: 144px; }
  :not(.login-page) .lh-146 {
    line-height: 146px; }
  :not(.login-page) .lh-148 {
    line-height: 148px; }
  :not(.login-page) .lh-150 {
    line-height: 150px; }
  :not(.login-page) .lh-152 {
    line-height: 152px; }
  :not(.login-page) .lh-154 {
    line-height: 154px; }
  :not(.login-page) .lh-156 {
    line-height: 156px; }
  :not(.login-page) .lh-158 {
    line-height: 158px; }
  :not(.login-page) .lh-160 {
    line-height: 160px; }
  :not(.login-page) .lh-162 {
    line-height: 162px; }
  :not(.login-page) .lh-164 {
    line-height: 164px; }
  :not(.login-page) .lh-166 {
    line-height: 166px; }
  :not(.login-page) .lh-168 {
    line-height: 168px; }
  :not(.login-page) .lh-170 {
    line-height: 170px; }
  :not(.login-page) .lh-172 {
    line-height: 172px; }
  :not(.login-page) .lh-174 {
    line-height: 174px; }
  :not(.login-page) .lh-176 {
    line-height: 176px; }
  :not(.login-page) .lh-178 {
    line-height: 178px; }
  :not(.login-page) .lh-180 {
    line-height: 180px; }
  :not(.login-page) .lh-182 {
    line-height: 182px; }
  :not(.login-page) .lh-184 {
    line-height: 184px; }
  :not(.login-page) .lh-186 {
    line-height: 186px; }
  :not(.login-page) .lh-188 {
    line-height: 188px; }
  :not(.login-page) .lh-190 {
    line-height: 190px; }
  :not(.login-page) .lh-192 {
    line-height: 192px; }
  :not(.login-page) .lh-194 {
    line-height: 194px; }
  :not(.login-page) .lh-196 {
    line-height: 196px; }
  :not(.login-page) .lh-198 {
    line-height: 198px; }
  :not(.login-page) .lh-200 {
    line-height: 200px; }
  :not(.login-page) .lh-202 {
    line-height: 202px; }
  :not(.login-page) .action-1 {
    background-color: #0074f1;
    color: #FFFFFF; }
    :not(.login-page) .action-1:hover {
      background-color: #005bbe; }
    :not(.login-page) .action-1.link:hover {
      color: #005bbe; }
  :not(.login-page) .action-2 {
    color: #0074f1; }
    :not(.login-page) .action-2.link:hover {
      color: #005bbe; }
  :not(.login-page) .action-3 {
    color: #7121F4; }
    :not(.login-page) .action-3.link:hover {
      color: #580bd7; }
  :not(.login-page) .color-main {
    color: #1e3060; }
    :not(.login-page) .color-main.link:hover {
      color: #3657ae; }
  :not(.login-page) .color-heading {
    color: #1E3060; }
    :not(.login-page) .color-heading.link:hover {
      color: #060912; }
  :not(.login-page) .color-white {
    color: #FFFFFF; }
    :not(.login-page) .color-white.link:hover {
      color: rgba(255, 255, 255, 0.7); }
  :not(.login-page) .color-transparent-white {
    color: rgba(255, 255, 255, 0.8); }
    :not(.login-page) .color-transparent-white.link:hover {
      color: #FFFFFF; }
  :not(.login-page) .color-gray {
    color: #E7EAED; }
    :not(.login-page) .color-gray.link:hover {
      color: #adb7c1; }
  :not(.login-page) .color-red {
    color: #EA3223; }
    :not(.login-page) .color-red.link:hover {
      color: #c72113; }
  :not(.login-page) .color-twitter {
    color: #0aa0f5; }
    :not(.login-page) .color-twitter.link:hover {
      color: #0880c4; }
  :not(.login-page) .color-facebook {
    color: #2e4da7; }
    :not(.login-page) .color-facebook.link:hover {
      color: #3e62ca; }
  :not(.login-page) .color-dribbble {
    color: #E93A7D; }
    :not(.login-page) .color-dribbble.link:hover {
      color: #d81862; }
  :not(.login-page) .bg-light {
    background-color: #FFFFFF !important; }
  :not(.login-page) .bg-dark {
    background-color: #35383D !important; }
  :not(.login-page) .bg-main {
    background-color: #1e3060; }
  :not(.login-page) .bg-heading {
    background-color: #1E3060; }
  :not(.login-page) .bg-white {
    background-color: #FFFFFF; }
  :not(.login-page) .bg-gray {
    background-color: #E7EAED; }
  :not(.login-page) .bg-action-1 {
    background-color: #0074f1; }
  :not(.login-page) .bg-action-2 {
    background-color: #0074f1; }
  :not(.login-page) .bg-action-3 {
    background-color: #7121F4; }
  :not(.login-page) [class^="color-filter-"]:not(.absolute), :not(.login-page) [class^="color-filter-"]:not(.fixed),
  :not(.login-page) [class*=" color-filter-"]:not(.absolute),
  :not(.login-page) [class*=" color-filter-"]:not(.fixed) {
    position: relative; }
  :not(.login-page) [class^="color-filter-"]:before,
  :not(.login-page) [class*=" color-filter-"]:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0; }
  :not(.login-page) .color-filter-light-1:before {
    background-color: rgba(255, 255, 255, 0.1); }
  :not(.login-page) .color-filter-dark-1:before {
    background-color: rgba(53, 56, 61, 0.1); }
  :not(.login-page) .color-filter-light-2:before {
    background-color: rgba(255, 255, 255, 0.2); }
  :not(.login-page) .color-filter-dark-2:before {
    background-color: rgba(53, 56, 61, 0.2); }
  :not(.login-page) .color-filter-light-3:before {
    background-color: rgba(255, 255, 255, 0.3); }
  :not(.login-page) .color-filter-dark-3:before {
    background-color: rgba(53, 56, 61, 0.3); }
  :not(.login-page) .color-filter-light-4:before {
    background-color: rgba(255, 255, 255, 0.4); }
  :not(.login-page) .color-filter-dark-4:before {
    background-color: rgba(53, 56, 61, 0.4); }
  :not(.login-page) .color-filter-light-5:before {
    background-color: rgba(255, 255, 255, 0.5); }
  :not(.login-page) .color-filter-dark-5:before {
    background-color: rgba(53, 56, 61, 0.5); }
  :not(.login-page) .color-filter-light-6:before {
    background-color: rgba(255, 255, 255, 0.6); }
  :not(.login-page) .color-filter-dark-6:before {
    background-color: rgba(53, 56, 61, 0.6); }
  :not(.login-page) .color-filter-light-7:before {
    background-color: rgba(255, 255, 255, 0.7); }
  :not(.login-page) .color-filter-dark-7:before {
    background-color: rgba(53, 56, 61, 0.7); }
  :not(.login-page) .color-filter-light-8:before {
    background-color: rgba(255, 255, 255, 0.8); }
  :not(.login-page) .color-filter-dark-8:before {
    background-color: rgba(53, 56, 61, 0.8); }
  :not(.login-page) .color-filter-light-9:before {
    background-color: rgba(255, 255, 255, 0.9); }
  :not(.login-page) .color-filter-dark-9:before {
    background-color: rgba(53, 56, 61, 0.9); }
  :not(.login-page) .radius4 {
    border-radius: 4px; }
  :not(.login-page) .radius6 {
    border-radius: 6px; }
  :not(.login-page) .radius8 {
    border-radius: 8px; }
  :not(.login-page) .radius10 {
    border-radius: 10px; }
  :not(.login-page) .radius12 {
    border-radius: 12px; }
  :not(.login-page) .radius14 {
    border-radius: 14px; }
  :not(.login-page) .radius16 {
    border-radius: 16px; }
  :not(.login-page) .radius18 {
    border-radius: 18px; }
  :not(.login-page) .radius20 {
    border-radius: 20px; }
  :not(.login-page) .radius22 {
    border-radius: 22px; }
  :not(.login-page) .radius24 {
    border-radius: 24px; }
  :not(.login-page) .radius26 {
    border-radius: 26px; }
  :not(.login-page) .radius28 {
    border-radius: 28px; }
  :not(.login-page) .radius30 {
    border-radius: 30px; }
  :not(.login-page) .radius32 {
    border-radius: 32px; }
  :not(.login-page) .radius_full {
    border-radius: 50%; }
  :not(.login-page) .noradius {
    border-radius: 0; }
  :not(.login-page) .noradius_top {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  :not(.login-page) .noradius_right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  :not(.login-page) .noradius_bottom {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  :not(.login-page) .noradius_left {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  :not(.login-page) .relative {
    position: relative; }
  :not(.login-page) .absolute {
    position: absolute; }
  :not(.login-page) body {
    font-family: Nunito Sans;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #1e3060;
    -webkit-font-smoothing: antialiased; }
    :not(.login-page) body.dev {
      background: #ccc;
      min-height: 10000px; }
      :not(.login-page) body.dev .js-content-editable {
        outline: rgba(113, 33, 244, 0.7) dashed 2px !important;
        outline-offset: -2px; }
        :not(.login-page) body.dev .js-content-editable:hover {
          outline-color: #0074f1 !important;
          outline-style: solid !important; }
      :not(.login-page) body.dev section.js-content-editable,
      :not(.login-page) body.dev header.js-content-editable,
      :not(.login-page) body.dev nav.js-content-editable,
      :not(.login-page) body.dev footer.js-content-editable {
        outline-width: 10px !important;
        outline-offset: -10px; }
  :not(.login-page) nav,
  :not(.login-page) header,
  :not(.login-page) section,
  :not(.login-page) footer {
    position: relative; }
  :not(.login-page) header,
  :not(.login-page) section {
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover; }
  :not(.login-page) header nav {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000; }
  :not(.login-page) nav {
    width: 100%; }
    :not(.login-page) nav.transparent {
      background: transparent; }
  :not(.login-page) a,
  :not(.login-page) button {
    cursor: pointer;
    transition: all 0.25s; }
    :not(.login-page) a:hover,
    :not(.login-page) button:hover {
      text-decoration: none; }
    :not(.login-page) a:active, :not(.login-page) a:focus,
    :not(.login-page) button:active,
    :not(.login-page) button:focus {
      text-decoration: none;
      outline: none; }
  :not(.login-page) button {
    border: none;
    outline: none !important; }
  :not(.login-page) input,
  :not(.login-page) textarea {
    border: 0;
    outline: none;
    background: transparent;
    color: #1e3060;
    resize: none; }
  :not(.login-page) label {
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer; }
  :not(.login-page) h1,
  :not(.login-page) h2,
  :not(.login-page) h3,
  :not(.login-page) h4,
  :not(.login-page) h5,
  :not(.login-page) h6 {
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    margin: 0;
    font-weight: 700;
    font-family: Nunito Sans; }
  :not(.login-page) h1,
  :not(.login-page) h2 {
    font-size: 58px;
    line-height: 70px; }
    :not(.login-page) h1.big,
    :not(.login-page) h2.big {
      font-size: 72px;
      line-height: 86px; }
    :not(.login-page) h1.small,
    :not(.login-page) h2.small {
      font-size: 42px;
      line-height: 52px;
      font-weight: 700; }
  :not(.login-page) h3 {
    font-size: 42px;
    line-height: 52px; }
  :not(.login-page) b,
  :not(.login-page) strong {
    font-weight: 700; }
  :not(.login-page) [data-aos="fade-down"] {
    -webkit-transform: translate3d(0, -35px, 0);
            transform: translate3d(0, -35px, 0); }
  :not(.login-page) [data-aos="fade-up"] {
    -webkit-transform: translate3d(0, 35px, 0);
            transform: translate3d(0, 35px, 0); }
  :not(.login-page) .close_menu {
    cursor: pointer; }
  :not(.login-page) .open_menu {
    cursor: pointer;
    transition: all .2s ease; }
    :not(.login-page) .open_menu:hover {
      background-color: #dae0e5 !important; }
  :not(.login-page) .slick-prev,
  :not(.login-page) .slick-next {
    position: absolute;
    display: block;
    height: 32px;
    width: 32px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    margin-top: -16px;
    padding: 0;
    border: none;
    outline: none;
    z-index: 1; }
    :not(.login-page) .slick-prev:hover, :not(.login-page) .slick-prev:focus,
    :not(.login-page) .slick-next:hover,
    :not(.login-page) .slick-next:focus {
      outline: none;
      background: transparent;
      color: transparent; }
      :not(.login-page) .slick-prev:hover:before, :not(.login-page) .slick-prev:focus:before,
      :not(.login-page) .slick-next:hover:before,
      :not(.login-page) .slick-next:focus:before {
        opacity: 0.5; }
    :not(.login-page) .slick-prev.slick-disabled:before,
    :not(.login-page) .slick-next.slick-disabled:before {
      opacity: 0.3; }
    :not(.login-page) .slick-prev:before,
    :not(.login-page) .slick-next:before {
      display: block;
      content: "";
      width: 32px;
      height: 32px;
      opacity: 1;
      transition: opacity 0.2s; }
  :not(.login-page) .slick-prev {
    left: 0; }
    [dir="rtl"] :not(.login-page) .slick-prev {
      left: auto;
      right: 0; }
  :not(.login-page) .slick-next {
    right: 0; }
    [dir="rtl"] :not(.login-page) .slick-next {
      left: 0;
      right: auto; }
  :not(.login-page) .slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
    margin: 0;
    left: 0;
    right: 0; }
    :not(.login-page) .slick-dots li {
      position: relative;
      display: inline-block;
      height: 10px;
      width: 10px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer; }
      :not(.login-page) .slick-dots li button {
        box-sizing: border-box;
        border: 2px solid #FFFFFF;
        border-radius: 50%;
        background: transparent;
        display: block;
        height: 10px;
        width: 10px;
        padding: 0;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        color: transparent;
        opacity: 0.3;
        cursor: pointer; }
        :not(.login-page) .slick-dots li button:hover {
          outline: none;
          opacity: 0.8; }
      :not(.login-page) .slick-dots li.slick-active button {
        background: #FFFFFF;
        opacity: 1; }
        :not(.login-page) .slick-dots li.slick-active button:hover {
          opacity: 1; }
  :not(.login-page) .slick-slide {
    outline: none; }
  :not(.login-page) .container {
    position: relative; }
  :not(.login-page) ::-moz-selection {
    background: #7121F4;
    color: #FFFFFF; }
  :not(.login-page) ::-webkit-selection {
    background: #7121F4;
    color: #FFFFFF; }
  :not(.login-page) ::selection {
    background: #7121F4;
    color: #FFFFFF; }
  @media (max-width: 1199.98px) {
    :not(.login-page) header[class^="pt-"], :not(.login-page) header[class*=" pt-"],
    :not(.login-page) section[class^="pt-"],
    :not(.login-page) section[class*=" pt-"] {
      padding-top: 80px; }
    :not(.login-page) header[class^="pb-"], :not(.login-page) header[class*=" pb-"],
    :not(.login-page) section[class^="pb-"],
    :not(.login-page) section[class*=" pb-"] {
      padding-bottom: 80px; }
    :not(.login-page) h1 br,
    :not(.login-page) h2 br,
    :not(.login-page) h3 br,
    :not(.login-page) .text-adaptive br {
      display: none; }
    :not(.login-page) h1,
    :not(.login-page) h2 {
      font-size: 52px;
      line-height: 62px; }
      :not(.login-page) h1.big,
      :not(.login-page) h2.big {
        font-size: 62px;
        line-height: 72px; }
      :not(.login-page) h1.small,
      :not(.login-page) h2.small {
        font-size: 36px;
        line-height: 46px; }
    :not(.login-page) h3 {
      font-size: 36px;
      line-height: 46px; }
    :not(.login-page) .navigation_mobile {
      display: block; } }
  @media (max-width: 991.98px) {
    :not(.login-page) h1,
    :not(.login-page) h2 {
      font-size: 42px;
      line-height: 52px; }
      :not(.login-page) h1.big,
      :not(.login-page) h2.big {
        font-size: 52px;
        line-height: 62px; }
      :not(.login-page) h1.small,
      :not(.login-page) h2.small {
        font-size: 32px;
        line-height: 42px; }
    :not(.login-page) h3 {
      font-size: 32px;
      line-height: 42px; }
    :not(.login-page) .text-adaptive.f-24 {
      font-size: 22px;
      line-height: 32px; }
    :not(.login-page) .text-adaptive.f-22 {
      font-size: 20px;
      line-height: 30px; }
    :not(.login-page) .text-adaptive.f-20 {
      font-size: 18px;
      line-height: 28px; }
    :not(.login-page) .alert {
      top: 10px;
      left: 20px;
      right: 20px;
      -webkit-transform: none;
              transform: none;
      width: auto;
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) h1,
    :not(.login-page) h2 {
      font-size: 32px;
      line-height: 42px; }
      :not(.login-page) h1.big,
      :not(.login-page) h2.big {
        font-size: 42px;
        line-height: 52px; }
      :not(.login-page) h1.small,
      :not(.login-page) h2.small {
        font-size: 26px;
        line-height: 36px; }
    :not(.login-page) h3 {
      font-size: 26px;
      line-height: 36px; }
    :not(.login-page) .text-adaptive.f-24 {
      font-size: 20px;
      line-height: 30px; }
    :not(.login-page) .text-adaptive.f-22 {
      font-size: 18px;
      line-height: 28px; }
    :not(.login-page) .text-adaptive.f-20 {
      font-size: 16px;
      line-height: 26px; }
    :not(.login-page) .text-adaptive.f-18 {
      font-size: 16px;
      line-height: 26px; }
    :not(.login-page) .btn.lg {
      height: 50px;
      line-height: 50px;
      padding: 0 30px;
      font-size: 18px;
      font-weight: 500; }
      :not(.login-page) .btn.lg[class^="border-"], :not(.login-page) .btn.lg[class*=" border-"] {
        line-height: 46px; }
    :not(.login-page) .btn.xl {
      height: 60px;
      line-height: 60px;
      padding: 0 35px;
      font-size: 18px;
      font-weight: 500; }
      :not(.login-page) .btn.xl[class^="border-"], :not(.login-page) .btn.xl[class*=" border-"] {
        line-height: 56px; }
    :not(.login-page) .input.lg {
      height: 50px;
      font-size: 18px;
      font-weight: 500; } }
  @media (max-width: 575.98px) {
    :not(.login-page) h1,
    :not(.login-page) h2 {
      font-size: 26px;
      line-height: 36px; }
      :not(.login-page) h1.big,
      :not(.login-page) h2.big {
        font-size: 32px;
        line-height: 42px; }
      :not(.login-page) h1.small,
      :not(.login-page) h2.small {
        font-size: 22px;
        line-height: 32px; }
    :not(.login-page) .btn.xl {
      height: 50px;
      line-height: 50px;
      padding: 0 30px; }
      :not(.login-page) .btn.xl[class^="border-"], :not(.login-page) .btn.xl[class*=" border-"] {
        line-height: 46px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .header_1 .text-adaptive {
      margin-top: 20px; }
    :not(.login-page) .header_1 .buttons {
      margin-top: 40px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .header_2 .text-adaptive {
      margin-top: 20px; }
    :not(.login-page) .header_2 form {
      margin-top: 40px; }
      :not(.login-page) .header_2 form .input {
        text-align: center;
        max-width: 100%;
        width: 100%; }
      :not(.login-page) .header_2 form .btn {
        margin-top: 15px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .header_3 form {
      margin-top: 40px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .header_3 .input {
      margin-bottom: 20px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .header_4 .poster {
      margin-top: 30px; } }
  :not(.login-page) .header_5 .block_with_border {
    border-left: 2px solid rgba(30, 48, 96, 0.3);
    border-right: 2px solid rgba(30, 48, 96, 0.3); }
  @media (max-width: 991.98px) {
    :not(.login-page) .header_5 .block_with_border {
      border: none;
      padding: 0 15px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .header_5 .btn {
      margin-top: 30px; }
    :not(.login-page) .header_5 img {
      margin-bottom: 20px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .header_6 .btn_holder {
      margin-top: 30px;
      margin-bottom: 30px; } }
  :not(.login-page) .header_7 {
    overflow: hidden; }
    :not(.login-page) .header_7_bg {
      top: 0; }
    @media (max-width: 1199.98px) {
      :not(.login-page) .header_7_bg {
        margin-left: 600px; }
      :not(.login-page) .header_7 .inner {
        padding-top: 80px;
        padding-bottom: 80px; } }
    @media (max-width: 575.98px) {
      :not(.login-page) .header_7 .buttons {
        margin-top: 30px; }
        :not(.login-page) .header_7 .buttons .btn {
          margin: 0 auto 10px auto !important;
          display: block;
          max-width: 320px; } }
  :not(.login-page) .header_8 .slick-arrow {
    top: 50px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .header_8 .slick-arrow {
      top: 40px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .header_8 .slick-arrow {
      top: 35px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .header_8 .slick-arrow {
      top: 30px; }
    :not(.login-page) .header_8 .text-adaptive {
      margin-top: 15px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .header_8 .slider {
      padding: 0; }
    :not(.login-page) .header_8 .slick-arrow {
      display: none !important; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .header_9 .left {
      padding-top: 40px; } }
  :not(.login-page) .header_10 .videos .fa-play {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  @media (max-width: 1199.98px) {
    :not(.login-page) .header_10 .btn,
    :not(.login-page) .header_10 .videos {
      margin-top: 50px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .header_10 .videos {
      margin-top: 20px; }
      :not(.login-page) .header_10 .videos .text-adaptive {
        width: auto;
        margin: 0 auto; }
    :not(.login-page) .header_10 .image {
      margin: 0 auto;
      margin-bottom: 15px; } }
  :not(.login-page) .header_menu_11 .btn {
    min-width: 80px; }
  @media (max-width: 469.98px) {
    :not(.login-page) .header_11 .buttons {
      margin-top: 20px;
      display: block !important; }
      :not(.login-page) .header_11 .buttons .btn,
      :not(.login-page) .header_11 .buttons .link {
        vertical-align: baseline;
        margin-left: 0;
        margin-top: 20px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .header_12 .logo {
      margin-bottom: 15px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .header_13 .text-adaptive {
      margin-top: 10px; } }
  :not(.login-page) .header_14 .separate:before {
    content: " ";
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    border-bottom: 2px solid #E7EAED; }
  @media (max-width: 991.98px) {
    :not(.login-page) .header_14 .text-adaptive {
      margin-top: 10px; }
    :not(.login-page) .header_14 a.btn {
      margin-top: 20px; }
    :not(.login-page) .header_14 form {
      margin-top: 40px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .header_14 form {
      padding-left: 40px;
      padding-right: 40px; } }
  @media (max-width: 374.98px) {
    :not(.login-page) .header_14 form {
      padding-left: 20px;
      padding-right: 20px; } }
  @media (max-width: 359.98px) {
    :not(.login-page) .header_14 label {
      font-size: 14px; } }
  :not(.login-page) .header_15 .separate {
    position: absolute;
    top: 0;
    width: 2px; }
    :not(.login-page) .header_15 .separate:nth-of-type(1) {
      left: 0; }
    :not(.login-page) .header_15 .separate:nth-of-type(2) {
      right: 0; }
  @media (max-width: 767.98px) {
    :not(.login-page) .header_15 h1 {
      margin-bottom: 15px; }
    :not(.login-page) .header_15 .btn {
      margin-top: 30px; }
    :not(.login-page) .header_15 .blocks {
      margin-top: 20px; } }
  :not(.login-page) .header_16 {
    background-position: 50% 100%;
    overflow: hidden; }
    @media (max-width: 991.98px) {
      :not(.login-page) .header_16 .blocks {
        margin-top: 20px; } }
    @media (max-width: 767.98px) {
      :not(.login-page) .header_16 .img {
        max-width: 100%;
        margin: 0; }
      :not(.login-page) .header_16 .blocks .title {
        margin-top: 15px;
        margin-bottom: 15px; } }
  :not(.login-page) .header_19 .slick-dots {
    width: 10px;
    height: auto;
    left: 0;
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    :not(.login-page) .header_19 .slick-dots li {
      margin: 5px auto; }
      :not(.login-page) .header_19 .slick-dots li button {
        border: none;
        background: #E7EAED; }
      :not(.login-page) .header_19 .slick-dots li.slick-active button {
        background: #7121F4; }
  @media (max-width: 991.98px) {
    :not(.login-page) .header_19 .btn {
      margin-top: 40px; }
    :not(.login-page) .header_19 .inner {
      padding-left: 30px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .header_19 .slider {
      margin-top: 30px; }
    :not(.login-page) .header_19 .slick-dots {
      width: auto;
      top: auto;
      right: 0;
      bottom: -40px;
      flex-direction: row; }
      :not(.login-page) .header_19 .slick-dots li {
        margin: 0 5px; }
    :not(.login-page) .header_19 .inner {
      margin-top: 40px;
      padding-left: 0; }
      :not(.login-page) .header_19 .inner .text-adaptive {
        margin-top: 15px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .header_20 h1 {
      margin-top: 10px; }
    :not(.login-page) .header_20 .text-adaptive {
      margin-top: 15px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .header_20 .img {
      margin-bottom: 40px;
      max-height: 70vh; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .header_21 .buttons {
      margin-top: 50px;
      margin-bottom: 20px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .header_22 .text-adaptive {
      margin-top: 10px;
      margin-bottom: 40px; }
    :not(.login-page) .header_22 .socials {
      margin-top: 40px; }
      :not(.login-page) .header_22 .socials .title {
        margin-bottom: 15px; }
      :not(.login-page) .header_22 .socials .btn {
        margin-left: 10px;
        margin-right: 10px; }
        :not(.login-page) .header_22 .socials .btn:first-of-type {
          margin-left: 0; }
        :not(.login-page) .header_22 .socials .btn:last-of-type {
          margin-right: 0; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .navigation_2 a {
      margin: 0 15px; }
    :not(.login-page) .navigation_2 .dropdown a {
      margin: 0; } }
  :not(.login-page) .navigation_3 form button:hover {
    background: #FFFFFF !important; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .navigation_4 a {
      margin: 0 10px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .navigation_8 a {
      margin: 0 10px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .navigation_9 a {
      margin: 0 10px; } }
  :not(.login-page) .navigation_13 .link {
    border-bottom: 2px solid transparent; }
    :not(.login-page) .navigation_13 .link:hover {
      border-bottom-color: #7121F4; }
  @media (max-width: 991.98px) {
    :not(.login-page) .navigation_14 a,
    :not(.login-page) .navigation_17 a,
    :not(.login-page) .navigation_19 a,
    :not(.login-page) .navigation_20 a,
    :not(.login-page) .navigation_21 a {
      margin: 0 10px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .navigation_18 a,
    :not(.login-page) .navigation_18 form {
      margin-left: 10px;
      margin-right: 10px; } }
  :not(.login-page) .navigation_22 .banner {
    overflow: hidden; }
  :not(.login-page) .navigation_22 .img {
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    min-width: 100%;
    min-height: 100%; }
  :not(.login-page) .navigation_22 .navigation_popup .link {
    padding: 5px 15px; }
    :not(.login-page) .navigation_22 .navigation_popup .link:hover {
      background: #E7EAED;
      color: #1e3060; }
  :not(.login-page) .navigation_22 .navigation_popup .inner {
    top: 0;
    left: 0; }
  @media (max-width: 399.98px) {
    :not(.login-page) .navigation_22 .inner {
      margin-left: 40px; } }
  :not(.login-page) .navigation_23 form button:hover {
    background: #FFFFFF !important; }
  :not(.login-page) .navigation_23 .navigation_popup .slider .inner {
    top: 0;
    left: 0; }
  :not(.login-page) .navigation_23 .navigation_popup .slider .slick-dots {
    bottom: -30px; }
    :not(.login-page) .navigation_23 .navigation_popup .slider .slick-dots li.slick-active button {
      background: #0074f1; }
    :not(.login-page) .navigation_23 .navigation_popup .slider .slick-dots li button {
      opacity: 1;
      background: #E7EAED;
      border: none; }
  :not(.login-page) .navigation_23 .navigation_popup .link:hover img {
    opacity: 0.7; }
  :not(.login-page) .navigation_23 .navigation_popup .link img {
    transition: opacity 0.25s; }
  @media (max-width: 991.98px) {
    :not(.login-page) .navigation_23 .navigation_popup .categories .link {
      margin: 0 10px; } }
  @media (max-width: 469.98px) {
    :not(.login-page) .navigation_23 .slide .inner {
      padding: 30px; }
      :not(.login-page) .navigation_23 .slide .inner h2 {
        font-size: 26px;
        line-height: 36px; } }
  @media (max-width: 359.98px) {
    :not(.login-page) .navigation_23 .slide .inner h2 {
      font-size: 22px;
      line-height: 32px; } }
  :not(.login-page) .navigation_24 .navigation_popup .link:hover img {
    opacity: 0.7; }
  :not(.login-page) .navigation_24 .navigation_popup .link img {
    transition: opacity 0.25s; }
  :not(.login-page) .navigation_25 .navigation_popup {
    border-color: rgba(255, 255, 255, 0.3); }
  :not(.login-page) .navigation_26 .navigation_popup {
    border-color: rgba(255, 255, 255, 0.3); }
  :not(.login-page) .navigation_26 .categories .link {
    color: rgba(255, 255, 255, 0.7); }
    :not(.login-page) .navigation_26 .categories .link:hover {
      background: rgba(255, 255, 255, 0.3);
      color: rgba(255, 255, 255, 0.7); }
  :not(.login-page) .navigation_26 .slider {
    overflow: hidden; }
    :not(.login-page) .navigation_26 .slider img {
      min-height: 250px;
      min-width: 100%; }
    :not(.login-page) .navigation_26 .slider .inner {
      top: 0;
      left: 0; }
    :not(.login-page) .navigation_26 .slider .slick-dots {
      left: 20px;
      right: auto;
      bottom: 25px; }
      :not(.login-page) .navigation_26 .slider .slick-dots li.slick-active button {
        background: #1e3060; }
      :not(.login-page) .navigation_26 .slider .slick-dots li button {
        background: transparent;
        border: 2px solid #1e3060; }
  :not(.login-page) .navigation_26 .right_block {
    overflow: hidden; }
    :not(.login-page) .navigation_26 .right_block .bg {
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .navigation_26 .categories .title {
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_1 h2 {
      margin-top: 10px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .content_2 .text-adaptive {
      margin-top: 15px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_3 .blocks {
      margin-top: 10px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_4 .icons .fa {
      margin: 0 15px; }
    :not(.login-page) .content_4 h2 {
      margin-top: 30px; }
    :not(.login-page) .content_4 .text-adaptive {
      margin-top: 15px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_5 h2 {
      margin-top: 15px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .content_6 .text-adaptive {
      margin-top: 15px; } }
  :not(.login-page) .content_13 .inner {
    border: 2px solid #E7EAED; }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_13 .inner {
      padding-top: 30px;
      padding-bottom: 30px; }
    :not(.login-page) .content_13 h2 {
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_14 .inner {
      padding-top: 30px;
      padding-bottom: 30px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .content_15 .container {
      padding-top: 40px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_15 .text-adaptive {
      margin-top: 10px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .content_16 h2,
    :not(.login-page) .content_16 .text-adaptive {
      padding-left: 0; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_16 .text-adaptive {
      margin-top: 5px; } }
  @media (max-width: 359.98px) {
    :not(.login-page) .content_17 .block_tip {
      padding-left: 20px;
      padding-right: 20px; } }
  :not(.login-page) .content_18 .opening_tabs .tab_opener .fa,
  :not(.login-page) .content_18 .opening_tabs .tab_opener .fas,
  :not(.login-page) .content_18 .opening_tabs .tab_opener .far,
  :not(.login-page) .content_18 .opening_tabs .tab_opener .fab {
    transition: all 0.2s; }
  :not(.login-page) .content_18 .opening_tabs .tab_opener.opened .fa,
  :not(.login-page) .content_18 .opening_tabs .tab_opener.opened .fas,
  :not(.login-page) .content_18 .opening_tabs .tab_opener.opened .far,
  :not(.login-page) .content_18 .opening_tabs .tab_opener.opened .fab {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  :not(.login-page) .content_18 .opening_tabs .tab_text {
    border-bottom: 2px solid #E7EAED; }
    :not(.login-page) .content_18 .opening_tabs .tab_text:last-child {
      border: none; }
  @media (max-width: 991.98px) {
    :not(.login-page) .content_18 .block_right {
      margin-left: 0;
      width: auto; } }
  :not(.login-page) .content_20 .number {
    position: absolute;
    top: 0;
    left: 0; }
  :not(.login-page) .content_20 .content_20_line {
    position: absolute;
    top: 0;
    left: 19px;
    width: 2px;
    height: 100%;
    background: #E7EAED; }
  :not(.login-page) .content_20 .video_block .fa,
  :not(.login-page) .content_20 .video_block .fas,
  :not(.login-page) .content_20 .video_block .far,
  :not(.login-page) .content_20 .video_block .fab {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  @media (max-width: 575.98px) {
    :not(.login-page) .content_20 .video_block .inner {
      margin-left: 0;
      margin-top: 30px; } }
  @media (max-width: 374.98px) {
    :not(.login-page) .content_20 .number {
      position: static; }
    :not(.login-page) .content_20 .block_inner {
      margin-left: 0; }
    :not(.login-page) .content_20 .content_20_line {
      display: none; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_21[class^="pt-"], :not(.login-page) .content_21[class*=" pt-"] {
      padding-top: 40px; }
    :not(.login-page) .content_21[class^="pb-"], :not(.login-page) .content_21[class*=" pb-"] {
      padding-bottom: 40px; } }
  :not(.login-page) .content_22 {
    min-height: 100vh; }
    @media (max-width: 1199.98px) {
      :not(.login-page) .content_22[class^="pb-"], :not(.login-page) .content_22[class*=" pb-"] {
        padding-bottom: 30px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_24[class^="pt-"], :not(.login-page) .content_24[class*=" pt-"] {
      padding-top: 40px; }
    :not(.login-page) .content_24[class^="pb-"], :not(.login-page) .content_24[class*=" pb-"] {
      padding-bottom: 40px; }
    :not(.login-page) .content_24 .tip {
      margin-top: 10px;
      margin-left: 10px; } }
  :not(.login-page) .content_25 {
    min-height: 100vh; }
  @media (max-width: 991.98px) {
    :not(.login-page) .content_26 .btn {
      margin-top: 30px; } }
  :not(.login-page) .content_27 .image .fa,
  :not(.login-page) .content_27 .image .fas,
  :not(.login-page) .content_27 .image .far,
  :not(.login-page) .content_27 .image .fab {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_27 .poster {
      margin-bottom: 20px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .content_27 .image {
      margin-left: auto;
      margin-right: auto; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_29[class^="pb-"], :not(.login-page) .content_29[class*=" pb-"] {
      padding-bottom: 60px; }
    :not(.login-page) .content_29 .holder {
      margin-bottom: 15px; } }
  :not(.login-page) .content_30 .block {
    background: rgba(231, 234, 237, 0.4); }
    :not(.login-page) .content_30 .block img {
      max-width: 70%;
      max-height: 70%; }
  :not(.login-page) .content_31 .block {
    border: 2px solid rgba(255, 255, 255, 0.15); }
    :not(.login-page) .content_31 .block img {
      max-width: 70%;
      max-height: 70%; }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_31 .text-adaptive {
      margin-top: 10px;
      margin-bottom: 30px; } }
  :not(.login-page) .content_32 .left .cell {
    padding: 22px 30px 20px 30px; }
    :not(.login-page) .content_32 .left .cell.bordered {
      border-bottom: 2px solid #E7EAED; }
  :not(.login-page) .content_32 .right {
    background: rgba(231, 234, 237, 0.6); }
  :not(.login-page) .content_33 .table td,
  :not(.login-page) .content_33 .table th {
    padding: 24px 15px 20px 15px; }
  :not(.login-page) .content_33 .table tr td:first-of-type,
  :not(.login-page) .content_33 .table tr th:first-of-type {
    padding-left: 30px; }
  :not(.login-page) .content_33 .table tr td:last-of-type,
  :not(.login-page) .content_33 .table tr th:last-of-type {
    padding-right: 30px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .content_33 .table-responsive-shadow {
      position: relative; }
      :not(.login-page) .content_33 .table-responsive-shadow:after {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 40px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 78%); } }
  :not(.login-page) .content_34 #copy_from_me {
    background: rgba(231, 234, 237, 0.5);
    font-family: Courier New, monospace; }
    :not(.login-page) .content_34 #copy_from_me pre {
      color: #1e3060; }
  @media (max-width: 992.98px) {
    :not(.login-page) .content_34 .right {
      margin-top: 50px;
      padding: 0; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .testimonial_2 .text-adaptive {
      margin-top: 10px;
      margin-bottom: 25px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .testimonial_3[class^="pb-"], :not(.login-page) .testimonial_3[class*=" pb-"] {
      padding-bottom: 20px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .testimonial_3 h2,
    :not(.login-page) .testimonial_3 .block {
      margin-bottom: 30px; } }
  :not(.login-page) .testimonial_4 .block {
    border: 2px solid #E7EAED; }
  @media (max-width: 991.98px) {
    :not(.login-page) .testimonial_4 .holder {
      max-width: 570px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .testimonial_4 .block {
      padding: 30px; }
    :not(.login-page) .testimonial_4 .text-adaptive {
      margin-bottom: 20px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .testimonial_4 .inner {
      margin-left: 0; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .testimonial_5[class^="pb-"], :not(.login-page) .testimonial_5[class*=" pb-"] {
      padding-bottom: 30px; } }
  :not(.login-page) .testimonial_6 .block {
    border: 2px solid rgba(255, 255, 255, 0.15); }
  :not(.login-page) .testimonial_6 .author {
    left: 0;
    bottom: 0; }
  @media (max-width: 575.98px) {
    :not(.login-page) .testimonial_6 .inner {
      padding-left: 20px;
      padding-bottom: 0; }
    :not(.login-page) .testimonial_6 .info {
      margin-left: 0; }
    :not(.login-page) .testimonial_6 .author {
      margin-top: 10px;
      position: static; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_1[class^="pb-"], :not(.login-page) .feature_1[class*=" pb-"] {
      padding-bottom: 30px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_1 .title {
      margin-top: 10px;
      margin-bottom: 10px; }
      :not(.login-page) .feature_1 .title br {
        display: none; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_2[class^="pb-"], :not(.login-page) .feature_2[class*=" pb-"] {
      padding-bottom: 30px; }
    :not(.login-page) .feature_2 .description {
      margin-top: 20px; } }
  :not(.login-page) .feature_3 .block {
    border: 2px solid #E7EAED; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_3[class^="pb-"], :not(.login-page) .feature_3[class*=" pb-"] {
      padding-bottom: 50px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_3 .description {
      margin-top: 10px;
      margin-bottom: 40px; } }
  :not(.login-page) .feature_4 .block {
    border: 2px solid rgba(255, 255, 255, 0.3); }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_4[class^="pb-"], :not(.login-page) .feature_4[class*=" pb-"] {
      padding-bottom: 50px; }
    :not(.login-page) .feature_4 .description {
      margin-top: 10px;
      margin-bottom: 40px; }
    :not(.login-page) .feature_4 .block {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 50px; } }
  :not(.login-page) .feature_5 .block {
    border: 2px solid #E7EAED;
    border-top: none; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_5[class^="pb-"], :not(.login-page) .feature_5[class*=" pb-"] {
      padding-bottom: 30px; }
    :not(.login-page) .feature_5 .description {
      margin-top: 10px; }
    :not(.login-page) .feature_5 .block {
      padding: 30px; }
      :not(.login-page) .feature_5 .block .title {
        margin-bottom: 10px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .feature_5 .holder {
      max-width: 370px; } }
  :not(.login-page) .feature_6 .block_bordered {
    border: 2px solid rgba(255, 255, 255, 0.2); }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_6 .block {
      margin-top: 40px; }
    :not(.login-page) .feature_6 .title {
      margin-top: 15px;
      margin-bottom: 10px; }
    :not(.login-page) .feature_6 .block_bordered {
      margin-top: 50px;
      padding: 30px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_7[class^="pb-"], :not(.login-page) .feature_7[class*=" pb-"] {
      padding-bottom: 20px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_7 h2 {
      margin-bottom: 40px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_8[class^="pb-"], :not(.login-page) .feature_8[class*=" pb-"] {
      padding-bottom: 40px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_8 .icon {
      margin-left: 0;
      width: 25px; }
    :not(.login-page) .feature_8 .title {
      margin-bottom: 10px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_9[class^="pb-"], :not(.login-page) .feature_9[class*=" pb-"] {
      padding-bottom: 40px; }
    :not(.login-page) .feature_9 h2 {
      margin-bottom: 15px; }
    :not(.login-page) .feature_9 .title {
      margin-bottom: 10px; }
    :not(.login-page) .feature_9 .hr {
      margin-bottom: 45px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_10[class^="pb-"], :not(.login-page) .feature_10[class*=" pb-"] {
      padding-bottom: 40px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_10 h2 {
      margin-bottom: 10px; } }
  :not(.login-page) .feature_11 {
    overflow: hidden; }
    @media (max-width: 992.98px) {
      :not(.login-page) .feature_11 .bg {
        max-height: 100vh; }
      :not(.login-page) .feature_11 .description {
        margin-top: 10px;
        margin-bottom: 50px; } }
    @media (max-width: 575.98px) {
      :not(.login-page) .feature_11 .bg {
        margin-top: 50px;
        max-height: 70vh;
        max-width: 100%; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_12[class^="pb-"], :not(.login-page) .feature_12[class*=" pb-"] {
      padding-bottom: 0px; }
    :not(.login-page) .feature_12 .icon {
      margin-left: 0; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_12 .title {
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_12 h2 {
      margin: 10px 0; }
    :not(.login-page) .feature_12 .icon {
      margin: 0 auto 10px auto; }
    :not(.login-page) .feature_12 .bg {
      margin-top: 50px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_13[class^="pb-"], :not(.login-page) .feature_13[class*=" pb-"] {
      padding-bottom: 40px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_14[class^="pb-"], :not(.login-page) .feature_14[class*=" pb-"] {
      padding-bottom: 40px; }
    :not(.login-page) .feature_14 h2 {
      margin-bottom: 0; }
    :not(.login-page) .feature_14 .main_title {
      margin-top: 10px; }
    :not(.login-page) .feature_14 .bg {
      max-height: 80vh;
      margin-bottom: 50px; }
    :not(.login-page) .feature_14 .block {
      margin-bottom: 40px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_15 h2 {
      margin: 0px 0 10px 0; }
    :not(.login-page) .feature_15 .block {
      margin-top: 40px; }
    :not(.login-page) .feature_15 .title {
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_15 .bg {
      margin-top: 50px;
      max-height: 80vh; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_16[class^="pb-"], :not(.login-page) .feature_16[class*=" pb-"] {
      padding-bottom: 30px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .feature_16 h2 {
      margin-bottom: 10px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_17[class^="pb-"], :not(.login-page) .feature_17[class*=" pb-"] {
      padding-bottom: 30px; }
    :not(.login-page) .feature_17 h6 {
      margin-top: 10px;
      margin-bottom: 50px; }
      :not(.login-page) .feature_17 h6 br {
        display: none; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_17 .img {
      margin-bottom: 40px;
      max-height: 70vh; }
    :not(.login-page) .feature_17 .title {
      margin-bottom: 10px; }
    :not(.login-page) .feature_17 .block {
      margin-bottom: 40px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_18 .description {
      margin-top: 10px;
      margin-bottom: 40px; }
    :not(.login-page) .feature_18 .block {
      margin-bottom: 40px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_18 .description {
      margin-bottom: 0; }
    :not(.login-page) .feature_18 .img {
      max-height: 70vh; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_19 .icons {
      margin-bottom: 30px;
      font-size: 26px;
      line-height: 36px; }
    :not(.login-page) .feature_19 .btn {
      margin-top: 30px; } }
  :not(.login-page) .feature_20 {
    overflow: hidden; }
    :not(.login-page) .feature_20 .bg {
      top: 0;
      left: 15px; }
    @media (max-width: 1199.98px) {
      :not(.login-page) .feature_20 .left_side {
        padding-top: 80px;
        padding-bottom: 40px; }
      :not(.login-page) .feature_20 h2 {
        margin-bottom: 10px; } }
    @media (max-width: 767.98px) {
      :not(.login-page) .feature_20 .btn {
        margin-bottom: 40px; }
      :not(.login-page) .feature_20 .title {
        margin: 10px 0; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_21[class^="pb-"], :not(.login-page) .feature_21[class*=" pb-"] {
      padding-bottom: 30px; }
    :not(.login-page) .feature_21 .block {
      margin-bottom: 50px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_21 .description {
      margin-top: 10px;
      margin-bottom: 50px; } }
  :not(.login-page) .feature_22 .buttons .btn {
    min-width: 200px; }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_22[class^="pb-"], :not(.login-page) .feature_22[class*=" pb-"] {
      padding-bottom: 40px; }
    :not(.login-page) .feature_22 .description {
      margin-top: 10px;
      margin-bottom: 40px; }
    :not(.login-page) .feature_22 .icon i {
      font-size: 32px;
      line-height: 42px; }
    :not(.login-page) .feature_22 .title {
      margin-bottom: 10px; }
    :not(.login-page) .feature_22 .block {
      margin-bottom: 40px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_23 .description {
      margin-top: 10px;
      margin-bottom: 40px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_23 .info {
      margin-left: 0; } }
  :not(.login-page) .feature_25 {
    overflow: hidden; }
    :not(.login-page) .feature_25 .bg {
      top: 0;
      left: 15px; }
    @media (max-width: 1199.98px) {
      :not(.login-page) .feature_25 .left_side {
        padding-top: 80px;
        padding-bottom: 40px; }
      :not(.login-page) .feature_25 .description {
        margin: 10px 0 50px 0; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_26 .link {
      margin-bottom: 40px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .feature_26[class^="pb-"], :not(.login-page) .feature_26[class*=" pb-"] {
      padding-bottom: 40px; }
    :not(.login-page) .feature_26 .img {
      max-height: 70vh; }
    :not(.login-page) .feature_26 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .feature_26 .title {
      margin-bottom: 10px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_27[class^="pb-"], :not(.login-page) .feature_27[class*=" pb-"] {
      padding-bottom: 30px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_27 .description {
      margin: 10px 0 50px 0; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .feature_28 .description {
      margin: 10px 0 40px 0; }
    :not(.login-page) .feature_28 .img {
      margin-top: 40px; } }
  :not(.login-page) .feature_29 .slick-slide {
    cursor: -webkit-grab;
    cursor: grab; }
  :not(.login-page) .feature_29 .slick-dots {
    left: 0;
    bottom: auto;
    top: 50%;
    width: 10px;
    margin-top: -35px; }
    :not(.login-page) .feature_29 .slick-dots li {
      margin: 5px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_29 .slick-slide {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_29 .slick-dots {
      top: auto;
      margin-top: 0;
      width: 100%;
      bottom: 30px; }
    :not(.login-page) .feature_29 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .feature_29 .title {
      margin: 10px 0; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_29 .img {
      max-height: 70vh;
      margin-bottom: 50px; } }
  :not(.login-page) .feature_31 .img {
    top: 0;
    right: 15px; }
  :not(.login-page) .feature_31 .slick-slide {
    cursor: -webkit-grab;
    cursor: grab; }
  :not(.login-page) .feature_31 .slick-dots {
    left: auto;
    right: calc((100% - 1170px) / 2);
    bottom: auto;
    top: 50%;
    width: 10px;
    margin-top: -35px; }
    :not(.login-page) .feature_31 .slick-dots li {
      margin: 5px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_31 .slick-dots {
      right: calc((100% - 970px) / 2); }
    :not(.login-page) .feature_31 .slick-slide {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_31 .slick-dots {
      right: calc((100% - 750px) / 2); } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_31 .slick-dots {
      top: auto;
      bottom: 30px;
      left: 0;
      right: 0;
      width: auto; }
    :not(.login-page) .feature_31 .slick-slide {
      padding-bottom: 40px; }
    :not(.login-page) .feature_31 .right_side {
      padding-bottom: 0; }
    :not(.login-page) .feature_31 .img {
      position: static;
      height: auto;
      max-width: 100%; }
    :not(.login-page) .feature_31 .title {
      margin-bottom: 10px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_32 .block {
      margin-left: 20px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_32 .decription {
      margin: 10px 0 40px 0; }
    :not(.login-page) .feature_32 .left_side,
    :not(.login-page) .feature_32 .right_side {
      margin-top: 0; }
    :not(.login-page) .feature_32 .block {
      margin-left: 0; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_33 .description {
      margin-top: 10px; }
    :not(.login-page) .feature_33 .title {
      margin-bottom: 0px; } }
  :not(.login-page) .feature_34 {
    overflow: hidden; }
    @media (max-width: 767.98px) {
      :not(.login-page) .feature_34[class^="pb-"], :not(.login-page) .feature_34[class*=" pb-"] {
        padding-bottom: 30px; }
      :not(.login-page) .feature_34 .title {
        margin: 10px 0; } }
    @media (max-width: 575.98px) {
      :not(.login-page) .feature_34 h2 {
        margin-bottom: 10px; }
      :not(.login-page) .feature_34 .img {
        max-width: 100%; }
      :not(.login-page) .feature_34 .blocks {
        margin-top: 50px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_36 h2 {
      margin-top: 0;
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_36 .img {
      max-height: 70vh; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_37[class^="pb-"], :not(.login-page) .feature_37[class*=" pb-"] {
      padding-bottom: 40px; }
    :not(.login-page) .feature_37 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .feature_37 .advantage {
      margin-bottom: 40px; } }
  :not(.login-page) .feature_38 {
    background-position: 50% 100%;
    overflow: hidden;
    padding-bottom: 370px; }
    :not(.login-page) .feature_38 .img {
      top: 70px;
      left: 50%;
      -webkit-transform: translateX(-44%);
              transform: translateX(-44%); }
    @media (max-width: 1199.98px) {
      :not(.login-page) .feature_38 {
        padding-bottom: 300px; }
        :not(.login-page) .feature_38 .img {
          height: 150%; } }
    @media (max-width: 991.98px) {
      :not(.login-page) .feature_38 {
        padding-bottom: 250px; }
        :not(.login-page) .feature_38 .img {
          top: 200px;
          height: 125%; } }
    @media (max-width: 767.98px) {
      :not(.login-page) .feature_38 h2 {
        margin-bottom: 10px; }
      :not(.login-page) .feature_38 .link {
        margin-top: 20px; } }
    @media (max-width: 575.98px) {
      :not(.login-page) .feature_38 {
        padding-bottom: 200px; }
        :not(.login-page) .feature_38 .img {
          top: 50%;
          height: 100%; }
        :not(.login-page) .feature_38 .link {
          font-size: 16px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_39 .description {
      margin: 10px 0 0 0; }
    :not(.login-page) .feature_39 .advantage {
      margin-top: 40px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_40[class^="pb-"], :not(.login-page) .feature_40[class*=" pb-"] {
      padding-bottom: 45px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_41 .description {
      margin: 10px 0 40px 0; }
    :not(.login-page) .feature_41 .inner {
      padding-bottom: 60px; } }
  :not(.login-page) .feature_42 .bg {
    top: 0;
    right: 15px; }
  @media (max-width: 575.98px) {
    :not(.login-page) .feature_42 .inner {
      padding-top: 80px;
      padding-bottom: 60px; }
    :not(.login-page) .feature_42 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .feature_42 .hr {
      margin: 40px 0 0 0; } }
  :not(.login-page) .feature_43 .bg {
    top: 0;
    right: 15px; }
  @media (max-width: 575.98px) {
    :not(.login-page) .feature_43 .inner {
      padding-top: 80px;
      padding-bottom: 80px; }
    :not(.login-page) .feature_43 h2 {
      margin-bottom: 10px; } }
  :not(.login-page) .feature_44 {
    overflow: hidden; }
    @media (max-width: 767.98px) {
      :not(.login-page) .feature_44 h2 {
        margin-top: 0;
        margin-bottom: 10px; }
      :not(.login-page) .feature_44 .img {
        margin-top: 40px;
        max-width: 100%; }
      :not(.login-page) .feature_44 .row2 {
        margin-top: 50px; }
      :not(.login-page) .feature_44 .inner2 {
        padding-bottom: 0; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_45[class^="pb-"], :not(.login-page) .feature_45[class*=" pb-"] {
      padding-bottom: 35px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .feature_45 h2,
    :not(.login-page) .feature_45 .title {
      margin-bottom: 10px; }
    :not(.login-page) .feature_45 .description {
      margin-bottom: 40px; } }
  :not(.login-page) .feature_46 .block:hover, :not(.login-page) .feature_46 .block:focus {
    opacity: 0.7; }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_46 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .feature_46 .block {
      margin-bottom: 50px; } }
  :not(.login-page) .feature_47 .block {
    border: 2px solid rgba(255, 255, 255, 0.2); }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_47[class^="pb-"], :not(.login-page) .feature_47[class*=" pb-"] {
      padding-bottom: 50px; }
    :not(.login-page) .feature_47 h2 {
      margin-bottom: 30px; }
    :not(.login-page) .feature_47 .block {
      padding: 30px; } }
  @media (max-width: 467.98px) {
    :not(.login-page) .form_1 h2 {
      margin-bottom: 20px; }
    :not(.login-page) .form_1 form {
      padding: 30px; } }
  @media (max-width: 359.98px) {
    :not(.login-page) .form_1 form {
      padding: 30px 20px; } }
  :not(.login-page) .form_2 {
    overflow: hidden; }
    :not(.login-page) .form_2 .bg {
      top: 0;
      left: 15px; }
    @media (max-width: 1199.98px) {
      :not(.login-page) .form_2 form {
        padding: 80px 0; }
      :not(.login-page) .form_2 .buttons {
        margin-top: 10px; }
      :not(.login-page) .form_2 button {
        margin-right: 0px;
        width: 100%; } }
    @media (max-width: 575.98px) {
      :not(.login-page) .form_2 form {
        max-width: 290px;
        margin: 0 auto; } }
  :not(.login-page) .form_4 .form_4_menu .slick-track {
    position: relative;
    border-bottom: 3px solid #E7EAED; }
  :not(.login-page) .form_4 .form_4_menu a {
    width: 50%; }
    :not(.login-page) .form_4 .form_4_menu a:after {
      content: "";
      display: block;
      width: 0%;
      height: 3px;
      background: #1e3060;
      position: absolute;
      right: 0;
      bottom: -3px;
      transition: width 0.2s;
      z-index: 10000; }
    :not(.login-page) .form_4 .form_4_menu a:last-child:after {
      left: 0;
      right: auto; }
    :not(.login-page) .form_4 .form_4_menu a.slick-current {
      color: #1e3060; }
      :not(.login-page) .form_4 .form_4_menu a.slick-current:after {
        width: 100%; }
  :not(.login-page) .form_4 .separate {
    position: relative; }
    :not(.login-page) .form_4 .separate:before {
      content: " ";
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      border-bottom: 1px solid #E7EAED; }
  @media (max-width: 575.98px) {
    :not(.login-page) .form_4 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .form_4 .form_4_menu a {
      line-height: 60px; }
    :not(.login-page) .form_4 .slider {
      margin-top: 40px;
      padding-bottom: 40px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .form_6 form {
      padding-top: 40px; }
    :not(.login-page) .form_6 h2 {
      margin-bottom: 30px; }
    :not(.login-page) .form_6 .hr {
      margin-top: 30px; } }
  :not(.login-page) .form_7 .right_side {
    border: 2px solid rgba(255, 255, 255, 0.2); }
  @media (max-width: 767.98px) {
    :not(.login-page) .form_7 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .form_7 .right_side {
      margin-top: 50px;
      padding: 30px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .form_9 form {
      margin-top: 40px; }
    :not(.login-page) .form_9 .input {
      width: 100%;
      margin-right: 0; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .form_10 .icons {
      font-size: 32px;
      line-height: 42px; }
      :not(.login-page) .form_10 .icons i {
        margin: 0 15px; }
    :not(.login-page) .form_10 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .form_10 form {
      margin-top: 40px; }
    :not(.login-page) .form_10 .input {
      width: 100%;
      margin-right: 0; } }
  :not(.login-page) .form_11 form {
    border: 2px solid #E7EAED; }
  @media (max-width: 767.98px) {
    :not(.login-page) .form_11 form {
      padding: 50px 30px; }
    :not(.login-page) .form_11 .input {
      width: 100%;
      margin-right: 0; } }
  :not(.login-page) .form_13 .js-form-block {
    border: 2px solid #E7EAED;
    transition: all 0.2s; }
    :not(.login-page) .form_13 .js-form-block.active {
      border-color: #0074f1; }
  :not(.login-page) .form_13 .icons {
    top: 25px;
    right: 30px;
    line-height: 1; }
  @media (max-width: 991.98px) {
    :not(.login-page) .form_13 .block {
      width: 250px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .form_13 h2 {
      margin-bottom: 30px; }
    :not(.login-page) .form_13 .js-form-block {
      padding: 30px; }
    :not(.login-page) .form_13 .icons {
      font-size: 32px; }
    :not(.login-page) .form_13 .block {
      margin-right: 0;
      width: 100%; } }
  @media (max-width: 469.98px) {
    :not(.login-page) .form_13 .inner,
    :not(.login-page) .form_13 .text-adaptive {
      margin-left: 0; } }
  :not(.login-page) .form_14 form {
    border: 2px solid #E7EAED; }
  @media (max-width: 575.98px) {
    :not(.login-page) .form_14 form {
      padding: 50px 30px; } }
  :not(.login-page) .form_15 .form_15_menu .slick-track {
    position: relative;
    border-bottom: 3px solid #E7EAED; }
  :not(.login-page) .form_15 .form_15_menu a {
    width: 50%; }
    :not(.login-page) .form_15 .form_15_menu a:after {
      content: "";
      display: block;
      width: 0%;
      height: 3px;
      background: #1e3060;
      position: absolute;
      right: 0;
      bottom: -3px;
      transition: width 0.2s;
      z-index: 10000; }
    :not(.login-page) .form_15 .form_15_menu a:last-child:after {
      left: 0;
      right: auto; }
    :not(.login-page) .form_15 .form_15_menu a.slick-current {
      color: #1e3060; }
      :not(.login-page) .form_15 .form_15_menu a.slick-current:after {
        width: 100%; }
  @media (max-width: 575.98px) {
    :not(.login-page) .form_15 h2 {
      margin-bottom: 30px; }
    :not(.login-page) .form_15 .form_15_menu a {
      line-height: 60px; }
    :not(.login-page) .form_15 .slider {
      margin-top: 40px;
      padding: 0 20px 40px 20px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_1 .description {
      margin: 10px 0 30px 0; }
    :not(.login-page) .call_to_action_1 .btn {
      margin-bottom: 15px; } }
  :not(.login-page) .call_to_action_2 .inner {
    border: 2px solid #E7EAED; }
  @media (max-width: 991.98px) {
    :not(.login-page) .call_to_action_2 .inner {
      padding: 40px 30px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .call_to_action_2 .inner {
      padding: 30px 20px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .call_to_action_3 .inner {
      padding: 50px 30px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .call_to_action_4 .text-adaptive {
      margin-top: 10px; } }
  :not(.login-page) .call_to_action_5 .inner {
    min-height: 100vh; }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_7 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .call_to_action_7 .btn {
      margin-top: 30px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_8 h2 {
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_9 .btn {
      margin-top: 30px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_10 h2 {
      margin-bottom: 0; } }
  :not(.login-page) .call_to_action_12 .btn {
    height: auto; }
  @media (max-width: 575.98px) {
    :not(.login-page) .call_to_action_13 .btn {
      margin: 20px 10px 0 10px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_14 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .call_to_action_14 .title {
      margin: 15px 0 10px 0; }
    :not(.login-page) .call_to_action_14 .btn {
      margin-top: 25px; } }
  :not(.login-page) .call_to_action_15 .inner {
    border: 2px solid #E7EAED; }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_15 h2 {
      margin: 10px 0; }
    :not(.login-page) .call_to_action_15 .inner {
      padding-top: 30px;
      padding-bottom: 40px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .call_to_action_16 .btn {
      margin-left: 10px;
      margin-right: 10px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_17 .buttons {
      font-size: 32px;
      line-height: 42px; }
      :not(.login-page) .call_to_action_17 .buttons a {
        margin: 0 10px 20px 10px; } }
  :not(.login-page) .call_to_action_19 .link {
    display: flex;
    width: 20%; }
    :not(.login-page) .call_to_action_19 .link:hover i {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    :not(.login-page) .call_to_action_19 .link i {
      transition: all 0.2s; }
  :not(.login-page) .call_to_action_19 .border_right {
    border-right: 2px solid #E7EAED; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .call_to_action_19 .inner {
      height: 200px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_19 .inner {
      height: 140px; }
      :not(.login-page) .call_to_action_19 .inner i {
        font-size: 36px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .call_to_action_19 .inner {
      height: 100px; }
      :not(.login-page) .call_to_action_19 .inner i {
        font-size: 28px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .call_to_action_20[class^="pb-"], :not(.login-page) .call_to_action_20[class*=" pb-"] {
      padding-bottom: 50px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_21 .text-adaptive {
      margin-top: 10px;
      margin-bottom: 40px; }
    :not(.login-page) .call_to_action_21 .play {
      width: 60px;
      height: 60px;
      line-height: 60px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .call_to_action_21 .play {
      margin: 0 auto; }
    :not(.login-page) .call_to_action_21 .btn {
      margin-left: 0; } }
  :not(.login-page) .call_to_action_22 .inner {
    border: 2px solid rgba(255, 255, 255, 0.3); }
  @media (max-width: 575.98px) {
    :not(.login-page) .call_to_action_22 .inner {
      padding: 40px 15px; }
    :not(.login-page) .call_to_action_22 .play {
      margin: 30px 0; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .call_to_action_23 {
      padding: 80px 0; }
      :not(.login-page) .call_to_action_23 .img {
        height: auto;
        width: 100%;
        border-radius: 10px; }
      :not(.login-page) .call_to_action_23 .inner {
        padding: 40px 0 0 0; }
      :not(.login-page) .call_to_action_23 .text-adaptive {
        margin: 10px 0 40px 0; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .call_to_action_26 .btn {
      margin: 0 10px 20px 10px; }
    :not(.login-page) .call_to_action_26 .img {
      margin-top: 40px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .call_to_action_27 .input {
      max-width: 290px; }
    :not(.login-page) .call_to_action_27 .btn {
      margin: 20px 0px 0px 0px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_28 .inner {
      padding-left: 0; } }
  :not(.login-page) .call_to_action_29 {
    overflow: hidden; }
    :not(.login-page) .call_to_action_29 .img {
      top: 0;
      left: 0; }
    @media (max-width: 575.98px) {
      :not(.login-page) .call_to_action_29 .img {
        position: static;
        max-width: 100%;
        height: auto; }
      :not(.login-page) .call_to_action_29 h2 {
        margin-bottom: 10px; }
      :not(.login-page) .call_to_action_29 .inner {
        padding-top: 80px;
        padding-bottom: 60px; }
      :not(.login-page) .call_to_action_29 .btn {
        margin-top: 30px; } }
  :not(.login-page) .call_to_action_30 {
    overflow: hidden; }
    @media (max-width: 575.98px) {
      :not(.login-page) .call_to_action_30 .img {
        position: static;
        max-width: 100%; }
      :not(.login-page) .call_to_action_30 .inner {
        padding-top: 0;
        padding-bottom: 60px; }
      :not(.login-page) .call_to_action_30 .btn {
        margin: 20px 10px 0 10px; } }
  :not(.login-page) .call_to_action_31 {
    overflow: hidden; }
    :not(.login-page) .call_to_action_31 .img {
      bottom: 0;
      left: 0px; }
    @media (max-width: 767.98px) {
      :not(.login-page) .call_to_action_31 .inner {
        padding-bottom: 60px; }
      :not(.login-page) .call_to_action_31 .img {
        position: static;
        max-width: 100%; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_32 {
      padding-bottom: 40px; }
      :not(.login-page) .call_to_action_32 .logos {
        margin-bottom: 0; }
        :not(.login-page) .call_to_action_32 .logos img {
          margin: 0 10px 20px 10px; }
      :not(.login-page) .call_to_action_32 .inner {
        padding: 80px 0px 60px 0px; }
      :not(.login-page) .call_to_action_32 .img {
        max-width: 100%; } }
  :not(.login-page) .team_1 .block {
    border: 2px solid #E7EAED; }
  :not(.login-page) .team_1 .block_socials {
    border-top: 2px solid #E7EAED; }
    :not(.login-page) .team_1 .block_socials .border_right {
      border-right: 2px solid #E7EAED; }
  :not(.login-page) .team_2 .bordered {
    border-left: 2px solid #E7EAED;
    border-right: 2px solid #E7EAED; }
  @media (max-width: 767.98px) {
    :not(.login-page) .team_2 .bordered {
      border: none;
      border-top: 2px solid #E7EAED;
      border-bottom: 2px solid #E7EAED; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .team_4 h2 {
      margin-bottom: 0; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .team_5 .block_socials .link {
      margin: 0 10px; } }
  :not(.login-page) .pricing_table_1 .block {
    border: 2px solid #E7EAED;
    border-bottom-left-radius: 10px; }
    :not(.login-page) .pricing_table_1 .block.noborder_right {
      border-right: none; }
  :not(.login-page) .pricing_table_1 .item {
    padding: 16px 0 14px 0;
    border-top: 2px solid #E7EAED; }
  :not(.login-page) .pricing_table_1 .price span.absolute {
    top: 5px;
    left: -15px; }
  @media (max-width: 991.98px) {
    :not(.login-page) .pricing_table_1[class^="pb-"], :not(.login-page) .pricing_table_1[class*=" pb-"] {
      padding-bottom: 30px; }
    :not(.login-page) .pricing_table_1 .block {
      margin: 0 auto 50px auto;
      max-width: 300px;
      border-radius: 10px; }
      :not(.login-page) .pricing_table_1 .block.noborder_right {
        border-right: 2px solid #E7EAED; } }
  :not(.login-page) .pricing_table_3 .overflow {
    overflow: auto; }
  :not(.login-page) .pricing_table_3 .block {
    border-right: 2px solid #E7EAED; }
    :not(.login-page) .pricing_table_3 .block.noborder_right {
      border-right: none; }
  :not(.login-page) .pricing_table_3 .price span.absolute {
    top: -5px;
    left: -15px; }
  :not(.login-page) .pricing_table_3 .table_row {
    border-left: 2px solid #E7EAED;
    border-right: 2px solid #E7EAED;
    border-bottom: 2px solid #E7EAED; }
    :not(.login-page) .pricing_table_3 .table_row:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px; }
  :not(.login-page) .pricing_table_3 .table_head {
    background: rgba(231, 234, 237, 0.5);
    border: none; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .pricing_table_3 .table-responsive-shadow {
      position: relative; }
      :not(.login-page) .pricing_table_3 .table-responsive-shadow:after {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 40px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 78%); } }
  :not(.login-page) .pricing_table_4 .block {
    border: 2px solid rgba(255, 255, 255, 0.2); }
  :not(.login-page) .pricing_table_4 ul {
    list-style: none; }
    :not(.login-page) .pricing_table_4 ul li {
      position: relative; }
      :not(.login-page) .pricing_table_4 ul li:not(.disabled):before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\F00C";
        display: block;
        position: absolute;
        left: -25px;
        top: 0px;
        color: #18c967;
        font-size: 12px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .pricing_table_4 .block {
      padding-left: 50px;
      padding-right: 30px; } }
  :not(.login-page) .pricing_table_5 .block:not(.bg-dark) {
    border: 2px solid #E7EAED; }
  :not(.login-page) .pricing_table_5 .price span.absolute {
    top: 0px;
    left: -15px; }
  :not(.login-page) .pricing_table_5 ul {
    list-style: none; }
    :not(.login-page) .pricing_table_5 ul li {
      position: relative; }
      :not(.login-page) .pricing_table_5 ul li:not(.disabled):before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\F00C";
        display: block;
        position: absolute;
        left: -25px;
        top: 0px;
        color: #0074f1;
        font-size: 12px; }
  :not(.login-page) .pricing_table_6 .custom-toggle:hover, :not(.login-page) .pricing_table_6 .custom-toggle:focus {
    background: #005bbe; }
  :not(.login-page) .pricing_table_6 .custom-toggle div {
    left: 3px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 24px;
    height: 24px; }
  :not(.login-page) .pricing_table_6 .img {
    margin-left: -2px;
    margin-right: -2px;
    width: auto;
    min-width: calc(100% + 4px);
    max-width: calc(100% + 4px); }
  :not(.login-page) .pricing_table_6 .block {
    border: 2px solid #E7EAED;
    border-top: none; }
  @media (max-width: 575.98px) {
    :not(.login-page) .pricing_table_6 .block .inner {
      padding: 0 30px; } }
  :not(.login-page) .pricing_table_7 .inner {
    border: 2px solid #E7EAED; }
  @media (max-width: 575.98px) {
    :not(.login-page) .pricing_table_7 h2 {
      margin-bottom: 10px; } }
  :not(.login-page) .contact_1 textarea.input {
    height: 170px; }
  :not(.login-page) .contact_1 .separate {
    position: relative; }
    :not(.login-page) .contact_1 .separate:before, :not(.login-page) .contact_1 .separate:after {
      content: " ";
      display: block;
      position: absolute;
      width: calc(100% / 2 - 60px);
      left: 0;
      top: 50%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
    :not(.login-page) .contact_1 .separate:after {
      left: auto;
      right: 0; }
  :not(.login-page) .contact_2 textarea.input {
    height: 170px; }
  :not(.login-page) .contact_3 textarea.input {
    height: 150px; }
  :not(.login-page) .contact_4 textarea.input {
    height: 110px; }
  @media (max-width: 469.98px) {
    :not(.login-page) .contact_4 form {
      padding: 40px 20px; } }
  :not(.login-page) .contact_5 textarea.input {
    height: 170px; }
  :not(.login-page) .contact_6 form {
    background: rgba(231, 234, 237, 0.3); }
  :not(.login-page) .contact_6 textarea.input {
    height: 170px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .contact_6 .input_block .input {
      width: 240px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .contact_6 .input_block .input {
      width: 300px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .contact_6 .input_block .input {
      width: 100%; } }
  @media (max-width: 469.98px) {
    :not(.login-page) .contact_6 form {
      padding: 40px 20px; } }
  :not(.login-page) .contact_7 textarea.input {
    height: 170px; }
  @media (max-width: 991.98px) {
    :not(.login-page) .contact_7 .input_block .input {
      width: 100%; } }
  :not(.login-page) .contact_8 .js-google-map {
    right: calc((-100vw + 1200px) / 2);
    left: 15px;
    top: 0; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .contact_8 .js-google-map {
      right: calc((-100vw + 970px) / 2); }
    :not(.login-page) .contact_8 .text_big {
      font-size: 32px;
      line-height: 42px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .contact_8 .js-google-map {
      right: calc((-100vw + 750px) / 2); }
    :not(.login-page) .contact_8 .text_big {
      font-size: 26px;
      line-height: 36px; }
    :not(.login-page) .contact_8 .title {
      margin-bottom: 0; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .contact_8 {
      padding-bottom: 80px; }
      :not(.login-page) .contact_8 .left_side {
        padding-top: 80px;
        padding-bottom: 40px; }
      :not(.login-page) .contact_8 .js-google-map {
        position: relative;
        left: auto;
        right: auto;
        height: 60vh; }
      :not(.login-page) .contact_8 .text-adaptive {
        margin: 10px 0 40px 0; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .contact_8 {
      padding-bottom: 0; }
      :not(.login-page) .contact_8 .text_big {
        font-size: 22px;
        line-height: 32px; }
      :not(.login-page) .contact_8 .js-google-map {
        left: -15px;
        width: calc(100% + 30px); } }
  :not(.login-page) .contact_9 .map_overlay,
  :not(.login-page) .contact_9 .js-google-map {
    top: 0; }
  :not(.login-page) .contact_9 .map_overlay {
    background: rgba(53, 56, 61, 0.85); }
  @media (max-width: 1199.98px) {
    :not(.login-page) .contact_9 .text_big {
      font-size: 32px;
      line-height: 42px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .contact_9 .text_big {
      font-size: 26px;
      line-height: 36px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .contact_9 .text-adaptive {
      margin: 10px 0 40px 0; }
    :not(.login-page) .contact_9 .text_big {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 40px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .contact_10[class^="pb-"], :not(.login-page) .contact_10[class*=" pb-"] {
      padding-bottom: 60px; }
    :not(.login-page) .contact_10 h2 {
      margin: 40px 0 60px 0; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .contact_10 h2 a {
      font-size: 75%;
      margin-top: 0; }
    :not(.login-page) .contact_10 .socials a {
      margin: 0 15px 20px 15px; } }
  :not(.login-page) .showcase_1 .block a:hover {
    opacity: 0.8; }
  @media (max-width: 991.98px) {
    :not(.login-page) .showcase_1 .description {
      margin: 10px 0 0 0; } }
  :not(.login-page) .showcase_2 a:hover {
    opacity: 0.8; }
  :not(.login-page) .showcase_3 .block {
    border: 2px solid #E7EAED; }
  :not(.login-page) .showcase_3 img {
    vertical-align: bottom;
    position: relative;
    top: 2px; }
  :not(.login-page) .showcase_3 a:hover {
    opacity: 0.8; }
  @media (max-width: 575.98px) {
    :not(.login-page) .showcase_3 .block .text-adaptive {
      margin: 10px 0 20px 0; } }
  :not(.login-page) .showcase_4 .link:hover {
    -webkit-filter: brightness(1.1);
            filter: brightness(1.1); }
  :not(.login-page) .showcase_6 img {
    transition: all 0.2s; }
  :not(.login-page) .showcase_6 a:hover img {
    opacity: 0.7; }
  :not(.login-page) .showcase_8 a:hover {
    -webkit-filter: brightness(1.15);
            filter: brightness(1.15); }
  :not(.login-page) .showcase_9 .block {
    border: 2px solid #E7EAED; }
  @media (max-width: 359.98px) {
    :not(.login-page) .showcase_9 .block {
      padding-left: 20px;
      padding-right: 20px; } }
  :not(.login-page) .showcase_10 .with_border {
    border: 2px solid #E7EAED; }
  @media (max-width: 575.98px) {
    :not(.login-page) .footer_1 .links a {
      margin: 0 10px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .footer_3 .description {
      margin: 10px 0; }
    :not(.login-page) .footer_3 .socials a {
      margin: 0 10px; }
    :not(.login-page) .footer_3 .title {
      margin-bottom: 15px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .footer_5 .hr {
      margin-top: 30px; }
    :not(.login-page) .footer_5 .socials {
      margin-top: 20px; }
    :not(.login-page) .footer_5 .logo,
    :not(.login-page) .footer_5 .title {
      margin-bottom: 15px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .footer_6 .hr {
      margin: 0 0 50px 0; }
    :not(.login-page) .footer_6 .title {
      margin-bottom: 15px; }
    :not(.login-page) .footer_6 .links a {
      margin-bottom: 10px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .footer_7 .link {
      margin: 0 10px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .footer_8 a {
      margin: 0 10px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .footer_9 form {
      margin-bottom: 40px; }
    :not(.login-page) .footer_9 .title {
      margin-bottom: 15px; }
    :not(.login-page) .footer_9 .links a {
      margin-bottom: 10px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .footer_10 .socials {
      margin-top: 15px; }
    :not(.login-page) .footer_10 .logo,
    :not(.login-page) .footer_10 .title {
      margin-bottom: 15px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .footer_11 a {
      margin: 0 10px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .footer_12 {
      padding-top: 50px; }
      :not(.login-page) .footer_12 .text-adaptive {
        margin-top: 30px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .footer_13 a {
      margin: 0 10px; } }
  :not(.login-page) .ecommerce_1 .product .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_1 .product .img_link:hover img {
    opacity: 0.8; }
  :not(.login-page) .ecommerce_1 .product .btn {
    left: 0;
    bottom: 0; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_1 .main_info .btn {
      margin-top: 30px; } }
  :not(.login-page) .ecommerce_2 .product {
    border: 2px solid #E7EAED; }
    :not(.login-page) .ecommerce_2 .product.big {
      min-height: calc(100% - 30px); }
      :not(.login-page) .ecommerce_2 .product.big ul {
        -webkit-column-count: 2;
                column-count: 2; }
    :not(.login-page) .ecommerce_2 .product li {
      list-style: none;
      position: relative; }
      :not(.login-page) .ecommerce_2 .product li:before {
        content: "";
        display: block;
        position: absolute;
        top: 11px;
        left: -10px;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background: #1E3060; }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_2 .product {
      max-width: 290px;
      padding: 20px 20px 30px 20px; }
      :not(.login-page) .ecommerce_2 .product.big ul {
        -webkit-column-count: 1;
                column-count: 1; }
      :not(.login-page) .ecommerce_2 .product .left {
        margin-right: 0; }
      :not(.login-page) .ecommerce_2 .product img {
        width: 100%;
        max-width: 100%; } }
  :not(.login-page) .ecommerce_3 .product .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_3 .product .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_3 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .ecommerce_3 .products {
      margin-top: 0; }
      :not(.login-page) .ecommerce_3 .products .text-adaptive {
        max-width: 100%; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_3 .product {
      max-width: 290px; } }
  :not(.login-page) .ecommerce_4 .dropdown-menu {
    box-shadow: 0px 15px 40px rgba(114, 114, 114, 0.3); }
  :not(.login-page) .ecommerce_4 .link_holder {
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_4 .link_holder:hover {
      background: #E7EAED; }
  :not(.login-page) .ecommerce_4 .product .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_4 .product .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_4 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .ecommerce_4 .dropdown {
      margin-left: 10px;
      margin-right: 10px; } }
  :not(.login-page) .ecommerce_5 .dropdown-menu {
    box-shadow: 0px 15px 40px rgba(114, 114, 114, 0.3); }
  :not(.login-page) .ecommerce_5 .link_holder {
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_5 .link_holder:hover {
      background: #E7EAED; }
  :not(.login-page) .ecommerce_5 .product .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_5 .product .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_5 .categories {
      margin-top: 10px; }
    :not(.login-page) .ecommerce_5 .category {
      margin-left: 10px;
      margin-right: 10px; }
    :not(.login-page) .ecommerce_5 .product {
      margin-bottom: 0; } }
  :not(.login-page) .ecommerce_6 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_6 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_6 {
      padding-top: 80px; }
      :not(.login-page) .ecommerce_6 .bg {
        max-width: 100%;
        height: auto; }
      :not(.login-page) .ecommerce_6 .inner {
        padding-top: 40px;
        padding-bottom: 80px; }
      :not(.login-page) .ecommerce_6 .buttons {
        margin-top: 30px; } }
  :not(.login-page) .ecommerce_7 .categories a {
    border-bottom: 2px solid transparent; }
    :not(.login-page) .ecommerce_7 .categories a:hover {
      border-color: #3657ae; }
  :not(.login-page) .ecommerce_7 .categories .active {
    color: #1E3060;
    border-color: #1E3060; }
    :not(.login-page) .ecommerce_7 .categories .active:hover {
      border-color: #1E3060; }
  :not(.login-page) .ecommerce_7 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_7 .img_link:hover img {
    opacity: 0.8; }
  :not(.login-page) .ecommerce_7 input[type="radio"]:checked + label:before {
    content: "\F00C";
    color: #FFFFFF; }
  :not(.login-page) .ecommerce_7 input[type="radio"] + label:before {
    border: none;
    line-height: 20px;
    color: #FFFFFF; }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_7 .categories {
      margin-top: 20px; }
      :not(.login-page) .ecommerce_7 .categories a {
        margin: 5px 8px 0 8px;
        font-size: 16px;
        line-height: 26px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_7 .product {
      max-width: 290px; } }
  :not(.login-page) .ecommerce_8 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_8 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_8 .product {
      max-width: 290px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_9 {
      padding-top: 80px; }
      :not(.login-page) .ecommerce_9 .bg {
        max-width: 100%;
        height: auto; }
      :not(.login-page) .ecommerce_9 .inner {
        padding-top: 40px;
        padding-bottom: 80px; }
      :not(.login-page) .ecommerce_9 .prices {
        margin-top: 40px; } }
  :not(.login-page) .ecommerce_10 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_10 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_10 .inner {
      padding: 0; }
    :not(.login-page) .ecommerce_10 .title {
      font-size: 18px;
      line-height: 28px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_10 .product {
      margin-bottom: 0;
      max-width: 370px; } }
  :not(.login-page) .ecommerce_11 .slick-arrow {
    margin-top: 0;
    top: -90px;
    width: 50px;
    height: 50px; }
    :not(.login-page) .ecommerce_11 .slick-arrow:before {
      width: 50px;
      height: 50px;
      border: 2px solid #E7EAED;
      border-radius: 100%;
      transition: all 0.25s; }
    :not(.login-page) .ecommerce_11 .slick-arrow:hover:before {
      opacity: 1;
      background-color: #FFFFFF; }
  :not(.login-page) .ecommerce_11 .slick-prev {
    left: auto;
    right: 75px; }
    :not(.login-page) .ecommerce_11 .slick-prev:before {
      background-size: 7px auto; }
  :not(.login-page) .ecommerce_11 .slick-next {
    right: 15px; }
    :not(.login-page) .ecommerce_11 .slick-next:before {
      background-size: 7px auto; }
  :not(.login-page) .ecommerce_11 .holder:hover .inner {
    opacity: 1; }
  :not(.login-page) .ecommerce_11 .inner {
    top: 0;
    left: 0;
    background: rgba(0, 116, 241, 0.7);
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_11 .inner .btn {
      min-width: 145px; }
  :not(.login-page) .ecommerce_11 input[type="radio"]:checked + label {
    background: #FFFFFF;
    color: #1e3060; }
  :not(.login-page) .ecommerce_11 input[type="radio"] + label {
    height: 50px;
    line-height: 46px;
    border: 2px solid #E7EAED;
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_11 input[type="radio"] + label:before {
      display: none; }
    :not(.login-page) .ecommerce_11 input[type="radio"] + label:hover {
      color: #1e3060; }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_11 .header_holder {
      padding-right: 0; }
    :not(.login-page) .ecommerce_11 .slick-arrow {
      top: 10px; } }
  :not(.login-page) .ecommerce_12 .product {
    border: 2px solid #E7EAED; }
    :not(.login-page) .ecommerce_12 .product li {
      list-style: none;
      position: relative; }
      :not(.login-page) .ecommerce_12 .product li:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background: #1E3060; }
    :not(.login-page) .ecommerce_12 .product .btn {
      left: 50%;
      bottom: 30px;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
  :not(.login-page) .ecommerce_12 .img_link img {
    margin: -2px -2px 0 -2px;
    max-width: calc(100% + 4px);
    min-width: calc(100% + 4px);
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_12 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_12 .product {
      max-width: 370px;
      padding-bottom: 30px; }
      :not(.login-page) .ecommerce_12 .product .btn {
        position: static;
        -webkit-transform: none;
                transform: none; }
    :not(.login-page) .ecommerce_12 .title {
      font-size: 18px;
      line-height: 28px; } }
  :not(.login-page) .ecommerce_13 .category {
    border: 2px solid #E7EAED; }
    :not(.login-page) .ecommerce_13 .category .btn {
      left: 50%;
      bottom: 30px;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
  :not(.login-page) .ecommerce_13 .img_link img {
    margin: -2px -2px 0 -2px;
    max-width: calc(100% + 4px);
    min-width: calc(100% + 4px);
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_13 .img_link:hover img {
    opacity: 0.8; }
  :not(.login-page) .ecommerce_14 .product {
    overflow: hidden; }
  :not(.login-page) .ecommerce_14 .img_link img {
    min-height: 100%;
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_14 .img_link:hover img {
    opacity: 0.8; }
  :not(.login-page) .ecommerce_14 .stars .active {
    color: #f2c94c; }
  :not(.login-page) .ecommerce_14 .stars .absolute {
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_14 .img_link img {
      width: 100%;
      min-height: 0; } }
  :not(.login-page) .ecommerce_15 .slider_menu a img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_15 .slider_menu a:hover img {
    opacity: 0.8; }
  :not(.login-page) .ecommerce_15 .stars .active {
    color: #f2c94c; }
  :not(.login-page) .ecommerce_15 .stars .absolute {
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_15 h2 {
      margin-top: 0px;
      margin-bottom: 5px; }
    :not(.login-page) .ecommerce_15 .btn {
      margin-top: 50px; } }
  :not(.login-page) .ecommerce_16 .dropdown-menu {
    box-shadow: 0px 15px 40px rgba(114, 114, 114, 0.3); }
  :not(.login-page) .ecommerce_16 .link_holder {
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_16 .link_holder:hover {
      background: #E7EAED; }
  :not(.login-page) .ecommerce_16 input[type="radio"]:checked + label:before {
    content: "\F00C";
    color: #FFFFFF; }
  :not(.login-page) .ecommerce_16 input[type="radio"] + label:before {
    border: none;
    line-height: 20px;
    color: #FFFFFF; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_16 .inner {
      margin-left: 0;
      width: 390px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_16 .categories {
      margin-top: 10px; }
    :not(.login-page) .ecommerce_16 .category {
      margin-left: 10px;
      margin-right: 10px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_16 .product {
      max-width: 370px; }
      :not(.login-page) .ecommerce_16 .product .img {
        width: 100%; }
    :not(.login-page) .ecommerce_16 .inner {
      max-width: 100%; }
    :not(.login-page) .ecommerce_16 .price {
      margin-left: 0; } }
  :not(.login-page) .ecommerce_17 .holder:hover .inner {
    opacity: 1; }
  :not(.login-page) .ecommerce_17 .inner {
    top: 0;
    left: 0;
    background: rgba(0, 116, 241, 0.7);
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_18 .dropdown-menu {
    box-shadow: 0px 15px 40px rgba(114, 114, 114, 0.3); }
  :not(.login-page) .ecommerce_18 .link_holder {
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_18 .link_holder:hover {
      background: #E7EAED; }
  :not(.login-page) .ecommerce_18 .img_link img {
    min-height: 100%;
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_18 .img_link:hover img {
    opacity: 0.8; }
  :not(.login-page) .ecommerce_18 .buttons {
    left: 0;
    bottom: 10px; }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_18 .inner {
      margin-left: 0;
      padding-bottom: 0; }
    :not(.login-page) .ecommerce_18 .buttons {
      position: static; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_18 .product {
      max-width: 370px; }
      :not(.login-page) .ecommerce_18 .product .img_link img {
        width: 100%; } }
  :not(.login-page) .ecommerce_19 .product {
    border: 2px solid #E7EAED; }
    :not(.login-page) .ecommerce_19 .product.big {
      min-height: calc(100% - 30px); }
    :not(.login-page) .ecommerce_19 .product .img,
    :not(.login-page) .ecommerce_19 .product .slider {
      margin-top: -2px;
      margin-right: -2px;
      margin-bottom: -2px; }
    :not(.login-page) .ecommerce_19 .product .slider {
      overflow: hidden; }
      :not(.login-page) .ecommerce_19 .product .slider .slick-list,
      :not(.login-page) .ecommerce_19 .product .slider .slick-track,
      :not(.login-page) .ecommerce_19 .product .slider .slick-slide {
        height: 100%; }
    :not(.login-page) .ecommerce_19 .product .slider_menu {
      max-width: 100%; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_19 .product.small {
      width: calc(50% - 15px); }
      :not(.login-page) .ecommerce_19 .product.small .inner {
        padding-left: 20px;
        padding-right: 20px; }
    :not(.login-page) .ecommerce_19 .product.big .inner {
      padding-left: 30px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_19 .product.small {
      width: 100%; }
    :not(.login-page) .ecommerce_19 .product .slider {
      margin: -2px;
      width: auto;
      border-radius: 10px 10px 0 0; }
      :not(.login-page) .ecommerce_19 .product .slider img {
        width: 100%; } }
  @media (max-width: 369.98px) {
    :not(.login-page) .ecommerce_19 .product.big .inner {
      padding: 20px; }
    :not(.login-page) .ecommerce_19 .product.big .title {
      font-size: 18px;
      line-height: 28px; }
    :not(.login-page) .ecommerce_19 .product.big .btn {
      width: 100%; }
    :not(.login-page) .ecommerce_19 .product.big .slider_menu img {
      margin: 0 auto; }
    :not(.login-page) .ecommerce_19 .product.small {
      display: block !important; }
      :not(.login-page) .ecommerce_19 .product.small .img {
        margin: -2px;
        width: calc(100% + 4px);
        max-width: calc(100% + 4px);
        border-radius: 10px 10px 0 0; } }
  :not(.login-page) .ecommerce_20 .category {
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_20 .category:hover {
      background: #E7EAED; }
  :not(.login-page) .ecommerce_20 .product .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_20 .product .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_20 h2 {
      margin-bottom: 20px; } }
  @media (max-width: 359.98px) {
    :not(.login-page) .ecommerce_20 .title {
      font-size: 22px;
      line-height: 32px; } }
  :not(.login-page) .ecommerce_21 .category {
    border: 2px solid #E7EAED;
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_21 .category:hover {
      border-color: #0074f1; }
  :not(.login-page) .ecommerce_21 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_21 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_21 .inner {
      padding-left: 0;
      padding-right: 0; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_21 .category {
      max-width: 320px; } }
  :not(.login-page) .ecommerce_22 .dropdown-menu {
    box-shadow: 0px 15px 40px rgba(114, 114, 114, 0.3); }
  :not(.login-page) .ecommerce_22 .link_holder {
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_22 .link_holder:hover {
      background: #E7EAED; }
  :not(.login-page) .ecommerce_22 .category {
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_22 .category:hover {
      background: #E7EAED; }
  :not(.login-page) .ecommerce_22 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_22 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_22[class^="pb-"], :not(.login-page) .ecommerce_22[class*=" pb-"] {
      padding-bottom: 30px; }
    :not(.login-page) .ecommerce_22 .btn {
      margin-right: 0; } }
  :not(.login-page) .ecommerce_23 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_23 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_23 .buttons {
      margin-top: 50px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_23 {
      padding-top: 80px; }
      :not(.login-page) .ecommerce_23 .bg {
        max-width: 100%;
        height: auto;
        border-radius: 10px; }
      :not(.login-page) .ecommerce_23 .inner {
        padding-top: 40px;
        padding-bottom: 80px; }
      :not(.login-page) .ecommerce_23 .buttons {
        margin-top: 30px; } }
  :not(.login-page) .ecommerce_24 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_24 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_24 {
      padding: 80px 0; }
      :not(.login-page) .ecommerce_24 .bg {
        max-width: 100%;
        height: auto;
        max-height: 80vh;
        border-radius: 10px; }
      :not(.login-page) .ecommerce_24 .inner {
        padding-top: 0;
        padding-bottom: 0; }
      :not(.login-page) .ecommerce_24 .title_price {
        width: 100%; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_24 .product {
      max-width: 370px; }
      :not(.login-page) .ecommerce_24 .product img {
        width: 100%; }
    :not(.login-page) .ecommerce_24 .inner {
      margin-left: 0; } }
  :not(.login-page) .ecommerce_26 .product:hover .inner {
    opacity: 1; }
  :not(.login-page) .ecommerce_26 .inner {
    top: 0;
    left: 0;
    background: rgba(0, 116, 241, 0.7);
    transition: all 0.25s;
    overflow: auto; }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_26 .main_btn {
      margin-top: 25px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_28 .btn {
      margin-top: 40px; } }
  :not(.login-page) .ecommerce_29 .inner {
    border: 2px solid #E7EAED; }
  :not(.login-page) .ecommerce_29 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_29 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_29 .socials {
      margin-top: 20px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_29 .inner {
      padding: 30px; }
    :not(.login-page) .ecommerce_29 .images {
      padding: 0; }
      :not(.login-page) .ecommerce_29 .images .img_link {
        width: calc(100% / 2 - 30px); }
      :not(.login-page) .ecommerce_29 .images img {
        width: 100%; } }
  :not(.login-page) .ecommerce_30 .holder {
    border: 2px solid #E7EAED; }
  :not(.login-page) .ecommerce_30 .bg {
    top: -2px;
    right: -2px;
    bottom: -2px;
    background-position: 50% 50%;
    background-size: cover; }
  :not(.login-page) .ecommerce_30 .product.big .bg {
    width: 300px;
    border-radius: 0 10px 10px 0; }
  :not(.login-page) .ecommerce_30 .product.big .inner {
    margin-right: 320px; }
  :not(.login-page) .ecommerce_30 .product.small .bg {
    left: -2px;
    border-radius: 10px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_30 .inner {
      padding-bottom: 50px; }
    :not(.login-page) .ecommerce_30 .product.big .bg {
      width: auto;
      left: -2px;
      border-radius: 10px; }
    :not(.login-page) .ecommerce_30 .product.big .inner {
      margin-right: 0; }
    :not(.login-page) .ecommerce_30 .product.big .subtitle,
    :not(.login-page) .ecommerce_30 .product.big h2 {
      color: #FFFFFF; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_30 .inner {
      padding: 50px 30px; } }
  @media (max-width: 469.98px) {
    :not(.login-page) .ecommerce_30 .inner {
      padding: 40px 20px; } }
  :not(.login-page) .ecommerce_31 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_31 .img_link:hover img {
    opacity: 0.8; }
  :not(.login-page) .ecommerce_31 .btn_play {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  :not(.login-page) .ecommerce_31 input[type="radio"]:checked + label:before {
    content: "\F00C";
    color: #FFFFFF;
    text-align: center; }
  :not(.login-page) .ecommerce_31 input[type="radio"] + label:before {
    border: none;
    width: 22px;
    height: 22px;
    line-height: 22px;
    color: #FFFFFF; }
  :not(.login-page) .ecommerce_31 input[type="radio"] + label {
    margin: 0 2px;
    width: 22px;
    height: 22px; }
  :not(.login-page) .ecommerce_31 .colors_border {
    border: 2px solid #E7EAED;
    padding: 0 12px; }
  :not(.login-page) .ecommerce_31 .quantity_selector {
    border: 2px solid #E7EAED;
    overflow: hidden; }
    :not(.login-page) .ecommerce_31 .quantity_selector .control {
      width: 36px;
      line-height: 36px;
      background: transparent; }
      :not(.login-page) .ecommerce_31 .quantity_selector .control:hover {
        background: #E7EAED; }
      :not(.login-page) .ecommerce_31 .quantity_selector .control::-moz-selection {
        background: transparent;
        color: #1e3060; }
      :not(.login-page) .ecommerce_31 .quantity_selector .control::-webkit-selection {
        background: transparent;
        color: #1e3060; }
      :not(.login-page) .ecommerce_31 .quantity_selector .control::selection {
        background: transparent;
        color: #1e3060; }
    :not(.login-page) .ecommerce_31 .quantity_selector .input {
      width: 40px;
      height: 36px;
      border-left: 2px solid #E7EAED;
      border-right: 2px solid #E7EAED;
      color: #1e3060;
      font-weight: 500;
      font-size: 16px;
      text-align: center; }
  :not(.login-page) .ecommerce_31 .advantages li {
    list-style: none;
    position: relative; }
    :not(.login-page) .ecommerce_31 .advantages li:before {
      content: "";
      display: block;
      position: absolute;
      top: 11px;
      left: -12px;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background: #1E3060; }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_31 .img_link {
      margin-right: 5px; } }
  :not(.login-page) .ecommerce_32 .slider_menu .slick-arrow {
    margin-top: -25px;
    width: 50px;
    height: 50px;
    background: #E7EAED;
    border-radius: 100%; }
    :not(.login-page) .ecommerce_32 .slider_menu .slick-arrow:before {
      width: 100%;
      height: 100%;
      background-size: 7px auto;
      background-position: 50% 50%; }
    :not(.login-page) .ecommerce_32 .slider_menu .slick-arrow:hover {
      background-color: #cad1d7; }
      :not(.login-page) .ecommerce_32 .slider_menu .slick-arrow:hover:before {
        opacity: 1; }
  :not(.login-page) .ecommerce_32 .slider_menu img {
    cursor: pointer;
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_32 .slider_menu img:hover {
      opacity: 0.8; }
  :not(.login-page) .ecommerce_32 input[type="radio"]:checked + label:before {
    content: "\F00C";
    color: #FFFFFF;
    text-align: center; }
  :not(.login-page) .ecommerce_32 input[type="radio"] + label:before {
    border: none;
    width: 22px;
    height: 22px;
    line-height: 22px;
    color: #FFFFFF; }
  :not(.login-page) .ecommerce_32 input[type="radio"] + label {
    margin: 0 2px;
    width: 22px;
    height: 22px; }
  :not(.login-page) .ecommerce_32 .colors_border {
    border: 2px solid #E7EAED;
    padding: 0 12px; }
  :not(.login-page) .ecommerce_32 .quantity_selector {
    border: 2px solid #E7EAED;
    overflow: hidden; }
    :not(.login-page) .ecommerce_32 .quantity_selector .control {
      width: 36px;
      line-height: 36px;
      background: transparent; }
      :not(.login-page) .ecommerce_32 .quantity_selector .control:hover {
        background: #E7EAED; }
      :not(.login-page) .ecommerce_32 .quantity_selector .control::-moz-selection {
        background: transparent;
        color: #1e3060; }
      :not(.login-page) .ecommerce_32 .quantity_selector .control::-webkit-selection {
        background: transparent;
        color: #1e3060; }
      :not(.login-page) .ecommerce_32 .quantity_selector .control::selection {
        background: transparent;
        color: #1e3060; }
    :not(.login-page) .ecommerce_32 .quantity_selector .input {
      width: 40px;
      height: 36px;
      border-left: 2px solid #E7EAED;
      border-right: 2px solid #E7EAED;
      color: #1e3060;
      font-size: 16px;
      font-weight: 500;
      text-align: center; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_32 .inner {
      margin-left: 0; }
    :not(.login-page) .ecommerce_32 .top_form {
      margin-top: 10px; }
    :not(.login-page) .ecommerce_32 .buttons {
      margin-top: 30px; }
    :not(.login-page) .ecommerce_32 .bottom_form {
      margin-top: 30px; }
      :not(.login-page) .ecommerce_32 .bottom_form .text-adaptive {
        max-width: 370px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_32 .text-adaptive {
      margin-left: auto;
      margin-right: auto; } }
  @media (max-width: 369.98px) {
    :not(.login-page) .ecommerce_32 .quantity,
    :not(.login-page) .ecommerce_32 .colors,
    :not(.login-page) .ecommerce_32 .buttons a,
    :not(.login-page) .ecommerce_32 .buttons button {
      margin-left: 10px;
      margin-right: 10px; } }
  :not(.login-page) .ecommerce_33 .remove_product {
    width: 45px;
    height: 45px;
    top: 30px;
    right: 0;
    cursor: pointer;
    z-index: 10; }
    :not(.login-page) .ecommerce_33 .remove_product:hover svg path {
      stroke: #1e3060; }
    :not(.login-page) .ecommerce_33 .remove_product svg path {
      stroke: #1E3060;
      transition: all 0.25s; }
  :not(.login-page) .ecommerce_33 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_33 .img_link:hover img {
    opacity: 0.8; }
  :not(.login-page) .ecommerce_33 .colors_border {
    border: 2px solid #E7EAED;
    padding: 0 8px; }
    :not(.login-page) .ecommerce_33 .colors_border input[type="radio"]:checked + label:before {
      content: "\F00C";
      color: #FFFFFF;
      text-align: center; }
    :not(.login-page) .ecommerce_33 .colors_border input[type="radio"] + label:before {
      border: none;
      width: 22px;
      height: 22px;
      line-height: 22px;
      color: #FFFFFF; }
    :not(.login-page) .ecommerce_33 .colors_border input[type="radio"] + label {
      margin: 0 2px;
      width: 22px;
      height: 22px; }
  :not(.login-page) .ecommerce_33 .sizes input[type="radio"]:checked + label {
    background: #1e3060;
    color: #FFFFFF;
    border-color: #1e3060; }
  :not(.login-page) .ecommerce_33 .sizes input[type="radio"] + label:before {
    display: none; }
  :not(.login-page) .ecommerce_33 .sizes input[type="radio"] + label {
    width: auto;
    height: 40px;
    line-height: 36px; }
  :not(.login-page) .ecommerce_33 .sizes label {
    padding: 0 12px;
    border: 2px solid #E7EAED;
    border-right: none; }
    :not(.login-page) .ecommerce_33 .sizes label:first-of-type {
      border-radius: 20px 0 0 20px; }
    :not(.login-page) .ecommerce_33 .sizes label:last-of-type {
      border-radius: 0 20px 20px 0;
      border-right: 2px solid #E7EAED; }
    :not(.login-page) .ecommerce_33 .sizes label:hover {
      color: #1e3060; }
  :not(.login-page) .ecommerce_33 .quantity_selector {
    border: 2px solid #E7EAED;
    overflow: hidden; }
    :not(.login-page) .ecommerce_33 .quantity_selector .control {
      width: 36px;
      line-height: 36px;
      background: transparent; }
      :not(.login-page) .ecommerce_33 .quantity_selector .control:hover {
        background: #E7EAED; }
      :not(.login-page) .ecommerce_33 .quantity_selector .control::-moz-selection {
        background: transparent;
        color: #1e3060; }
      :not(.login-page) .ecommerce_33 .quantity_selector .control::-webkit-selection {
        background: transparent;
        color: #1e3060; }
      :not(.login-page) .ecommerce_33 .quantity_selector .control::selection {
        background: transparent;
        color: #1e3060; }
    :not(.login-page) .ecommerce_33 .quantity_selector .input {
      width: 40px;
      height: 36px;
      border-left: 2px solid #E7EAED;
      border-right: 2px solid #E7EAED;
      color: #1e3060;
      font-weight: 500;
      font-size: 16px;
      text-align: center; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_33 .product .inner {
      margin-left: 15px; }
    :not(.login-page) .ecommerce_33 .label_total {
      margin-left: 23px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_33 .label_total {
      margin-left: 0; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_33 .product_total {
      height: auto; } }
  @media (max-width: 481.98px) {
    :not(.login-page) .ecommerce_33 .product .inner {
      margin-top: 20px;
      margin-left: 0;
      max-width: 100%;
      width: 100%; } }
  :not(.login-page) .ecommerce_34 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_34 .img_link:hover img {
    opacity: 0.8; }
  :not(.login-page) .ecommerce_34 .quantity_selector {
    border: 2px solid #E7EAED;
    overflow: hidden; }
    :not(.login-page) .ecommerce_34 .quantity_selector .control {
      width: 36px;
      line-height: 36px;
      background: transparent; }
      :not(.login-page) .ecommerce_34 .quantity_selector .control:hover {
        background: #E7EAED; }
      :not(.login-page) .ecommerce_34 .quantity_selector .control::-moz-selection {
        background: transparent;
        color: #1e3060; }
      :not(.login-page) .ecommerce_34 .quantity_selector .control::-webkit-selection {
        background: transparent;
        color: #1e3060; }
      :not(.login-page) .ecommerce_34 .quantity_selector .control::selection {
        background: transparent;
        color: #1e3060; }
    :not(.login-page) .ecommerce_34 .quantity_selector .input {
      width: 40px;
      height: 36px;
      border-left: 2px solid #E7EAED;
      border-right: 2px solid #E7EAED;
      color: #1e3060;
      font-weight: 500;
      font-size: 16px;
      text-align: center; }
  :not(.login-page) .ecommerce_34 .total,
  :not(.login-page) .ecommerce_34 .subtotal,
  :not(.login-page) .ecommerce_34 .delivery {
    min-width: 115px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_34 .address_form {
      padding-right: 40px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_34 .address_form {
      padding-top: 20px;
      padding-right: 0px; }
      :not(.login-page) .ecommerce_34 .address_form .block {
        margin-bottom: 15px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_34 .total,
    :not(.login-page) .ecommerce_34 .subtotal,
    :not(.login-page) .ecommerce_34 .delivery {
      min-width: 50px; } }
  :not(.login-page) .ecommerce_35 .slider_menu .slick-track {
    position: relative;
    border-bottom: 3px solid #E7EAED; }
  :not(.login-page) .ecommerce_35 .slider_menu a {
    width: 50%; }
    :not(.login-page) .ecommerce_35 .slider_menu a:after {
      content: "";
      display: block;
      width: 0%;
      height: 3px;
      background: #1e3060;
      position: absolute;
      right: 0;
      bottom: -3px;
      transition: width 0.2s;
      z-index: 10000; }
    :not(.login-page) .ecommerce_35 .slider_menu a:last-child:after {
      left: 0;
      right: auto; }
    :not(.login-page) .ecommerce_35 .slider_menu a.slick-current {
      color: #1e3060; }
      :not(.login-page) .ecommerce_35 .slider_menu a.slick-current:after {
        width: 100%; }
  :not(.login-page) .ecommerce_35 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_35 .img_link:hover img {
    opacity: 0.8; }
  :not(.login-page) .ecommerce_35 .quantity_selector {
    border: 2px solid #E7EAED;
    overflow: hidden; }
    :not(.login-page) .ecommerce_35 .quantity_selector .control {
      width: 36px;
      line-height: 36px;
      background: transparent; }
      :not(.login-page) .ecommerce_35 .quantity_selector .control:hover {
        background: #E7EAED; }
      :not(.login-page) .ecommerce_35 .quantity_selector .control::-moz-selection {
        background: transparent;
        color: #1e3060; }
      :not(.login-page) .ecommerce_35 .quantity_selector .control::-webkit-selection {
        background: transparent;
        color: #1e3060; }
      :not(.login-page) .ecommerce_35 .quantity_selector .control::selection {
        background: transparent;
        color: #1e3060; }
    :not(.login-page) .ecommerce_35 .quantity_selector .input {
      width: 40px;
      height: 36px;
      border-left: 2px solid #E7EAED;
      border-right: 2px solid #E7EAED;
      color: #1e3060;
      font-weight: 500;
      font-size: 16px;
      text-align: center; }
  :not(.login-page) .ecommerce_35 .total {
    min-width: 115px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_35 .slider,
    :not(.login-page) .ecommerce_35 .slider_menu {
      padding-right: 40px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_35 .slider,
    :not(.login-page) .ecommerce_35 .slider_menu {
      padding-right: 0px; }
    :not(.login-page) .ecommerce_35 .products {
      padding-top: 0; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_35 .slider_menu a {
      line-height: 60px; }
    :not(.login-page) .ecommerce_35 .slider {
      margin-top: 40px; }
    :not(.login-page) .ecommerce_35 .total {
      min-width: 50px; } }
  :not(.login-page) .ecommerce_36 .with_border {
    border-bottom: 2px solid #E7EAED; }
  :not(.login-page) .ecommerce_36 .product .with_border {
    padding-top: 28px;
    padding-bottom: 28px; }
  :not(.login-page) .ecommerce_36 .quantity_selector .control {
    right: 20px;
    top: 50%;
    width: 16px;
    height: 12px;
    line-height: 8px;
    font-size: 12px; }
    :not(.login-page) .ecommerce_36 .quantity_selector .control.greater {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%); }
  :not(.login-page) .ecommerce_36 .remove_product {
    width: 45px;
    height: 45px;
    top: 50%;
    right: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    cursor: pointer;
    z-index: 10; }
    :not(.login-page) .ecommerce_36 .remove_product:hover svg path {
      stroke: #1e3060; }
    :not(.login-page) .ecommerce_36 .remove_product svg path {
      stroke: #1E3060;
      transition: all 0.25s; }
  :not(.login-page) .ecommerce_36 .total {
    min-width: 70px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_36 .total {
      min-width: 65px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_36 .total {
      min-width: 95px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_36 .product .title,
    :not(.login-page) .ecommerce_36 .product .variants {
      padding-top: 0px;
      padding-bottom: 0px; }
    :not(.login-page) .ecommerce_36 .with_border {
      border: none; }
    :not(.login-page) .ecommerce_36 .price_holder {
      position: static; }
    :not(.login-page) .ecommerce_36 .remove_product {
      top: 0;
      -webkit-transform: none;
              transform: none; }
    :not(.login-page) .ecommerce_36 .total {
      min-width: 0px; } }
  :not(.login-page) .ecommerce_37 .holder {
    border: 2px solid #E7EAED; }
  :not(.login-page) .ecommerce_37 .delivery_type input[type="radio"]:checked + label {
    background: #1e3060;
    color: #FFFFFF;
    border-color: #1e3060; }
  :not(.login-page) .ecommerce_37 .delivery_type input[type="radio"] + label:before {
    display: none; }
  :not(.login-page) .ecommerce_37 .delivery_type input[type="radio"] + label {
    line-height: 46px; }
  :not(.login-page) .ecommerce_37 .delivery_type label {
    height: 50px;
    border: 2px solid #E7EAED;
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_37 .delivery_type label:hover {
      color: #1e3060; }
  @media (max-width: 399.98px) {
    :not(.login-page) .ecommerce_37 .holder {
      padding: 30px 20px; } }
  :not(.login-page) .ecommerce_38 .product {
    border-bottom: 2px solid #E7EAED; }
  :not(.login-page) .ecommerce_38 .remove_product {
    width: 45px;
    height: 45px;
    top: 8px;
    right: -45px;
    cursor: pointer;
    z-index: 10; }
    :not(.login-page) .ecommerce_38 .remove_product:hover svg path {
      stroke: #1e3060; }
    :not(.login-page) .ecommerce_38 .remove_product svg path {
      stroke: #1E3060;
      transition: all 0.25s; }
  :not(.login-page) .ecommerce_38 .total,
  :not(.login-page) .ecommerce_38 .subtotal,
  :not(.login-page) .ecommerce_38 .delivery {
    min-width: 30px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_38 .remove_product {
      right: -15px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_38 .btn {
      margin-top: 0px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_38 .total,
    :not(.login-page) .ecommerce_38 .subtotal,
    :not(.login-page) .ecommerce_38 .delivery {
      min-width: 50px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .application_2 .application_2_left_img {
      max-height: 50vh; } }
  :not(.login-page) .application_2 .with_borders:nth-of-type(1) {
    border-bottom: 2px solid #ebeaed;
    border-right: 2px solid #ebeaed; }
  :not(.login-page) .application_2 .with_borders:nth-of-type(2) {
    border-bottom: 2px solid #ebeaed;
    border-right: 2px solid #ebeaed; }
  :not(.login-page) .application_2 .with_borders:nth-of-type(3) {
    border-bottom: 2px solid #ebeaed; }
  :not(.login-page) .application_2 .with_borders:nth-of-type(4) {
    border-right: 2px solid #ebeaed; }
  :not(.login-page) .application_2 .with_borders:nth-of-type(5) {
    border-right: 2px solid #ebeaed; }
  @media (max-width: 991.98px) {
    :not(.login-page) .application_2 .with_borders:nth-of-type(2) {
      border-right: none; }
    :not(.login-page) .application_2 .with_borders:nth-of-type(3) {
      border-right: 2px solid #ebeaed;
      padding-top: 35px; }
    :not(.login-page) .application_2 .with_borders:nth-of-type(4) {
      border-right: none;
      border-bottom: 2px solid #ebeaed; } }
  :not(.login-page) .application_3 .bg {
    top: 0;
    right: -50px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .application_3 .inner {
      padding-top: 350px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .application_3 .inner {
      padding-top: 300px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .application_3 .inner {
      padding-top: 200px; } }
  @media (max-width: 576.98px) {
    :not(.login-page) .application_3 .inner {
      padding-top: 100px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .application_4 .application_4_left_img {
      max-height: 80vh; } }
  :not(.login-page) .application_5 .with_box_shadow {
    box-shadow: -50px 30px 70px rgba(13, 0, 0, 0.11); }
    @media (max-width: 767.98px) {
      :not(.login-page) .application_5 .with_box_shadow {
        display: none; } }
  :not(.login-page) .application_5 .left .absolute {
    bottom: 120px;
    left: -120px; }
    @media (max-width: 1199.98px) {
      :not(.login-page) .application_5 .left .absolute {
        bottom: 50px; } }
    @media (max-width: 991.98px) {
      :not(.login-page) .application_5 .left .absolute {
        bottom: 120px;
        left: -110px; } }
  :not(.login-page) .application_5 .right .absolute {
    top: 30px;
    left: -175px; }
  @media (max-width: 991.98px) {
    :not(.login-page) .application_5 h3 {
      margin-bottom: 40px; } }
  :not(.login-page) .application_11 ul {
    list-style-type: none; }
    :not(.login-page) .application_11 ul li .fas {
      font-size: 10px;
      color: #0074f1;
      padding-right: 15px; }
  @media (max-width: 767.98px) {
    :not(.login-page) .application_13 img {
      max-height: 80vh; } }
  :not(.login-page) .application_14 .bg {
    top: 0;
    left: 30px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .application_14 .application_14_inner {
      padding-bottom: 200px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .application_14 .application_14_inner {
      padding-bottom: 180px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .application_14 .application_14_inner {
      padding-bottom: 100px; } }
  @media (max-width: 576.98px) {
    :not(.login-page) .application_14 .application_14_inner {
      padding-bottom: 50px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .application_15 img {
      max-height: 80vh; } }
  :not(.login-page) .application_16 .with_border_radius_10 {
    border: 2px solid #E7EAED;
    border-radius: 10px; }
  :not(.login-page) .application_16 .border_on_right {
    border-right: 2px solid #E7EAED; }
  :not(.login-page) .application_16 .border_on_top {
    border-top: 2px solid #E7EAED; }
  :not(.login-page) .application_16 .bg {
    top: -377px;
    left: -170px; }
  @media (max-width: 991.98px) {
    :not(.login-page) .application_16 .bg {
      left: -100px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .application_16 .bg {
      display: none; } }
  :not(.login-page) .application_17 .bg {
    top: -376px;
    left: -30px; }
  @media (max-width: 991.98px) {
    :not(.login-page) .application_17 .bg {
      left: 0; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .application_17 .bg {
      display: none; } }
  :not(.login-page) .application_20 #application_20_bg {
    background-position: 50% 50%;
    background-size: contain; }
  :not(.login-page) .application_22 #application_22_bg {
    background-position: 50% 50%;
    background-size: contain; }
  :not(.login-page) .application_23 .color-dependent:nth-of-type(even) .radius_full {
    background: #0074f1; }
  :not(.login-page) .application_23 .color-dependent:nth-of-type(odd) .radius_full {
    background: #0074f1; }
  :not(.login-page) .application_23 i {
    color: #000000; }
  :not(.login-page) .application_23 .bg {
    position: absolute;
    top: -230px;
    left: -340px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .application_23 .pb-dependent {
      padding-bottom: 150px; }
    :not(.login-page) .application_23 .bg {
      top: -250px;
      left: -300px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .application_23 .pb-dependent {
      padding-bottom: 100px; }
    :not(.login-page) .application_23 .bg {
      top: -320px;
      left: -250px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .application_23 .pb-dependent {
      padding-bottom: 40px; }
    :not(.login-page) .application_23 .bg {
      top: -530px;
      left: -180px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .application_23 .bg {
      display: none; } }
  :not(.login-page) .application_24 ul {
    list-style: none;
    padding-left: 0; }
    :not(.login-page) .application_24 ul li {
      display: flex;
      justify-content: flex-start;
      align-items: align-start; }
      :not(.login-page) .application_24 ul li:before {
        content: "\25CF";
        color: #1e3060;
        margin-right: 12px; }
  @media (max-width: 767.98px) {
    :not(.login-page) .application_25 .text-nowrap {
      white-space: normal !important; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .application_26 img {
      max-height: 80vh; } }

