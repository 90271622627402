$base_font_size: 16px;
$base_line_height: 26px;
$font_heading: Nunito Sans;
$font_main: Nunito Sans;
$font_heading_weight: 700;
$font_main_weight: 400;
$retina: "(-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)";

$thin:100;
$extralight:200;
$light:300;
$normal:400;
$medium:500;
$semibold:600;
$bold:700;
$extrabold:800;
$heavy:900;

$action_1: #0074f1;
$action_2: #0074f1;
$action_3: #7121F4;
$color_main: #1e3060;
$color_heading: #1E3060;
$color_white: #FFFFFF;
$color_gray: #E7EAED;
$bg_dark: #35383D;
$bg_light: #FFFFFF;

$color_red: #EA3223;
$color_red_hover: #e10000;
$color_green: #18c967;
$color_green_hover: #0cb658;
$color_facebook: #2e4da7;
$color_twitter: #0aa0f5;
$color_dribbble: #E93A7D;
