@charset "UTF-8";
:not(.login-page) {
  /*
	Table of contents:
	1. Notifications
	2. Modals
*/
  /* 1. Notifications */
  /* 1. Modals */
  /* Change bootstrap's .container default width */
  /* Font styles */
  /* Opacity */
  /* z-index */
  /* Links */
  /* Buttons */
  /* inputs */
  /* Selects */
  /* Textareas */
  /* Checkboxes */
  /* Other elements */
  /* Navigation Mobile */
  /* Video block */
  /* Play button */
  /* Opening tabs */
  /* Alerts */
  /* Google ReCaptcha */
  /* Dropdown menu */
  /* Dropdown popup in Navigation */
  /* Slider stylers */
  /* Change some Bootstrap's styles */
  /* Classes for all elements */
  /* Margins and paddings */
  /* width, max-width, height, max-height */
  /* Rewrite some bootstrap's classes */
  /* Font families */
  /* Font sizes */
  /* Colors */
  /* background-colors */
  /* color filters */
  /*
picture[class^="color-filter-"], picture[class*=" color-filter-"]{
display:inline-block;
overflow:hidden;
&:before{
  z-index:1;
}
}
*/
  /* Border-radiuses */
  /* Position */
  /*
	Table of contents:
	1. Common styles
	2. Common responsive styles
	3. Headers
	4. Navigations
	5. Contents
	6. Testimonials
	7. Features
	8. Forms
	9. Call to Actions
	10. Teams
	11. Pricing Tables
	12. Contacts
	13. Showcases
	14. Footers
	15. Ecommerce
	16. Applications
*/
  /* 1. Common styles */
  /* Change AOS plugin default settings */
  /* Slick slider */
  /* Arrows */
  /* Dots */
  /* Slick slider Dark arrows */
  /* 2. Common responsive styles */
  /* Smaller than 1200px */
  /* Smaller than 992px */
  /* Smaller than 768px */
  /* Smaller than 576px */
  /* 3. Headers */
  /* 4. Navigations */
  /* 5. Contents */
  /* 6. Testimonials */
  /* 7. Features */
  /* 8. Forms */
  /* 9. Call to Actions */
  /* 10. Teams */
  /* 11. Pricing Tables */
  /* 12. Contacts */
  /* 13. Showcases */
  /* 14. Footers */
  /* 15. Ecommerce */
  /* 16. Applications */ }
  :not(.login-page) .ant-message {
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    position: fixed;
    z-index: 1010;
    width: 100%;
    top: 16px;
    left: 0;
    pointer-events: none; }
    :not(.login-page) .ant-message-notice {
      padding: 8px;
      text-align: center; }
      :not(.login-page) .ant-message-notice:first-child {
        margin-top: -8px; }
      :not(.login-page) .ant-message-notice-content {
        padding: 10px 16px;
        border-radius: 4px;
        -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
        background: #fff;
        display: inline-block;
        pointer-events: all; }
    :not(.login-page) .ant-message-success .anticon {
      color: #52c41a; }
    :not(.login-page) .ant-message-warning .anticon {
      color: #faad14; }
    :not(.login-page) .ant-message-error .anticon {
      color: #f5222d; }
    :not(.login-page) .ant-message-info .anticon, :not(.login-page) .ant-message-loading .anticon {
      color: #1890ff; }
    :not(.login-page) .ant-message .anticon {
      margin-right: 8px;
      font-size: 16px;
      top: 1px;
      position: relative; }
    :not(.login-page) .ant-message-notice.move-up-leave.move-up-leave-active {
      -webkit-animation-name: MessageMoveOut;
      animation-name: MessageMoveOut;
      overflow: hidden;
      -webkit-animation-duration: .3s;
      animation-duration: .3s; }

@keyframes MessageMoveOut {
  0% {
    opacity: 1;
    max-height: 150px;
    padding: 8px; }
  to {
    opacity: 0;
    max-height: 0;
    padding: 0; } }
  :not(.login-page) .ant-modal {
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    list-style: none;
    position: relative;
    width: auto;
    margin: 0 auto;
    top: 100px;
    padding: 0 0 24px; }
    :not(.login-page) .ant-modal-wrap {
      position: fixed;
      overflow: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1000;
      -webkit-overflow-scrolling: touch;
      outline: 0; }
    :not(.login-page) .ant-modal-title {
      margin: 0;
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85); }
    :not(.login-page) .ant-modal-content {
      position: relative;
      background-color: #fff;
      border: 0;
      border-radius: 4px;
      background-clip: padding-box;
      -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); }
    :not(.login-page) .ant-modal-close {
      cursor: pointer;
      border: 0;
      background: transparent;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 10;
      font-weight: 700;
      line-height: 1;
      text-decoration: none;
      -webkit-transition: color .3s;
      transition: color .3s;
      color: rgba(0, 0, 0, 0.45);
      outline: 0;
      padding: 0; }
      :not(.login-page) .ant-modal-close:focus, :not(.login-page) .ant-modal-close:hover {
        color: rgba(0, 0, 0, 0.75);
        text-decoration: none; }
    :not(.login-page) .ant-modal-close-x {
      display: block;
      font-style: normal;
      vertical-align: baseline;
      text-align: center;
      text-transform: none;
      text-rendering: auto;
      width: 56px;
      height: 56px;
      line-height: 56px;
      font-size: 16px; }
    :not(.login-page) .ant-modal-header {
      padding: 16px 24px;
      border-radius: 4px 4px 0 0;
      background: #fff;
      color: rgba(0, 0, 0, 0.65);
      border-bottom: 1px solid #e8e8e8; }
    :not(.login-page) .ant-modal-body {
      padding: 24px;
      font-size: 14px;
      line-height: 1.5;
      word-wrap: break-word; }
    :not(.login-page) .ant-modal-footer {
      border-top: 1px solid #e8e8e8;
      padding: 10px 16px;
      text-align: right;
      border-radius: 0 0 4px 4px; }
      :not(.login-page) .ant-modal-footer button + button {
        margin-left: 8px;
        margin-bottom: 0; }
    :not(.login-page) .ant-modal.zoom-appear, :not(.login-page) .ant-modal.zoom-enter {
      -webkit-animation-duration: .3s;
      animation-duration: .3s;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
      opacity: 0;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
    :not(.login-page) .ant-modal-mask {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.65);
      height: 100%;
      z-index: 1000;
      filter: alpha(opacity=50); }
      :not(.login-page) .ant-modal-mask-hidden {
        display: none; }
    :not(.login-page) .ant-modal-open {
      overflow: hidden; }
    :not(.login-page) .ant-modal-centered {
      text-align: center; }
      :not(.login-page) .ant-modal-centered:before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        width: 0; }
      :not(.login-page) .ant-modal-centered .ant-modal {
        display: inline-block;
        vertical-align: middle;
        top: 0;
        text-align: left; }
  @media (max-width: 767px) {
    :not(.login-page) .ant-modal {
      max-width: calc(100vw - 16px);
      margin: 8px auto; }
      :not(.login-page) .ant-modal-centered .ant-modal {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1; } }
  @media (min-width: 768px) {
    :not(.login-page) .container {
      max-width: 750px; } }
  @media (min-width: 992px) {
    :not(.login-page) .container {
      max-width: 970px; } }
  @media (min-width: 1200px) {
    :not(.login-page) .container {
      max-width: 1170px; } }
  :not(.login-page) .underline {
    text-decoration: underline; }
  :not(.login-page) .thin {
    font-weight: 100; }
  :not(.login-page) .extralight {
    font-weight: 200; }
  :not(.login-page) .light {
    font-weight: 300; }
  :not(.login-page) .regular {
    font-weight: 400; }
  :not(.login-page) .medium {
    font-weight: 500; }
  :not(.login-page) .semibold {
    font-weight: 600; }
  :not(.login-page) .bold {
    font-weight: 700; }
  :not(.login-page) .extrabold {
    font-weight: 800; }
  :not(.login-page) .heavy {
    font-weight: 900; }
  :not(.login-page) .sp-10 {
    letter-spacing: 1px; }
  :not(.login-page) .sp-15 {
    letter-spacing: 1.5px; }
  :not(.login-page) .sp-20 {
    letter-spacing: 2px; }
  :not(.login-page) .sp-25 {
    letter-spacing: 2.5px; }
  :not(.login-page) .op-0 {
    opacity: 0; }
  :not(.login-page) .op-1 {
    opacity: 0.1; }
  :not(.login-page) .op-2 {
    opacity: 0.2; }
  :not(.login-page) .op-3 {
    opacity: 0.3; }
  :not(.login-page) .op-4 {
    opacity: 0.4; }
  :not(.login-page) .op-5 {
    opacity: 0.5; }
  :not(.login-page) .op-6 {
    opacity: 0.6; }
  :not(.login-page) .op-7 {
    opacity: 0.7; }
  :not(.login-page) .op-8 {
    opacity: 0.8; }
  :not(.login-page) .op-9 {
    opacity: 0.9; }
  :not(.login-page) .op-10 {
    opacity: 1; }
  :not(.login-page) .zi-0 {
    z-index: 0; }
  :not(.login-page) .zi-1 {
    z-index: 1; }
  :not(.login-page) .zi-2 {
    z-index: 2; }
  :not(.login-page) .zi-3 {
    z-index: 3; }
  :not(.login-page) .zi-4 {
    z-index: 4; }
  :not(.login-page) .zi-5 {
    z-index: 5; }
  :not(.login-page) .link {
    display: inline-block; }
    :not(.login-page) .link.underline:hover, :not(.login-page) .link.underline:focus {
      text-decoration: underline; }
  :not(.login-page) .btn {
    vertical-align: top;
    height: 50px;
    line-height: 50px;
    padding: 0px 30px;
    border: none;
    border-radius: 50px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    background: transparent;
    transition: all 0.25s;
    /* sizes */
    /* z-index */
    /* Borders */ }
    :not(.login-page) .btn.active, :not(.login-page) .btn:active {
      box-shadow: none; }
    :not(.login-page) .btn:active, :not(.login-page) .btn:focus {
      box-shadow: none; }
    :not(.login-page) .btn[class^="border-"], :not(.login-page) .btn[class*=" border-"] {
      line-height: 46px; }
    :not(.login-page) .btn.sm {
      height: 40px;
      line-height: 40px;
      padding: 0 15px; }
      :not(.login-page) .btn.sm[class^="border-"], :not(.login-page) .btn.sm[class*=" border-"] {
        line-height: 36px; }
    :not(.login-page) .btn.md {
      /* regular size */ }
    :not(.login-page) .btn.lg {
      height: 60px;
      line-height: 60px;
      padding: 0 40px;
      font-size: 20px;
      font-weight: 600; }
      :not(.login-page) .btn.lg[class^="border-"], :not(.login-page) .btn.lg[class*=" border-"] {
        line-height: 56px; }
    :not(.login-page) .btn.xl {
      height: 70px;
      line-height: 70px;
      padding: 0 45px;
      font-size: 20px;
      font-weight: 600; }
      :not(.login-page) .btn.xl[class^="border-"], :not(.login-page) .btn.xl[class*=" border-"] {
        line-height: 66px; }
    :not(.login-page) .btn .zi-0 {
      z-index: 0; }
    :not(.login-page) .btn.action-2 {
      background-color: #0074f1;
      color: #FFFFFF; }
      :not(.login-page) .btn.action-2:hover {
        background-color: #005bbe; }
    :not(.login-page) .btn.action-3 {
      background-color: #7121F4;
      color: #FFFFFF; }
      :not(.login-page) .btn.action-3:hover {
        background-color: #580bd7; }
    :not(.login-page) .btn.action-white {
      background-color: #FFFFFF;
      color: #0074f1; }
      :not(.login-page) .btn.action-white:hover {
        background-color: #e6e6e6; }
    :not(.login-page) .btn.action-red {
      background-color: #EA3223;
      color: #FFFFFF; }
      :not(.login-page) .btn.action-red:hover {
        background-color: #c72113; }
    :not(.login-page) .btn.action-facebook {
      background-color: #2e4da7;
      color: #FFFFFF; }
      :not(.login-page) .btn.action-facebook:hover {
        background-color: #3e62ca; }
    :not(.login-page) .btn.action-twitter {
      background-color: #0aa0f5;
      color: #FFFFFF; }
      :not(.login-page) .btn.action-twitter:hover {
        background-color: #0880c4; }
    :not(.login-page) .btn.action-dribbble {
      background-color: #E93A7D;
      color: #FFFFFF; }
      :not(.login-page) .btn.action-dribbble:hover {
        background-color: #d81862; }
    :not(.login-page) .btn.border-main {
      border: 2px solid #1e3060; }
      :not(.login-page) .btn.border-main:hover {
        border-color: #3657ae; }
    :not(.login-page) .btn.border-transparent-main {
      color: #1e3060;
      border: 2px solid rgba(30, 48, 96, 0.3); }
      :not(.login-page) .btn.border-transparent-main:hover {
        border-color: rgba(30, 48, 96, 0.7); }
    :not(.login-page) .btn.border-heading {
      border: 2px solid #1E3060; }
      :not(.login-page) .btn.border-heading:hover {
        border-color: #060912; }
    :not(.login-page) .btn.border-white {
      border: 2px solid #FFFFFF; }
      :not(.login-page) .btn.border-white:hover {
        border-color: #cccccc; }
    :not(.login-page) .btn.border-transparent-white {
      border: 2px solid rgba(255, 255, 255, 0.3); }
      :not(.login-page) .btn.border-transparent-white:hover {
        border-color: rgba(255, 255, 255, 0.7);
        color: #FFFFFF; }
    :not(.login-page) .btn.border-gray {
      border: 2px solid #E7EAED; }
      :not(.login-page) .btn.border-gray:hover {
        border-color: #adb7c1; }
    :not(.login-page) .btn.border-light {
      border: 2px solid #FFFFFF; }
      :not(.login-page) .btn.border-light:hover {
        border-color: #cccccc; }
    :not(.login-page) .btn.border-dark {
      border: 2px solid #35383D; }
      :not(.login-page) .btn.border-dark:hover {
        border-color: #646a74; }
    :not(.login-page) .btn.border-action-1 {
      border: 2px solid #0074f1;
      color: #0074f1; }
      :not(.login-page) .btn.border-action-1:hover {
        border-color: #00438b;
        color: #00438b; }
    :not(.login-page) .btn.border-action-2 {
      border: 2px solid #0074f1;
      color: #0074f1; }
      :not(.login-page) .btn.border-action-2:hover {
        border-color: #00438b;
        color: #00438b; }
    :not(.login-page) .btn.border-action-3 {
      border: 2px solid #7121F4;
      color: #7121F4; }
      :not(.login-page) .btn.border-action-3:hover {
        border-color: #4408a7;
        color: #4408a7; }
  :not(.login-page) .input {
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    padding: 0 25px;
    height: 50px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 30px;
    transition: border-color 0.3s;
    /* Sizes */
    /* Borders */
    /* styles on focus */
    /* placeholders */ }
    :not(.login-page) .input:focus::-moz-placeholder {
      color: transparent !important; }
    :not(.login-page) .input:focus::-webkit-input-placeholder {
      color: transparent !important; }
    :not(.login-page) .input:focus:-ms-input-placeholder {
      color: transparent !important; }
    :not(.login-page) .input.sm {
      font-size: 16px;
      height: 40px;
      padding-left: 20px;
      padding-right: 20px;
      font-weight: 400; }
    :not(.login-page) .input.lg {
      font-size: 20px;
      height: 60px;
      padding-left: 25px;
      padding-right: 25px;
      font-weight: 600; }
    :not(.login-page) .input.border-main {
      border: 2px solid #1e3060; }
    :not(.login-page) .input.border-heading {
      border: 2px solid #1E3060; }
    :not(.login-page) .input.border-white {
      border: 2px solid #FFFFFF; }
    :not(.login-page) .input.border-transparent-white {
      border: 2px solid rgba(255, 255, 255, 0.4); }
    :not(.login-page) .input.border-gray {
      border: 2px solid #E7EAED; }
    :not(.login-page) .input.border-light {
      border: 2px solid #FFFFFF; }
    :not(.login-page) .input.border-dark {
      border: 2px solid #35383D; }
    :not(.login-page) .input.border-action-1 {
      border: 2px solid #0074f1; }
    :not(.login-page) .input.border-action-2 {
      border: 2px solid #0074f1; }
    :not(.login-page) .input.border-action-3 {
      border: 2px solid #7121F4; }
    :not(.login-page) .input.focus-main:focus {
      border-color: #1e3060; }
    :not(.login-page) .input.focus-heading:focus {
      border-color: #1E3060; }
    :not(.login-page) .input.focus-white:focus {
      border-color: #FFFFFF; }
    :not(.login-page) .input.focus-gray:focus {
      border-color: #E7EAED; }
    :not(.login-page) .input.focus-light:focus {
      border-color: #FFFFFF; }
    :not(.login-page) .input.focus-dark:focus {
      border-color: #35383D; }
    :not(.login-page) .input.focus-action-1:focus {
      border-color: #0074f1; }
    :not(.login-page) .input.focus-action-2:focus {
      border-color: #0074f1; }
    :not(.login-page) .input.focus-action-3:focus {
      border-color: #7121F4; }
    :not(.login-page) .input.placeholder-white::-moz-placeholder {
      color: #FFFFFF; }
    :not(.login-page) .input.placeholder-white::-webkit-input-placeholder {
      color: #FFFFFF; }
    :not(.login-page) .input.placeholder-white:-ms-input-placeholder {
      color: #FFFFFF; }
    :not(.login-page) .input.placeholder-transparent-white::-moz-placeholder {
      color: rgba(255, 255, 255, 0.4); }
    :not(.login-page) .input.placeholder-transparent-white::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.4); }
    :not(.login-page) .input.placeholder-transparent-white:-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.4); }
    :not(.login-page) .input.placeholder-main::-moz-placeholder {
      color: #1e3060; }
    :not(.login-page) .input.placeholder-main::-webkit-input-placeholder {
      color: #1e3060; }
    :not(.login-page) .input.placeholder-main:-ms-input-placeholder {
      color: #1e3060; }
    :not(.login-page) .input.placeholder-heading::-moz-placeholder {
      color: #1E3060; }
    :not(.login-page) .input.placeholder-heading::-webkit-input-placeholder {
      color: #1E3060; }
    :not(.login-page) .input.placeholder-heading:-ms-input-placeholder {
      color: #1E3060; }
    :not(.login-page) .input.placeholder-gray::-moz-placeholder {
      color: #E7EAED; }
    :not(.login-page) .input.placeholder-gray::-webkit-input-placeholder {
      color: #E7EAED; }
    :not(.login-page) .input.placeholder-gray:-ms-input-placeholder {
      color: #E7EAED; }
    :not(.login-page) .input.placeholder-action-1::-moz-placeholder {
      color: #0074f1; }
    :not(.login-page) .input.placeholder-action-1::-webkit-input-placeholder {
      color: #0074f1; }
    :not(.login-page) .input.placeholder-action-1:-ms-input-placeholder {
      color: #0074f1; }
    :not(.login-page) .input.placeholder-action-2::-moz-placeholder {
      color: #0074f1; }
    :not(.login-page) .input.placeholder-action-2::-webkit-input-placeholder {
      color: #0074f1; }
    :not(.login-page) .input.placeholder-action-2:-ms-input-placeholder {
      color: #0074f1; }
    :not(.login-page) .input.placeholder-action-3::-moz-placeholder {
      color: #7121F4; }
    :not(.login-page) .input.placeholder-action-3::-webkit-input-placeholder {
      color: #7121F4; }
    :not(.login-page) .input.placeholder-action-3:-ms-input-placeholder {
      color: #7121F4; }
  :not(.login-page) select {
    appearance: none;
    text-indent: 0.01px;
    text-overflow: "";
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 90% 50%;
    background-position: calc(100% - 20px) 50%;
    background-size: 11px 6px;
    border: 0;
    outline: none;
    color: #1e3060;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-indent: 1px;
    text-overflow: ""; }
    :not(.login-page) select::-ms-expand {
      display: none; }
    :not(.login-page) select.color-white {
      color: #FFFFFF; }
      :not(.login-page) select.color-white option {
        color: #1e3060;
        background: #fff; }
  :not(.login-page) textarea.input {
    padding-top: 15px;
    padding-bottom: 15px; }
  :not(.login-page) input[type="checkbox"],
  :not(.login-page) input[type="radio"] {
    /* Borders */
    /* Backgrounds */
    /* styles on focus */ }
    :not(.login-page) input[type="checkbox"] + label,
    :not(.login-page) input[type="radio"] + label {
      line-height: 24px; }
      :not(.login-page) input[type="checkbox"] + label:before,
      :not(.login-page) input[type="radio"] + label:before {
        display: inline-block;
        vertical-align: top;
        content: "";
        text-align: center;
        width: 24px;
        height: 24px;
        line-height: 20px;
        border-width: 2px;
        border-style: solid;
        margin-right: 10px;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 12px;
        transition: all 0.2s; }
    :not(.login-page) input[type="checkbox"].border-main + label:before,
    :not(.login-page) input[type="radio"].border-main + label:before {
      border: 2px solid #1e3060; }
    :not(.login-page) input[type="checkbox"].border-heading + label:before,
    :not(.login-page) input[type="radio"].border-heading + label:before {
      border: 2px solid #1E3060; }
    :not(.login-page) input[type="checkbox"].border-white + label:before,
    :not(.login-page) input[type="radio"].border-white + label:before {
      border: 2px solid #FFFFFF; }
    :not(.login-page) input[type="checkbox"].border-transparent-white + label:before,
    :not(.login-page) input[type="radio"].border-transparent-white + label:before {
      border: 2px solid rgba(255, 255, 255, 0.4); }
    :not(.login-page) input[type="checkbox"].border-gray + label:before,
    :not(.login-page) input[type="radio"].border-gray + label:before {
      border: 2px solid #E7EAED; }
    :not(.login-page) input[type="checkbox"].border-light + label:before,
    :not(.login-page) input[type="radio"].border-light + label:before {
      border: 2px solid #FFFFFF; }
    :not(.login-page) input[type="checkbox"].border-dark + label:before,
    :not(.login-page) input[type="radio"].border-dark + label:before {
      border: 2px solid #35383D; }
    :not(.login-page) input[type="checkbox"].border-action-1 + label:before,
    :not(.login-page) input[type="radio"].border-action-1 + label:before {
      border: 2px solid #0074f1; }
    :not(.login-page) input[type="checkbox"].border-action-2 + label:before,
    :not(.login-page) input[type="radio"].border-action-2 + label:before {
      border: 2px solid #0074f1; }
    :not(.login-page) input[type="checkbox"].border-action-3 + label:before,
    :not(.login-page) input[type="radio"].border-action-3 + label:before {
      border: 2px solid #7121F4; }
    :not(.login-page) input[type="checkbox"].bg-main + label:before,
    :not(.login-page) input[type="radio"].bg-main + label:before {
      background-color: #1e3060; }
    :not(.login-page) input[type="checkbox"].bg-heading + label:before,
    :not(.login-page) input[type="radio"].bg-heading + label:before {
      background-color: #1E3060; }
    :not(.login-page) input[type="checkbox"].bg-white + label:before,
    :not(.login-page) input[type="radio"].bg-white + label:before {
      background-color: #FFFFFF; }
    :not(.login-page) input[type="checkbox"].bg-transparent-white + label:before,
    :not(.login-page) input[type="radio"].bg-transparent-white + label:before {
      background-color: rgba(255, 255, 255, 0.4); }
    :not(.login-page) input[type="checkbox"].bg-gray + label:before,
    :not(.login-page) input[type="radio"].bg-gray + label:before {
      background-color: #E7EAED; }
    :not(.login-page) input[type="checkbox"].bg-light + label:before,
    :not(.login-page) input[type="radio"].bg-light + label:before {
      background-color: #FFFFFF; }
    :not(.login-page) input[type="checkbox"].bg-dark + label:before,
    :not(.login-page) input[type="radio"].bg-dark + label:before {
      background-color: #35383D; }
    :not(.login-page) input[type="checkbox"].bg-action-1 + label:before,
    :not(.login-page) input[type="radio"].bg-action-1 + label:before {
      background-color: #0074f1; }
    :not(.login-page) input[type="checkbox"].bg-action-2 + label:before,
    :not(.login-page) input[type="radio"].bg-action-2 + label:before {
      background-color: #0074f1; }
    :not(.login-page) input[type="checkbox"].bg-action-3 + label:before,
    :not(.login-page) input[type="radio"].bg-action-3 + label:before {
      background-color: #7121F4; }
    :not(.login-page) input[type="checkbox"].focus-main:checked + label:before,
    :not(.login-page) input[type="radio"].focus-main:checked + label:before {
      border-color: #1e3060;
      background-color: #1e3060;
      color: #FFFFFF; }
    :not(.login-page) input[type="checkbox"].focus-heading:checked + label:before,
    :not(.login-page) input[type="radio"].focus-heading:checked + label:before {
      border-color: #1E3060;
      background-color: #1E3060; }
    :not(.login-page) input[type="checkbox"].focus-white:checked + label:before,
    :not(.login-page) input[type="radio"].focus-white:checked + label:before {
      border-color: #FFFFFF;
      background-color: #FFFFFF;
      color: #1e3060; }
    :not(.login-page) input[type="checkbox"].focus-gray:checked + label:before,
    :not(.login-page) input[type="radio"].focus-gray:checked + label:before {
      border-color: #E7EAED;
      background-color: #E7EAED; }
    :not(.login-page) input[type="checkbox"].focus-light:checked + label:before,
    :not(.login-page) input[type="radio"].focus-light:checked + label:before {
      border-color: #FFFFFF;
      background-color: #FFFFFF; }
    :not(.login-page) input[type="checkbox"].focus-dark:checked + label:before,
    :not(.login-page) input[type="radio"].focus-dark:checked + label:before {
      border-color: #35383D;
      background-color: #35383D;
      color: #FFFFFF; }
    :not(.login-page) input[type="checkbox"].focus-action-1:checked + label:before,
    :not(.login-page) input[type="radio"].focus-action-1:checked + label:before {
      border-color: #0074f1;
      background-color: #0074f1;
      color: #1e3060; }
    :not(.login-page) input[type="checkbox"].focus-action-2:checked + label:before,
    :not(.login-page) input[type="radio"].focus-action-2:checked + label:before {
      border-color: #0074f1;
      background-color: #0074f1;
      color: #FFFFFF; }
    :not(.login-page) input[type="checkbox"].focus-action-3:checked + label:before,
    :not(.login-page) input[type="radio"].focus-action-3:checked + label:before {
      border-color: #7121F4;
      background-color: #7121F4;
      color: #FFFFFF; }
  :not(.login-page) input[type="checkbox"]:checked + label:before {
    content: "\f00c"; }
  :not(.login-page) input[type="checkbox"] + label:before {
    border-radius: 6px; }
  :not(.login-page) input[type="radio"]:checked + label:before {
    content: "\f111"; }
  :not(.login-page) input[type="radio"] + label:before {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    line-height: 16px; }
  :not(.login-page) .logo {
    font-family: Nunito Sans;
    font-size: 24px;
    color: #1e3060;
    font-weight: 700; }
    :not(.login-page) .logo.color-white {
      color: #FFFFFF; }
  :not(.login-page) .navigation_mobile {
    position: fixed;
    top: 0px;
    height: 100%;
    width: 320px;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1000;
    transform: translateX(-320px);
    transition: transform 0.4s ease; }
    :not(.login-page) .navigation_mobile .inner {
      display: flex;
      flex-direction: column; }
    :not(.login-page) .navigation_mobile.opened {
      transform: translateX(0); }
      :not(.login-page) .navigation_mobile.opened .close_menu {
        display: block; }
  :not(.login-page) .open_menu,
  :not(.login-page) .close_menu {
    display: none;
    top: 20px;
    width: 40px;
    height: 40px;
    color: #1e3060;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    z-index: 990; }
    :not(.login-page) .open_menu.color-white,
    :not(.login-page) .close_menu.color-white {
      color: #FFFFFF !important; }
    :not(.login-page) .open_menu.right,
    :not(.login-page) .close_menu.right {
      left: auto;
      right: 20px; }
  :not(.login-page) .open_menu {
    position: fixed;
    left: 20px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.04); }
  :not(.login-page) .close_menu {
    right: 20px;
    position: absolute; }
  :not(.login-page) .video {
    position: relative;
    overflow: hidden; }
    :not(.login-page) .video .poster {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all 0.2s;
      cursor: pointer;
      z-index: 500; }
      :not(.login-page) .video .poster img {
        width: 100%;
        max-width: 100%; }
    :not(.login-page) .video video {
      width: 100%;
      height: 100%;
      display: none; }
  :not(.login-page) .play {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 16px;
    border-radius: 50%;
    text-align: center;
    transition: all 0.2s;
    z-index: 501; }
    :not(.login-page) .play:hover {
      transform: scale(1.08); }
    :not(.login-page) .play:not(.relative) {
      margin: -35px 0 0 -35px;
      position: absolute;
      top: 50%;
      left: 50%; }
    :not(.login-page) .play.relative {
      display: inline-block; }
    :not(.login-page) .play.sm {
      width: 60px;
      height: 60px;
      line-height: 60px;
      margin: -30px 0 0 -30px; }
    :not(.login-page) .play.red {
      background: #EA3223;
      color: #FFFFFF; }
    :not(.login-page) .play.white {
      background: #FFFFFF;
      color: #1e3060; }
    :not(.login-page) .play.action-1 {
      background: #0074f1;
      color: #FFFFFF; }
    :not(.login-page) .play.action-2 {
      background: #0074f1;
      color: #FFFFFF; }
    :not(.login-page) .play.action-3 {
      background: #7121F4;
      color: #FFFFFF; }
    :not(.login-page) .play .fa,
    :not(.login-page) .play .fas {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  :not(.login-page) .opening_tabs .tab_opener {
    cursor: pointer; }
  :not(.login-page) .opening_tabs .tab_text {
    overflow: hidden;
    height: 0; }
  :not(.login-page) .alert {
    display: none;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 970px;
    color: #FFFFFF;
    z-index: 2000; }
    :not(.login-page) .alert-dismissable .close, :not(.login-page) .alert-dismissible .close {
      top: 0; }
    :not(.login-page) .alert.fixed-top {
      margin-top: 1rem; }
    :not(.login-page) .alert-primary {
      background: #1e3060;
      border-color: #1e3060; }
    :not(.login-page) .alert-secondary {
      background: #1E3060;
      border-color: #1E3060; }
    :not(.login-page) .alert-success {
      background: #0074f1;
      border-color: #0074f1; }
    :not(.login-page) .alert-danger {
      background: #7121F4;
      border-color: #7121F4; }
    :not(.login-page) .alert-warning, :not(.login-page) .alert-info {
      background: #0074f1;
      border-color: #0074f1; }
    :not(.login-page) .alert-light {
      background: #FFFFFF;
      border-color: #FFFFFF;
      color: #1e3060; }
      :not(.login-page) .alert-light.alert .close:hover {
        color: #1e3060; }
    :not(.login-page) .alert-dark {
      background: #35383D;
      border-color: #35383D; }
    :not(.login-page) .alert .close {
      text-shadow: none; }
      :not(.login-page) .alert .close:hover {
        color: #FFFFFF; }
  :not(.login-page) .grecaptcha-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    cursor: pointer; }
  :not(.login-page) .grecaptcha-popup {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000; }
  :not(.login-page) .dropdown.show .dropdown-toggle:after {
    transform: scaleY(-1); }
  :not(.login-page) .dropdown a {
    white-space: nowrap; }
  :not(.login-page) .dropdown-toggle:after {
    content: "\f107";
    height: 100%;
    vertical-align: top;
    border: none;
    font-size: 18px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    transition: transform 0.2s; }
  :not(.login-page) .dropdown-menu {
    margin-top: 0;
    margin-left: -20px;
    padding: 0px 20px;
    border: none;
    border-radius: 0px; }
    :not(.login-page) .dropdown-menu.dropdown-menu-right {
      margin-left: auto;
      margin-right: -20px; }
  :not(.login-page) .navigation_popup {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border-top: 2px solid #E7EAED;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.25s; }
    :not(.login-page) .navigation_popup.opened {
      opacity: 1;
      visibility: visible; }
  :not(.login-page) .slider.arrows0 .slick-prev {
    left: 0px; }
  :not(.login-page) .slider.arrows0 .slick-next {
    right: 0px; }
  :not(.login-page) .slider.arrows10 .slick-prev {
    left: 10px; }
  :not(.login-page) .slider.arrows10 .slick-next {
    right: 10px; }
  :not(.login-page) .slider.arrows20 .slick-prev {
    left: 20px; }
  :not(.login-page) .slider.arrows20 .slick-next {
    right: 20px; }
  :not(.login-page) .slider.arrows30 .slick-prev {
    left: 30px; }
  :not(.login-page) .slider.arrows30 .slick-next {
    right: 30px; }
  :not(.login-page) .slider.arrows40 .slick-prev {
    left: 40px; }
  :not(.login-page) .slider.arrows40 .slick-next {
    right: 40px; }
  :not(.login-page) .slider.arrows50 .slick-prev {
    left: 50px; }
  :not(.login-page) .slider.arrows50 .slick-next {
    right: 50px; }
  :not(.login-page) .slider.arrows60 .slick-prev {
    left: 60px; }
  :not(.login-page) .slider.arrows60 .slick-next {
    right: 60px; }
  :not(.login-page) .slider.arrows70 .slick-prev {
    left: 70px; }
  :not(.login-page) .slider.arrows70 .slick-next {
    right: 70px; }
  :not(.login-page) .slider.arrows80 .slick-prev {
    left: 80px; }
  :not(.login-page) .slider.arrows80 .slick-next {
    right: 80px; }
  :not(.login-page) .slider.arrows90 .slick-prev {
    left: 90px; }
  :not(.login-page) .slider.arrows90 .slick-next {
    right: 90px; }
  :not(.login-page) .slider.arrows100 .slick-prev {
    left: 100px; }
  :not(.login-page) .slider.arrows100 .slick-next {
    right: 100px; }
  :not(.login-page) .slider.dots_color_main .slick-dots li button {
    border-color: #1e3060; }
  :not(.login-page) .slider.dots_color_main .slick-dots li.slick-active button {
    background: #1e3060; }
  :not(.login-page) .slider.dots-100 .slick-dots {
    bottom: -100px; }
  :not(.login-page) .slider.dots-90 .slick-dots {
    bottom: -90px; }
  :not(.login-page) .slider.dots-80 .slick-dots {
    bottom: -80px; }
  :not(.login-page) .slider.dots-70 .slick-dots {
    bottom: -70px; }
  :not(.login-page) .slider.dots-60 .slick-dots {
    bottom: -60px; }
  :not(.login-page) .slider.dots-50 .slick-dots {
    bottom: -50px; }
  :not(.login-page) .slider.dots-40 .slick-dots {
    bottom: -40px; }
  :not(.login-page) .slider.dots-30 .slick-dots {
    bottom: -30px; }
  :not(.login-page) .slider.dots-20 .slick-dots {
    bottom: -20px; }
  :not(.login-page) .slider.dots-10 .slick-dots {
    bottom: -10px; }
  :not(.login-page) .slider.dots0 .slick-dots {
    bottom: 0px; }
  :not(.login-page) .slider.dots10 .slick-dots {
    bottom: 10px; }
  :not(.login-page) .slider.dots20 .slick-dots {
    bottom: 20px; }
  :not(.login-page) .slider.dots30 .slick-dots {
    bottom: 30px; }
  :not(.login-page) .slider.dots40 .slick-dots {
    bottom: 40px; }
  :not(.login-page) .slider.dots50 .slick-dots {
    bottom: 50px; }
  :not(.login-page) .slider.dots60 .slick-dots {
    bottom: 60px; }
  :not(.login-page) .slider.dots70 .slick-dots {
    bottom: 70px; }
  :not(.login-page) .slider.dots80 .slick-dots {
    bottom: 80px; }
  :not(.login-page) .slider.dots90 .slick-dots {
    bottom: 90px; }
  :not(.login-page) .slider.dots100 .slick-dots {
    bottom: 100px; }
  :not(.login-page) .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(231, 234, 237, 0.5); }
  :not(.login-page) .mt-0 {
    margin-top: 0px; }
  :not(.login-page) .mb-0 {
    margin-bottom: 0px; }
  :not(.login-page) .mr-0 {
    margin-right: 0px; }
  :not(.login-page) .ml-0 {
    margin-left: 0px; }
  :not(.login-page) .mx-0 {
    margin-left: 0px;
    margin-right: 0px; }
  :not(.login-page) .pt-0 {
    padding-top: 0px; }
  :not(.login-page) .pb-0 {
    padding-bottom: 0px; }
  :not(.login-page) .pr-0 {
    padding-right: 0px; }
  :not(.login-page) .pl-0 {
    padding-left: 0px; }
  :not(.login-page) .px-0 {
    padding-left: 0px;
    padding-right: 0px; }
  :not(.login-page) .mt-5 {
    margin-top: 5px; }
  :not(.login-page) .mb-5 {
    margin-bottom: 5px; }
  :not(.login-page) .mr-5 {
    margin-right: 5px; }
  :not(.login-page) .ml-5 {
    margin-left: 5px; }
  :not(.login-page) .mx-5 {
    margin-left: 5px;
    margin-right: 5px; }
  :not(.login-page) .pt-5 {
    padding-top: 5px; }
  :not(.login-page) .pb-5 {
    padding-bottom: 5px; }
  :not(.login-page) .pr-5 {
    padding-right: 5px; }
  :not(.login-page) .pl-5 {
    padding-left: 5px; }
  :not(.login-page) .px-5 {
    padding-left: 5px;
    padding-right: 5px; }
  :not(.login-page) .mt-10 {
    margin-top: 10px; }
  :not(.login-page) .mb-10 {
    margin-bottom: 10px; }
  :not(.login-page) .mr-10 {
    margin-right: 10px; }
  :not(.login-page) .ml-10 {
    margin-left: 10px; }
  :not(.login-page) .mx-10 {
    margin-left: 10px;
    margin-right: 10px; }
  :not(.login-page) .pt-10 {
    padding-top: 10px; }
  :not(.login-page) .pb-10 {
    padding-bottom: 10px; }
  :not(.login-page) .pr-10 {
    padding-right: 10px; }
  :not(.login-page) .pl-10 {
    padding-left: 10px; }
  :not(.login-page) .px-10 {
    padding-left: 10px;
    padding-right: 10px; }
  :not(.login-page) .mt-15 {
    margin-top: 15px; }
  :not(.login-page) .mb-15 {
    margin-bottom: 15px; }
  :not(.login-page) .mr-15 {
    margin-right: 15px; }
  :not(.login-page) .ml-15 {
    margin-left: 15px; }
  :not(.login-page) .mx-15 {
    margin-left: 15px;
    margin-right: 15px; }
  :not(.login-page) .pt-15 {
    padding-top: 15px; }
  :not(.login-page) .pb-15 {
    padding-bottom: 15px; }
  :not(.login-page) .pr-15 {
    padding-right: 15px; }
  :not(.login-page) .pl-15 {
    padding-left: 15px; }
  :not(.login-page) .px-15 {
    padding-left: 15px;
    padding-right: 15px; }
  :not(.login-page) .mt-20 {
    margin-top: 20px; }
  :not(.login-page) .mb-20 {
    margin-bottom: 20px; }
  :not(.login-page) .mr-20 {
    margin-right: 20px; }
  :not(.login-page) .ml-20 {
    margin-left: 20px; }
  :not(.login-page) .mx-20 {
    margin-left: 20px;
    margin-right: 20px; }
  :not(.login-page) .pt-20 {
    padding-top: 20px; }
  :not(.login-page) .pb-20 {
    padding-bottom: 20px; }
  :not(.login-page) .pr-20 {
    padding-right: 20px; }
  :not(.login-page) .pl-20 {
    padding-left: 20px; }
  :not(.login-page) .px-20 {
    padding-left: 20px;
    padding-right: 20px; }
  :not(.login-page) .mt-25 {
    margin-top: 25px; }
  :not(.login-page) .mb-25 {
    margin-bottom: 25px; }
  :not(.login-page) .mr-25 {
    margin-right: 25px; }
  :not(.login-page) .ml-25 {
    margin-left: 25px; }
  :not(.login-page) .mx-25 {
    margin-left: 25px;
    margin-right: 25px; }
  :not(.login-page) .pt-25 {
    padding-top: 25px; }
  :not(.login-page) .pb-25 {
    padding-bottom: 25px; }
  :not(.login-page) .pr-25 {
    padding-right: 25px; }
  :not(.login-page) .pl-25 {
    padding-left: 25px; }
  :not(.login-page) .px-25 {
    padding-left: 25px;
    padding-right: 25px; }
  :not(.login-page) .mt-30 {
    margin-top: 30px; }
  :not(.login-page) .mb-30 {
    margin-bottom: 30px; }
  :not(.login-page) .mr-30 {
    margin-right: 30px; }
  :not(.login-page) .ml-30 {
    margin-left: 30px; }
  :not(.login-page) .mx-30 {
    margin-left: 30px;
    margin-right: 30px; }
  :not(.login-page) .pt-30 {
    padding-top: 30px; }
  :not(.login-page) .pb-30 {
    padding-bottom: 30px; }
  :not(.login-page) .pr-30 {
    padding-right: 30px; }
  :not(.login-page) .pl-30 {
    padding-left: 30px; }
  :not(.login-page) .px-30 {
    padding-left: 30px;
    padding-right: 30px; }
  :not(.login-page) .mt-35 {
    margin-top: 35px; }
  :not(.login-page) .mb-35 {
    margin-bottom: 35px; }
  :not(.login-page) .mr-35 {
    margin-right: 35px; }
  :not(.login-page) .ml-35 {
    margin-left: 35px; }
  :not(.login-page) .mx-35 {
    margin-left: 35px;
    margin-right: 35px; }
  :not(.login-page) .pt-35 {
    padding-top: 35px; }
  :not(.login-page) .pb-35 {
    padding-bottom: 35px; }
  :not(.login-page) .pr-35 {
    padding-right: 35px; }
  :not(.login-page) .pl-35 {
    padding-left: 35px; }
  :not(.login-page) .px-35 {
    padding-left: 35px;
    padding-right: 35px; }
  :not(.login-page) .mt-40 {
    margin-top: 40px; }
  :not(.login-page) .mb-40 {
    margin-bottom: 40px; }
  :not(.login-page) .mr-40 {
    margin-right: 40px; }
  :not(.login-page) .ml-40 {
    margin-left: 40px; }
  :not(.login-page) .mx-40 {
    margin-left: 40px;
    margin-right: 40px; }
  :not(.login-page) .pt-40 {
    padding-top: 40px; }
  :not(.login-page) .pb-40 {
    padding-bottom: 40px; }
  :not(.login-page) .pr-40 {
    padding-right: 40px; }
  :not(.login-page) .pl-40 {
    padding-left: 40px; }
  :not(.login-page) .px-40 {
    padding-left: 40px;
    padding-right: 40px; }
  :not(.login-page) .mt-45 {
    margin-top: 45px; }
  :not(.login-page) .mb-45 {
    margin-bottom: 45px; }
  :not(.login-page) .mr-45 {
    margin-right: 45px; }
  :not(.login-page) .ml-45 {
    margin-left: 45px; }
  :not(.login-page) .mx-45 {
    margin-left: 45px;
    margin-right: 45px; }
  :not(.login-page) .pt-45 {
    padding-top: 45px; }
  :not(.login-page) .pb-45 {
    padding-bottom: 45px; }
  :not(.login-page) .pr-45 {
    padding-right: 45px; }
  :not(.login-page) .pl-45 {
    padding-left: 45px; }
  :not(.login-page) .px-45 {
    padding-left: 45px;
    padding-right: 45px; }
  :not(.login-page) .mt-50 {
    margin-top: 50px; }
  :not(.login-page) .mb-50 {
    margin-bottom: 50px; }
  :not(.login-page) .mr-50 {
    margin-right: 50px; }
  :not(.login-page) .ml-50 {
    margin-left: 50px; }
  :not(.login-page) .mx-50 {
    margin-left: 50px;
    margin-right: 50px; }
  :not(.login-page) .pt-50 {
    padding-top: 50px; }
  :not(.login-page) .pb-50 {
    padding-bottom: 50px; }
  :not(.login-page) .pr-50 {
    padding-right: 50px; }
  :not(.login-page) .pl-50 {
    padding-left: 50px; }
  :not(.login-page) .px-50 {
    padding-left: 50px;
    padding-right: 50px; }
  :not(.login-page) .mt-55 {
    margin-top: 55px; }
  :not(.login-page) .mb-55 {
    margin-bottom: 55px; }
  :not(.login-page) .mr-55 {
    margin-right: 55px; }
  :not(.login-page) .ml-55 {
    margin-left: 55px; }
  :not(.login-page) .mx-55 {
    margin-left: 55px;
    margin-right: 55px; }
  :not(.login-page) .pt-55 {
    padding-top: 55px; }
  :not(.login-page) .pb-55 {
    padding-bottom: 55px; }
  :not(.login-page) .pr-55 {
    padding-right: 55px; }
  :not(.login-page) .pl-55 {
    padding-left: 55px; }
  :not(.login-page) .px-55 {
    padding-left: 55px;
    padding-right: 55px; }
  :not(.login-page) .mt-60 {
    margin-top: 60px; }
  :not(.login-page) .mb-60 {
    margin-bottom: 60px; }
  :not(.login-page) .mr-60 {
    margin-right: 60px; }
  :not(.login-page) .ml-60 {
    margin-left: 60px; }
  :not(.login-page) .mx-60 {
    margin-left: 60px;
    margin-right: 60px; }
  :not(.login-page) .pt-60 {
    padding-top: 60px; }
  :not(.login-page) .pb-60 {
    padding-bottom: 60px; }
  :not(.login-page) .pr-60 {
    padding-right: 60px; }
  :not(.login-page) .pl-60 {
    padding-left: 60px; }
  :not(.login-page) .px-60 {
    padding-left: 60px;
    padding-right: 60px; }
  :not(.login-page) .mt-65 {
    margin-top: 65px; }
  :not(.login-page) .mb-65 {
    margin-bottom: 65px; }
  :not(.login-page) .mr-65 {
    margin-right: 65px; }
  :not(.login-page) .ml-65 {
    margin-left: 65px; }
  :not(.login-page) .mx-65 {
    margin-left: 65px;
    margin-right: 65px; }
  :not(.login-page) .pt-65 {
    padding-top: 65px; }
  :not(.login-page) .pb-65 {
    padding-bottom: 65px; }
  :not(.login-page) .pr-65 {
    padding-right: 65px; }
  :not(.login-page) .pl-65 {
    padding-left: 65px; }
  :not(.login-page) .px-65 {
    padding-left: 65px;
    padding-right: 65px; }
  :not(.login-page) .mt-70 {
    margin-top: 70px; }
  :not(.login-page) .mb-70 {
    margin-bottom: 70px; }
  :not(.login-page) .mr-70 {
    margin-right: 70px; }
  :not(.login-page) .ml-70 {
    margin-left: 70px; }
  :not(.login-page) .mx-70 {
    margin-left: 70px;
    margin-right: 70px; }
  :not(.login-page) .pt-70 {
    padding-top: 70px; }
  :not(.login-page) .pb-70 {
    padding-bottom: 70px; }
  :not(.login-page) .pr-70 {
    padding-right: 70px; }
  :not(.login-page) .pl-70 {
    padding-left: 70px; }
  :not(.login-page) .px-70 {
    padding-left: 70px;
    padding-right: 70px; }
  :not(.login-page) .mt-75 {
    margin-top: 75px; }
  :not(.login-page) .mb-75 {
    margin-bottom: 75px; }
  :not(.login-page) .mr-75 {
    margin-right: 75px; }
  :not(.login-page) .ml-75 {
    margin-left: 75px; }
  :not(.login-page) .mx-75 {
    margin-left: 75px;
    margin-right: 75px; }
  :not(.login-page) .pt-75 {
    padding-top: 75px; }
  :not(.login-page) .pb-75 {
    padding-bottom: 75px; }
  :not(.login-page) .pr-75 {
    padding-right: 75px; }
  :not(.login-page) .pl-75 {
    padding-left: 75px; }
  :not(.login-page) .px-75 {
    padding-left: 75px;
    padding-right: 75px; }
  :not(.login-page) .mt-80 {
    margin-top: 80px; }
  :not(.login-page) .mb-80 {
    margin-bottom: 80px; }
  :not(.login-page) .mr-80 {
    margin-right: 80px; }
  :not(.login-page) .ml-80 {
    margin-left: 80px; }
  :not(.login-page) .mx-80 {
    margin-left: 80px;
    margin-right: 80px; }
  :not(.login-page) .pt-80 {
    padding-top: 80px; }
  :not(.login-page) .pb-80 {
    padding-bottom: 80px; }
  :not(.login-page) .pr-80 {
    padding-right: 80px; }
  :not(.login-page) .pl-80 {
    padding-left: 80px; }
  :not(.login-page) .px-80 {
    padding-left: 80px;
    padding-right: 80px; }
  :not(.login-page) .mt-85 {
    margin-top: 85px; }
  :not(.login-page) .mb-85 {
    margin-bottom: 85px; }
  :not(.login-page) .mr-85 {
    margin-right: 85px; }
  :not(.login-page) .ml-85 {
    margin-left: 85px; }
  :not(.login-page) .mx-85 {
    margin-left: 85px;
    margin-right: 85px; }
  :not(.login-page) .pt-85 {
    padding-top: 85px; }
  :not(.login-page) .pb-85 {
    padding-bottom: 85px; }
  :not(.login-page) .pr-85 {
    padding-right: 85px; }
  :not(.login-page) .pl-85 {
    padding-left: 85px; }
  :not(.login-page) .px-85 {
    padding-left: 85px;
    padding-right: 85px; }
  :not(.login-page) .mt-90 {
    margin-top: 90px; }
  :not(.login-page) .mb-90 {
    margin-bottom: 90px; }
  :not(.login-page) .mr-90 {
    margin-right: 90px; }
  :not(.login-page) .ml-90 {
    margin-left: 90px; }
  :not(.login-page) .mx-90 {
    margin-left: 90px;
    margin-right: 90px; }
  :not(.login-page) .pt-90 {
    padding-top: 90px; }
  :not(.login-page) .pb-90 {
    padding-bottom: 90px; }
  :not(.login-page) .pr-90 {
    padding-right: 90px; }
  :not(.login-page) .pl-90 {
    padding-left: 90px; }
  :not(.login-page) .px-90 {
    padding-left: 90px;
    padding-right: 90px; }
  :not(.login-page) .mt-95 {
    margin-top: 95px; }
  :not(.login-page) .mb-95 {
    margin-bottom: 95px; }
  :not(.login-page) .mr-95 {
    margin-right: 95px; }
  :not(.login-page) .ml-95 {
    margin-left: 95px; }
  :not(.login-page) .mx-95 {
    margin-left: 95px;
    margin-right: 95px; }
  :not(.login-page) .pt-95 {
    padding-top: 95px; }
  :not(.login-page) .pb-95 {
    padding-bottom: 95px; }
  :not(.login-page) .pr-95 {
    padding-right: 95px; }
  :not(.login-page) .pl-95 {
    padding-left: 95px; }
  :not(.login-page) .px-95 {
    padding-left: 95px;
    padding-right: 95px; }
  :not(.login-page) .mt-100 {
    margin-top: 100px; }
  :not(.login-page) .mb-100 {
    margin-bottom: 100px; }
  :not(.login-page) .mr-100 {
    margin-right: 100px; }
  :not(.login-page) .ml-100 {
    margin-left: 100px; }
  :not(.login-page) .mx-100 {
    margin-left: 100px;
    margin-right: 100px; }
  :not(.login-page) .pt-100 {
    padding-top: 100px; }
  :not(.login-page) .pb-100 {
    padding-bottom: 100px; }
  :not(.login-page) .pr-100 {
    padding-right: 100px; }
  :not(.login-page) .pl-100 {
    padding-left: 100px; }
  :not(.login-page) .px-100 {
    padding-left: 100px;
    padding-right: 100px; }
  :not(.login-page) .mt-105 {
    margin-top: 105px; }
  :not(.login-page) .mb-105 {
    margin-bottom: 105px; }
  :not(.login-page) .mr-105 {
    margin-right: 105px; }
  :not(.login-page) .ml-105 {
    margin-left: 105px; }
  :not(.login-page) .mx-105 {
    margin-left: 105px;
    margin-right: 105px; }
  :not(.login-page) .pt-105 {
    padding-top: 105px; }
  :not(.login-page) .pb-105 {
    padding-bottom: 105px; }
  :not(.login-page) .pr-105 {
    padding-right: 105px; }
  :not(.login-page) .pl-105 {
    padding-left: 105px; }
  :not(.login-page) .px-105 {
    padding-left: 105px;
    padding-right: 105px; }
  :not(.login-page) .mt-110 {
    margin-top: 110px; }
  :not(.login-page) .mb-110 {
    margin-bottom: 110px; }
  :not(.login-page) .mr-110 {
    margin-right: 110px; }
  :not(.login-page) .ml-110 {
    margin-left: 110px; }
  :not(.login-page) .mx-110 {
    margin-left: 110px;
    margin-right: 110px; }
  :not(.login-page) .pt-110 {
    padding-top: 110px; }
  :not(.login-page) .pb-110 {
    padding-bottom: 110px; }
  :not(.login-page) .pr-110 {
    padding-right: 110px; }
  :not(.login-page) .pl-110 {
    padding-left: 110px; }
  :not(.login-page) .px-110 {
    padding-left: 110px;
    padding-right: 110px; }
  :not(.login-page) .mt-115 {
    margin-top: 115px; }
  :not(.login-page) .mb-115 {
    margin-bottom: 115px; }
  :not(.login-page) .mr-115 {
    margin-right: 115px; }
  :not(.login-page) .ml-115 {
    margin-left: 115px; }
  :not(.login-page) .mx-115 {
    margin-left: 115px;
    margin-right: 115px; }
  :not(.login-page) .pt-115 {
    padding-top: 115px; }
  :not(.login-page) .pb-115 {
    padding-bottom: 115px; }
  :not(.login-page) .pr-115 {
    padding-right: 115px; }
  :not(.login-page) .pl-115 {
    padding-left: 115px; }
  :not(.login-page) .px-115 {
    padding-left: 115px;
    padding-right: 115px; }
  :not(.login-page) .mt-120 {
    margin-top: 120px; }
  :not(.login-page) .mb-120 {
    margin-bottom: 120px; }
  :not(.login-page) .mr-120 {
    margin-right: 120px; }
  :not(.login-page) .ml-120 {
    margin-left: 120px; }
  :not(.login-page) .mx-120 {
    margin-left: 120px;
    margin-right: 120px; }
  :not(.login-page) .pt-120 {
    padding-top: 120px; }
  :not(.login-page) .pb-120 {
    padding-bottom: 120px; }
  :not(.login-page) .pr-120 {
    padding-right: 120px; }
  :not(.login-page) .pl-120 {
    padding-left: 120px; }
  :not(.login-page) .px-120 {
    padding-left: 120px;
    padding-right: 120px; }
  :not(.login-page) .mt-125 {
    margin-top: 125px; }
  :not(.login-page) .mb-125 {
    margin-bottom: 125px; }
  :not(.login-page) .mr-125 {
    margin-right: 125px; }
  :not(.login-page) .ml-125 {
    margin-left: 125px; }
  :not(.login-page) .mx-125 {
    margin-left: 125px;
    margin-right: 125px; }
  :not(.login-page) .pt-125 {
    padding-top: 125px; }
  :not(.login-page) .pb-125 {
    padding-bottom: 125px; }
  :not(.login-page) .pr-125 {
    padding-right: 125px; }
  :not(.login-page) .pl-125 {
    padding-left: 125px; }
  :not(.login-page) .px-125 {
    padding-left: 125px;
    padding-right: 125px; }
  :not(.login-page) .mt-130 {
    margin-top: 130px; }
  :not(.login-page) .mb-130 {
    margin-bottom: 130px; }
  :not(.login-page) .mr-130 {
    margin-right: 130px; }
  :not(.login-page) .ml-130 {
    margin-left: 130px; }
  :not(.login-page) .mx-130 {
    margin-left: 130px;
    margin-right: 130px; }
  :not(.login-page) .pt-130 {
    padding-top: 130px; }
  :not(.login-page) .pb-130 {
    padding-bottom: 130px; }
  :not(.login-page) .pr-130 {
    padding-right: 130px; }
  :not(.login-page) .pl-130 {
    padding-left: 130px; }
  :not(.login-page) .px-130 {
    padding-left: 130px;
    padding-right: 130px; }
  :not(.login-page) .mt-135 {
    margin-top: 135px; }
  :not(.login-page) .mb-135 {
    margin-bottom: 135px; }
  :not(.login-page) .mr-135 {
    margin-right: 135px; }
  :not(.login-page) .ml-135 {
    margin-left: 135px; }
  :not(.login-page) .mx-135 {
    margin-left: 135px;
    margin-right: 135px; }
  :not(.login-page) .pt-135 {
    padding-top: 135px; }
  :not(.login-page) .pb-135 {
    padding-bottom: 135px; }
  :not(.login-page) .pr-135 {
    padding-right: 135px; }
  :not(.login-page) .pl-135 {
    padding-left: 135px; }
  :not(.login-page) .px-135 {
    padding-left: 135px;
    padding-right: 135px; }
  :not(.login-page) .mt-140 {
    margin-top: 140px; }
  :not(.login-page) .mb-140 {
    margin-bottom: 140px; }
  :not(.login-page) .mr-140 {
    margin-right: 140px; }
  :not(.login-page) .ml-140 {
    margin-left: 140px; }
  :not(.login-page) .mx-140 {
    margin-left: 140px;
    margin-right: 140px; }
  :not(.login-page) .pt-140 {
    padding-top: 140px; }
  :not(.login-page) .pb-140 {
    padding-bottom: 140px; }
  :not(.login-page) .pr-140 {
    padding-right: 140px; }
  :not(.login-page) .pl-140 {
    padding-left: 140px; }
  :not(.login-page) .px-140 {
    padding-left: 140px;
    padding-right: 140px; }
  :not(.login-page) .mt-145 {
    margin-top: 145px; }
  :not(.login-page) .mb-145 {
    margin-bottom: 145px; }
  :not(.login-page) .mr-145 {
    margin-right: 145px; }
  :not(.login-page) .ml-145 {
    margin-left: 145px; }
  :not(.login-page) .mx-145 {
    margin-left: 145px;
    margin-right: 145px; }
  :not(.login-page) .pt-145 {
    padding-top: 145px; }
  :not(.login-page) .pb-145 {
    padding-bottom: 145px; }
  :not(.login-page) .pr-145 {
    padding-right: 145px; }
  :not(.login-page) .pl-145 {
    padding-left: 145px; }
  :not(.login-page) .px-145 {
    padding-left: 145px;
    padding-right: 145px; }
  :not(.login-page) .mt-150 {
    margin-top: 150px; }
  :not(.login-page) .mb-150 {
    margin-bottom: 150px; }
  :not(.login-page) .mr-150 {
    margin-right: 150px; }
  :not(.login-page) .ml-150 {
    margin-left: 150px; }
  :not(.login-page) .mx-150 {
    margin-left: 150px;
    margin-right: 150px; }
  :not(.login-page) .pt-150 {
    padding-top: 150px; }
  :not(.login-page) .pb-150 {
    padding-bottom: 150px; }
  :not(.login-page) .pr-150 {
    padding-right: 150px; }
  :not(.login-page) .pl-150 {
    padding-left: 150px; }
  :not(.login-page) .px-150 {
    padding-left: 150px;
    padding-right: 150px; }
  :not(.login-page) .mt-155 {
    margin-top: 155px; }
  :not(.login-page) .mb-155 {
    margin-bottom: 155px; }
  :not(.login-page) .mr-155 {
    margin-right: 155px; }
  :not(.login-page) .ml-155 {
    margin-left: 155px; }
  :not(.login-page) .mx-155 {
    margin-left: 155px;
    margin-right: 155px; }
  :not(.login-page) .pt-155 {
    padding-top: 155px; }
  :not(.login-page) .pb-155 {
    padding-bottom: 155px; }
  :not(.login-page) .pr-155 {
    padding-right: 155px; }
  :not(.login-page) .pl-155 {
    padding-left: 155px; }
  :not(.login-page) .px-155 {
    padding-left: 155px;
    padding-right: 155px; }
  :not(.login-page) .mt-160 {
    margin-top: 160px; }
  :not(.login-page) .mb-160 {
    margin-bottom: 160px; }
  :not(.login-page) .mr-160 {
    margin-right: 160px; }
  :not(.login-page) .ml-160 {
    margin-left: 160px; }
  :not(.login-page) .mx-160 {
    margin-left: 160px;
    margin-right: 160px; }
  :not(.login-page) .pt-160 {
    padding-top: 160px; }
  :not(.login-page) .pb-160 {
    padding-bottom: 160px; }
  :not(.login-page) .pr-160 {
    padding-right: 160px; }
  :not(.login-page) .pl-160 {
    padding-left: 160px; }
  :not(.login-page) .px-160 {
    padding-left: 160px;
    padding-right: 160px; }
  :not(.login-page) .mt-165 {
    margin-top: 165px; }
  :not(.login-page) .mb-165 {
    margin-bottom: 165px; }
  :not(.login-page) .mr-165 {
    margin-right: 165px; }
  :not(.login-page) .ml-165 {
    margin-left: 165px; }
  :not(.login-page) .mx-165 {
    margin-left: 165px;
    margin-right: 165px; }
  :not(.login-page) .pt-165 {
    padding-top: 165px; }
  :not(.login-page) .pb-165 {
    padding-bottom: 165px; }
  :not(.login-page) .pr-165 {
    padding-right: 165px; }
  :not(.login-page) .pl-165 {
    padding-left: 165px; }
  :not(.login-page) .px-165 {
    padding-left: 165px;
    padding-right: 165px; }
  :not(.login-page) .mt-170 {
    margin-top: 170px; }
  :not(.login-page) .mb-170 {
    margin-bottom: 170px; }
  :not(.login-page) .mr-170 {
    margin-right: 170px; }
  :not(.login-page) .ml-170 {
    margin-left: 170px; }
  :not(.login-page) .mx-170 {
    margin-left: 170px;
    margin-right: 170px; }
  :not(.login-page) .pt-170 {
    padding-top: 170px; }
  :not(.login-page) .pb-170 {
    padding-bottom: 170px; }
  :not(.login-page) .pr-170 {
    padding-right: 170px; }
  :not(.login-page) .pl-170 {
    padding-left: 170px; }
  :not(.login-page) .px-170 {
    padding-left: 170px;
    padding-right: 170px; }
  :not(.login-page) .mt-175 {
    margin-top: 175px; }
  :not(.login-page) .mb-175 {
    margin-bottom: 175px; }
  :not(.login-page) .mr-175 {
    margin-right: 175px; }
  :not(.login-page) .ml-175 {
    margin-left: 175px; }
  :not(.login-page) .mx-175 {
    margin-left: 175px;
    margin-right: 175px; }
  :not(.login-page) .pt-175 {
    padding-top: 175px; }
  :not(.login-page) .pb-175 {
    padding-bottom: 175px; }
  :not(.login-page) .pr-175 {
    padding-right: 175px; }
  :not(.login-page) .pl-175 {
    padding-left: 175px; }
  :not(.login-page) .px-175 {
    padding-left: 175px;
    padding-right: 175px; }
  :not(.login-page) .mt-180 {
    margin-top: 180px; }
  :not(.login-page) .mb-180 {
    margin-bottom: 180px; }
  :not(.login-page) .mr-180 {
    margin-right: 180px; }
  :not(.login-page) .ml-180 {
    margin-left: 180px; }
  :not(.login-page) .mx-180 {
    margin-left: 180px;
    margin-right: 180px; }
  :not(.login-page) .pt-180 {
    padding-top: 180px; }
  :not(.login-page) .pb-180 {
    padding-bottom: 180px; }
  :not(.login-page) .pr-180 {
    padding-right: 180px; }
  :not(.login-page) .pl-180 {
    padding-left: 180px; }
  :not(.login-page) .px-180 {
    padding-left: 180px;
    padding-right: 180px; }
  :not(.login-page) .mt-185 {
    margin-top: 185px; }
  :not(.login-page) .mb-185 {
    margin-bottom: 185px; }
  :not(.login-page) .mr-185 {
    margin-right: 185px; }
  :not(.login-page) .ml-185 {
    margin-left: 185px; }
  :not(.login-page) .mx-185 {
    margin-left: 185px;
    margin-right: 185px; }
  :not(.login-page) .pt-185 {
    padding-top: 185px; }
  :not(.login-page) .pb-185 {
    padding-bottom: 185px; }
  :not(.login-page) .pr-185 {
    padding-right: 185px; }
  :not(.login-page) .pl-185 {
    padding-left: 185px; }
  :not(.login-page) .px-185 {
    padding-left: 185px;
    padding-right: 185px; }
  :not(.login-page) .mt-190 {
    margin-top: 190px; }
  :not(.login-page) .mb-190 {
    margin-bottom: 190px; }
  :not(.login-page) .mr-190 {
    margin-right: 190px; }
  :not(.login-page) .ml-190 {
    margin-left: 190px; }
  :not(.login-page) .mx-190 {
    margin-left: 190px;
    margin-right: 190px; }
  :not(.login-page) .pt-190 {
    padding-top: 190px; }
  :not(.login-page) .pb-190 {
    padding-bottom: 190px; }
  :not(.login-page) .pr-190 {
    padding-right: 190px; }
  :not(.login-page) .pl-190 {
    padding-left: 190px; }
  :not(.login-page) .px-190 {
    padding-left: 190px;
    padding-right: 190px; }
  :not(.login-page) .mt-195 {
    margin-top: 195px; }
  :not(.login-page) .mb-195 {
    margin-bottom: 195px; }
  :not(.login-page) .mr-195 {
    margin-right: 195px; }
  :not(.login-page) .ml-195 {
    margin-left: 195px; }
  :not(.login-page) .mx-195 {
    margin-left: 195px;
    margin-right: 195px; }
  :not(.login-page) .pt-195 {
    padding-top: 195px; }
  :not(.login-page) .pb-195 {
    padding-bottom: 195px; }
  :not(.login-page) .pr-195 {
    padding-right: 195px; }
  :not(.login-page) .pl-195 {
    padding-left: 195px; }
  :not(.login-page) .px-195 {
    padding-left: 195px;
    padding-right: 195px; }
  :not(.login-page) .mt-200 {
    margin-top: 200px; }
  :not(.login-page) .mb-200 {
    margin-bottom: 200px; }
  :not(.login-page) .mr-200 {
    margin-right: 200px; }
  :not(.login-page) .ml-200 {
    margin-left: 200px; }
  :not(.login-page) .mx-200 {
    margin-left: 200px;
    margin-right: 200px; }
  :not(.login-page) .pt-200 {
    padding-top: 200px; }
  :not(.login-page) .pb-200 {
    padding-bottom: 200px; }
  :not(.login-page) .pr-200 {
    padding-right: 200px; }
  :not(.login-page) .pl-200 {
    padding-left: 200px; }
  :not(.login-page) .px-200 {
    padding-left: 200px;
    padding-right: 200px; }
  :not(.login-page) .mt-205 {
    margin-top: 205px; }
  :not(.login-page) .mb-205 {
    margin-bottom: 205px; }
  :not(.login-page) .mr-205 {
    margin-right: 205px; }
  :not(.login-page) .ml-205 {
    margin-left: 205px; }
  :not(.login-page) .mx-205 {
    margin-left: 205px;
    margin-right: 205px; }
  :not(.login-page) .pt-205 {
    padding-top: 205px; }
  :not(.login-page) .pb-205 {
    padding-bottom: 205px; }
  :not(.login-page) .pr-205 {
    padding-right: 205px; }
  :not(.login-page) .pl-205 {
    padding-left: 205px; }
  :not(.login-page) .px-205 {
    padding-left: 205px;
    padding-right: 205px; }
  :not(.login-page) .mt-210 {
    margin-top: 210px; }
  :not(.login-page) .mb-210 {
    margin-bottom: 210px; }
  :not(.login-page) .mr-210 {
    margin-right: 210px; }
  :not(.login-page) .ml-210 {
    margin-left: 210px; }
  :not(.login-page) .mx-210 {
    margin-left: 210px;
    margin-right: 210px; }
  :not(.login-page) .pt-210 {
    padding-top: 210px; }
  :not(.login-page) .pb-210 {
    padding-bottom: 210px; }
  :not(.login-page) .pr-210 {
    padding-right: 210px; }
  :not(.login-page) .pl-210 {
    padding-left: 210px; }
  :not(.login-page) .px-210 {
    padding-left: 210px;
    padding-right: 210px; }
  :not(.login-page) .mt-215 {
    margin-top: 215px; }
  :not(.login-page) .mb-215 {
    margin-bottom: 215px; }
  :not(.login-page) .mr-215 {
    margin-right: 215px; }
  :not(.login-page) .ml-215 {
    margin-left: 215px; }
  :not(.login-page) .mx-215 {
    margin-left: 215px;
    margin-right: 215px; }
  :not(.login-page) .pt-215 {
    padding-top: 215px; }
  :not(.login-page) .pb-215 {
    padding-bottom: 215px; }
  :not(.login-page) .pr-215 {
    padding-right: 215px; }
  :not(.login-page) .pl-215 {
    padding-left: 215px; }
  :not(.login-page) .px-215 {
    padding-left: 215px;
    padding-right: 215px; }
  :not(.login-page) .mt-220 {
    margin-top: 220px; }
  :not(.login-page) .mb-220 {
    margin-bottom: 220px; }
  :not(.login-page) .mr-220 {
    margin-right: 220px; }
  :not(.login-page) .ml-220 {
    margin-left: 220px; }
  :not(.login-page) .mx-220 {
    margin-left: 220px;
    margin-right: 220px; }
  :not(.login-page) .pt-220 {
    padding-top: 220px; }
  :not(.login-page) .pb-220 {
    padding-bottom: 220px; }
  :not(.login-page) .pr-220 {
    padding-right: 220px; }
  :not(.login-page) .pl-220 {
    padding-left: 220px; }
  :not(.login-page) .px-220 {
    padding-left: 220px;
    padding-right: 220px; }
  :not(.login-page) .mt-225 {
    margin-top: 225px; }
  :not(.login-page) .mb-225 {
    margin-bottom: 225px; }
  :not(.login-page) .mr-225 {
    margin-right: 225px; }
  :not(.login-page) .ml-225 {
    margin-left: 225px; }
  :not(.login-page) .mx-225 {
    margin-left: 225px;
    margin-right: 225px; }
  :not(.login-page) .pt-225 {
    padding-top: 225px; }
  :not(.login-page) .pb-225 {
    padding-bottom: 225px; }
  :not(.login-page) .pr-225 {
    padding-right: 225px; }
  :not(.login-page) .pl-225 {
    padding-left: 225px; }
  :not(.login-page) .px-225 {
    padding-left: 225px;
    padding-right: 225px; }
  :not(.login-page) .mt-230 {
    margin-top: 230px; }
  :not(.login-page) .mb-230 {
    margin-bottom: 230px; }
  :not(.login-page) .mr-230 {
    margin-right: 230px; }
  :not(.login-page) .ml-230 {
    margin-left: 230px; }
  :not(.login-page) .mx-230 {
    margin-left: 230px;
    margin-right: 230px; }
  :not(.login-page) .pt-230 {
    padding-top: 230px; }
  :not(.login-page) .pb-230 {
    padding-bottom: 230px; }
  :not(.login-page) .pr-230 {
    padding-right: 230px; }
  :not(.login-page) .pl-230 {
    padding-left: 230px; }
  :not(.login-page) .px-230 {
    padding-left: 230px;
    padding-right: 230px; }
  :not(.login-page) .mt-235 {
    margin-top: 235px; }
  :not(.login-page) .mb-235 {
    margin-bottom: 235px; }
  :not(.login-page) .mr-235 {
    margin-right: 235px; }
  :not(.login-page) .ml-235 {
    margin-left: 235px; }
  :not(.login-page) .mx-235 {
    margin-left: 235px;
    margin-right: 235px; }
  :not(.login-page) .pt-235 {
    padding-top: 235px; }
  :not(.login-page) .pb-235 {
    padding-bottom: 235px; }
  :not(.login-page) .pr-235 {
    padding-right: 235px; }
  :not(.login-page) .pl-235 {
    padding-left: 235px; }
  :not(.login-page) .px-235 {
    padding-left: 235px;
    padding-right: 235px; }
  :not(.login-page) .mt-240 {
    margin-top: 240px; }
  :not(.login-page) .mb-240 {
    margin-bottom: 240px; }
  :not(.login-page) .mr-240 {
    margin-right: 240px; }
  :not(.login-page) .ml-240 {
    margin-left: 240px; }
  :not(.login-page) .mx-240 {
    margin-left: 240px;
    margin-right: 240px; }
  :not(.login-page) .pt-240 {
    padding-top: 240px; }
  :not(.login-page) .pb-240 {
    padding-bottom: 240px; }
  :not(.login-page) .pr-240 {
    padding-right: 240px; }
  :not(.login-page) .pl-240 {
    padding-left: 240px; }
  :not(.login-page) .px-240 {
    padding-left: 240px;
    padding-right: 240px; }
  :not(.login-page) .mt-245 {
    margin-top: 245px; }
  :not(.login-page) .mb-245 {
    margin-bottom: 245px; }
  :not(.login-page) .mr-245 {
    margin-right: 245px; }
  :not(.login-page) .ml-245 {
    margin-left: 245px; }
  :not(.login-page) .mx-245 {
    margin-left: 245px;
    margin-right: 245px; }
  :not(.login-page) .pt-245 {
    padding-top: 245px; }
  :not(.login-page) .pb-245 {
    padding-bottom: 245px; }
  :not(.login-page) .pr-245 {
    padding-right: 245px; }
  :not(.login-page) .pl-245 {
    padding-left: 245px; }
  :not(.login-page) .px-245 {
    padding-left: 245px;
    padding-right: 245px; }
  :not(.login-page) .mt-250 {
    margin-top: 250px; }
  :not(.login-page) .mb-250 {
    margin-bottom: 250px; }
  :not(.login-page) .mr-250 {
    margin-right: 250px; }
  :not(.login-page) .ml-250 {
    margin-left: 250px; }
  :not(.login-page) .mx-250 {
    margin-left: 250px;
    margin-right: 250px; }
  :not(.login-page) .pt-250 {
    padding-top: 250px; }
  :not(.login-page) .pb-250 {
    padding-bottom: 250px; }
  :not(.login-page) .pr-250 {
    padding-right: 250px; }
  :not(.login-page) .pl-250 {
    padding-left: 250px; }
  :not(.login-page) .px-250 {
    padding-left: 250px;
    padding-right: 250px; }
  :not(.login-page) .mt-255 {
    margin-top: 255px; }
  :not(.login-page) .mb-255 {
    margin-bottom: 255px; }
  :not(.login-page) .mr-255 {
    margin-right: 255px; }
  :not(.login-page) .ml-255 {
    margin-left: 255px; }
  :not(.login-page) .mx-255 {
    margin-left: 255px;
    margin-right: 255px; }
  :not(.login-page) .pt-255 {
    padding-top: 255px; }
  :not(.login-page) .pb-255 {
    padding-bottom: 255px; }
  :not(.login-page) .pr-255 {
    padding-right: 255px; }
  :not(.login-page) .pl-255 {
    padding-left: 255px; }
  :not(.login-page) .px-255 {
    padding-left: 255px;
    padding-right: 255px; }
  :not(.login-page) .mt-260 {
    margin-top: 260px; }
  :not(.login-page) .mb-260 {
    margin-bottom: 260px; }
  :not(.login-page) .mr-260 {
    margin-right: 260px; }
  :not(.login-page) .ml-260 {
    margin-left: 260px; }
  :not(.login-page) .mx-260 {
    margin-left: 260px;
    margin-right: 260px; }
  :not(.login-page) .pt-260 {
    padding-top: 260px; }
  :not(.login-page) .pb-260 {
    padding-bottom: 260px; }
  :not(.login-page) .pr-260 {
    padding-right: 260px; }
  :not(.login-page) .pl-260 {
    padding-left: 260px; }
  :not(.login-page) .px-260 {
    padding-left: 260px;
    padding-right: 260px; }
  :not(.login-page) .mt-265 {
    margin-top: 265px; }
  :not(.login-page) .mb-265 {
    margin-bottom: 265px; }
  :not(.login-page) .mr-265 {
    margin-right: 265px; }
  :not(.login-page) .ml-265 {
    margin-left: 265px; }
  :not(.login-page) .mx-265 {
    margin-left: 265px;
    margin-right: 265px; }
  :not(.login-page) .pt-265 {
    padding-top: 265px; }
  :not(.login-page) .pb-265 {
    padding-bottom: 265px; }
  :not(.login-page) .pr-265 {
    padding-right: 265px; }
  :not(.login-page) .pl-265 {
    padding-left: 265px; }
  :not(.login-page) .px-265 {
    padding-left: 265px;
    padding-right: 265px; }
  :not(.login-page) .mt-270 {
    margin-top: 270px; }
  :not(.login-page) .mb-270 {
    margin-bottom: 270px; }
  :not(.login-page) .mr-270 {
    margin-right: 270px; }
  :not(.login-page) .ml-270 {
    margin-left: 270px; }
  :not(.login-page) .mx-270 {
    margin-left: 270px;
    margin-right: 270px; }
  :not(.login-page) .pt-270 {
    padding-top: 270px; }
  :not(.login-page) .pb-270 {
    padding-bottom: 270px; }
  :not(.login-page) .pr-270 {
    padding-right: 270px; }
  :not(.login-page) .pl-270 {
    padding-left: 270px; }
  :not(.login-page) .px-270 {
    padding-left: 270px;
    padding-right: 270px; }
  :not(.login-page) .mt-275 {
    margin-top: 275px; }
  :not(.login-page) .mb-275 {
    margin-bottom: 275px; }
  :not(.login-page) .mr-275 {
    margin-right: 275px; }
  :not(.login-page) .ml-275 {
    margin-left: 275px; }
  :not(.login-page) .mx-275 {
    margin-left: 275px;
    margin-right: 275px; }
  :not(.login-page) .pt-275 {
    padding-top: 275px; }
  :not(.login-page) .pb-275 {
    padding-bottom: 275px; }
  :not(.login-page) .pr-275 {
    padding-right: 275px; }
  :not(.login-page) .pl-275 {
    padding-left: 275px; }
  :not(.login-page) .px-275 {
    padding-left: 275px;
    padding-right: 275px; }
  :not(.login-page) .mt-280 {
    margin-top: 280px; }
  :not(.login-page) .mb-280 {
    margin-bottom: 280px; }
  :not(.login-page) .mr-280 {
    margin-right: 280px; }
  :not(.login-page) .ml-280 {
    margin-left: 280px; }
  :not(.login-page) .mx-280 {
    margin-left: 280px;
    margin-right: 280px; }
  :not(.login-page) .pt-280 {
    padding-top: 280px; }
  :not(.login-page) .pb-280 {
    padding-bottom: 280px; }
  :not(.login-page) .pr-280 {
    padding-right: 280px; }
  :not(.login-page) .pl-280 {
    padding-left: 280px; }
  :not(.login-page) .px-280 {
    padding-left: 280px;
    padding-right: 280px; }
  :not(.login-page) .mt-285 {
    margin-top: 285px; }
  :not(.login-page) .mb-285 {
    margin-bottom: 285px; }
  :not(.login-page) .mr-285 {
    margin-right: 285px; }
  :not(.login-page) .ml-285 {
    margin-left: 285px; }
  :not(.login-page) .mx-285 {
    margin-left: 285px;
    margin-right: 285px; }
  :not(.login-page) .pt-285 {
    padding-top: 285px; }
  :not(.login-page) .pb-285 {
    padding-bottom: 285px; }
  :not(.login-page) .pr-285 {
    padding-right: 285px; }
  :not(.login-page) .pl-285 {
    padding-left: 285px; }
  :not(.login-page) .px-285 {
    padding-left: 285px;
    padding-right: 285px; }
  :not(.login-page) .mt-290 {
    margin-top: 290px; }
  :not(.login-page) .mb-290 {
    margin-bottom: 290px; }
  :not(.login-page) .mr-290 {
    margin-right: 290px; }
  :not(.login-page) .ml-290 {
    margin-left: 290px; }
  :not(.login-page) .mx-290 {
    margin-left: 290px;
    margin-right: 290px; }
  :not(.login-page) .pt-290 {
    padding-top: 290px; }
  :not(.login-page) .pb-290 {
    padding-bottom: 290px; }
  :not(.login-page) .pr-290 {
    padding-right: 290px; }
  :not(.login-page) .pl-290 {
    padding-left: 290px; }
  :not(.login-page) .px-290 {
    padding-left: 290px;
    padding-right: 290px; }
  :not(.login-page) .mt-295 {
    margin-top: 295px; }
  :not(.login-page) .mb-295 {
    margin-bottom: 295px; }
  :not(.login-page) .mr-295 {
    margin-right: 295px; }
  :not(.login-page) .ml-295 {
    margin-left: 295px; }
  :not(.login-page) .mx-295 {
    margin-left: 295px;
    margin-right: 295px; }
  :not(.login-page) .pt-295 {
    padding-top: 295px; }
  :not(.login-page) .pb-295 {
    padding-bottom: 295px; }
  :not(.login-page) .pr-295 {
    padding-right: 295px; }
  :not(.login-page) .pl-295 {
    padding-left: 295px; }
  :not(.login-page) .px-295 {
    padding-left: 295px;
    padding-right: 295px; }
  :not(.login-page) .mt-300 {
    margin-top: 300px; }
  :not(.login-page) .mb-300 {
    margin-bottom: 300px; }
  :not(.login-page) .mr-300 {
    margin-right: 300px; }
  :not(.login-page) .ml-300 {
    margin-left: 300px; }
  :not(.login-page) .mx-300 {
    margin-left: 300px;
    margin-right: 300px; }
  :not(.login-page) .pt-300 {
    padding-top: 300px; }
  :not(.login-page) .pb-300 {
    padding-bottom: 300px; }
  :not(.login-page) .pr-300 {
    padding-right: 300px; }
  :not(.login-page) .pl-300 {
    padding-left: 300px; }
  :not(.login-page) .px-300 {
    padding-left: 300px;
    padding-right: 300px; }
  :not(.login-page) .mt-305 {
    margin-top: 305px; }
  :not(.login-page) .mb-305 {
    margin-bottom: 305px; }
  :not(.login-page) .mr-305 {
    margin-right: 305px; }
  :not(.login-page) .ml-305 {
    margin-left: 305px; }
  :not(.login-page) .mx-305 {
    margin-left: 305px;
    margin-right: 305px; }
  :not(.login-page) .pt-305 {
    padding-top: 305px; }
  :not(.login-page) .pb-305 {
    padding-bottom: 305px; }
  :not(.login-page) .pr-305 {
    padding-right: 305px; }
  :not(.login-page) .pl-305 {
    padding-left: 305px; }
  :not(.login-page) .px-305 {
    padding-left: 305px;
    padding-right: 305px; }
  :not(.login-page) .mt-310 {
    margin-top: 310px; }
  :not(.login-page) .mb-310 {
    margin-bottom: 310px; }
  :not(.login-page) .mr-310 {
    margin-right: 310px; }
  :not(.login-page) .ml-310 {
    margin-left: 310px; }
  :not(.login-page) .mx-310 {
    margin-left: 310px;
    margin-right: 310px; }
  :not(.login-page) .pt-310 {
    padding-top: 310px; }
  :not(.login-page) .pb-310 {
    padding-bottom: 310px; }
  :not(.login-page) .pr-310 {
    padding-right: 310px; }
  :not(.login-page) .pl-310 {
    padding-left: 310px; }
  :not(.login-page) .px-310 {
    padding-left: 310px;
    padding-right: 310px; }
  :not(.login-page) .mt-315 {
    margin-top: 315px; }
  :not(.login-page) .mb-315 {
    margin-bottom: 315px; }
  :not(.login-page) .mr-315 {
    margin-right: 315px; }
  :not(.login-page) .ml-315 {
    margin-left: 315px; }
  :not(.login-page) .mx-315 {
    margin-left: 315px;
    margin-right: 315px; }
  :not(.login-page) .pt-315 {
    padding-top: 315px; }
  :not(.login-page) .pb-315 {
    padding-bottom: 315px; }
  :not(.login-page) .pr-315 {
    padding-right: 315px; }
  :not(.login-page) .pl-315 {
    padding-left: 315px; }
  :not(.login-page) .px-315 {
    padding-left: 315px;
    padding-right: 315px; }
  :not(.login-page) .mt-320 {
    margin-top: 320px; }
  :not(.login-page) .mb-320 {
    margin-bottom: 320px; }
  :not(.login-page) .mr-320 {
    margin-right: 320px; }
  :not(.login-page) .ml-320 {
    margin-left: 320px; }
  :not(.login-page) .mx-320 {
    margin-left: 320px;
    margin-right: 320px; }
  :not(.login-page) .pt-320 {
    padding-top: 320px; }
  :not(.login-page) .pb-320 {
    padding-bottom: 320px; }
  :not(.login-page) .pr-320 {
    padding-right: 320px; }
  :not(.login-page) .pl-320 {
    padding-left: 320px; }
  :not(.login-page) .px-320 {
    padding-left: 320px;
    padding-right: 320px; }
  :not(.login-page) .mt-325 {
    margin-top: 325px; }
  :not(.login-page) .mb-325 {
    margin-bottom: 325px; }
  :not(.login-page) .mr-325 {
    margin-right: 325px; }
  :not(.login-page) .ml-325 {
    margin-left: 325px; }
  :not(.login-page) .mx-325 {
    margin-left: 325px;
    margin-right: 325px; }
  :not(.login-page) .pt-325 {
    padding-top: 325px; }
  :not(.login-page) .pb-325 {
    padding-bottom: 325px; }
  :not(.login-page) .pr-325 {
    padding-right: 325px; }
  :not(.login-page) .pl-325 {
    padding-left: 325px; }
  :not(.login-page) .px-325 {
    padding-left: 325px;
    padding-right: 325px; }
  :not(.login-page) .mt-330 {
    margin-top: 330px; }
  :not(.login-page) .mb-330 {
    margin-bottom: 330px; }
  :not(.login-page) .mr-330 {
    margin-right: 330px; }
  :not(.login-page) .ml-330 {
    margin-left: 330px; }
  :not(.login-page) .mx-330 {
    margin-left: 330px;
    margin-right: 330px; }
  :not(.login-page) .pt-330 {
    padding-top: 330px; }
  :not(.login-page) .pb-330 {
    padding-bottom: 330px; }
  :not(.login-page) .pr-330 {
    padding-right: 330px; }
  :not(.login-page) .pl-330 {
    padding-left: 330px; }
  :not(.login-page) .px-330 {
    padding-left: 330px;
    padding-right: 330px; }
  :not(.login-page) .mt-335 {
    margin-top: 335px; }
  :not(.login-page) .mb-335 {
    margin-bottom: 335px; }
  :not(.login-page) .mr-335 {
    margin-right: 335px; }
  :not(.login-page) .ml-335 {
    margin-left: 335px; }
  :not(.login-page) .mx-335 {
    margin-left: 335px;
    margin-right: 335px; }
  :not(.login-page) .pt-335 {
    padding-top: 335px; }
  :not(.login-page) .pb-335 {
    padding-bottom: 335px; }
  :not(.login-page) .pr-335 {
    padding-right: 335px; }
  :not(.login-page) .pl-335 {
    padding-left: 335px; }
  :not(.login-page) .px-335 {
    padding-left: 335px;
    padding-right: 335px; }
  :not(.login-page) .mt-340 {
    margin-top: 340px; }
  :not(.login-page) .mb-340 {
    margin-bottom: 340px; }
  :not(.login-page) .mr-340 {
    margin-right: 340px; }
  :not(.login-page) .ml-340 {
    margin-left: 340px; }
  :not(.login-page) .mx-340 {
    margin-left: 340px;
    margin-right: 340px; }
  :not(.login-page) .pt-340 {
    padding-top: 340px; }
  :not(.login-page) .pb-340 {
    padding-bottom: 340px; }
  :not(.login-page) .pr-340 {
    padding-right: 340px; }
  :not(.login-page) .pl-340 {
    padding-left: 340px; }
  :not(.login-page) .px-340 {
    padding-left: 340px;
    padding-right: 340px; }
  :not(.login-page) .mt-345 {
    margin-top: 345px; }
  :not(.login-page) .mb-345 {
    margin-bottom: 345px; }
  :not(.login-page) .mr-345 {
    margin-right: 345px; }
  :not(.login-page) .ml-345 {
    margin-left: 345px; }
  :not(.login-page) .mx-345 {
    margin-left: 345px;
    margin-right: 345px; }
  :not(.login-page) .pt-345 {
    padding-top: 345px; }
  :not(.login-page) .pb-345 {
    padding-bottom: 345px; }
  :not(.login-page) .pr-345 {
    padding-right: 345px; }
  :not(.login-page) .pl-345 {
    padding-left: 345px; }
  :not(.login-page) .px-345 {
    padding-left: 345px;
    padding-right: 345px; }
  :not(.login-page) .mt-350 {
    margin-top: 350px; }
  :not(.login-page) .mb-350 {
    margin-bottom: 350px; }
  :not(.login-page) .mr-350 {
    margin-right: 350px; }
  :not(.login-page) .ml-350 {
    margin-left: 350px; }
  :not(.login-page) .mx-350 {
    margin-left: 350px;
    margin-right: 350px; }
  :not(.login-page) .pt-350 {
    padding-top: 350px; }
  :not(.login-page) .pb-350 {
    padding-bottom: 350px; }
  :not(.login-page) .pr-350 {
    padding-right: 350px; }
  :not(.login-page) .pl-350 {
    padding-left: 350px; }
  :not(.login-page) .px-350 {
    padding-left: 350px;
    padding-right: 350px; }
  :not(.login-page) .mt-355 {
    margin-top: 355px; }
  :not(.login-page) .mb-355 {
    margin-bottom: 355px; }
  :not(.login-page) .mr-355 {
    margin-right: 355px; }
  :not(.login-page) .ml-355 {
    margin-left: 355px; }
  :not(.login-page) .mx-355 {
    margin-left: 355px;
    margin-right: 355px; }
  :not(.login-page) .pt-355 {
    padding-top: 355px; }
  :not(.login-page) .pb-355 {
    padding-bottom: 355px; }
  :not(.login-page) .pr-355 {
    padding-right: 355px; }
  :not(.login-page) .pl-355 {
    padding-left: 355px; }
  :not(.login-page) .px-355 {
    padding-left: 355px;
    padding-right: 355px; }
  :not(.login-page) .mt-360 {
    margin-top: 360px; }
  :not(.login-page) .mb-360 {
    margin-bottom: 360px; }
  :not(.login-page) .mr-360 {
    margin-right: 360px; }
  :not(.login-page) .ml-360 {
    margin-left: 360px; }
  :not(.login-page) .mx-360 {
    margin-left: 360px;
    margin-right: 360px; }
  :not(.login-page) .pt-360 {
    padding-top: 360px; }
  :not(.login-page) .pb-360 {
    padding-bottom: 360px; }
  :not(.login-page) .pr-360 {
    padding-right: 360px; }
  :not(.login-page) .pl-360 {
    padding-left: 360px; }
  :not(.login-page) .px-360 {
    padding-left: 360px;
    padding-right: 360px; }
  :not(.login-page) .mt-365 {
    margin-top: 365px; }
  :not(.login-page) .mb-365 {
    margin-bottom: 365px; }
  :not(.login-page) .mr-365 {
    margin-right: 365px; }
  :not(.login-page) .ml-365 {
    margin-left: 365px; }
  :not(.login-page) .mx-365 {
    margin-left: 365px;
    margin-right: 365px; }
  :not(.login-page) .pt-365 {
    padding-top: 365px; }
  :not(.login-page) .pb-365 {
    padding-bottom: 365px; }
  :not(.login-page) .pr-365 {
    padding-right: 365px; }
  :not(.login-page) .pl-365 {
    padding-left: 365px; }
  :not(.login-page) .px-365 {
    padding-left: 365px;
    padding-right: 365px; }
  :not(.login-page) .mt-370 {
    margin-top: 370px; }
  :not(.login-page) .mb-370 {
    margin-bottom: 370px; }
  :not(.login-page) .mr-370 {
    margin-right: 370px; }
  :not(.login-page) .ml-370 {
    margin-left: 370px; }
  :not(.login-page) .mx-370 {
    margin-left: 370px;
    margin-right: 370px; }
  :not(.login-page) .pt-370 {
    padding-top: 370px; }
  :not(.login-page) .pb-370 {
    padding-bottom: 370px; }
  :not(.login-page) .pr-370 {
    padding-right: 370px; }
  :not(.login-page) .pl-370 {
    padding-left: 370px; }
  :not(.login-page) .px-370 {
    padding-left: 370px;
    padding-right: 370px; }
  :not(.login-page) .mt-375 {
    margin-top: 375px; }
  :not(.login-page) .mb-375 {
    margin-bottom: 375px; }
  :not(.login-page) .mr-375 {
    margin-right: 375px; }
  :not(.login-page) .ml-375 {
    margin-left: 375px; }
  :not(.login-page) .mx-375 {
    margin-left: 375px;
    margin-right: 375px; }
  :not(.login-page) .pt-375 {
    padding-top: 375px; }
  :not(.login-page) .pb-375 {
    padding-bottom: 375px; }
  :not(.login-page) .pr-375 {
    padding-right: 375px; }
  :not(.login-page) .pl-375 {
    padding-left: 375px; }
  :not(.login-page) .px-375 {
    padding-left: 375px;
    padding-right: 375px; }
  :not(.login-page) .mt-380 {
    margin-top: 380px; }
  :not(.login-page) .mb-380 {
    margin-bottom: 380px; }
  :not(.login-page) .mr-380 {
    margin-right: 380px; }
  :not(.login-page) .ml-380 {
    margin-left: 380px; }
  :not(.login-page) .mx-380 {
    margin-left: 380px;
    margin-right: 380px; }
  :not(.login-page) .pt-380 {
    padding-top: 380px; }
  :not(.login-page) .pb-380 {
    padding-bottom: 380px; }
  :not(.login-page) .pr-380 {
    padding-right: 380px; }
  :not(.login-page) .pl-380 {
    padding-left: 380px; }
  :not(.login-page) .px-380 {
    padding-left: 380px;
    padding-right: 380px; }
  :not(.login-page) .mt-385 {
    margin-top: 385px; }
  :not(.login-page) .mb-385 {
    margin-bottom: 385px; }
  :not(.login-page) .mr-385 {
    margin-right: 385px; }
  :not(.login-page) .ml-385 {
    margin-left: 385px; }
  :not(.login-page) .mx-385 {
    margin-left: 385px;
    margin-right: 385px; }
  :not(.login-page) .pt-385 {
    padding-top: 385px; }
  :not(.login-page) .pb-385 {
    padding-bottom: 385px; }
  :not(.login-page) .pr-385 {
    padding-right: 385px; }
  :not(.login-page) .pl-385 {
    padding-left: 385px; }
  :not(.login-page) .px-385 {
    padding-left: 385px;
    padding-right: 385px; }
  :not(.login-page) .mt-390 {
    margin-top: 390px; }
  :not(.login-page) .mb-390 {
    margin-bottom: 390px; }
  :not(.login-page) .mr-390 {
    margin-right: 390px; }
  :not(.login-page) .ml-390 {
    margin-left: 390px; }
  :not(.login-page) .mx-390 {
    margin-left: 390px;
    margin-right: 390px; }
  :not(.login-page) .pt-390 {
    padding-top: 390px; }
  :not(.login-page) .pb-390 {
    padding-bottom: 390px; }
  :not(.login-page) .pr-390 {
    padding-right: 390px; }
  :not(.login-page) .pl-390 {
    padding-left: 390px; }
  :not(.login-page) .px-390 {
    padding-left: 390px;
    padding-right: 390px; }
  :not(.login-page) .mt-395 {
    margin-top: 395px; }
  :not(.login-page) .mb-395 {
    margin-bottom: 395px; }
  :not(.login-page) .mr-395 {
    margin-right: 395px; }
  :not(.login-page) .ml-395 {
    margin-left: 395px; }
  :not(.login-page) .mx-395 {
    margin-left: 395px;
    margin-right: 395px; }
  :not(.login-page) .pt-395 {
    padding-top: 395px; }
  :not(.login-page) .pb-395 {
    padding-bottom: 395px; }
  :not(.login-page) .pr-395 {
    padding-right: 395px; }
  :not(.login-page) .pl-395 {
    padding-left: 395px; }
  :not(.login-page) .px-395 {
    padding-left: 395px;
    padding-right: 395px; }
  :not(.login-page) .mt-400 {
    margin-top: 400px; }
  :not(.login-page) .mb-400 {
    margin-bottom: 400px; }
  :not(.login-page) .mr-400 {
    margin-right: 400px; }
  :not(.login-page) .ml-400 {
    margin-left: 400px; }
  :not(.login-page) .mx-400 {
    margin-left: 400px;
    margin-right: 400px; }
  :not(.login-page) .pt-400 {
    padding-top: 400px; }
  :not(.login-page) .pb-400 {
    padding-bottom: 400px; }
  :not(.login-page) .pr-400 {
    padding-right: 400px; }
  :not(.login-page) .pl-400 {
    padding-left: 400px; }
  :not(.login-page) .px-400 {
    padding-left: 400px;
    padding-right: 400px; }
  :not(.login-page) .mt-405 {
    margin-top: 405px; }
  :not(.login-page) .mb-405 {
    margin-bottom: 405px; }
  :not(.login-page) .mr-405 {
    margin-right: 405px; }
  :not(.login-page) .ml-405 {
    margin-left: 405px; }
  :not(.login-page) .mx-405 {
    margin-left: 405px;
    margin-right: 405px; }
  :not(.login-page) .pt-405 {
    padding-top: 405px; }
  :not(.login-page) .pb-405 {
    padding-bottom: 405px; }
  :not(.login-page) .pr-405 {
    padding-right: 405px; }
  :not(.login-page) .pl-405 {
    padding-left: 405px; }
  :not(.login-page) .px-405 {
    padding-left: 405px;
    padding-right: 405px; }
  :not(.login-page) .mt-410 {
    margin-top: 410px; }
  :not(.login-page) .mb-410 {
    margin-bottom: 410px; }
  :not(.login-page) .mr-410 {
    margin-right: 410px; }
  :not(.login-page) .ml-410 {
    margin-left: 410px; }
  :not(.login-page) .mx-410 {
    margin-left: 410px;
    margin-right: 410px; }
  :not(.login-page) .pt-410 {
    padding-top: 410px; }
  :not(.login-page) .pb-410 {
    padding-bottom: 410px; }
  :not(.login-page) .pr-410 {
    padding-right: 410px; }
  :not(.login-page) .pl-410 {
    padding-left: 410px; }
  :not(.login-page) .px-410 {
    padding-left: 410px;
    padding-right: 410px; }
  :not(.login-page) .mt-415 {
    margin-top: 415px; }
  :not(.login-page) .mb-415 {
    margin-bottom: 415px; }
  :not(.login-page) .mr-415 {
    margin-right: 415px; }
  :not(.login-page) .ml-415 {
    margin-left: 415px; }
  :not(.login-page) .mx-415 {
    margin-left: 415px;
    margin-right: 415px; }
  :not(.login-page) .pt-415 {
    padding-top: 415px; }
  :not(.login-page) .pb-415 {
    padding-bottom: 415px; }
  :not(.login-page) .pr-415 {
    padding-right: 415px; }
  :not(.login-page) .pl-415 {
    padding-left: 415px; }
  :not(.login-page) .px-415 {
    padding-left: 415px;
    padding-right: 415px; }
  :not(.login-page) .mt-420 {
    margin-top: 420px; }
  :not(.login-page) .mb-420 {
    margin-bottom: 420px; }
  :not(.login-page) .mr-420 {
    margin-right: 420px; }
  :not(.login-page) .ml-420 {
    margin-left: 420px; }
  :not(.login-page) .mx-420 {
    margin-left: 420px;
    margin-right: 420px; }
  :not(.login-page) .pt-420 {
    padding-top: 420px; }
  :not(.login-page) .pb-420 {
    padding-bottom: 420px; }
  :not(.login-page) .pr-420 {
    padding-right: 420px; }
  :not(.login-page) .pl-420 {
    padding-left: 420px; }
  :not(.login-page) .px-420 {
    padding-left: 420px;
    padding-right: 420px; }
  :not(.login-page) .mt-425 {
    margin-top: 425px; }
  :not(.login-page) .mb-425 {
    margin-bottom: 425px; }
  :not(.login-page) .mr-425 {
    margin-right: 425px; }
  :not(.login-page) .ml-425 {
    margin-left: 425px; }
  :not(.login-page) .mx-425 {
    margin-left: 425px;
    margin-right: 425px; }
  :not(.login-page) .pt-425 {
    padding-top: 425px; }
  :not(.login-page) .pb-425 {
    padding-bottom: 425px; }
  :not(.login-page) .pr-425 {
    padding-right: 425px; }
  :not(.login-page) .pl-425 {
    padding-left: 425px; }
  :not(.login-page) .px-425 {
    padding-left: 425px;
    padding-right: 425px; }
  :not(.login-page) .mt-430 {
    margin-top: 430px; }
  :not(.login-page) .mb-430 {
    margin-bottom: 430px; }
  :not(.login-page) .mr-430 {
    margin-right: 430px; }
  :not(.login-page) .ml-430 {
    margin-left: 430px; }
  :not(.login-page) .mx-430 {
    margin-left: 430px;
    margin-right: 430px; }
  :not(.login-page) .pt-430 {
    padding-top: 430px; }
  :not(.login-page) .pb-430 {
    padding-bottom: 430px; }
  :not(.login-page) .pr-430 {
    padding-right: 430px; }
  :not(.login-page) .pl-430 {
    padding-left: 430px; }
  :not(.login-page) .px-430 {
    padding-left: 430px;
    padding-right: 430px; }
  :not(.login-page) .mt-435 {
    margin-top: 435px; }
  :not(.login-page) .mb-435 {
    margin-bottom: 435px; }
  :not(.login-page) .mr-435 {
    margin-right: 435px; }
  :not(.login-page) .ml-435 {
    margin-left: 435px; }
  :not(.login-page) .mx-435 {
    margin-left: 435px;
    margin-right: 435px; }
  :not(.login-page) .pt-435 {
    padding-top: 435px; }
  :not(.login-page) .pb-435 {
    padding-bottom: 435px; }
  :not(.login-page) .pr-435 {
    padding-right: 435px; }
  :not(.login-page) .pl-435 {
    padding-left: 435px; }
  :not(.login-page) .px-435 {
    padding-left: 435px;
    padding-right: 435px; }
  :not(.login-page) .mt-440 {
    margin-top: 440px; }
  :not(.login-page) .mb-440 {
    margin-bottom: 440px; }
  :not(.login-page) .mr-440 {
    margin-right: 440px; }
  :not(.login-page) .ml-440 {
    margin-left: 440px; }
  :not(.login-page) .mx-440 {
    margin-left: 440px;
    margin-right: 440px; }
  :not(.login-page) .pt-440 {
    padding-top: 440px; }
  :not(.login-page) .pb-440 {
    padding-bottom: 440px; }
  :not(.login-page) .pr-440 {
    padding-right: 440px; }
  :not(.login-page) .pl-440 {
    padding-left: 440px; }
  :not(.login-page) .px-440 {
    padding-left: 440px;
    padding-right: 440px; }
  :not(.login-page) .mt-445 {
    margin-top: 445px; }
  :not(.login-page) .mb-445 {
    margin-bottom: 445px; }
  :not(.login-page) .mr-445 {
    margin-right: 445px; }
  :not(.login-page) .ml-445 {
    margin-left: 445px; }
  :not(.login-page) .mx-445 {
    margin-left: 445px;
    margin-right: 445px; }
  :not(.login-page) .pt-445 {
    padding-top: 445px; }
  :not(.login-page) .pb-445 {
    padding-bottom: 445px; }
  :not(.login-page) .pr-445 {
    padding-right: 445px; }
  :not(.login-page) .pl-445 {
    padding-left: 445px; }
  :not(.login-page) .px-445 {
    padding-left: 445px;
    padding-right: 445px; }
  :not(.login-page) .mt-450 {
    margin-top: 450px; }
  :not(.login-page) .mb-450 {
    margin-bottom: 450px; }
  :not(.login-page) .mr-450 {
    margin-right: 450px; }
  :not(.login-page) .ml-450 {
    margin-left: 450px; }
  :not(.login-page) .mx-450 {
    margin-left: 450px;
    margin-right: 450px; }
  :not(.login-page) .pt-450 {
    padding-top: 450px; }
  :not(.login-page) .pb-450 {
    padding-bottom: 450px; }
  :not(.login-page) .pr-450 {
    padding-right: 450px; }
  :not(.login-page) .pl-450 {
    padding-left: 450px; }
  :not(.login-page) .px-450 {
    padding-left: 450px;
    padding-right: 450px; }
  :not(.login-page) .mt-455 {
    margin-top: 455px; }
  :not(.login-page) .mb-455 {
    margin-bottom: 455px; }
  :not(.login-page) .mr-455 {
    margin-right: 455px; }
  :not(.login-page) .ml-455 {
    margin-left: 455px; }
  :not(.login-page) .mx-455 {
    margin-left: 455px;
    margin-right: 455px; }
  :not(.login-page) .pt-455 {
    padding-top: 455px; }
  :not(.login-page) .pb-455 {
    padding-bottom: 455px; }
  :not(.login-page) .pr-455 {
    padding-right: 455px; }
  :not(.login-page) .pl-455 {
    padding-left: 455px; }
  :not(.login-page) .px-455 {
    padding-left: 455px;
    padding-right: 455px; }
  :not(.login-page) .mt-460 {
    margin-top: 460px; }
  :not(.login-page) .mb-460 {
    margin-bottom: 460px; }
  :not(.login-page) .mr-460 {
    margin-right: 460px; }
  :not(.login-page) .ml-460 {
    margin-left: 460px; }
  :not(.login-page) .mx-460 {
    margin-left: 460px;
    margin-right: 460px; }
  :not(.login-page) .pt-460 {
    padding-top: 460px; }
  :not(.login-page) .pb-460 {
    padding-bottom: 460px; }
  :not(.login-page) .pr-460 {
    padding-right: 460px; }
  :not(.login-page) .pl-460 {
    padding-left: 460px; }
  :not(.login-page) .px-460 {
    padding-left: 460px;
    padding-right: 460px; }
  :not(.login-page) .mt-465 {
    margin-top: 465px; }
  :not(.login-page) .mb-465 {
    margin-bottom: 465px; }
  :not(.login-page) .mr-465 {
    margin-right: 465px; }
  :not(.login-page) .ml-465 {
    margin-left: 465px; }
  :not(.login-page) .mx-465 {
    margin-left: 465px;
    margin-right: 465px; }
  :not(.login-page) .pt-465 {
    padding-top: 465px; }
  :not(.login-page) .pb-465 {
    padding-bottom: 465px; }
  :not(.login-page) .pr-465 {
    padding-right: 465px; }
  :not(.login-page) .pl-465 {
    padding-left: 465px; }
  :not(.login-page) .px-465 {
    padding-left: 465px;
    padding-right: 465px; }
  :not(.login-page) .mt-470 {
    margin-top: 470px; }
  :not(.login-page) .mb-470 {
    margin-bottom: 470px; }
  :not(.login-page) .mr-470 {
    margin-right: 470px; }
  :not(.login-page) .ml-470 {
    margin-left: 470px; }
  :not(.login-page) .mx-470 {
    margin-left: 470px;
    margin-right: 470px; }
  :not(.login-page) .pt-470 {
    padding-top: 470px; }
  :not(.login-page) .pb-470 {
    padding-bottom: 470px; }
  :not(.login-page) .pr-470 {
    padding-right: 470px; }
  :not(.login-page) .pl-470 {
    padding-left: 470px; }
  :not(.login-page) .px-470 {
    padding-left: 470px;
    padding-right: 470px; }
  :not(.login-page) .mt-475 {
    margin-top: 475px; }
  :not(.login-page) .mb-475 {
    margin-bottom: 475px; }
  :not(.login-page) .mr-475 {
    margin-right: 475px; }
  :not(.login-page) .ml-475 {
    margin-left: 475px; }
  :not(.login-page) .mx-475 {
    margin-left: 475px;
    margin-right: 475px; }
  :not(.login-page) .pt-475 {
    padding-top: 475px; }
  :not(.login-page) .pb-475 {
    padding-bottom: 475px; }
  :not(.login-page) .pr-475 {
    padding-right: 475px; }
  :not(.login-page) .pl-475 {
    padding-left: 475px; }
  :not(.login-page) .px-475 {
    padding-left: 475px;
    padding-right: 475px; }
  :not(.login-page) .mt-480 {
    margin-top: 480px; }
  :not(.login-page) .mb-480 {
    margin-bottom: 480px; }
  :not(.login-page) .mr-480 {
    margin-right: 480px; }
  :not(.login-page) .ml-480 {
    margin-left: 480px; }
  :not(.login-page) .mx-480 {
    margin-left: 480px;
    margin-right: 480px; }
  :not(.login-page) .pt-480 {
    padding-top: 480px; }
  :not(.login-page) .pb-480 {
    padding-bottom: 480px; }
  :not(.login-page) .pr-480 {
    padding-right: 480px; }
  :not(.login-page) .pl-480 {
    padding-left: 480px; }
  :not(.login-page) .px-480 {
    padding-left: 480px;
    padding-right: 480px; }
  :not(.login-page) .mt-485 {
    margin-top: 485px; }
  :not(.login-page) .mb-485 {
    margin-bottom: 485px; }
  :not(.login-page) .mr-485 {
    margin-right: 485px; }
  :not(.login-page) .ml-485 {
    margin-left: 485px; }
  :not(.login-page) .mx-485 {
    margin-left: 485px;
    margin-right: 485px; }
  :not(.login-page) .pt-485 {
    padding-top: 485px; }
  :not(.login-page) .pb-485 {
    padding-bottom: 485px; }
  :not(.login-page) .pr-485 {
    padding-right: 485px; }
  :not(.login-page) .pl-485 {
    padding-left: 485px; }
  :not(.login-page) .px-485 {
    padding-left: 485px;
    padding-right: 485px; }
  :not(.login-page) .mt-490 {
    margin-top: 490px; }
  :not(.login-page) .mb-490 {
    margin-bottom: 490px; }
  :not(.login-page) .mr-490 {
    margin-right: 490px; }
  :not(.login-page) .ml-490 {
    margin-left: 490px; }
  :not(.login-page) .mx-490 {
    margin-left: 490px;
    margin-right: 490px; }
  :not(.login-page) .pt-490 {
    padding-top: 490px; }
  :not(.login-page) .pb-490 {
    padding-bottom: 490px; }
  :not(.login-page) .pr-490 {
    padding-right: 490px; }
  :not(.login-page) .pl-490 {
    padding-left: 490px; }
  :not(.login-page) .px-490 {
    padding-left: 490px;
    padding-right: 490px; }
  :not(.login-page) .mt-495 {
    margin-top: 495px; }
  :not(.login-page) .mb-495 {
    margin-bottom: 495px; }
  :not(.login-page) .mr-495 {
    margin-right: 495px; }
  :not(.login-page) .ml-495 {
    margin-left: 495px; }
  :not(.login-page) .mx-495 {
    margin-left: 495px;
    margin-right: 495px; }
  :not(.login-page) .pt-495 {
    padding-top: 495px; }
  :not(.login-page) .pb-495 {
    padding-bottom: 495px; }
  :not(.login-page) .pr-495 {
    padding-right: 495px; }
  :not(.login-page) .pl-495 {
    padding-left: 495px; }
  :not(.login-page) .px-495 {
    padding-left: 495px;
    padding-right: 495px; }
  :not(.login-page) .mt-500 {
    margin-top: 500px; }
  :not(.login-page) .mb-500 {
    margin-bottom: 500px; }
  :not(.login-page) .mr-500 {
    margin-right: 500px; }
  :not(.login-page) .ml-500 {
    margin-left: 500px; }
  :not(.login-page) .mx-500 {
    margin-left: 500px;
    margin-right: 500px; }
  :not(.login-page) .pt-500 {
    padding-top: 500px; }
  :not(.login-page) .pb-500 {
    padding-bottom: 500px; }
  :not(.login-page) .pr-500 {
    padding-right: 500px; }
  :not(.login-page) .pl-500 {
    padding-left: 500px; }
  :not(.login-page) .px-500 {
    padding-left: 500px;
    padding-right: 500px; }
  :not(.login-page) .mt-505 {
    margin-top: 505px; }
  :not(.login-page) .mb-505 {
    margin-bottom: 505px; }
  :not(.login-page) .mr-505 {
    margin-right: 505px; }
  :not(.login-page) .ml-505 {
    margin-left: 505px; }
  :not(.login-page) .mx-505 {
    margin-left: 505px;
    margin-right: 505px; }
  :not(.login-page) .pt-505 {
    padding-top: 505px; }
  :not(.login-page) .pb-505 {
    padding-bottom: 505px; }
  :not(.login-page) .pr-505 {
    padding-right: 505px; }
  :not(.login-page) .pl-505 {
    padding-left: 505px; }
  :not(.login-page) .px-505 {
    padding-left: 505px;
    padding-right: 505px; }
  :not(.login-page) .mt-510 {
    margin-top: 510px; }
  :not(.login-page) .mb-510 {
    margin-bottom: 510px; }
  :not(.login-page) .mr-510 {
    margin-right: 510px; }
  :not(.login-page) .ml-510 {
    margin-left: 510px; }
  :not(.login-page) .mx-510 {
    margin-left: 510px;
    margin-right: 510px; }
  :not(.login-page) .pt-510 {
    padding-top: 510px; }
  :not(.login-page) .pb-510 {
    padding-bottom: 510px; }
  :not(.login-page) .pr-510 {
    padding-right: 510px; }
  :not(.login-page) .pl-510 {
    padding-left: 510px; }
  :not(.login-page) .px-510 {
    padding-left: 510px;
    padding-right: 510px; }
  :not(.login-page) .mt-515 {
    margin-top: 515px; }
  :not(.login-page) .mb-515 {
    margin-bottom: 515px; }
  :not(.login-page) .mr-515 {
    margin-right: 515px; }
  :not(.login-page) .ml-515 {
    margin-left: 515px; }
  :not(.login-page) .mx-515 {
    margin-left: 515px;
    margin-right: 515px; }
  :not(.login-page) .pt-515 {
    padding-top: 515px; }
  :not(.login-page) .pb-515 {
    padding-bottom: 515px; }
  :not(.login-page) .pr-515 {
    padding-right: 515px; }
  :not(.login-page) .pl-515 {
    padding-left: 515px; }
  :not(.login-page) .px-515 {
    padding-left: 515px;
    padding-right: 515px; }
  :not(.login-page) .mt-520 {
    margin-top: 520px; }
  :not(.login-page) .mb-520 {
    margin-bottom: 520px; }
  :not(.login-page) .mr-520 {
    margin-right: 520px; }
  :not(.login-page) .ml-520 {
    margin-left: 520px; }
  :not(.login-page) .mx-520 {
    margin-left: 520px;
    margin-right: 520px; }
  :not(.login-page) .pt-520 {
    padding-top: 520px; }
  :not(.login-page) .pb-520 {
    padding-bottom: 520px; }
  :not(.login-page) .pr-520 {
    padding-right: 520px; }
  :not(.login-page) .pl-520 {
    padding-left: 520px; }
  :not(.login-page) .px-520 {
    padding-left: 520px;
    padding-right: 520px; }
  :not(.login-page) .mt-525 {
    margin-top: 525px; }
  :not(.login-page) .mb-525 {
    margin-bottom: 525px; }
  :not(.login-page) .mr-525 {
    margin-right: 525px; }
  :not(.login-page) .ml-525 {
    margin-left: 525px; }
  :not(.login-page) .mx-525 {
    margin-left: 525px;
    margin-right: 525px; }
  :not(.login-page) .pt-525 {
    padding-top: 525px; }
  :not(.login-page) .pb-525 {
    padding-bottom: 525px; }
  :not(.login-page) .pr-525 {
    padding-right: 525px; }
  :not(.login-page) .pl-525 {
    padding-left: 525px; }
  :not(.login-page) .px-525 {
    padding-left: 525px;
    padding-right: 525px; }
  :not(.login-page) .mt-530 {
    margin-top: 530px; }
  :not(.login-page) .mb-530 {
    margin-bottom: 530px; }
  :not(.login-page) .mr-530 {
    margin-right: 530px; }
  :not(.login-page) .ml-530 {
    margin-left: 530px; }
  :not(.login-page) .mx-530 {
    margin-left: 530px;
    margin-right: 530px; }
  :not(.login-page) .pt-530 {
    padding-top: 530px; }
  :not(.login-page) .pb-530 {
    padding-bottom: 530px; }
  :not(.login-page) .pr-530 {
    padding-right: 530px; }
  :not(.login-page) .pl-530 {
    padding-left: 530px; }
  :not(.login-page) .px-530 {
    padding-left: 530px;
    padding-right: 530px; }
  :not(.login-page) .mt-535 {
    margin-top: 535px; }
  :not(.login-page) .mb-535 {
    margin-bottom: 535px; }
  :not(.login-page) .mr-535 {
    margin-right: 535px; }
  :not(.login-page) .ml-535 {
    margin-left: 535px; }
  :not(.login-page) .mx-535 {
    margin-left: 535px;
    margin-right: 535px; }
  :not(.login-page) .pt-535 {
    padding-top: 535px; }
  :not(.login-page) .pb-535 {
    padding-bottom: 535px; }
  :not(.login-page) .pr-535 {
    padding-right: 535px; }
  :not(.login-page) .pl-535 {
    padding-left: 535px; }
  :not(.login-page) .px-535 {
    padding-left: 535px;
    padding-right: 535px; }
  :not(.login-page) .mt-540 {
    margin-top: 540px; }
  :not(.login-page) .mb-540 {
    margin-bottom: 540px; }
  :not(.login-page) .mr-540 {
    margin-right: 540px; }
  :not(.login-page) .ml-540 {
    margin-left: 540px; }
  :not(.login-page) .mx-540 {
    margin-left: 540px;
    margin-right: 540px; }
  :not(.login-page) .pt-540 {
    padding-top: 540px; }
  :not(.login-page) .pb-540 {
    padding-bottom: 540px; }
  :not(.login-page) .pr-540 {
    padding-right: 540px; }
  :not(.login-page) .pl-540 {
    padding-left: 540px; }
  :not(.login-page) .px-540 {
    padding-left: 540px;
    padding-right: 540px; }
  :not(.login-page) .mt-545 {
    margin-top: 545px; }
  :not(.login-page) .mb-545 {
    margin-bottom: 545px; }
  :not(.login-page) .mr-545 {
    margin-right: 545px; }
  :not(.login-page) .ml-545 {
    margin-left: 545px; }
  :not(.login-page) .mx-545 {
    margin-left: 545px;
    margin-right: 545px; }
  :not(.login-page) .pt-545 {
    padding-top: 545px; }
  :not(.login-page) .pb-545 {
    padding-bottom: 545px; }
  :not(.login-page) .pr-545 {
    padding-right: 545px; }
  :not(.login-page) .pl-545 {
    padding-left: 545px; }
  :not(.login-page) .px-545 {
    padding-left: 545px;
    padding-right: 545px; }
  :not(.login-page) .mt-550 {
    margin-top: 550px; }
  :not(.login-page) .mb-550 {
    margin-bottom: 550px; }
  :not(.login-page) .mr-550 {
    margin-right: 550px; }
  :not(.login-page) .ml-550 {
    margin-left: 550px; }
  :not(.login-page) .mx-550 {
    margin-left: 550px;
    margin-right: 550px; }
  :not(.login-page) .pt-550 {
    padding-top: 550px; }
  :not(.login-page) .pb-550 {
    padding-bottom: 550px; }
  :not(.login-page) .pr-550 {
    padding-right: 550px; }
  :not(.login-page) .pl-550 {
    padding-left: 550px; }
  :not(.login-page) .px-550 {
    padding-left: 550px;
    padding-right: 550px; }
  :not(.login-page) .mt-555 {
    margin-top: 555px; }
  :not(.login-page) .mb-555 {
    margin-bottom: 555px; }
  :not(.login-page) .mr-555 {
    margin-right: 555px; }
  :not(.login-page) .ml-555 {
    margin-left: 555px; }
  :not(.login-page) .mx-555 {
    margin-left: 555px;
    margin-right: 555px; }
  :not(.login-page) .pt-555 {
    padding-top: 555px; }
  :not(.login-page) .pb-555 {
    padding-bottom: 555px; }
  :not(.login-page) .pr-555 {
    padding-right: 555px; }
  :not(.login-page) .pl-555 {
    padding-left: 555px; }
  :not(.login-page) .px-555 {
    padding-left: 555px;
    padding-right: 555px; }
  :not(.login-page) .mt-560 {
    margin-top: 560px; }
  :not(.login-page) .mb-560 {
    margin-bottom: 560px; }
  :not(.login-page) .mr-560 {
    margin-right: 560px; }
  :not(.login-page) .ml-560 {
    margin-left: 560px; }
  :not(.login-page) .mx-560 {
    margin-left: 560px;
    margin-right: 560px; }
  :not(.login-page) .pt-560 {
    padding-top: 560px; }
  :not(.login-page) .pb-560 {
    padding-bottom: 560px; }
  :not(.login-page) .pr-560 {
    padding-right: 560px; }
  :not(.login-page) .pl-560 {
    padding-left: 560px; }
  :not(.login-page) .px-560 {
    padding-left: 560px;
    padding-right: 560px; }
  :not(.login-page) .mt-565 {
    margin-top: 565px; }
  :not(.login-page) .mb-565 {
    margin-bottom: 565px; }
  :not(.login-page) .mr-565 {
    margin-right: 565px; }
  :not(.login-page) .ml-565 {
    margin-left: 565px; }
  :not(.login-page) .mx-565 {
    margin-left: 565px;
    margin-right: 565px; }
  :not(.login-page) .pt-565 {
    padding-top: 565px; }
  :not(.login-page) .pb-565 {
    padding-bottom: 565px; }
  :not(.login-page) .pr-565 {
    padding-right: 565px; }
  :not(.login-page) .pl-565 {
    padding-left: 565px; }
  :not(.login-page) .px-565 {
    padding-left: 565px;
    padding-right: 565px; }
  :not(.login-page) .mt-570 {
    margin-top: 570px; }
  :not(.login-page) .mb-570 {
    margin-bottom: 570px; }
  :not(.login-page) .mr-570 {
    margin-right: 570px; }
  :not(.login-page) .ml-570 {
    margin-left: 570px; }
  :not(.login-page) .mx-570 {
    margin-left: 570px;
    margin-right: 570px; }
  :not(.login-page) .pt-570 {
    padding-top: 570px; }
  :not(.login-page) .pb-570 {
    padding-bottom: 570px; }
  :not(.login-page) .pr-570 {
    padding-right: 570px; }
  :not(.login-page) .pl-570 {
    padding-left: 570px; }
  :not(.login-page) .px-570 {
    padding-left: 570px;
    padding-right: 570px; }
  :not(.login-page) .mt-575 {
    margin-top: 575px; }
  :not(.login-page) .mb-575 {
    margin-bottom: 575px; }
  :not(.login-page) .mr-575 {
    margin-right: 575px; }
  :not(.login-page) .ml-575 {
    margin-left: 575px; }
  :not(.login-page) .mx-575 {
    margin-left: 575px;
    margin-right: 575px; }
  :not(.login-page) .pt-575 {
    padding-top: 575px; }
  :not(.login-page) .pb-575 {
    padding-bottom: 575px; }
  :not(.login-page) .pr-575 {
    padding-right: 575px; }
  :not(.login-page) .pl-575 {
    padding-left: 575px; }
  :not(.login-page) .px-575 {
    padding-left: 575px;
    padding-right: 575px; }
  :not(.login-page) .mt-580 {
    margin-top: 580px; }
  :not(.login-page) .mb-580 {
    margin-bottom: 580px; }
  :not(.login-page) .mr-580 {
    margin-right: 580px; }
  :not(.login-page) .ml-580 {
    margin-left: 580px; }
  :not(.login-page) .mx-580 {
    margin-left: 580px;
    margin-right: 580px; }
  :not(.login-page) .pt-580 {
    padding-top: 580px; }
  :not(.login-page) .pb-580 {
    padding-bottom: 580px; }
  :not(.login-page) .pr-580 {
    padding-right: 580px; }
  :not(.login-page) .pl-580 {
    padding-left: 580px; }
  :not(.login-page) .px-580 {
    padding-left: 580px;
    padding-right: 580px; }
  :not(.login-page) .mt-585 {
    margin-top: 585px; }
  :not(.login-page) .mb-585 {
    margin-bottom: 585px; }
  :not(.login-page) .mr-585 {
    margin-right: 585px; }
  :not(.login-page) .ml-585 {
    margin-left: 585px; }
  :not(.login-page) .mx-585 {
    margin-left: 585px;
    margin-right: 585px; }
  :not(.login-page) .pt-585 {
    padding-top: 585px; }
  :not(.login-page) .pb-585 {
    padding-bottom: 585px; }
  :not(.login-page) .pr-585 {
    padding-right: 585px; }
  :not(.login-page) .pl-585 {
    padding-left: 585px; }
  :not(.login-page) .px-585 {
    padding-left: 585px;
    padding-right: 585px; }
  :not(.login-page) .mt-590 {
    margin-top: 590px; }
  :not(.login-page) .mb-590 {
    margin-bottom: 590px; }
  :not(.login-page) .mr-590 {
    margin-right: 590px; }
  :not(.login-page) .ml-590 {
    margin-left: 590px; }
  :not(.login-page) .mx-590 {
    margin-left: 590px;
    margin-right: 590px; }
  :not(.login-page) .pt-590 {
    padding-top: 590px; }
  :not(.login-page) .pb-590 {
    padding-bottom: 590px; }
  :not(.login-page) .pr-590 {
    padding-right: 590px; }
  :not(.login-page) .pl-590 {
    padding-left: 590px; }
  :not(.login-page) .px-590 {
    padding-left: 590px;
    padding-right: 590px; }
  :not(.login-page) .mt-595 {
    margin-top: 595px; }
  :not(.login-page) .mb-595 {
    margin-bottom: 595px; }
  :not(.login-page) .mr-595 {
    margin-right: 595px; }
  :not(.login-page) .ml-595 {
    margin-left: 595px; }
  :not(.login-page) .mx-595 {
    margin-left: 595px;
    margin-right: 595px; }
  :not(.login-page) .pt-595 {
    padding-top: 595px; }
  :not(.login-page) .pb-595 {
    padding-bottom: 595px; }
  :not(.login-page) .pr-595 {
    padding-right: 595px; }
  :not(.login-page) .pl-595 {
    padding-left: 595px; }
  :not(.login-page) .px-595 {
    padding-left: 595px;
    padding-right: 595px; }
  :not(.login-page) .mt-600 {
    margin-top: 600px; }
  :not(.login-page) .mb-600 {
    margin-bottom: 600px; }
  :not(.login-page) .mr-600 {
    margin-right: 600px; }
  :not(.login-page) .ml-600 {
    margin-left: 600px; }
  :not(.login-page) .mx-600 {
    margin-left: 600px;
    margin-right: 600px; }
  :not(.login-page) .pt-600 {
    padding-top: 600px; }
  :not(.login-page) .pb-600 {
    padding-bottom: 600px; }
  :not(.login-page) .pr-600 {
    padding-right: 600px; }
  :not(.login-page) .pl-600 {
    padding-left: 600px; }
  :not(.login-page) .px-600 {
    padding-left: 600px;
    padding-right: 600px; }
  :not(.login-page) .mt-605 {
    margin-top: 605px; }
  :not(.login-page) .mb-605 {
    margin-bottom: 605px; }
  :not(.login-page) .mr-605 {
    margin-right: 605px; }
  :not(.login-page) .ml-605 {
    margin-left: 605px; }
  :not(.login-page) .mx-605 {
    margin-left: 605px;
    margin-right: 605px; }
  :not(.login-page) .pt-605 {
    padding-top: 605px; }
  :not(.login-page) .pb-605 {
    padding-bottom: 605px; }
  :not(.login-page) .pr-605 {
    padding-right: 605px; }
  :not(.login-page) .pl-605 {
    padding-left: 605px; }
  :not(.login-page) .px-605 {
    padding-left: 605px;
    padding-right: 605px; }
  :not(.login-page) .mt-610 {
    margin-top: 610px; }
  :not(.login-page) .mb-610 {
    margin-bottom: 610px; }
  :not(.login-page) .mr-610 {
    margin-right: 610px; }
  :not(.login-page) .ml-610 {
    margin-left: 610px; }
  :not(.login-page) .mx-610 {
    margin-left: 610px;
    margin-right: 610px; }
  :not(.login-page) .pt-610 {
    padding-top: 610px; }
  :not(.login-page) .pb-610 {
    padding-bottom: 610px; }
  :not(.login-page) .pr-610 {
    padding-right: 610px; }
  :not(.login-page) .pl-610 {
    padding-left: 610px; }
  :not(.login-page) .px-610 {
    padding-left: 610px;
    padding-right: 610px; }
  :not(.login-page) .mt-615 {
    margin-top: 615px; }
  :not(.login-page) .mb-615 {
    margin-bottom: 615px; }
  :not(.login-page) .mr-615 {
    margin-right: 615px; }
  :not(.login-page) .ml-615 {
    margin-left: 615px; }
  :not(.login-page) .mx-615 {
    margin-left: 615px;
    margin-right: 615px; }
  :not(.login-page) .pt-615 {
    padding-top: 615px; }
  :not(.login-page) .pb-615 {
    padding-bottom: 615px; }
  :not(.login-page) .pr-615 {
    padding-right: 615px; }
  :not(.login-page) .pl-615 {
    padding-left: 615px; }
  :not(.login-page) .px-615 {
    padding-left: 615px;
    padding-right: 615px; }
  :not(.login-page) .mt-620 {
    margin-top: 620px; }
  :not(.login-page) .mb-620 {
    margin-bottom: 620px; }
  :not(.login-page) .mr-620 {
    margin-right: 620px; }
  :not(.login-page) .ml-620 {
    margin-left: 620px; }
  :not(.login-page) .mx-620 {
    margin-left: 620px;
    margin-right: 620px; }
  :not(.login-page) .pt-620 {
    padding-top: 620px; }
  :not(.login-page) .pb-620 {
    padding-bottom: 620px; }
  :not(.login-page) .pr-620 {
    padding-right: 620px; }
  :not(.login-page) .pl-620 {
    padding-left: 620px; }
  :not(.login-page) .px-620 {
    padding-left: 620px;
    padding-right: 620px; }
  :not(.login-page) .mt-625 {
    margin-top: 625px; }
  :not(.login-page) .mb-625 {
    margin-bottom: 625px; }
  :not(.login-page) .mr-625 {
    margin-right: 625px; }
  :not(.login-page) .ml-625 {
    margin-left: 625px; }
  :not(.login-page) .mx-625 {
    margin-left: 625px;
    margin-right: 625px; }
  :not(.login-page) .pt-625 {
    padding-top: 625px; }
  :not(.login-page) .pb-625 {
    padding-bottom: 625px; }
  :not(.login-page) .pr-625 {
    padding-right: 625px; }
  :not(.login-page) .pl-625 {
    padding-left: 625px; }
  :not(.login-page) .px-625 {
    padding-left: 625px;
    padding-right: 625px; }
  :not(.login-page) .mt-630 {
    margin-top: 630px; }
  :not(.login-page) .mb-630 {
    margin-bottom: 630px; }
  :not(.login-page) .mr-630 {
    margin-right: 630px; }
  :not(.login-page) .ml-630 {
    margin-left: 630px; }
  :not(.login-page) .mx-630 {
    margin-left: 630px;
    margin-right: 630px; }
  :not(.login-page) .pt-630 {
    padding-top: 630px; }
  :not(.login-page) .pb-630 {
    padding-bottom: 630px; }
  :not(.login-page) .pr-630 {
    padding-right: 630px; }
  :not(.login-page) .pl-630 {
    padding-left: 630px; }
  :not(.login-page) .px-630 {
    padding-left: 630px;
    padding-right: 630px; }
  :not(.login-page) .mt-635 {
    margin-top: 635px; }
  :not(.login-page) .mb-635 {
    margin-bottom: 635px; }
  :not(.login-page) .mr-635 {
    margin-right: 635px; }
  :not(.login-page) .ml-635 {
    margin-left: 635px; }
  :not(.login-page) .mx-635 {
    margin-left: 635px;
    margin-right: 635px; }
  :not(.login-page) .pt-635 {
    padding-top: 635px; }
  :not(.login-page) .pb-635 {
    padding-bottom: 635px; }
  :not(.login-page) .pr-635 {
    padding-right: 635px; }
  :not(.login-page) .pl-635 {
    padding-left: 635px; }
  :not(.login-page) .px-635 {
    padding-left: 635px;
    padding-right: 635px; }
  :not(.login-page) .mt-640 {
    margin-top: 640px; }
  :not(.login-page) .mb-640 {
    margin-bottom: 640px; }
  :not(.login-page) .mr-640 {
    margin-right: 640px; }
  :not(.login-page) .ml-640 {
    margin-left: 640px; }
  :not(.login-page) .mx-640 {
    margin-left: 640px;
    margin-right: 640px; }
  :not(.login-page) .pt-640 {
    padding-top: 640px; }
  :not(.login-page) .pb-640 {
    padding-bottom: 640px; }
  :not(.login-page) .pr-640 {
    padding-right: 640px; }
  :not(.login-page) .pl-640 {
    padding-left: 640px; }
  :not(.login-page) .px-640 {
    padding-left: 640px;
    padding-right: 640px; }
  :not(.login-page) .mt-645 {
    margin-top: 645px; }
  :not(.login-page) .mb-645 {
    margin-bottom: 645px; }
  :not(.login-page) .mr-645 {
    margin-right: 645px; }
  :not(.login-page) .ml-645 {
    margin-left: 645px; }
  :not(.login-page) .mx-645 {
    margin-left: 645px;
    margin-right: 645px; }
  :not(.login-page) .pt-645 {
    padding-top: 645px; }
  :not(.login-page) .pb-645 {
    padding-bottom: 645px; }
  :not(.login-page) .pr-645 {
    padding-right: 645px; }
  :not(.login-page) .pl-645 {
    padding-left: 645px; }
  :not(.login-page) .px-645 {
    padding-left: 645px;
    padding-right: 645px; }
  :not(.login-page) .mt-650 {
    margin-top: 650px; }
  :not(.login-page) .mb-650 {
    margin-bottom: 650px; }
  :not(.login-page) .mr-650 {
    margin-right: 650px; }
  :not(.login-page) .ml-650 {
    margin-left: 650px; }
  :not(.login-page) .mx-650 {
    margin-left: 650px;
    margin-right: 650px; }
  :not(.login-page) .pt-650 {
    padding-top: 650px; }
  :not(.login-page) .pb-650 {
    padding-bottom: 650px; }
  :not(.login-page) .pr-650 {
    padding-right: 650px; }
  :not(.login-page) .pl-650 {
    padding-left: 650px; }
  :not(.login-page) .px-650 {
    padding-left: 650px;
    padding-right: 650px; }
  :not(.login-page) .mt-655 {
    margin-top: 655px; }
  :not(.login-page) .mb-655 {
    margin-bottom: 655px; }
  :not(.login-page) .mr-655 {
    margin-right: 655px; }
  :not(.login-page) .ml-655 {
    margin-left: 655px; }
  :not(.login-page) .mx-655 {
    margin-left: 655px;
    margin-right: 655px; }
  :not(.login-page) .pt-655 {
    padding-top: 655px; }
  :not(.login-page) .pb-655 {
    padding-bottom: 655px; }
  :not(.login-page) .pr-655 {
    padding-right: 655px; }
  :not(.login-page) .pl-655 {
    padding-left: 655px; }
  :not(.login-page) .px-655 {
    padding-left: 655px;
    padding-right: 655px; }
  :not(.login-page) .mt-660 {
    margin-top: 660px; }
  :not(.login-page) .mb-660 {
    margin-bottom: 660px; }
  :not(.login-page) .mr-660 {
    margin-right: 660px; }
  :not(.login-page) .ml-660 {
    margin-left: 660px; }
  :not(.login-page) .mx-660 {
    margin-left: 660px;
    margin-right: 660px; }
  :not(.login-page) .pt-660 {
    padding-top: 660px; }
  :not(.login-page) .pb-660 {
    padding-bottom: 660px; }
  :not(.login-page) .pr-660 {
    padding-right: 660px; }
  :not(.login-page) .pl-660 {
    padding-left: 660px; }
  :not(.login-page) .px-660 {
    padding-left: 660px;
    padding-right: 660px; }
  :not(.login-page) .mt-665 {
    margin-top: 665px; }
  :not(.login-page) .mb-665 {
    margin-bottom: 665px; }
  :not(.login-page) .mr-665 {
    margin-right: 665px; }
  :not(.login-page) .ml-665 {
    margin-left: 665px; }
  :not(.login-page) .mx-665 {
    margin-left: 665px;
    margin-right: 665px; }
  :not(.login-page) .pt-665 {
    padding-top: 665px; }
  :not(.login-page) .pb-665 {
    padding-bottom: 665px; }
  :not(.login-page) .pr-665 {
    padding-right: 665px; }
  :not(.login-page) .pl-665 {
    padding-left: 665px; }
  :not(.login-page) .px-665 {
    padding-left: 665px;
    padding-right: 665px; }
  :not(.login-page) .mt-670 {
    margin-top: 670px; }
  :not(.login-page) .mb-670 {
    margin-bottom: 670px; }
  :not(.login-page) .mr-670 {
    margin-right: 670px; }
  :not(.login-page) .ml-670 {
    margin-left: 670px; }
  :not(.login-page) .mx-670 {
    margin-left: 670px;
    margin-right: 670px; }
  :not(.login-page) .pt-670 {
    padding-top: 670px; }
  :not(.login-page) .pb-670 {
    padding-bottom: 670px; }
  :not(.login-page) .pr-670 {
    padding-right: 670px; }
  :not(.login-page) .pl-670 {
    padding-left: 670px; }
  :not(.login-page) .px-670 {
    padding-left: 670px;
    padding-right: 670px; }
  :not(.login-page) .mt-675 {
    margin-top: 675px; }
  :not(.login-page) .mb-675 {
    margin-bottom: 675px; }
  :not(.login-page) .mr-675 {
    margin-right: 675px; }
  :not(.login-page) .ml-675 {
    margin-left: 675px; }
  :not(.login-page) .mx-675 {
    margin-left: 675px;
    margin-right: 675px; }
  :not(.login-page) .pt-675 {
    padding-top: 675px; }
  :not(.login-page) .pb-675 {
    padding-bottom: 675px; }
  :not(.login-page) .pr-675 {
    padding-right: 675px; }
  :not(.login-page) .pl-675 {
    padding-left: 675px; }
  :not(.login-page) .px-675 {
    padding-left: 675px;
    padding-right: 675px; }
  :not(.login-page) .mt-680 {
    margin-top: 680px; }
  :not(.login-page) .mb-680 {
    margin-bottom: 680px; }
  :not(.login-page) .mr-680 {
    margin-right: 680px; }
  :not(.login-page) .ml-680 {
    margin-left: 680px; }
  :not(.login-page) .mx-680 {
    margin-left: 680px;
    margin-right: 680px; }
  :not(.login-page) .pt-680 {
    padding-top: 680px; }
  :not(.login-page) .pb-680 {
    padding-bottom: 680px; }
  :not(.login-page) .pr-680 {
    padding-right: 680px; }
  :not(.login-page) .pl-680 {
    padding-left: 680px; }
  :not(.login-page) .px-680 {
    padding-left: 680px;
    padding-right: 680px; }
  :not(.login-page) .mt-685 {
    margin-top: 685px; }
  :not(.login-page) .mb-685 {
    margin-bottom: 685px; }
  :not(.login-page) .mr-685 {
    margin-right: 685px; }
  :not(.login-page) .ml-685 {
    margin-left: 685px; }
  :not(.login-page) .mx-685 {
    margin-left: 685px;
    margin-right: 685px; }
  :not(.login-page) .pt-685 {
    padding-top: 685px; }
  :not(.login-page) .pb-685 {
    padding-bottom: 685px; }
  :not(.login-page) .pr-685 {
    padding-right: 685px; }
  :not(.login-page) .pl-685 {
    padding-left: 685px; }
  :not(.login-page) .px-685 {
    padding-left: 685px;
    padding-right: 685px; }
  :not(.login-page) .mt-690 {
    margin-top: 690px; }
  :not(.login-page) .mb-690 {
    margin-bottom: 690px; }
  :not(.login-page) .mr-690 {
    margin-right: 690px; }
  :not(.login-page) .ml-690 {
    margin-left: 690px; }
  :not(.login-page) .mx-690 {
    margin-left: 690px;
    margin-right: 690px; }
  :not(.login-page) .pt-690 {
    padding-top: 690px; }
  :not(.login-page) .pb-690 {
    padding-bottom: 690px; }
  :not(.login-page) .pr-690 {
    padding-right: 690px; }
  :not(.login-page) .pl-690 {
    padding-left: 690px; }
  :not(.login-page) .px-690 {
    padding-left: 690px;
    padding-right: 690px; }
  :not(.login-page) .mt-695 {
    margin-top: 695px; }
  :not(.login-page) .mb-695 {
    margin-bottom: 695px; }
  :not(.login-page) .mr-695 {
    margin-right: 695px; }
  :not(.login-page) .ml-695 {
    margin-left: 695px; }
  :not(.login-page) .mx-695 {
    margin-left: 695px;
    margin-right: 695px; }
  :not(.login-page) .pt-695 {
    padding-top: 695px; }
  :not(.login-page) .pb-695 {
    padding-bottom: 695px; }
  :not(.login-page) .pr-695 {
    padding-right: 695px; }
  :not(.login-page) .pl-695 {
    padding-left: 695px; }
  :not(.login-page) .px-695 {
    padding-left: 695px;
    padding-right: 695px; }
  :not(.login-page) .mt-700 {
    margin-top: 700px; }
  :not(.login-page) .mb-700 {
    margin-bottom: 700px; }
  :not(.login-page) .mr-700 {
    margin-right: 700px; }
  :not(.login-page) .ml-700 {
    margin-left: 700px; }
  :not(.login-page) .mx-700 {
    margin-left: 700px;
    margin-right: 700px; }
  :not(.login-page) .pt-700 {
    padding-top: 700px; }
  :not(.login-page) .pb-700 {
    padding-bottom: 700px; }
  :not(.login-page) .pr-700 {
    padding-right: 700px; }
  :not(.login-page) .pl-700 {
    padding-left: 700px; }
  :not(.login-page) .px-700 {
    padding-left: 700px;
    padding-right: 700px; }
  :not(.login-page) .mt-705 {
    margin-top: 705px; }
  :not(.login-page) .mb-705 {
    margin-bottom: 705px; }
  :not(.login-page) .mr-705 {
    margin-right: 705px; }
  :not(.login-page) .ml-705 {
    margin-left: 705px; }
  :not(.login-page) .mx-705 {
    margin-left: 705px;
    margin-right: 705px; }
  :not(.login-page) .pt-705 {
    padding-top: 705px; }
  :not(.login-page) .pb-705 {
    padding-bottom: 705px; }
  :not(.login-page) .pr-705 {
    padding-right: 705px; }
  :not(.login-page) .pl-705 {
    padding-left: 705px; }
  :not(.login-page) .px-705 {
    padding-left: 705px;
    padding-right: 705px; }
  :not(.login-page) .mt-710 {
    margin-top: 710px; }
  :not(.login-page) .mb-710 {
    margin-bottom: 710px; }
  :not(.login-page) .mr-710 {
    margin-right: 710px; }
  :not(.login-page) .ml-710 {
    margin-left: 710px; }
  :not(.login-page) .mx-710 {
    margin-left: 710px;
    margin-right: 710px; }
  :not(.login-page) .pt-710 {
    padding-top: 710px; }
  :not(.login-page) .pb-710 {
    padding-bottom: 710px; }
  :not(.login-page) .pr-710 {
    padding-right: 710px; }
  :not(.login-page) .pl-710 {
    padding-left: 710px; }
  :not(.login-page) .px-710 {
    padding-left: 710px;
    padding-right: 710px; }
  :not(.login-page) .mt-715 {
    margin-top: 715px; }
  :not(.login-page) .mb-715 {
    margin-bottom: 715px; }
  :not(.login-page) .mr-715 {
    margin-right: 715px; }
  :not(.login-page) .ml-715 {
    margin-left: 715px; }
  :not(.login-page) .mx-715 {
    margin-left: 715px;
    margin-right: 715px; }
  :not(.login-page) .pt-715 {
    padding-top: 715px; }
  :not(.login-page) .pb-715 {
    padding-bottom: 715px; }
  :not(.login-page) .pr-715 {
    padding-right: 715px; }
  :not(.login-page) .pl-715 {
    padding-left: 715px; }
  :not(.login-page) .px-715 {
    padding-left: 715px;
    padding-right: 715px; }
  :not(.login-page) .mt-720 {
    margin-top: 720px; }
  :not(.login-page) .mb-720 {
    margin-bottom: 720px; }
  :not(.login-page) .mr-720 {
    margin-right: 720px; }
  :not(.login-page) .ml-720 {
    margin-left: 720px; }
  :not(.login-page) .mx-720 {
    margin-left: 720px;
    margin-right: 720px; }
  :not(.login-page) .pt-720 {
    padding-top: 720px; }
  :not(.login-page) .pb-720 {
    padding-bottom: 720px; }
  :not(.login-page) .pr-720 {
    padding-right: 720px; }
  :not(.login-page) .pl-720 {
    padding-left: 720px; }
  :not(.login-page) .px-720 {
    padding-left: 720px;
    padding-right: 720px; }
  :not(.login-page) .mt-725 {
    margin-top: 725px; }
  :not(.login-page) .mb-725 {
    margin-bottom: 725px; }
  :not(.login-page) .mr-725 {
    margin-right: 725px; }
  :not(.login-page) .ml-725 {
    margin-left: 725px; }
  :not(.login-page) .mx-725 {
    margin-left: 725px;
    margin-right: 725px; }
  :not(.login-page) .pt-725 {
    padding-top: 725px; }
  :not(.login-page) .pb-725 {
    padding-bottom: 725px; }
  :not(.login-page) .pr-725 {
    padding-right: 725px; }
  :not(.login-page) .pl-725 {
    padding-left: 725px; }
  :not(.login-page) .px-725 {
    padding-left: 725px;
    padding-right: 725px; }
  :not(.login-page) .mt-730 {
    margin-top: 730px; }
  :not(.login-page) .mb-730 {
    margin-bottom: 730px; }
  :not(.login-page) .mr-730 {
    margin-right: 730px; }
  :not(.login-page) .ml-730 {
    margin-left: 730px; }
  :not(.login-page) .mx-730 {
    margin-left: 730px;
    margin-right: 730px; }
  :not(.login-page) .pt-730 {
    padding-top: 730px; }
  :not(.login-page) .pb-730 {
    padding-bottom: 730px; }
  :not(.login-page) .pr-730 {
    padding-right: 730px; }
  :not(.login-page) .pl-730 {
    padding-left: 730px; }
  :not(.login-page) .px-730 {
    padding-left: 730px;
    padding-right: 730px; }
  :not(.login-page) .mt-735 {
    margin-top: 735px; }
  :not(.login-page) .mb-735 {
    margin-bottom: 735px; }
  :not(.login-page) .mr-735 {
    margin-right: 735px; }
  :not(.login-page) .ml-735 {
    margin-left: 735px; }
  :not(.login-page) .mx-735 {
    margin-left: 735px;
    margin-right: 735px; }
  :not(.login-page) .pt-735 {
    padding-top: 735px; }
  :not(.login-page) .pb-735 {
    padding-bottom: 735px; }
  :not(.login-page) .pr-735 {
    padding-right: 735px; }
  :not(.login-page) .pl-735 {
    padding-left: 735px; }
  :not(.login-page) .px-735 {
    padding-left: 735px;
    padding-right: 735px; }
  :not(.login-page) .mt-740 {
    margin-top: 740px; }
  :not(.login-page) .mb-740 {
    margin-bottom: 740px; }
  :not(.login-page) .mr-740 {
    margin-right: 740px; }
  :not(.login-page) .ml-740 {
    margin-left: 740px; }
  :not(.login-page) .mx-740 {
    margin-left: 740px;
    margin-right: 740px; }
  :not(.login-page) .pt-740 {
    padding-top: 740px; }
  :not(.login-page) .pb-740 {
    padding-bottom: 740px; }
  :not(.login-page) .pr-740 {
    padding-right: 740px; }
  :not(.login-page) .pl-740 {
    padding-left: 740px; }
  :not(.login-page) .px-740 {
    padding-left: 740px;
    padding-right: 740px; }
  :not(.login-page) .mt-745 {
    margin-top: 745px; }
  :not(.login-page) .mb-745 {
    margin-bottom: 745px; }
  :not(.login-page) .mr-745 {
    margin-right: 745px; }
  :not(.login-page) .ml-745 {
    margin-left: 745px; }
  :not(.login-page) .mx-745 {
    margin-left: 745px;
    margin-right: 745px; }
  :not(.login-page) .pt-745 {
    padding-top: 745px; }
  :not(.login-page) .pb-745 {
    padding-bottom: 745px; }
  :not(.login-page) .pr-745 {
    padding-right: 745px; }
  :not(.login-page) .pl-745 {
    padding-left: 745px; }
  :not(.login-page) .px-745 {
    padding-left: 745px;
    padding-right: 745px; }
  :not(.login-page) .mt-750 {
    margin-top: 750px; }
  :not(.login-page) .mb-750 {
    margin-bottom: 750px; }
  :not(.login-page) .mr-750 {
    margin-right: 750px; }
  :not(.login-page) .ml-750 {
    margin-left: 750px; }
  :not(.login-page) .mx-750 {
    margin-left: 750px;
    margin-right: 750px; }
  :not(.login-page) .pt-750 {
    padding-top: 750px; }
  :not(.login-page) .pb-750 {
    padding-bottom: 750px; }
  :not(.login-page) .pr-750 {
    padding-right: 750px; }
  :not(.login-page) .pl-750 {
    padding-left: 750px; }
  :not(.login-page) .px-750 {
    padding-left: 750px;
    padding-right: 750px; }
  :not(.login-page) .mt-755 {
    margin-top: 755px; }
  :not(.login-page) .mb-755 {
    margin-bottom: 755px; }
  :not(.login-page) .mr-755 {
    margin-right: 755px; }
  :not(.login-page) .ml-755 {
    margin-left: 755px; }
  :not(.login-page) .mx-755 {
    margin-left: 755px;
    margin-right: 755px; }
  :not(.login-page) .pt-755 {
    padding-top: 755px; }
  :not(.login-page) .pb-755 {
    padding-bottom: 755px; }
  :not(.login-page) .pr-755 {
    padding-right: 755px; }
  :not(.login-page) .pl-755 {
    padding-left: 755px; }
  :not(.login-page) .px-755 {
    padding-left: 755px;
    padding-right: 755px; }
  :not(.login-page) .mt-760 {
    margin-top: 760px; }
  :not(.login-page) .mb-760 {
    margin-bottom: 760px; }
  :not(.login-page) .mr-760 {
    margin-right: 760px; }
  :not(.login-page) .ml-760 {
    margin-left: 760px; }
  :not(.login-page) .mx-760 {
    margin-left: 760px;
    margin-right: 760px; }
  :not(.login-page) .pt-760 {
    padding-top: 760px; }
  :not(.login-page) .pb-760 {
    padding-bottom: 760px; }
  :not(.login-page) .pr-760 {
    padding-right: 760px; }
  :not(.login-page) .pl-760 {
    padding-left: 760px; }
  :not(.login-page) .px-760 {
    padding-left: 760px;
    padding-right: 760px; }
  :not(.login-page) .mt-765 {
    margin-top: 765px; }
  :not(.login-page) .mb-765 {
    margin-bottom: 765px; }
  :not(.login-page) .mr-765 {
    margin-right: 765px; }
  :not(.login-page) .ml-765 {
    margin-left: 765px; }
  :not(.login-page) .mx-765 {
    margin-left: 765px;
    margin-right: 765px; }
  :not(.login-page) .pt-765 {
    padding-top: 765px; }
  :not(.login-page) .pb-765 {
    padding-bottom: 765px; }
  :not(.login-page) .pr-765 {
    padding-right: 765px; }
  :not(.login-page) .pl-765 {
    padding-left: 765px; }
  :not(.login-page) .px-765 {
    padding-left: 765px;
    padding-right: 765px; }
  :not(.login-page) .mt-770 {
    margin-top: 770px; }
  :not(.login-page) .mb-770 {
    margin-bottom: 770px; }
  :not(.login-page) .mr-770 {
    margin-right: 770px; }
  :not(.login-page) .ml-770 {
    margin-left: 770px; }
  :not(.login-page) .mx-770 {
    margin-left: 770px;
    margin-right: 770px; }
  :not(.login-page) .pt-770 {
    padding-top: 770px; }
  :not(.login-page) .pb-770 {
    padding-bottom: 770px; }
  :not(.login-page) .pr-770 {
    padding-right: 770px; }
  :not(.login-page) .pl-770 {
    padding-left: 770px; }
  :not(.login-page) .px-770 {
    padding-left: 770px;
    padding-right: 770px; }
  :not(.login-page) .mt-775 {
    margin-top: 775px; }
  :not(.login-page) .mb-775 {
    margin-bottom: 775px; }
  :not(.login-page) .mr-775 {
    margin-right: 775px; }
  :not(.login-page) .ml-775 {
    margin-left: 775px; }
  :not(.login-page) .mx-775 {
    margin-left: 775px;
    margin-right: 775px; }
  :not(.login-page) .pt-775 {
    padding-top: 775px; }
  :not(.login-page) .pb-775 {
    padding-bottom: 775px; }
  :not(.login-page) .pr-775 {
    padding-right: 775px; }
  :not(.login-page) .pl-775 {
    padding-left: 775px; }
  :not(.login-page) .px-775 {
    padding-left: 775px;
    padding-right: 775px; }
  :not(.login-page) .mt-780 {
    margin-top: 780px; }
  :not(.login-page) .mb-780 {
    margin-bottom: 780px; }
  :not(.login-page) .mr-780 {
    margin-right: 780px; }
  :not(.login-page) .ml-780 {
    margin-left: 780px; }
  :not(.login-page) .mx-780 {
    margin-left: 780px;
    margin-right: 780px; }
  :not(.login-page) .pt-780 {
    padding-top: 780px; }
  :not(.login-page) .pb-780 {
    padding-bottom: 780px; }
  :not(.login-page) .pr-780 {
    padding-right: 780px; }
  :not(.login-page) .pl-780 {
    padding-left: 780px; }
  :not(.login-page) .px-780 {
    padding-left: 780px;
    padding-right: 780px; }
  :not(.login-page) .mt-785 {
    margin-top: 785px; }
  :not(.login-page) .mb-785 {
    margin-bottom: 785px; }
  :not(.login-page) .mr-785 {
    margin-right: 785px; }
  :not(.login-page) .ml-785 {
    margin-left: 785px; }
  :not(.login-page) .mx-785 {
    margin-left: 785px;
    margin-right: 785px; }
  :not(.login-page) .pt-785 {
    padding-top: 785px; }
  :not(.login-page) .pb-785 {
    padding-bottom: 785px; }
  :not(.login-page) .pr-785 {
    padding-right: 785px; }
  :not(.login-page) .pl-785 {
    padding-left: 785px; }
  :not(.login-page) .px-785 {
    padding-left: 785px;
    padding-right: 785px; }
  :not(.login-page) .mt-790 {
    margin-top: 790px; }
  :not(.login-page) .mb-790 {
    margin-bottom: 790px; }
  :not(.login-page) .mr-790 {
    margin-right: 790px; }
  :not(.login-page) .ml-790 {
    margin-left: 790px; }
  :not(.login-page) .mx-790 {
    margin-left: 790px;
    margin-right: 790px; }
  :not(.login-page) .pt-790 {
    padding-top: 790px; }
  :not(.login-page) .pb-790 {
    padding-bottom: 790px; }
  :not(.login-page) .pr-790 {
    padding-right: 790px; }
  :not(.login-page) .pl-790 {
    padding-left: 790px; }
  :not(.login-page) .px-790 {
    padding-left: 790px;
    padding-right: 790px; }
  :not(.login-page) .mt-795 {
    margin-top: 795px; }
  :not(.login-page) .mb-795 {
    margin-bottom: 795px; }
  :not(.login-page) .mr-795 {
    margin-right: 795px; }
  :not(.login-page) .ml-795 {
    margin-left: 795px; }
  :not(.login-page) .mx-795 {
    margin-left: 795px;
    margin-right: 795px; }
  :not(.login-page) .pt-795 {
    padding-top: 795px; }
  :not(.login-page) .pb-795 {
    padding-bottom: 795px; }
  :not(.login-page) .pr-795 {
    padding-right: 795px; }
  :not(.login-page) .pl-795 {
    padding-left: 795px; }
  :not(.login-page) .px-795 {
    padding-left: 795px;
    padding-right: 795px; }
  :not(.login-page) .mt-800 {
    margin-top: 800px; }
  :not(.login-page) .mb-800 {
    margin-bottom: 800px; }
  :not(.login-page) .mr-800 {
    margin-right: 800px; }
  :not(.login-page) .ml-800 {
    margin-left: 800px; }
  :not(.login-page) .mx-800 {
    margin-left: 800px;
    margin-right: 800px; }
  :not(.login-page) .pt-800 {
    padding-top: 800px; }
  :not(.login-page) .pb-800 {
    padding-bottom: 800px; }
  :not(.login-page) .pr-800 {
    padding-right: 800px; }
  :not(.login-page) .pl-800 {
    padding-left: 800px; }
  :not(.login-page) .px-800 {
    padding-left: 800px;
    padding-right: 800px; }
  :not(.login-page) .mt-805 {
    margin-top: 805px; }
  :not(.login-page) .mb-805 {
    margin-bottom: 805px; }
  :not(.login-page) .mr-805 {
    margin-right: 805px; }
  :not(.login-page) .ml-805 {
    margin-left: 805px; }
  :not(.login-page) .mx-805 {
    margin-left: 805px;
    margin-right: 805px; }
  :not(.login-page) .pt-805 {
    padding-top: 805px; }
  :not(.login-page) .pb-805 {
    padding-bottom: 805px; }
  :not(.login-page) .pr-805 {
    padding-right: 805px; }
  :not(.login-page) .pl-805 {
    padding-left: 805px; }
  :not(.login-page) .px-805 {
    padding-left: 805px;
    padding-right: 805px; }
  :not(.login-page) .mt-810 {
    margin-top: 810px; }
  :not(.login-page) .mb-810 {
    margin-bottom: 810px; }
  :not(.login-page) .mr-810 {
    margin-right: 810px; }
  :not(.login-page) .ml-810 {
    margin-left: 810px; }
  :not(.login-page) .mx-810 {
    margin-left: 810px;
    margin-right: 810px; }
  :not(.login-page) .pt-810 {
    padding-top: 810px; }
  :not(.login-page) .pb-810 {
    padding-bottom: 810px; }
  :not(.login-page) .pr-810 {
    padding-right: 810px; }
  :not(.login-page) .pl-810 {
    padding-left: 810px; }
  :not(.login-page) .px-810 {
    padding-left: 810px;
    padding-right: 810px; }
  :not(.login-page) .mt-815 {
    margin-top: 815px; }
  :not(.login-page) .mb-815 {
    margin-bottom: 815px; }
  :not(.login-page) .mr-815 {
    margin-right: 815px; }
  :not(.login-page) .ml-815 {
    margin-left: 815px; }
  :not(.login-page) .mx-815 {
    margin-left: 815px;
    margin-right: 815px; }
  :not(.login-page) .pt-815 {
    padding-top: 815px; }
  :not(.login-page) .pb-815 {
    padding-bottom: 815px; }
  :not(.login-page) .pr-815 {
    padding-right: 815px; }
  :not(.login-page) .pl-815 {
    padding-left: 815px; }
  :not(.login-page) .px-815 {
    padding-left: 815px;
    padding-right: 815px; }
  :not(.login-page) .mt-820 {
    margin-top: 820px; }
  :not(.login-page) .mb-820 {
    margin-bottom: 820px; }
  :not(.login-page) .mr-820 {
    margin-right: 820px; }
  :not(.login-page) .ml-820 {
    margin-left: 820px; }
  :not(.login-page) .mx-820 {
    margin-left: 820px;
    margin-right: 820px; }
  :not(.login-page) .pt-820 {
    padding-top: 820px; }
  :not(.login-page) .pb-820 {
    padding-bottom: 820px; }
  :not(.login-page) .pr-820 {
    padding-right: 820px; }
  :not(.login-page) .pl-820 {
    padding-left: 820px; }
  :not(.login-page) .px-820 {
    padding-left: 820px;
    padding-right: 820px; }
  :not(.login-page) .mt-825 {
    margin-top: 825px; }
  :not(.login-page) .mb-825 {
    margin-bottom: 825px; }
  :not(.login-page) .mr-825 {
    margin-right: 825px; }
  :not(.login-page) .ml-825 {
    margin-left: 825px; }
  :not(.login-page) .mx-825 {
    margin-left: 825px;
    margin-right: 825px; }
  :not(.login-page) .pt-825 {
    padding-top: 825px; }
  :not(.login-page) .pb-825 {
    padding-bottom: 825px; }
  :not(.login-page) .pr-825 {
    padding-right: 825px; }
  :not(.login-page) .pl-825 {
    padding-left: 825px; }
  :not(.login-page) .px-825 {
    padding-left: 825px;
    padding-right: 825px; }
  :not(.login-page) .mt-830 {
    margin-top: 830px; }
  :not(.login-page) .mb-830 {
    margin-bottom: 830px; }
  :not(.login-page) .mr-830 {
    margin-right: 830px; }
  :not(.login-page) .ml-830 {
    margin-left: 830px; }
  :not(.login-page) .mx-830 {
    margin-left: 830px;
    margin-right: 830px; }
  :not(.login-page) .pt-830 {
    padding-top: 830px; }
  :not(.login-page) .pb-830 {
    padding-bottom: 830px; }
  :not(.login-page) .pr-830 {
    padding-right: 830px; }
  :not(.login-page) .pl-830 {
    padding-left: 830px; }
  :not(.login-page) .px-830 {
    padding-left: 830px;
    padding-right: 830px; }
  :not(.login-page) .mt-835 {
    margin-top: 835px; }
  :not(.login-page) .mb-835 {
    margin-bottom: 835px; }
  :not(.login-page) .mr-835 {
    margin-right: 835px; }
  :not(.login-page) .ml-835 {
    margin-left: 835px; }
  :not(.login-page) .mx-835 {
    margin-left: 835px;
    margin-right: 835px; }
  :not(.login-page) .pt-835 {
    padding-top: 835px; }
  :not(.login-page) .pb-835 {
    padding-bottom: 835px; }
  :not(.login-page) .pr-835 {
    padding-right: 835px; }
  :not(.login-page) .pl-835 {
    padding-left: 835px; }
  :not(.login-page) .px-835 {
    padding-left: 835px;
    padding-right: 835px; }
  :not(.login-page) .mt-840 {
    margin-top: 840px; }
  :not(.login-page) .mb-840 {
    margin-bottom: 840px; }
  :not(.login-page) .mr-840 {
    margin-right: 840px; }
  :not(.login-page) .ml-840 {
    margin-left: 840px; }
  :not(.login-page) .mx-840 {
    margin-left: 840px;
    margin-right: 840px; }
  :not(.login-page) .pt-840 {
    padding-top: 840px; }
  :not(.login-page) .pb-840 {
    padding-bottom: 840px; }
  :not(.login-page) .pr-840 {
    padding-right: 840px; }
  :not(.login-page) .pl-840 {
    padding-left: 840px; }
  :not(.login-page) .px-840 {
    padding-left: 840px;
    padding-right: 840px; }
  :not(.login-page) .mt-845 {
    margin-top: 845px; }
  :not(.login-page) .mb-845 {
    margin-bottom: 845px; }
  :not(.login-page) .mr-845 {
    margin-right: 845px; }
  :not(.login-page) .ml-845 {
    margin-left: 845px; }
  :not(.login-page) .mx-845 {
    margin-left: 845px;
    margin-right: 845px; }
  :not(.login-page) .pt-845 {
    padding-top: 845px; }
  :not(.login-page) .pb-845 {
    padding-bottom: 845px; }
  :not(.login-page) .pr-845 {
    padding-right: 845px; }
  :not(.login-page) .pl-845 {
    padding-left: 845px; }
  :not(.login-page) .px-845 {
    padding-left: 845px;
    padding-right: 845px; }
  :not(.login-page) .mt-850 {
    margin-top: 850px; }
  :not(.login-page) .mb-850 {
    margin-bottom: 850px; }
  :not(.login-page) .mr-850 {
    margin-right: 850px; }
  :not(.login-page) .ml-850 {
    margin-left: 850px; }
  :not(.login-page) .mx-850 {
    margin-left: 850px;
    margin-right: 850px; }
  :not(.login-page) .pt-850 {
    padding-top: 850px; }
  :not(.login-page) .pb-850 {
    padding-bottom: 850px; }
  :not(.login-page) .pr-850 {
    padding-right: 850px; }
  :not(.login-page) .pl-850 {
    padding-left: 850px; }
  :not(.login-page) .px-850 {
    padding-left: 850px;
    padding-right: 850px; }
  :not(.login-page) .mt-855 {
    margin-top: 855px; }
  :not(.login-page) .mb-855 {
    margin-bottom: 855px; }
  :not(.login-page) .mr-855 {
    margin-right: 855px; }
  :not(.login-page) .ml-855 {
    margin-left: 855px; }
  :not(.login-page) .mx-855 {
    margin-left: 855px;
    margin-right: 855px; }
  :not(.login-page) .pt-855 {
    padding-top: 855px; }
  :not(.login-page) .pb-855 {
    padding-bottom: 855px; }
  :not(.login-page) .pr-855 {
    padding-right: 855px; }
  :not(.login-page) .pl-855 {
    padding-left: 855px; }
  :not(.login-page) .px-855 {
    padding-left: 855px;
    padding-right: 855px; }
  :not(.login-page) .mt-860 {
    margin-top: 860px; }
  :not(.login-page) .mb-860 {
    margin-bottom: 860px; }
  :not(.login-page) .mr-860 {
    margin-right: 860px; }
  :not(.login-page) .ml-860 {
    margin-left: 860px; }
  :not(.login-page) .mx-860 {
    margin-left: 860px;
    margin-right: 860px; }
  :not(.login-page) .pt-860 {
    padding-top: 860px; }
  :not(.login-page) .pb-860 {
    padding-bottom: 860px; }
  :not(.login-page) .pr-860 {
    padding-right: 860px; }
  :not(.login-page) .pl-860 {
    padding-left: 860px; }
  :not(.login-page) .px-860 {
    padding-left: 860px;
    padding-right: 860px; }
  :not(.login-page) .mt-865 {
    margin-top: 865px; }
  :not(.login-page) .mb-865 {
    margin-bottom: 865px; }
  :not(.login-page) .mr-865 {
    margin-right: 865px; }
  :not(.login-page) .ml-865 {
    margin-left: 865px; }
  :not(.login-page) .mx-865 {
    margin-left: 865px;
    margin-right: 865px; }
  :not(.login-page) .pt-865 {
    padding-top: 865px; }
  :not(.login-page) .pb-865 {
    padding-bottom: 865px; }
  :not(.login-page) .pr-865 {
    padding-right: 865px; }
  :not(.login-page) .pl-865 {
    padding-left: 865px; }
  :not(.login-page) .px-865 {
    padding-left: 865px;
    padding-right: 865px; }
  :not(.login-page) .mt-870 {
    margin-top: 870px; }
  :not(.login-page) .mb-870 {
    margin-bottom: 870px; }
  :not(.login-page) .mr-870 {
    margin-right: 870px; }
  :not(.login-page) .ml-870 {
    margin-left: 870px; }
  :not(.login-page) .mx-870 {
    margin-left: 870px;
    margin-right: 870px; }
  :not(.login-page) .pt-870 {
    padding-top: 870px; }
  :not(.login-page) .pb-870 {
    padding-bottom: 870px; }
  :not(.login-page) .pr-870 {
    padding-right: 870px; }
  :not(.login-page) .pl-870 {
    padding-left: 870px; }
  :not(.login-page) .px-870 {
    padding-left: 870px;
    padding-right: 870px; }
  :not(.login-page) .mt-875 {
    margin-top: 875px; }
  :not(.login-page) .mb-875 {
    margin-bottom: 875px; }
  :not(.login-page) .mr-875 {
    margin-right: 875px; }
  :not(.login-page) .ml-875 {
    margin-left: 875px; }
  :not(.login-page) .mx-875 {
    margin-left: 875px;
    margin-right: 875px; }
  :not(.login-page) .pt-875 {
    padding-top: 875px; }
  :not(.login-page) .pb-875 {
    padding-bottom: 875px; }
  :not(.login-page) .pr-875 {
    padding-right: 875px; }
  :not(.login-page) .pl-875 {
    padding-left: 875px; }
  :not(.login-page) .px-875 {
    padding-left: 875px;
    padding-right: 875px; }
  :not(.login-page) .mt-880 {
    margin-top: 880px; }
  :not(.login-page) .mb-880 {
    margin-bottom: 880px; }
  :not(.login-page) .mr-880 {
    margin-right: 880px; }
  :not(.login-page) .ml-880 {
    margin-left: 880px; }
  :not(.login-page) .mx-880 {
    margin-left: 880px;
    margin-right: 880px; }
  :not(.login-page) .pt-880 {
    padding-top: 880px; }
  :not(.login-page) .pb-880 {
    padding-bottom: 880px; }
  :not(.login-page) .pr-880 {
    padding-right: 880px; }
  :not(.login-page) .pl-880 {
    padding-left: 880px; }
  :not(.login-page) .px-880 {
    padding-left: 880px;
    padding-right: 880px; }
  :not(.login-page) .mt-885 {
    margin-top: 885px; }
  :not(.login-page) .mb-885 {
    margin-bottom: 885px; }
  :not(.login-page) .mr-885 {
    margin-right: 885px; }
  :not(.login-page) .ml-885 {
    margin-left: 885px; }
  :not(.login-page) .mx-885 {
    margin-left: 885px;
    margin-right: 885px; }
  :not(.login-page) .pt-885 {
    padding-top: 885px; }
  :not(.login-page) .pb-885 {
    padding-bottom: 885px; }
  :not(.login-page) .pr-885 {
    padding-right: 885px; }
  :not(.login-page) .pl-885 {
    padding-left: 885px; }
  :not(.login-page) .px-885 {
    padding-left: 885px;
    padding-right: 885px; }
  :not(.login-page) .mt-890 {
    margin-top: 890px; }
  :not(.login-page) .mb-890 {
    margin-bottom: 890px; }
  :not(.login-page) .mr-890 {
    margin-right: 890px; }
  :not(.login-page) .ml-890 {
    margin-left: 890px; }
  :not(.login-page) .mx-890 {
    margin-left: 890px;
    margin-right: 890px; }
  :not(.login-page) .pt-890 {
    padding-top: 890px; }
  :not(.login-page) .pb-890 {
    padding-bottom: 890px; }
  :not(.login-page) .pr-890 {
    padding-right: 890px; }
  :not(.login-page) .pl-890 {
    padding-left: 890px; }
  :not(.login-page) .px-890 {
    padding-left: 890px;
    padding-right: 890px; }
  :not(.login-page) .mt-895 {
    margin-top: 895px; }
  :not(.login-page) .mb-895 {
    margin-bottom: 895px; }
  :not(.login-page) .mr-895 {
    margin-right: 895px; }
  :not(.login-page) .ml-895 {
    margin-left: 895px; }
  :not(.login-page) .mx-895 {
    margin-left: 895px;
    margin-right: 895px; }
  :not(.login-page) .pt-895 {
    padding-top: 895px; }
  :not(.login-page) .pb-895 {
    padding-bottom: 895px; }
  :not(.login-page) .pr-895 {
    padding-right: 895px; }
  :not(.login-page) .pl-895 {
    padding-left: 895px; }
  :not(.login-page) .px-895 {
    padding-left: 895px;
    padding-right: 895px; }
  :not(.login-page) .mt-900 {
    margin-top: 900px; }
  :not(.login-page) .mb-900 {
    margin-bottom: 900px; }
  :not(.login-page) .mr-900 {
    margin-right: 900px; }
  :not(.login-page) .ml-900 {
    margin-left: 900px; }
  :not(.login-page) .mx-900 {
    margin-left: 900px;
    margin-right: 900px; }
  :not(.login-page) .pt-900 {
    padding-top: 900px; }
  :not(.login-page) .pb-900 {
    padding-bottom: 900px; }
  :not(.login-page) .pr-900 {
    padding-right: 900px; }
  :not(.login-page) .pl-900 {
    padding-left: 900px; }
  :not(.login-page) .px-900 {
    padding-left: 900px;
    padding-right: 900px; }
  :not(.login-page) .mt-905 {
    margin-top: 905px; }
  :not(.login-page) .mb-905 {
    margin-bottom: 905px; }
  :not(.login-page) .mr-905 {
    margin-right: 905px; }
  :not(.login-page) .ml-905 {
    margin-left: 905px; }
  :not(.login-page) .mx-905 {
    margin-left: 905px;
    margin-right: 905px; }
  :not(.login-page) .pt-905 {
    padding-top: 905px; }
  :not(.login-page) .pb-905 {
    padding-bottom: 905px; }
  :not(.login-page) .pr-905 {
    padding-right: 905px; }
  :not(.login-page) .pl-905 {
    padding-left: 905px; }
  :not(.login-page) .px-905 {
    padding-left: 905px;
    padding-right: 905px; }
  :not(.login-page) .mt-910 {
    margin-top: 910px; }
  :not(.login-page) .mb-910 {
    margin-bottom: 910px; }
  :not(.login-page) .mr-910 {
    margin-right: 910px; }
  :not(.login-page) .ml-910 {
    margin-left: 910px; }
  :not(.login-page) .mx-910 {
    margin-left: 910px;
    margin-right: 910px; }
  :not(.login-page) .pt-910 {
    padding-top: 910px; }
  :not(.login-page) .pb-910 {
    padding-bottom: 910px; }
  :not(.login-page) .pr-910 {
    padding-right: 910px; }
  :not(.login-page) .pl-910 {
    padding-left: 910px; }
  :not(.login-page) .px-910 {
    padding-left: 910px;
    padding-right: 910px; }
  :not(.login-page) .mt-915 {
    margin-top: 915px; }
  :not(.login-page) .mb-915 {
    margin-bottom: 915px; }
  :not(.login-page) .mr-915 {
    margin-right: 915px; }
  :not(.login-page) .ml-915 {
    margin-left: 915px; }
  :not(.login-page) .mx-915 {
    margin-left: 915px;
    margin-right: 915px; }
  :not(.login-page) .pt-915 {
    padding-top: 915px; }
  :not(.login-page) .pb-915 {
    padding-bottom: 915px; }
  :not(.login-page) .pr-915 {
    padding-right: 915px; }
  :not(.login-page) .pl-915 {
    padding-left: 915px; }
  :not(.login-page) .px-915 {
    padding-left: 915px;
    padding-right: 915px; }
  :not(.login-page) .mt-920 {
    margin-top: 920px; }
  :not(.login-page) .mb-920 {
    margin-bottom: 920px; }
  :not(.login-page) .mr-920 {
    margin-right: 920px; }
  :not(.login-page) .ml-920 {
    margin-left: 920px; }
  :not(.login-page) .mx-920 {
    margin-left: 920px;
    margin-right: 920px; }
  :not(.login-page) .pt-920 {
    padding-top: 920px; }
  :not(.login-page) .pb-920 {
    padding-bottom: 920px; }
  :not(.login-page) .pr-920 {
    padding-right: 920px; }
  :not(.login-page) .pl-920 {
    padding-left: 920px; }
  :not(.login-page) .px-920 {
    padding-left: 920px;
    padding-right: 920px; }
  :not(.login-page) .mt-925 {
    margin-top: 925px; }
  :not(.login-page) .mb-925 {
    margin-bottom: 925px; }
  :not(.login-page) .mr-925 {
    margin-right: 925px; }
  :not(.login-page) .ml-925 {
    margin-left: 925px; }
  :not(.login-page) .mx-925 {
    margin-left: 925px;
    margin-right: 925px; }
  :not(.login-page) .pt-925 {
    padding-top: 925px; }
  :not(.login-page) .pb-925 {
    padding-bottom: 925px; }
  :not(.login-page) .pr-925 {
    padding-right: 925px; }
  :not(.login-page) .pl-925 {
    padding-left: 925px; }
  :not(.login-page) .px-925 {
    padding-left: 925px;
    padding-right: 925px; }
  :not(.login-page) .mt-930 {
    margin-top: 930px; }
  :not(.login-page) .mb-930 {
    margin-bottom: 930px; }
  :not(.login-page) .mr-930 {
    margin-right: 930px; }
  :not(.login-page) .ml-930 {
    margin-left: 930px; }
  :not(.login-page) .mx-930 {
    margin-left: 930px;
    margin-right: 930px; }
  :not(.login-page) .pt-930 {
    padding-top: 930px; }
  :not(.login-page) .pb-930 {
    padding-bottom: 930px; }
  :not(.login-page) .pr-930 {
    padding-right: 930px; }
  :not(.login-page) .pl-930 {
    padding-left: 930px; }
  :not(.login-page) .px-930 {
    padding-left: 930px;
    padding-right: 930px; }
  :not(.login-page) .mt-935 {
    margin-top: 935px; }
  :not(.login-page) .mb-935 {
    margin-bottom: 935px; }
  :not(.login-page) .mr-935 {
    margin-right: 935px; }
  :not(.login-page) .ml-935 {
    margin-left: 935px; }
  :not(.login-page) .mx-935 {
    margin-left: 935px;
    margin-right: 935px; }
  :not(.login-page) .pt-935 {
    padding-top: 935px; }
  :not(.login-page) .pb-935 {
    padding-bottom: 935px; }
  :not(.login-page) .pr-935 {
    padding-right: 935px; }
  :not(.login-page) .pl-935 {
    padding-left: 935px; }
  :not(.login-page) .px-935 {
    padding-left: 935px;
    padding-right: 935px; }
  :not(.login-page) .mt-940 {
    margin-top: 940px; }
  :not(.login-page) .mb-940 {
    margin-bottom: 940px; }
  :not(.login-page) .mr-940 {
    margin-right: 940px; }
  :not(.login-page) .ml-940 {
    margin-left: 940px; }
  :not(.login-page) .mx-940 {
    margin-left: 940px;
    margin-right: 940px; }
  :not(.login-page) .pt-940 {
    padding-top: 940px; }
  :not(.login-page) .pb-940 {
    padding-bottom: 940px; }
  :not(.login-page) .pr-940 {
    padding-right: 940px; }
  :not(.login-page) .pl-940 {
    padding-left: 940px; }
  :not(.login-page) .px-940 {
    padding-left: 940px;
    padding-right: 940px; }
  :not(.login-page) .mt-945 {
    margin-top: 945px; }
  :not(.login-page) .mb-945 {
    margin-bottom: 945px; }
  :not(.login-page) .mr-945 {
    margin-right: 945px; }
  :not(.login-page) .ml-945 {
    margin-left: 945px; }
  :not(.login-page) .mx-945 {
    margin-left: 945px;
    margin-right: 945px; }
  :not(.login-page) .pt-945 {
    padding-top: 945px; }
  :not(.login-page) .pb-945 {
    padding-bottom: 945px; }
  :not(.login-page) .pr-945 {
    padding-right: 945px; }
  :not(.login-page) .pl-945 {
    padding-left: 945px; }
  :not(.login-page) .px-945 {
    padding-left: 945px;
    padding-right: 945px; }
  :not(.login-page) .mt-950 {
    margin-top: 950px; }
  :not(.login-page) .mb-950 {
    margin-bottom: 950px; }
  :not(.login-page) .mr-950 {
    margin-right: 950px; }
  :not(.login-page) .ml-950 {
    margin-left: 950px; }
  :not(.login-page) .mx-950 {
    margin-left: 950px;
    margin-right: 950px; }
  :not(.login-page) .pt-950 {
    padding-top: 950px; }
  :not(.login-page) .pb-950 {
    padding-bottom: 950px; }
  :not(.login-page) .pr-950 {
    padding-right: 950px; }
  :not(.login-page) .pl-950 {
    padding-left: 950px; }
  :not(.login-page) .px-950 {
    padding-left: 950px;
    padding-right: 950px; }
  :not(.login-page) .mt-955 {
    margin-top: 955px; }
  :not(.login-page) .mb-955 {
    margin-bottom: 955px; }
  :not(.login-page) .mr-955 {
    margin-right: 955px; }
  :not(.login-page) .ml-955 {
    margin-left: 955px; }
  :not(.login-page) .mx-955 {
    margin-left: 955px;
    margin-right: 955px; }
  :not(.login-page) .pt-955 {
    padding-top: 955px; }
  :not(.login-page) .pb-955 {
    padding-bottom: 955px; }
  :not(.login-page) .pr-955 {
    padding-right: 955px; }
  :not(.login-page) .pl-955 {
    padding-left: 955px; }
  :not(.login-page) .px-955 {
    padding-left: 955px;
    padding-right: 955px; }
  :not(.login-page) .mt-960 {
    margin-top: 960px; }
  :not(.login-page) .mb-960 {
    margin-bottom: 960px; }
  :not(.login-page) .mr-960 {
    margin-right: 960px; }
  :not(.login-page) .ml-960 {
    margin-left: 960px; }
  :not(.login-page) .mx-960 {
    margin-left: 960px;
    margin-right: 960px; }
  :not(.login-page) .pt-960 {
    padding-top: 960px; }
  :not(.login-page) .pb-960 {
    padding-bottom: 960px; }
  :not(.login-page) .pr-960 {
    padding-right: 960px; }
  :not(.login-page) .pl-960 {
    padding-left: 960px; }
  :not(.login-page) .px-960 {
    padding-left: 960px;
    padding-right: 960px; }
  :not(.login-page) .mt-965 {
    margin-top: 965px; }
  :not(.login-page) .mb-965 {
    margin-bottom: 965px; }
  :not(.login-page) .mr-965 {
    margin-right: 965px; }
  :not(.login-page) .ml-965 {
    margin-left: 965px; }
  :not(.login-page) .mx-965 {
    margin-left: 965px;
    margin-right: 965px; }
  :not(.login-page) .pt-965 {
    padding-top: 965px; }
  :not(.login-page) .pb-965 {
    padding-bottom: 965px; }
  :not(.login-page) .pr-965 {
    padding-right: 965px; }
  :not(.login-page) .pl-965 {
    padding-left: 965px; }
  :not(.login-page) .px-965 {
    padding-left: 965px;
    padding-right: 965px; }
  :not(.login-page) .mt-970 {
    margin-top: 970px; }
  :not(.login-page) .mb-970 {
    margin-bottom: 970px; }
  :not(.login-page) .mr-970 {
    margin-right: 970px; }
  :not(.login-page) .ml-970 {
    margin-left: 970px; }
  :not(.login-page) .mx-970 {
    margin-left: 970px;
    margin-right: 970px; }
  :not(.login-page) .pt-970 {
    padding-top: 970px; }
  :not(.login-page) .pb-970 {
    padding-bottom: 970px; }
  :not(.login-page) .pr-970 {
    padding-right: 970px; }
  :not(.login-page) .pl-970 {
    padding-left: 970px; }
  :not(.login-page) .px-970 {
    padding-left: 970px;
    padding-right: 970px; }
  :not(.login-page) .mt-975 {
    margin-top: 975px; }
  :not(.login-page) .mb-975 {
    margin-bottom: 975px; }
  :not(.login-page) .mr-975 {
    margin-right: 975px; }
  :not(.login-page) .ml-975 {
    margin-left: 975px; }
  :not(.login-page) .mx-975 {
    margin-left: 975px;
    margin-right: 975px; }
  :not(.login-page) .pt-975 {
    padding-top: 975px; }
  :not(.login-page) .pb-975 {
    padding-bottom: 975px; }
  :not(.login-page) .pr-975 {
    padding-right: 975px; }
  :not(.login-page) .pl-975 {
    padding-left: 975px; }
  :not(.login-page) .px-975 {
    padding-left: 975px;
    padding-right: 975px; }
  :not(.login-page) .mt-980 {
    margin-top: 980px; }
  :not(.login-page) .mb-980 {
    margin-bottom: 980px; }
  :not(.login-page) .mr-980 {
    margin-right: 980px; }
  :not(.login-page) .ml-980 {
    margin-left: 980px; }
  :not(.login-page) .mx-980 {
    margin-left: 980px;
    margin-right: 980px; }
  :not(.login-page) .pt-980 {
    padding-top: 980px; }
  :not(.login-page) .pb-980 {
    padding-bottom: 980px; }
  :not(.login-page) .pr-980 {
    padding-right: 980px; }
  :not(.login-page) .pl-980 {
    padding-left: 980px; }
  :not(.login-page) .px-980 {
    padding-left: 980px;
    padding-right: 980px; }
  :not(.login-page) .mt-985 {
    margin-top: 985px; }
  :not(.login-page) .mb-985 {
    margin-bottom: 985px; }
  :not(.login-page) .mr-985 {
    margin-right: 985px; }
  :not(.login-page) .ml-985 {
    margin-left: 985px; }
  :not(.login-page) .mx-985 {
    margin-left: 985px;
    margin-right: 985px; }
  :not(.login-page) .pt-985 {
    padding-top: 985px; }
  :not(.login-page) .pb-985 {
    padding-bottom: 985px; }
  :not(.login-page) .pr-985 {
    padding-right: 985px; }
  :not(.login-page) .pl-985 {
    padding-left: 985px; }
  :not(.login-page) .px-985 {
    padding-left: 985px;
    padding-right: 985px; }
  :not(.login-page) .mt-990 {
    margin-top: 990px; }
  :not(.login-page) .mb-990 {
    margin-bottom: 990px; }
  :not(.login-page) .mr-990 {
    margin-right: 990px; }
  :not(.login-page) .ml-990 {
    margin-left: 990px; }
  :not(.login-page) .mx-990 {
    margin-left: 990px;
    margin-right: 990px; }
  :not(.login-page) .pt-990 {
    padding-top: 990px; }
  :not(.login-page) .pb-990 {
    padding-bottom: 990px; }
  :not(.login-page) .pr-990 {
    padding-right: 990px; }
  :not(.login-page) .pl-990 {
    padding-left: 990px; }
  :not(.login-page) .px-990 {
    padding-left: 990px;
    padding-right: 990px; }
  :not(.login-page) .mt-995 {
    margin-top: 995px; }
  :not(.login-page) .mb-995 {
    margin-bottom: 995px; }
  :not(.login-page) .mr-995 {
    margin-right: 995px; }
  :not(.login-page) .ml-995 {
    margin-left: 995px; }
  :not(.login-page) .mx-995 {
    margin-left: 995px;
    margin-right: 995px; }
  :not(.login-page) .pt-995 {
    padding-top: 995px; }
  :not(.login-page) .pb-995 {
    padding-bottom: 995px; }
  :not(.login-page) .pr-995 {
    padding-right: 995px; }
  :not(.login-page) .pl-995 {
    padding-left: 995px; }
  :not(.login-page) .px-995 {
    padding-left: 995px;
    padding-right: 995px; }
  :not(.login-page) .mt-1000 {
    margin-top: 1000px; }
  :not(.login-page) .mb-1000 {
    margin-bottom: 1000px; }
  :not(.login-page) .mr-1000 {
    margin-right: 1000px; }
  :not(.login-page) .ml-1000 {
    margin-left: 1000px; }
  :not(.login-page) .mx-1000 {
    margin-left: 1000px;
    margin-right: 1000px; }
  :not(.login-page) .pt-1000 {
    padding-top: 1000px; }
  :not(.login-page) .pb-1000 {
    padding-bottom: 1000px; }
  :not(.login-page) .pr-1000 {
    padding-right: 1000px; }
  :not(.login-page) .pl-1000 {
    padding-left: 1000px; }
  :not(.login-page) .px-1000 {
    padding-left: 1000px;
    padding-right: 1000px; }
  :not(.login-page) .w-0 {
    width: 0px; }
  :not(.login-page) .mw-0 {
    max-width: 0px; }
  :not(.login-page) .h-0 {
    height: 0px; }
  :not(.login-page) .mh-0 {
    max-height: 0px; }
  :not(.login-page) .w-10 {
    width: 10px; }
  :not(.login-page) .mw-10 {
    max-width: 10px; }
  :not(.login-page) .h-10 {
    height: 10px; }
  :not(.login-page) .mh-10 {
    max-height: 10px; }
  :not(.login-page) .w-20 {
    width: 20px; }
  :not(.login-page) .mw-20 {
    max-width: 20px; }
  :not(.login-page) .h-20 {
    height: 20px; }
  :not(.login-page) .mh-20 {
    max-height: 20px; }
  :not(.login-page) .w-30 {
    width: 30px; }
  :not(.login-page) .mw-30 {
    max-width: 30px; }
  :not(.login-page) .h-30 {
    height: 30px; }
  :not(.login-page) .mh-30 {
    max-height: 30px; }
  :not(.login-page) .w-40 {
    width: 40px; }
  :not(.login-page) .mw-40 {
    max-width: 40px; }
  :not(.login-page) .h-40 {
    height: 40px; }
  :not(.login-page) .mh-40 {
    max-height: 40px; }
  :not(.login-page) .w-50 {
    width: 50px; }
  :not(.login-page) .mw-50 {
    max-width: 50px; }
  :not(.login-page) .h-50 {
    height: 50px; }
  :not(.login-page) .mh-50 {
    max-height: 50px; }
  :not(.login-page) .w-60 {
    width: 60px; }
  :not(.login-page) .mw-60 {
    max-width: 60px; }
  :not(.login-page) .h-60 {
    height: 60px; }
  :not(.login-page) .mh-60 {
    max-height: 60px; }
  :not(.login-page) .w-70 {
    width: 70px; }
  :not(.login-page) .mw-70 {
    max-width: 70px; }
  :not(.login-page) .h-70 {
    height: 70px; }
  :not(.login-page) .mh-70 {
    max-height: 70px; }
  :not(.login-page) .w-80 {
    width: 80px; }
  :not(.login-page) .mw-80 {
    max-width: 80px; }
  :not(.login-page) .h-80 {
    height: 80px; }
  :not(.login-page) .mh-80 {
    max-height: 80px; }
  :not(.login-page) .w-90 {
    width: 90px; }
  :not(.login-page) .mw-90 {
    max-width: 90px; }
  :not(.login-page) .h-90 {
    height: 90px; }
  :not(.login-page) .mh-90 {
    max-height: 90px; }
  :not(.login-page) .w-100 {
    width: 100px; }
  :not(.login-page) .mw-100 {
    max-width: 100px; }
  :not(.login-page) .h-100 {
    height: 100px; }
  :not(.login-page) .mh-100 {
    max-height: 100px; }
  :not(.login-page) .w-110 {
    width: 110px; }
  :not(.login-page) .mw-110 {
    max-width: 110px; }
  :not(.login-page) .h-110 {
    height: 110px; }
  :not(.login-page) .mh-110 {
    max-height: 110px; }
  :not(.login-page) .w-120 {
    width: 120px; }
  :not(.login-page) .mw-120 {
    max-width: 120px; }
  :not(.login-page) .h-120 {
    height: 120px; }
  :not(.login-page) .mh-120 {
    max-height: 120px; }
  :not(.login-page) .w-130 {
    width: 130px; }
  :not(.login-page) .mw-130 {
    max-width: 130px; }
  :not(.login-page) .h-130 {
    height: 130px; }
  :not(.login-page) .mh-130 {
    max-height: 130px; }
  :not(.login-page) .w-140 {
    width: 140px; }
  :not(.login-page) .mw-140 {
    max-width: 140px; }
  :not(.login-page) .h-140 {
    height: 140px; }
  :not(.login-page) .mh-140 {
    max-height: 140px; }
  :not(.login-page) .w-150 {
    width: 150px; }
  :not(.login-page) .mw-150 {
    max-width: 150px; }
  :not(.login-page) .h-150 {
    height: 150px; }
  :not(.login-page) .mh-150 {
    max-height: 150px; }
  :not(.login-page) .w-160 {
    width: 160px; }
  :not(.login-page) .mw-160 {
    max-width: 160px; }
  :not(.login-page) .h-160 {
    height: 160px; }
  :not(.login-page) .mh-160 {
    max-height: 160px; }
  :not(.login-page) .w-170 {
    width: 170px; }
  :not(.login-page) .mw-170 {
    max-width: 170px; }
  :not(.login-page) .h-170 {
    height: 170px; }
  :not(.login-page) .mh-170 {
    max-height: 170px; }
  :not(.login-page) .w-180 {
    width: 180px; }
  :not(.login-page) .mw-180 {
    max-width: 180px; }
  :not(.login-page) .h-180 {
    height: 180px; }
  :not(.login-page) .mh-180 {
    max-height: 180px; }
  :not(.login-page) .w-190 {
    width: 190px; }
  :not(.login-page) .mw-190 {
    max-width: 190px; }
  :not(.login-page) .h-190 {
    height: 190px; }
  :not(.login-page) .mh-190 {
    max-height: 190px; }
  :not(.login-page) .w-200 {
    width: 200px; }
  :not(.login-page) .mw-200 {
    max-width: 200px; }
  :not(.login-page) .h-200 {
    height: 200px; }
  :not(.login-page) .mh-200 {
    max-height: 200px; }
  :not(.login-page) .w-210 {
    width: 210px; }
  :not(.login-page) .mw-210 {
    max-width: 210px; }
  :not(.login-page) .h-210 {
    height: 210px; }
  :not(.login-page) .mh-210 {
    max-height: 210px; }
  :not(.login-page) .w-220 {
    width: 220px; }
  :not(.login-page) .mw-220 {
    max-width: 220px; }
  :not(.login-page) .h-220 {
    height: 220px; }
  :not(.login-page) .mh-220 {
    max-height: 220px; }
  :not(.login-page) .w-230 {
    width: 230px; }
  :not(.login-page) .mw-230 {
    max-width: 230px; }
  :not(.login-page) .h-230 {
    height: 230px; }
  :not(.login-page) .mh-230 {
    max-height: 230px; }
  :not(.login-page) .w-240 {
    width: 240px; }
  :not(.login-page) .mw-240 {
    max-width: 240px; }
  :not(.login-page) .h-240 {
    height: 240px; }
  :not(.login-page) .mh-240 {
    max-height: 240px; }
  :not(.login-page) .w-250 {
    width: 250px; }
  :not(.login-page) .mw-250 {
    max-width: 250px; }
  :not(.login-page) .h-250 {
    height: 250px; }
  :not(.login-page) .mh-250 {
    max-height: 250px; }
  :not(.login-page) .w-260 {
    width: 260px; }
  :not(.login-page) .mw-260 {
    max-width: 260px; }
  :not(.login-page) .h-260 {
    height: 260px; }
  :not(.login-page) .mh-260 {
    max-height: 260px; }
  :not(.login-page) .w-270 {
    width: 270px; }
  :not(.login-page) .mw-270 {
    max-width: 270px; }
  :not(.login-page) .h-270 {
    height: 270px; }
  :not(.login-page) .mh-270 {
    max-height: 270px; }
  :not(.login-page) .w-280 {
    width: 280px; }
  :not(.login-page) .mw-280 {
    max-width: 280px; }
  :not(.login-page) .h-280 {
    height: 280px; }
  :not(.login-page) .mh-280 {
    max-height: 280px; }
  :not(.login-page) .w-290 {
    width: 290px; }
  :not(.login-page) .mw-290 {
    max-width: 290px; }
  :not(.login-page) .h-290 {
    height: 290px; }
  :not(.login-page) .mh-290 {
    max-height: 290px; }
  :not(.login-page) .w-300 {
    width: 300px; }
  :not(.login-page) .mw-300 {
    max-width: 300px; }
  :not(.login-page) .h-300 {
    height: 300px; }
  :not(.login-page) .mh-300 {
    max-height: 300px; }
  :not(.login-page) .w-310 {
    width: 310px; }
  :not(.login-page) .mw-310 {
    max-width: 310px; }
  :not(.login-page) .h-310 {
    height: 310px; }
  :not(.login-page) .mh-310 {
    max-height: 310px; }
  :not(.login-page) .w-320 {
    width: 320px; }
  :not(.login-page) .mw-320 {
    max-width: 320px; }
  :not(.login-page) .h-320 {
    height: 320px; }
  :not(.login-page) .mh-320 {
    max-height: 320px; }
  :not(.login-page) .w-330 {
    width: 330px; }
  :not(.login-page) .mw-330 {
    max-width: 330px; }
  :not(.login-page) .h-330 {
    height: 330px; }
  :not(.login-page) .mh-330 {
    max-height: 330px; }
  :not(.login-page) .w-340 {
    width: 340px; }
  :not(.login-page) .mw-340 {
    max-width: 340px; }
  :not(.login-page) .h-340 {
    height: 340px; }
  :not(.login-page) .mh-340 {
    max-height: 340px; }
  :not(.login-page) .w-350 {
    width: 350px; }
  :not(.login-page) .mw-350 {
    max-width: 350px; }
  :not(.login-page) .h-350 {
    height: 350px; }
  :not(.login-page) .mh-350 {
    max-height: 350px; }
  :not(.login-page) .w-360 {
    width: 360px; }
  :not(.login-page) .mw-360 {
    max-width: 360px; }
  :not(.login-page) .h-360 {
    height: 360px; }
  :not(.login-page) .mh-360 {
    max-height: 360px; }
  :not(.login-page) .w-370 {
    width: 370px; }
  :not(.login-page) .mw-370 {
    max-width: 370px; }
  :not(.login-page) .h-370 {
    height: 370px; }
  :not(.login-page) .mh-370 {
    max-height: 370px; }
  :not(.login-page) .w-380 {
    width: 380px; }
  :not(.login-page) .mw-380 {
    max-width: 380px; }
  :not(.login-page) .h-380 {
    height: 380px; }
  :not(.login-page) .mh-380 {
    max-height: 380px; }
  :not(.login-page) .w-390 {
    width: 390px; }
  :not(.login-page) .mw-390 {
    max-width: 390px; }
  :not(.login-page) .h-390 {
    height: 390px; }
  :not(.login-page) .mh-390 {
    max-height: 390px; }
  :not(.login-page) .w-400 {
    width: 400px; }
  :not(.login-page) .mw-400 {
    max-width: 400px; }
  :not(.login-page) .h-400 {
    height: 400px; }
  :not(.login-page) .mh-400 {
    max-height: 400px; }
  :not(.login-page) .w-410 {
    width: 410px; }
  :not(.login-page) .mw-410 {
    max-width: 410px; }
  :not(.login-page) .h-410 {
    height: 410px; }
  :not(.login-page) .mh-410 {
    max-height: 410px; }
  :not(.login-page) .w-420 {
    width: 420px; }
  :not(.login-page) .mw-420 {
    max-width: 420px; }
  :not(.login-page) .h-420 {
    height: 420px; }
  :not(.login-page) .mh-420 {
    max-height: 420px; }
  :not(.login-page) .w-430 {
    width: 430px; }
  :not(.login-page) .mw-430 {
    max-width: 430px; }
  :not(.login-page) .h-430 {
    height: 430px; }
  :not(.login-page) .mh-430 {
    max-height: 430px; }
  :not(.login-page) .w-440 {
    width: 440px; }
  :not(.login-page) .mw-440 {
    max-width: 440px; }
  :not(.login-page) .h-440 {
    height: 440px; }
  :not(.login-page) .mh-440 {
    max-height: 440px; }
  :not(.login-page) .w-450 {
    width: 450px; }
  :not(.login-page) .mw-450 {
    max-width: 450px; }
  :not(.login-page) .h-450 {
    height: 450px; }
  :not(.login-page) .mh-450 {
    max-height: 450px; }
  :not(.login-page) .w-460 {
    width: 460px; }
  :not(.login-page) .mw-460 {
    max-width: 460px; }
  :not(.login-page) .h-460 {
    height: 460px; }
  :not(.login-page) .mh-460 {
    max-height: 460px; }
  :not(.login-page) .w-470 {
    width: 470px; }
  :not(.login-page) .mw-470 {
    max-width: 470px; }
  :not(.login-page) .h-470 {
    height: 470px; }
  :not(.login-page) .mh-470 {
    max-height: 470px; }
  :not(.login-page) .w-480 {
    width: 480px; }
  :not(.login-page) .mw-480 {
    max-width: 480px; }
  :not(.login-page) .h-480 {
    height: 480px; }
  :not(.login-page) .mh-480 {
    max-height: 480px; }
  :not(.login-page) .w-490 {
    width: 490px; }
  :not(.login-page) .mw-490 {
    max-width: 490px; }
  :not(.login-page) .h-490 {
    height: 490px; }
  :not(.login-page) .mh-490 {
    max-height: 490px; }
  :not(.login-page) .w-500 {
    width: 500px; }
  :not(.login-page) .mw-500 {
    max-width: 500px; }
  :not(.login-page) .h-500 {
    height: 500px; }
  :not(.login-page) .mh-500 {
    max-height: 500px; }
  :not(.login-page) .w-510 {
    width: 510px; }
  :not(.login-page) .mw-510 {
    max-width: 510px; }
  :not(.login-page) .h-510 {
    height: 510px; }
  :not(.login-page) .mh-510 {
    max-height: 510px; }
  :not(.login-page) .w-520 {
    width: 520px; }
  :not(.login-page) .mw-520 {
    max-width: 520px; }
  :not(.login-page) .h-520 {
    height: 520px; }
  :not(.login-page) .mh-520 {
    max-height: 520px; }
  :not(.login-page) .w-530 {
    width: 530px; }
  :not(.login-page) .mw-530 {
    max-width: 530px; }
  :not(.login-page) .h-530 {
    height: 530px; }
  :not(.login-page) .mh-530 {
    max-height: 530px; }
  :not(.login-page) .w-540 {
    width: 540px; }
  :not(.login-page) .mw-540 {
    max-width: 540px; }
  :not(.login-page) .h-540 {
    height: 540px; }
  :not(.login-page) .mh-540 {
    max-height: 540px; }
  :not(.login-page) .w-550 {
    width: 550px; }
  :not(.login-page) .mw-550 {
    max-width: 550px; }
  :not(.login-page) .h-550 {
    height: 550px; }
  :not(.login-page) .mh-550 {
    max-height: 550px; }
  :not(.login-page) .w-560 {
    width: 560px; }
  :not(.login-page) .mw-560 {
    max-width: 560px; }
  :not(.login-page) .h-560 {
    height: 560px; }
  :not(.login-page) .mh-560 {
    max-height: 560px; }
  :not(.login-page) .w-570 {
    width: 570px; }
  :not(.login-page) .mw-570 {
    max-width: 570px; }
  :not(.login-page) .h-570 {
    height: 570px; }
  :not(.login-page) .mh-570 {
    max-height: 570px; }
  :not(.login-page) .w-580 {
    width: 580px; }
  :not(.login-page) .mw-580 {
    max-width: 580px; }
  :not(.login-page) .h-580 {
    height: 580px; }
  :not(.login-page) .mh-580 {
    max-height: 580px; }
  :not(.login-page) .w-590 {
    width: 590px; }
  :not(.login-page) .mw-590 {
    max-width: 590px; }
  :not(.login-page) .h-590 {
    height: 590px; }
  :not(.login-page) .mh-590 {
    max-height: 590px; }
  :not(.login-page) .w-600 {
    width: 600px; }
  :not(.login-page) .mw-600 {
    max-width: 600px; }
  :not(.login-page) .h-600 {
    height: 600px; }
  :not(.login-page) .mh-600 {
    max-height: 600px; }
  :not(.login-page) .w-610 {
    width: 610px; }
  :not(.login-page) .mw-610 {
    max-width: 610px; }
  :not(.login-page) .h-610 {
    height: 610px; }
  :not(.login-page) .mh-610 {
    max-height: 610px; }
  :not(.login-page) .w-620 {
    width: 620px; }
  :not(.login-page) .mw-620 {
    max-width: 620px; }
  :not(.login-page) .h-620 {
    height: 620px; }
  :not(.login-page) .mh-620 {
    max-height: 620px; }
  :not(.login-page) .w-630 {
    width: 630px; }
  :not(.login-page) .mw-630 {
    max-width: 630px; }
  :not(.login-page) .h-630 {
    height: 630px; }
  :not(.login-page) .mh-630 {
    max-height: 630px; }
  :not(.login-page) .w-640 {
    width: 640px; }
  :not(.login-page) .mw-640 {
    max-width: 640px; }
  :not(.login-page) .h-640 {
    height: 640px; }
  :not(.login-page) .mh-640 {
    max-height: 640px; }
  :not(.login-page) .w-650 {
    width: 650px; }
  :not(.login-page) .mw-650 {
    max-width: 650px; }
  :not(.login-page) .h-650 {
    height: 650px; }
  :not(.login-page) .mh-650 {
    max-height: 650px; }
  :not(.login-page) .w-660 {
    width: 660px; }
  :not(.login-page) .mw-660 {
    max-width: 660px; }
  :not(.login-page) .h-660 {
    height: 660px; }
  :not(.login-page) .mh-660 {
    max-height: 660px; }
  :not(.login-page) .w-670 {
    width: 670px; }
  :not(.login-page) .mw-670 {
    max-width: 670px; }
  :not(.login-page) .h-670 {
    height: 670px; }
  :not(.login-page) .mh-670 {
    max-height: 670px; }
  :not(.login-page) .w-680 {
    width: 680px; }
  :not(.login-page) .mw-680 {
    max-width: 680px; }
  :not(.login-page) .h-680 {
    height: 680px; }
  :not(.login-page) .mh-680 {
    max-height: 680px; }
  :not(.login-page) .w-690 {
    width: 690px; }
  :not(.login-page) .mw-690 {
    max-width: 690px; }
  :not(.login-page) .h-690 {
    height: 690px; }
  :not(.login-page) .mh-690 {
    max-height: 690px; }
  :not(.login-page) .w-700 {
    width: 700px; }
  :not(.login-page) .mw-700 {
    max-width: 700px; }
  :not(.login-page) .h-700 {
    height: 700px; }
  :not(.login-page) .mh-700 {
    max-height: 700px; }
  :not(.login-page) .w-710 {
    width: 710px; }
  :not(.login-page) .mw-710 {
    max-width: 710px; }
  :not(.login-page) .h-710 {
    height: 710px; }
  :not(.login-page) .mh-710 {
    max-height: 710px; }
  :not(.login-page) .w-720 {
    width: 720px; }
  :not(.login-page) .mw-720 {
    max-width: 720px; }
  :not(.login-page) .h-720 {
    height: 720px; }
  :not(.login-page) .mh-720 {
    max-height: 720px; }
  :not(.login-page) .w-730 {
    width: 730px; }
  :not(.login-page) .mw-730 {
    max-width: 730px; }
  :not(.login-page) .h-730 {
    height: 730px; }
  :not(.login-page) .mh-730 {
    max-height: 730px; }
  :not(.login-page) .w-740 {
    width: 740px; }
  :not(.login-page) .mw-740 {
    max-width: 740px; }
  :not(.login-page) .h-740 {
    height: 740px; }
  :not(.login-page) .mh-740 {
    max-height: 740px; }
  :not(.login-page) .w-750 {
    width: 750px; }
  :not(.login-page) .mw-750 {
    max-width: 750px; }
  :not(.login-page) .h-750 {
    height: 750px; }
  :not(.login-page) .mh-750 {
    max-height: 750px; }
  :not(.login-page) .w-760 {
    width: 760px; }
  :not(.login-page) .mw-760 {
    max-width: 760px; }
  :not(.login-page) .h-760 {
    height: 760px; }
  :not(.login-page) .mh-760 {
    max-height: 760px; }
  :not(.login-page) .w-770 {
    width: 770px; }
  :not(.login-page) .mw-770 {
    max-width: 770px; }
  :not(.login-page) .h-770 {
    height: 770px; }
  :not(.login-page) .mh-770 {
    max-height: 770px; }
  :not(.login-page) .w-780 {
    width: 780px; }
  :not(.login-page) .mw-780 {
    max-width: 780px; }
  :not(.login-page) .h-780 {
    height: 780px; }
  :not(.login-page) .mh-780 {
    max-height: 780px; }
  :not(.login-page) .w-790 {
    width: 790px; }
  :not(.login-page) .mw-790 {
    max-width: 790px; }
  :not(.login-page) .h-790 {
    height: 790px; }
  :not(.login-page) .mh-790 {
    max-height: 790px; }
  :not(.login-page) .w-800 {
    width: 800px; }
  :not(.login-page) .mw-800 {
    max-width: 800px; }
  :not(.login-page) .h-800 {
    height: 800px; }
  :not(.login-page) .mh-800 {
    max-height: 800px; }
  :not(.login-page) .w-810 {
    width: 810px; }
  :not(.login-page) .mw-810 {
    max-width: 810px; }
  :not(.login-page) .h-810 {
    height: 810px; }
  :not(.login-page) .mh-810 {
    max-height: 810px; }
  :not(.login-page) .w-820 {
    width: 820px; }
  :not(.login-page) .mw-820 {
    max-width: 820px; }
  :not(.login-page) .h-820 {
    height: 820px; }
  :not(.login-page) .mh-820 {
    max-height: 820px; }
  :not(.login-page) .w-830 {
    width: 830px; }
  :not(.login-page) .mw-830 {
    max-width: 830px; }
  :not(.login-page) .h-830 {
    height: 830px; }
  :not(.login-page) .mh-830 {
    max-height: 830px; }
  :not(.login-page) .w-840 {
    width: 840px; }
  :not(.login-page) .mw-840 {
    max-width: 840px; }
  :not(.login-page) .h-840 {
    height: 840px; }
  :not(.login-page) .mh-840 {
    max-height: 840px; }
  :not(.login-page) .w-850 {
    width: 850px; }
  :not(.login-page) .mw-850 {
    max-width: 850px; }
  :not(.login-page) .h-850 {
    height: 850px; }
  :not(.login-page) .mh-850 {
    max-height: 850px; }
  :not(.login-page) .w-860 {
    width: 860px; }
  :not(.login-page) .mw-860 {
    max-width: 860px; }
  :not(.login-page) .h-860 {
    height: 860px; }
  :not(.login-page) .mh-860 {
    max-height: 860px; }
  :not(.login-page) .w-870 {
    width: 870px; }
  :not(.login-page) .mw-870 {
    max-width: 870px; }
  :not(.login-page) .h-870 {
    height: 870px; }
  :not(.login-page) .mh-870 {
    max-height: 870px; }
  :not(.login-page) .w-880 {
    width: 880px; }
  :not(.login-page) .mw-880 {
    max-width: 880px; }
  :not(.login-page) .h-880 {
    height: 880px; }
  :not(.login-page) .mh-880 {
    max-height: 880px; }
  :not(.login-page) .w-890 {
    width: 890px; }
  :not(.login-page) .mw-890 {
    max-width: 890px; }
  :not(.login-page) .h-890 {
    height: 890px; }
  :not(.login-page) .mh-890 {
    max-height: 890px; }
  :not(.login-page) .w-900 {
    width: 900px; }
  :not(.login-page) .mw-900 {
    max-width: 900px; }
  :not(.login-page) .h-900 {
    height: 900px; }
  :not(.login-page) .mh-900 {
    max-height: 900px; }
  :not(.login-page) .w-910 {
    width: 910px; }
  :not(.login-page) .mw-910 {
    max-width: 910px; }
  :not(.login-page) .h-910 {
    height: 910px; }
  :not(.login-page) .mh-910 {
    max-height: 910px; }
  :not(.login-page) .w-920 {
    width: 920px; }
  :not(.login-page) .mw-920 {
    max-width: 920px; }
  :not(.login-page) .h-920 {
    height: 920px; }
  :not(.login-page) .mh-920 {
    max-height: 920px; }
  :not(.login-page) .w-930 {
    width: 930px; }
  :not(.login-page) .mw-930 {
    max-width: 930px; }
  :not(.login-page) .h-930 {
    height: 930px; }
  :not(.login-page) .mh-930 {
    max-height: 930px; }
  :not(.login-page) .w-940 {
    width: 940px; }
  :not(.login-page) .mw-940 {
    max-width: 940px; }
  :not(.login-page) .h-940 {
    height: 940px; }
  :not(.login-page) .mh-940 {
    max-height: 940px; }
  :not(.login-page) .w-950 {
    width: 950px; }
  :not(.login-page) .mw-950 {
    max-width: 950px; }
  :not(.login-page) .h-950 {
    height: 950px; }
  :not(.login-page) .mh-950 {
    max-height: 950px; }
  :not(.login-page) .w-960 {
    width: 960px; }
  :not(.login-page) .mw-960 {
    max-width: 960px; }
  :not(.login-page) .h-960 {
    height: 960px; }
  :not(.login-page) .mh-960 {
    max-height: 960px; }
  :not(.login-page) .w-970 {
    width: 970px; }
  :not(.login-page) .mw-970 {
    max-width: 970px; }
  :not(.login-page) .h-970 {
    height: 970px; }
  :not(.login-page) .mh-970 {
    max-height: 970px; }
  :not(.login-page) .w-980 {
    width: 980px; }
  :not(.login-page) .mw-980 {
    max-width: 980px; }
  :not(.login-page) .h-980 {
    height: 980px; }
  :not(.login-page) .mh-980 {
    max-height: 980px; }
  :not(.login-page) .w-990 {
    width: 990px; }
  :not(.login-page) .mw-990 {
    max-width: 990px; }
  :not(.login-page) .h-990 {
    height: 990px; }
  :not(.login-page) .mh-990 {
    max-height: 990px; }
  :not(.login-page) .w-1000 {
    width: 1000px; }
  :not(.login-page) .mw-1000 {
    max-width: 1000px; }
  :not(.login-page) .h-1000 {
    height: 1000px; }
  :not(.login-page) .mh-1000 {
    max-height: 1000px; }
  :not(.login-page) .w-1010 {
    width: 1010px; }
  :not(.login-page) .mw-1010 {
    max-width: 1010px; }
  :not(.login-page) .h-1010 {
    height: 1010px; }
  :not(.login-page) .mh-1010 {
    max-height: 1010px; }
  :not(.login-page) .w-1020 {
    width: 1020px; }
  :not(.login-page) .mw-1020 {
    max-width: 1020px; }
  :not(.login-page) .h-1020 {
    height: 1020px; }
  :not(.login-page) .mh-1020 {
    max-height: 1020px; }
  :not(.login-page) .w-1030 {
    width: 1030px; }
  :not(.login-page) .mw-1030 {
    max-width: 1030px; }
  :not(.login-page) .h-1030 {
    height: 1030px; }
  :not(.login-page) .mh-1030 {
    max-height: 1030px; }
  :not(.login-page) .w-1040 {
    width: 1040px; }
  :not(.login-page) .mw-1040 {
    max-width: 1040px; }
  :not(.login-page) .h-1040 {
    height: 1040px; }
  :not(.login-page) .mh-1040 {
    max-height: 1040px; }
  :not(.login-page) .w-1050 {
    width: 1050px; }
  :not(.login-page) .mw-1050 {
    max-width: 1050px; }
  :not(.login-page) .h-1050 {
    height: 1050px; }
  :not(.login-page) .mh-1050 {
    max-height: 1050px; }
  :not(.login-page) .w-1060 {
    width: 1060px; }
  :not(.login-page) .mw-1060 {
    max-width: 1060px; }
  :not(.login-page) .h-1060 {
    height: 1060px; }
  :not(.login-page) .mh-1060 {
    max-height: 1060px; }
  :not(.login-page) .w-1070 {
    width: 1070px; }
  :not(.login-page) .mw-1070 {
    max-width: 1070px; }
  :not(.login-page) .h-1070 {
    height: 1070px; }
  :not(.login-page) .mh-1070 {
    max-height: 1070px; }
  :not(.login-page) .w-1080 {
    width: 1080px; }
  :not(.login-page) .mw-1080 {
    max-width: 1080px; }
  :not(.login-page) .h-1080 {
    height: 1080px; }
  :not(.login-page) .mh-1080 {
    max-height: 1080px; }
  :not(.login-page) .w-1090 {
    width: 1090px; }
  :not(.login-page) .mw-1090 {
    max-width: 1090px; }
  :not(.login-page) .h-1090 {
    height: 1090px; }
  :not(.login-page) .mh-1090 {
    max-height: 1090px; }
  :not(.login-page) .w-1100 {
    width: 1100px; }
  :not(.login-page) .mw-1100 {
    max-width: 1100px; }
  :not(.login-page) .h-1100 {
    height: 1100px; }
  :not(.login-page) .mh-1100 {
    max-height: 1100px; }
  :not(.login-page) .w-1110 {
    width: 1110px; }
  :not(.login-page) .mw-1110 {
    max-width: 1110px; }
  :not(.login-page) .h-1110 {
    height: 1110px; }
  :not(.login-page) .mh-1110 {
    max-height: 1110px; }
  :not(.login-page) .w-1120 {
    width: 1120px; }
  :not(.login-page) .mw-1120 {
    max-width: 1120px; }
  :not(.login-page) .h-1120 {
    height: 1120px; }
  :not(.login-page) .mh-1120 {
    max-height: 1120px; }
  :not(.login-page) .w-1130 {
    width: 1130px; }
  :not(.login-page) .mw-1130 {
    max-width: 1130px; }
  :not(.login-page) .h-1130 {
    height: 1130px; }
  :not(.login-page) .mh-1130 {
    max-height: 1130px; }
  :not(.login-page) .w-1140 {
    width: 1140px; }
  :not(.login-page) .mw-1140 {
    max-width: 1140px; }
  :not(.login-page) .h-1140 {
    height: 1140px; }
  :not(.login-page) .mh-1140 {
    max-height: 1140px; }
  :not(.login-page) .w-1150 {
    width: 1150px; }
  :not(.login-page) .mw-1150 {
    max-width: 1150px; }
  :not(.login-page) .h-1150 {
    height: 1150px; }
  :not(.login-page) .mh-1150 {
    max-height: 1150px; }
  :not(.login-page) .w-1160 {
    width: 1160px; }
  :not(.login-page) .mw-1160 {
    max-width: 1160px; }
  :not(.login-page) .h-1160 {
    height: 1160px; }
  :not(.login-page) .mh-1160 {
    max-height: 1160px; }
  :not(.login-page) .w-1170 {
    width: 1170px; }
  :not(.login-page) .mw-1170 {
    max-width: 1170px; }
  :not(.login-page) .h-1170 {
    height: 1170px; }
  :not(.login-page) .mh-1170 {
    max-height: 1170px; }
  :not(.login-page) .w-full {
    width: 100%; }
  :not(.login-page) .mw-full {
    max-width: 100%; }
  :not(.login-page) .h-full {
    height: 100%; }
  :not(.login-page) .mh-full {
    max-height: 100%; }
  :not(.login-page) .h-1 {
    height: 1px; }
  :not(.login-page) .w-1 {
    width: 1px; }
  :not(.login-page) .ml-1 {
    margin-left: 1px !important; }
  :not(.login-page) .mr-1 {
    margin-right: 1px !important; }
  :not(.login-page) .mx-1 {
    margin-left: 1px !important;
    margin-right: 1px !important; }
  :not(.login-page) .h-2 {
    height: 2px; }
  :not(.login-page) .w-2 {
    width: 2px; }
  :not(.login-page) .ml-2 {
    margin-left: 2px !important; }
  :not(.login-page) .mr-2 {
    margin-right: 2px !important; }
  :not(.login-page) .mx-2 {
    margin-left: 2px !important;
    margin-right: 2px !important; }
  :not(.login-page) .h-3 {
    height: 3px; }
  :not(.login-page) .w-3 {
    width: 3px; }
  :not(.login-page) .ml-3 {
    margin-left: 3px !important; }
  :not(.login-page) .mr-3 {
    margin-right: 3px !important; }
  :not(.login-page) .mx-3 {
    margin-left: 3px !important;
    margin-right: 3px !important; }
  :not(.login-page) .h-4 {
    height: 4px; }
  :not(.login-page) .w-4 {
    width: 4px; }
  :not(.login-page) .ml-4 {
    margin-left: 4px !important; }
  :not(.login-page) .mr-4 {
    margin-right: 4px !important; }
  :not(.login-page) .mx-4 {
    margin-left: 4px !important;
    margin-right: 4px !important; }
  :not(.login-page) .h-5 {
    height: 5px; }
  :not(.login-page) .w-5 {
    width: 5px; }
  :not(.login-page) .ml-5 {
    margin-left: 5px !important; }
  :not(.login-page) .mr-5 {
    margin-right: 5px !important; }
  :not(.login-page) .mx-5 {
    margin-left: 5px !important;
    margin-right: 5px !important; }
  :not(.login-page) .h-6 {
    height: 6px; }
  :not(.login-page) .w-6 {
    width: 6px; }
  :not(.login-page) .ml-6 {
    margin-left: 6px !important; }
  :not(.login-page) .mr-6 {
    margin-right: 6px !important; }
  :not(.login-page) .mx-6 {
    margin-left: 6px !important;
    margin-right: 6px !important; }
  :not(.login-page) .h-7 {
    height: 7px; }
  :not(.login-page) .w-7 {
    width: 7px; }
  :not(.login-page) .ml-7 {
    margin-left: 7px !important; }
  :not(.login-page) .mr-7 {
    margin-right: 7px !important; }
  :not(.login-page) .mx-7 {
    margin-left: 7px !important;
    margin-right: 7px !important; }
  :not(.login-page) .h-8 {
    height: 8px; }
  :not(.login-page) .w-8 {
    width: 8px; }
  :not(.login-page) .ml-8 {
    margin-left: 8px !important; }
  :not(.login-page) .mr-8 {
    margin-right: 8px !important; }
  :not(.login-page) .mx-8 {
    margin-left: 8px !important;
    margin-right: 8px !important; }
  :not(.login-page) .h-9 {
    height: 9px; }
  :not(.login-page) .w-9 {
    width: 9px; }
  :not(.login-page) .ml-9 {
    margin-left: 9px !important; }
  :not(.login-page) .mr-9 {
    margin-right: 9px !important; }
  :not(.login-page) .mx-9 {
    margin-left: 9px !important;
    margin-right: 9px !important; }
  :not(.login-page) .h-10 {
    height: 10px; }
  :not(.login-page) .w-10 {
    width: 10px; }
  :not(.login-page) .ml-10 {
    margin-left: 10px !important; }
  :not(.login-page) .mr-10 {
    margin-right: 10px !important; }
  :not(.login-page) .mx-10 {
    margin-left: 10px !important;
    margin-right: 10px !important; }
  :not(.login-page) .w-25 {
    width: 25px !important; }
  :not(.login-page) .w-50 {
    width: 50px !important; }
  :not(.login-page) .w-75 {
    width: 75px !important; }
  :not(.login-page) .w-100 {
    width: 100px !important; }
  :not(.login-page) .f-heading {
    font-family: Nunito Sans; }
  :not(.login-page) .f-main {
    font-family: Nunito Sans; }
  :not(.login-page) .f-12 {
    font-size: 12px;
    line-height: 22px; }
  :not(.login-page) .f-14 {
    font-size: 14px;
    line-height: 24px; }
  :not(.login-page) .f-16 {
    font-size: 16px;
    line-height: 26px; }
  :not(.login-page) .f-18 {
    font-size: 18px;
    line-height: 28px; }
  :not(.login-page) .f-20 {
    font-size: 20px;
    line-height: 30px; }
  :not(.login-page) .f-22 {
    font-size: 22px;
    line-height: 32px; }
  :not(.login-page) .f-24 {
    font-size: 24px;
    line-height: 34px; }
  :not(.login-page) .f-26 {
    font-size: 26px;
    line-height: 36px; }
  :not(.login-page) .f-28 {
    font-size: 28px;
    line-height: 38px; }
  :not(.login-page) .f-30 {
    font-size: 30px;
    line-height: 40px; }
  :not(.login-page) .f-32 {
    font-size: 32px;
    line-height: 42px; }
  :not(.login-page) .f-34 {
    font-size: 34px;
    line-height: 44px; }
  :not(.login-page) .f-36 {
    font-size: 36px;
    line-height: 46px; }
  :not(.login-page) .f-38 {
    font-size: 38px;
    line-height: 48px; }
  :not(.login-page) .f-40 {
    font-size: 40px;
    line-height: 50px; }
  :not(.login-page) .f-42 {
    font-size: 42px;
    line-height: 52px; }
  :not(.login-page) .f-44 {
    font-size: 44px;
    line-height: 54px; }
  :not(.login-page) .f-46 {
    font-size: 46px;
    line-height: 56px; }
  :not(.login-page) .f-48 {
    font-size: 48px;
    line-height: 58px; }
  :not(.login-page) .f-50 {
    font-size: 50px;
    line-height: 60px; }
  :not(.login-page) .f-52 {
    font-size: 52px;
    line-height: 62px; }
  :not(.login-page) .f-54 {
    font-size: 54px;
    line-height: 64px; }
  :not(.login-page) .f-56 {
    font-size: 56px;
    line-height: 66px; }
  :not(.login-page) .f-58 {
    font-size: 58px;
    line-height: 68px; }
  :not(.login-page) .f-60 {
    font-size: 60px;
    line-height: 70px; }
  :not(.login-page) .f-62 {
    font-size: 62px;
    line-height: 72px; }
  :not(.login-page) .f-64 {
    font-size: 64px;
    line-height: 74px; }
  :not(.login-page) .f-66 {
    font-size: 66px;
    line-height: 76px; }
  :not(.login-page) .f-68 {
    font-size: 68px;
    line-height: 78px; }
  :not(.login-page) .f-70 {
    font-size: 70px;
    line-height: 80px; }
  :not(.login-page) .f-72 {
    font-size: 72px;
    line-height: 82px; }
  :not(.login-page) .f-74 {
    font-size: 74px;
    line-height: 84px; }
  :not(.login-page) .f-76 {
    font-size: 76px;
    line-height: 86px; }
  :not(.login-page) .f-78 {
    font-size: 78px;
    line-height: 88px; }
  :not(.login-page) .f-80 {
    font-size: 80px;
    line-height: 90px; }
  :not(.login-page) .f-82 {
    font-size: 82px;
    line-height: 92px; }
  :not(.login-page) .f-84 {
    font-size: 84px;
    line-height: 94px; }
  :not(.login-page) .f-86 {
    font-size: 86px;
    line-height: 96px; }
  :not(.login-page) .f-88 {
    font-size: 88px;
    line-height: 98px; }
  :not(.login-page) .f-90 {
    font-size: 90px;
    line-height: 100px; }
  :not(.login-page) .f-92 {
    font-size: 92px;
    line-height: 102px; }
  :not(.login-page) .f-94 {
    font-size: 94px;
    line-height: 104px; }
  :not(.login-page) .f-96 {
    font-size: 96px;
    line-height: 106px; }
  :not(.login-page) .f-98 {
    font-size: 98px;
    line-height: 108px; }
  :not(.login-page) .f-100 {
    font-size: 100px;
    line-height: 110px; }
  :not(.login-page) .f-102 {
    font-size: 102px;
    line-height: 112px; }
  :not(.login-page) .lh-12 {
    line-height: 12px; }
  :not(.login-page) .lh-14 {
    line-height: 14px; }
  :not(.login-page) .lh-16 {
    line-height: 16px; }
  :not(.login-page) .lh-18 {
    line-height: 18px; }
  :not(.login-page) .lh-20 {
    line-height: 20px; }
  :not(.login-page) .lh-22 {
    line-height: 22px; }
  :not(.login-page) .lh-24 {
    line-height: 24px; }
  :not(.login-page) .lh-26 {
    line-height: 26px; }
  :not(.login-page) .lh-28 {
    line-height: 28px; }
  :not(.login-page) .lh-30 {
    line-height: 30px; }
  :not(.login-page) .lh-32 {
    line-height: 32px; }
  :not(.login-page) .lh-34 {
    line-height: 34px; }
  :not(.login-page) .lh-36 {
    line-height: 36px; }
  :not(.login-page) .lh-38 {
    line-height: 38px; }
  :not(.login-page) .lh-40 {
    line-height: 40px; }
  :not(.login-page) .lh-42 {
    line-height: 42px; }
  :not(.login-page) .lh-44 {
    line-height: 44px; }
  :not(.login-page) .lh-46 {
    line-height: 46px; }
  :not(.login-page) .lh-48 {
    line-height: 48px; }
  :not(.login-page) .lh-50 {
    line-height: 50px; }
  :not(.login-page) .lh-52 {
    line-height: 52px; }
  :not(.login-page) .lh-54 {
    line-height: 54px; }
  :not(.login-page) .lh-56 {
    line-height: 56px; }
  :not(.login-page) .lh-58 {
    line-height: 58px; }
  :not(.login-page) .lh-60 {
    line-height: 60px; }
  :not(.login-page) .lh-62 {
    line-height: 62px; }
  :not(.login-page) .lh-64 {
    line-height: 64px; }
  :not(.login-page) .lh-66 {
    line-height: 66px; }
  :not(.login-page) .lh-68 {
    line-height: 68px; }
  :not(.login-page) .lh-70 {
    line-height: 70px; }
  :not(.login-page) .lh-72 {
    line-height: 72px; }
  :not(.login-page) .lh-74 {
    line-height: 74px; }
  :not(.login-page) .lh-76 {
    line-height: 76px; }
  :not(.login-page) .lh-78 {
    line-height: 78px; }
  :not(.login-page) .lh-80 {
    line-height: 80px; }
  :not(.login-page) .lh-82 {
    line-height: 82px; }
  :not(.login-page) .lh-84 {
    line-height: 84px; }
  :not(.login-page) .lh-86 {
    line-height: 86px; }
  :not(.login-page) .lh-88 {
    line-height: 88px; }
  :not(.login-page) .lh-90 {
    line-height: 90px; }
  :not(.login-page) .lh-92 {
    line-height: 92px; }
  :not(.login-page) .lh-94 {
    line-height: 94px; }
  :not(.login-page) .lh-96 {
    line-height: 96px; }
  :not(.login-page) .lh-98 {
    line-height: 98px; }
  :not(.login-page) .lh-100 {
    line-height: 100px; }
  :not(.login-page) .lh-102 {
    line-height: 102px; }
  :not(.login-page) .lh-104 {
    line-height: 104px; }
  :not(.login-page) .lh-106 {
    line-height: 106px; }
  :not(.login-page) .lh-108 {
    line-height: 108px; }
  :not(.login-page) .lh-110 {
    line-height: 110px; }
  :not(.login-page) .lh-112 {
    line-height: 112px; }
  :not(.login-page) .lh-114 {
    line-height: 114px; }
  :not(.login-page) .lh-116 {
    line-height: 116px; }
  :not(.login-page) .lh-118 {
    line-height: 118px; }
  :not(.login-page) .lh-120 {
    line-height: 120px; }
  :not(.login-page) .lh-122 {
    line-height: 122px; }
  :not(.login-page) .lh-124 {
    line-height: 124px; }
  :not(.login-page) .lh-126 {
    line-height: 126px; }
  :not(.login-page) .lh-128 {
    line-height: 128px; }
  :not(.login-page) .lh-130 {
    line-height: 130px; }
  :not(.login-page) .lh-132 {
    line-height: 132px; }
  :not(.login-page) .lh-134 {
    line-height: 134px; }
  :not(.login-page) .lh-136 {
    line-height: 136px; }
  :not(.login-page) .lh-138 {
    line-height: 138px; }
  :not(.login-page) .lh-140 {
    line-height: 140px; }
  :not(.login-page) .lh-142 {
    line-height: 142px; }
  :not(.login-page) .lh-144 {
    line-height: 144px; }
  :not(.login-page) .lh-146 {
    line-height: 146px; }
  :not(.login-page) .lh-148 {
    line-height: 148px; }
  :not(.login-page) .lh-150 {
    line-height: 150px; }
  :not(.login-page) .lh-152 {
    line-height: 152px; }
  :not(.login-page) .lh-154 {
    line-height: 154px; }
  :not(.login-page) .lh-156 {
    line-height: 156px; }
  :not(.login-page) .lh-158 {
    line-height: 158px; }
  :not(.login-page) .lh-160 {
    line-height: 160px; }
  :not(.login-page) .lh-162 {
    line-height: 162px; }
  :not(.login-page) .lh-164 {
    line-height: 164px; }
  :not(.login-page) .lh-166 {
    line-height: 166px; }
  :not(.login-page) .lh-168 {
    line-height: 168px; }
  :not(.login-page) .lh-170 {
    line-height: 170px; }
  :not(.login-page) .lh-172 {
    line-height: 172px; }
  :not(.login-page) .lh-174 {
    line-height: 174px; }
  :not(.login-page) .lh-176 {
    line-height: 176px; }
  :not(.login-page) .lh-178 {
    line-height: 178px; }
  :not(.login-page) .lh-180 {
    line-height: 180px; }
  :not(.login-page) .lh-182 {
    line-height: 182px; }
  :not(.login-page) .lh-184 {
    line-height: 184px; }
  :not(.login-page) .lh-186 {
    line-height: 186px; }
  :not(.login-page) .lh-188 {
    line-height: 188px; }
  :not(.login-page) .lh-190 {
    line-height: 190px; }
  :not(.login-page) .lh-192 {
    line-height: 192px; }
  :not(.login-page) .lh-194 {
    line-height: 194px; }
  :not(.login-page) .lh-196 {
    line-height: 196px; }
  :not(.login-page) .lh-198 {
    line-height: 198px; }
  :not(.login-page) .lh-200 {
    line-height: 200px; }
  :not(.login-page) .lh-202 {
    line-height: 202px; }
  :not(.login-page) .action-1 {
    background-color: #0074f1;
    color: #FFFFFF; }
    :not(.login-page) .action-1:hover {
      background-color: #005bbe; }
    :not(.login-page) .action-1.link:hover {
      color: #005bbe; }
  :not(.login-page) .action-2 {
    color: #0074f1; }
    :not(.login-page) .action-2.link:hover {
      color: #005bbe; }
  :not(.login-page) .action-3 {
    color: #7121F4; }
    :not(.login-page) .action-3.link:hover {
      color: #580bd7; }
  :not(.login-page) .color-main {
    color: #1e3060; }
    :not(.login-page) .color-main.link:hover {
      color: #3657ae; }
  :not(.login-page) .color-heading {
    color: #1E3060; }
    :not(.login-page) .color-heading.link:hover {
      color: #060912; }
  :not(.login-page) .color-white {
    color: #FFFFFF; }
    :not(.login-page) .color-white.link:hover {
      color: rgba(255, 255, 255, 0.7); }
  :not(.login-page) .color-transparent-white {
    color: rgba(255, 255, 255, 0.8); }
    :not(.login-page) .color-transparent-white.link:hover {
      color: #FFFFFF; }
  :not(.login-page) .color-gray {
    color: #E7EAED; }
    :not(.login-page) .color-gray.link:hover {
      color: #adb7c1; }
  :not(.login-page) .color-red {
    color: #EA3223; }
    :not(.login-page) .color-red.link:hover {
      color: #c72113; }
  :not(.login-page) .color-twitter {
    color: #0aa0f5; }
    :not(.login-page) .color-twitter.link:hover {
      color: #0880c4; }
  :not(.login-page) .color-facebook {
    color: #2e4da7; }
    :not(.login-page) .color-facebook.link:hover {
      color: #3e62ca; }
  :not(.login-page) .color-dribbble {
    color: #E93A7D; }
    :not(.login-page) .color-dribbble.link:hover {
      color: #d81862; }
  :not(.login-page) .bg-light {
    background-color: #FFFFFF !important; }
  :not(.login-page) .bg-dark {
    background-color: #35383D !important; }
  :not(.login-page) .bg-main {
    background-color: #1e3060; }
  :not(.login-page) .bg-heading {
    background-color: #1E3060; }
  :not(.login-page) .bg-white {
    background-color: #FFFFFF; }
  :not(.login-page) .bg-gray {
    background-color: #E7EAED; }
  :not(.login-page) .bg-action-1 {
    background-color: #0074f1; }
  :not(.login-page) .bg-action-2 {
    background-color: #0074f1; }
  :not(.login-page) .bg-action-3 {
    background-color: #7121F4; }
  :not(.login-page) [class^="color-filter-"]:not(.absolute), :not(.login-page) [class^="color-filter-"]:not(.fixed),
  :not(.login-page) [class*=" color-filter-"]:not(.absolute),
  :not(.login-page) [class*=" color-filter-"]:not(.fixed) {
    position: relative; }
  :not(.login-page) [class^="color-filter-"]:before,
  :not(.login-page) [class*=" color-filter-"]:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0; }
  :not(.login-page) .color-filter-light-1:before {
    background-color: rgba(255, 255, 255, 0.1); }
  :not(.login-page) .color-filter-dark-1:before {
    background-color: rgba(53, 56, 61, 0.1); }
  :not(.login-page) .color-filter-light-2:before {
    background-color: rgba(255, 255, 255, 0.2); }
  :not(.login-page) .color-filter-dark-2:before {
    background-color: rgba(53, 56, 61, 0.2); }
  :not(.login-page) .color-filter-light-3:before {
    background-color: rgba(255, 255, 255, 0.3); }
  :not(.login-page) .color-filter-dark-3:before {
    background-color: rgba(53, 56, 61, 0.3); }
  :not(.login-page) .color-filter-light-4:before {
    background-color: rgba(255, 255, 255, 0.4); }
  :not(.login-page) .color-filter-dark-4:before {
    background-color: rgba(53, 56, 61, 0.4); }
  :not(.login-page) .color-filter-light-5:before {
    background-color: rgba(255, 255, 255, 0.5); }
  :not(.login-page) .color-filter-dark-5:before {
    background-color: rgba(53, 56, 61, 0.5); }
  :not(.login-page) .color-filter-light-6:before {
    background-color: rgba(255, 255, 255, 0.6); }
  :not(.login-page) .color-filter-dark-6:before {
    background-color: rgba(53, 56, 61, 0.6); }
  :not(.login-page) .color-filter-light-7:before {
    background-color: rgba(255, 255, 255, 0.7); }
  :not(.login-page) .color-filter-dark-7:before {
    background-color: rgba(53, 56, 61, 0.7); }
  :not(.login-page) .color-filter-light-8:before {
    background-color: rgba(255, 255, 255, 0.8); }
  :not(.login-page) .color-filter-dark-8:before {
    background-color: rgba(53, 56, 61, 0.8); }
  :not(.login-page) .color-filter-light-9:before {
    background-color: rgba(255, 255, 255, 0.9); }
  :not(.login-page) .color-filter-dark-9:before {
    background-color: rgba(53, 56, 61, 0.9); }
  :not(.login-page) .radius4 {
    border-radius: 4px; }
  :not(.login-page) .radius6 {
    border-radius: 6px; }
  :not(.login-page) .radius8 {
    border-radius: 8px; }
  :not(.login-page) .radius10 {
    border-radius: 10px; }
  :not(.login-page) .radius12 {
    border-radius: 12px; }
  :not(.login-page) .radius14 {
    border-radius: 14px; }
  :not(.login-page) .radius16 {
    border-radius: 16px; }
  :not(.login-page) .radius18 {
    border-radius: 18px; }
  :not(.login-page) .radius20 {
    border-radius: 20px; }
  :not(.login-page) .radius22 {
    border-radius: 22px; }
  :not(.login-page) .radius24 {
    border-radius: 24px; }
  :not(.login-page) .radius26 {
    border-radius: 26px; }
  :not(.login-page) .radius28 {
    border-radius: 28px; }
  :not(.login-page) .radius30 {
    border-radius: 30px; }
  :not(.login-page) .radius32 {
    border-radius: 32px; }
  :not(.login-page) .radius_full {
    border-radius: 50%; }
  :not(.login-page) .noradius {
    border-radius: 0; }
  :not(.login-page) .noradius_top {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  :not(.login-page) .noradius_right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  :not(.login-page) .noradius_bottom {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  :not(.login-page) .noradius_left {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  :not(.login-page) .relative {
    position: relative; }
  :not(.login-page) .absolute {
    position: absolute; }
  :not(.login-page) body {
    font-family: Nunito Sans;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #1e3060;
    -webkit-font-smoothing: antialiased; }
    :not(.login-page) body.dev {
      background: #ccc;
      min-height: 10000px; }
      :not(.login-page) body.dev .js-content-editable {
        outline: rgba(113, 33, 244, 0.7) dashed 2px !important;
        outline-offset: -2px; }
        :not(.login-page) body.dev .js-content-editable:hover {
          outline-color: #0074f1 !important;
          outline-style: solid !important; }
      :not(.login-page) body.dev section.js-content-editable,
      :not(.login-page) body.dev header.js-content-editable,
      :not(.login-page) body.dev nav.js-content-editable,
      :not(.login-page) body.dev footer.js-content-editable {
        outline-width: 10px !important;
        outline-offset: -10px; }
  :not(.login-page) nav,
  :not(.login-page) header,
  :not(.login-page) section,
  :not(.login-page) footer {
    position: relative; }
  :not(.login-page) header,
  :not(.login-page) section {
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover; }
  :not(.login-page) header nav {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000; }
  :not(.login-page) nav {
    width: 100%; }
    :not(.login-page) nav.transparent {
      background: transparent; }
  :not(.login-page) a,
  :not(.login-page) button {
    cursor: pointer;
    transition: all 0.25s; }
    :not(.login-page) a:hover,
    :not(.login-page) button:hover {
      text-decoration: none; }
    :not(.login-page) a:active, :not(.login-page) a:focus,
    :not(.login-page) button:active,
    :not(.login-page) button:focus {
      text-decoration: none;
      outline: none; }
  :not(.login-page) button {
    border: none;
    outline: none !important; }
  :not(.login-page) input,
  :not(.login-page) textarea {
    border: 0;
    outline: none;
    background: transparent;
    color: #1e3060;
    resize: none; }
  :not(.login-page) label {
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer; }
  :not(.login-page) h1,
  :not(.login-page) h2,
  :not(.login-page) h3,
  :not(.login-page) h4,
  :not(.login-page) h5,
  :not(.login-page) h6 {
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    margin: 0;
    font-weight: 700;
    font-family: Nunito Sans; }
  :not(.login-page) h1,
  :not(.login-page) h2 {
    font-size: 58px;
    line-height: 70px; }
    :not(.login-page) h1.big,
    :not(.login-page) h2.big {
      font-size: 72px;
      line-height: 86px; }
    :not(.login-page) h1.small,
    :not(.login-page) h2.small {
      font-size: 42px;
      line-height: 52px;
      font-weight: 700; }
  :not(.login-page) h3 {
    font-size: 42px;
    line-height: 52px; }
  :not(.login-page) b,
  :not(.login-page) strong {
    font-weight: 700; }
  :not(.login-page) [data-aos="fade-down"] {
    transform: translate3d(0, -35px, 0); }
  :not(.login-page) [data-aos="fade-up"] {
    transform: translate3d(0, 35px, 0); }
  :not(.login-page) .close_menu {
    cursor: pointer; }
  :not(.login-page) .open_menu {
    cursor: pointer;
    transition: all .2s ease; }
    :not(.login-page) .open_menu:hover {
      background-color: #dae0e5 !important; }
  :not(.login-page) .slick-prev,
  :not(.login-page) .slick-next {
    position: absolute;
    display: block;
    height: 32px;
    width: 32px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    margin-top: -16px;
    padding: 0;
    border: none;
    outline: none;
    z-index: 1; }
    :not(.login-page) .slick-prev:hover, :not(.login-page) .slick-prev:focus,
    :not(.login-page) .slick-next:hover,
    :not(.login-page) .slick-next:focus {
      outline: none;
      background: transparent;
      color: transparent; }
      :not(.login-page) .slick-prev:hover:before, :not(.login-page) .slick-prev:focus:before,
      :not(.login-page) .slick-next:hover:before,
      :not(.login-page) .slick-next:focus:before {
        opacity: 0.5; }
    :not(.login-page) .slick-prev.slick-disabled:before,
    :not(.login-page) .slick-next.slick-disabled:before {
      opacity: 0.3; }
    :not(.login-page) .slick-prev:before,
    :not(.login-page) .slick-next:before {
      display: block;
      content: "";
      width: 32px;
      height: 32px;
      opacity: 1;
      transition: opacity 0.2s; }
  :not(.login-page) .slick-prev {
    left: 0; }
    [dir="rtl"] :not(.login-page) .slick-prev {
      left: auto;
      right: 0; }
  :not(.login-page) .slick-next {
    right: 0; }
    [dir="rtl"] :not(.login-page) .slick-next {
      left: 0;
      right: auto; }
  :not(.login-page) .slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0;
    margin: 0;
    left: 0;
    right: 0; }
    :not(.login-page) .slick-dots li {
      position: relative;
      display: inline-block;
      height: 10px;
      width: 10px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer; }
      :not(.login-page) .slick-dots li button {
        box-sizing: border-box;
        border: 2px solid #FFFFFF;
        border-radius: 50%;
        background: transparent;
        display: block;
        height: 10px;
        width: 10px;
        padding: 0;
        outline: none;
        line-height: 0px;
        font-size: 0px;
        color: transparent;
        opacity: 0.3;
        cursor: pointer; }
        :not(.login-page) .slick-dots li button:hover {
          outline: none;
          opacity: 0.8; }
      :not(.login-page) .slick-dots li.slick-active button {
        background: #FFFFFF;
        opacity: 1; }
        :not(.login-page) .slick-dots li.slick-active button:hover {
          opacity: 1; }
  :not(.login-page) .slick-slide {
    outline: none; }
  :not(.login-page) .container {
    position: relative; }
  :not(.login-page) ::-moz-selection {
    background: #7121F4;
    color: #FFFFFF; }
  :not(.login-page) ::-webkit-selection {
    background: #7121F4;
    color: #FFFFFF; }
  :not(.login-page) ::selection {
    background: #7121F4;
    color: #FFFFFF; }
  @media (max-width: 1199.98px) {
    :not(.login-page) header[class^="pt-"], :not(.login-page) header[class*=" pt-"],
    :not(.login-page) section[class^="pt-"],
    :not(.login-page) section[class*=" pt-"] {
      padding-top: 80px; }
    :not(.login-page) header[class^="pb-"], :not(.login-page) header[class*=" pb-"],
    :not(.login-page) section[class^="pb-"],
    :not(.login-page) section[class*=" pb-"] {
      padding-bottom: 80px; }
    :not(.login-page) h1 br,
    :not(.login-page) h2 br,
    :not(.login-page) h3 br,
    :not(.login-page) .text-adaptive br {
      display: none; }
    :not(.login-page) h1,
    :not(.login-page) h2 {
      font-size: 52px;
      line-height: 62px; }
      :not(.login-page) h1.big,
      :not(.login-page) h2.big {
        font-size: 62px;
        line-height: 72px; }
      :not(.login-page) h1.small,
      :not(.login-page) h2.small {
        font-size: 36px;
        line-height: 46px; }
    :not(.login-page) h3 {
      font-size: 36px;
      line-height: 46px; }
    :not(.login-page) .navigation_mobile {
      display: block; } }
  @media (max-width: 991.98px) {
    :not(.login-page) h1,
    :not(.login-page) h2 {
      font-size: 42px;
      line-height: 52px; }
      :not(.login-page) h1.big,
      :not(.login-page) h2.big {
        font-size: 52px;
        line-height: 62px; }
      :not(.login-page) h1.small,
      :not(.login-page) h2.small {
        font-size: 32px;
        line-height: 42px; }
    :not(.login-page) h3 {
      font-size: 32px;
      line-height: 42px; }
    :not(.login-page) .text-adaptive.f-24 {
      font-size: 22px;
      line-height: 32px; }
    :not(.login-page) .text-adaptive.f-22 {
      font-size: 20px;
      line-height: 30px; }
    :not(.login-page) .text-adaptive.f-20 {
      font-size: 18px;
      line-height: 28px; }
    :not(.login-page) .alert {
      top: 10px;
      left: 20px;
      right: 20px;
      transform: none;
      width: auto;
      padding-left: 40px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) h1,
    :not(.login-page) h2 {
      font-size: 32px;
      line-height: 42px; }
      :not(.login-page) h1.big,
      :not(.login-page) h2.big {
        font-size: 42px;
        line-height: 52px; }
      :not(.login-page) h1.small,
      :not(.login-page) h2.small {
        font-size: 26px;
        line-height: 36px; }
    :not(.login-page) h3 {
      font-size: 26px;
      line-height: 36px; }
    :not(.login-page) .text-adaptive.f-24 {
      font-size: 20px;
      line-height: 30px; }
    :not(.login-page) .text-adaptive.f-22 {
      font-size: 18px;
      line-height: 28px; }
    :not(.login-page) .text-adaptive.f-20 {
      font-size: 16px;
      line-height: 26px; }
    :not(.login-page) .text-adaptive.f-18 {
      font-size: 16px;
      line-height: 26px; }
    :not(.login-page) .btn.lg {
      height: 50px;
      line-height: 50px;
      padding: 0 30px;
      font-size: 18px;
      font-weight: 500; }
      :not(.login-page) .btn.lg[class^="border-"], :not(.login-page) .btn.lg[class*=" border-"] {
        line-height: 46px; }
    :not(.login-page) .btn.xl {
      height: 60px;
      line-height: 60px;
      padding: 0 35px;
      font-size: 18px;
      font-weight: 500; }
      :not(.login-page) .btn.xl[class^="border-"], :not(.login-page) .btn.xl[class*=" border-"] {
        line-height: 56px; }
    :not(.login-page) .input.lg {
      height: 50px;
      font-size: 18px;
      font-weight: 500; } }
  @media (max-width: 575.98px) {
    :not(.login-page) h1,
    :not(.login-page) h2 {
      font-size: 26px;
      line-height: 36px; }
      :not(.login-page) h1.big,
      :not(.login-page) h2.big {
        font-size: 32px;
        line-height: 42px; }
      :not(.login-page) h1.small,
      :not(.login-page) h2.small {
        font-size: 22px;
        line-height: 32px; }
    :not(.login-page) .btn.xl {
      height: 50px;
      line-height: 50px;
      padding: 0 30px; }
      :not(.login-page) .btn.xl[class^="border-"], :not(.login-page) .btn.xl[class*=" border-"] {
        line-height: 46px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .header_1 .text-adaptive {
      margin-top: 20px; }
    :not(.login-page) .header_1 .buttons {
      margin-top: 40px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .header_2 .text-adaptive {
      margin-top: 20px; }
    :not(.login-page) .header_2 form {
      margin-top: 40px; }
      :not(.login-page) .header_2 form .input {
        text-align: center;
        max-width: 100%;
        width: 100%; }
      :not(.login-page) .header_2 form .btn {
        margin-top: 15px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .header_3 form {
      margin-top: 40px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .header_3 .input {
      margin-bottom: 20px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .header_4 .poster {
      margin-top: 30px; } }
  :not(.login-page) .header_5 .block_with_border {
    border-left: 2px solid rgba(30, 48, 96, 0.3);
    border-right: 2px solid rgba(30, 48, 96, 0.3); }
  @media (max-width: 991.98px) {
    :not(.login-page) .header_5 .block_with_border {
      border: none;
      padding: 0 15px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .header_5 .btn {
      margin-top: 30px; }
    :not(.login-page) .header_5 img {
      margin-bottom: 20px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .header_6 .btn_holder {
      margin-top: 30px;
      margin-bottom: 30px; } }
  :not(.login-page) .header_7 {
    overflow: hidden; }
    :not(.login-page) .header_7_bg {
      top: 0; }
    @media (max-width: 1199.98px) {
      :not(.login-page) .header_7_bg {
        margin-left: 600px; }
      :not(.login-page) .header_7 .inner {
        padding-top: 80px;
        padding-bottom: 80px; } }
    @media (max-width: 575.98px) {
      :not(.login-page) .header_7 .buttons {
        margin-top: 30px; }
        :not(.login-page) .header_7 .buttons .btn {
          margin: 0 auto 10px auto !important;
          display: block;
          max-width: 320px; } }
  :not(.login-page) .header_8 .slick-arrow {
    top: 50px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .header_8 .slick-arrow {
      top: 40px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .header_8 .slick-arrow {
      top: 35px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .header_8 .slick-arrow {
      top: 30px; }
    :not(.login-page) .header_8 .text-adaptive {
      margin-top: 15px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .header_8 .slider {
      padding: 0; }
    :not(.login-page) .header_8 .slick-arrow {
      display: none !important; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .header_9 .left {
      padding-top: 40px; } }
  :not(.login-page) .header_10 .videos .fa-play {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  @media (max-width: 1199.98px) {
    :not(.login-page) .header_10 .btn,
    :not(.login-page) .header_10 .videos {
      margin-top: 50px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .header_10 .videos {
      margin-top: 20px; }
      :not(.login-page) .header_10 .videos .text-adaptive {
        width: auto;
        margin: 0 auto; }
    :not(.login-page) .header_10 .image {
      margin: 0 auto;
      margin-bottom: 15px; } }
  :not(.login-page) .header_menu_11 .btn {
    min-width: 80px; }
  @media (max-width: 469.98px) {
    :not(.login-page) .header_11 .buttons {
      margin-top: 20px;
      display: block !important; }
      :not(.login-page) .header_11 .buttons .btn,
      :not(.login-page) .header_11 .buttons .link {
        vertical-align: baseline;
        margin-left: 0;
        margin-top: 20px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .header_12 .logo {
      margin-bottom: 15px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .header_13 .text-adaptive {
      margin-top: 10px; } }
  :not(.login-page) .header_14 .separate:before {
    content: " ";
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-bottom: 2px solid #E7EAED; }
  @media (max-width: 991.98px) {
    :not(.login-page) .header_14 .text-adaptive {
      margin-top: 10px; }
    :not(.login-page) .header_14 a.btn {
      margin-top: 20px; }
    :not(.login-page) .header_14 form {
      margin-top: 40px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .header_14 form {
      padding-left: 40px;
      padding-right: 40px; } }
  @media (max-width: 374.98px) {
    :not(.login-page) .header_14 form {
      padding-left: 20px;
      padding-right: 20px; } }
  @media (max-width: 359.98px) {
    :not(.login-page) .header_14 label {
      font-size: 14px; } }
  :not(.login-page) .header_15 .separate {
    position: absolute;
    top: 0;
    width: 2px; }
    :not(.login-page) .header_15 .separate:nth-of-type(1) {
      left: 0; }
    :not(.login-page) .header_15 .separate:nth-of-type(2) {
      right: 0; }
  @media (max-width: 767.98px) {
    :not(.login-page) .header_15 h1 {
      margin-bottom: 15px; }
    :not(.login-page) .header_15 .btn {
      margin-top: 30px; }
    :not(.login-page) .header_15 .blocks {
      margin-top: 20px; } }
  :not(.login-page) .header_16 {
    background-position: 50% 100%;
    overflow: hidden; }
    @media (max-width: 991.98px) {
      :not(.login-page) .header_16 .blocks {
        margin-top: 20px; } }
    @media (max-width: 767.98px) {
      :not(.login-page) .header_16 .img {
        max-width: 100%;
        margin: 0; }
      :not(.login-page) .header_16 .blocks .title {
        margin-top: 15px;
        margin-bottom: 15px; } }
  :not(.login-page) .header_19 .slick-dots {
    width: 10px;
    height: auto;
    left: 0;
    bottom: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    :not(.login-page) .header_19 .slick-dots li {
      margin: 5px auto; }
      :not(.login-page) .header_19 .slick-dots li button {
        border: none;
        background: #E7EAED; }
      :not(.login-page) .header_19 .slick-dots li.slick-active button {
        background: #7121F4; }
  @media (max-width: 991.98px) {
    :not(.login-page) .header_19 .btn {
      margin-top: 40px; }
    :not(.login-page) .header_19 .inner {
      padding-left: 30px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .header_19 .slider {
      margin-top: 30px; }
    :not(.login-page) .header_19 .slick-dots {
      width: auto;
      top: auto;
      right: 0;
      bottom: -40px;
      flex-direction: row; }
      :not(.login-page) .header_19 .slick-dots li {
        margin: 0 5px; }
    :not(.login-page) .header_19 .inner {
      margin-top: 40px;
      padding-left: 0; }
      :not(.login-page) .header_19 .inner .text-adaptive {
        margin-top: 15px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .header_20 h1 {
      margin-top: 10px; }
    :not(.login-page) .header_20 .text-adaptive {
      margin-top: 15px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .header_20 .img {
      margin-bottom: 40px;
      max-height: 70vh; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .header_21 .buttons {
      margin-top: 50px;
      margin-bottom: 20px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .header_22 .text-adaptive {
      margin-top: 10px;
      margin-bottom: 40px; }
    :not(.login-page) .header_22 .socials {
      margin-top: 40px; }
      :not(.login-page) .header_22 .socials .title {
        margin-bottom: 15px; }
      :not(.login-page) .header_22 .socials .btn {
        margin-left: 10px;
        margin-right: 10px; }
        :not(.login-page) .header_22 .socials .btn:first-of-type {
          margin-left: 0; }
        :not(.login-page) .header_22 .socials .btn:last-of-type {
          margin-right: 0; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .navigation_2 a {
      margin: 0 15px; }
    :not(.login-page) .navigation_2 .dropdown a {
      margin: 0; } }
  :not(.login-page) .navigation_3 form button:hover {
    background: #FFFFFF !important; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .navigation_4 a {
      margin: 0 10px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .navigation_8 a {
      margin: 0 10px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .navigation_9 a {
      margin: 0 10px; } }
  :not(.login-page) .navigation_13 .link {
    border-bottom: 2px solid transparent; }
    :not(.login-page) .navigation_13 .link:hover {
      border-bottom-color: #7121F4; }
  @media (max-width: 991.98px) {
    :not(.login-page) .navigation_14 a,
    :not(.login-page) .navigation_17 a,
    :not(.login-page) .navigation_19 a,
    :not(.login-page) .navigation_20 a,
    :not(.login-page) .navigation_21 a {
      margin: 0 10px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .navigation_18 a,
    :not(.login-page) .navigation_18 form {
      margin-left: 10px;
      margin-right: 10px; } }
  :not(.login-page) .navigation_22 .banner {
    overflow: hidden; }
  :not(.login-page) .navigation_22 .img {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    min-width: 100%;
    min-height: 100%; }
  :not(.login-page) .navigation_22 .navigation_popup .link {
    padding: 5px 15px; }
    :not(.login-page) .navigation_22 .navigation_popup .link:hover {
      background: #E7EAED;
      color: #1e3060; }
  :not(.login-page) .navigation_22 .navigation_popup .inner {
    top: 0;
    left: 0; }
  @media (max-width: 399.98px) {
    :not(.login-page) .navigation_22 .inner {
      margin-left: 40px; } }
  :not(.login-page) .navigation_23 form button:hover {
    background: #FFFFFF !important; }
  :not(.login-page) .navigation_23 .navigation_popup .slider .inner {
    top: 0;
    left: 0; }
  :not(.login-page) .navigation_23 .navigation_popup .slider .slick-dots {
    bottom: -30px; }
    :not(.login-page) .navigation_23 .navigation_popup .slider .slick-dots li.slick-active button {
      background: #0074f1; }
    :not(.login-page) .navigation_23 .navigation_popup .slider .slick-dots li button {
      opacity: 1;
      background: #E7EAED;
      border: none; }
  :not(.login-page) .navigation_23 .navigation_popup .link:hover img {
    opacity: 0.7; }
  :not(.login-page) .navigation_23 .navigation_popup .link img {
    transition: opacity 0.25s; }
  @media (max-width: 991.98px) {
    :not(.login-page) .navigation_23 .navigation_popup .categories .link {
      margin: 0 10px; } }
  @media (max-width: 469.98px) {
    :not(.login-page) .navigation_23 .slide .inner {
      padding: 30px; }
      :not(.login-page) .navigation_23 .slide .inner h2 {
        font-size: 26px;
        line-height: 36px; } }
  @media (max-width: 359.98px) {
    :not(.login-page) .navigation_23 .slide .inner h2 {
      font-size: 22px;
      line-height: 32px; } }
  :not(.login-page) .navigation_24 .navigation_popup .link:hover img {
    opacity: 0.7; }
  :not(.login-page) .navigation_24 .navigation_popup .link img {
    transition: opacity 0.25s; }
  :not(.login-page) .navigation_25 .navigation_popup {
    border-color: rgba(255, 255, 255, 0.3); }
  :not(.login-page) .navigation_26 .navigation_popup {
    border-color: rgba(255, 255, 255, 0.3); }
  :not(.login-page) .navigation_26 .categories .link {
    color: rgba(255, 255, 255, 0.7); }
    :not(.login-page) .navigation_26 .categories .link:hover {
      background: rgba(255, 255, 255, 0.3);
      color: rgba(255, 255, 255, 0.7); }
  :not(.login-page) .navigation_26 .slider {
    overflow: hidden; }
    :not(.login-page) .navigation_26 .slider img {
      min-height: 250px;
      min-width: 100%; }
    :not(.login-page) .navigation_26 .slider .inner {
      top: 0;
      left: 0; }
    :not(.login-page) .navigation_26 .slider .slick-dots {
      left: 20px;
      right: auto;
      bottom: 25px; }
      :not(.login-page) .navigation_26 .slider .slick-dots li.slick-active button {
        background: #1e3060; }
      :not(.login-page) .navigation_26 .slider .slick-dots li button {
        background: transparent;
        border: 2px solid #1e3060; }
  :not(.login-page) .navigation_26 .right_block {
    overflow: hidden; }
    :not(.login-page) .navigation_26 .right_block .bg {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .navigation_26 .categories .title {
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_1 h2 {
      margin-top: 10px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .content_2 .text-adaptive {
      margin-top: 15px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_3 .blocks {
      margin-top: 10px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_4 .icons .fa {
      margin: 0 15px; }
    :not(.login-page) .content_4 h2 {
      margin-top: 30px; }
    :not(.login-page) .content_4 .text-adaptive {
      margin-top: 15px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_5 h2 {
      margin-top: 15px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .content_6 .text-adaptive {
      margin-top: 15px; } }
  :not(.login-page) .content_13 .inner {
    border: 2px solid #E7EAED; }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_13 .inner {
      padding-top: 30px;
      padding-bottom: 30px; }
    :not(.login-page) .content_13 h2 {
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_14 .inner {
      padding-top: 30px;
      padding-bottom: 30px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .content_15 .container {
      padding-top: 40px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_15 .text-adaptive {
      margin-top: 10px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .content_16 h2,
    :not(.login-page) .content_16 .text-adaptive {
      padding-left: 0; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_16 .text-adaptive {
      margin-top: 5px; } }
  @media (max-width: 359.98px) {
    :not(.login-page) .content_17 .block_tip {
      padding-left: 20px;
      padding-right: 20px; } }
  :not(.login-page) .content_18 .opening_tabs .tab_opener .fa,
  :not(.login-page) .content_18 .opening_tabs .tab_opener .fas,
  :not(.login-page) .content_18 .opening_tabs .tab_opener .far,
  :not(.login-page) .content_18 .opening_tabs .tab_opener .fab {
    transition: all 0.2s; }
  :not(.login-page) .content_18 .opening_tabs .tab_opener.opened .fa,
  :not(.login-page) .content_18 .opening_tabs .tab_opener.opened .fas,
  :not(.login-page) .content_18 .opening_tabs .tab_opener.opened .far,
  :not(.login-page) .content_18 .opening_tabs .tab_opener.opened .fab {
    transform: rotate(90deg); }
  :not(.login-page) .content_18 .opening_tabs .tab_text {
    border-bottom: 2px solid #E7EAED; }
    :not(.login-page) .content_18 .opening_tabs .tab_text:last-child {
      border: none; }
  @media (max-width: 991.98px) {
    :not(.login-page) .content_18 .block_right {
      margin-left: 0;
      width: auto; } }
  :not(.login-page) .content_20 .number {
    position: absolute;
    top: 0;
    left: 0; }
  :not(.login-page) .content_20 .content_20_line {
    position: absolute;
    top: 0;
    left: 19px;
    width: 2px;
    height: 100%;
    background: #E7EAED; }
  :not(.login-page) .content_20 .video_block .fa,
  :not(.login-page) .content_20 .video_block .fas,
  :not(.login-page) .content_20 .video_block .far,
  :not(.login-page) .content_20 .video_block .fab {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  @media (max-width: 575.98px) {
    :not(.login-page) .content_20 .video_block .inner {
      margin-left: 0;
      margin-top: 30px; } }
  @media (max-width: 374.98px) {
    :not(.login-page) .content_20 .number {
      position: static; }
    :not(.login-page) .content_20 .block_inner {
      margin-left: 0; }
    :not(.login-page) .content_20 .content_20_line {
      display: none; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_21[class^="pt-"], :not(.login-page) .content_21[class*=" pt-"] {
      padding-top: 40px; }
    :not(.login-page) .content_21[class^="pb-"], :not(.login-page) .content_21[class*=" pb-"] {
      padding-bottom: 40px; } }
  :not(.login-page) .content_22 {
    min-height: 100vh; }
    @media (max-width: 1199.98px) {
      :not(.login-page) .content_22[class^="pb-"], :not(.login-page) .content_22[class*=" pb-"] {
        padding-bottom: 30px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_24[class^="pt-"], :not(.login-page) .content_24[class*=" pt-"] {
      padding-top: 40px; }
    :not(.login-page) .content_24[class^="pb-"], :not(.login-page) .content_24[class*=" pb-"] {
      padding-bottom: 40px; }
    :not(.login-page) .content_24 .tip {
      margin-top: 10px;
      margin-left: 10px; } }
  :not(.login-page) .content_25 {
    min-height: 100vh; }
  @media (max-width: 991.98px) {
    :not(.login-page) .content_26 .btn {
      margin-top: 30px; } }
  :not(.login-page) .content_27 .image .fa,
  :not(.login-page) .content_27 .image .fas,
  :not(.login-page) .content_27 .image .far,
  :not(.login-page) .content_27 .image .fab {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_27 .poster {
      margin-bottom: 20px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .content_27 .image {
      margin-left: auto;
      margin-right: auto; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_29[class^="pb-"], :not(.login-page) .content_29[class*=" pb-"] {
      padding-bottom: 60px; }
    :not(.login-page) .content_29 .holder {
      margin-bottom: 15px; } }
  :not(.login-page) .content_30 .block {
    background: rgba(231, 234, 237, 0.4); }
    :not(.login-page) .content_30 .block img {
      max-width: 70%;
      max-height: 70%; }
  :not(.login-page) .content_31 .block {
    border: 2px solid rgba(255, 255, 255, 0.15); }
    :not(.login-page) .content_31 .block img {
      max-width: 70%;
      max-height: 70%; }
  @media (max-width: 767.98px) {
    :not(.login-page) .content_31 .text-adaptive {
      margin-top: 10px;
      margin-bottom: 30px; } }
  :not(.login-page) .content_32 .left .cell {
    padding: 22px 30px 20px 30px; }
    :not(.login-page) .content_32 .left .cell.bordered {
      border-bottom: 2px solid #E7EAED; }
  :not(.login-page) .content_32 .right {
    background: rgba(231, 234, 237, 0.6); }
  :not(.login-page) .content_33 .table td,
  :not(.login-page) .content_33 .table th {
    padding: 24px 15px 20px 15px; }
  :not(.login-page) .content_33 .table tr td:first-of-type,
  :not(.login-page) .content_33 .table tr th:first-of-type {
    padding-left: 30px; }
  :not(.login-page) .content_33 .table tr td:last-of-type,
  :not(.login-page) .content_33 .table tr th:last-of-type {
    padding-right: 30px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .content_33 .table-responsive-shadow {
      position: relative; }
      :not(.login-page) .content_33 .table-responsive-shadow:after {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 40px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 78%); } }
  :not(.login-page) .content_34 #copy_from_me {
    background: rgba(231, 234, 237, 0.5);
    font-family: Courier New, monospace; }
    :not(.login-page) .content_34 #copy_from_me pre {
      color: #1e3060; }
  @media (max-width: 992.98px) {
    :not(.login-page) .content_34 .right {
      margin-top: 50px;
      padding: 0; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .testimonial_2 .text-adaptive {
      margin-top: 10px;
      margin-bottom: 25px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .testimonial_3[class^="pb-"], :not(.login-page) .testimonial_3[class*=" pb-"] {
      padding-bottom: 20px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .testimonial_3 h2,
    :not(.login-page) .testimonial_3 .block {
      margin-bottom: 30px; } }
  :not(.login-page) .testimonial_4 .block {
    border: 2px solid #E7EAED; }
  @media (max-width: 991.98px) {
    :not(.login-page) .testimonial_4 .holder {
      max-width: 570px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .testimonial_4 .block {
      padding: 30px; }
    :not(.login-page) .testimonial_4 .text-adaptive {
      margin-bottom: 20px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .testimonial_4 .inner {
      margin-left: 0; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .testimonial_5[class^="pb-"], :not(.login-page) .testimonial_5[class*=" pb-"] {
      padding-bottom: 30px; } }
  :not(.login-page) .testimonial_6 .block {
    border: 2px solid rgba(255, 255, 255, 0.15); }
  :not(.login-page) .testimonial_6 .author {
    left: 0;
    bottom: 0; }
  @media (max-width: 575.98px) {
    :not(.login-page) .testimonial_6 .inner {
      padding-left: 20px;
      padding-bottom: 0; }
    :not(.login-page) .testimonial_6 .info {
      margin-left: 0; }
    :not(.login-page) .testimonial_6 .author {
      margin-top: 10px;
      position: static; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_1[class^="pb-"], :not(.login-page) .feature_1[class*=" pb-"] {
      padding-bottom: 30px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_1 .title {
      margin-top: 10px;
      margin-bottom: 10px; }
      :not(.login-page) .feature_1 .title br {
        display: none; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_2[class^="pb-"], :not(.login-page) .feature_2[class*=" pb-"] {
      padding-bottom: 30px; }
    :not(.login-page) .feature_2 .description {
      margin-top: 20px; } }
  :not(.login-page) .feature_3 .block {
    border: 2px solid #E7EAED; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_3[class^="pb-"], :not(.login-page) .feature_3[class*=" pb-"] {
      padding-bottom: 50px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_3 .description {
      margin-top: 10px;
      margin-bottom: 40px; } }
  :not(.login-page) .feature_4 .block {
    border: 2px solid rgba(255, 255, 255, 0.3); }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_4[class^="pb-"], :not(.login-page) .feature_4[class*=" pb-"] {
      padding-bottom: 50px; }
    :not(.login-page) .feature_4 .description {
      margin-top: 10px;
      margin-bottom: 40px; }
    :not(.login-page) .feature_4 .block {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 50px; } }
  :not(.login-page) .feature_5 .block {
    border: 2px solid #E7EAED;
    border-top: none; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_5[class^="pb-"], :not(.login-page) .feature_5[class*=" pb-"] {
      padding-bottom: 30px; }
    :not(.login-page) .feature_5 .description {
      margin-top: 10px; }
    :not(.login-page) .feature_5 .block {
      padding: 30px; }
      :not(.login-page) .feature_5 .block .title {
        margin-bottom: 10px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .feature_5 .holder {
      max-width: 370px; } }
  :not(.login-page) .feature_6 .block_bordered {
    border: 2px solid rgba(255, 255, 255, 0.2); }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_6 .block {
      margin-top: 40px; }
    :not(.login-page) .feature_6 .title {
      margin-top: 15px;
      margin-bottom: 10px; }
    :not(.login-page) .feature_6 .block_bordered {
      margin-top: 50px;
      padding: 30px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_7[class^="pb-"], :not(.login-page) .feature_7[class*=" pb-"] {
      padding-bottom: 20px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_7 h2 {
      margin-bottom: 40px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_8[class^="pb-"], :not(.login-page) .feature_8[class*=" pb-"] {
      padding-bottom: 40px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_8 .icon {
      margin-left: 0;
      width: 25px; }
    :not(.login-page) .feature_8 .title {
      margin-bottom: 10px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_9[class^="pb-"], :not(.login-page) .feature_9[class*=" pb-"] {
      padding-bottom: 40px; }
    :not(.login-page) .feature_9 h2 {
      margin-bottom: 15px; }
    :not(.login-page) .feature_9 .title {
      margin-bottom: 10px; }
    :not(.login-page) .feature_9 .hr {
      margin-bottom: 45px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_10[class^="pb-"], :not(.login-page) .feature_10[class*=" pb-"] {
      padding-bottom: 40px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_10 h2 {
      margin-bottom: 10px; } }
  :not(.login-page) .feature_11 {
    overflow: hidden; }
    @media (max-width: 992.98px) {
      :not(.login-page) .feature_11 .bg {
        max-height: 100vh; }
      :not(.login-page) .feature_11 .description {
        margin-top: 10px;
        margin-bottom: 50px; } }
    @media (max-width: 575.98px) {
      :not(.login-page) .feature_11 .bg {
        margin-top: 50px;
        max-height: 70vh;
        max-width: 100%; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_12[class^="pb-"], :not(.login-page) .feature_12[class*=" pb-"] {
      padding-bottom: 0px; }
    :not(.login-page) .feature_12 .icon {
      margin-left: 0; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_12 .title {
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_12 h2 {
      margin: 10px 0; }
    :not(.login-page) .feature_12 .icon {
      margin: 0 auto 10px auto; }
    :not(.login-page) .feature_12 .bg {
      margin-top: 50px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_13[class^="pb-"], :not(.login-page) .feature_13[class*=" pb-"] {
      padding-bottom: 40px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_14[class^="pb-"], :not(.login-page) .feature_14[class*=" pb-"] {
      padding-bottom: 40px; }
    :not(.login-page) .feature_14 h2 {
      margin-bottom: 0; }
    :not(.login-page) .feature_14 .main_title {
      margin-top: 10px; }
    :not(.login-page) .feature_14 .bg {
      max-height: 80vh;
      margin-bottom: 50px; }
    :not(.login-page) .feature_14 .block {
      margin-bottom: 40px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_15 h2 {
      margin: 0px 0 10px 0; }
    :not(.login-page) .feature_15 .block {
      margin-top: 40px; }
    :not(.login-page) .feature_15 .title {
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_15 .bg {
      margin-top: 50px;
      max-height: 80vh; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_16[class^="pb-"], :not(.login-page) .feature_16[class*=" pb-"] {
      padding-bottom: 30px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .feature_16 h2 {
      margin-bottom: 10px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_17[class^="pb-"], :not(.login-page) .feature_17[class*=" pb-"] {
      padding-bottom: 30px; }
    :not(.login-page) .feature_17 h6 {
      margin-top: 10px;
      margin-bottom: 50px; }
      :not(.login-page) .feature_17 h6 br {
        display: none; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_17 .img {
      margin-bottom: 40px;
      max-height: 70vh; }
    :not(.login-page) .feature_17 .title {
      margin-bottom: 10px; }
    :not(.login-page) .feature_17 .block {
      margin-bottom: 40px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_18 .description {
      margin-top: 10px;
      margin-bottom: 40px; }
    :not(.login-page) .feature_18 .block {
      margin-bottom: 40px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_18 .description {
      margin-bottom: 0; }
    :not(.login-page) .feature_18 .img {
      max-height: 70vh; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_19 .icons {
      margin-bottom: 30px;
      font-size: 26px;
      line-height: 36px; }
    :not(.login-page) .feature_19 .btn {
      margin-top: 30px; } }
  :not(.login-page) .feature_20 {
    overflow: hidden; }
    :not(.login-page) .feature_20 .bg {
      top: 0;
      left: 15px; }
    @media (max-width: 1199.98px) {
      :not(.login-page) .feature_20 .left_side {
        padding-top: 80px;
        padding-bottom: 40px; }
      :not(.login-page) .feature_20 h2 {
        margin-bottom: 10px; } }
    @media (max-width: 767.98px) {
      :not(.login-page) .feature_20 .btn {
        margin-bottom: 40px; }
      :not(.login-page) .feature_20 .title {
        margin: 10px 0; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_21[class^="pb-"], :not(.login-page) .feature_21[class*=" pb-"] {
      padding-bottom: 30px; }
    :not(.login-page) .feature_21 .block {
      margin-bottom: 50px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_21 .description {
      margin-top: 10px;
      margin-bottom: 50px; } }
  :not(.login-page) .feature_22 .buttons .btn {
    min-width: 200px; }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_22[class^="pb-"], :not(.login-page) .feature_22[class*=" pb-"] {
      padding-bottom: 40px; }
    :not(.login-page) .feature_22 .description {
      margin-top: 10px;
      margin-bottom: 40px; }
    :not(.login-page) .feature_22 .icon i {
      font-size: 32px;
      line-height: 42px; }
    :not(.login-page) .feature_22 .title {
      margin-bottom: 10px; }
    :not(.login-page) .feature_22 .block {
      margin-bottom: 40px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_23 .description {
      margin-top: 10px;
      margin-bottom: 40px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_23 .info {
      margin-left: 0; } }
  :not(.login-page) .feature_25 {
    overflow: hidden; }
    :not(.login-page) .feature_25 .bg {
      top: 0;
      left: 15px; }
    @media (max-width: 1199.98px) {
      :not(.login-page) .feature_25 .left_side {
        padding-top: 80px;
        padding-bottom: 40px; }
      :not(.login-page) .feature_25 .description {
        margin: 10px 0 50px 0; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_26 .link {
      margin-bottom: 40px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .feature_26[class^="pb-"], :not(.login-page) .feature_26[class*=" pb-"] {
      padding-bottom: 40px; }
    :not(.login-page) .feature_26 .img {
      max-height: 70vh; }
    :not(.login-page) .feature_26 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .feature_26 .title {
      margin-bottom: 10px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_27[class^="pb-"], :not(.login-page) .feature_27[class*=" pb-"] {
      padding-bottom: 30px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_27 .description {
      margin: 10px 0 50px 0; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .feature_28 .description {
      margin: 10px 0 40px 0; }
    :not(.login-page) .feature_28 .img {
      margin-top: 40px; } }
  :not(.login-page) .feature_29 .slick-slide {
    cursor: grab; }
  :not(.login-page) .feature_29 .slick-dots {
    left: 0;
    bottom: auto;
    top: 50%;
    width: 10px;
    margin-top: -35px; }
    :not(.login-page) .feature_29 .slick-dots li {
      margin: 5px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_29 .slick-slide {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_29 .slick-dots {
      top: auto;
      margin-top: 0;
      width: 100%;
      bottom: 30px; }
    :not(.login-page) .feature_29 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .feature_29 .title {
      margin: 10px 0; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_29 .img {
      max-height: 70vh;
      margin-bottom: 50px; } }
  :not(.login-page) .feature_31 .img {
    top: 0;
    right: 15px; }
  :not(.login-page) .feature_31 .slick-slide {
    cursor: grab; }
  :not(.login-page) .feature_31 .slick-dots {
    left: auto;
    right: calc((100% - 1170px) / 2);
    bottom: auto;
    top: 50%;
    width: 10px;
    margin-top: -35px; }
    :not(.login-page) .feature_31 .slick-dots li {
      margin: 5px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_31 .slick-dots {
      right: calc((100% - 970px) / 2); }
    :not(.login-page) .feature_31 .slick-slide {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_31 .slick-dots {
      right: calc((100% - 750px) / 2); } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_31 .slick-dots {
      top: auto;
      bottom: 30px;
      left: 0;
      right: 0;
      width: auto; }
    :not(.login-page) .feature_31 .slick-slide {
      padding-bottom: 40px; }
    :not(.login-page) .feature_31 .right_side {
      padding-bottom: 0; }
    :not(.login-page) .feature_31 .img {
      position: static;
      height: auto;
      max-width: 100%; }
    :not(.login-page) .feature_31 .title {
      margin-bottom: 10px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_32 .block {
      margin-left: 20px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_32 .decription {
      margin: 10px 0 40px 0; }
    :not(.login-page) .feature_32 .left_side,
    :not(.login-page) .feature_32 .right_side {
      margin-top: 0; }
    :not(.login-page) .feature_32 .block {
      margin-left: 0; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_33 .description {
      margin-top: 10px; }
    :not(.login-page) .feature_33 .title {
      margin-bottom: 0px; } }
  :not(.login-page) .feature_34 {
    overflow: hidden; }
    @media (max-width: 767.98px) {
      :not(.login-page) .feature_34[class^="pb-"], :not(.login-page) .feature_34[class*=" pb-"] {
        padding-bottom: 30px; }
      :not(.login-page) .feature_34 .title {
        margin: 10px 0; } }
    @media (max-width: 575.98px) {
      :not(.login-page) .feature_34 h2 {
        margin-bottom: 10px; }
      :not(.login-page) .feature_34 .img {
        max-width: 100%; }
      :not(.login-page) .feature_34 .blocks {
        margin-top: 50px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_36 h2 {
      margin-top: 0;
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_36 .img {
      max-height: 70vh; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_37[class^="pb-"], :not(.login-page) .feature_37[class*=" pb-"] {
      padding-bottom: 40px; }
    :not(.login-page) .feature_37 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .feature_37 .advantage {
      margin-bottom: 40px; } }
  :not(.login-page) .feature_38 {
    background-position: 50% 100%;
    overflow: hidden;
    padding-bottom: 370px; }
    :not(.login-page) .feature_38 .img {
      top: 70px;
      left: 50%;
      transform: translateX(-44%); }
    @media (max-width: 1199.98px) {
      :not(.login-page) .feature_38 {
        padding-bottom: 300px; }
        :not(.login-page) .feature_38 .img {
          height: 150%; } }
    @media (max-width: 991.98px) {
      :not(.login-page) .feature_38 {
        padding-bottom: 250px; }
        :not(.login-page) .feature_38 .img {
          top: 200px;
          height: 125%; } }
    @media (max-width: 767.98px) {
      :not(.login-page) .feature_38 h2 {
        margin-bottom: 10px; }
      :not(.login-page) .feature_38 .link {
        margin-top: 20px; } }
    @media (max-width: 575.98px) {
      :not(.login-page) .feature_38 {
        padding-bottom: 200px; }
        :not(.login-page) .feature_38 .img {
          top: 50%;
          height: 100%; }
        :not(.login-page) .feature_38 .link {
          font-size: 16px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_39 .description {
      margin: 10px 0 0 0; }
    :not(.login-page) .feature_39 .advantage {
      margin-top: 40px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .feature_40[class^="pb-"], :not(.login-page) .feature_40[class*=" pb-"] {
      padding-bottom: 45px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_41 .description {
      margin: 10px 0 40px 0; }
    :not(.login-page) .feature_41 .inner {
      padding-bottom: 60px; } }
  :not(.login-page) .feature_42 .bg {
    top: 0;
    right: 15px; }
  @media (max-width: 575.98px) {
    :not(.login-page) .feature_42 .inner {
      padding-top: 80px;
      padding-bottom: 60px; }
    :not(.login-page) .feature_42 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .feature_42 .hr {
      margin: 40px 0 0 0; } }
  :not(.login-page) .feature_43 .bg {
    top: 0;
    right: 15px; }
  @media (max-width: 575.98px) {
    :not(.login-page) .feature_43 .inner {
      padding-top: 80px;
      padding-bottom: 80px; }
    :not(.login-page) .feature_43 h2 {
      margin-bottom: 10px; } }
  :not(.login-page) .feature_44 {
    overflow: hidden; }
    @media (max-width: 767.98px) {
      :not(.login-page) .feature_44 h2 {
        margin-top: 0;
        margin-bottom: 10px; }
      :not(.login-page) .feature_44 .img {
        margin-top: 40px;
        max-width: 100%; }
      :not(.login-page) .feature_44 .row2 {
        margin-top: 50px; }
      :not(.login-page) .feature_44 .inner2 {
        padding-bottom: 0; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_45[class^="pb-"], :not(.login-page) .feature_45[class*=" pb-"] {
      padding-bottom: 35px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .feature_45 h2,
    :not(.login-page) .feature_45 .title {
      margin-bottom: 10px; }
    :not(.login-page) .feature_45 .description {
      margin-bottom: 40px; } }
  :not(.login-page) .feature_46 .block:hover, :not(.login-page) .feature_46 .block:focus {
    opacity: 0.7; }
  @media (max-width: 991.98px) {
    :not(.login-page) .feature_46 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .feature_46 .block {
      margin-bottom: 50px; } }
  :not(.login-page) .feature_47 .block {
    border: 2px solid rgba(255, 255, 255, 0.2); }
  @media (max-width: 1199.98px) {
    :not(.login-page) .feature_47[class^="pb-"], :not(.login-page) .feature_47[class*=" pb-"] {
      padding-bottom: 50px; }
    :not(.login-page) .feature_47 h2 {
      margin-bottom: 30px; }
    :not(.login-page) .feature_47 .block {
      padding: 30px; } }
  @media (max-width: 467.98px) {
    :not(.login-page) .form_1 h2 {
      margin-bottom: 20px; }
    :not(.login-page) .form_1 form {
      padding: 30px; } }
  @media (max-width: 359.98px) {
    :not(.login-page) .form_1 form {
      padding: 30px 20px; } }
  :not(.login-page) .form_2 {
    overflow: hidden; }
    :not(.login-page) .form_2 .bg {
      top: 0;
      left: 15px; }
    @media (max-width: 1199.98px) {
      :not(.login-page) .form_2 form {
        padding: 80px 0; }
      :not(.login-page) .form_2 .buttons {
        margin-top: 10px; }
      :not(.login-page) .form_2 button {
        margin-right: 0px;
        width: 100%; } }
    @media (max-width: 575.98px) {
      :not(.login-page) .form_2 form {
        max-width: 290px;
        margin: 0 auto; } }
  :not(.login-page) .form_4 .form_4_menu .slick-track {
    position: relative;
    border-bottom: 3px solid #E7EAED; }
  :not(.login-page) .form_4 .form_4_menu a {
    width: 50%; }
    :not(.login-page) .form_4 .form_4_menu a:after {
      content: "";
      display: block;
      width: 0%;
      height: 3px;
      background: #1e3060;
      position: absolute;
      right: 0;
      bottom: -3px;
      transition: width 0.2s;
      z-index: 10000; }
    :not(.login-page) .form_4 .form_4_menu a:last-child:after {
      left: 0;
      right: auto; }
    :not(.login-page) .form_4 .form_4_menu a.slick-current {
      color: #1e3060; }
      :not(.login-page) .form_4 .form_4_menu a.slick-current:after {
        width: 100%; }
  :not(.login-page) .form_4 .separate {
    position: relative; }
    :not(.login-page) .form_4 .separate:before {
      content: " ";
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      border-bottom: 1px solid #E7EAED; }
  @media (max-width: 575.98px) {
    :not(.login-page) .form_4 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .form_4 .form_4_menu a {
      line-height: 60px; }
    :not(.login-page) .form_4 .slider {
      margin-top: 40px;
      padding-bottom: 40px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .form_6 form {
      padding-top: 40px; }
    :not(.login-page) .form_6 h2 {
      margin-bottom: 30px; }
    :not(.login-page) .form_6 .hr {
      margin-top: 30px; } }
  :not(.login-page) .form_7 .right_side {
    border: 2px solid rgba(255, 255, 255, 0.2); }
  @media (max-width: 767.98px) {
    :not(.login-page) .form_7 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .form_7 .right_side {
      margin-top: 50px;
      padding: 30px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .form_9 form {
      margin-top: 40px; }
    :not(.login-page) .form_9 .input {
      width: 100%;
      margin-right: 0; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .form_10 .icons {
      font-size: 32px;
      line-height: 42px; }
      :not(.login-page) .form_10 .icons i {
        margin: 0 15px; }
    :not(.login-page) .form_10 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .form_10 form {
      margin-top: 40px; }
    :not(.login-page) .form_10 .input {
      width: 100%;
      margin-right: 0; } }
  :not(.login-page) .form_11 form {
    border: 2px solid #E7EAED; }
  @media (max-width: 767.98px) {
    :not(.login-page) .form_11 form {
      padding: 50px 30px; }
    :not(.login-page) .form_11 .input {
      width: 100%;
      margin-right: 0; } }
  :not(.login-page) .form_13 .js-form-block {
    border: 2px solid #E7EAED;
    transition: all 0.2s; }
    :not(.login-page) .form_13 .js-form-block.active {
      border-color: #0074f1; }
  :not(.login-page) .form_13 .icons {
    top: 25px;
    right: 30px;
    line-height: 1; }
  @media (max-width: 991.98px) {
    :not(.login-page) .form_13 .block {
      width: 250px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .form_13 h2 {
      margin-bottom: 30px; }
    :not(.login-page) .form_13 .js-form-block {
      padding: 30px; }
    :not(.login-page) .form_13 .icons {
      font-size: 32px; }
    :not(.login-page) .form_13 .block {
      margin-right: 0;
      width: 100%; } }
  @media (max-width: 469.98px) {
    :not(.login-page) .form_13 .inner,
    :not(.login-page) .form_13 .text-adaptive {
      margin-left: 0; } }
  :not(.login-page) .form_14 form {
    border: 2px solid #E7EAED; }
  @media (max-width: 575.98px) {
    :not(.login-page) .form_14 form {
      padding: 50px 30px; } }
  :not(.login-page) .form_15 .form_15_menu .slick-track {
    position: relative;
    border-bottom: 3px solid #E7EAED; }
  :not(.login-page) .form_15 .form_15_menu a {
    width: 50%; }
    :not(.login-page) .form_15 .form_15_menu a:after {
      content: "";
      display: block;
      width: 0%;
      height: 3px;
      background: #1e3060;
      position: absolute;
      right: 0;
      bottom: -3px;
      transition: width 0.2s;
      z-index: 10000; }
    :not(.login-page) .form_15 .form_15_menu a:last-child:after {
      left: 0;
      right: auto; }
    :not(.login-page) .form_15 .form_15_menu a.slick-current {
      color: #1e3060; }
      :not(.login-page) .form_15 .form_15_menu a.slick-current:after {
        width: 100%; }
  @media (max-width: 575.98px) {
    :not(.login-page) .form_15 h2 {
      margin-bottom: 30px; }
    :not(.login-page) .form_15 .form_15_menu a {
      line-height: 60px; }
    :not(.login-page) .form_15 .slider {
      margin-top: 40px;
      padding: 0 20px 40px 20px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_1 .description {
      margin: 10px 0 30px 0; }
    :not(.login-page) .call_to_action_1 .btn {
      margin-bottom: 15px; } }
  :not(.login-page) .call_to_action_2 .inner {
    border: 2px solid #E7EAED; }
  @media (max-width: 991.98px) {
    :not(.login-page) .call_to_action_2 .inner {
      padding: 40px 30px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .call_to_action_2 .inner {
      padding: 30px 20px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .call_to_action_3 .inner {
      padding: 50px 30px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .call_to_action_4 .text-adaptive {
      margin-top: 10px; } }
  :not(.login-page) .call_to_action_5 .inner {
    min-height: 100vh; }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_7 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .call_to_action_7 .btn {
      margin-top: 30px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_8 h2 {
      margin-bottom: 10px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_9 .btn {
      margin-top: 30px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_10 h2 {
      margin-bottom: 0; } }
  :not(.login-page) .call_to_action_12 .btn {
    height: auto; }
  @media (max-width: 575.98px) {
    :not(.login-page) .call_to_action_13 .btn {
      margin: 20px 10px 0 10px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_14 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .call_to_action_14 .title {
      margin: 15px 0 10px 0; }
    :not(.login-page) .call_to_action_14 .btn {
      margin-top: 25px; } }
  :not(.login-page) .call_to_action_15 .inner {
    border: 2px solid #E7EAED; }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_15 h2 {
      margin: 10px 0; }
    :not(.login-page) .call_to_action_15 .inner {
      padding-top: 30px;
      padding-bottom: 40px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .call_to_action_16 .btn {
      margin-left: 10px;
      margin-right: 10px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_17 .buttons {
      font-size: 32px;
      line-height: 42px; }
      :not(.login-page) .call_to_action_17 .buttons a {
        margin: 0 10px 20px 10px; } }
  :not(.login-page) .call_to_action_19 .link {
    display: flex;
    width: 20%; }
    :not(.login-page) .call_to_action_19 .link:hover i {
      transform: scale(1.1); }
    :not(.login-page) .call_to_action_19 .link i {
      transition: all 0.2s; }
  :not(.login-page) .call_to_action_19 .border_right {
    border-right: 2px solid #E7EAED; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .call_to_action_19 .inner {
      height: 200px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_19 .inner {
      height: 140px; }
      :not(.login-page) .call_to_action_19 .inner i {
        font-size: 36px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .call_to_action_19 .inner {
      height: 100px; }
      :not(.login-page) .call_to_action_19 .inner i {
        font-size: 28px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .call_to_action_20[class^="pb-"], :not(.login-page) .call_to_action_20[class*=" pb-"] {
      padding-bottom: 50px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_21 .text-adaptive {
      margin-top: 10px;
      margin-bottom: 40px; }
    :not(.login-page) .call_to_action_21 .play {
      width: 60px;
      height: 60px;
      line-height: 60px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .call_to_action_21 .play {
      margin: 0 auto; }
    :not(.login-page) .call_to_action_21 .btn {
      margin-left: 0; } }
  :not(.login-page) .call_to_action_22 .inner {
    border: 2px solid rgba(255, 255, 255, 0.3); }
  @media (max-width: 575.98px) {
    :not(.login-page) .call_to_action_22 .inner {
      padding: 40px 15px; }
    :not(.login-page) .call_to_action_22 .play {
      margin: 30px 0; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .call_to_action_23 {
      padding: 80px 0; }
      :not(.login-page) .call_to_action_23 .img {
        height: auto;
        width: 100%;
        border-radius: 10px; }
      :not(.login-page) .call_to_action_23 .inner {
        padding: 40px 0 0 0; }
      :not(.login-page) .call_to_action_23 .text-adaptive {
        margin: 10px 0 40px 0; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .call_to_action_26 .btn {
      margin: 0 10px 20px 10px; }
    :not(.login-page) .call_to_action_26 .img {
      margin-top: 40px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .call_to_action_27 .input {
      max-width: 290px; }
    :not(.login-page) .call_to_action_27 .btn {
      margin: 20px 0px 0px 0px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_28 .inner {
      padding-left: 0; } }
  :not(.login-page) .call_to_action_29 {
    overflow: hidden; }
    :not(.login-page) .call_to_action_29 .img {
      top: 0;
      left: 0; }
    @media (max-width: 575.98px) {
      :not(.login-page) .call_to_action_29 .img {
        position: static;
        max-width: 100%;
        height: auto; }
      :not(.login-page) .call_to_action_29 h2 {
        margin-bottom: 10px; }
      :not(.login-page) .call_to_action_29 .inner {
        padding-top: 80px;
        padding-bottom: 60px; }
      :not(.login-page) .call_to_action_29 .btn {
        margin-top: 30px; } }
  :not(.login-page) .call_to_action_30 {
    overflow: hidden; }
    @media (max-width: 575.98px) {
      :not(.login-page) .call_to_action_30 .img {
        position: static;
        max-width: 100%; }
      :not(.login-page) .call_to_action_30 .inner {
        padding-top: 0;
        padding-bottom: 60px; }
      :not(.login-page) .call_to_action_30 .btn {
        margin: 20px 10px 0 10px; } }
  :not(.login-page) .call_to_action_31 {
    overflow: hidden; }
    :not(.login-page) .call_to_action_31 .img {
      bottom: 0;
      left: 0px; }
    @media (max-width: 767.98px) {
      :not(.login-page) .call_to_action_31 .inner {
        padding-bottom: 60px; }
      :not(.login-page) .call_to_action_31 .img {
        position: static;
        max-width: 100%; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .call_to_action_32 {
      padding-bottom: 40px; }
      :not(.login-page) .call_to_action_32 .logos {
        margin-bottom: 0; }
        :not(.login-page) .call_to_action_32 .logos img {
          margin: 0 10px 20px 10px; }
      :not(.login-page) .call_to_action_32 .inner {
        padding: 80px 0px 60px 0px; }
      :not(.login-page) .call_to_action_32 .img {
        max-width: 100%; } }
  :not(.login-page) .team_1 .block {
    border: 2px solid #E7EAED; }
  :not(.login-page) .team_1 .block_socials {
    border-top: 2px solid #E7EAED; }
    :not(.login-page) .team_1 .block_socials .border_right {
      border-right: 2px solid #E7EAED; }
  :not(.login-page) .team_2 .bordered {
    border-left: 2px solid #E7EAED;
    border-right: 2px solid #E7EAED; }
  @media (max-width: 767.98px) {
    :not(.login-page) .team_2 .bordered {
      border: none;
      border-top: 2px solid #E7EAED;
      border-bottom: 2px solid #E7EAED; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .team_4 h2 {
      margin-bottom: 0; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .team_5 .block_socials .link {
      margin: 0 10px; } }
  :not(.login-page) .pricing_table_1 .block {
    border: 2px solid #E7EAED;
    border-bottom-left-radius: 10px; }
    :not(.login-page) .pricing_table_1 .block.noborder_right {
      border-right: none; }
  :not(.login-page) .pricing_table_1 .item {
    padding: 16px 0 14px 0;
    border-top: 2px solid #E7EAED; }
  :not(.login-page) .pricing_table_1 .price span.absolute {
    top: 5px;
    left: -15px; }
  @media (max-width: 991.98px) {
    :not(.login-page) .pricing_table_1[class^="pb-"], :not(.login-page) .pricing_table_1[class*=" pb-"] {
      padding-bottom: 30px; }
    :not(.login-page) .pricing_table_1 .block {
      margin: 0 auto 50px auto;
      max-width: 300px;
      border-radius: 10px; }
      :not(.login-page) .pricing_table_1 .block.noborder_right {
        border-right: 2px solid #E7EAED; } }
  :not(.login-page) .pricing_table_3 .overflow {
    overflow: auto; }
  :not(.login-page) .pricing_table_3 .block {
    border-right: 2px solid #E7EAED; }
    :not(.login-page) .pricing_table_3 .block.noborder_right {
      border-right: none; }
  :not(.login-page) .pricing_table_3 .price span.absolute {
    top: -5px;
    left: -15px; }
  :not(.login-page) .pricing_table_3 .table_row {
    border-left: 2px solid #E7EAED;
    border-right: 2px solid #E7EAED;
    border-bottom: 2px solid #E7EAED; }
    :not(.login-page) .pricing_table_3 .table_row:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px; }
  :not(.login-page) .pricing_table_3 .table_head {
    background: rgba(231, 234, 237, 0.5);
    border: none; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .pricing_table_3 .table-responsive-shadow {
      position: relative; }
      :not(.login-page) .pricing_table_3 .table-responsive-shadow:after {
        display: block;
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 40px;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 78%); } }
  :not(.login-page) .pricing_table_4 .block {
    border: 2px solid rgba(255, 255, 255, 0.2); }
  :not(.login-page) .pricing_table_4 ul {
    list-style: none; }
    :not(.login-page) .pricing_table_4 ul li {
      position: relative; }
      :not(.login-page) .pricing_table_4 ul li:not(.disabled):before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f00c";
        display: block;
        position: absolute;
        left: -25px;
        top: 0px;
        color: #18c967;
        font-size: 12px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .pricing_table_4 .block {
      padding-left: 50px;
      padding-right: 30px; } }
  :not(.login-page) .pricing_table_5 .block:not(.bg-dark) {
    border: 2px solid #E7EAED; }
  :not(.login-page) .pricing_table_5 .price span.absolute {
    top: 0px;
    left: -15px; }
  :not(.login-page) .pricing_table_5 ul {
    list-style: none; }
    :not(.login-page) .pricing_table_5 ul li {
      position: relative; }
      :not(.login-page) .pricing_table_5 ul li:not(.disabled):before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f00c";
        display: block;
        position: absolute;
        left: -25px;
        top: 0px;
        color: #0074f1;
        font-size: 12px; }
  :not(.login-page) .pricing_table_6 .custom-toggle:hover, :not(.login-page) .pricing_table_6 .custom-toggle:focus {
    background: #005bbe; }
  :not(.login-page) .pricing_table_6 .custom-toggle div {
    left: 3px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px; }
  :not(.login-page) .pricing_table_6 .img {
    margin-left: -2px;
    margin-right: -2px;
    width: auto;
    min-width: calc(100% + 4px);
    max-width: calc(100% + 4px); }
  :not(.login-page) .pricing_table_6 .block {
    border: 2px solid #E7EAED;
    border-top: none; }
  @media (max-width: 575.98px) {
    :not(.login-page) .pricing_table_6 .block .inner {
      padding: 0 30px; } }
  :not(.login-page) .pricing_table_7 .inner {
    border: 2px solid #E7EAED; }
  @media (max-width: 575.98px) {
    :not(.login-page) .pricing_table_7 h2 {
      margin-bottom: 10px; } }
  :not(.login-page) .contact_1 textarea.input {
    height: 170px; }
  :not(.login-page) .contact_1 .separate {
    position: relative; }
    :not(.login-page) .contact_1 .separate:before, :not(.login-page) .contact_1 .separate:after {
      content: " ";
      display: block;
      position: absolute;
      width: calc(100% / 2 - 60px);
      left: 0;
      top: 50%;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
    :not(.login-page) .contact_1 .separate:after {
      left: auto;
      right: 0; }
  :not(.login-page) .contact_2 textarea.input {
    height: 170px; }
  :not(.login-page) .contact_3 textarea.input {
    height: 150px; }
  :not(.login-page) .contact_4 textarea.input {
    height: 110px; }
  @media (max-width: 469.98px) {
    :not(.login-page) .contact_4 form {
      padding: 40px 20px; } }
  :not(.login-page) .contact_5 textarea.input {
    height: 170px; }
  :not(.login-page) .contact_6 form {
    background: rgba(231, 234, 237, 0.3); }
  :not(.login-page) .contact_6 textarea.input {
    height: 170px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .contact_6 .input_block .input {
      width: 240px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .contact_6 .input_block .input {
      width: 300px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .contact_6 .input_block .input {
      width: 100%; } }
  @media (max-width: 469.98px) {
    :not(.login-page) .contact_6 form {
      padding: 40px 20px; } }
  :not(.login-page) .contact_7 textarea.input {
    height: 170px; }
  @media (max-width: 991.98px) {
    :not(.login-page) .contact_7 .input_block .input {
      width: 100%; } }
  :not(.login-page) .contact_8 .js-google-map {
    right: calc((-100vw + 1200px) / 2);
    left: 15px;
    top: 0; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .contact_8 .js-google-map {
      right: calc((-100vw + 970px) / 2); }
    :not(.login-page) .contact_8 .text_big {
      font-size: 32px;
      line-height: 42px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .contact_8 .js-google-map {
      right: calc((-100vw + 750px) / 2); }
    :not(.login-page) .contact_8 .text_big {
      font-size: 26px;
      line-height: 36px; }
    :not(.login-page) .contact_8 .title {
      margin-bottom: 0; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .contact_8 {
      padding-bottom: 80px; }
      :not(.login-page) .contact_8 .left_side {
        padding-top: 80px;
        padding-bottom: 40px; }
      :not(.login-page) .contact_8 .js-google-map {
        position: relative;
        left: auto;
        right: auto;
        height: 60vh; }
      :not(.login-page) .contact_8 .text-adaptive {
        margin: 10px 0 40px 0; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .contact_8 {
      padding-bottom: 0; }
      :not(.login-page) .contact_8 .text_big {
        font-size: 22px;
        line-height: 32px; }
      :not(.login-page) .contact_8 .js-google-map {
        left: -15px;
        width: calc(100% + 30px); } }
  :not(.login-page) .contact_9 .map_overlay,
  :not(.login-page) .contact_9 .js-google-map {
    top: 0; }
  :not(.login-page) .contact_9 .map_overlay {
    background: rgba(53, 56, 61, 0.85); }
  @media (max-width: 1199.98px) {
    :not(.login-page) .contact_9 .text_big {
      font-size: 32px;
      line-height: 42px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .contact_9 .text_big {
      font-size: 26px;
      line-height: 36px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .contact_9 .text-adaptive {
      margin: 10px 0 40px 0; }
    :not(.login-page) .contact_9 .text_big {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 40px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .contact_10[class^="pb-"], :not(.login-page) .contact_10[class*=" pb-"] {
      padding-bottom: 60px; }
    :not(.login-page) .contact_10 h2 {
      margin: 40px 0 60px 0; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .contact_10 h2 a {
      font-size: 75%;
      margin-top: 0; }
    :not(.login-page) .contact_10 .socials a {
      margin: 0 15px 20px 15px; } }
  :not(.login-page) .showcase_1 .block a:hover {
    opacity: 0.8; }
  @media (max-width: 991.98px) {
    :not(.login-page) .showcase_1 .description {
      margin: 10px 0 0 0; } }
  :not(.login-page) .showcase_2 a:hover {
    opacity: 0.8; }
  :not(.login-page) .showcase_3 .block {
    border: 2px solid #E7EAED; }
  :not(.login-page) .showcase_3 img {
    vertical-align: bottom;
    position: relative;
    top: 2px; }
  :not(.login-page) .showcase_3 a:hover {
    opacity: 0.8; }
  @media (max-width: 575.98px) {
    :not(.login-page) .showcase_3 .block .text-adaptive {
      margin: 10px 0 20px 0; } }
  :not(.login-page) .showcase_4 .link:hover {
    filter: brightness(1.1); }
  :not(.login-page) .showcase_6 img {
    transition: all 0.2s; }
  :not(.login-page) .showcase_6 a:hover img {
    opacity: 0.7; }
  :not(.login-page) .showcase_8 a:hover {
    filter: brightness(1.15); }
  :not(.login-page) .showcase_9 .block {
    border: 2px solid #E7EAED; }
  @media (max-width: 359.98px) {
    :not(.login-page) .showcase_9 .block {
      padding-left: 20px;
      padding-right: 20px; } }
  :not(.login-page) .showcase_10 .with_border {
    border: 2px solid #E7EAED; }
  @media (max-width: 575.98px) {
    :not(.login-page) .footer_1 .links a {
      margin: 0 10px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .footer_3 .description {
      margin: 10px 0; }
    :not(.login-page) .footer_3 .socials a {
      margin: 0 10px; }
    :not(.login-page) .footer_3 .title {
      margin-bottom: 15px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .footer_5 .hr {
      margin-top: 30px; }
    :not(.login-page) .footer_5 .socials {
      margin-top: 20px; }
    :not(.login-page) .footer_5 .logo,
    :not(.login-page) .footer_5 .title {
      margin-bottom: 15px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .footer_6 .hr {
      margin: 0 0 50px 0; }
    :not(.login-page) .footer_6 .title {
      margin-bottom: 15px; }
    :not(.login-page) .footer_6 .links a {
      margin-bottom: 10px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .footer_7 .link {
      margin: 0 10px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .footer_8 a {
      margin: 0 10px; } }
  @media (max-width: 1199.98px) {
    :not(.login-page) .footer_9 form {
      margin-bottom: 40px; }
    :not(.login-page) .footer_9 .title {
      margin-bottom: 15px; }
    :not(.login-page) .footer_9 .links a {
      margin-bottom: 10px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .footer_10 .socials {
      margin-top: 15px; }
    :not(.login-page) .footer_10 .logo,
    :not(.login-page) .footer_10 .title {
      margin-bottom: 15px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .footer_11 a {
      margin: 0 10px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .footer_12 {
      padding-top: 50px; }
      :not(.login-page) .footer_12 .text-adaptive {
        margin-top: 30px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .footer_13 a {
      margin: 0 10px; } }
  :not(.login-page) .ecommerce_1 .product .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_1 .product .img_link:hover img {
    opacity: 0.8; }
  :not(.login-page) .ecommerce_1 .product .btn {
    left: 0;
    bottom: 0; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_1 .main_info .btn {
      margin-top: 30px; } }
  :not(.login-page) .ecommerce_2 .product {
    border: 2px solid #E7EAED; }
    :not(.login-page) .ecommerce_2 .product.big {
      min-height: calc(100% - 30px); }
      :not(.login-page) .ecommerce_2 .product.big ul {
        column-count: 2; }
    :not(.login-page) .ecommerce_2 .product li {
      list-style: none;
      position: relative; }
      :not(.login-page) .ecommerce_2 .product li:before {
        content: "";
        display: block;
        position: absolute;
        top: 11px;
        left: -10px;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background: #1E3060; }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_2 .product {
      max-width: 290px;
      padding: 20px 20px 30px 20px; }
      :not(.login-page) .ecommerce_2 .product.big ul {
        column-count: 1; }
      :not(.login-page) .ecommerce_2 .product .left {
        margin-right: 0; }
      :not(.login-page) .ecommerce_2 .product img {
        width: 100%;
        max-width: 100%; } }
  :not(.login-page) .ecommerce_3 .product .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_3 .product .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_3 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .ecommerce_3 .products {
      margin-top: 0; }
      :not(.login-page) .ecommerce_3 .products .text-adaptive {
        max-width: 100%; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_3 .product {
      max-width: 290px; } }
  :not(.login-page) .ecommerce_4 .dropdown-menu {
    box-shadow: 0px 15px 40px rgba(114, 114, 114, 0.3); }
  :not(.login-page) .ecommerce_4 .link_holder {
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_4 .link_holder:hover {
      background: #E7EAED; }
  :not(.login-page) .ecommerce_4 .product .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_4 .product .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_4 h2 {
      margin-bottom: 10px; }
    :not(.login-page) .ecommerce_4 .dropdown {
      margin-left: 10px;
      margin-right: 10px; } }
  :not(.login-page) .ecommerce_5 .dropdown-menu {
    box-shadow: 0px 15px 40px rgba(114, 114, 114, 0.3); }
  :not(.login-page) .ecommerce_5 .link_holder {
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_5 .link_holder:hover {
      background: #E7EAED; }
  :not(.login-page) .ecommerce_5 .product .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_5 .product .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_5 .categories {
      margin-top: 10px; }
    :not(.login-page) .ecommerce_5 .category {
      margin-left: 10px;
      margin-right: 10px; }
    :not(.login-page) .ecommerce_5 .product {
      margin-bottom: 0; } }
  :not(.login-page) .ecommerce_6 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_6 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_6 {
      padding-top: 80px; }
      :not(.login-page) .ecommerce_6 .bg {
        max-width: 100%;
        height: auto; }
      :not(.login-page) .ecommerce_6 .inner {
        padding-top: 40px;
        padding-bottom: 80px; }
      :not(.login-page) .ecommerce_6 .buttons {
        margin-top: 30px; } }
  :not(.login-page) .ecommerce_7 .categories a {
    border-bottom: 2px solid transparent; }
    :not(.login-page) .ecommerce_7 .categories a:hover {
      border-color: #3657ae; }
  :not(.login-page) .ecommerce_7 .categories .active {
    color: #1E3060;
    border-color: #1E3060; }
    :not(.login-page) .ecommerce_7 .categories .active:hover {
      border-color: #1E3060; }
  :not(.login-page) .ecommerce_7 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_7 .img_link:hover img {
    opacity: 0.8; }
  :not(.login-page) .ecommerce_7 input[type="radio"]:checked + label:before {
    content: "\f00c";
    color: #FFFFFF; }
  :not(.login-page) .ecommerce_7 input[type="radio"] + label:before {
    border: none;
    line-height: 20px;
    color: #FFFFFF; }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_7 .categories {
      margin-top: 20px; }
      :not(.login-page) .ecommerce_7 .categories a {
        margin: 5px 8px 0 8px;
        font-size: 16px;
        line-height: 26px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_7 .product {
      max-width: 290px; } }
  :not(.login-page) .ecommerce_8 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_8 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_8 .product {
      max-width: 290px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_9 {
      padding-top: 80px; }
      :not(.login-page) .ecommerce_9 .bg {
        max-width: 100%;
        height: auto; }
      :not(.login-page) .ecommerce_9 .inner {
        padding-top: 40px;
        padding-bottom: 80px; }
      :not(.login-page) .ecommerce_9 .prices {
        margin-top: 40px; } }
  :not(.login-page) .ecommerce_10 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_10 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_10 .inner {
      padding: 0; }
    :not(.login-page) .ecommerce_10 .title {
      font-size: 18px;
      line-height: 28px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_10 .product {
      margin-bottom: 0;
      max-width: 370px; } }
  :not(.login-page) .ecommerce_11 .slick-arrow {
    margin-top: 0;
    top: -90px;
    width: 50px;
    height: 50px; }
    :not(.login-page) .ecommerce_11 .slick-arrow:before {
      width: 50px;
      height: 50px;
      border: 2px solid #E7EAED;
      border-radius: 100%;
      transition: all 0.25s; }
    :not(.login-page) .ecommerce_11 .slick-arrow:hover:before {
      opacity: 1;
      background-color: #FFFFFF; }
  :not(.login-page) .ecommerce_11 .slick-prev {
    left: auto;
    right: 75px; }
    :not(.login-page) .ecommerce_11 .slick-prev:before {
      background-size: 7px auto; }
  :not(.login-page) .ecommerce_11 .slick-next {
    right: 15px; }
    :not(.login-page) .ecommerce_11 .slick-next:before {
      background-size: 7px auto; }
  :not(.login-page) .ecommerce_11 .holder:hover .inner {
    opacity: 1; }
  :not(.login-page) .ecommerce_11 .inner {
    top: 0;
    left: 0;
    background: rgba(0, 116, 241, 0.7);
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_11 .inner .btn {
      min-width: 145px; }
  :not(.login-page) .ecommerce_11 input[type="radio"]:checked + label {
    background: #FFFFFF;
    color: #1e3060; }
  :not(.login-page) .ecommerce_11 input[type="radio"] + label {
    height: 50px;
    line-height: 46px;
    border: 2px solid #E7EAED;
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_11 input[type="radio"] + label:before {
      display: none; }
    :not(.login-page) .ecommerce_11 input[type="radio"] + label:hover {
      color: #1e3060; }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_11 .header_holder {
      padding-right: 0; }
    :not(.login-page) .ecommerce_11 .slick-arrow {
      top: 10px; } }
  :not(.login-page) .ecommerce_12 .product {
    border: 2px solid #E7EAED; }
    :not(.login-page) .ecommerce_12 .product li {
      list-style: none;
      position: relative; }
      :not(.login-page) .ecommerce_12 .product li:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background: #1E3060; }
    :not(.login-page) .ecommerce_12 .product .btn {
      left: 50%;
      bottom: 30px;
      transform: translateX(-50%); }
  :not(.login-page) .ecommerce_12 .img_link img {
    margin: -2px -2px 0 -2px;
    max-width: calc(100% + 4px);
    min-width: calc(100% + 4px);
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_12 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_12 .product {
      max-width: 370px;
      padding-bottom: 30px; }
      :not(.login-page) .ecommerce_12 .product .btn {
        position: static;
        transform: none; }
    :not(.login-page) .ecommerce_12 .title {
      font-size: 18px;
      line-height: 28px; } }
  :not(.login-page) .ecommerce_13 .category {
    border: 2px solid #E7EAED; }
    :not(.login-page) .ecommerce_13 .category .btn {
      left: 50%;
      bottom: 30px;
      transform: translateX(-50%); }
  :not(.login-page) .ecommerce_13 .img_link img {
    margin: -2px -2px 0 -2px;
    max-width: calc(100% + 4px);
    min-width: calc(100% + 4px);
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_13 .img_link:hover img {
    opacity: 0.8; }
  :not(.login-page) .ecommerce_14 .product {
    overflow: hidden; }
  :not(.login-page) .ecommerce_14 .img_link img {
    min-height: 100%;
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_14 .img_link:hover img {
    opacity: 0.8; }
  :not(.login-page) .ecommerce_14 .stars .active {
    color: #f2c94c; }
  :not(.login-page) .ecommerce_14 .stars .absolute {
    top: 50%;
    left: 0;
    transform: translateY(-50%); }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_14 .img_link img {
      width: 100%;
      min-height: 0; } }
  :not(.login-page) .ecommerce_15 .slider_menu a img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_15 .slider_menu a:hover img {
    opacity: 0.8; }
  :not(.login-page) .ecommerce_15 .stars .active {
    color: #f2c94c; }
  :not(.login-page) .ecommerce_15 .stars .absolute {
    top: 50%;
    left: 0;
    transform: translateY(-50%); }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_15 h2 {
      margin-top: 0px;
      margin-bottom: 5px; }
    :not(.login-page) .ecommerce_15 .btn {
      margin-top: 50px; } }
  :not(.login-page) .ecommerce_16 .dropdown-menu {
    box-shadow: 0px 15px 40px rgba(114, 114, 114, 0.3); }
  :not(.login-page) .ecommerce_16 .link_holder {
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_16 .link_holder:hover {
      background: #E7EAED; }
  :not(.login-page) .ecommerce_16 input[type="radio"]:checked + label:before {
    content: "\f00c";
    color: #FFFFFF; }
  :not(.login-page) .ecommerce_16 input[type="radio"] + label:before {
    border: none;
    line-height: 20px;
    color: #FFFFFF; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_16 .inner {
      margin-left: 0;
      width: 390px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_16 .categories {
      margin-top: 10px; }
    :not(.login-page) .ecommerce_16 .category {
      margin-left: 10px;
      margin-right: 10px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_16 .product {
      max-width: 370px; }
      :not(.login-page) .ecommerce_16 .product .img {
        width: 100%; }
    :not(.login-page) .ecommerce_16 .inner {
      max-width: 100%; }
    :not(.login-page) .ecommerce_16 .price {
      margin-left: 0; } }
  :not(.login-page) .ecommerce_17 .holder:hover .inner {
    opacity: 1; }
  :not(.login-page) .ecommerce_17 .inner {
    top: 0;
    left: 0;
    background: rgba(0, 116, 241, 0.7);
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_18 .dropdown-menu {
    box-shadow: 0px 15px 40px rgba(114, 114, 114, 0.3); }
  :not(.login-page) .ecommerce_18 .link_holder {
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_18 .link_holder:hover {
      background: #E7EAED; }
  :not(.login-page) .ecommerce_18 .img_link img {
    min-height: 100%;
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_18 .img_link:hover img {
    opacity: 0.8; }
  :not(.login-page) .ecommerce_18 .buttons {
    left: 0;
    bottom: 10px; }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_18 .inner {
      margin-left: 0;
      padding-bottom: 0; }
    :not(.login-page) .ecommerce_18 .buttons {
      position: static; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_18 .product {
      max-width: 370px; }
      :not(.login-page) .ecommerce_18 .product .img_link img {
        width: 100%; } }
  :not(.login-page) .ecommerce_19 .product {
    border: 2px solid #E7EAED; }
    :not(.login-page) .ecommerce_19 .product.big {
      min-height: calc(100% - 30px); }
    :not(.login-page) .ecommerce_19 .product .img,
    :not(.login-page) .ecommerce_19 .product .slider {
      margin-top: -2px;
      margin-right: -2px;
      margin-bottom: -2px; }
    :not(.login-page) .ecommerce_19 .product .slider {
      overflow: hidden; }
      :not(.login-page) .ecommerce_19 .product .slider .slick-list,
      :not(.login-page) .ecommerce_19 .product .slider .slick-track,
      :not(.login-page) .ecommerce_19 .product .slider .slick-slide {
        height: 100%; }
    :not(.login-page) .ecommerce_19 .product .slider_menu {
      max-width: 100%; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_19 .product.small {
      width: calc(50% - 15px); }
      :not(.login-page) .ecommerce_19 .product.small .inner {
        padding-left: 20px;
        padding-right: 20px; }
    :not(.login-page) .ecommerce_19 .product.big .inner {
      padding-left: 30px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_19 .product.small {
      width: 100%; }
    :not(.login-page) .ecommerce_19 .product .slider {
      margin: -2px;
      width: auto;
      border-radius: 10px 10px 0 0; }
      :not(.login-page) .ecommerce_19 .product .slider img {
        width: 100%; } }
  @media (max-width: 369.98px) {
    :not(.login-page) .ecommerce_19 .product.big .inner {
      padding: 20px; }
    :not(.login-page) .ecommerce_19 .product.big .title {
      font-size: 18px;
      line-height: 28px; }
    :not(.login-page) .ecommerce_19 .product.big .btn {
      width: 100%; }
    :not(.login-page) .ecommerce_19 .product.big .slider_menu img {
      margin: 0 auto; }
    :not(.login-page) .ecommerce_19 .product.small {
      display: block !important; }
      :not(.login-page) .ecommerce_19 .product.small .img {
        margin: -2px;
        width: calc(100% + 4px);
        max-width: calc(100% + 4px);
        border-radius: 10px 10px 0 0; } }
  :not(.login-page) .ecommerce_20 .category {
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_20 .category:hover {
      background: #E7EAED; }
  :not(.login-page) .ecommerce_20 .product .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_20 .product .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_20 h2 {
      margin-bottom: 20px; } }
  @media (max-width: 359.98px) {
    :not(.login-page) .ecommerce_20 .title {
      font-size: 22px;
      line-height: 32px; } }
  :not(.login-page) .ecommerce_21 .category {
    border: 2px solid #E7EAED;
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_21 .category:hover {
      border-color: #0074f1; }
  :not(.login-page) .ecommerce_21 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_21 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_21 .inner {
      padding-left: 0;
      padding-right: 0; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_21 .category {
      max-width: 320px; } }
  :not(.login-page) .ecommerce_22 .dropdown-menu {
    box-shadow: 0px 15px 40px rgba(114, 114, 114, 0.3); }
  :not(.login-page) .ecommerce_22 .link_holder {
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_22 .link_holder:hover {
      background: #E7EAED; }
  :not(.login-page) .ecommerce_22 .category {
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_22 .category:hover {
      background: #E7EAED; }
  :not(.login-page) .ecommerce_22 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_22 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_22[class^="pb-"], :not(.login-page) .ecommerce_22[class*=" pb-"] {
      padding-bottom: 30px; }
    :not(.login-page) .ecommerce_22 .btn {
      margin-right: 0; } }
  :not(.login-page) .ecommerce_23 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_23 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_23 .buttons {
      margin-top: 50px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_23 {
      padding-top: 80px; }
      :not(.login-page) .ecommerce_23 .bg {
        max-width: 100%;
        height: auto;
        border-radius: 10px; }
      :not(.login-page) .ecommerce_23 .inner {
        padding-top: 40px;
        padding-bottom: 80px; }
      :not(.login-page) .ecommerce_23 .buttons {
        margin-top: 30px; } }
  :not(.login-page) .ecommerce_24 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_24 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_24 {
      padding: 80px 0; }
      :not(.login-page) .ecommerce_24 .bg {
        max-width: 100%;
        height: auto;
        max-height: 80vh;
        border-radius: 10px; }
      :not(.login-page) .ecommerce_24 .inner {
        padding-top: 0;
        padding-bottom: 0; }
      :not(.login-page) .ecommerce_24 .title_price {
        width: 100%; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_24 .product {
      max-width: 370px; }
      :not(.login-page) .ecommerce_24 .product img {
        width: 100%; }
    :not(.login-page) .ecommerce_24 .inner {
      margin-left: 0; } }
  :not(.login-page) .ecommerce_26 .product:hover .inner {
    opacity: 1; }
  :not(.login-page) .ecommerce_26 .inner {
    top: 0;
    left: 0;
    background: rgba(0, 116, 241, 0.7);
    transition: all 0.25s;
    overflow: auto; }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_26 .main_btn {
      margin-top: 25px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_28 .btn {
      margin-top: 40px; } }
  :not(.login-page) .ecommerce_29 .inner {
    border: 2px solid #E7EAED; }
  :not(.login-page) .ecommerce_29 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_29 .img_link:hover img {
    opacity: 0.8; }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_29 .socials {
      margin-top: 20px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_29 .inner {
      padding: 30px; }
    :not(.login-page) .ecommerce_29 .images {
      padding: 0; }
      :not(.login-page) .ecommerce_29 .images .img_link {
        width: calc(100% / 2 - 30px); }
      :not(.login-page) .ecommerce_29 .images img {
        width: 100%; } }
  :not(.login-page) .ecommerce_30 .holder {
    border: 2px solid #E7EAED; }
  :not(.login-page) .ecommerce_30 .bg {
    top: -2px;
    right: -2px;
    bottom: -2px;
    background-position: 50% 50%;
    background-size: cover; }
  :not(.login-page) .ecommerce_30 .product.big .bg {
    width: 300px;
    border-radius: 0 10px 10px 0; }
  :not(.login-page) .ecommerce_30 .product.big .inner {
    margin-right: 320px; }
  :not(.login-page) .ecommerce_30 .product.small .bg {
    left: -2px;
    border-radius: 10px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_30 .inner {
      padding-bottom: 50px; }
    :not(.login-page) .ecommerce_30 .product.big .bg {
      width: auto;
      left: -2px;
      border-radius: 10px; }
    :not(.login-page) .ecommerce_30 .product.big .inner {
      margin-right: 0; }
    :not(.login-page) .ecommerce_30 .product.big .subtitle,
    :not(.login-page) .ecommerce_30 .product.big h2 {
      color: #FFFFFF; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_30 .inner {
      padding: 50px 30px; } }
  @media (max-width: 469.98px) {
    :not(.login-page) .ecommerce_30 .inner {
      padding: 40px 20px; } }
  :not(.login-page) .ecommerce_31 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_31 .img_link:hover img {
    opacity: 0.8; }
  :not(.login-page) .ecommerce_31 .btn_play {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  :not(.login-page) .ecommerce_31 input[type="radio"]:checked + label:before {
    content: "\f00c";
    color: #FFFFFF;
    text-align: center; }
  :not(.login-page) .ecommerce_31 input[type="radio"] + label:before {
    border: none;
    width: 22px;
    height: 22px;
    line-height: 22px;
    color: #FFFFFF; }
  :not(.login-page) .ecommerce_31 input[type="radio"] + label {
    margin: 0 2px;
    width: 22px;
    height: 22px; }
  :not(.login-page) .ecommerce_31 .colors_border {
    border: 2px solid #E7EAED;
    padding: 0 12px; }
  :not(.login-page) .ecommerce_31 .quantity_selector {
    border: 2px solid #E7EAED;
    overflow: hidden; }
    :not(.login-page) .ecommerce_31 .quantity_selector .control {
      width: 36px;
      line-height: 36px;
      background: transparent; }
      :not(.login-page) .ecommerce_31 .quantity_selector .control:hover {
        background: #E7EAED; }
      :not(.login-page) .ecommerce_31 .quantity_selector .control::-moz-selection {
        background: transparent;
        color: #1e3060; }
      :not(.login-page) .ecommerce_31 .quantity_selector .control::-webkit-selection {
        background: transparent;
        color: #1e3060; }
      :not(.login-page) .ecommerce_31 .quantity_selector .control::selection {
        background: transparent;
        color: #1e3060; }
    :not(.login-page) .ecommerce_31 .quantity_selector .input {
      width: 40px;
      height: 36px;
      border-left: 2px solid #E7EAED;
      border-right: 2px solid #E7EAED;
      color: #1e3060;
      font-weight: 500;
      font-size: 16px;
      text-align: center; }
  :not(.login-page) .ecommerce_31 .advantages li {
    list-style: none;
    position: relative; }
    :not(.login-page) .ecommerce_31 .advantages li:before {
      content: "";
      display: block;
      position: absolute;
      top: 11px;
      left: -12px;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background: #1E3060; }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_31 .img_link {
      margin-right: 5px; } }
  :not(.login-page) .ecommerce_32 .slider_menu .slick-arrow {
    margin-top: -25px;
    width: 50px;
    height: 50px;
    background: #E7EAED;
    border-radius: 100%; }
    :not(.login-page) .ecommerce_32 .slider_menu .slick-arrow:before {
      width: 100%;
      height: 100%;
      background-size: 7px auto;
      background-position: 50% 50%; }
    :not(.login-page) .ecommerce_32 .slider_menu .slick-arrow:hover {
      background-color: #cad1d7; }
      :not(.login-page) .ecommerce_32 .slider_menu .slick-arrow:hover:before {
        opacity: 1; }
  :not(.login-page) .ecommerce_32 .slider_menu img {
    cursor: pointer;
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_32 .slider_menu img:hover {
      opacity: 0.8; }
  :not(.login-page) .ecommerce_32 input[type="radio"]:checked + label:before {
    content: "\f00c";
    color: #FFFFFF;
    text-align: center; }
  :not(.login-page) .ecommerce_32 input[type="radio"] + label:before {
    border: none;
    width: 22px;
    height: 22px;
    line-height: 22px;
    color: #FFFFFF; }
  :not(.login-page) .ecommerce_32 input[type="radio"] + label {
    margin: 0 2px;
    width: 22px;
    height: 22px; }
  :not(.login-page) .ecommerce_32 .colors_border {
    border: 2px solid #E7EAED;
    padding: 0 12px; }
  :not(.login-page) .ecommerce_32 .quantity_selector {
    border: 2px solid #E7EAED;
    overflow: hidden; }
    :not(.login-page) .ecommerce_32 .quantity_selector .control {
      width: 36px;
      line-height: 36px;
      background: transparent; }
      :not(.login-page) .ecommerce_32 .quantity_selector .control:hover {
        background: #E7EAED; }
      :not(.login-page) .ecommerce_32 .quantity_selector .control::-moz-selection {
        background: transparent;
        color: #1e3060; }
      :not(.login-page) .ecommerce_32 .quantity_selector .control::-webkit-selection {
        background: transparent;
        color: #1e3060; }
      :not(.login-page) .ecommerce_32 .quantity_selector .control::selection {
        background: transparent;
        color: #1e3060; }
    :not(.login-page) .ecommerce_32 .quantity_selector .input {
      width: 40px;
      height: 36px;
      border-left: 2px solid #E7EAED;
      border-right: 2px solid #E7EAED;
      color: #1e3060;
      font-size: 16px;
      font-weight: 500;
      text-align: center; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_32 .inner {
      margin-left: 0; }
    :not(.login-page) .ecommerce_32 .top_form {
      margin-top: 10px; }
    :not(.login-page) .ecommerce_32 .buttons {
      margin-top: 30px; }
    :not(.login-page) .ecommerce_32 .bottom_form {
      margin-top: 30px; }
      :not(.login-page) .ecommerce_32 .bottom_form .text-adaptive {
        max-width: 370px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_32 .text-adaptive {
      margin-left: auto;
      margin-right: auto; } }
  @media (max-width: 369.98px) {
    :not(.login-page) .ecommerce_32 .quantity,
    :not(.login-page) .ecommerce_32 .colors,
    :not(.login-page) .ecommerce_32 .buttons a,
    :not(.login-page) .ecommerce_32 .buttons button {
      margin-left: 10px;
      margin-right: 10px; } }
  :not(.login-page) .ecommerce_33 .remove_product {
    width: 45px;
    height: 45px;
    top: 30px;
    right: 0;
    cursor: pointer;
    z-index: 10; }
    :not(.login-page) .ecommerce_33 .remove_product:hover svg path {
      stroke: #1e3060; }
    :not(.login-page) .ecommerce_33 .remove_product svg path {
      stroke: #1E3060;
      transition: all 0.25s; }
  :not(.login-page) .ecommerce_33 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_33 .img_link:hover img {
    opacity: 0.8; }
  :not(.login-page) .ecommerce_33 .colors_border {
    border: 2px solid #E7EAED;
    padding: 0 8px; }
    :not(.login-page) .ecommerce_33 .colors_border input[type="radio"]:checked + label:before {
      content: "\f00c";
      color: #FFFFFF;
      text-align: center; }
    :not(.login-page) .ecommerce_33 .colors_border input[type="radio"] + label:before {
      border: none;
      width: 22px;
      height: 22px;
      line-height: 22px;
      color: #FFFFFF; }
    :not(.login-page) .ecommerce_33 .colors_border input[type="radio"] + label {
      margin: 0 2px;
      width: 22px;
      height: 22px; }
  :not(.login-page) .ecommerce_33 .sizes input[type="radio"]:checked + label {
    background: #1e3060;
    color: #FFFFFF;
    border-color: #1e3060; }
  :not(.login-page) .ecommerce_33 .sizes input[type="radio"] + label:before {
    display: none; }
  :not(.login-page) .ecommerce_33 .sizes input[type="radio"] + label {
    width: auto;
    height: 40px;
    line-height: 36px; }
  :not(.login-page) .ecommerce_33 .sizes label {
    padding: 0 12px;
    border: 2px solid #E7EAED;
    border-right: none; }
    :not(.login-page) .ecommerce_33 .sizes label:first-of-type {
      border-radius: 20px 0 0 20px; }
    :not(.login-page) .ecommerce_33 .sizes label:last-of-type {
      border-radius: 0 20px 20px 0;
      border-right: 2px solid #E7EAED; }
    :not(.login-page) .ecommerce_33 .sizes label:hover {
      color: #1e3060; }
  :not(.login-page) .ecommerce_33 .quantity_selector {
    border: 2px solid #E7EAED;
    overflow: hidden; }
    :not(.login-page) .ecommerce_33 .quantity_selector .control {
      width: 36px;
      line-height: 36px;
      background: transparent; }
      :not(.login-page) .ecommerce_33 .quantity_selector .control:hover {
        background: #E7EAED; }
      :not(.login-page) .ecommerce_33 .quantity_selector .control::-moz-selection {
        background: transparent;
        color: #1e3060; }
      :not(.login-page) .ecommerce_33 .quantity_selector .control::-webkit-selection {
        background: transparent;
        color: #1e3060; }
      :not(.login-page) .ecommerce_33 .quantity_selector .control::selection {
        background: transparent;
        color: #1e3060; }
    :not(.login-page) .ecommerce_33 .quantity_selector .input {
      width: 40px;
      height: 36px;
      border-left: 2px solid #E7EAED;
      border-right: 2px solid #E7EAED;
      color: #1e3060;
      font-weight: 500;
      font-size: 16px;
      text-align: center; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_33 .product .inner {
      margin-left: 15px; }
    :not(.login-page) .ecommerce_33 .label_total {
      margin-left: 23px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_33 .label_total {
      margin-left: 0; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_33 .product_total {
      height: auto; } }
  @media (max-width: 481.98px) {
    :not(.login-page) .ecommerce_33 .product .inner {
      margin-top: 20px;
      margin-left: 0;
      max-width: 100%;
      width: 100%; } }
  :not(.login-page) .ecommerce_34 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_34 .img_link:hover img {
    opacity: 0.8; }
  :not(.login-page) .ecommerce_34 .quantity_selector {
    border: 2px solid #E7EAED;
    overflow: hidden; }
    :not(.login-page) .ecommerce_34 .quantity_selector .control {
      width: 36px;
      line-height: 36px;
      background: transparent; }
      :not(.login-page) .ecommerce_34 .quantity_selector .control:hover {
        background: #E7EAED; }
      :not(.login-page) .ecommerce_34 .quantity_selector .control::-moz-selection {
        background: transparent;
        color: #1e3060; }
      :not(.login-page) .ecommerce_34 .quantity_selector .control::-webkit-selection {
        background: transparent;
        color: #1e3060; }
      :not(.login-page) .ecommerce_34 .quantity_selector .control::selection {
        background: transparent;
        color: #1e3060; }
    :not(.login-page) .ecommerce_34 .quantity_selector .input {
      width: 40px;
      height: 36px;
      border-left: 2px solid #E7EAED;
      border-right: 2px solid #E7EAED;
      color: #1e3060;
      font-weight: 500;
      font-size: 16px;
      text-align: center; }
  :not(.login-page) .ecommerce_34 .total,
  :not(.login-page) .ecommerce_34 .subtotal,
  :not(.login-page) .ecommerce_34 .delivery {
    min-width: 115px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_34 .address_form {
      padding-right: 40px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_34 .address_form {
      padding-top: 20px;
      padding-right: 0px; }
      :not(.login-page) .ecommerce_34 .address_form .block {
        margin-bottom: 15px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_34 .total,
    :not(.login-page) .ecommerce_34 .subtotal,
    :not(.login-page) .ecommerce_34 .delivery {
      min-width: 50px; } }
  :not(.login-page) .ecommerce_35 .slider_menu .slick-track {
    position: relative;
    border-bottom: 3px solid #E7EAED; }
  :not(.login-page) .ecommerce_35 .slider_menu a {
    width: 50%; }
    :not(.login-page) .ecommerce_35 .slider_menu a:after {
      content: "";
      display: block;
      width: 0%;
      height: 3px;
      background: #1e3060;
      position: absolute;
      right: 0;
      bottom: -3px;
      transition: width 0.2s;
      z-index: 10000; }
    :not(.login-page) .ecommerce_35 .slider_menu a:last-child:after {
      left: 0;
      right: auto; }
    :not(.login-page) .ecommerce_35 .slider_menu a.slick-current {
      color: #1e3060; }
      :not(.login-page) .ecommerce_35 .slider_menu a.slick-current:after {
        width: 100%; }
  :not(.login-page) .ecommerce_35 .img_link img {
    transition: all 0.25s; }
  :not(.login-page) .ecommerce_35 .img_link:hover img {
    opacity: 0.8; }
  :not(.login-page) .ecommerce_35 .quantity_selector {
    border: 2px solid #E7EAED;
    overflow: hidden; }
    :not(.login-page) .ecommerce_35 .quantity_selector .control {
      width: 36px;
      line-height: 36px;
      background: transparent; }
      :not(.login-page) .ecommerce_35 .quantity_selector .control:hover {
        background: #E7EAED; }
      :not(.login-page) .ecommerce_35 .quantity_selector .control::-moz-selection {
        background: transparent;
        color: #1e3060; }
      :not(.login-page) .ecommerce_35 .quantity_selector .control::-webkit-selection {
        background: transparent;
        color: #1e3060; }
      :not(.login-page) .ecommerce_35 .quantity_selector .control::selection {
        background: transparent;
        color: #1e3060; }
    :not(.login-page) .ecommerce_35 .quantity_selector .input {
      width: 40px;
      height: 36px;
      border-left: 2px solid #E7EAED;
      border-right: 2px solid #E7EAED;
      color: #1e3060;
      font-weight: 500;
      font-size: 16px;
      text-align: center; }
  :not(.login-page) .ecommerce_35 .total {
    min-width: 115px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_35 .slider,
    :not(.login-page) .ecommerce_35 .slider_menu {
      padding-right: 40px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_35 .slider,
    :not(.login-page) .ecommerce_35 .slider_menu {
      padding-right: 0px; }
    :not(.login-page) .ecommerce_35 .products {
      padding-top: 0; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_35 .slider_menu a {
      line-height: 60px; }
    :not(.login-page) .ecommerce_35 .slider {
      margin-top: 40px; }
    :not(.login-page) .ecommerce_35 .total {
      min-width: 50px; } }
  :not(.login-page) .ecommerce_36 .with_border {
    border-bottom: 2px solid #E7EAED; }
  :not(.login-page) .ecommerce_36 .product .with_border {
    padding-top: 28px;
    padding-bottom: 28px; }
  :not(.login-page) .ecommerce_36 .quantity_selector .control {
    right: 20px;
    top: 50%;
    width: 16px;
    height: 12px;
    line-height: 8px;
    font-size: 12px; }
    :not(.login-page) .ecommerce_36 .quantity_selector .control.greater {
      transform: translateY(-100%); }
  :not(.login-page) .ecommerce_36 .remove_product {
    width: 45px;
    height: 45px;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10; }
    :not(.login-page) .ecommerce_36 .remove_product:hover svg path {
      stroke: #1e3060; }
    :not(.login-page) .ecommerce_36 .remove_product svg path {
      stroke: #1E3060;
      transition: all 0.25s; }
  :not(.login-page) .ecommerce_36 .total {
    min-width: 70px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_36 .total {
      min-width: 65px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .ecommerce_36 .total {
      min-width: 95px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_36 .product .title,
    :not(.login-page) .ecommerce_36 .product .variants {
      padding-top: 0px;
      padding-bottom: 0px; }
    :not(.login-page) .ecommerce_36 .with_border {
      border: none; }
    :not(.login-page) .ecommerce_36 .price_holder {
      position: static; }
    :not(.login-page) .ecommerce_36 .remove_product {
      top: 0;
      transform: none; }
    :not(.login-page) .ecommerce_36 .total {
      min-width: 0px; } }
  :not(.login-page) .ecommerce_37 .holder {
    border: 2px solid #E7EAED; }
  :not(.login-page) .ecommerce_37 .delivery_type input[type="radio"]:checked + label {
    background: #1e3060;
    color: #FFFFFF;
    border-color: #1e3060; }
  :not(.login-page) .ecommerce_37 .delivery_type input[type="radio"] + label:before {
    display: none; }
  :not(.login-page) .ecommerce_37 .delivery_type input[type="radio"] + label {
    line-height: 46px; }
  :not(.login-page) .ecommerce_37 .delivery_type label {
    height: 50px;
    border: 2px solid #E7EAED;
    transition: all 0.25s; }
    :not(.login-page) .ecommerce_37 .delivery_type label:hover {
      color: #1e3060; }
  @media (max-width: 399.98px) {
    :not(.login-page) .ecommerce_37 .holder {
      padding: 30px 20px; } }
  :not(.login-page) .ecommerce_38 .product {
    border-bottom: 2px solid #E7EAED; }
  :not(.login-page) .ecommerce_38 .remove_product {
    width: 45px;
    height: 45px;
    top: 8px;
    right: -45px;
    cursor: pointer;
    z-index: 10; }
    :not(.login-page) .ecommerce_38 .remove_product:hover svg path {
      stroke: #1e3060; }
    :not(.login-page) .ecommerce_38 .remove_product svg path {
      stroke: #1E3060;
      transition: all 0.25s; }
  :not(.login-page) .ecommerce_38 .total,
  :not(.login-page) .ecommerce_38 .subtotal,
  :not(.login-page) .ecommerce_38 .delivery {
    min-width: 30px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .ecommerce_38 .remove_product {
      right: -15px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .ecommerce_38 .btn {
      margin-top: 0px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .ecommerce_38 .total,
    :not(.login-page) .ecommerce_38 .subtotal,
    :not(.login-page) .ecommerce_38 .delivery {
      min-width: 50px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .application_2 .application_2_left_img {
      max-height: 50vh; } }
  :not(.login-page) .application_2 .with_borders:nth-of-type(1) {
    border-bottom: 2px solid #ebeaed;
    border-right: 2px solid #ebeaed; }
  :not(.login-page) .application_2 .with_borders:nth-of-type(2) {
    border-bottom: 2px solid #ebeaed;
    border-right: 2px solid #ebeaed; }
  :not(.login-page) .application_2 .with_borders:nth-of-type(3) {
    border-bottom: 2px solid #ebeaed; }
  :not(.login-page) .application_2 .with_borders:nth-of-type(4) {
    border-right: 2px solid #ebeaed; }
  :not(.login-page) .application_2 .with_borders:nth-of-type(5) {
    border-right: 2px solid #ebeaed; }
  @media (max-width: 991.98px) {
    :not(.login-page) .application_2 .with_borders:nth-of-type(2) {
      border-right: none; }
    :not(.login-page) .application_2 .with_borders:nth-of-type(3) {
      border-right: 2px solid #ebeaed;
      padding-top: 35px; }
    :not(.login-page) .application_2 .with_borders:nth-of-type(4) {
      border-right: none;
      border-bottom: 2px solid #ebeaed; } }
  :not(.login-page) .application_3 .bg {
    top: 0;
    right: -50px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .application_3 .inner {
      padding-top: 350px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .application_3 .inner {
      padding-top: 300px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .application_3 .inner {
      padding-top: 200px; } }
  @media (max-width: 576.98px) {
    :not(.login-page) .application_3 .inner {
      padding-top: 100px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .application_4 .application_4_left_img {
      max-height: 80vh; } }
  :not(.login-page) .application_5 .with_box_shadow {
    box-shadow: -50px 30px 70px rgba(13, 0, 0, 0.11); }
    @media (max-width: 767.98px) {
      :not(.login-page) .application_5 .with_box_shadow {
        display: none; } }
  :not(.login-page) .application_5 .left .absolute {
    bottom: 120px;
    left: -120px; }
    @media (max-width: 1199.98px) {
      :not(.login-page) .application_5 .left .absolute {
        bottom: 50px; } }
    @media (max-width: 991.98px) {
      :not(.login-page) .application_5 .left .absolute {
        bottom: 120px;
        left: -110px; } }
  :not(.login-page) .application_5 .right .absolute {
    top: 30px;
    left: -175px; }
  @media (max-width: 991.98px) {
    :not(.login-page) .application_5 h3 {
      margin-bottom: 40px; } }
  :not(.login-page) .application_11 ul {
    list-style-type: none; }
    :not(.login-page) .application_11 ul li .fas {
      font-size: 10px;
      color: #0074f1;
      padding-right: 15px; }
  @media (max-width: 767.98px) {
    :not(.login-page) .application_13 img {
      max-height: 80vh; } }
  :not(.login-page) .application_14 .bg {
    top: 0;
    left: 30px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .application_14 .application_14_inner {
      padding-bottom: 200px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .application_14 .application_14_inner {
      padding-bottom: 180px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .application_14 .application_14_inner {
      padding-bottom: 100px; } }
  @media (max-width: 576.98px) {
    :not(.login-page) .application_14 .application_14_inner {
      padding-bottom: 50px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .application_15 img {
      max-height: 80vh; } }
  :not(.login-page) .application_16 .with_border_radius_10 {
    border: 2px solid #E7EAED;
    border-radius: 10px; }
  :not(.login-page) .application_16 .border_on_right {
    border-right: 2px solid #E7EAED; }
  :not(.login-page) .application_16 .border_on_top {
    border-top: 2px solid #E7EAED; }
  :not(.login-page) .application_16 .bg {
    top: -377px;
    left: -170px; }
  @media (max-width: 991.98px) {
    :not(.login-page) .application_16 .bg {
      left: -100px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .application_16 .bg {
      display: none; } }
  :not(.login-page) .application_17 .bg {
    top: -376px;
    left: -30px; }
  @media (max-width: 991.98px) {
    :not(.login-page) .application_17 .bg {
      left: 0; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .application_17 .bg {
      display: none; } }
  :not(.login-page) .application_20 #application_20_bg {
    background-position: 50% 50%;
    background-size: contain; }
  :not(.login-page) .application_22 #application_22_bg {
    background-position: 50% 50%;
    background-size: contain; }
  :not(.login-page) .application_23 .color-dependent:nth-of-type(even) .radius_full {
    background: #0074f1; }
  :not(.login-page) .application_23 .color-dependent:nth-of-type(odd) .radius_full {
    background: #0074f1; }
  :not(.login-page) .application_23 i {
    color: #000000; }
  :not(.login-page) .application_23 .bg {
    position: absolute;
    top: -230px;
    left: -340px; }
  @media (max-width: 1199.98px) {
    :not(.login-page) .application_23 .pb-dependent {
      padding-bottom: 150px; }
    :not(.login-page) .application_23 .bg {
      top: -250px;
      left: -300px; } }
  @media (max-width: 991.98px) {
    :not(.login-page) .application_23 .pb-dependent {
      padding-bottom: 100px; }
    :not(.login-page) .application_23 .bg {
      top: -320px;
      left: -250px; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .application_23 .pb-dependent {
      padding-bottom: 40px; }
    :not(.login-page) .application_23 .bg {
      top: -530px;
      left: -180px; } }
  @media (max-width: 575.98px) {
    :not(.login-page) .application_23 .bg {
      display: none; } }
  :not(.login-page) .application_24 ul {
    list-style: none;
    padding-left: 0; }
    :not(.login-page) .application_24 ul li {
      display: flex;
      justify-content: flex-start;
      align-items: align-start; }
      :not(.login-page) .application_24 ul li:before {
        content: "●";
        color: #1e3060;
        margin-right: 12px; }
  @media (max-width: 767.98px) {
    :not(.login-page) .application_25 .text-nowrap {
      white-space: normal !important; } }
  @media (max-width: 767.98px) {
    :not(.login-page) .application_26 img {
      max-height: 80vh; } }
