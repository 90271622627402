/*
	Table of contents:
	1. Notifications
	2. Modals
*/

/* 1. Notifications */
.ant-message {
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  color: rgba(0,0,0,.65);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  position: fixed;
  z-index: 1010;
  width: 100%;
  top: 16px;
  left: 0;
  pointer-events: none;
  &-notice {
    padding: 8px;
    text-align: center;
    &:first-child {
      margin-top: -8px;
    }
    &-content {
      padding: 10px 16px;
      border-radius: 4px;
      -webkit-box-shadow: 0 4px 12px rgba(0,0,0,.15);
      box-shadow: 0 4px 12px rgba(0,0,0,.15);
      background: #fff;
      display: inline-block;
      pointer-events: all;
    }
  }
  &-success .anticon {
    color: #52c41a;
  }
  &-warning .anticon {
    color: #faad14;
  }
  &-error .anticon {
    color: #f5222d;
  }
  &-info, &-loading {
    .anticon {
      color: #1890ff;
    }
  }
  .anticon {
    margin-right: 8px;
    font-size: 16px;
    top: 1px;
    position: relative;
  }
  &-notice.move-up-leave.move-up-leave-active {
    -webkit-animation-name: MessageMoveOut;
    animation-name: MessageMoveOut;
    overflow: hidden;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
  }
}

@keyframes MessageMoveOut {
  0% {
    opacity: 1;
    max-height: 150px;
    padding: 8px;
  }

  to {
    opacity: 0;
    max-height: 0;
    padding: 0;
  }
}

/* 1. Modals */
.ant-modal {
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  color: rgba(0,0,0,.65);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  list-style: none;
  position: relative;
  width: auto;
  margin: 0 auto;
  top: 100px;
  padding: 0 0 24px;
  &-wrap {
    position: fixed;
    overflow: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
    outline: 0;
  }
  &-title {
    margin: 0;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: rgba(0,0,0,.85);
  }
  &-content {
    position: relative;
    background-color: #fff;
    border: 0;
    border-radius: 4px;
    background-clip: padding-box;
    -webkit-box-shadow: 0 4px 12px rgba(0,0,0,.15);
    box-shadow: 0 4px 12px rgba(0,0,0,.15);
  }
  &-close {
    cursor: pointer;
    border: 0;
    background: transparent;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    -webkit-transition: color .3s;
    transition: color .3s;
    color: rgba(0,0,0,.45);
    outline: 0;
    padding: 0;
    &:focus,&:hover {
      color: rgba(0,0,0,.75);
      text-decoration: none;
    }
  }
  &-close-x {
    display: block;
    font-style: normal;
    vertical-align: baseline;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
    width: 56px;
    height: 56px;
    line-height: 56px;
    font-size: 16px;
  }
  &-header {
    padding: 16px 24px;
    border-radius: 4px 4px 0 0;
    background: #fff;
    color: rgba(0,0,0,.65);
    border-bottom: 1px solid #e8e8e8;
  }
  &-body {
    padding: 24px;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word;
  }
  &-footer {
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    border-radius: 0 0 4px 4px;
    button+button {
      margin-left: 8px;
      margin-bottom: 0;
    }
  }
  &.zoom-appear,&.zoom-enter {
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    opacity: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  &-mask {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.65);
    height: 100%;
    z-index: 1000;
    filter: alpha(opacity=50);
    &-hidden {
      display: none;
    }
  }
  &-open {
    overflow: hidden;
  }
  &-centered {
    text-align: center;
    &:before {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      width: 0
    }
    .ant-modal {
      display: inline-block;
      vertical-align: middle;
      top: 0;
      text-align: left;
    }
  }
}

@media (max-width: 767px) {
  .ant-modal {
    max-width:calc(100vw - 16px);
    margin: 8px auto;
    &-centered .ant-modal {
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
    }
  }
}
